import { useEffect } from 'react';
import { useAppDispatch } from '@store';
import { GroupsType } from '@features/groups';
import { useDebounce } from '@hooks';
import { ChangedObjectsType, SaveStatus, StoryLayers } from '../types';
import { fetchSaveStory } from '../storiesSlice';
import { useCurrentStoriesSlice } from './useCurrentStoriesSlice';

export const useSaveChanges = ({
  changedObjects,
  stories,
  appId,
  groupId,
  templateId
}: {
  changedObjects: ChangedObjectsType[];
  stories: StoryLayers;
  appId?: string;
  groupId?: string;
  templateId?: string;
}) => {
  const dispatch = useAppDispatch();
  const changedObjectsDebounced = useDebounce(changedObjects, 100);
  const currentStoriesSlice = useCurrentStoriesSlice(
    templateId ? GroupsType.TEMPLATE : GroupsType.GROUP
  );

  useEffect(() => {
    const notSavedStoryObjects = changedObjectsDebounced
      .filter((item) => item.saveStatus === SaveStatus.NOT_SAVED)
      .reduce(
        (
          acc: {
            [storyId: string]: {
              widgetId: string | null;
              layersGroupId: string;
              syncToLocales?: boolean;
            };
          },
          item
        ) => {
          acc[item.storyId] = {
            widgetId: item.widgetId ?? null,
            layersGroupId: item.layersGroupId,
            syncToLocales: item.syncToLocales
          };

          return acc;
        },
        {}
      );

    if (Object.keys(notSavedStoryObjects).length === 0) {
      dispatch(currentStoriesSlice.actions.setSavingStatus('saved'));

      const savedStoryObjects = changedObjectsDebounced.filter(
        (item) => item.saveStatus === SaveStatus.SAVED
      );

      savedStoryObjects.forEach((item) => {
        dispatch(currentStoriesSlice.actions.deleteChangedObject(item.id));
      });

      return;
    }

    dispatch(currentStoriesSlice.actions.setSavingStatus('saving'));

    for (const storyId in notSavedStoryObjects) {
      if (Object.prototype.hasOwnProperty.call(notSavedStoryObjects, storyId)) {
        const story = stories?.[notSavedStoryObjects[storyId].layersGroupId]?.layers?.[storyId];

        dispatch(
          fetchSaveStory({
            appId,
            groupId,
            templateId,
            story
          })
        );
      }
    }
  }, [changedObjectsDebounced]);
};
