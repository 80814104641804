import React from 'react';
import block from 'bem-cn';
import './SettingsTabLayout.scss';
import { Typography } from '@components';

const b = block('SettingsTabLayout');

interface Props {
  title: string;
  controlsGroup?: React.ReactChild;
  wide?: boolean;
}

export const SettingsTabLayout: React.FunctionComponent<Props> = (props) => {
  const { title, controlsGroup, children, wide } = props;

  return (
    <div className={b()}>
      <div className={b('header')}>
        <Typography className={b('title')} styling="h5" tag="h5">
          {title}
        </Typography>
        <div className={b('controlsGroup')}>{controlsGroup}</div>
      </div>
      <div className={b('body', { wide })}>{children}</div>
    </div>
  );
};
