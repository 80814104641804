import React from 'react';
import PropTypes from 'prop-types';

const IconSticker = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    stroke="#AFAFAF"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.31 4.31005L19.69 11.69C19.888 11.888 20.003 12.161 19.988 12.44C19.732 17.172 15.364 20.81 10.395 19.844C7.27604 19.238 4.76204 16.724 4.15604 13.605C3.19104 8.63605 6.82904 4.26805 11.56 4.01205C11.839 3.99705 12.112 4.11205 12.31 4.31005Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M11.9369 4.07617C9.79893 6.29117 9.81493 9.81517 11.9999 12.0002C14.1849 14.1852 17.7089 14.2012 19.9239 12.0622"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconSticker.propTypes = {
  className: PropTypes.string
};

IconSticker.defaultProps = {
  className: ''
};

export default IconSticker;
