import React, { useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { FormSubmitHandler } from 'redux-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { SignInForm } from '@forms';
import { userSlice, informSlice, MessageTypes } from '@features';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { AuthLayout } from '@layouts';
import { Auth } from '@utils';
import { useAppDispatch } from '../../../store';
import { FooterLinks } from '../_components';

export const SignInPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [figmaToken] = useQueryParam('figmaToken', StringParam);
  const [joinApp] = useQueryParam('joinApp', StringParam);

  if (localStorage.getItem('auth')) {
    if (!figmaToken) {
      history.push('/dashboard');
    }
  }

  useEffect(() => {
    if (joinApp) {
      localStorage.setItem('joinAppId', joinApp);
    }
  }, [joinApp]);

  const handleSubmit: FormSubmitHandler = (data: any) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/sign-in`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: figmaToken ? { Authorization: figmaToken && `Bearer ${figmaToken}` } : undefined
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          dispatch(userSlice.actions.setEmail(data.email));

          Auth.setStorage({
            ...response,
            user: { ...response.user, email: data.email }
          });

          axios.defaults.headers.common = { Authorization: `Bearer ${response.access_token}` };

          if (figmaToken) {
            history.push('/returnToFigma');
          } else {
            history.push('/dashboard');
          }

          ReactGA.event({
            category: 'User',
            action: 'login',
            label: response.user.id
          });
        } else if (
          response.error === 'mongo: no documents in result' ||
          response.error === 'invalid params'
        ) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: t('errors.invalidEmailOrPassword')
            })
          );
        } else {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: t('errors.somethingWentWrong')
            })
          );
        }
      });
  };

  return (
    <AuthLayout
      footer={
        <FooterLinks
          links={[
            {
              title: t('auth.forgetPassword'),
              href: '/auth/reset-password'
            },
            {
              title: t('auth.signUp'),
              href: '/signup'
            },
            {
              title: t('auth.help'),
              href: 'https://docs.storysdk.com/',
              isExternal: true
            }
          ]}
        />
      }
      template="form"
      title={t('auth.welcomeBack')}
    >
      <SignInForm onSubmit={handleSubmit} />
    </AuthLayout>
  );
};
