import { GoogleFont } from '@features';
import { TextWidgetParamsType } from '@storysdk/react';
import { getFontLink, renderTextBackgroundStyles, renderBackgroundStyles } from '.';

export const EXCLUDE_FONT_FAMILY = ['Roboto'];

export const getHtmlTextLayout = (
  width: number,
  height: number,
  params: TextWidgetParamsType,
  fonts: GoogleFont[]
) => {
  if (EXCLUDE_FONT_FAMILY.includes(params.fontFamily)) {
    return null;
  }

  const fontLink = getFontLink(fonts);
  const IMAGE_QUALITY_INDEX = 1;

  return {
    width: Math.round(width * IMAGE_QUALITY_INDEX),
    height: Math.round(height * IMAGE_QUALITY_INDEX),
    head: fontLink || '',
    body: `<div class="TextWidgetControl" style="opacity: ${
      params.widgetOpacity ? +params.widgetOpacity / 100 : 1
    };" ><div class="TextWidgetControl__container ${
      params.color.type === 'gradient' ? 'TextWidgetControl__container_gradient' : ''
    }" style="font-style: ${params.fontParams.style}; font-weight: ${
      params.fontParams.weight
    }; font-family: ${params.fontFamily}; font-size: ${Math.round(
      params.fontSize * IMAGE_QUALITY_INDEX
    )}px; text-align: ${params.align}; ${renderTextBackgroundStyles({
      color: params.color,
      returnType: 'string'
    })}"><span class="TextWidgetControl__span">${params.text}</span></div>${
      params.withFill
        ? `<div class="TextWidgetControl__background" style="background: ${renderBackgroundStyles(
            params.backgroundColor
          )}"></div>`
        : ''
    }</div>`,
    css: `
    html, body {
      margin: 0;
      padding: 0;
    }
    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    .TextWidgetControl {
      position: relative;
    } 
    .TextWidgetControl__span {
      width: 100%;
    }
    .TextWidgetControl__container {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
    }
    .TextWidgetControl__container_gradient {
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent;
    }
    .TextWidgetControl__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }`
  };
};
