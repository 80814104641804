import { App } from '@features/appsManagerSlice';
import { useGetUserSubscription } from '@hooks';
import { AppPlan, UserSubscriptionStatus } from '../types';

export const useGetUserPlanByApp = (app?: App): AppPlan => {
  const userSubcription = useGetUserSubscription();

  let plan: AppPlan | string = AppPlan.FREE;

  if (
    app &&
    app.ownerId &&
    app.ownerId !== userSubcription.userId &&
    (app.subscriptionStatus === UserSubscriptionStatus.ACTIVE ||
      app.subscriptionStatus === UserSubscriptionStatus.TRIALING)
  ) {
    plan = app.plan;
  } else if (!app || app.ownerId === userSubcription.userId) {
    plan = userSubcription.plan;

    if (plan === 'Startup' || plan === 'Business') {
      plan = AppPlan.STARTUP;
    } else if (plan === 'Indie') {
      plan = AppPlan.INDIE;
    }
  }

  return plan as AppPlan;
};
