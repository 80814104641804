import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshModal, FreshModalTitle, FreshButton } from '@components/_fresh';

import './PlanDowngradeModal.scss';

interface PlanDowngradeModalProps {
  isOpen: boolean;
  onClose(): void;
  handleDowngradePlan(): void;
}

export const PlanDowngradeModal: React.FC<PlanDowngradeModalProps> = (props) => {
  const { isOpen, onClose, handleDowngradePlan } = props;

  const b = block('PlanDowngradeModal');
  const { t } = useTranslation();

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle centered>{t('dashboard.modals.downgradePlan.title')}</FreshModalTitle>

        <section className={b('btnContaier')}>
          <FreshButton
            color="grey"
            text={t('dashboard.modals.downgradePlan.close')}
            onClick={onClose}
          />
          <FreshButton
            color="black"
            text={t('dashboard.modals.downgradePlan.downgradeNow')}
            onClick={handleDowngradePlan}
          />
        </section>
      </div>
    </FreshModal>
  );
};
