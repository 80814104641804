import { RootState, useAppDispatch } from '@store';
import { MessageTypes, informSlice } from '@features/inform';
import { App } from '@features/appsManagerSlice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AccesibleFeaturesByCollaboratorRole, AppCollaborationFeatures } from '../consts';
import { CollabaratorRole } from '../types';

export const useRedirectByAccessCollaborator = ({
  feature,
  redirectLink,
  app
}: {
  feature: AppCollaborationFeatures;
  redirectLink?: string;
  app?: App;
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector((store: RootState) => store.user.user);
  const history = useHistory();
  const { t } = useTranslation();

  const collaboratorRole =
    app && app.collaboratorRole && user.id !== app.ownerId
      ? app.collaboratorRole
      : CollabaratorRole.OWNER;

  if (!AccesibleFeaturesByCollaboratorRole[collaboratorRole][feature]) {
    if (redirectLink) {
      history.push(redirectLink);
    } else {
      history.goBack();
    }

    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.DONT_HAVE_ACCESS,
        text: t('notification.subscription.dontHaveAccessCollaboratorRole')
      })
    );
  }
};
