import { DEFAULT_WIDGET_PARAMS } from '@features/consts';
import { WidgetPositionType, WidgetsTypes } from '@storysdk/react';
import { StoryActionType, StoryEditorAction } from '@modules';
import { Dispatch } from 'react';
import { getInitWidgetSizes } from '@utils';
import { WidgetContentType } from '../types';
import { getScalableValue } from './calculateWidgetsValues';
import StoryWidgetFactory from './StoryWidgetFactory';

export const createObjectWithPosition = ({
  dispatch,
  type,
  position,
  originPosition,
  recentFont,
  content,
  nextStoryId
}: {
  dispatch: Dispatch<StoryActionType> | null;
  type: WidgetsTypes;
  position?: Partial<WidgetPositionType>;
  originPosition?: Partial<WidgetPositionType['origin']>;
  recentFont?: string;
  content?: Partial<WidgetContentType>;
  nextStoryId?: string;
}) => (e: React.MouseEvent<HTMLButtonElement>) => {
  dispatch &&
    dispatch({
      type: StoryEditorAction.ADD_STORY_WIDGET,
      payload: StoryWidgetFactory.makeWithPosition({
        type,
        position: {
          rotate: 0,
          x: getScalableValue(DEFAULT_WIDGET_PARAMS.x),
          y: getScalableValue(DEFAULT_WIDGET_PARAMS.y),
          width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
          height: getScalableValue(DEFAULT_WIDGET_PARAMS.height),
          realWidth: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
          realHeight: getScalableValue(DEFAULT_WIDGET_PARAMS.height),
          origin: {
            x: getScalableValue(DEFAULT_WIDGET_PARAMS.x),
            y: getScalableValue(DEFAULT_WIDGET_PARAMS.y),
            width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
            height: getScalableValue(DEFAULT_WIDGET_PARAMS.height),
            ...originPosition
          },
          elementsSize: getInitWidgetSizes(type),
          ...position
        },
        font: recentFont,
        content,
        nextStoryId
      })
    });

  e.currentTarget.blur();
};
