import React, { useCallback } from 'react';
import ReactSlider from 'react-slider';
import block from 'bem-cn';

import './SettingsSlider.scss';

const b = block('SettingsSlider');

type PropsType = {
  value: number;
  title: string;
  onChange(value: number): void;
};

export const SettingsSlider: React.FC<PropsType> = ({ value, title, onChange }) => {
  const Thumb = useCallback((props: any) => <div {...props} className={b('thumb')} />, []);
  const SliderTrack = useCallback(
    (props: any, state: any) => (
      <div {...props} className={b('track', { selected: state.index === 1 })} />
    ),
    []
  );

  const handleChange = (newValue: any) => {
    onChange(newValue);
  };

  return (
    <div className={b()}>
      <span className={b('title')}>{title}</span>
      <div className={b('slider')}>
        <ReactSlider
          max={100}
          min={0}
          renderThumb={Thumb}
          renderTrack={SliderTrack}
          value={[value]}
          onChange={handleChange}
        />
      </div>
      <span className={b('value')}>{value}</span>
    </div>
  );
};
