import React from 'react';
import Skeleton from 'react-loading-skeleton';
import block from 'bem-cn';
import { useIsMobile } from '@hooks';
import '../GroupsLine.scss';

const b = block('GroupsLine');

interface GroupsLineLoadItemProps {
  isSquared?: boolean;
}

export const GroupsLineLoadItem: React.FC<GroupsLineLoadItemProps> = ({ isSquared = false }) => {
  const isMobile = useIsMobile();

  return (
    <div className={b('groupItem', { noHover: true })}>
      <span className={b('addGroup', { noBackground: true })}>
        <Skeleton
          height={isMobile ? 48 : 64}
          style={{ borderRadius: isSquared ? 12 : '50%' }}
          width={isMobile ? 48 : 64}
        />
      </span>
      <div className={b('groupTitle')}>
        <Skeleton height={isMobile ? 14 : 18} width={isMobile ? 64 : 82} />
      </div>
    </div>
  );
};
