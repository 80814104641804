import React from 'react';
import block from 'bem-cn';
import { Icon } from '@components';
import './SettingsInputText.scss';

const b = block('SettingsInputText');

interface Props {
  value: string;
  name: string;
  placeholder?: string;
  icon?: string;
  className?: string;
  onChange(fieldName: string, value: string): void;
}

export const SettingsInputText: React.FC<Props> = ({
  onChange,
  value,
  name,
  className,
  placeholder,
  icon
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <div className={`${b()} ${className || ''}`}>
      {icon ? <Icon className={b('icon').toString()} name={icon} /> : null}

      <input
        className={`${b('input', { icon: !!icon })}`}
        name={name}
        placeholder={placeholder || ''}
        type="text"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};
