import { domain } from 'process';
import axios from 'axios';
import { nanoid } from 'nanoid';
import {
  GroupsType,
  LayerData,
  SYNC_TO_LOCALES_TEXT_WIDGETS_LIMITS,
  Story,
  StoryData,
  StoryStatus,
  Templates
} from '@features';
import { DateTime } from 'luxon';
import { WidgetObjectType } from '@storysdk/react';
import { Auth } from '@utils';

export const API = {
  user: {
    init() {
      return axios({
        method: 'get',
        url: '/users/whoami'
      });
    },
    changeName(name: string) {
      return axios({
        method: 'post',
        url: '/users/update-full-name',
        data: {
          full_name: name
        }
      });
    }
  },
  apps: {
    create(params: { name: string; localization: any }) {
      return axios({
        method: 'post',
        url: '/apps',
        data: {
          user_id: Auth.user ? Auth.user.id : '',
          ios_app_id: '',
          android_app_id: '',
          title: params.name,
          share: false,
          sdk_token: '',
          localization: params.localization,
          settings: {
            groupView: {
              ios: 'circle',
              android: 'circle',
              react: 'circle',
              web: 'circle'
            },
            isShowMockup: true,
            fonts: [
              {
                family: 'Roboto',
                variants: [
                  '100',
                  '100italic',
                  '300',
                  '300italic',
                  'regular',
                  'italic',
                  '500',
                  '500italic',
                  '700',
                  '700italic',
                  '900',
                  '900italic'
                ],
                subsets: [
                  'cyrillic',
                  'cyrillic-ext',
                  'greek',
                  'greek-ext',
                  'latin',
                  'latin-ext',
                  'vietnamese'
                ],
                version: 'v30',
                lastModified: '2022-09-22',
                files: {
                  '100': 'http://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf',
                  '300': 'http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf',
                  '500': 'http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf',
                  '700': 'http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
                  '900': 'http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf',
                  '100italic':
                    'http://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf',
                  '300italic':
                    'http://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf',
                  regular: 'http://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
                  italic: 'http://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf',
                  '500italic':
                    'http://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf',
                  '700italic':
                    'http://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf',
                  '900italic':
                    'http://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf'
                },
                category: 'sans-serif',
                kind: 'webfonts#webfont'
              }
            ]
          },
          setting_ios: {},
          setting_android: {}
        }
      });
    },
    getLocales() {
      return axios({
        method: 'get',
        url: '/static/locales'
      });
    },
    getList() {
      return axios({
        method: 'get',
        url: '/apps'
      });
    },
    getAppsWithStoriesCount() {
      return axios({
        method: 'get',
        url: '/apps/stories_count'
      });
    },
    get(params: { appId: string }) {
      return axios({
        method: 'get',
        url: `/apps/${params.appId}`
      });
    },
    update(params: {
      id: string;
      appLocalization?: any;
      title?: string;
      iosAppId?: string;
      settings?: any;
      androidAppId?: string;
      domain?: string;
      settingAndroid?: any;
      settingIos?: any;
      share?: boolean;
      noNotification?: boolean;
    }) {
      return axios({
        method: 'put',
        url: `/apps/${params.id}`,
        data: {
          title: params.title,
          settings: params.settings,
          ios_app_id: params.iosAppId,
          domain: params.domain,
          setting_ios: params.settingIos,
          android_app_id: params.androidAppId,
          setting_android: params.settingAndroid,
          share: params.share,
          localization: {
            ...params.appLocalization,
            languages: params.appLocalization.languages.map((item: any) => item.shortName)
          }
        }
      });
    },
    remove(params: { id: string }) {
      return axios({
        method: 'delete',
        url: `/apps/${params.id}`
      });
    }
  },
  shorts: {
    create(params: { data: any }) {
      return axios({
        method: 'post',
        url: '/shorts',
        data: params
      });
    },
    get(params: { shortId: string }) {
      return axios({
        method: 'get',
        url: `/shorts/${params.shortId}`
      });
    }
  },
  templates: {
    create(params: {
      name: string;
      description: string;
      image?: string;
      type: string;
      category: Templates.Category;
      fileId?: string;
      languages?: string[];
      settings?: any;
    }) {
      const reqParams = {
        title: {} as { [index: string]: string },
        type: params.type,
        category: params.category,
        description: {} as { [index: string]: string },
        image_url: {} as { [index: string]: string },
        active: false,
        settings: params.settings ?? {}
      };

      if (params.languages) {
        params.languages.forEach((lang: string) => {
          reqParams.title[lang] = params.name;
          reqParams.description[lang] = params.description;
          if (params.image) {
            reqParams.image_url[lang] = params.image;
          }
        });
      }

      return axios({
        method: 'post',
        url: `/group_templates`,
        data: reqParams
      });
    },
    update(params: any) {
      const reqParams = {
        title: params.title,
        type: params.type,
        category: params.category,
        description: params.description,
        image_url: params.image,
        active: params.active,
        settings: params.settings
      };

      return axios({
        method: 'put',
        url: `/group_templates/${params.templateId}`,
        data: reqParams
      });
    },
    getList() {
      return axios({
        method: 'get',
        url: '/group_templates'
      });
    },
    remove(params: { templateId: string }) {
      return axios({
        method: 'delete',
        url: `/group_templates/${params.templateId}`
      });
    }
  },
  groups: {
    create(params: {
      appId: string;
      name: string;
      image: string;
      type: GroupsType;
      fileId?: string;
      startTime?: string;
      endTime?: string;
      languages?: string[];
      settings?: any;
    }) {
      const reqParams = {
        app_id: params.appId,
        title: {},
        type: params.type,
        file_id: params.fileId || '',
        image_url: {},
        start_time: params.startTime ? `${params.startTime}` : '',
        end_time: params.endTime ? `${params.endTime}` : '',
        active: false,
        settings: params.settings ?? {}
      };

      if (params.languages) {
        params.languages.forEach((lang: string) => {
          // @ts-ignore
          reqParams.title[lang] = params.name;
          // @ts-ignore
          reqParams.image_url[lang] = params.image;
        });
      }

      return axios({
        method: 'post',
        url: `/apps/${params.appId}/groups`,
        data: reqParams
      });
    },
    update(params: any) {
      const reqParams = {
        title: params.title,
        image_url: params.image,
        file_id: params.fileId ?? '',
        start_time: params.startTime ? `${params.startTime}` : '',
        end_time: params.endTime ? `${params.endTime}` : '',
        active: params.active,
        type: params.type,
        settings: params.settings
      };

      return axios({
        method: 'put',
        url: `/apps/${params.appId}/groups/${params.groupId}`,
        data: reqParams
      });
    },
    remove(params: { appId: string; groupId: string }) {
      return axios({
        method: 'delete',
        url: `/apps/${params.appId}/groups/${params.groupId}`
      });
    },
    get(params: { appId: string; groupId: string; reqStatistic?: boolean }) {
      return axios({
        method: 'get',
        url: `/apps/${params.appId}/groups/${params.groupId}?statistic=${params.reqStatistic || false
          }`
      });
    },
    getList(params: { appId: string; dateFrom?: string; dateTo?: string; reqStatistic?: boolean }) {
      return axios({
        method: 'get',
        url: `/apps/${params.appId}/groups${params.reqStatistic ? '?statistic=true' : ''}${params.reqStatistic && params.dateFrom ? `&date_from=${params.dateFrom}` : ''
          }${params.reqStatistic && params.dateFrom ? `&date_to=${params.dateTo}` : ''}`
      });
    }
  },
  templateStories: {
    create(
      params: { templateId: string; story?: Story },
      locales: string[],
      defaultLocale: string
    ) {
      const data = {
        position: params.story?.position ?? 1,
        story_data: {},
        layer_data: params.story?.layerData ?? {
          layersGroupId: nanoid()
        }
      };

      locales.forEach((locale: string) => {
        let background = '' as any;

        if (params.story?.storyData?.[locale]?.background) {
          background = params.story?.storyData?.[locale].background;
        } else if (params.story?.background) {
          background = params.story?.background;
        }

        let widgets = [] as any;

        if (params.story?.storyData?.[locale]?.widgets?.length) {
          widgets = params.story.storyData[locale].widgets.map((widget: any) => ({
            ...widget,
            id: `W${nanoid()}`
          }));
        } else if (params.story?.widgets?.length) {
          widgets = params.story.widgets.map((widget: any) => ({
            ...widget,
            id: `W${nanoid()}`
          }));
        }

        // @ts-ignore
        data.story_data[locale] = {
          status: 'draft',
          is_hidden: false,
          start_time: DateTime.now().toISO(),
          background,
          widgets,
          is_sync_design_active: locale !== defaultLocale,
          history: {
            story_data: {
              widgets,
              background
            }
          }
        };
      });

      return axios({
        method: 'post',
        url: `/group_templates/${params.templateId}/group_template_stories`,
        data
      });
    },
    getList(params: { templateId: string }) {
      return axios({
        method: 'get',
        url: `/group_templates/${params.templateId}/group_template_stories`
      });
    },
    getStory(params: { templateId: string; storyId: string }) {
      return axios({
        method: 'get',
        url: `/group_templates/${params.templateId}/group_template_stories/${params.storyId}`
      });
    },
    updateStatuses(params: { templateId: string; status: StoryStatus; locale: string }) {
      return axios({
        method: 'post',
        url: `/group_templates/${params.templateId}/group_template_stories/update-statuses`,
        data: {
          status: params.status,
          locale: params.locale
        }
      });
    },
    remove(params: { templateId: string; storyId: string }) {
      return axios({
        method: 'delete',
        url: `/group_templates/${params.templateId}/group_template_stories/${params.storyId}`
      });
    },
    update(params: {
      templateId: string;
      story: Story;
      currentLocale: string;
      defaultLocale?: string;
      syncToLocales?: boolean;
      locales: string[];
      isStatusUpdate?: boolean;
    }) {
      const {
        templateId,
        story,
        currentLocale,
        defaultLocale,
        locales,
        syncToLocales,
        isStatusUpdate
      } = params;

      const data = {
        background: 'null',
        position: story.position,
        statistic: {},
        story_data: {} as StoryData,
        layer_data: {} as LayerData
      };

      data.story_data = { ...story.storyData };
      data.layer_data = { ...story.layerData };

      if (isStatusUpdate) {
        if (data.story_data[currentLocale].status === StoryStatus.ACTIVE) {
          data.story_data[currentLocale].widgets = story.widgets;
          data.story_data[currentLocale].background = story.background;
          data.story_data[currentLocale].history = {
            ...data.story_data[currentLocale].history,
            story_data: null,
            published_at: DateTime.now().toISO()
          };
        }
      } else if (story.storyData) {
        const widgetsIds = story.widgets.reduce(
          (acc: { [id: string]: number }, widget: WidgetObjectType) => {
            acc[widget.id] = (acc[widget.id] || 0) + 1;
            return acc;
          },
          {}
        );

        // Story design sync

        locales.forEach((locale: string) => {
          if (
            locale === currentLocale ||
            story.type === 'new' ||
            syncToLocales ||
            (data.story_data[locale].is_sync_design_active && currentLocale === defaultLocale)
          ) {
            data.story_data[locale] = {
              // @ts-ignore
              ...story.storyData[locale],
              history: {
                // @ts-ignore
                ...story.storyData[locale].history,
                story_data: {
                  // @ts-ignore
                  ...story.storyData[locale].history?.story_data,
                  widgets: story.widgets.map((widget: WidgetObjectType) => {
                    let newWidget = { ...widget } as WidgetObjectType;

                    const textSyncLimits =
                      SYNC_TO_LOCALES_TEXT_WIDGETS_LIMITS[
                      newWidget.content.type as keyof typeof SYNC_TO_LOCALES_TEXT_WIDGETS_LIMITS
                      ];

                    if (textSyncLimits) {
                      // @ts-ignore
                      const localeWidget = story.storyData[locale].widgets.find(
                        (w: WidgetObjectType) => w.id === widget.id
                      );

                      if (localeWidget) {
                        for (const key in textSyncLimits) {
                          if (Object.prototype.hasOwnProperty.call(textSyncLimits, key)) {
                            if (
                              textSyncLimits[key as keyof typeof textSyncLimits] &&
                              ((typeof textSyncLimits[key as keyof typeof textSyncLimits] !==
                                'object' &&
                                widget.content.params[key as keyof typeof widget.content.params] !==
                                localeWidget.content.params[
                                key as keyof typeof localeWidget.content.params
                                ]) ||
                                typeof textSyncLimits[key as keyof typeof textSyncLimits] ===
                                'object') &&
                              locale !== currentLocale
                            ) {
                              newWidget = {
                                ...newWidget,
                                // @ts-ignore
                                content: {
                                  ...newWidget.content,
                                  params: {
                                    ...newWidget.content.params,
                                    [key as keyof typeof newWidget.content.params]: localeWidget
                                      .content.params[
                                      key as keyof typeof localeWidget.content.params
                                    ]
                                  }
                                }
                              };
                            }
                          }
                        }
                      }
                    }

                    if (widgetsIds[newWidget.id] === 1) {
                      return newWidget;
                    }

                    return {
                      ...newWidget,
                      id: `W${nanoid()}`
                    };
                  }),
                  background: story.background
                }
              }
            };
          }
        });

        // Story design sync end
      }

      return axios({
        method: 'put',
        url: `/group_templates/${templateId}/group_template_stories/${story.id}`,
        data
      });
    }
  },
  stories: {
    create(
      params: { appId: string; groupId: string; story?: Story },
      locales: string[],
      defaultLocale: string
    ) {
      const data = {
        position: params.story?.position ?? 1,
        story_data: {},
        layer_data: params.story?.layerData ?? {
          layersGroupId: nanoid()
        }
      };

      locales.forEach((locale: string) => {
        let background = '' as any;

        if (params.story?.storyData?.[locale]?.background) {
          background = params.story?.storyData?.[locale].background;
        } else if (params.story?.background) {
          background = params.story?.background;
        }

        let widgets = [] as any;

        if (
          params.story?.storyData?.[locale]?.widgets &&
          params.story?.storyData?.[locale].widgets.length
        ) {
          widgets = params.story?.storyData?.[locale].widgets.map((widget: any) => ({
            ...widget,
            id: `W${nanoid()}`
          }));
        } else if (params.story?.widgets && params.story?.widgets.length) {
          widgets = params.story?.widgets.map((widget: any) => ({
            ...widget,
            id: `W${nanoid()}`
          }));
        }

        // @ts-ignore
        data.story_data[locale] = {
          status: 'draft',
          is_hidden: false,
          start_time: DateTime.now().toISO(),
          background,
          widgets,
          is_sync_design_active: locale !== defaultLocale,
          history: {
            story_data: {
              widgets,
              background
            }
          }
        };
      });

      return axios({
        method: 'post',
        url: `/apps/${params.appId}/groups/${params.groupId}/stories`,
        data
      });
    },
    getList(params: {
      appId: string;
      groupId: string;
      dateFrom?: string;
      dateTo?: string;
      reqStatistic?: boolean;
    }) {
      return axios({
        method: 'get',
        url: `/apps/${params.appId}/groups/${params.groupId}/stories${params.reqStatistic ? '?statistic=true' : ''
          }${params.reqStatistic && params.dateFrom ? `&date_from=${params.dateFrom}` : ''}${params.reqStatistic && params.dateFrom ? `&date_to=${params.dateTo}` : ''
          }`
      });
    },
    getStory(params: {
      appId: string;
      groupId: string;
      storyId: string;
      dateFrom?: string;
      dateTo?: string;
      reqStatistic?: boolean;
    }) {
      return axios({
        method: 'get',
        url: `/apps/${params.appId}/groups/${params.groupId}/stories/${params.storyId}?statistic=${params.reqStatistic || false
          }${params.reqStatistic && params.dateFrom ? `&date_from=${params.dateFrom}` : ''}${params.reqStatistic && params.dateFrom ? `&date_to=${params.dateTo}` : ''
          }`
      });
    },
    remove(params: { appId: string; groupId: string; storyId: string }) {
      return axios({
        method: 'delete',
        url: `/apps/${params.appId}/groups/${params.groupId}/stories/${params.storyId}`
      });
    },
    updateStatuses(params: {
      appId: string;
      groupId: string;
      status: StoryStatus;
      locale: string;
    }) {
      return axios({
        method: 'post',
        url: `/apps/${params.appId}/groups/${params.groupId}/stories/update-statuses`,
        data: {
          status: params.status,
          locale: params.locale
        }
      });
    },
    update(params: {
      appId: string;
      groupId: string;
      story: Story;
      currentLocale: string;
      defaultLocale?: string;
      syncToLocales?: boolean;
      locales: string[];
      isStatusUpdate?: boolean;
    }) {
      const {
        appId,
        groupId,
        story,
        currentLocale,
        defaultLocale,
        locales,
        syncToLocales,
        isStatusUpdate
      } = params;

      const data = {
        background: 'null',
        position: story.position,
        statistic: {},
        story_data: {} as StoryData,
        layer_data: {} as LayerData
      };

      data.story_data = { ...story.storyData };
      data.layer_data = { ...story.layerData };

      if (isStatusUpdate) {
        if (data.story_data[currentLocale].status === StoryStatus.ACTIVE) {
          data.story_data[currentLocale].widgets = story.widgets;
          data.story_data[currentLocale].background = story.background;
          data.story_data[currentLocale].history = {
            ...data.story_data[currentLocale].history,
            story_data: null,
            published_at: DateTime.now().toISO()
          };
        }
      } else if (story.storyData) {
        const widgetsIds = story.widgets.reduce(
          (acc: { [id: string]: number }, widget: WidgetObjectType) => {
            acc[widget.id] = (acc[widget.id] || 0) + 1;
            return acc;
          },
          {}
        );

        // Story design sync

        locales.forEach((locale: string) => {
          if (
            locale === currentLocale ||
            story.type === 'new' ||
            syncToLocales ||
            (data.story_data[locale].is_sync_design_active && currentLocale === defaultLocale)
          ) {
            try {
              data.story_data[locale] = {
                // @ts-ignore
                ...story.storyData[locale],
                history: {
                  // @ts-ignore
                  ...story.storyData[locale].history,
                  story_data: {
                    // @ts-ignore
                    ...story.storyData[locale].history?.story_data,
                    widgets: story.widgets.map((widget: WidgetObjectType) => {
                      let newWidget = { ...widget } as WidgetObjectType;

                      const textSyncLimits =
                        SYNC_TO_LOCALES_TEXT_WIDGETS_LIMITS[
                        newWidget.content.type as keyof typeof SYNC_TO_LOCALES_TEXT_WIDGETS_LIMITS
                        ];

                      if (textSyncLimits) {
                        // @ts-ignore
                        const localeWidget =
                          story.storyData[locale].history.story_data?.widgets.find(
                            (w: WidgetObjectType) => w.id === widget.id
                          ) ??
                          story.storyData[locale].widgets.find(
                            (w: WidgetObjectType) => w.id === widget.id
                          );

                        if (localeWidget) {
                          for (const key in textSyncLimits) {
                            if (Object.prototype.hasOwnProperty.call(textSyncLimits, key)) {
                              if (
                                textSyncLimits[key as keyof typeof textSyncLimits] &&
                                ((typeof textSyncLimits[key as keyof typeof textSyncLimits] !==
                                  'object' &&
                                  widget.content.params[
                                  key as keyof typeof widget.content.params
                                  ] !==
                                  localeWidget.content.params[
                                  key as keyof typeof localeWidget.content.params
                                  ]) ||
                                  typeof textSyncLimits[key as keyof typeof textSyncLimits] ===
                                  'object') &&
                                locale !== currentLocale
                              ) {
                                newWidget = {
                                  ...newWidget,
                                  // @ts-ignore
                                  content: {
                                    ...newWidget.content,
                                    params: {
                                      ...newWidget.content.params,
                                      [key as keyof typeof newWidget.content.params]: localeWidget
                                        .content.params[
                                        key as keyof typeof localeWidget.content.params
                                      ]
                                    }
                                  }
                                };
                              }
                            }
                          }
                        }
                      }

                      if (widgetsIds[newWidget.id] === 1) {
                        return newWidget;
                      }

                      return {
                        ...newWidget,
                        id: `W${nanoid()}`
                      };
                    }),
                    background: story.background
                  }
                }
              };
            } catch (error) {
              console.warn('error', error);
            }
          }
        });

        // Story design sync end
      }

      return axios({
        method: 'put',
        url: `/apps/${appId}/groups/${groupId}/stories/${story.id}`,
        data
      });
    },
    html2img(params: any) {
      return axios({
        method: 'post',
        url: `/static/html2img`,
        data: params
      });
    }
  },
  collabolators: {
    getList(params: { appId: string }) {
      return axios({
        method: 'get',
        url: `/apps/${params.appId}/collaborations`
      });
    },
    create(params: { appId: string; email: string; role: string }) {
      return axios({
        method: 'post',
        url: `/apps/${params.appId}/collaborations`,
        data: {
          collaborator_email: params.email,
          role: params.role
        }
      });
    },
    update(params: { appId: string; collabolatorId: string; role: string }) {
      return axios({
        method: 'put',
        url: `/apps/${params.appId}/collaborations/${params.collabolatorId}`,
        data: {
          role: params.role
        }
      });
    },
    remove(params: { appId: string; collaboratorId: string }) {
      return axios({
        method: 'delete',
        url: `/apps/${params.appId}/collaborations/${params.collaboratorId}`
      });
    },
    resend(params: { appId: string; collaboratorId: string }) {
      return axios({
        method: 'get',
        url: `/apps/${params.appId}/collaborations/${params.collaboratorId}/resend`
      });
    }
  },
  storage: {
    create(params: {
      file: Blob | null;
      onUploadProgress?: (value: number, modalProgressId?: string) => void;
      modalProgressId?: string;
    }) {
      const formData = new FormData();

      if (params.file) {
        let ext = 'png';

        // @ts-ignore
        if (params.file.name) {
          // @ts-ignore
          ext = params.file.name.split('.').pop();
        }

        formData.append('file', params.file as Blob, `${nanoid()}.${ext}`);
      }

      return axios({
        method: 'post',
        url: `/storages`,
        data: formData,
        onUploadProgress(progressEvent) {
          if (params.onUploadProgress) {
            params.onUploadProgress(
              Math.round((progressEvent.loaded / progressEvent.total) * 100),
              params.modalProgressId
            );
          }
        }
      });
    },
    remove(params: { fileId: string }) {
      return axios({
        method: 'delete',
        url: `/storages/${params.fileId}`
      });
    }
  },
  subscription: {
    сustomer() {
      return axios({
        method: 'get',
        url: '/stripe/customer'
      });
    },
    products() {
      return axios({
        method: 'get',
        url: '/stripe/products'
      });
    },
    subscriptions() {
      return axios({
        method: 'get',
        url: '/stripe/subscriptions'
      });
    },
    setFreeSubscription(params: { appId: string; nextUpgrade?: boolean }) {
      return axios({
        method: 'post',
        url: '/stripe/set_free_subscription',
        data: {
          app_id: params.appId,
          next_upgrade: params.nextUpgrade ?? false
        }
      });
    },
    prices(params: { productId: string }) {
      return axios({
        method: 'get',
        url: `/stripe/products/${params.productId}/prices`
      });
    },
    productsTrials() {
      return axios({
        method: 'get',
        url: 'stripe/products_trials'
      });
    },
    createSubscription(params: { appId: string; priceId: string }) {
      return axios({
        method: 'post',
        url: '/stripe/subscriptions',
        data: {
          app_id: params.appId,
          price_id: params.priceId
        }
      });
    },
    updateSubscriptionItem(params: {
      subscriptionId: string;
      subscriptionItemId: string;
      priceId: string;
    }) {
      return axios({
        method: 'put',
        url: `/stripe/subscriptions/${params.subscriptionId}/subscription_items/${params.subscriptionItemId}`,
        data: {
          price: params.priceId
        }
      });
    },
    subscription(params: { subscriptionId: string }) {
      return axios({
        method: 'get',
        url: `/stripe/subscriptions/${params.subscriptionId}`
      });
    },
    resumeSubscription(params: { subscriptionId: string }) {
      return axios({
        method: 'post',
        url: `/stripe/subscriptions/${params.subscriptionId}/resume`,
        data: {
          billing_cycle_anchor: 'now',
          proration_behavior: 'always_invoice'
        }
      });
    },
    cancelSubscription(params: { subscriptionId: string }) {
      return axios({
        method: 'delete',
        url: `/stripe/subscriptions/${params.subscriptionId}/cancel`
      });
    },
    pauseSubscription(params: { subscriptionId: string }) {
      return axios({
        method: 'get',
        url: `/stripe/subscriptions/${params.subscriptionId}/pause`
      });
    },
    updateSubscription(params: { priceId: string }) {
      return axios({
        method: 'put',
        url: '/stripe/subscription',
        data: {
          price_id: params.priceId
        }
      });
    },
    checkoutSession(params: { appId: string; priceId: string }) {
      return axios({
        method: 'post',
        url: '/stripe/checkout_session',
        data: {
          app_id: params.appId,
          price_id: params.priceId
        }
      });
    }
  }
};
