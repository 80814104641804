import { AppPlan } from './types';

export enum AppFeatures {
  CREATE_STORIES = 'CREATE_STORIES',
  CREATE_ONBOARDING = 'CREATE_ONBOARDING',
  CREATE_STORIES_GROUP = 'CREATE_STORIES_GROUP',
  CREATE_ONBOARDING_GROUP = 'CREATE_ONBOARDING_GROUP',
  PUBLISH_STORIES = 'PUBLISH_STORIES',
  BASE_ANALITICS = 'BASE_ANALITICS',
  EXTENDED_ANALITICS = 'EXTENDED_ANALITICS',
  DEFAULT_LOCALIZATION = 'DEFAULT_LOCALIZATION',
  ALL_LOCALIZATIONS = 'ALL_LOCALIZATIONS',
  BASE_WIDGETS = 'BASE_WIDGETS',
  INTERACTIVE_WIDGETS = 'INTERACTIVE_WIDGETS',
  DESIGN_EDITOR_MODE = 'DESIGN_EDITOR_MODE',
  LOGIC_EDITOR_MODE = 'LOGIC_EDITOR_MODE',
  TEMPLATES = 'TEMPLATES',
  COLLABORATORS = 'COLLABORATORS',
  CREATE_APP = 'CREATE_APP'
}

export enum AppLimits {
  APPS_COUNT = 'APPS_COUNT',
  STORIES_COUNT = 'STORIES_COUNT',
  COLLABORATORS_COUNT = 'COLLABORATORS_COUNT'
}

export const LimitsByPlan = {
  [AppPlan.FREE]: {
    [AppLimits.APPS_COUNT]: 1,
    [AppLimits.STORIES_COUNT]: 10,
    [AppLimits.COLLABORATORS_COUNT]: 1
  },
  [AppPlan.INDIE]: {
    [AppLimits.APPS_COUNT]: 1,
    [AppLimits.COLLABORATORS_COUNT]: 2,
    [AppLimits.STORIES_COUNT]: 0
  },
  [AppPlan.STARTUP]: {
    [AppLimits.APPS_COUNT]: 0,
    [AppLimits.COLLABORATORS_COUNT]: 0,
    [AppLimits.STORIES_COUNT]: 0
  }
};

export const AccesibleFeaturesByPlan = {
  [AppPlan.FREE]: {
    [AppFeatures.CREATE_STORIES]: true,
    [AppFeatures.CREATE_ONBOARDING]: true,
    [AppFeatures.BASE_ANALITICS]: true,
    [AppFeatures.PUBLISH_STORIES]: true,
    [AppFeatures.EXTENDED_ANALITICS]: true,
    [AppFeatures.CREATE_STORIES_GROUP]: true,
    [AppFeatures.CREATE_ONBOARDING_GROUP]: true,
    [AppFeatures.DEFAULT_LOCALIZATION]: true,
    [AppFeatures.ALL_LOCALIZATIONS]: true,
    [AppFeatures.BASE_WIDGETS]: true,
    [AppFeatures.INTERACTIVE_WIDGETS]: true,
    [AppFeatures.DESIGN_EDITOR_MODE]: true,
    [AppFeatures.LOGIC_EDITOR_MODE]: true,
    [AppFeatures.TEMPLATES]: true,
    [AppFeatures.COLLABORATORS]: false,
    [AppFeatures.CREATE_APP]: false
  },
  [AppPlan.INDIE]: {
    [AppFeatures.CREATE_STORIES]: true,
    [AppFeatures.CREATE_ONBOARDING]: true,
    [AppFeatures.BASE_ANALITICS]: true,
    [AppFeatures.PUBLISH_STORIES]: true,
    [AppFeatures.CREATE_STORIES_GROUP]: true,
    [AppFeatures.CREATE_ONBOARDING_GROUP]: true,
    [AppFeatures.EXTENDED_ANALITICS]: true,
    [AppFeatures.DEFAULT_LOCALIZATION]: true,
    [AppFeatures.ALL_LOCALIZATIONS]: true,
    [AppFeatures.BASE_WIDGETS]: true,
    [AppFeatures.INTERACTIVE_WIDGETS]: true,
    [AppFeatures.DESIGN_EDITOR_MODE]: true,
    [AppFeatures.LOGIC_EDITOR_MODE]: true,
    [AppFeatures.TEMPLATES]: true,
    [AppFeatures.COLLABORATORS]: true,
    [AppFeatures.CREATE_APP]: true
  },
  [AppPlan.STARTUP]: {
    [AppFeatures.CREATE_STORIES]: true,
    [AppFeatures.CREATE_ONBOARDING]: true,
    [AppFeatures.BASE_ANALITICS]: true,
    [AppFeatures.PUBLISH_STORIES]: true,
    [AppFeatures.CREATE_STORIES_GROUP]: true,
    [AppFeatures.CREATE_ONBOARDING_GROUP]: true,
    [AppFeatures.EXTENDED_ANALITICS]: true,
    [AppFeatures.DEFAULT_LOCALIZATION]: true,
    [AppFeatures.ALL_LOCALIZATIONS]: true,
    [AppFeatures.BASE_WIDGETS]: true,
    [AppFeatures.INTERACTIVE_WIDGETS]: true,
    [AppFeatures.DESIGN_EDITOR_MODE]: true,
    [AppFeatures.LOGIC_EDITOR_MODE]: true,
    [AppFeatures.TEMPLATES]: true,
    [AppFeatures.COLLABORATORS]: true,
    [AppFeatures.CREATE_APP]: true
  }
};
