import React from 'react';
import block from 'bem-cn';
import './Checkbox.scss';

const b = block('Checkbox');

interface Props {
  label: string | React.ReactNode;
  name: string;
  value: boolean;
  onChange?(): void;
}

export const Checkbox: React.FunctionComponent<Props> = (props) => {
  const { label, name, value, onChange } = props;

  return (
    <label className={b()}>
      <input
        checked={value}
        className={b('checkbox')}
        name={name}
        type="checkbox"
        onChange={onChange}
      />
      <div className={b('customCheckbox', { checked: value })}>
        {value && (
          <svg
            fill="none"
            height="9"
            viewBox="0 0 12 9"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.20711 4.29289C1.81658 3.90237 1.18342 3.90237 0.792893 4.29289C0.402369 4.68342 0.402369 5.31658 0.792893 5.70711L2.20711 4.29289ZM4.17579 7.67579L4.8829 6.96868H4.8829L4.17579 7.67579ZM5.6893 7.56005L4.88289 6.96868V6.96868L5.6893 7.56005ZM11.3064 1.59136C11.633 1.146 11.5367 0.520196 11.0914 0.193595C10.646 -0.133006 10.0202 -0.0367289 9.69359 0.408636L11.3064 1.59136ZM0.792893 5.70711L3.46868 8.3829L4.8829 6.96868L2.20711 4.29289L0.792893 5.70711ZM6.49571 8.15141L11.3064 1.59136L9.69359 0.408636L4.88289 6.96868L6.49571 8.15141ZM3.46868 8.3829C4.33543 9.24965 5.77083 9.13988 6.49571 8.15141L4.88289 6.96868L4.8829 6.96868L3.46868 8.3829Z"
              fill="#FF00D0"
            />
          </svg>
        )}
      </div>
      <div className={b('label')}>{label}</div>
    </label>
  );
};
