import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { IconActionsFresh } from '@components/Icon';
import { useVisible } from '@hooks';

import './LocaleItem.scss';

const b = block('LocaleItem');

export type LocaleType = {
  name: string;
  icon: string;
  shortName: string;
};

interface Props {
  localeItem: LocaleType;
  isDefault: boolean;
  isDeleteAvailable: boolean;
  onDeleteLocale: (localeShortName: string) => void;
  onMakeDefault: (localeShortName: string) => void;
}

export const LocaleItem: React.FC<Props> = ({
  localeItem,
  isDefault,
  isDeleteAvailable,
  onMakeDefault,
  onDeleteLocale
}) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('icon')} dangerouslySetInnerHTML={{ __html: localeItem.icon }} />
      <p className={b('title')}>{localeItem.name}</p>
      {isDefault && <div className={b('defaultLabel')}> {t('user.default')}</div>}
      {isDeleteAvailable && (
        <div className={b('actionsBtnWrapper')}>
          <button
            className={b('actionsBtn')}
            onClick={(e) => {
              e.preventDefault();
              setIsVisible(true);
            }}
          >
            <IconActionsFresh />
          </button>

          {isVisible && (
            <div className={b('actionsList')} ref={ref}>
              <a
                className={b('acitonsListItem')}
                role="button"
                tabIndex={0}
                onClick={() => onMakeDefault(localeItem.shortName)}
                onKeyDown={() => onMakeDefault(localeItem.shortName)}
              >
                {t('user.makeDefault')}
              </a>

              <a
                className={b('acitonsListItem')}
                role="button"
                tabIndex={0}
                onClick={() => onDeleteLocale(localeItem.shortName)}
                onKeyDown={() => onDeleteLocale(localeItem.shortName)}
              >
                {t('user.delete')}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
