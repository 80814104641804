import React, { useCallback, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useHistory, useParams } from 'react-router-dom';
import {
  StoriesTemplatesItemContainer,
  Story,
  Templates,
  templateStoriesSlice,
  useFetchTemplates
} from '@features';
import { StoriesTemplatesCatalogContainer } from '@features/storyTemplates/containers/StoriesTemplatesCatalogContainer';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { useIsAdmin } from '@hooks';
import { getActiveStoriesFlattenedArr } from '@utils';
import './StoryTemplatesRoute.scss';

const b = block('StoryTemplatesRoute');

export const StoryTemplatesRoute: React.FC = () => {
  const { category, appId, templateId } = useParams<{
    category: Templates.Category;
    appId?: string;
    templateId?: string;
  }>();

  const history = useHistory();
  const isAdmin = useIsAdmin();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!category && appId) {
      history.push(`/dashboard/${appId}/templates/stories`);
    }
  }, [history, category, appId]);

  const templateStories = useSelector((store: RootState) => store.templateStories);
  const selectedStoryLayers = templateStories.selectedStoryLayers;
  const allStoriesArr = Object.values(templateStories.stories);
  const templates = useSelector((store: RootState) => store.templates);
  const currentTemplate = templates.templates.find((template) => template.id === templateId);

  const selectedStories = useMemo(() => {
    const activeStoriesArr = getActiveStoriesFlattenedArr(templateStories.stories);

    return activeStoriesArr.filter((story) =>
      selectedStoryLayers.find((layer) => layer.id === story.layerData.layersGroupId)
    );
  }, [selectedStoryLayers, templateStories.stories]);

  const chooseStory = useCallback(
    (story: Story) => {
      if (selectedStoryLayers.find((layer) => layer.id === story.layerData.layersGroupId)) {
        dispatch(templateStoriesSlice.actions.removeSelectedStory(story.layerData.layersGroupId));
      } else {
        const selectedLayer = templateStories.stories[story.layerData.layersGroupId];

        dispatch(templateStoriesSlice.actions.addSelectedStory({ layer: selectedLayer, category }));
      }
    },
    [category, dispatch, selectedStoryLayers, templateStories.stories]
  );

  const chooseAllStories = useCallback(() => {
    if (selectedStoryLayers.length) {
      dispatch(templateStoriesSlice.actions.setSelectedStories({ layers: [], category }));
    } else if (allStoriesArr.length) {
      dispatch(
        templateStoriesSlice.actions.setSelectedStories({ layers: allStoriesArr, category })
      );
    }
  }, [allStoriesArr, category, dispatch, selectedStoryLayers.length]);

  const handleChooseAllStories = useCallback(
    (isAllChosen: boolean) => {
      if (currentTemplate?.settings?.scoreResultLayersGroupId && isAllChosen) {
        dispatch(templateStoriesSlice.actions.setSelectedGroupSettings(currentTemplate?.settings));
      } else {
        dispatch(templateStoriesSlice.actions.setSelectedGroupSettings(null));
      }
    },
    [currentTemplate?.settings, dispatch]
  );

  useFetchTemplates({
    category,
    isWithStories: true,
    isOnlyActive: !isAdmin
  });

  return (
    <div className={b()}>
      {!templateId ? (
        <StoriesTemplatesCatalogContainer category={category} />
      ) : (
        <StoriesTemplatesItemContainer
          category={category}
          chosenStories={selectedStories}
          templateId={templateId}
          onAfterChooseAllStories={handleChooseAllStories}
          onChooseAllStories={chooseAllStories}
          onChooseStory={chooseStory}
        />
      )}
    </div>
  );
};
