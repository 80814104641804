import { useWindowSize } from '@react-hook/window-size';
import { useEffect, useState } from 'react';

// 3205

const DEFAULT_WINDOW_HEIGHT = 1067;

type ParamsType = {
  storyWidth?: number;
  presetWidth?: number;
};

export const useCardScale = (params: ParamsType = {}) => {
  const [, height] = useWindowSize();
  const [scale, setScale] = useState(1);
  const { storyWidth, presetWidth } = params ?? {};

  useEffect(() => {
    if (storyWidth && presetWidth) {
      setScale(storyWidth / presetWidth);
    } else {
      setScale(height / DEFAULT_WINDOW_HEIGHT);
    }
  }, [storyWidth, presetWidth, height]);

  return scale;
};
