import {
  fetchUpdateGroup,
  fetchUpdateOnboarding,
  GroupsType,
  StoriesGroup,
  fetchUpdateTemplate,
  StoriesTemplateBase
} from '@features';
import { RootState, useAppDispatch } from '@store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InteractiveWidgets } from '../consts';
import { useCurrentStoriesType } from './useCurrentStoriesType';

interface LogicModeOffParams {
  appId?: string;
  currentGroup?: StoriesGroup | StoriesTemplateBase;
  selectedLayersGroupId: string | null;
  selectedStoryId: string | null;
  type: GroupsType;
}

export const useLogicModeOff = ({
  appId,
  currentGroup,
  selectedLayersGroupId,
  selectedStoryId,
  type
}: LogicModeOffParams) => {
  const dispatch = useAppDispatch();

  const currentStoriesType = useCurrentStoriesType(type);
  const stories = useSelector((store: RootState) => store[currentStoriesType].stories);
  const selectedWidgetIds = useSelector(
    (store: RootState) => store[currentStoriesType].editor.selectedWidgetIds
  );
  const widgets = Object.values(stories)
    .map((story) => Object.values(story.layers))
    .flat()
    .map((layer) => layer.widgets)
    .flat();

  const widgetsLength = widgets.length;

  useEffect(() => {
    if (
      selectedLayersGroupId &&
      selectedStoryId &&
      currentGroup?.settings?.scoreResultLayersGroupId
    ) {
      const leftWidgetsTypes = widgets
        .filter(({ id }) => !selectedWidgetIds.includes(id))
        .map(({ content }) => content.type);

      if (!InteractiveWidgets.some((widgetType) => leftWidgetsTypes.includes(widgetType))) {
        if (currentGroup.type === GroupsType.TEMPLATE) {
          const templateParams = {
            templateId: currentGroup.id,
            active: currentGroup.active,
            title: currentGroup.title,
            // @ts-ignore
            description: currentGroup.description,
            type: currentGroup.type,
            // @ts-ignore
            category: currentGroup.category,
            noNotification: true,
            settings: {
              ...currentGroup.settings,
              scoreResultLayersGroupId: null
            }
          };

          dispatch(fetchUpdateTemplate(templateParams));
          return;
        }

        const params = {
          appId: appId ?? '',
          templateId: currentGroup.id,
          groupId: currentGroup.id,
          active: currentGroup.active,
          title: currentGroup.title,
          // @ts-ignore
          startTime: currentGroup.startDate,
          // @ts-ignore
          endTime: currentGroup.endDate,
          noNotification: true,
          settings: {
            ...currentGroup.settings,
            scoreResultLayersGroupId: null
          }
        };

        if (currentGroup.type === GroupsType.ONBOARDING) {
          dispatch(fetchUpdateOnboarding(params));
        } else if (currentGroup.type === GroupsType.GROUP && params.appId) {
          dispatch(fetchUpdateGroup(params));
        }
      }
    }
  }, [widgetsLength]);
};
