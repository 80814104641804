import React from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import { SwitchControl } from '@components';

import './SwitchGroup.scss';

interface Props {
  label: string | React.ReactChild;
  className?: string;
  labelClassName?: string;
  onToggle(): void;
  isChecked: boolean;
  isDisabled?: boolean;
}

export const SwitchGroup: React.FunctionComponent<Props> = ({
  label,
  isDisabled,
  className,
  labelClassName,
  onToggle,
  isChecked
}) => {
  const b = block('SwitchGroup');

  return (
    <div className={cn(b().toString(), className?.toString())}>
      <span className={cn(b('label').toString(), labelClassName?.toString())}>{label}</span>
      <SwitchControl isChecked={isChecked} isDisabled={isDisabled} onToggle={onToggle} />
    </div>
  );
};
