import React, { useRef } from 'react';
import block from 'bem-cn';
import { Icon } from '@components';
import { IconLogoCircle } from '@components/Icon';
import './SettingsUploadImage.scss';

const b = block('SettingsUploadImage');

interface Props {
  image?: string | null;
  onChange(value: FileList): void;
}

export const SettingsUploadImage: React.FC<Props> = ({ onChange, image }) => {
  const inputImgRef = useRef<HTMLInputElement | null>(null);

  const handleInputChange = (e: any) => {
    if (inputImgRef.current) {
      const files = inputImgRef.current.files;

      if (files && files.length) {
        onChange(files);

        inputImgRef.current.value = '';
      }
    }
  };

  const handleUploadClick = () => {
    if (inputImgRef.current) {
      inputImgRef.current.click();
    }
  };

  return (
    <>
      <button className={b('uploadBtn')} onClick={handleUploadClick}>
        <div className={b('uploadImgContainer')}>
          {image ? (
            <img alt="" className={b('uploadImg')} src={image} />
          ) : (
            <IconLogoCircle className={b('emptyImuploadImgIcon').toString()} />
          )}
          <Icon className={b('iconEdit').toString()} name="upload" variant="editor" />
        </div>

        <span className={b('uploadText')}>Upload</span>
      </button>

      <input
        accept="image/jpeg,image/png,image/gif,image/webp"
        hidden
        ref={inputImgRef}
        type="file"
        onChange={handleInputChange}
      />
    </>
  );
};
