import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  ColorPicker,
  DropIcon,
  getChangedOpacityColor,
  getOpacityFromColor,
  SettingsBox
} from '@modules';
import {
  SettingsTextarea,
  ControlGroup,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsAnswers,
  EmojiItemType,
  SettingsAnswersType,
  SettingsFontSelector,
  FONT_STYLES,
  SettingsSelect,
  GroupsType,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features';
import { useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import { EmojiData } from 'emoji-mart';
import { nanoid } from 'nanoid';
import {
  BackgroundColorType,
  QuizOneAnswerWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { Icon, SwitchGroup } from '@components';

interface QuizOneAnswerWidgetSettingsProps {
  params: QuizOneAnswerWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
  onChangePosition(params: WidgetPositionType): void;
  onChange(params: QuizOneAnswerWidgetParamsType): void;
}

export const QuizOneAnswerWidgetSettings: React.FC<QuizOneAnswerWidgetSettingsProps> = (props) => {
  const { params, position, positionLimits, onChange, onChangeAlign, onChangePosition } = props;

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const handleChangeAnswer = (id: string) => (event: any) => {
    onChange({
      ...params,
      answers: params.answers.map((answer) =>
        answer.id === id ? { ...answer, title: event.target.value } : answer
      )
    });
  };

  const [isEmojiChangeOpen, setEmojiChangeOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleChangeText = (value: string) => {
    onChange({
      ...params,
      title: value
    });
  };

  const handleAddAnswer = () => {
    onChange({
      ...params,
      answers: [
        ...params.answers,
        {
          id: nanoid(),
          title: `Answer ${params.answers.length + 1}`,
          emoji: { name: 'smile', unicode: '1f604' },
          score: {
            letter: 'A',
            points: 0
          }
        }
      ]
    });
  };

  const handleChangeTitleHide = () => {
    onChange({
      ...params,
      isTitleHidden: !params.isTitleHidden
    });
  };

  const handleRemoveAnswer = (id: string) => () => {
    if (params.answers.length > 1) {
      onChange({
        ...params,
        answers: params.answers.filter((answer) => answer.id !== id)
      });
    }
  };

  const handleChangePosition = (field: string) => (value: any) => {
    if (positionLimits.keepRatio && positionLimits.ratioIndex) {
      if (field === 'width') {
        onChangePosition({
          ...position,
          width: value,
          realWidth: value,
          height: Math.round(value / positionLimits.ratioIndex),
          realHeight: Math.round(value / positionLimits.ratioIndex)
        });
      } else if (field === 'height') {
        onChangePosition({
          ...position,
          width: Math.round(value * positionLimits.ratioIndex),
          height: value,
          realHeight: value,
          realWidth: Math.round(value * positionLimits.ratioIndex)
        });
      }
    } else {
      onChangePosition({
        ...position,
        [field]: value
      });
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(currentStoriesSlice.actions.setIsPickerOpen(isEmojiChangeOpen));
  }, [isEmojiChangeOpen, dispatch]);

  const handleChangeEmoji = (i: number) => (newEmoji: EmojiData) => {
    onChange({
      ...params,
      answers: params.answers.map((answer, index) => {
        if (index === i) {
          return {
            ...answer,
            // @ts-ignore
            emoji: { name: newEmoji.id, unicode: newEmoji.unified } as EmojiItemType
          };
        }
        return answer;
      })
    });
  };

  const handleDropChangeOpen = useCallback((isOpen: boolean) => {
    setEmojiChangeOpen(isOpen);
  }, []);

  // TEXT STYLES

  const handleChangeTextStyle = useCallback(
    (fieldType: 'titleFont' | 'answersFont', fieldName, value) => {
      onChange({ ...params, [fieldType]: { ...params[fieldType], [fieldName]: value } });
    },
    [params, onChange]
  );

  const handleChangeTextOpacity = (fieldType: 'titleFont' | 'answersFont') => (value: number) => {
    handleChangeTextStyle(fieldType, 'fontColor', {
      type: 'color',
      value: getChangedOpacityColor(params[fieldType].fontColor.value as string, value)
    });
  };

  const titleFontOpacity = useMemo(() => {
    if (params.titleFont.fontColor.type === 'color') {
      return getOpacityFromColor(params.titleFont.fontColor.value);
    }

    return 100;
  }, [params.titleFont.fontColor]);

  const answerFontOpacity = useMemo(() => {
    if (params.answersFont.fontColor.type === 'color') {
      return getOpacityFromColor(params.answersFont.fontColor.value);
    }

    return 100;
  }, [params.answersFont.fontColor]);

  // END TEXT STYLES

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked={position.isHeightLocked}>
            <SettingsInput
              max={positionLimits.maxWidth}
              min={positionLimits.minWidth}
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              postfix="px"
              prefix="H"
              type="number"
              value={position.realHeight}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.title')}>
        <SettingsTextarea isMarginBottom value={params.title} onChange={handleChangeText} />

        <SwitchGroup
          isChecked={params.isTitleHidden}
          label={t('editor.hideTitle')}
          onToggle={handleChangeTitleHide}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.answers')}>
        <SettingsAnswers
          answers={params.answers}
          handleAddAnswer={handleAddAnswer}
          handleChangeAnswer={handleChangeAnswer}
          handleChangeEmoji={handleChangeEmoji}
          handleDropChangeOpen={handleDropChangeOpen}
          handleRemoveAnswer={handleRemoveAnswer}
          isInputDisabled={false}
          leftChildType={SettingsAnswersType.EMOJI}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.titleStyle')}>
        <SettingsBox.Field>
          <SettingsFontSelector
            value={params.titleFont.fontFamily}
            onChange={(value) => handleChangeTextStyle('titleFont', 'fontFamily', value)}
          />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsSelect
              options={FONT_STYLES}
              value={params.titleFont.fontParams}
              onChange={(value) => handleChangeTextStyle('titleFont', 'fontParams', value)}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
              value={params.titleFont.fontColor}
              onChange={(value) => handleChangeTextStyle('titleFont', 'fontColor', value)}
            />
            <SettingsInput
              icon={<DropIcon />}
              max={100}
              type="number"
              value={titleFontOpacity}
              onChange={handleChangeTextOpacity('titleFont')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.answerStyle')}>
        <SettingsBox.Field>
          <SettingsFontSelector
            value={params.answersFont.fontFamily}
            onChange={(value) => handleChangeTextStyle('answersFont', 'fontFamily', value)}
          />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsSelect
              options={FONT_STYLES}
              value={params.answersFont.fontParams}
              onChange={(value) => handleChangeTextStyle('answersFont', 'fontParams', value)}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
              value={params.answersFont.fontColor}
              onChange={(value) => handleChangeTextStyle('answersFont', 'fontColor', value)}
            />
            <SettingsInput
              icon={<DropIcon />}
              max={100}
              type="number"
              value={answerFontOpacity}
              onChange={handleChangeTextOpacity('answersFont')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>
    </>
  );
};
