import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { Templates } from '@features';
import { usePrevious } from '@hooks';
import { fetchTemplates } from '../templatesSlice';

interface UseFetchTemplatesProps {
  category?: Templates.Category;
  stopFetching?: boolean;
  isWithStories?: boolean;
  isOnlyActive?: boolean;
}

export const useFetchTemplates = ({
  category,
  stopFetching,
  isWithStories,
  isOnlyActive
}: UseFetchTemplatesProps) => {
  const dispatch = useAppDispatch();
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);
  const prevLocale = usePrevious(currentLocale);
  const templatesStatus = useSelector((state: RootState) => state.templates.status);
  const appStatus = useSelector((store: RootState) => store.appManager.status);

  useEffect(() => {
    if (
      category &&
      currentLocale &&
      ((templatesStatus !== 'pending' && appStatus === 'loaded' && !stopFetching) ||
        (currentLocale && prevLocale && currentLocale !== prevLocale))
    ) {
      dispatch(fetchTemplates({ category, isOnlyActive }));
    }
  }, [dispatch, currentLocale, prevLocale, stopFetching, category, appStatus]);
};
