import { GoogleFont } from '@features';

export const getFontsLink = (fonts: GoogleFont[]) => {
  const fontsLink = fonts
    .map((font, index) => {
      let string = '';

      string += `${font.family}:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700`;

      if (index !== fonts.length - 1) {
        string += '&family=';
      }

      return string;
    })
    .join('');

  const updateLink = `https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=${fontsLink}`;

  return updateLink;
};
