import React from 'react';
import block from 'bem-cn';
import { AppPlan } from '@features/user/types';
import { useTranslation } from 'react-i18next';
import { AppLimits, LimitsByPlan } from '@features';
import { useGetUserSubscription } from '@hooks';
import { IconInfoSuccess, IconWarningCircleFresh } from '@components/Icon';
import IconCircleFire from '@components/Icon/icons/icon-circle-fire';
import './HeaderSubscription.scss';

const b = block('HeaderSubscription');

export const HeaderSubscription: React.FC = () => {
  const userSubcription = useGetUserSubscription();
  const { t } = useTranslation();

  const getIcon = () => {
    if (userSubcription.plan === AppPlan.STARTUP) {
      return <IconCircleFire />;
    }

    if (userSubcription.plan === AppPlan.FREE && userSubcription.storiesCount > 5) {
      return <IconWarningCircleFresh />;
    }

    return <IconInfoSuccess />;
  };

  return (
    <div className={b()}>
      {getIcon()}

      <div className={b('subscriptionTextContainer')}>
        <span className={b('subscriptionText')}>{`${userSubcription.plan === AppPlan.FREE
            ? `${userSubcription.storiesCount} / ${LimitsByPlan[AppPlan.FREE][AppLimits.STORIES_COUNT]
            }`
            : '∞'
          }  ${t('user.stories')}`}</span>
        <span className={b('subscriptionLimits')}>
          {userSubcription.plan}:{' '}
          {userSubcription.plan === AppPlan.STARTUP || userSubcription.plan === AppPlan.INDIE
            ? `∞ ${t('user.projects')}`
            : `1 ${t('user.project')}`}
        </span>
      </div>
    </div>
  );
};
