import React from 'react';
import PropTypes from 'prop-types';

const IconCopy = ({ className, color }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    stroke="#AFAFAF"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 12H3.333C2.73525 12 2.25 11.5147 2.25 10.917V3.333C2.25 2.73525 2.73525 2.25 3.333 2.25H10.9163C11.5148 2.25 12 2.73525 12 3.333V3.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M14.667 15.75H7.083C6.48525 15.75 6 15.2647 6 14.667V7.083C6 6.48525 6.48525 6 7.083 6H14.6663C15.2648 6 15.75 6.48525 15.75 7.083V14.6663C15.75 15.2648 15.2647 15.75 14.667 15.75Z"
      fillRule="evenodd"
      strokeWidth="1.5"
    />
  </svg>
);

IconCopy.propTypes = {
  className: PropTypes.string
};

IconCopy.defaultProps = {
  className: ''
};

export default IconCopy;
