import React, { useCallback, useMemo, useState } from 'react';
import block from 'bem-cn';
import {
  STORY_WIDTH,
  StoriesTemplateBase,
  StoryLayers,
  StoryPreview,
  Templates,
  useGetTemplateStories
} from '@features';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomGroupControl, GroupType } from '@storysdk/react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { FreshButton, FreshCheckbox } from '@components/_fresh';
import { IconPlay } from '@components';
import { getActiveStoriesFlattenedArr, getInitStoryParams } from '@utils';
import { useIsMobile } from '@hooks';
import './StoriesTemplateCard.scss';

const b = block('StoriesTemplateCard');

interface StoriesTemplateCardProps {
  template?: StoriesTemplateBase;
  chosenStoryIds?: string[];
  isPreviewAvailable?: boolean;
  isChoosen?: boolean;
  isLoading?: boolean;
  isAdmin?: boolean;
  onEditClick?: (id: string) => void;
  onPublish?: (id: string) => void;
  onUnpublish?: (id: string) => void;
  onRemove?: (id: string) => void;
  onChooseTemplate?: (templateId: string, stories: StoryLayers) => void;
  onSelectTemplate?: (templateId: string) => void;
}

const CARD_WIDTH = 96;
const CARD_WIDTH_MOBILE = 83;
const PREVIEW_STORIES_COUNT = 3;

export const StoriesTemplateCard: React.FC<StoriesTemplateCardProps> = ({
  template,
  isChoosen,
  isLoading,
  isAdmin,
  isPreviewAvailable,
  chosenStoryIds,
  onChooseTemplate,
  onSelectTemplate,
  onPublish,
  onUnpublish,
  onRemove,
  onEditClick
}) => {
  const templateStories = useGetTemplateStories(template?.id);

  const isMobile = useIsMobile();

  const scaleIndex = (isMobile ? CARD_WIDTH_MOBILE : CARD_WIDTH) / STORY_WIDTH;
  const { t } = useTranslation();

  const currentApp = useSelector((state: RootState) => state.appManager.current);
  const currentAppId = currentApp?.id;

  const history = useHistory();

  const onClick = useCallback(() => {
    if (!template) return;

    if (onSelectTemplate) {
      onSelectTemplate(template.id);
    } else if (currentAppId) {
      history.push(
        `/dashboard/${currentAppId}/templates/${template.category === Templates.Category.STORIES ? 'stories' : 'onboarding'
        }/${template.id}`
      );
    }
  }, [history, onSelectTemplate, template?.id, template?.category, currentAppId]);

  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

  const activeStories = useMemo(() => getActiveStoriesFlattenedArr(templateStories ?? {}), [
    templateStories
  ]);

  const storiesFromTemplate = useMemo(
    () =>
      activeStories
        .slice(0, PREVIEW_STORIES_COUNT)
        .sort((storyA, storyB) => storyA.position - storyB.position),
    [activeStories]
  );

  const storiesToRender = useMemo(
    () =>
      storiesFromTemplate?.concat(
        Array.from({ length: PREVIEW_STORIES_COUNT - storiesFromTemplate.length }).map(() =>
          getInitStoryParams({
            isGreyBackground: true
          })
        )
      ),
    [storiesFromTemplate]
  );

  if (isLoading || !templateStories || !template) {
    return (
      <div className={b()}>
        <div className={b('preview')}>
          {Array.from({ length: 3 }).map((_, index) => (
            <div className={b('previewItem')} key={`story-load-card-${index}`}>
              <Skeleton height="100%" />
            </div>
          ))}
        </div>

        <Skeleton className={b('title').toString()} height={20} />
        <Skeleton className={b('desc').toString()} height={20} />
      </div>
    );
  }

  return (
    <div className={b({ choosen: isChoosen })}>
      <div className={b('preview')}>
        {storiesToRender?.map((story) => (
          <div className={b('previewItem')} key={`story-preveiw-card-${story.id}`}>
            {chosenStoryIds?.includes(story.id) && (
              <FreshCheckbox
                className={b('previewCheckbox').toString()}
                name={`choose-checkbox-${story.id}`}
                type="round"
                value
              />
            )}

            <StoryPreview
              isOnboarding={template.category === Templates.Category.ONBOARDING}
              scaleIndex={scaleIndex}
              story={story}
              templateVariant
            />
          </div>
        ))}
      </div>

      <a className={b('title')} onClick={onClick}>
        {template.title}
      </a>
      {template.description && <p className={b('desc')}>{template.description}</p>}

      {isPreviewAvailable && onChooseTemplate && activeStories.length > 0 && (
        <div className={b('btnContainer')}>
          <FreshButton
            className={b('btn')}
            size="md"
            text={t('dashboard.templates.chooseAll')}
            onClick={() => onChooseTemplate && onChooseTemplate(template.id, templateStories)}
          />
          <CustomGroupControl
            group={{
              id: template?.id,
              imageUrl: `${process.env.REACT_APP_SITE_URL}/images/group-default.jpg`,
              title: template.title,
              type:
                template.category === Templates.Category.STORIES
                  ? GroupType.GROUP
                  : GroupType.ONBOARDING,
              settings: {},
              stories: [...activeStories]
                .sort((storyA, storyB) => storyA.position - storyB.position)
                .map((item) => ({
                  ...item,
                  storyData: item.widgets,
                  positionIndex: item.position
                }))
            }}
            handleCloseModal={() => setIsPreviewOpen(false)}
            handleNextGroup={() => undefined}
            handlePrevGroup={() => undefined}
            isCacheDisabled
            isFirstGroup
            isForceCloseAvailable
            isLastGroup
            isShowing={isPreviewOpen}
            startStoryId={undefined}
          >
            <FreshButton
              className={b('btn')}
              color="dark-grey"
              leftIcon={<IconPlay className={b('btnIcon').toString()} />}
              size="md"
              text={t('editor.header.preview')}
              onClick={() => setIsPreviewOpen(true)}
            />
          </CustomGroupControl>
        </div>
      )}

      {isAdmin && (
        <div className={b('actionsContainer')}>
          {!template.active && onPublish && (
            <FreshButton
              color="transparent-grey"
              size="sm"
              text={t('dashboard.stories.publish')}
              onClick={() => onPublish(template.id)}
            />
          )}

          {template.active && onUnpublish && (
            <FreshButton
              color="transparent-grey"
              size="sm"
              text={t('dashboard.stories.removeToDraft')}
              onClick={() => onUnpublish(template.id)}
            />
          )}

          {onEditClick && (
            <FreshButton
              color="transparent-grey"
              size="sm"
              text={t('dashboard.stories.edit')}
              onClick={() => onEditClick(template.id)}
            />
          )}

          {onRemove && (
            <FreshButton
              color="transparent-grey"
              size="sm"
              text={t('dashboard.stories.delete')}
              onClick={() => onRemove(template.id)}
            />
          )}
        </div>
      )}
    </div>
  );
};
