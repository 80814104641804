import React from 'react';
import PropTypes from 'prop-types';

const IconApple = ({ className }) => (
  <svg
    className={className}
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.467 13.615a8.715 8.715 0 01-.862 1.55c-.453.645-.824 1.092-1.11 1.34-.443.408-.918.617-1.426.629-.365 0-.806-.104-1.318-.315-.514-.21-.987-.313-1.419-.313-.453 0-.939.104-1.458.313-.521.211-.94.32-1.261.332-.488.02-.974-.194-1.459-.645-.31-.27-.697-.733-1.16-1.39-.499-.7-.908-1.512-1.228-2.438-.344-1-.516-1.969-.516-2.906 0-1.074.232-2 .697-2.777a4.088 4.088 0 011.46-1.477 3.927 3.927 0 011.973-.556c.387 0 .895.12 1.527.355.629.236 1.033.356 1.21.356.133 0 .582-.14 1.343-.42.72-.258 1.327-.366 1.824-.323 1.349.108 2.362.64 3.036 1.598-1.206.73-1.803 1.754-1.791 3.067.01 1.022.382 1.873 1.111 2.549.33.313.7.556 1.11.728-.089.258-.183.506-.283.743zm-3.093-12.17c0 .802-.292 1.55-.876 2.243-.704.824-1.556 1.3-2.48 1.225a2.496 2.496 0 01-.019-.304c0-.77.335-1.593.93-2.266a3.58 3.58 0 011.133-.851c.457-.223.89-.346 1.296-.367.012.107.016.214.016.32z" />
  </svg>
);

IconApple.propTypes = {
  className: PropTypes.string
};

IconApple.defaultProps = {
  className: ''
};

export default IconApple;
