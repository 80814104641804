import React from 'react';
import axios from 'axios';
import { FormSubmitHandler } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { SignUpForm } from '@forms';
import { userSlice, informSlice, MessageTypes } from '@features';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { AuthLayout } from '@layouts';
import { Auth } from '@utils';
import { useAppDispatch } from '../../../store';
import { FooterLinks } from '../_components';

export const SignUpPage: React.FunctionComponent = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  if (localStorage.getItem('auth')) {
    history.push('/dashboard');
  }

  const handleSubmit: FormSubmitHandler = (data: any) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/sign-up`, {
      method: 'post',
      body: JSON.stringify({
        ...data,
        password_confirmation: data.password
      })
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          dispatch(userSlice.actions.setEmail(data.email));

          Auth.setStorage({
            ...response,
            user: { ...response.user, email: data.email }
          });

          axios.defaults.headers.common = { Authorization: `Bearer ${response.access_token}` };
          history.push('/dashboard');

          ReactGA.event({
            category: 'User',
            action: 'sign_up',
            label: response.user.id
          });
        } else if (response.error.includes('duplicate key error collection')) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: t('errors.userExists')
            })
          );
        } else {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: t('errors.somethingWentWrong')
            })
          );
        }
      });
  };

  return (
    <AuthLayout
      footer={
        <FooterLinks
          links={[
            {
              title: t('auth.signIn'),
              href: '/'
            },
            {
              title: t('auth.help'),
              href: 'https://docs.storysdk.com/',
              isExternal: true
            }
          ]}
        />
      }
      template="form"
      title={t('auth.createAccountNow')}
    >
      <SignUpForm initialValues={{ privacyPolicy: true }} onSubmit={handleSubmit} />
    </AuthLayout>
  );
};
