import React from 'react';
import PropTypes from 'prop-types';

const IconEllipse = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={9} cy={9} r={8.3} strokeWidth={1.4} />
  </svg>
);

IconEllipse.propTypes = {
  className: PropTypes.string
};

IconEllipse.defaultProps = {
  className: ''
};

export default IconEllipse;
