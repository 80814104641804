import React from 'react';
import PropTypes from 'prop-types';

const IconCopyFresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16667 13.3333H3.70333C3.03917 13.3333 2.5 12.7942 2.5 12.13V3.70333C2.5 3.03917 3.03917 2.5 3.70333 2.5H12.1292C12.7942 2.5 13.3333 3.03917 13.3333 3.70333V4.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M16.2966 17.5003H7.86996C7.20579 17.5003 6.66663 16.9612 6.66663 16.297V7.87033C6.66663 7.20616 7.20579 6.66699 7.86996 6.66699H16.2958C16.9608 6.66699 17.5 7.20616 17.5 7.87033V16.2962C17.5 16.9612 16.9608 17.5003 16.2966 17.5003Z"
      fillRule="evenodd"
      strokeWidth="1.5"
    />
  </svg>
);

IconCopyFresh.propTypes = {
  className: PropTypes.string
};

IconCopyFresh.defaultProps = {
  className: ''
};

export default IconCopyFresh;
