import React, { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useAppDispatch } from '@store';
import { GroupsType } from '@features';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { MaterialIcon, MaterialIconValueType } from '@components';
import { Drop } from '@custom';
import { MaterialIcons } from './_components/MaterialIcons';
import { MaterialIconsPickerChangeType } from './_types';

import './MaterialIconsPicker.scss';

const CLASS_NAME = 'MaterialIconsPicker';

const DEFAUL_MATERIAL_ICON = {
  name: 'IconChevronCircleUp'
};

type PropsType = {
  value: MaterialIconValueType;
  viewType?: 'single' | 'group';
  onChange: MaterialIconsPickerChangeType;
};

export const MaterialIconsPicker = memo(
  ({ value = DEFAUL_MATERIAL_ICON, viewType = 'single', onChange }: PropsType) => {
    const handleChange = useCallback(
      (newValue) => {
        onChange(newValue);
      },
      [onChange]
    );

    const { type } = useParams<{
      type: GroupsType;
    }>();
    const currentStoriesSlice = useCurrentStoriesSlice(type);

    const IconButton = useMemo(
      () => (
        <button
          className={cn(`${CLASS_NAME}__button`, `${CLASS_NAME}__button_${viewType}`)}
          type="button"
        >
          <MaterialIcon color="#fff" name={value.name} size={18} />
        </button>
      ),
      [value.name, viewType]
    );

    const dispatch = useAppDispatch();

    const handleDropOpen = useCallback(
      (isOpen: boolean) => {
        dispatch(currentStoriesSlice.actions.setIsPickerOpen(isOpen));
      },
      [dispatch]
    );

    return (
      <Drop control={IconButton} handleOpen={handleDropOpen}>
        <MaterialIcons value={value} onChange={handleChange} />
      </Drop>
    );
  }
);
