import React from 'react';
import PropTypes from 'prop-types';

const IconBCircle = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={20}
    viewBox="0 0 20 20"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={10} cy={10} r={9.5} stroke="#18182E" />
    <path
      d="M7.629 14V6.727h2.542c.507 0 .925.088 1.254.263.329.173.574.406.735.7.161.29.242.614.242.97 0 .312-.056.57-.167.773a1.299 1.299 0 01-.434.483 1.97 1.97 0 01-.579.263v.071c.223.014.447.092.672.234a1.7 1.7 0 01.564.611c.152.265.227.59.227.973 0 .365-.082.693-.248.984-.166.291-.427.522-.785.692-.357.17-.822.256-1.395.256H7.629zm.88-.781h1.748c.575 0 .983-.111 1.225-.334a1.07 1.07 0 00.366-.817c0-.246-.063-.473-.189-.682a1.362 1.362 0 00-.536-.504 1.682 1.682 0 00-.824-.192H8.51v2.529zm0-3.296h1.634c.398 0 .734-.115 1.008-.344.275-.23.412-.536.412-.92 0-.32-.11-.59-.333-.813-.223-.225-.576-.337-1.059-.337H8.51v2.414z"
      fill="#18182E"
    />
  </svg>
);

IconBCircle.propTypes = {
  className: PropTypes.string
};

IconBCircle.defaultProps = {
  className: ''
};

export default IconBCircle;
