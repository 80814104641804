export const getStoryObject = (elem: any) => {
  if (!elem) {
    return null;
  }

  if (elem?.className?.length && elem.className.split(' ').includes('StoryObjectComponent')) {
    return {
      element: elem,
      id: elem.id,
      widgetType: elem.dataset?.widgetType,
      isOriginal: true
    };
  }

  let objectElement;

  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() !== 'body') {
    elem = elem.parentNode;

    if (elem?.className?.length && elem.className.split(' ').includes('StoryObjectComponent')) {
      objectElement = elem;
      break;
    }
  }

  return {
    element: objectElement,
    id: objectElement?.id,
    widgetType: objectElement?.dataset?.widgetType,
    isOriginal: false
  };
};
