import { CollabaratorRole } from './types';

export enum AppCollaborationFeatures {
  CREATE_STORIES = 'CREATE_STORIES',
  CREATE_ONBOARDING = 'CREATE_ONBOARDING',
  PUBLISH_STORIES = 'PUBLISH_STORIES',
  ARCHIVE_APP = 'ARCHIVE_APP',
  REMOVE_STORIES = 'REMOVE_STORIES',
  INVITE_COLLABORATORS = 'INVITE_COLLABORATORS',
  REMOVE_COLLABORATORS = 'REMOVE_COLLABORATORS',
  EDIT_COLLABORATORS = 'EDIT_COLLABORATORS',
  DELETE_GROUP = 'DELETE_GROUP',
  CHANGE_APP_NAME = 'CHANGE_NAME',
  CHANGE_APP_STYLE = 'CHANGE_STYLE',
  ADD_APP_LOCALIZATION = 'ADD_LOCALIZATION',
  CHANGE_APP_SETTINGS = 'CHANGE_APP_SETTINGS',
  BILLING_APP = 'BILLING_APP'
}

export const AccesibleFeaturesByCollaboratorRole = {
  [CollabaratorRole.OWNER]: {
    [AppCollaborationFeatures.CREATE_STORIES]: true,
    [AppCollaborationFeatures.CREATE_ONBOARDING]: true,
    [AppCollaborationFeatures.PUBLISH_STORIES]: true,
    [AppCollaborationFeatures.ARCHIVE_APP]: true,
    [AppCollaborationFeatures.REMOVE_STORIES]: true,
    [AppCollaborationFeatures.INVITE_COLLABORATORS]: true,
    [AppCollaborationFeatures.REMOVE_COLLABORATORS]: true,
    [AppCollaborationFeatures.EDIT_COLLABORATORS]: true,
    [AppCollaborationFeatures.DELETE_GROUP]: true,
    [AppCollaborationFeatures.CHANGE_APP_NAME]: true,
    [AppCollaborationFeatures.CHANGE_APP_STYLE]: true,
    [AppCollaborationFeatures.ADD_APP_LOCALIZATION]: true,
    [AppCollaborationFeatures.CHANGE_APP_SETTINGS]: true,
    [AppCollaborationFeatures.BILLING_APP]: true
  },
  [CollabaratorRole.ADMIN]: {
    [AppCollaborationFeatures.CREATE_STORIES]: true,
    [AppCollaborationFeatures.CREATE_ONBOARDING]: true,
    [AppCollaborationFeatures.PUBLISH_STORIES]: true,
    [AppCollaborationFeatures.ARCHIVE_APP]: false,
    [AppCollaborationFeatures.REMOVE_STORIES]: true,
    [AppCollaborationFeatures.INVITE_COLLABORATORS]: true,
    [AppCollaborationFeatures.REMOVE_COLLABORATORS]: false,
    [AppCollaborationFeatures.EDIT_COLLABORATORS]: false,
    [AppCollaborationFeatures.DELETE_GROUP]: true,
    [AppCollaborationFeatures.CHANGE_APP_NAME]: false,
    [AppCollaborationFeatures.CHANGE_APP_STYLE]: true,
    [AppCollaborationFeatures.ADD_APP_LOCALIZATION]: true,
    [AppCollaborationFeatures.CHANGE_APP_SETTINGS]: true,
    [AppCollaborationFeatures.BILLING_APP]: false
  },
  [CollabaratorRole.MEMBER]: {
    [AppCollaborationFeatures.CREATE_STORIES]: true,
    [AppCollaborationFeatures.CREATE_ONBOARDING]: true,
    [AppCollaborationFeatures.PUBLISH_STORIES]: true,
    [AppCollaborationFeatures.ARCHIVE_APP]: false,
    [AppCollaborationFeatures.REMOVE_STORIES]: false,
    [AppCollaborationFeatures.INVITE_COLLABORATORS]: false,
    [AppCollaborationFeatures.REMOVE_COLLABORATORS]: false,
    [AppCollaborationFeatures.EDIT_COLLABORATORS]: false,
    [AppCollaborationFeatures.DELETE_GROUP]: false,
    [AppCollaborationFeatures.CHANGE_APP_NAME]: false,
    [AppCollaborationFeatures.CHANGE_APP_STYLE]: false,
    [AppCollaborationFeatures.ADD_APP_LOCALIZATION]: false,
    [AppCollaborationFeatures.CHANGE_APP_SETTINGS]: true,
    [AppCollaborationFeatures.BILLING_APP]: false
  }
};
