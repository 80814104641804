import React from 'react';
import PropTypes from 'prop-types';

const IconUpload = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 12.8333C3.7835 12.8333 1.16667 10.2165 1.16667 7C1.16667 3.7835 3.7835 1.16667 7 1.16667C10.2165 1.16667 12.8333 3.7835 12.8333 7C12.8333 10.2165 10.2165 12.8333 7 12.8333ZM7 14C10.8599 14 14 10.8599 14 7C14 3.14008 10.8599 0 7 0C3.14008 0 0 3.14008 0 7C0 10.8599 3.14008 14 7 14Z" />
    <path d="M4.79506 4.36395L6.69089 2.46812C6.77606 2.38295 6.88806 2.33978 7.00006 2.33978C7.11206 2.33978 7.22406 2.38237 7.30923 2.46812L9.20506 4.36395C9.47981 4.63812 9.28556 5.11062 8.89589 5.11062H7.58339V7.58978C7.58339 7.91178 7.32264 8.17312 7.00006 8.17312C6.67748 8.17312 6.41673 7.91178 6.41673 7.58978V5.11062H5.10423C4.71398 5.11062 4.51973 4.63812 4.79506 4.36395Z" />
    <path d="M10.5 9.91787C10.5 10.2399 10.2392 10.5012 9.91667 10.5012H4.08333C3.76075 10.5012 3.5 10.2399 3.5 9.91787C3.5 9.59587 3.76075 9.33453 4.08333 9.33453H9.91667C10.2392 9.33453 10.5 9.59587 10.5 9.91787Z" />
  </svg>
);

IconUpload.propTypes = {
  className: PropTypes.string
};

IconUpload.defaultProps = {
  className: ''
};

export default IconUpload;
