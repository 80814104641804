import React, { RefObject, memo } from 'react';
import block from 'bem-cn';
import { WidgetObjectType } from '@storysdk/react';
import StoryObjectComponent from '../WidgetFactory/WidgetFactory';
import './StoryEditorPreviewInner.scss';

const b = block('StoryEditorPreviewInner');

interface StoryEditorPreviewProps {
  widgets: WidgetObjectType[];
  selectedWidgetIds?: string[];
  scale: number;
  presetHeight: number;
  presetWidth: number;
  hoveredWidget?: WidgetObjectType;
  editorRef: RefObject<HTMLDivElement>;
  isAltPressed?: boolean;
  isAutoplayVideo?: boolean;
  isBorderVisible?: boolean;
  isEditWidgetMode?: boolean;
  handleWidgetChange?(payload: WidgetObjectType, isFirst?: boolean): void;
  handleOpenContextMenu?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  handleMouseDown?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  handleMouseUp?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  handleHover?(id: string | null): void;
  handleDoubleClick?(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export const StoryEditorPreviewInner: React.FC<StoryEditorPreviewProps> = memo(
  ({
    widgets,
    selectedWidgetIds,
    editorRef,
    scale,
    isAltPressed,
    hoveredWidget,
    children,
    isBorderVisible,
    presetHeight,
    presetWidth,
    isEditWidgetMode,
    isAutoplayVideo,
    handleWidgetChange,
    handleHover,
    handleOpenContextMenu,
    handleMouseDown,
    handleMouseUp,
    handleDoubleClick
  }) => (
    <div
      className={b({
        showBorders: isBorderVisible
      })}
      ref={editorRef}
      style={{
        transform: `scale(${scale})`,
        height: presetHeight,
        width: presetWidth
      }}
      onContextMenu={handleOpenContextMenu}
      onDoubleClick={handleDoubleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {widgets.map((widget) => {
        const hasSelected = !!selectedWidgetIds?.length;
        const isSelectedWidget =
          selectedWidgetIds?.length === 1 && selectedWidgetIds[0] === widget.id;
        const isHovered = widget.id === hoveredWidget?.id;
        const isGroupMode = selectedWidgetIds && selectedWidgetIds.length > 1;

        return (
          <StoryObjectComponent
            hasSelected={hasSelected}
            hoveredWidget={hoveredWidget}
            isAltPressed={isAltPressed}
            isAutoplayVideo={isAutoplayVideo}
            isEditWidgetMode={isEditWidgetMode}
            isGroupMode={isGroupMode}
            isHovered={isHovered}
            isSelected={isSelectedWidget}
            key={widget.id}
            presetHeight={presetHeight}
            presetWidth={presetWidth}
            scale={scale}
            widget={widget}
            onChangeWidget={handleWidgetChange}
            onHover={handleHover}
          />
        );
      })}

      {children}
    </div>
  )
);
