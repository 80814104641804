import React from 'react';
import PropTypes from 'prop-types';

const IconCircleFire = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
      fill="#00CC66"
      fillRule="evenodd"
    />
    <path
      d="M10.4047 17.8667C9.75137 17.48 9.28471 16.8 9.25804 16C9.21804 14.5067 10.258 13.7733 10.8714 12.7733C11.7514 11.3333 11.5114 10.44 11.5114 10.44C11.5114 10.44 12.2447 10.8533 12.6714 12.3867C12.8047 12.84 12.8314 13.2933 12.7914 13.72C12.7247 14.8 12.258 15.7733 12.258 15.7733C12.258 15.7733 13.0714 15.6 13.298 14.12C13.6714 14.5067 14.018 15.0667 14.058 15.6533C14.1247 16.6667 13.5247 17.6 12.618 18C14.1914 17.64 15.3114 16.3067 15.698 15.3333C16.1914 14.1067 16.058 13.0133 15.978 12.0667C15.8714 10.7733 16.3247 9.81333 16.3247 9.81333C16.3247 9.81333 15.458 10.0667 14.818 11.12C14.5247 11.6 14.4047 12.3067 14.4047 12.3067C14.4047 12.3067 14.4714 11.68 14.058 10.5333C13.6447 9.41333 13.2714 9.01333 13.0447 8.18667C12.7514 7.08 13.4047 6 13.4047 6C13.4047 6 10.818 6.48 9.64471 8.73333C8.60471 10.7333 9.03137 11.9333 9.03137 11.9333C9.03137 11.9333 8.59137 11.52 8.36471 10.9467C8.13804 10.3733 8.19137 9.85333 8.19137 9.85333C8.19137 9.85333 6.35137 11.88 7.24471 14.4267C7.84471 16.2133 9.00471 17.3867 10.4047 17.8667Z"
      fill="white"
    />
  </svg>
);

IconCircleFire.propTypes = {
  className: PropTypes.string
};

IconCircleFire.defaultProps = {
  className: ''
};

export default IconCircleFire;
