import React from 'react';
import PropTypes from 'prop-types';

const IconLogoChrome = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2855 9.00007C13.2855 11.3662 11.366 13.2858 8.99983 13.2858C6.63368 13.2858 4.71411 11.3662 4.71411 9.00007C4.71411 6.63393 6.63368 4.71436 8.99983 4.71436C11.366 4.71436 13.2855 6.63393 13.2855 9.00007Z"
      fill="white"
    />
    <path
      d="M12.1413 11.2329L8.64418 17.5629C4.07561 17.3786 0.428467 13.6114 0.428467 9.00002C0.428467 4.26859 4.26847 0.428589 8.9999 0.428589V5.14287C6.8699 5.14287 5.14275 6.87002 5.14275 9.00002C5.14275 11.13 6.8699 12.8572 8.9999 12.8572C10.2985 12.8572 11.4428 12.2186 12.1413 11.2329Z"
      fill="url(#paint0_linear_3418_16919)"
    />
    <path
      d="M17.5713 9.00002C17.5713 13.7357 13.7356 17.5714 8.99989 17.5714H8.63989L12.1413 11.2329C12.5913 10.6029 12.857 9.83145 12.857 9.00002C12.857 6.87002 11.1299 5.14287 8.99989 5.14287V0.428589C12.347 0.428589 15.2356 2.34859 16.6499 5.14287C17.2327 6.3043 17.5713 7.61145 17.5713 9.00002Z"
      fill="url(#paint1_linear_3418_16919)"
    />
    <path
      d="M16.6456 5.14287H8.99988C6.86988 5.14287 5.14274 6.87002 5.14274 9.00002C5.14274 9.63859 5.29703 10.2386 5.5756 10.77H5.57131L1.78274 4.39716H1.77417C3.29131 2.01002 5.96131 0.428589 8.99988 0.428589C12.3427 0.428589 15.2356 2.34859 16.6456 5.14287Z"
      fill="url(#paint2_linear_3418_16919)"
    />
    <path
      d="M12 9C12 10.6573 10.6573 12 9 12C7.34271 12 6 10.6573 6 9C6 7.34271 7.34271 6 9 6C10.6573 6 12 7.34271 12 9Z"
      fill="url(#paint3_linear_3418_16919)"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3418_16919"
        x1="1.19518"
        x2="12.1388"
        y1="5.41887"
        y2="11.2376"
      >
        <stop stopColor="#4CAF50" />
        <stop offset="0.489" stopColor="#4AAF50" />
        <stop offset="0.665" stopColor="#43AD50" />
        <stop offset="0.79" stopColor="#38AA50" />
        <stop offset="0.892" stopColor="#27A550" />
        <stop offset="0.978" stopColor="#11A050" />
        <stop offset="1" stopColor="#0A9E50" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_3418_16919"
        x1="13.1056"
        x2="13.1056"
        y1="17.5714"
        y2="5.22987"
      >
        <stop stopColor="#FFD747" />
        <stop offset="0.482" stopColor="#FFD645" />
        <stop offset="0.655" stopColor="#FED43E" />
        <stop offset="0.779" stopColor="#FCCF33" />
        <stop offset="0.879" stopColor="#FAC922" />
        <stop offset="0.964" stopColor="#F7C10C" />
        <stop offset="1" stopColor="#F5BC00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_3418_16919"
        x1="14.1976"
        x2="3.67446"
        y1="1.15845"
        y2="7.73402"
      >
        <stop stopColor="#F7572F" />
        <stop offset="0.523" stopColor="#F7552D" />
        <stop offset="0.712" stopColor="#F75026" />
        <stop offset="0.846" stopColor="#F7461B" />
        <stop offset="0.954" stopColor="#F7390A" />
        <stop offset="1" stopColor="#F73100" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_3418_16919"
        x1="6.87857"
        x2="11.1214"
        y1="6.87857"
        y2="11.1214"
      >
        <stop stopColor="#2AA4F4" />
        <stop offset="1" stopColor="#007AD9" />
      </linearGradient>
    </defs>
  </svg>
);

IconLogoChrome.propTypes = {
  className: PropTypes.string
};

IconLogoChrome.defaultProps = {
  className: ''
};

export default IconLogoChrome;
