import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '@store';
import { fetchUpdateApp, fetchRemoveApp } from '@features/appsManagerSlice/appsManagerSlice';
import { DeleteAppModal } from '@features/appsManagerSlice/components/DeleteAppModal/DeleteAppModal';
import {
  AppCollaborationFeatures,
  useGetAccessByCollaboratorRole,
  useGetActionByCollaboratorRole,
  useRedirectByAccessCollaborator
} from '@features';
import { IconSaveFresh } from '@components';
import { FreshButton, FreshInput, FreshOutput, FreshSwitchControl } from '@components/_fresh';
import './AppSettingsTab.scss';
import { getUrlFromString } from '@utils';

interface FormProps {
  title: string | null;
  domain: string | null;
}

const s = block('GroupSettingsTab');
const b = block('AppSettingsTab');

export const AppSettingsTab: React.FC = () => {
  const initialFormData = {
    title: null,
    domain: null
  };

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormProps>(initialFormData);
  const [isDomainShow, setDomainShow] = useState<boolean>(false);

  const appState = useSelector((state: RootState) => state);
  const app = appState.appManager;

  const { t } = useTranslation();
  const onActionClickCollaborator = useGetActionByCollaboratorRole();

  const isDeleteAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.ARCHIVE_APP
  });

  const isNameAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.CHANGE_APP_NAME
  });

  useRedirectByAccessCollaborator({
    feature: AppCollaborationFeatures.CHANGE_APP_SETTINGS
  });

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      title: app.current?.title ?? '',
      domain: app.current?.domain ?? null
    }));

    setDomainShow(!!app.current?.domain);
  }, [app.current]);

  const handleChange = ({ fieldName, value }: any) => {
    let newValue = value;

    if (fieldName === 'domain') {
      const url = getUrlFromString(value);

      if (url) {
        const hostName = url[2].replace('www.', '');
        newValue = hostName;
      }
    }

    setFormData((prevState) => ({ ...prevState, [fieldName]: newValue }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (app.current) {
      dispatch(
        fetchUpdateApp({
          id: app.current.id,
          title: formData.title ?? '',
          domain: formData.domain ?? '',
          settings: {
            ...app.current.settings
          }
        })
      );

      if (!formData.domain) {
        setDomainShow(false);
      }
    }
  };

  const handleDeleteApp = () => {
    if (app) {
      dispatch(
        fetchRemoveApp({
          id: app.current?.id,
          subscriptionId: app.current?.subscriptionId
        })
      ).then(() => {
        setDeleteOpen(false);
        history.push(`/dashboard`);
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={s('formHeader')}>
          <h1 className={s('formHeaderTitle')}>{app.current?.title}</h1>

          {isNameAvailable && (
            <FreshButton
              disabled={
                !formData.title ||
                !!(
                  formData.domain &&
                  !/^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/.test(
                    formData.domain.replace(/^www\./, '')
                  )
                )
              }
              leftIcon={<IconSaveFresh />}
              size="md"
              text={t('form.saveSettings')}
              type="submit"
            />
          )}
        </div>

        <div className={s('formColumn')}>
          {isNameAvailable && (
            <div className={s('filedSet', { noBorder: true })}>
              <div className={s('fieldGroup')}>
                <p className={s('fieldLabel')}>{t('dashboard.apps.appName')}</p>

                <FreshInput
                  fieldName="title"
                  invalid={!formData.title}
                  placeholder={t('dashboard.apps.appNamePlaceholder')}
                  type="text"
                  value={formData.title as string}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}

          <div className={s('filedSet', { noBorder: true })}>
            <div className={s('fieldGroup')}>
              <p className={s('fieldLabel')}>{t('dashboard.apps.sdkToken')}</p>

              <FreshOutput
                className={`${s('descGroup')}`}
                isCopy
                value={app.current?.sdkToken ?? ''}
              />

              <p className={s('desc')}>{t('dashboard.apps.sdkTokenDesc')}</p>
            </div>
          </div>
          {isNameAvailable && (
            <div className={s('filedSet')}>
              <div className={s('fieldGroup')}>
                <div className={b('labelContainer')}>
                  <p className={s('fieldLabel', { noMargin: true })}>
                    {t('dashboard.apps.ownDomain')}
                  </p>

                  <FreshSwitchControl
                    className={b('switch')}
                    isChecked={isDomainShow}
                    onToggle={() => {
                      if (isDomainShow) {
                        setFormData((prevState) => ({ ...prevState, domain: '' }));
                      }

                      setDomainShow(!isDomainShow);
                    }}
                  />
                </div>

                {isDomainShow && (
                  <>
                    <FreshInput
                      fieldName="domain"
                      invalid={
                        !!(
                          formData.domain &&
                          !/^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/.test(
                            formData.domain.replace(/^www\./, '')
                          )
                        )
                      }
                      placeholder={t('dashboard.apps.ownDomainPlaceholder')}
                      type="text"
                      value={formData.domain as string}
                      onChange={handleChange}
                    />

                    <p className={s('desc')}>{t('dashboard.apps.ownDomainDesc')}</p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {isDeleteAvailable && (
          <a
            className={cn(`${s('link')}`)}
            role="button"
            tabIndex={0}
            onClick={() =>
              onActionClickCollaborator(AppCollaborationFeatures.ARCHIVE_APP, () =>
                setDeleteOpen(true)
              )
            }
            onKeyDown={() =>
              onActionClickCollaborator(AppCollaborationFeatures.ARCHIVE_APP, () =>
                setDeleteOpen(true)
              )
            }
          >
            {t('dashboard.apps.deleteApp')}
          </a>
        )}
      </form>

      <DeleteAppModal
        handleDeleteApp={handleDeleteApp}
        isOpen={isDeleteOpen}
        title={formData.title as string}
        onClose={() => setDeleteOpen(false)}
      />
    </>
  );
};
