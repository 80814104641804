import { GroupsType, StoriesGroup } from '@features';
import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { Icon, IconCheckArrow } from '@components';
import './GroupChooseItems.scss';

const b = block('GroupChooseItems');

interface GroupChooseItemsProps {
  groups: StoriesGroup[];
  chosenGroupId: string;
  type: GroupsType;
  onChoose: (id: string) => void;
  onAddModalOpen?(type: GroupsType): void;
}

export const GroupChooseItems: React.FC<GroupChooseItemsProps> = ({
  groups,
  type,
  chosenGroupId,
  onAddModalOpen,
  onChoose
}) => {
  const { t } = useTranslation();

  const handleOpenModal = () => {
    onAddModalOpen && onAddModalOpen(type);
  };

  return (
    <div className={b()}>
      {groups.map((group) => (
        <div
          className={b('item', {
            chosen: chosenGroupId === group.id
          })}
          key={`group-choose-${group.id}`}
          onClick={() => onChoose(group.id)}
        >
          <div className={b('itemImageWrapper', { squared: type === GroupsType.ONBOARDING })}>
            <div className={b('itemImageWrapperInner')}>
              <img
                alt=""
                className={b('itemImage', { hidden: type === GroupsType.ONBOARDING })}
                src={group.image}
              />

              <div
                className={b('itemImageOverlay', { noBackground: type === GroupsType.ONBOARDING })}
              >
                <div className={b('itemCheckIconContainer')}>
                  <IconCheckArrow className={b('itemCheckIcon').toString()} />
                </div>
              </div>
            </div>
          </div>

          <p className={b('itemTitle')}>{group.title}</p>
        </div>
      ))}

      <button className={b('item', { grey: true })} onClick={handleOpenModal}>
        <div className={b('itemImageWrapper', { squared: type === GroupsType.ONBOARDING })}>
          <Icon className={b('addGroupIcon').toString()} name="add-large" variant="editor" />
        </div>
        <div className={b('itemTitle', { grey: true })}>
          {type === GroupsType.ONBOARDING
            ? t('dashboard.header.addOnboarding')
            : t('dashboard.header.addGroup')}
        </div>
      </button>
    </div>
  );
};
