import React, { useCallback } from 'react';
import { RootState, useAppDispatch } from '@store';
import {
  GroupsType,
  StoriesGroup,
  StoriesTemplateBase,
  Story,
  StoryLayer,
  fetchCreateStory,
  fetchCreateTemplateStory,
  fetchUpdateGroup,
  fetchUpdateOnboarding,
  fetchUpdateStory,
  fetchUpdateTemplate,
  fetchUpdateTemplateStory
} from '@features';
import { nanoid } from 'nanoid';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesType } from '@features/stories/hooks';
import { useSelector } from 'react-redux';
import { BackgroundColorType } from '@storysdk/react';
import { asyncForEach } from '@utils';

interface UseCreateStoriesFromTemplateProps {
  currentAppId?: string;
  currentGroup?: StoriesGroup | StoriesTemplateBase;
  templateSettings?: any;
  lastPosition?: number;
  onBeforeCreating?: () => void;
  onAfterCreating?: () => void;
}

export const useCreateStoriesFromTemplate = ({
  currentAppId,
  currentGroup,
  templateSettings,
  onBeforeCreating,
  onAfterCreating
}: UseCreateStoriesFromTemplateProps) => {
  const dispatch = useAppDispatch();

  const { type } = useParams<{
    type: GroupsType;
  }>();

  const storiesType = useCurrentStoriesType(type);
  const currentStories = useSelector((store: RootState) => store[storiesType].stories);

  const selectedLayersGroupId =
    useSelector((store: RootState) => store[storiesType].editor.selectedLayersGroupId) ?? '';

  const getSelectedStory = useCallback(
    (isApplyToStory) => {
      if (!selectedLayersGroupId) {
        return null;
      }

      const storyId = currentStories[selectedLayersGroupId].activeLayerId;
      const story = currentStories[selectedLayersGroupId].layers[storyId];

      if (isApplyToStory || story.background.type === BackgroundColorType.TRANSPARENT) {
        return story;
      }

      return null;
    },
    [currentStories, selectedLayersGroupId]
  );

  const create = useCallback(
    async (stories: StoryLayer[], isApplyToStory?: boolean) => {
      if ((!currentAppId && currentGroup?.type !== GroupsType.TEMPLATE) || !currentGroup) {
        return;
      }

      const selectedStory: Story | null = getSelectedStory(isApplyToStory);

      const lastStoryPosition = currentGroup.settings?.lastStoryPosition || 0;

      onBeforeCreating && onBeforeCreating();

      let scoreResultLayersGroupId = templateSettings?.scoreResultLayersGroupId;

      const newStories = [...stories];

      const newLayers = newStories.map((story) => {
        const newLayersGroupId = nanoid();

        const storyLayes = { ...story.layers };

        for (const key in storyLayes) {
          if (Object.prototype.hasOwnProperty.call(storyLayes, key)) {
            const layer = { ...storyLayes[key] };

            layer.id = nanoid();
            const layerData = { ...layer.layerData };

            if (layerData.layersGroupId === scoreResultLayersGroupId) {
              scoreResultLayersGroupId = selectedStory?.layerData.layersGroupId ?? newLayersGroupId;
            }

            layer.position = lastStoryPosition + 1;
            layerData.layersGroupId = selectedStory?.layerData.layersGroupId ?? newLayersGroupId;
            layer.layerData = layerData;
            layer.widgets.forEach((widget) => {
              const newWidget = { ...widget };
              newWidget.id = `W${nanoid()}`;
              widget = newWidget;
            });
            storyLayes[key] = layer;
          }
        }

        return storyLayes;
      });

      const newCurrentStories = newLayers.map((layer) => Object.values(layer)).flat();

      let index = 0;

      await asyncForEach(newCurrentStories, async (story: Story) => {
        if (currentGroup.type === GroupsType.TEMPLATE) {
          if (selectedStory && index === 0) {
            dispatch(
              fetchUpdateTemplateStory({
                templateId: currentGroup.id,
                story: {
                  ...story,
                  id: selectedStory.id,
                  position: selectedStory.position,
                  layerData: selectedStory.layerData
                }
              })
            );
          } else {
            dispatch(
              fetchCreateTemplateStory({
                templateId: currentGroup.id,
                story
              })
            );
          }
        } else if (selectedStory && index === 0) {
          dispatch(
            fetchUpdateStory({
              appId: currentAppId,
              groupId: currentGroup.id,
              story: {
                ...story,
                id: selectedStory.id,
                position: selectedStory.position,
                layerData: selectedStory.layerData
              }
            })
          );
        } else if (currentAppId) {
          dispatch(
            fetchCreateStory({
              appId: currentAppId,
              groupId: currentGroup.id,
              story
            })
          );
        }

        index += 1;
      });

      if (
        scoreResultLayersGroupId &&
        newCurrentStories.some(
          (story) => story.layerData.layersGroupId === scoreResultLayersGroupId
        )
      ) {
        const params = {
          appId: currentAppId,
          groupId: currentGroup.id,
          templateId: currentGroup.id,
          active: currentGroup.active,
          title: currentGroup.title,
          startTime: (currentGroup as StoriesGroup).startDate ?? null,
          endTime: (currentGroup as StoriesGroup).endDate ?? null,
          noNotification: true,
          settings: {
            ...currentGroup.settings,
            scoreResultLayersGroupId
          }
        };

        if (currentGroup.type === GroupsType.GROUP) {
          dispatch(fetchUpdateGroup(params));
        } else if (currentGroup.type === GroupsType.ONBOARDING) {
          dispatch(fetchUpdateOnboarding(params));
        } else if (currentGroup.type === GroupsType.TEMPLATE) {
          dispatch(fetchUpdateTemplate(params));
        }
      }

      onAfterCreating && onAfterCreating();
    },
    [
      currentAppId,
      currentGroup,
      getSelectedStory,
      onBeforeCreating,
      templateSettings?.scoreResultLayersGroupId,
      onAfterCreating,
      dispatch
    ]
  );

  return (stories: StoryLayer[], isApplyToStory?: boolean) => create(stories, isApplyToStory);
};
