import React from 'react';
import PropTypes from 'prop-types';

const IconCopy2 = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4 6.6H8.2a1.6 1.6 0 00-1.6 1.6v7.2A1.6 1.6 0 008.2 17h7.2a1.6 1.6 0 001.6-1.6V8.2a1.6 1.6 0 00-1.6-1.6z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
    <path
      d="M3.4 11.4h-.8A1.6 1.6 0 011 9.8V2.6A1.6 1.6 0 012.6 1h7.2a1.6 1.6 0 011.6 1.6v.8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconCopy2.propTypes = {
  className: PropTypes.string
};

IconCopy2.defaultProps = {
  className: ''
};

export default IconCopy2;
