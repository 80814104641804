import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { GroupsType, informSlice, MessageTypes, StoriesTemplateBase, Templates } from '@features';
import { RootState } from '@store';
import i18n from '../../i18n/i18n';

interface State {
  templates: StoriesTemplateBase[];
  status: string;
  current: string | null;
}

const initialState: State = {
  templates: [],
  status: 'idle',
  current: null
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<{ templates: StoriesTemplateBase[] }>) => {
      state.templates = action.payload.templates;

      state.status = 'loaded';
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    create: (state, action: PayloadAction<StoriesTemplateBase>) => {
      state.templates.push(action.payload);
    },
    select: (state, action: PayloadAction<string>) => {
      state.current = action.payload;
    },
    remove: (state, action: PayloadAction<string>) => {
      const findIndex = state.templates.findIndex(({ id }) => id === action.payload);
      state.templates.splice(findIndex, 1);
    },
    update: (state, action: PayloadAction<StoriesTemplateBase>) => {
      state.templates = state.templates.map((template) =>
        template.id === action.payload.id ? action.payload : template
      );
    }
  }
});

export const fetchCreateTemplate = createAsyncThunk(
  'templates/fetchCreateTemplate',
  async (
    params: {
      name: string;
      image?: Blob | null;
      description: string;
      category: Templates.Category;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const languages = state.appManager.locales;
    const currentLocale = state.appManager.currentLocale;

    let imageUrl = `${process.env.REACT_APP_SITE_URL}/images/group-default.jpg`;
    let fileId;

    if (params.image) {
      const storageData = await API.storage.create({ file: params.image });

      if (storageData && storageData.data && !storageData.data.error) {
        imageUrl = storageData.data.data.image_url;
        fileId = storageData.data.data.id;
      }
    }

    const reqParams = {
      description: params.description,
      image: imageUrl,
      fileId,
      category: params.category,
      type: GroupsType.TEMPLATE,
      name: params.name,
      languages: languages?.map((item: any) => item.shortName)
    };

    const { data } = await API.templates.create(reqParams);

    if (data.data && !data.error && currentLocale) {
      dispatch(
        templatesSlice.actions.create({
          id: data.data.id,
          title: data.data.title[currentLocale],
          image: data.data.image_url[currentLocale],
          description: data.data.description[currentLocale],
          fileId: data.data.file_id,
          type: data.data.type,
          settings: data.data.settings,
          category: data.data.category,
          active: false,
          stories: {},
          templateData: {
            title: data.data.title,
            image: data.data.image,
            description: data.data.description
          }
        })
      );
    }
  }
);

export const fetchDeleteTemplate = createAsyncThunk(
  'templates/fetchDeleteTemplate',
  async (params: { templateId: string }, { dispatch }) => {
    const { data } = await API.templates.remove(params);

    if (data.data && !data.error) {
      dispatch(templatesSlice.actions.remove(params.templateId));
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.groups.deleted')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.groups.deleteError')
        })
      );
    }
  }
);

export const fetchChangeTemplatePublishStatus = createAsyncThunk(
  'templates/fetchChangeTemplatePublishStatus',
  async (params: { templateId: string; active: boolean }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;
    const currentGroup = state.templates.templates.find(
      (item: StoriesTemplateBase) => item.id === params.templateId
    );

    if (currentGroup) {
      const reqParams = { ...currentGroup, active: params.active } as any;

      reqParams.type = GroupsType.TEMPLATE;
      reqParams.title = currentGroup.templateData.title;
      reqParams.description = currentGroup.templateData.description;
      reqParams.image = currentGroup.templateData.image;
      reqParams.templateId = currentGroup.id;

      const { data } = await API.templates.update(reqParams);

      if (data.data && !data.error && currentLocale) {
        dispatch(
          templatesSlice.actions.update({
            id: data.data.id,
            title: data.data.title[currentLocale],
            image: data.data.image_url?.[currentLocale],
            active: data.data.active,
            category: data.data.category,
            type: data.data.type,
            settings: data.data.settings,
            templateData: {
              title: data.data.title,
              image: data.data.image_url,
              description: data.data.description
            },
            description: data.data.description?.[currentLocale],
            stories: currentGroup.stories
          })
        );
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: i18n.t('notification.groups.updateError')
          })
        );
      }
    }
  }
);

export const fetchUpdateTemplate = createAsyncThunk(
  'templates/fetchUpdateTemplate',
  async (params: any, { dispatch, getState }) => {
    const state = getState() as RootState;

    const currentLocale = state.appManager.currentLocale;
    const currentGroup = state.templates.templates.find(
      (item: StoriesTemplateBase) => item.id === params.templateId
    );

    let storageData;

    if (params.image) {
      storageData = await API.storage.create({ file: params.image });
    }

    if (
      ((storageData && storageData.data && !storageData.data.error) || !params.image) &&
      currentGroup &&
      currentLocale
    ) {
      const reqParams = { ...params };
      let image = null;

      reqParams.title = { ...currentGroup.templateData.title, [currentLocale]: params.title };

      reqParams.description = {
        ...currentGroup.templateData.description,
        [currentLocale]: params.description
      };

      if (storageData && storageData.data) {
        image = storageData.data.data.file_url;
        reqParams.image = { ...currentGroup.templateData.image, [currentLocale]: image };
      } else {
        reqParams.image = currentGroup.templateData.image;
      }

      reqParams.type = GroupsType.TEMPLATE;

      const { data } = await API.templates.update(reqParams);

      if (data.data && !data.error) {
        dispatch(
          templatesSlice.actions.update({
            id: data.data.id,
            title: data.data.title[currentLocale],
            image: data.data.image_url?.[currentLocale],
            active: data.data.active,
            type: data.data.type,
            category: data.data.category,
            settings: data.data.settings,
            templateData: {
              title: data.data.title,
              image: data.data.image_url,
              description: data.data.description
            },
            description: data.data.description?.[currentLocale],
            stories: currentGroup.stories
          })
        );
        if (!params.noNotification) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.SUCCESS,
              text: i18n.t('notification.groups.updated')
            })
          );
        }
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: i18n.t('notification.groups.updateError')
          })
        );
      }
    }
  }
);

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplates',
  async (
    params: { category: Templates.Category; isOnlyActive?: boolean },
    { dispatch, getState }
  ) => {
    dispatch(templatesSlice.actions.setStatus('pending'));

    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    const { data } = await API.templates.getList();

    if (data.data && !data.error) {
      const templates = data.data.filter(
        (currentTemplate: any) => currentTemplate.category === params.category
      );

      let result: StoriesTemplateBase[] = [];

      if (currentLocale) {
        templates.forEach((currentTemplate: any) => {
          result.push({
            id: currentTemplate.id,
            title: currentTemplate.title[currentLocale],
            image: currentTemplate.image_url?.[currentLocale],
            description: currentTemplate.description?.[currentLocale],
            active: currentTemplate.active,
            type: currentTemplate.type,
            settings: currentTemplate.settings,
            category: currentTemplate.category,
            templateData: {
              title: currentTemplate.title,
              image: currentTemplate.image_url,
              description: currentTemplate.description
            }
          });
        });
      }

      if (params.isOnlyActive) {
        result = result.filter((item: any) => item.active);
      }

      dispatch(
        templatesSlice.actions.init({
          templates: result
        })
      );
    }
  }
);
