import React from 'react';
import PropTypes from 'prop-types';

const IconElementAlignLeft = ({ className }) => (
  <svg
    className={className}
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1210_12882)">
      <rect height="16" width="1" />
      <rect height="16" width="1" />
      <rect height="5" rx="1.5" width="12" x="4" y="2" />
      <rect height="5" rx="1.5" width="12" x="4" y="2" />
      <rect height="5" rx="1.5" width="6" x="4" y="9" />
      <rect height="5" rx="1.5" width="6" x="4" y="9" />
    </g>
    <defs>
      <clipPath id="clip0_1210_12882">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);

IconElementAlignLeft.propTypes = {
  className: PropTypes.string
};

IconElementAlignLeft.defaultProps = {
  className: ''
};

export default IconElementAlignLeft;
