import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Auth } from '@utils';

export const GoogleAuthRedirectPage: React.FC = () => {
  const history = useHistory();
  const searchParamsString = useLocation().search.substring(0);

  useEffect(() => {
    if (searchParamsString) {
      fetch(`${process.env.REACT_APP_API_URL}/auth/google_callback${searchParamsString}`, {
        method: 'get'
      })
        .then((r) => r.json())
        .then((response) => {
          if (response.user) {
            Auth.setStorage({
              ...response,
              user: { ...response.user }
            });
            axios.defaults.headers.common = { Authorization: `Bearer ${response.access_token}` };
            history.push('/dashboard');
          } else {
            history.push('/');
          }
        })
        .catch(() => {
          history.push('/');
        });
    } else {
      history.push('/');
    }
  }, [history, searchParamsString]);

  return null;
};
