import React from 'react';
import { Story, StoryPreview } from '@features';
import block from 'bem-cn';
import './StoryPreviewIcon.scss';

const b = block('StoryPreviewIcon');

export const StoryPreviewIcon: React.FC<{ story: Story }> = ({ story }) => (
  <div className={b('storyImage')}>
    <StoryPreview className={b('storyImageIcon')} scaleIndex={0.045} story={story} />
  </div>
);
