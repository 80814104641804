import React from 'react';
import { GroupsType, StoriesGroup } from '@features/groups/types';
import block from 'bem-cn';
import { getGroupImage } from '@features/stories';
import { useAppDispatch } from '@store';
import { App, groupsSlice } from '@features';
import { GroupAvatar } from '@components';
import './GroupItem.scss';

interface GroupItemProps {
  group: StoriesGroup;
  groupId?: string;
  hoveringGroup: string | null;
  isActive?: boolean;
  isDisabled?: boolean;
  onSelect?: (groupId: string) => void;
  setHoveringGroup?: (groupId: string | null) => void;
}

const b = block('GroupItem');

export const GroupItem: React.FC<GroupItemProps> = ({
  group,
  groupId,
  isActive,
  isDisabled,
  hoveringGroup,
  onSelect,
  setHoveringGroup
}) => {
  const dispatch = useAppDispatch();

  return (
    <div
      className={b('groupItem', {
        active: isActive,
        disabled: isDisabled
      })}
      key={group.id}
      role="button"
      tabIndex={0}
      onClick={() => onSelect && onSelect(group.id)}
      onKeyDown={() => onSelect && onSelect(group.id)}
    >
      <GroupAvatar
        current={isActive}
        image={getGroupImage(
          group,
          group.type,
          group.image,
          groupId ?? '',
          hoveringGroup ?? undefined
        )}
        isHovered={hoveringGroup === group.id}
        isSquared={group.type === GroupsType.ONBOARDING}
        size="lg"
        onEditClick={() => {
          dispatch(groupsSlice.actions.setEditGroup(group));
        }}
        onMouseEnter={() => setHoveringGroup && setHoveringGroup(group.id)}
        onMouseLeave={() => setHoveringGroup && setHoveringGroup(null)}
      />
      <div className={b('groupTitle')}>{group.title}</div>
    </div>
  );
};
