import { WidgetObjectType, WidgetPositionType } from '@storysdk/react';

export const getNewWidgetPositions = (
  widgetPosition: WidgetPositionType,
  currentWidgetPositions: WidgetObjectType['positionByResolutions'],
  storyWidth: number,
  storyHeight: number,
  isWidgetsSizeSynced?: boolean
): WidgetObjectType['positionByResolutions'] => {
  const currentWidgetPositionsCopy = { ...currentWidgetPositions };

  if (isWidgetsSizeSynced) {
    for (const key in currentWidgetPositionsCopy) {
      if (currentWidgetPositionsCopy[key]) {
        const [, height] = key.split('x').map((item) => Number(item));

        if (widgetPosition?.y !== undefined && widgetPosition.y > height) {
          currentWidgetPositionsCopy[key] = {
            ...currentWidgetPositionsCopy[key],
            ...widgetPosition,
            y: height - widgetPosition.origin.height,
            origin: {
              ...widgetPosition.origin,
              y: height - widgetPosition.origin.height
            }
          };
        } else {
          currentWidgetPositionsCopy[key] = {
            ...currentWidgetPositionsCopy[key],
            ...widgetPosition
          };
        }
      }
    }
  } else {
    currentWidgetPositionsCopy[`${storyWidth}x${storyHeight}`] = {
      ...currentWidgetPositionsCopy[`${storyWidth}x${storyHeight}`],
      ...widgetPosition
    };
  }

  return currentWidgetPositionsCopy;
};
