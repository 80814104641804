import React, { RefObject } from 'react';
import block from 'bem-cn';
import { Icon } from '@components';
import { useVisible } from '@hooks';
import './SettingsDropdownButton.scss';

const b = block('SettingsDropdownButton');

interface Props {
  icon: React.ReactNode;
  options?: Array<{ value: any; title: string }>;
  disabled?: boolean;
  onChange(value: any): void;
  onClick(): void;
}

export const SettingsDropdownButton: React.FC<Props> = ({
  children,
  options,
  disabled,
  icon,
  onChange,
  onClick
}) => {
  const { ref, isVisible, additionalRef, setIsVisible } = useVisible(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleSelect = (newValue: any) => () => {
    onChange(newValue);
    setIsVisible(false);
  };

  return (
    <div className={b('container')}>
      <div className={b('menu', { open: isVisible })} ref={ref}>
        {options?.map((option, index) => (
          <button
            className={b('option')}
            key={`${JSON.stringify(option.value)}-${index}`}
            onClick={handleSelect(option.value)}
          >
            {`${option.title}`}
          </button>
        ))}
      </div>

      <div className={b({ disabled })}>
        <div
          className={b('titleContainer', { disabled })}
          onClick={!disabled ? onClick : undefined}
        >
          <span className={b('icon')}>{icon}</span>
          <span className={b('title')}>{children}</span>
        </div>
        {options?.length && (
          <div
            className={b('btn', { disabled })}
            ref={additionalRef as RefObject<HTMLDivElement>}
            onClick={!disabled ? handleToggle : undefined}
          >
            <Icon className={b('chevron').toString()} name="chevron-down-fresh" variant="icons" />
          </div>
        )}
      </div>
    </div>
  );
};
