import React from 'react';
import PropTypes from 'prop-types';

const IconSettings = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 11.182a2.182 2.182 0 100-4.364 2.182 2.182 0 000 4.364z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
    <path
      d="M14.382 11.182a1.2 1.2 0 00.24 1.323l.043.044a1.455 1.455 0 11-2.058 2.058l-.043-.043a1.2 1.2 0 00-1.324-.24 1.2 1.2 0 00-.727 1.098v.123a1.455 1.455 0 11-2.91 0v-.065a1.2 1.2 0 00-.785-1.098 1.2 1.2 0 00-1.323.24l-.044.043a1.455 1.455 0 11-2.058-2.058l.043-.043a1.2 1.2 0 00.24-1.324 1.2 1.2 0 00-1.098-.727h-.123a1.454 1.454 0 110-2.91h.065a1.2 1.2 0 001.098-.785 1.2 1.2 0 00-.24-1.323l-.043-.044a1.455 1.455 0 112.058-2.058l.043.043a1.2 1.2 0 001.324.24h.058a1.2 1.2 0 00.727-1.098v-.123a1.455 1.455 0 012.91 0v.065a1.2 1.2 0 00.727 1.098 1.2 1.2 0 001.323-.24l.044-.043a1.455 1.455 0 112.058 2.058l-.043.043a1.2 1.2 0 00-.24 1.324v.058a1.2 1.2 0 001.098.727h.123a1.455 1.455 0 010 2.91h-.065a1.2 1.2 0 00-1.098.727v0z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconSettings.propTypes = {
  className: PropTypes.string
};

IconSettings.defaultProps = {
  className: ''
};

export default IconSettings;
