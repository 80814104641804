import React from 'react';
import PropTypes from 'prop-types';

const IconAddPlusLarge = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    stroke="#2B1E2A"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 1V13" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M13 7H1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </svg>
);

IconAddPlusLarge.propTypes = {
  className: PropTypes.string
};

IconAddPlusLarge.defaultProps = {
  className: ''
};

export default IconAddPlusLarge;
