import React from 'react';
import PropTypes from 'prop-types';

const IconSliderArrow = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="36"
    stroke="#A49BA4"
    viewBox="0 0 36 36"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 27L13.5 18L22.5 9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

IconSliderArrow.propTypes = {
  className: PropTypes.string
};

IconSliderArrow.defaultProps = {
  className: ''
};

export default IconSliderArrow;
