import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { GoogleFont } from '@features/stories/editorSettings/components/SettingsFontSelector/SettingsFontSelector';
import { RootState } from '@store';
import { appsManagerSlice } from '@features/appsManagerSlice';

interface State {
  fonts: GoogleFont[];
  googleFonts: GoogleFont[];
  status: string;
  recentFont?: string;
}

const initialState: State = {
  fonts: [],
  googleFonts: [],
  status: 'idle',
  recentFont: undefined
};

export const fontsSlice = createSlice({
  name: 'fonts',
  initialState,
  reducers: {
    reset: () => initialState,
    setStatus(state, action: PayloadAction<string>) {
      state.status = action.payload;
    },
    init(state, action: PayloadAction<{ fonts: GoogleFont[]; googleFonts: GoogleFont[] }>) {
      state.fonts = action.payload.fonts;
      state.googleFonts = action.payload.googleFonts;

      state.status = 'loaded';
    },
    installFont(state, action: PayloadAction<GoogleFont>) {
      state.fonts.push(action.payload);
    },
    setRecentFont(state, action: PayloadAction<string>) {
      state.recentFont = action.payload;
    }
  }
});

export const fetchGetFonts = createAsyncThunk(
  'fonts/fetchGetFonts',
  async (params, { dispatch, getState }) => {
    const state = getState() as RootState;
    const app = state.appManager.current;

    const response = await fetch(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC-9VCGf0XoxtERAHI1iSi81AvzLCSRkDE`
    );

    const { items } = await response.json();

    if (app?.settings?.fonts) {
      dispatch(fontsSlice.actions.init({ fonts: app.settings.fonts, googleFonts: items }));
    } else {
      dispatch(fontsSlice.actions.init({ fonts: [], googleFonts: items }));
    }
  }
);

export const fetchInstallFont = createAsyncThunk<
  void,
  GoogleFont,
  {
    state: RootState;
  }
>('fonts/fetchInstallFont', async (font, { dispatch, getState }) => {
  const state = getState();
  dispatch(fontsSlice.actions.installFont(font));
  const app = state.appManager.current;

  if (app) {
    const { data } = await API.apps.update({
      ...app,
      settings: {
        ...app.settings,
        fonts: [...state.fonts.fonts, font]
      }
    });

    if (data.data && !data.error) {
      dispatch(
        appsManagerSlice.actions.setSettings({
          ...app.settings,
          fonts: [...state.fonts.fonts, font]
        })
      );

      dispatch(
        appsManagerSlice.actions.updateApp({
          ...app,
          settings: {
            ...app.settings,
            fonts: [...state.fonts.fonts, font]
          }
        })
      );
    }
  }
});
