import React from 'react';
import PropTypes from 'prop-types';

const IconMoreSquare = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24847 12.6674L9.33268 10.4439L11.4169 12.6674L14.7516 9.33264"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.41"
    />
    <rect
      height="15.0062"
      rx="5"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="15.0062"
      x="3.49689"
      y="3.49689"
    />
  </svg>
);

IconMoreSquare.propTypes = {
  className: PropTypes.string
};

IconMoreSquare.defaultProps = {
  className: ''
};

export default IconMoreSquare;
