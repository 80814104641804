import React from 'react';
import block from 'bem-cn';
import { ColorThemesItem, ColorThemesItemColor } from './ColorThemesItem';
import './ColorThemes.scss';

const b = block('ColorThemes');

interface ColorThemesProps {
  selected: ColorThemesItemColor;
  options: ColorThemesItemColor[];
  onChange: (value: ColorThemesItemColor) => void;
}

export const ColorThemes: React.FC<ColorThemesProps> = ({ selected, options, onChange }) => (
  <div className={b()}>
    {options.map((option, index) => (
      <ColorThemesItem
        color={option}
        key={`theme-color-${index}`}
        selected={selected}
        onChange={onChange}
      />
    ))}
  </div>
);
