import React, { useEffect, useState, useRef, useCallback } from 'react';
import './TextWidgetInput.scss';
import block from 'bem-cn';
import {
  BackgroundColorType,
  TextWidgetParamsType,
  WidgetComponent,
  WidgetObjectType,
  renderBackgroundStyles,
  renderTextBackgroundStyles
} from '@storysdk/react';
import { useDebounce } from '@hooks';

const b = block('TextWidgetInput');

type PropsType = {
  params: TextWidgetParamsType;
  isEditWidgetMode?: boolean;
  handleChangheWidgetContent?: (payload: WidgetObjectType['content']['params']) => void;
};

export const TextWidgetInput: WidgetComponent<PropsType> = React.memo((props: PropsType) => {
  const { params, isEditWidgetMode, handleChangheWidgetContent } = props;

  const [text, setText] = useState(params.text);
  const [textareaWidth, setTextareaWidth] = useState(0);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleTextChange = (text: string) => {
    setText(text);
  };

  const textValue = useDebounce(text, 500);

  useEffect(() => {
    if (textValue !== params.text) {
      handleChangheWidgetContent?.({ ...params, text: textValue });
    }
  }, [textValue]);

  useEffect(() => {
    if (text !== params.text) {
      setText(params.text);
    }
  }, [params.text]);

  useEffect(() => {
    if (isEditWidgetMode) {
      const textarea = textareaRef.current;

      if (textarea) {
        textarea.focus();
        textarea.select();
      }
    }
  }, [isEditWidgetMode]);

  const widthDebounced = useDebounce(textareaWidth, 10);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [params.lineHeight, params.fontSize, text, widthDebounced]);

  const handleResize = useCallback(() => {
    if (textareaRef.current) {
      const { current } = textareaRef;
      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;
      const witdthRound = Math.round(width);
      setTextareaWidth(witdthRound);
    }
  }, [textareaRef]);

  useEffect(() => {
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        handleResize();
      });
    });

    if (textareaRef.current) {
      observer.observe(textareaRef.current);
    }
    return () => {
      if (textareaRef.current) {
        observer.unobserve(textareaRef.current);
      }
    };
  }, [handleResize]);

  return (
    <div className={b()}>
      <div
        className={b('container', {
          gradient: params.color.type === BackgroundColorType.GRADIENT
        })}
        style={{
          opacity: params.widgetOpacity / 100,
          fontStyle: params.fontParams.style,
          fontWeight: params.fontParams.weight,
          fontFamily: params.fontFamily,
          fontSize: `${params.fontSize}px`,
          lineHeight: `${params.lineHeight}%`,
          textAlign: params.align,
          ...renderTextBackgroundStyles({ color: params.color })
        }}
      >
        <textarea
          className={b('textarea')}
          ref={textareaRef}
          rows={1}
          spellCheck="false"
          value={text}
          onChange={(e) => {
            handleTextChange(e.target.value);
          }}
          onInput={() => {
            if (textareaRef.current) {
              textareaRef.current.style.height = 'auto';
              textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
          }}
        />
      </div>

      {params.withFill ? (
        <div
          className={b('background')}
          style={{
            background: renderBackgroundStyles(params.backgroundColor)
          }}
        />
      ) : null}
    </div>
  );
});
