import React from 'react';
import { OutputAnswerItemType } from '@features';
import { WidgetObjectType, WidgetsTypes } from '@storysdk/react';
import block from 'bem-cn';
import { t } from 'i18next';
import { getAnswer, getWidgetTitle, isShowPercent } from '@features/storiesAnalyticsFeature/utils';
import './WidgetAnalyticsAnswers.scss';

const b = block('WidgetAnalyticsAnswers');

interface WidgetAnalyticsAnswersProps {
  widget: WidgetObjectType;
  widgetNumber?: number;
  isShowNumber?: boolean;
  answers: OutputAnswerItemType[];
}

export const WidgetAnalyticsAnswers: React.FC<WidgetAnalyticsAnswersProps> = ({
  widget,
  answers,
  isShowNumber,
  widgetNumber
}) => {
  const widgetsTitleMap = {
    [WidgetsTypes.SWIPE_UP]: t('dashboard.analytics.names.link'),
    [WidgetsTypes.SLIDER]: t('dashboard.analytics.names.answers'),
    [WidgetsTypes.QUESTION]: t('dashboard.analytics.names.answers'),
    [WidgetsTypes.CLICK_ME]: t('dashboard.analytics.names.button'),
    [WidgetsTypes.TALK_ABOUT]: t('dashboard.analytics.names.answers'),
    [WidgetsTypes.EMOJI_REACTION]: t('dashboard.analytics.names.answers'),
    [WidgetsTypes.CHOOSE_ANSWER]: t('dashboard.analytics.names.answers'),
    [WidgetsTypes.QUIZ_MULTIPLE_ANSWERS]: t('dashboard.analytics.names.answers'),
    [WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE]: t('dashboard.analytics.names.answers'),
    [WidgetsTypes.QUIZ_ONE_ANSWER]: t('dashboard.analytics.names.answers'),
    [WidgetsTypes.QUIZ_OPEN_ANSWER]: t('dashboard.analytics.names.answers')
  };

  const widgetTitle = getWidgetTitle(widget);

  return (
    <div className={b()}>
      {Object.prototype.hasOwnProperty.call(widgetsTitleMap, widget.content.type) && (
        <p className={b('statListTitle')}>
          <>
            {/* @ts-ignore */}
            {widgetsTitleMap[widget.content.type]}
            {widgetNumber && widgetNumber > 0 && isShowNumber && `#${widgetNumber}`}
          </>
        </p>
      )}

      {widgetTitle && <p className={b('statListWidgetTitle')}>{widgetTitle}</p>}

      {answers.map((item) => {
        const answerInfo = getAnswer(widget.content.type, item.answer, t);

        return (
          <div className={b('statListItem')} key={`answer-${item.answer.title}`}>
            <div className={b('statListItemTopContainer')}>
              <div className={b('statListItemTopContainerLeft')}>
                <div className={b('statListItemTitleContainer')}>
                  {answerInfo.emoji && (
                    <span className={b('statListItemEmoji')}>{answerInfo.emoji}</span>
                  )}
                  <span className={b('statListItemTitle')}>{answerInfo.title}</span>
                </div>

                {item.percent !== undefined &&
                  item.count !== undefined &&
                  isShowPercent(widget.content.type) && (
                    <span className={b('statListItemValueAdditional')}>{`${item.percent}%`}</span>
                  )}
              </div>

              {widget.content.type !== WidgetsTypes.TALK_ABOUT &&
                widget.content.type !== WidgetsTypes.QUIZ_OPEN_ANSWER && (
                  <div className={b('statListItemTopContainerRight')}>
                    <span className={b('statListItemValue')}>{item.count}</span>
                  </div>
                )}
            </div>

            {item.percent !== undefined &&
              item.percent > 0 &&
              item.count !== undefined &&
              isShowPercent(widget.content.type) && (
                <div className={b('statListItemBottomContainer')}>
                  <div className={b('statPercentLine')}>
                    <div
                      className={b('statPercentLineValue')}
                      style={{ width: `${item.percent}%` }}
                    />
                  </div>
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};
