import React from 'react';
import block from 'bem-cn';
import './StoryCardMessage.scss';

interface StoryCardMessageProps {
  text: string;
  theme?: 'dark' | 'light';
}

const b = block('StoryCardMessage');

export const StoryCardMessage: React.FC<StoryCardMessageProps> = ({ text, theme }) => (
  <div className={b({ theme })}>
    <p className={b('text')}>{text}</p>
  </div>
);
