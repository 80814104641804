import React from 'react';
import PropTypes from 'prop-types';

const IconActionsFresh = ({ className }) => (
  <svg
    className={className}
    fill="#FD19CC"
    height="5"
    viewBox="0 0 20 5"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.25614 0C1.00987 0 0 1.01019 0 2.25614C0 3.50241 1.01019 4.51228 2.25614 4.51228C3.50177 4.51228 4.51284 3.50209 4.51284 2.25614C4.51284 1.00987 3.50177 0 2.25614 0ZM9.99988 0C8.75361 0 7.74374 1.01019 7.74374 2.25614C7.74374 3.50241 8.75393 4.51228 9.99988 4.51228C11.2455 4.51228 12.2566 3.50209 12.2566 2.25614C12.2563 1.00987 11.2455 0 9.99988 0ZM17.7433 0C16.4977 0 15.4875 1.01019 15.4875 2.25614C15.4875 3.50241 16.4977 4.51228 17.7433 4.51228C18.9889 4.51228 20 3.50209 20 2.25614C20 1.00987 18.9889 0 17.7433 0Z" />
  </svg>
);

IconActionsFresh.propTypes = {
  className: PropTypes.string
};

IconActionsFresh.defaultProps = {
  className: ''
};

export default IconActionsFresh;
