import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshModal, FreshModalTitle, FreshButton } from '@components/_fresh';

import './PlanCancelModal.scss';

interface PlanCancelModalProps {
  isOpen: boolean;
  onClose(): void;
  handleCancelPlan(): void;
}

export const PlanCancelModal: React.FC<PlanCancelModalProps> = (props) => {
  const { isOpen, onClose, handleCancelPlan } = props;

  const b = block('PlanCancelModal');
  const { t } = useTranslation();

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle centered>{t('dashboard.modals.cancelPlan.title')}</FreshModalTitle>

        <section className={b('btnContaier')}>
          <FreshButton
            color="grey"
            text={t('dashboard.modals.cancelPlan.close')}
            onClick={onClose}
          />
          <FreshButton
            color="black"
            text={t('dashboard.modals.cancelPlan.cancelNow')}
            onClick={handleCancelPlan}
          />
        </section>
      </div>
    </FreshModal>
  );
};
