import React, { useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { renderWidgetControl } from '@features/stories/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { WidgetInitSize } from '@features/stories/consts';
import { useTranslation } from 'react-i18next';
import { WidgetObjectType, WidgetsTypes } from '@storysdk/react';
import { OutputAnswerItemType, STAT_WIDGETS_TYPES, WIDGETS_WITH_BACKGROUND } from '@features';
import { getAnswer, isShowPercent } from '@features/storiesAnalyticsFeature/utils';
import { IconSliderArrow } from '@components';
import { FreshModal } from '@components/_fresh';
import { getAnswerVariantsData, shortEnglishHumanizer } from '@utils';
import './StoryAnalyticsModal.scss';
import 'swiper/swiper.scss';

const b = block('StoryAnalyticsModal');

interface StoryAnalyticsModalProps {
  isOpen: boolean;
  onClose(): void;
}

SwiperCore.use([Navigation]);

export const StoryAnalyticsModal: React.FC<StoryAnalyticsModalProps> = (props) => {
  const { isOpen, onClose } = props;

  const storyData = useSelector((store: RootState) => store.storiesAnalyticsGroup.currentStory);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [widgets, setWidgets] = useState<WidgetObjectType[]>([]);
  const [activeWidget, setAciveWidget] = useState<any>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (storyData && storyData.widgets.length) {
      const widgetsFiltered = [...storyData.widgets].filter((widget) =>
        STAT_WIDGETS_TYPES.find((type) => type === widget.content.type)
      );

      setWidgets(widgetsFiltered);

      if (widgetsFiltered.length && storyData.statistic) {
        setAciveWidget({
          id: widgetsFiltered[0].id,
          type: widgetsFiltered[0].content.type,
          params: widgetsFiltered[0].content.params,
          statistic: storyData.statistic.interactions
            ? storyData.statistic.interactions[widgetsFiltered[0].id]
            : null
        });
      }
    } else {
      setWidgets([]);
      setAciveWidget(null);
    }
  }, [storyData]);

  const handleChangeWidget = (index: number) => {
    if (widgets[index] && storyData && storyData.statistic) {
      setAciveWidget({
        id: widgets[index].id,
        type: widgets[index].content.type,
        params: widgets[index].content.params,
        statistic: storyData.statistic.interactions
          ? storyData.statistic.interactions[widgets[index].id]
          : null
      });
    }
  };

  return (
    <>
      {storyData ? (
        <FreshModal
          className={b('modal')}
          isOpen={isOpen}
          position="right"
          size="md"
          onClose={onClose}
        >
          <div className={b()}>
            {widgets.length ? (
              <div className={b('widgetsStat')}>
                <p className={b('title')}>{t('dashboard.analytics.storyModal.title')}</p>
                <div className={b('widgetsContainer')}>
                  <Swiper
                    centeredSlides
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current
                    }}
                    slidesPerView={1}
                    onBeforeInit={(swiper) => {
                      if (
                        swiper &&
                        swiper.params.navigation &&
                        typeof swiper.params.navigation !== 'boolean'
                      ) {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                      }

                      swiper.navigation.update();
                    }}
                    onSlideChange={(swiper) => handleChangeWidget(swiper.activeIndex)}
                  >
                    {widgets.map((widget) => (
                      <SwiperSlide key={widget.id}>
                        <div
                          className={b('widgetContainer', {
                            background: WIDGETS_WITH_BACKGROUND.includes(widget.content.type)
                          })}
                        >
                          <div className={b('widget')} style={WidgetInitSize[widget.content.type]}>
                            {renderWidgetControl({
                              content: widget.content
                            })}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}

                    {widgets.length > 1 ? (
                      <>
                        <button
                          aria-label="Prev"
                          className={b('widgetsNav', { prev: true })}
                          ref={navigationPrevRef}
                        >
                          <IconSliderArrow className={b('widgetsNavIcon', { prev: true })} />
                        </button>
                        <button
                          aria-label="Next"
                          className={b('widgetsNav')}
                          ref={navigationNextRef}
                        >
                          <IconSliderArrow className={b('widgetsNavIcon', { next: true })} />
                        </button>
                      </>
                    ) : null}
                  </Swiper>
                </div>

                <div className={b('mainIndicatorContainer')}>
                  <p className={b('mainIndicatorValue')}>
                    {activeWidget?.statistic?.reactions || 0}
                  </p>
                  <p className={b('mainIndicatorDesc')}>
                    {t('dashboard.analytics.storyModal.reactions')}
                  </p>
                </div>

                {activeWidget && activeWidget.statistic && activeWidget.statistic.variants && (
                  <div className={b('statList')}>
                    {getAnswerVariantsData(
                      activeWidget.type,
                      activeWidget.statistic.variants,
                      activeWidget.params
                    ).map((item: OutputAnswerItemType) => {
                      const answerInfo = getAnswer(activeWidget.type, item.answer, t);

                      return (
                        <div className={b('statListItem')} key={`answer-${item.answer.title}`}>
                          <div className={b('statListItemTitleContainer')}>
                            {answerInfo.emoji && (
                              <span className={b('statListItemEmoji')}>{answerInfo.emoji}</span>
                            )}
                            <span className={b('statListItemTitle')}>{answerInfo.title}</span>
                          </div>

                          {activeWidget.type !== WidgetsTypes.TALK_ABOUT &&
                            activeWidget.type !== WidgetsTypes.QUIZ_OPEN_ANSWER && (
                              <span className={b('statListItemValue')}>
                                {item.count}{' '}
                                {item.percent && item.count && isShowPercent(activeWidget.type) && (
                                  <span className={b('statListItemValueAdditional')}>
                                    ({item.percent}%)
                                  </span>
                                )}
                              </span>
                            )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}

            <div className={b('storyStat')}>
              <p className={b('title')}>{t('dashboard.analytics.storyModal.statistics')}</p>

              <div className={b('mainIndicatorContainer')}>
                <p className={b('mainIndicatorValue')}>{storyData.statistic?.views || 0}</p>
                <p className={b('mainIndicatorDesc')}>{t('dashboard.analytics.storyModal.show')}</p>
              </div>

              <div className={b('statList')}>
                <div className={b('statListItem')}>
                  <span className={b('statListItemTitle', { bold: true })}>
                    {t('dashboard.analytics.storyModal.navigation')}
                  </span>
                  <span className={b('statListItemValue', { bold: true })}>
                    {(storyData.statistic?.back || 0) +
                      (storyData.statistic?.next || 0) +
                      (storyData.statistic?.close || 0)}
                  </span>
                </div>

                <div className={b('statListItem')}>
                  <span className={b('statListItemTitle', { thin: true, grey: true })}>
                    {t('dashboard.analytics.storyModal.nextStory')}
                  </span>
                  <span className={b('statListItemValue', { thin: true, grey: true })}>
                    {storyData.statistic?.next || 0}
                  </span>
                </div>

                <div className={b('statListItem')}>
                  <span className={b('statListItemTitle', { thin: true, grey: true })}>
                    {t('dashboard.analytics.storyModal.back')}
                  </span>
                  <span className={b('statListItemValue', { thin: true, grey: true })}>
                    {storyData.statistic?.back || 0}
                  </span>
                </div>

                <div className={b('statListItem')}>
                  <span className={b('statListItemTitle', { thin: true, grey: true })}>
                    {t('dashboard.analytics.storyModal.close')}
                  </span>
                  <span className={b('statListItemValue', { thin: true, grey: true })}>
                    {storyData.statistic?.close || 0}
                  </span>
                </div>

                <div className={b('statListItem')}>
                  <span className={b('statListItemTitle', { bold: true })}>
                    {t('dashboard.analytics.storyModal.averageTime')}
                  </span>
                  <span className={b('statListItemValue', { bold: true })}>
                    {storyData.statistic?.duration
                      ? shortEnglishHumanizer(storyData.statistic.duration * 1000)
                      : '0s'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </FreshModal>
      ) : null}
    </>
  );
};
