import React from 'react';
import { SettingsBox } from '@modules';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonSettings: React.FC = () => (
  <SkeletonTheme baseColor="#05051d" highlightColor="#0e0e2a">
    <SettingsBox>
      <SettingsBox.Group title={<Skeleton height={15} />}>
        <SettingsBox.Field>
          <Skeleton height={34} style={{ borderRadius: 8 }} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={<Skeleton height={15} />}>
        <SettingsBox.Field>
          <Skeleton height={34} style={{ borderRadius: 8 }} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={<Skeleton height={15} />}>
        <SettingsBox.Field>
          <Skeleton height={34} style={{ borderRadius: 8 }} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={<Skeleton height={15} />}>
        <SettingsBox.Field>
          <Skeleton height={34} style={{ borderRadius: 8 }} />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <Skeleton height={34} style={{ borderRadius: 8 }} />
        </SettingsBox.Field>
      </SettingsBox.Group>
    </SettingsBox>
  </SkeletonTheme>
);

export default SkeletonSettings;
