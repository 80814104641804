import React from 'react';
import block from 'bem-cn';
import { Grid, SearchBar, SearchContextManager, SearchContext } from '@giphy/react-components';
import { IGif } from '@giphy/js-types';
import { DEFAULT_WIDGET_PARAMS, SIZE_PRESETS, getScalableValue } from '@features';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { getWidgetPositionLimits } from '@features/stories/consts';
import { WidgetObjectType, WidgetsTypes } from '@storysdk/react';
import { StoryEditorAction, useStoryEditorDispatch } from '@modules';
import './GiphyWidgetList.scss';

const b = block('GiphyWidgetList');

const GiphyWidgetListComponent: React.FC = () => {
  const { fetchGifs, searchKey } = React.useContext(SearchContext);

  const { t } = useTranslation();
  const storyEditorDispatch = useStoryEditorDispatch();
  const position = {
    rotate: 0,
    x: getScalableValue(DEFAULT_WIDGET_PARAMS.x),
    y: getScalableValue(DEFAULT_WIDGET_PARAMS.y),
    width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
    height: getScalableValue(DEFAULT_WIDGET_PARAMS.height),
    realWidth: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
    realHeight: getScalableValue(DEFAULT_WIDGET_PARAMS.height),
    isHeightLocked: false,
    origin: {
      x: getScalableValue(DEFAULT_WIDGET_PARAMS.x),
      y: getScalableValue(DEFAULT_WIDGET_PARAMS.y),
      width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
      height: getScalableValue(DEFAULT_WIDGET_PARAMS.height)
    }
  };

  const handleGifClick = (gif: IGif) => {
    const positionByResolutions: WidgetObjectType['positionByResolutions'] = {};

    for (const key in SIZE_PRESETS) {
      if (Object.prototype.hasOwnProperty.call(SIZE_PRESETS, key)) {
        const { width, height } = SIZE_PRESETS[key];
        positionByResolutions[`${width}x${height}`] = position;
      }
    }

    storyEditorDispatch?.({
      type: StoryEditorAction.ADD_STORY_WIDGET,
      payload: {
        id: `WG${nanoid()}`,
        positionByResolutions,
        positionLimits: getWidgetPositionLimits(WidgetsTypes.GIPHY),
        content: {
          type: WidgetsTypes.GIPHY,
          params: {
            gif: gif.images.original.webp,
            widgetOpacity: 100,
            borderRadius: 0
          }
        }
      }
    });
  };

  return (
    <div className={b()}>
      <SearchBar className={b('search')} placeholder={t('editor.searchGiphy')} />
      <div className={b('grid')}>
        <Grid
          columns={2}
          fetchGifs={fetchGifs}
          gutter={8}
          hideAttribution
          key={searchKey}
          noLink
          width={200}
          onGifClick={handleGifClick}
        />
      </div>
    </div>
  );
};

export const GiphyWidgetList = () => (
  <SearchContextManager apiKey="Oy86PFXScJZ4XlmDyfUBFuhITBlXZdJN">
    <GiphyWidgetListComponent />
  </SearchContextManager>
);
