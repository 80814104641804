import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { AppCollaborationFeatures, useRedirectByAccessCollaborator } from '@features';
import { useGetCurrentApp } from '@features/stories/hooks';
import { Tabs, TabType } from '@components';
import { OrderSummaryTab } from '../../components';
import './AppBillingRoute.scss';

const b = block('AppBillingRoute');

export const AppBillingRoute: React.FC = () => {
  const { t } = useTranslation();
  const currentApp = useGetCurrentApp();

  useRedirectByAccessCollaborator({
    feature: AppCollaborationFeatures.BILLING_APP,
    app: currentApp ?? undefined
  });

  const tabsItems: TabType[] = [
    {
      id: 'summary',
      title: t('dashboard.billing.orderSummary'),
      component: OrderSummaryTab
    }
  ];

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <Tabs tabs={tabsItems} />
      </div>
    </div>
  );
};
