import { GoogleFont } from '@features';
import { WidgetPositionType, WidgetsTypes } from '@storysdk/react';
import { MaterialIconToString } from '@components';
import {
  getFontLink,
  renderTextBackgroundStyles,
  renderBackgroundStyles,
  EXCLUDE_FONT_FAMILY
} from '.';

export const getHtmlWidgetLayout = (
  widget: WidgetsTypes,
  position: WidgetPositionType,
  params: any,
  fonts: GoogleFont[]
) => {
  const fontLink = getFontLink(fonts);
  const IMAGE_QUALITY_INDEX = 1;

  switch (widget) {
    case WidgetsTypes.TEXT:
      if (EXCLUDE_FONT_FAMILY.includes(params.fontFamily)) {
        return null;
      }

      return {
        width: Math.round(position.realWidth * IMAGE_QUALITY_INDEX),
        height: Math.round(position.realHeight * IMAGE_QUALITY_INDEX),
        head: fontLink || '',
        body: `<div class="TextWidgetControl" style="opacity: ${
          params.widgetOpacity ? +params.widgetOpacity / 100 : 1
        };" ><div class="TextWidgetControl__container ${
          params.color.type === 'gradient' ? 'TextWidgetControl__container_gradient' : ''
        }" style="font-style: ${params.fontParams.style}; font-weight: ${
          params.fontParams.weight
        }; font-family: ${params.fontFamily}; font-size: ${Math.round(
          params.fontSize * IMAGE_QUALITY_INDEX
        )}px; text-align: ${params.align}; ${renderTextBackgroundStyles({
          color: params.color,
          returnType: 'string'
        })}"><span class="TextWidgetControl__span">${JSON.parse(
          JSON.stringify(params.text)
        )}</span></div>${
          params.withFill
            ? `<div class="TextWidgetControl__background" style="background: ${renderBackgroundStyles(
                params.backgroundColor
              )}"></div>`
            : ''
        }</div>`,
        css: `
        html, body {
          margin: 0;
          padding: 0;
        }
        * {
          box-sizing: border-box;
        }
        .TextWidgetControl {
          position: relative;
          border-radius: 8px;
          overflow: hidden;
        }
        .TextWidgetControl__container {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .TextWidgetControl__container_gradient {
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent;
        }
        .TextWidgetControl__span {
          word-break: break-word;
          white-space: pre-wrap;
        }
        .TextWidgetControl__background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
        }`
      };
    case WidgetsTypes.CLICK_ME:
      return {
        width: Math.round(position.realWidth * IMAGE_QUALITY_INDEX),
        height: Math.round(position.realHeight * IMAGE_QUALITY_INDEX),
        css: `
        html, body {
          margin: 0;
          padding: 0;
        }
        * {
          box-sizing: border-box;
        }
        .ClickMeWidgetControl {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
          flex-grow: 1;
        }
        .ClickMeWidgetControl__container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 14px;
          position: relative;
          z-index: 2;
        }
        .ClickMeWidgetControl__background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .ClickMeWidgetControl__icon {
          padding: 0;
          margin-right: 9px;
          display: flex;
          align-self: center;
          background: none;
          border: none;
          justify-content: center;
          align-items: center;
        }
        .MaterialIcon>svg {
          width: 100%;
          height: 100%;
        }`,
        head: fontLink || '',
        body: `<div class="ClickMeWidgetControl" style="height: ${Math.round(
          position.realHeight * IMAGE_QUALITY_INDEX
        )}px; border-radius: ${
          params.borderRadius * IMAGE_QUALITY_INDEX
        }px; border-style: solid; border-width: ${
          params.hasBorder ? params.borderWidth : 0
        }px; border-color: ${
          params.borderColor.value
        }"><div class="ClickMeWidgetControl__container ${
          params.color.type === 'gradient' ? 'ClickMeWidgetControl__container_gradient' : ''
        }" style="font-style: ${params.fontParams.style}; font-weight: ${
          params.fontParams.weight
        }; font-family: ${params.fontFamily}; font-size: ${Math.round(
          params.fontSize * IMAGE_QUALITY_INDEX
        )}px; ${renderTextBackgroundStyles({
          color: params.color,
          returnType: 'string'
        })}">${
          params.hasIcon
            ? MaterialIconToString({
                name: params.icon.name,
                className: 'ClickMeWidgetControl__icon',
                color: renderBackgroundStyles(params.color),
                background: params.color,
                size: params.iconSize * IMAGE_QUALITY_INDEX
              })
            : ''
        }<span class="ClickMeWidgetControl__text" style="opacity: ${
          params.opacity ? +params.opacity / 100 : 1
        };">${
          params.text
        }</span></div><div class="ClickMeWidgetControl__background" style="background: ${renderBackgroundStyles(
          params.backgroundColor
        )};"></div></div>`
      };

    case WidgetsTypes.SWIPE_UP:
      return {
        width: Math.round(position.realWidth * IMAGE_QUALITY_INDEX),
        height: Math.round(position.realHeight * IMAGE_QUALITY_INDEX),
        css: ` 
        html, body {
          margin: 0;
          padding: 0;
        }
        * {
          box-sizing: border-box;
        }
        .SwipeUpWidgetControl {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .SwipeUpWidgetControl__icon {
          display: flex;
          margin-bottom: 2px;
        } 
        .SwipeUpWidgetControl__text {
          text-align: center;
          line-height: 120%;
        }
        .MaterialIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
        }
        .MaterialIcon>svg {
          width: 100%;
          height: 100%;
        }
        `,
        head: fontLink || '',
        body: `<div class="SwipeUpWidgetControl" style="font-family: ${
          params.fontFamily
        }; font-size: ${Math.round(params.fontSize * IMAGE_QUALITY_INDEX)}px; font-style: ${
          params.fontParams.style
        }; font-weight: ${params.fontParams.weight}; ${renderTextBackgroundStyles({
          color: params.color,
          returnType: 'string'
        })};"><div class="SwipeUpWidgetControl__icon">
        ${
          params.hasIcon
            ? MaterialIconToString({
                name: params.icon.name,
                color: renderBackgroundStyles(params.color),
                background: params.color,
                size: params.iconSize * IMAGE_QUALITY_INDEX
              })
            : ''
        }</div><span class="SwipeUpWidgetControl__text">${params.text}</span></div>`
      };
    case WidgetsTypes.RECTANGLE:
      return {
        width: Math.round(position.realWidth * IMAGE_QUALITY_INDEX),
        height: Math.round(position.realHeight * IMAGE_QUALITY_INDEX),
        head: fontLink || '',
        body: `<div style="width: ${Math.round(
          position.realWidth * IMAGE_QUALITY_INDEX
        )}px; height: ${Math.round(
          position.realHeight * IMAGE_QUALITY_INDEX
        )}px"><div class="RectangleWidgetControl" style="border-radius: ${
          params.fillBorderRadius * IMAGE_QUALITY_INDEX
        }px; border-style: solid; border-width: ${
          params.hasBorder ? params.strokeThickness : 0
        }px; border-color: ${renderBackgroundStyles(params.strokeColor)}; opacity: ${
          params.widgetOpacity ? +params.widgetOpacity / 100 : 1
        };"><div class="RectangleWidgetControl__background" style="background: ${renderBackgroundStyles(
          params.fillColor
        )}; border-radius: ${
          params.fillBorderRadius - params.strokeThickness
        }px;"></div></div></div>`,
        css: `
        html, body {
          margin: 0;
          padding: 0;
        }
        * {
          box-sizing: border-box;
        }
        .RectangleWidgetControl {
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
          flex-grow: 1;
        }
        .RectangleWidgetControl__background {
          width: 100%;
          height: 100%;
        }`
      };
    case WidgetsTypes.ELLIPSE:
      return {
        width: Math.round(position.realWidth * IMAGE_QUALITY_INDEX),
        height: Math.round(position.realHeight * IMAGE_QUALITY_INDEX),
        head: fontLink || '',
        body: `<div style="width: ${Math.round(
          position.realWidth * IMAGE_QUALITY_INDEX
        )}px; height: ${Math.round(
          position.realHeight * IMAGE_QUALITY_INDEX
        )}px"><div class="EllipseWidgetControl" style="border-style: solid; border-width: ${
          params.hasBorder ? params.strokeThickness : 0
        }px; border-color: ${renderBackgroundStyles(params.strokeColor)}; opacity: ${
          params.widgetOpacity ? +params.widgetOpacity / 100 : 1
        };"><div class="EllipseWidgetControl__background" style="background: ${renderBackgroundStyles(
          params.fillColor
        )};"></div></div></div>`,
        css: `
        html, body {
          margin: 0;
          padding: 0;
        }
        * {
          box-sizing: border-box;
        }
        .EllipseWidgetControl {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          flex-grow: 1;
        }        
        .EllipseWidgetControl__background {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
        }`
      };
  }
  return { body: '<div></div>' };
};
