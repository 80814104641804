import React, { useEffect } from 'react';
import { RootState, useAppDispatch } from '@store';
import { fetchGetFonts } from '@features/fonts';
import { useSelector } from 'react-redux';
import { GoogleFont } from '@features/stories/editorSettings/components/SettingsFontSelector/SettingsFontSelector';
import { getFontsLink } from '@utils';

const createLinkElement = () => {
  const headID = document.getElementsByTagName('head')[0];
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.id = 'googleFonts';
  headID.appendChild(link);

  return link;
};

const updateLinkHref = (link: any, fonts: GoogleFont[]) => {
  if (fonts.length === 0) return;

  link.href = getFontsLink(fonts);
};

const loadFontsToPage = (fonts: GoogleFont[]) => {
  const googleFontsLink = document.getElementById('googleFonts');

  if (googleFontsLink && fonts.length) {
    updateLinkHref(googleFontsLink, fonts);
    return;
  }

  updateLinkHref(createLinkElement(), fonts);
};

export const FontsContainer: React.FC = () => {
  const appManager = useSelector((store: RootState) => store.appManager);
  const fonts = useSelector((store: RootState) => store.fonts).fonts;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appManager.current) {
      dispatch(fetchGetFonts());
    }
  }, [appManager.current?.id]);

  useEffect(() => {
    loadFontsToPage(fonts);
  }, [fonts]);

  return null;
};
