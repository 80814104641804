import {
  informSlice,
  MessageTypes,
  BackgroundBlob,
  onUploadProgress,
  GroupsType,
  fetchUpdateTemplateStoryWidgetBackground,
  fetchUpdateStoryWidgetBackground
} from '@features';
import StoryWidgetFactory from '@features/stories/utils/StoryWidgetFactory';
import { WIDGET_PARAMS } from '@modules/EditorModule/components/EditorLayout/components/EditorSidebar/_components/consts';
import { MediaType, WidgetsTypes, WidgetObjectType } from '@storysdk/react';
import { nanoid } from 'nanoid';
import { useAppDispatch } from '@store';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesSlice } from '@features/stories/hooks';

export const useCreateMediaWidget = () => {
  const dispatch = useAppDispatch();

  const { type } = useParams<{
    type: GroupsType;
  }>();

  const currentStoriesSlice = useCurrentStoriesSlice(type);

  return async (contentType: MediaType, file: Blob, metadata?: any) => {
    const newModalId = nanoid(6);

    dispatch(
      informSlice.actions.addMessage({
        id: newModalId,
        type: MessageTypes.PROGRESS,
        // @ts-ignoresss
        text: file.name
      })
    );

    let newWidget;

    if (contentType === MediaType.IMAGE) {
      const widget = StoryWidgetFactory.makeWithPosition({
        type: WidgetsTypes.IMAGE,
        position: {
          x: WIDGET_PARAMS[WidgetsTypes.IMAGE].x,
          y: WIDGET_PARAMS[WidgetsTypes.IMAGE].y,
          width: WIDGET_PARAMS[WidgetsTypes.IMAGE].width,
          height: WIDGET_PARAMS[WidgetsTypes.IMAGE].height,
          realWidth: WIDGET_PARAMS[WidgetsTypes.IMAGE].width,
          realHeight: WIDGET_PARAMS[WidgetsTypes.IMAGE].height,
          isHeightLocked: true,
          rotate: 0,
          origin: {
            x: WIDGET_PARAMS[WidgetsTypes.IMAGE].x,
            y: WIDGET_PARAMS[WidgetsTypes.IMAGE].y,
            width: WIDGET_PARAMS[WidgetsTypes.IMAGE].width,
            height: WIDGET_PARAMS[WidgetsTypes.IMAGE].height
          }
        }
      });

      newWidget = {
        ...widget,
        content: {
          ...widget.content,
          params: {
            ...widget.content.params,
            imageUrl: URL.createObjectURL(file)
          }
        }
      } as WidgetObjectType;
    } else if (contentType === MediaType.VIDEO) {
      const widget = StoryWidgetFactory.makeWithPosition({
        type: WidgetsTypes.VIDEO,
        position: {
          x: WIDGET_PARAMS[WidgetsTypes.VIDEO].x,
          y: WIDGET_PARAMS[WidgetsTypes.VIDEO].y,
          width: WIDGET_PARAMS[WidgetsTypes.VIDEO].width,
          height: WIDGET_PARAMS[WidgetsTypes.VIDEO].height,
          realWidth: WIDGET_PARAMS[WidgetsTypes.VIDEO].width,
          realHeight: WIDGET_PARAMS[WidgetsTypes.VIDEO].height,
          isHeightLocked: true,
          rotate: 0,
          origin: {
            x: WIDGET_PARAMS[WidgetsTypes.VIDEO].x,
            y: WIDGET_PARAMS[WidgetsTypes.VIDEO].y,
            width: WIDGET_PARAMS[WidgetsTypes.VIDEO].width,
            height: WIDGET_PARAMS[WidgetsTypes.VIDEO].height
          }
        }
      });

      newWidget = {
        ...widget,
        content: {
          ...widget.content,
          params: {
            ...widget.content.params,
            videoUrl: URL.createObjectURL(file),
            metadata
          }
        }
      } as WidgetObjectType;
    } else {
      newWidget = StoryWidgetFactory.make(WidgetsTypes.RECTANGLE);
    }

    dispatch(currentStoriesSlice.actions.addStoryWidget({ widget: newWidget, preventSave: true }));

    const params = {
      widgetId: newWidget.id,
      widgetType: newWidget.content.type,
      background: { type: contentType, value: file, metadata } as BackgroundBlob,
      onUploadProgress: onUploadProgress(dispatch),
      modalProgressId: newModalId,
      mediaType: contentType
    };

    if (type === GroupsType.TEMPLATE) {
      dispatch(fetchUpdateTemplateStoryWidgetBackground(params));
    } else {
      dispatch(fetchUpdateStoryWidgetBackground(params));
    }
  };
};
