import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import './CreateAppContainer.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { FormGroup, FormLabel } from '@components';
import { FreshModalTitle, FreshInput, FreshButton } from '@components/_fresh';
import { appsManagerSlice, fetchCreateApp } from '../../appsManagerSlice';
import { FormState } from '../../types';

const b = block('CreateAppContainer');

interface Props {
  className?: string;
  title?: string;
  setOpenModal?: (param: boolean) => void;
}

type InputEvent = { value: string; fieldName: string };

const initFormState: FormState = {
  name: '',
  localization: {
    default: 'en',
    languages: ['en']
  }
};

export const CreateAppContainer: React.FC<Props> = (props) => {
  const { className, title, setOpenModal } = props;
  const [formState, setFormState] = useState<FormState>(initFormState);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const isFirstUsing = useSelector((store: RootState) => store.appManager.isFirstUsing);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (String(formState.name).trim().length !== 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [formState]);

  const dispatch = useAppDispatch();

  const handleChange = ({ value, fieldName }: InputEvent) =>
    setFormState({ ...formState, [fieldName]: value });

  const handleCreate = () => {
    setButtonDisabled(true);

    dispatch(fetchCreateApp(formState))
      .unwrap()
      .then((appId) => {
        if (isFirstUsing) {
          dispatch(appsManagerSlice.actions.setIsFirstUsing(false));
          return;
        }

        if (appId) {
          history.push(`/dashboard/${appId}/stories`);
        } else {
          history.push(`/dashboard`);
        }

        setFormState(initFormState);
      });

    if (setOpenModal) {
      setOpenModal(false);
    }
  };

  return (
    <div className={className}>
      <FreshModalTitle>{title ?? t('dashboard.modals.addNewApp.title')}</FreshModalTitle>
      <div className={b()}>
        <FormGroup>
          <FormLabel>{t('dashboard.modals.addNewApp.appName')}</FormLabel>
          <FreshInput
            fieldName="name"
            placeholder={t('dashboard.modals.addNewApp.appNamePlaceholder')}
            type="text"
            value={formState.name}
            onChange={handleChange}
          />
        </FormGroup>
      </div>
      <FreshButton
        className={b('btn')}
        disabled={buttonDisabled}
        text={t('dashboard.modals.addNewApp.addBtn')}
        onClick={handleCreate}
      />
    </div>
  );
};
