import React from 'react';
import block from 'bem-cn';
import { useHistory } from 'react-router-dom';
import { FreshButton } from '@components/_fresh';
import { Icon, IconWarningCircleFresh } from '@components';
import './HeaderAction.scss';

interface HeaderActionProps {
  text: string;
  icon?: string;
  btnIcon?: string;
  actionText: string;
  actionLink?: string;
  onClick?: () => void;
  isExternal?: boolean;
}

const b = block('HeaderAction');

export const HeaderAction: React.FC<HeaderActionProps> = ({
  text,
  actionLink,
  actionText,
  isExternal,
  icon,
  btnIcon,
  onClick
}) => {
  const history = useHistory();

  return (
    <div className={b()}>
      <div className={b('message')}>
        {icon ? (
          <Icon className={b('icon').toString()} name={icon} variant="icons" />
        ) : (
          <IconWarningCircleFresh className={b('icon')} />
        )}

        <p className={b('text')}>{text}</p>
      </div>
      <FreshButton
        leftIcon={btnIcon ? <Icon name={btnIcon} variant="icons" /> : undefined}
        size="sm"
        text={actionText}
        onClick={() => {
          if (onClick) {
            onClick();
          } else if (actionLink) {
            if (isExternal) {
              window.open(actionLink, '_blank');
            } else {
              history.push(actionLink);
            }
          }
        }}
      />
    </div>
  );
};
