import React from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import { ButtonType } from './_types';
import './FreshButton.scss';

type PropTypes = {
  className?: string;
  disabled?: boolean;
  color?:
  | 'pink'
  | 'grey'
  | 'black'
  | 'white'
  | 'dark-grey'
  | 'transparent-grey'
  | 'grey-light'
  | 'transparent-grey-light'
  | 'dark';
  type?: ButtonType;
  leftIcon?: React.ReactElement;
  size?: 'lg' | 'md' | 'sm';
  text: string;
  onClick?: (params: any) => void;
};

const b = block('FreshButton');

export const FreshButton = ({
  className,
  size = 'lg',
  text,
  disabled,
  type = 'button',
  color = 'pink',
  leftIcon: LeftIcon,
  onClick
}: PropTypes) => (
  <button
    className={cn(b({ size, color }).toString(), className?.toString())}
    disabled={disabled}
    type={type}
    onClick={onClick}
  >
    {LeftIcon}
    {text && <span className={b('text', { marginLeft: !!LeftIcon })}>{text}</span>}
  </button>
);
