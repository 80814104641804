import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import axios from 'axios';
import { Auth } from '@utils';

export const ConfirmEmailRedirectPage: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const userState = useSelector((store: RootState) => store.user);

  useEffect(() => {
    if (Auth.isLoggedIn && userState.user.isEmailConfirmed) {
      history.push('/dashboard');
    } else if (token) {
      fetch(`${process.env.REACT_APP_API_URL}/auth/confirm-email/${token}`, {
        method: 'get'
      })
        .then((r) => r.json())
        .then((response) => {
          if (response.user) {
            Auth.setStorage({
              ...response,
              user: { ...response.user }
            });

            axios.defaults.headers.common = { Authorization: `Bearer ${response.access_token}` };

            history.push('/dashboard');
          } else {
            history.push('/');
          }
        })
        .catch(() => {
          history.push('/');
        });
    } else {
      history.push('/');
    }
  }, [history, token]);

  return null;
};
