import React from 'react';
import PropTypes from 'prop-types';

const IconDashboardCard = ({ className }) => (
  <svg
    className={className}
    fill="#A49BA4"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.124 5H5.876C5.11252 5.00106 4.38071 5.30523 3.84141 5.84565C3.30211 6.38608 2.99947 7.11852 3 7.882V15.882C2.99947 16.6455 3.30211 17.3779 3.84141 17.9183C4.38071 18.4588 5.11252 18.7629 5.876 18.764H18.124C18.8875 18.7629 19.6193 18.4588 20.1586 17.9183C20.6979 17.3779 21.0005 16.6455 21 15.882V7.882C21.0005 7.11852 20.6979 6.38608 20.1586 5.84565C19.6193 5.30523 18.8875 5.00106 18.124 5ZM5.876 5.902H18.124C18.3837 5.90226 18.6407 5.9537 18.8805 6.05337C19.1203 6.15304 19.338 6.29899 19.5214 6.48288C19.7047 6.66676 19.85 6.88498 19.9489 7.12505C20.0479 7.36512 20.0985 7.62234 20.098 7.882V7.95H3.898V7.882C3.89747 7.622 3.94826 7.36446 4.04746 7.12413C4.14665 6.88379 4.29229 6.6654 4.47605 6.48146C4.6598 6.29752 4.87805 6.15166 5.11828 6.05222C5.35851 5.95278 5.616 5.90174 5.876 5.902ZM18.124 17.862H5.876C5.61634 17.8617 5.35928 17.8103 5.11951 17.7106C4.87974 17.611 4.66196 17.465 4.47863 17.2811C4.29531 17.0972 4.15002 16.879 4.05107 16.6389C3.95213 16.3989 3.90147 16.1417 3.902 15.882V10.682H20.102V15.882C20.1025 16.142 20.0517 16.3995 19.9525 16.6399C19.8534 16.8802 19.7077 17.0986 19.524 17.2825C19.3402 17.4665 19.122 17.6123 18.8817 17.7118C18.6415 17.8112 18.384 17.8623 18.124 17.862ZM12.2 12.908H5.576C5.46991 12.908 5.36817 12.8659 5.29316 12.7908C5.21814 12.7158 5.176 12.6141 5.176 12.508C5.176 12.4019 5.21814 12.3002 5.29316 12.2252C5.36817 12.1501 5.46991 12.108 5.576 12.108H12.2C12.3061 12.108 12.4078 12.1501 12.4828 12.2252C12.5579 12.3002 12.6 12.4019 12.6 12.508C12.6 12.6141 12.5579 12.7158 12.4828 12.7908C12.4078 12.8659 12.3061 12.908 12.2 12.908ZM8.888 14.708H5.576C5.46991 14.708 5.36817 14.6659 5.29316 14.5908C5.21814 14.5158 5.176 14.4141 5.176 14.308C5.176 14.2019 5.21814 14.1002 5.29316 14.0252C5.36817 13.9501 5.46991 13.908 5.576 13.908H8.888C8.99409 13.908 9.09583 13.9501 9.17084 14.0252C9.24586 14.1002 9.288 14.2019 9.288 14.308C9.288 14.4141 9.24586 14.5158 9.17084 14.5908C9.09583 14.6659 8.99409 14.708 8.888 14.708Z" />
  </svg>
);

IconDashboardCard.propTypes = {
  className: PropTypes.string
};

IconDashboardCard.defaultProps = {
  className: ''
};

export default IconDashboardCard;
