import React, { RefObject } from 'react';
import block from 'bem-cn';
import { Icon } from '@components';
import { useVisible } from '@hooks';
import './SettingsSelect.scss';

const b = block('SettingsSelect');

interface SettingsSelectProps {
  value: any;
  leftTitleChildren?: React.ReactNode;
  displayValue?: string;
  options: Array<{ value: any; title: string }>;
  className?: string;
  titleClassName?: string;
  onChange(value: any): void;
}

export const SettingsSelect: React.FC<SettingsSelectProps> = (props) => {
  const {
    options,
    value,
    leftTitleChildren,
    titleClassName,
    displayValue,
    className,
    onChange
  } = props;

  const { ref, isVisible, additionalRef, setIsVisible } = useVisible(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleSelect = (newValue: any) => () => {
    onChange(newValue);
    setIsVisible(false);
  };

  const selectedTitle = options.find(
    (option) => JSON.stringify(option.value) === JSON.stringify(value)
  )?.title;

  return (
    <div className={`${b()} ${className ?? ''}`}>
      <button
        className={b('btn')}
        ref={additionalRef as RefObject<HTMLButtonElement>}
        onClick={handleToggle}
      >
        {leftTitleChildren}
        <span className={`${b('title')} ${titleClassName ?? ''}`}>{`${displayValue ?? selectedTitle ?? value
          }`}</span>
        <Icon className={b('chevron').toString()} name="chevron-down-fresh" variant="icons" />
      </button>
      <div className={b('menu', { open: isVisible })} ref={ref}>
        {options.map((option, index) => (
          <button
            className={b('option', {
              current: JSON.stringify(option.value) === JSON.stringify(value)
            })}
            key={`${JSON.stringify(option.value)}-${index}`}
            onClick={handleSelect(option.value)}
          >
            {`${option.title}`}
          </button>
        ))}
      </div>
    </div>
  );
};
