import { MessageTypes, informSlice } from '@features/inform';
import i18n from '../../../i18n/i18n';

export const onUploadProgress = (dispatch: any) => (value: number, modalProgressId?: string) => {
  if (modalProgressId) {
    dispatch(
      informSlice.actions.updateMessage({
        id: modalProgressId,
        progress: value
      })
    );

    if (value === 100) {
      dispatch(informSlice.actions.removeMessage(modalProgressId));

      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.files.uploaded')
        })
      );
    }
  }
};
