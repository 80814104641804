import React from 'react';
import PropTypes from 'prop-types';

const IconSave = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={14}
    viewBox="0 0 14 14"
    width={14}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.84 13.222H2.16A1.383 1.383 0 01.778 11.84V2.16A1.383 1.383 0 012.16.778h7.605l3.457 3.457v7.604a1.383 1.383 0 01-1.383 1.383z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
    <path
      d="M10.457 13.222v-5.53H3.543v5.53M3.543.778v3.457h5.531"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconSave.propTypes = {
  className: PropTypes.string
};

IconSave.defaultProps = {
  className: ''
};

export default IconSave;
