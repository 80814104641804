import React from 'react';
import PropTypes from 'prop-types';

const IconPasswordShown = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7684 8.23331C12.7442 9.20914 12.7442 10.7933 11.7684 11.7708C10.7926 12.7466 9.2084 12.7466 8.2309 11.7708C7.25507 10.795 7.25507 9.21081 8.2309 8.23331C9.2084 7.25581 10.7917 7.25581 11.7684 8.23331"
      stroke="#FD19CC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M2.5 9.99999C2.5 9.45082 2.62667 8.90749 2.87167 8.40666V8.40666C4.13417 5.82582 6.92417 4.16666 10 4.16666C13.0758 4.16666 15.8658 5.82582 17.1283 8.40666V8.40666C17.3733 8.90749 17.5 9.45082 17.5 9.99999C17.5 10.5492 17.3733 11.0925 17.1283 11.5933V11.5933C15.8658 14.1742 13.0758 15.8333 10 15.8333C6.92417 15.8333 4.13417 14.1742 2.87167 11.5933V11.5933C2.62667 11.0925 2.5 10.5492 2.5 9.99999Z"
      fillRule="evenodd"
      stroke="#FD19CC"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconPasswordShown.propTypes = {
  className: PropTypes.string
};

IconPasswordShown.defaultProps = {
  className: ''
};

export default IconPasswordShown;
