import React from 'react';
import block from 'bem-cn';
import { ColorValue, GradientValue, renderBackgroundStyles } from '@storysdk/react';
import './ColorThemesItem.scss';

const b = block('ColorThemesItem');

export type ColorThemesItemColor = { background: ColorValue | GradientValue; text: string };

interface ColorThemesItemProps {
  selected: ColorThemesItemColor;
  color: ColorThemesItemColor;
  isDark?: boolean;
  onChange?: (value: ColorThemesItemColor) => void;
}

export const ColorThemesItem: React.FC<ColorThemesItemProps> = ({
  color,
  selected,
  isDark,
  onChange
}) => (
  <button
    className={b({
      selected:
        JSON.stringify(selected.background.value) === JSON.stringify(color.background.value),
      dark: isDark
    })}
    onClick={() => onChange?.(color)}
  >
    <div
      className={b('color')}
      style={{
        background: renderBackgroundStyles(color.background)
      }}
    />
  </button>
);
