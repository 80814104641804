import React from 'react';
import PropTypes from 'prop-types';

const IconGroups = ({ className, color }) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5446 2.49548H3.44296C2.37535 2.49548 1.5 3.33252 1.5 4.37685V14.1186C1.5 15.1629 2.37535 15.9999 3.44296 15.9999H12.5446C13.6122 15.9999 14.487 15.163 14.487 14.1186V4.37685C14.487 3.33243 13.6122 2.49548 12.5446 2.49548ZM3.44296 3.57773H12.5446C13.025 3.57773 13.4047 3.94105 13.4047 4.37685V14.1186C13.4047 14.5544 13.025 14.9177 12.5446 14.9177H3.44296C2.96248 14.9177 2.58224 14.5541 2.58224 14.1186V4.37685C2.58224 3.9413 2.96248 3.57773 3.44296 3.57773Z"
      fill={color}
    />
    <path d="M7.99382 7.2478V11.2478" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.99365 9.24784H5.99365"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.64953 0.120882C10.8989 -0.327104 12.2389 0.522734 12.319 1.80173L12.3228 1.92288V3.03675C12.3228 3.33558 12.0805 3.57787 11.7816 3.57787C11.5077 3.57787 11.2813 3.37423 11.2455 3.11019L11.2405 3.03675V1.92288C11.2405 1.37087 10.6735 0.967474 10.1047 1.11215L10.0149 1.13957L3.30656 3.54611C3.02525 3.64696 2.71544 3.50069 2.61445 3.21952C2.52193 2.96164 2.63716 2.67973 2.87366 2.55688L2.94106 2.52741L9.64953 0.120882Z"
      fill={color}
    />
  </svg>
);

IconGroups.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

IconGroups.defaultProps = {
  className: '',
  color: '#A49BA4'
};

export default IconGroups;
