import { WidgetsTypes } from '@storysdk/react';

export const getWidgetImageParts = (type: WidgetsTypes) => {
  const parts = {
    widget: false,
    title: false,
    answers: false
  };

  switch (type) {
    case WidgetsTypes.CLICK_ME:
    case WidgetsTypes.SWIPE_UP:
    case WidgetsTypes.ELLIPSE:
    case WidgetsTypes.RECTANGLE:
    case WidgetsTypes.TEXT:
      parts.widget = true;
      break;
    case WidgetsTypes.TALK_ABOUT:
    case WidgetsTypes.SLIDER:
    case WidgetsTypes.QUESTION:
    case WidgetsTypes.QUIZ_RATE:
    case WidgetsTypes.QUIZ_OPEN_ANSWER:
      parts.title = true;
      break;
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS:
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE:
    case WidgetsTypes.QUIZ_ONE_ANSWER:
      parts.title = true;
      parts.answers = true;
      break;
  }

  return parts;
};
