import React from 'react';
import PropTypes from 'prop-types';

const IconElementAlignBottom = ({ className }) => (
  <svg
    className={className}
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1210_12881)">
      <rect height="16" transform="rotate(-90 0 16)" width="1" y="16" />
      <rect height="16" transform="rotate(-90 0 16)" width="1" y="16" />
      <rect height="5" rx="1.5" transform="rotate(-90 2 12)" width="12" x="2" y="12" />
      <rect height="5" rx="1.5" transform="rotate(-90 2 12)" width="12" x="2" y="12" />
      <rect height="5" rx="1.5" transform="rotate(-90 9 12)" width="7" x="9" y="12" />
      <rect height="5" rx="1.5" transform="rotate(-90 9 12)" width="7" x="9" y="12" />
    </g>
    <defs>
      <clipPath id="clip0_1210_12881">
        <rect fill="white" height="16" transform="translate(0 16) rotate(-90)" width="16" />
      </clipPath>
    </defs>
  </svg>
);

IconElementAlignBottom.propTypes = {
  className: PropTypes.string
};

IconElementAlignBottom.defaultProps = {
  className: ''
};

export default IconElementAlignBottom;
