import React from 'react';
import PropTypes from 'prop-types';

const IconPlay = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#AFAFAF"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7.92841 5.2368L14.6767 9.28597C15.2159 9.6093 15.2159 10.3918 14.6767 10.7151L7.92841 14.7643C7.37341 15.0968 6.66675 14.6968 6.66675 14.0493V5.95097C6.66675 5.30347 7.37341 4.90347 7.92841 5.2368Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconPlay.propTypes = {
  className: PropTypes.string
};

IconPlay.defaultProps = {
  className: ''
};

export default IconPlay;
