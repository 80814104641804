import React, { memo, useCallback } from 'react';
import cn from 'classnames';
import './EmojiWidget.scss';
import { EmojiData, Picker } from 'emoji-mart';

const CLASS_NAME = 'EmojiWidget';

type PropsType = {
  selectedEmoji?: string;
  onChange: (emoji: EmojiData) => void;
};

export const EmojiWidget = memo(({ selectedEmoji, onChange }: PropsType) => {
  const handleChange = useCallback(
    (emoji) => {
      onChange(emoji);
    },
    [onChange]
  );
  return (
    <div className={cn(CLASS_NAME)}>
      <div className={cn(`${CLASS_NAME}__emoji`)}>
        <Picker
          emoji={selectedEmoji}
          native
          set="apple"
          theme="dark"
          title="Pick your emoji…"
          onSelect={handleChange}
        />
      </div>
    </div>
  );
});
