import React from 'react';
import PropTypes from 'prop-types';

const IconSuccessCircle = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="50"
    viewBox="0 0 50 50"
    width="50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M38 15L22 33L12 25"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear"
        x1="8.03163"
        x2="27.7169"
        y1="19.4913"
        y2="48.8074"
      >
        <stop stopColor="#FF0198" />
        <stop offset="1" stopColor="#B90AE0" />
      </linearGradient>
    </defs>
  </svg>
);

IconSuccessCircle.propTypes = {
  className: PropTypes.string
};

IconSuccessCircle.defaultProps = {
  className: ''
};

export default IconSuccessCircle;
