import React, { useEffect } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { StoriesGroupBase, fetchGroupsAnalytic, useGetActionByAccess } from '@features';
import { RootState, useAppDispatch } from '@store';
import { useHistory, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { AppFeatures } from '@features/user/consts';
import { shortEnglishHumanizer } from '@utils';
import { FreshTable, FreshTableDataRow } from '@components/_fresh';
import './AnalyticsStoryGroupsTab.scss';

const b = block('AnalyticsStoryGroupsTab');

export const AnalyticsStoryGroupsTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { appId } = useParams<{ appId: string }>();

  const groups = useSelector((store: RootState) => store.groupsAnalytic.data);
  const status = useSelector((store: RootState) => store.groupsAnalytic.status);
  const range = useSelector((store: RootState) => store.storiesAnalyticsGroup.range);
  const appsState = useSelector((store: RootState) => store.appManager);
  const onGroupClick = useGetActionByAccess();

  useEffect(() => {
    if (appId && appsState.status === 'loaded') {
      dispatch(fetchGroupsAnalytic({ appId, range }));
    }
  }, [appId, range, dispatch, appsState]);

  const getGroupRow = (item: StoriesGroupBase): FreshTableDataRow => {
    const watchDuration = item.statistic?.duration
      ? shortEnglishHumanizer(item.statistic.duration * 1000)
      : '0s';

    return {
      title: (
        <div
          className={b('tableCol', { first: true, link: true })}
          onClick={() =>
            onGroupClick(AppFeatures.EXTENDED_ANALITICS, () => {
              history.push(`/dashboard/${appId}/analytics/${item.id}/stories`);
            })
          }
        >
          <img className={b('tableGroupImg')} src={item.image} />
          <p className={b('tableText')}>{item.title}</p>
        </div>
      ),
      open: item.statistic?.open || 0,
      impression: item.statistic?.impression || 0,
      duration: watchDuration,
      clicks: item.statistic?.click || 0
    };
  };

  const headerCols = [
    {
      title: t('dashboard.analytics.groups.title'),
      key: 'title'
    },
    {
      title: t('dashboard.analytics.groups.open'),
      key: 'open'
    },
    {
      title: t('dashboard.analytics.groups.impression'),
      key: 'impression'
    },
    {
      title: t('dashboard.analytics.groups.duration'),
      key: 'duration'
    },
    {
      title: t('dashboard.analytics.groups.clicks'),
      key: 'clicks'
    }
  ];

  const groupRows = groups?.map((item) => getGroupRow(item));

  return (
    <div className={b()}>
      <FreshTable
        dataRows={groupRows ?? []}
        emptyText={t('dashboard.analytics.groups.noData')}
        headerCols={headerCols}
        isLoading={status === 'pending'}
      />
    </div>
  );
};
