import { Story, StoryLayer, StoryLayers } from '@features';

export const getStoriesFlattenedArr = (stories: StoryLayers) =>
  Object.values(stories)
    .map((story) => Object.values(story.layers))
    .flat();

export const getActiveStoriesFlattenedArr = (stories: StoryLayers) =>
  Object.values(stories).map((story) => story.layers[story.activeLayerId]);

export const getActiveStoriesFlattenedArrFromLayersArr = (stories: StoryLayer[]) =>
  stories.map((story) => story.layers[story.activeLayerId]);

export const getActiveStories = (stories: StoryLayers) =>
  Object.values(stories).reduce((acc: { [index: string]: Story }, story) => {
    const activeLayer = story.layers[story.activeLayerId];

    acc[activeLayer.id] = activeLayer;

    return acc;
  }, {});
