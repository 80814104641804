import React, { useState } from 'react';
import './Output.scss';
import block from 'bem-cn';
import cn from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormGroup, FormLabel, IconCopy3 } from '@components';

const b = block('Output');

interface Props {
  value: string;
  label?: string;
  isCopy: boolean;
  theme?: 'light' | 'dark';
  className?: string;
}

export const Output: React.FunctionComponent<Props> = ({
  value,
  label,
  isCopy,
  theme = 'light',
  className
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyValue = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <FormGroup className={className}>
      {label && (
        <FormLabel color={theme === 'light' ? 'black' : 'white'} isBlack>
          {label}
        </FormLabel>
      )}

      <section className={b('container')}>
        <article className={b('wrapper')}>
          <output className={cn(`${b({ copy: isCopy })}`)}>{value}</output>

          {isCopy && (
            <CopyToClipboard text={value} onCopy={copyValue}>
              <div
                className={b('copyBtn')}
                role="button"
                tabIndex={0}
                onClick={copyValue}
                onKeyDown={copyValue}
              >
                <IconCopy3 />
              </div>
            </CopyToClipboard>
          )}
        </article>

        {isCopied && <p className={b('copyText')}>Copied</p>}
      </section>
    </FormGroup>
  );
};
