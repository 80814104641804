import {
  SettingsInputText,
  SettingsSelect,
  SettingsStoriesPreview
} from '@features/stories/editorSettings/components';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  fetchCreateStory,
  fetchCreateTemplateStory,
  GroupsType,
  Story,
  Templates
} from '@features';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesType } from '@features/stories/hooks';
import { BackgroundColorType } from '@storysdk/react';
import { useDebounce } from '@hooks';
import { IconExtLink, IconStory, IconUrl } from '@components';
import { getActiveStories, getInitStoryParams } from '@utils';
import { StoryPreviewIcon } from '../StoryPreviewIcon';
import './LinkAndActionSelect.scss';

const b = block('LinkAndActionSelect');

interface LinkAndActionSelectProps {
  params: any;
  isActionFrist?: boolean;
  isOnlyAction?: boolean;
  onChangeLink?: (value: string) => void;
  onChangeStoryId: (value: string) => void;
  onChangeCustomFields?: (value: { [key: string]: string }) => void;
}

type ValueType = {
  value:
  | string
  | {
    [key: string]: string;
  };
  type: 'link' | 'story' | 'custom';
};

export const LinkAndActionSelect: React.FC<LinkAndActionSelectProps> = ({
  params,
  isActionFrist,
  isOnlyAction,
  onChangeLink,
  onChangeStoryId,
  onChangeCustomFields
}) => {
  const dispatch = useAppDispatch();

  const { appId, groupId, templateId, type } = useParams<{
    appId?: string;
    groupId?: string;
    templateId?: string;
    type: GroupsType;
    category?: Templates.Category;
  }>();

  const currentStoriesType = useCurrentStoriesType(type);

  const stories = useSelector((store: RootState) => store[currentStoriesType].stories);
  const activeStories = getActiveStories(stories);
  const selectedLayersGroupId = useSelector(
    (store: RootState) => store[currentStoriesType].editor.selectedLayersGroupId
  );
  const selectedStoryId = selectedLayersGroupId
    ? stories[selectedLayersGroupId].activeLayerId
    : null;

  const [currentValue, setCurrentValue] = useState<ValueType | null>(null);
  const currentValueDebounced = useDebounce(currentValue);

  const storiesToSelect = Object.values(activeStories).filter(
    (story) =>
      story.id !== selectedStoryId && story.background.type !== BackgroundColorType.TRANSPARENT
  );
  const [selectedStory, setSelectedStory] = useState<Story | null>(
    params.storyId && params.actionType === 'story' ? activeStories[params.storyId] : null
  );

  const { t } = useTranslation();

  const handleCreateStory = () => {
    if (type === GroupsType.TEMPLATE && templateId) {
      dispatch(
        fetchCreateTemplateStory({
          templateId,
          story: getInitStoryParams({})
        })
      );
    } else if (appId && groupId) {
      dispatch(
        fetchCreateStory({
          appId,
          groupId,
          story: getInitStoryParams({})
        })
      );
    }
  };

  const actionOptions = [
    {
      value: 'link',
      title: t('editor.link')
    },
    {
      value: 'story',
      title: t('editor.openStory')
    },
    {
      value: 'custom',
      title: t('editor.customField')
    }
  ].filter((option) => !isOnlyAction || option.value === 'story');

  useEffect(() => {
    if (params.actionType === 'link' && params.url) {
      setCurrentValue({
        value: params.url,
        type: 'link'
      });
    } else if ((params.actionType === 'story' || isOnlyAction) && params.storyId) {
      setCurrentValue({
        value: params.storyId,
        type: 'story'
      });

      setSelectedStory(activeStories[params.storyId]);
    } else if (params.actionType === 'custom' && params.customFields) {
      setSelectActionType('custom');

      setCurrentValue({
        value: params.customFields,
        type: 'custom'
      });
    } else if (isActionFrist || isOnlyAction) {
      setSelectActionType('story');
    } else {
      setSelectActionType('link');
    }
  }, []);

  useEffect(() => {
    if (currentValue?.type === 'story' && selectedStory) {
      setSelectActionType('story');
    }
  }, [currentValue]);

  const [selectActionType, setSelectActionType] = useState<string | null>(null);

  const handleChangeActionType = (option: string) => {
    setSelectActionType(option);
  };

  const handleStoryChoose = (storyId: string) => {
    setCurrentValue({
      value: storyId,
      type: 'story'
    });

    setSelectedStory(activeStories[storyId]);
    setSelectActionType(null);
  };

  const handleUrlChange = (url: string) => {
    setCurrentValue({
      value: url,
      type: 'link'
    });
  };

  const handleChangeCustom = (field: string) => (value: string) => {
    if (currentValue && currentValue.type === 'custom' && typeof currentValue.value === 'object') {
      setCurrentValue({
        value: {
          ...currentValue?.value,
          [field]: value
        },
        type: 'custom'
      });
    } else {
      setCurrentValue({
        value: {
          [field]: value
        },
        type: 'custom'
      });
    }
  };

  useEffect(() => {
    if (currentValueDebounced) {
      if (
        currentValueDebounced.type === 'link' &&
        typeof currentValueDebounced.value === 'string' &&
        onChangeLink
      ) {
        onChangeLink(currentValueDebounced.value);
        setSelectActionType(null);
      } else if (
        currentValueDebounced.type === 'story' &&
        typeof currentValueDebounced.value === 'string'
      ) {
        onChangeStoryId(currentValueDebounced.value);
      } else if (
        currentValueDebounced.type === 'custom' &&
        typeof currentValueDebounced.value === 'object' &&
        onChangeCustomFields
      ) {
        onChangeCustomFields(currentValueDebounced.value);
      }
    }
  }, [currentValueDebounced]);

  const getDisplayValue = () => {
    if (currentValue?.type === 'story' && selectActionType === 'story') {
      return `id${currentValue.value}}`;
    }

    if (selectActionType === 'link') {
      return t('editor.linkPlaceholder');
    }

    if (selectActionType === 'story') {
      return t('editor.selectStoryPlaceholder');
    }

    if (selectActionType === 'custom') {
      return t('editor.customFieldPlaceholder');
    }

    if (!currentValue) {
      if (params.url) {
        return params.url;
      }

      return params.storyId;
    }

    return currentValue.value;
  };

  const getTitleIcon = () => {
    if (selectedStory && selectActionType === 'story') {
      return <StoryPreviewIcon story={selectedStory} />;
    }

    if (selectActionType === 'link' || (params.url && params.actionType === 'link')) {
      return <IconUrl className={b('iconUrl').toString()} />;
    }

    if (selectActionType === 'story') {
      return <IconStory className={b('iconStory').toString()} />;
    }

    if (selectActionType === 'custom') {
      return <IconExtLink className={b('iconExtLink').toString()} />;
    }

    return null;
  };

  return (
    <>
      <SettingsSelect
        className={b('select')}
        displayValue={getDisplayValue()}
        leftTitleChildren={getTitleIcon()}
        options={actionOptions}
        titleClassName={b('selectTitle')}
        value={selectActionType}
        onChange={handleChangeActionType}
      />

      {selectActionType === 'link' && (
        <SettingsInputText
          name="url"
          placeholder={t('editor.clickMePlaceholder')}
          value={
            currentValue?.type === 'link' && typeof currentValue.value === 'string'
              ? currentValue.value
              : ''
          }
          onChange={(fieldName, urlValue) => handleUrlChange(urlValue)}
        />
      )}

      {selectActionType === 'story' && (
        <SettingsStoriesPreview
          currentStoryId={
            currentValue?.type === 'story' ? (currentValue?.value as string) : undefined
          }
          stories={storiesToSelect}
          onClick={handleStoryChoose}
          onCreateStory={handleCreateStory}
        />
      )}

      {selectActionType === 'custom' && (
        <div className={b('fields')}>
          <SettingsInputText
            icon="logo-apple"
            name="ios"
            placeholder={t('editor.storeLinks.ios')}
            value={
              typeof currentValue?.value === 'object' && currentValue?.value.ios
                ? currentValue?.value.ios
                : ''
            }
            onChange={(fieldName, value) => handleChangeCustom(fieldName)(value)}
          />
          <SettingsInputText
            icon="logo-chrome"
            name="web"
            placeholder={t('editor.storeLinks.web')}
            value={
              typeof currentValue?.value === 'object' && currentValue?.value.web
                ? currentValue?.value.web
                : ''
            }
            onChange={(fieldName, value) => handleChangeCustom(fieldName)(value)}
          />
          <span className={b('note')}>
            {t('editor.customNote')}{' '}
            <a
              className={b('noteLink')}
              href="https://docs.storysdk.com/"
              rel="noreferrer"
              target="_blank"
            >
              {t('dashboard.setup.documentation')}
            </a>
          </span>
          {/* <SettingsInputText
            icon="logo-android"
            name="android"
            placeholder={t('editor.storeLinks.android')}
            value={
              typeof currentValue?.value === 'object' && currentValue?.value.android
                ? currentValue?.value.android
                : ''
            }
            onChange={(fieldName, value) => handleChangeCustom(fieldName)(value)}
          /> */}
          {/* <SettingsInputText
            icon="logo-react"
            name="reactNative"
            placeholder={t('editor.storeLinks.reactNative')}
            value={
              typeof currentValue?.value === 'object' && currentValue?.value.reactNative
                ? currentValue?.value.reactNative
                : ''
            }
            onChange={(fieldName, value) => handleChangeCustom(fieldName)(value)}
          /> */}
        </div>
      )}
    </>
  );
};
