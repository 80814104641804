import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MessageTypes, informSlice } from '@features';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '@layouts';
import { AuthPageConfirmMessage } from '../_components/AuthPageConfirmMessage';
import { RootState, useAppDispatch } from '../../../store';
import { FooterLinks } from '../_components';

export const ConfirmEmailPage: React.FC = () => {
  const userState = useSelector((store: RootState) => store.user);
  const history = useHistory();
  const [isSending, setSending] = useState(false);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('auth')) {
      history.push('/dashboard');
    } else if (!userState.user.email || userState.user.isEmailConfirmed) {
      history.push('/');
    }
  }, [history, userState]);

  const resendConfirmEmail = (email: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/resend-confirm-email`, {
      method: 'post',
      body: JSON.stringify({ email })
    })
      .then((r) => r.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: t('errors.somethingWentWrong')
            })
          );
        }

        setSending(false);
      });
  };

  return (
    <AuthLayout
      footer={
        <FooterLinks
          links={[
            {
              title: t('auth.signIn'),
              href: '/'
            },
            {
              title: t('auth.help'),
              href: 'https://docs.storysdk.com/',
              isExternal: true
            }
          ]}
        />
      }
      template="form"
      title={t('auth.congratulations')}
    >
      <AuthPageConfirmMessage
        email={userState.user.email as string}
        isSending={isSending}
        onButtonClick={() => resendConfirmEmail(userState.user.email as string)}
      />
    </AuthLayout>
  );
};
