import React from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';
import './FooterLinks.scss';

const b = block('FooterLinks');

type PropTypes = {
  links: Array<{ href: string; title: string; isExternal?: boolean }>;
};

export const FooterLinks = ({ links }: PropTypes) => (
  <section className={b()}>
    {links.map((link) => (
      <>
        {link.isExternal ? (
          <a className={b('link')} href={link.href} target="_blank">
            {link.title}
          </a>
        ) : (
          <Link className={b('link')} to={link.href}>
            {link.title}
          </Link>
        )}
      </>
    ))}
  </section>
);
