import React from 'react';
import PropTypes from 'prop-types';

const IconInfoError = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
      fill="#EE2E2E"
      fillRule="evenodd"
    />
    <path
      d="M14.8299 9.16992L9.16992 14.8299"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M14.8299 14.8299L9.16992 9.16992"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconInfoError.propTypes = {
  className: PropTypes.string
};

IconInfoError.defaultProps = {
  className: ''
};

export default IconInfoError;
