import {
  fetchUpdateGroup,
  fetchUpdateOnboarding,
  GroupsType,
  StoriesGroup,
  fetchUpdateTemplate,
  StoriesTemplateBase
} from '@features';
import { RootState, useAppDispatch } from '@store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLastStoryPosition, getStoriesFlattenedArr } from '@utils';
import { useCurrentStoriesType } from './useCurrentStoriesType';

interface SaveLastPositionParams {
  appId?: string;
  currentGroup?: StoriesGroup | StoriesTemplateBase;
  type: GroupsType;
}

export const useSaveLastPosition = ({ appId, currentGroup, type }: SaveLastPositionParams) => {
  const dispatch = useAppDispatch();

  const currentStoriesType = useCurrentStoriesType(type);
  const stories = useSelector((store: RootState) => store[currentStoriesType].stories);
  const storiesArr = getStoriesFlattenedArr(stories);
  const currentLastPostition = currentGroup?.settings?.lastStoryPosition;
  const newLastPosition = getLastStoryPosition(storiesArr);

  useEffect(() => {
    if (currentGroup && newLastPosition !== currentLastPostition) {
      if (currentGroup.type === GroupsType.TEMPLATE) {
        const templateParams = {
          templateId: currentGroup.id,
          active: currentGroup.active,
          title: currentGroup.title,
          // @ts-ignore
          description: currentGroup.description,
          type: currentGroup.type,
          // @ts-ignore
          category: currentGroup.category,
          noNotification: true,
          settings: {
            ...currentGroup.settings,
            lastStoryPosition: newLastPosition
          }
        };

        dispatch(fetchUpdateTemplate(templateParams));
        return;
      }

      const params = {
        appId: appId ?? '',
        groupId: currentGroup.id,
        active: currentGroup.active,
        title: currentGroup.title,
        // @ts-ignore
        startTime: currentGroup.startDate,
        // @ts-ignore
        endTime: currentGroup.endDate,
        noNotification: true,
        settings: {
          ...currentGroup.settings,
          lastStoryPosition: newLastPosition
        }
      };

      if (currentGroup.type === GroupsType.ONBOARDING) {
        dispatch(fetchUpdateOnboarding(params));
      } else if (currentGroup.type === GroupsType.GROUP && params.appId) {
        dispatch(fetchUpdateGroup(params));
      }
    }
  }, [newLastPosition]);
};
