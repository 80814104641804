import React from 'react';
import PropTypes from 'prop-types';

const IconElementAlignRight = ({ className }) => (
  <svg
    className={className}
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1210_12884)">
      <rect height="16" transform="matrix(-1 0 0 1 16 0)" width="1" />
      <rect height="16" transform="matrix(-1 0 0 1 16 0)" width="1" />
      <rect height="5" rx="1.5" transform="matrix(-1 0 0 1 12 2)" width="12" />
      <rect height="5" rx="1.5" transform="matrix(-1 0 0 1 12 2)" width="12" />
      <rect height="5" rx="1.5" transform="matrix(-1 0 0 1 12 9)" width="7" />
      <rect height="5" rx="1.5" transform="matrix(-1 0 0 1 12 9)" width="7" />
    </g>
    <defs>
      <clipPath id="clip0_1210_12884">
        <rect fill="white" height="16" transform="matrix(-1 0 0 1 16 0)" width="16" />
      </clipPath>
    </defs>
  </svg>
);

IconElementAlignRight.propTypes = {
  className: PropTypes.string
};

IconElementAlignRight.defaultProps = {
  className: ''
};

export default IconElementAlignRight;
