/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { convertStoryFromRaw, StoryLayers } from '@features/stories';
import { API } from '@services';
import { getStoriesByLayers } from '@utils';

export const useGetTemplateStories = (templateId?: string) => {
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);
  const [templateStories, setTemplateStories] = useState<StoryLayers | null>(null);

  useEffect(() => {
    if (!templateId || !currentLocale) return;

    setTemplateStories(null);

    const fetchData = async () => {
      const storiesResponse = await API.templateStories.getList({
        templateId
      });

      if (storiesResponse.data.data && !storiesResponse.data.error && currentLocale) {
        const rawStories = storiesResponse.data.data.map((item: any) =>
          convertStoryFromRaw(item, currentLocale, 'exist')
        );
        const storiesByLayers = getStoriesByLayers(rawStories);

        setTemplateStories(storiesByLayers);
      }
    };

    fetchData();

    return () => {
      setTemplateStories(null);
    };
  }, [templateId, currentLocale]);

  return templateStories;
};
