import React, { memo } from 'react';
import block from 'bem-cn';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Drop } from '@custom';
import { Icon } from '@components';
import './GroupSelector.scss';

const b = block('GroupSelector');

interface Props {
  currentId?: string;
  groups: any[];
  onChange(groupId: string): void;
}

export const GroupSelector: React.FC<Props> = memo(({ currentId, groups, onChange }) => {
  const currentGroup = groups.find((group: any) => group.id === currentId);

  const handleChange = (id: string) => () => onChange(id);

  const control = (
    <div className={b('btn')}>
      <span className={b('title')}>
        {currentGroup ? (
          <>
            <img alt="" className={b('img', { current: true })} src={currentGroup?.image} />
            <span className={b('text')}>{currentGroup?.title}</span>
          </>
        ) : (
          <SkeletonTheme baseColor="#18182E" highlightColor="#0e0e2a">
            <Skeleton height={20} style={{ marginRight: 8, borderRadius: '50%' }} width={20} />
            <Skeleton height={20} style={{ borderRadius: 5 }} width={140} />
          </SkeletonTheme>
        )}
      </span>
      {groups.length > 1 && (
        <Icon className={b('chevron').toString()} name="chevron-down-fresh" variant="icons" />
      )}
    </div>
  );

  return (
    <div className={b()}>
      <Drop control={control}>
        <div className={b('menu')}>
          {groups.map((group: any) => (
            <button
              className={b('group', { current: group.id === currentId })}
              key={group.id}
              onClick={handleChange(group.id)}
            >
              <img
                alt=""
                className={b('img', { current: group.id === currentId })}
                src={group.image}
              />
              {group.title}
            </button>
          ))}
        </div>
      </Drop>
    </div>
  );
});
