import React, { useCallback, useMemo } from 'react';
import { SettingsBox, ColorPicker, DropIcon, FontSizeIcon, TextLineHeightIcon } from '@modules';
import {
  ControlGroup,
  SettingsInput,
  SettingsSelect,
  SettingsSlider,
  SettingsTextAlign,
  SettingAlignOnChangeType,
  SettingsAlign,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features/stories/editorSettings/components';
import {
  FONT_STYLES,
  FONT_SIZE_LIMITS,
  FONT_LINE_HEIGHT_LIMITS
} from '@features/stories/editorSettings/constants';
import { getChangedOpacityColor, getOpacityFromColor } from '@modules/EditorModule/utils';
import { SettingsFontSelector } from '@features/stories/editorSettings/components/SettingsFontSelector/SettingsFontSelector';
import { useTranslation } from 'react-i18next';
import { BackgroundColorType, TextWidgetParamsType, WidgetPositionType } from '@storysdk/react';
import block from 'bem-cn';
import { Icon } from '@components';
import './TextWidgetSettings.scss';

const b = block('TextWidgetSettings');

type TextWidgetSettingsProps = {
  position: WidgetPositionType;
  params: TextWidgetParamsType;
  onChange(params: TextWidgetParamsType): void;
  onChangePosition(params: WidgetPositionType, stopSave?: boolean): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
};

export const TextWidgetSettings: React.FC<TextWidgetSettingsProps> = (props) => {
  const { params, position, onChangePosition, onChange, onChangeAlign } = props;

  const { t } = useTranslation();

  const handleChange = (field: string) => (value: any) => {
    const newParams = {
      ...params,
      [field]: value
    };

    onChange(newParams);
  };

  const handleChangeFill = useCallback(() => {
    onChange({ ...params, withFill: !params.withFill });
  }, [params, onChange]);

  const handleChangePosition = (field: string) => (value: any) => {
    onChangePosition(
      {
        ...position,
        realWidth: field === 'width' ? value : position.realWidth,
        [field]: value
      },
      field !== 'width' && field !== 'height'
    );
  };

  const handleChangeOpacity = (fieldName: 'backgroundColor' | 'color') => (value: number) => {
    if (params[fieldName].type === 'color') {
      handleChange(fieldName)({
        type: 'color',
        value: getChangedOpacityColor(params[fieldName].value as string, value)
      });
    }
  };

  const backgroundOpacity = useMemo(() => {
    if (params.backgroundColor.type === 'color') {
      return getOpacityFromColor(params.backgroundColor.value);
    }

    return 100;
  }, [params.backgroundColor]);

  const fontOpacity = useMemo(() => {
    if (params.color.type === 'color') {
      return getOpacityFromColor(params.color.value);
    }

    return 100;
  }, [params.color]);

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked={position.isHeightLocked}>
            <SettingsInput
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              postfix="px"
              prefix="H"
              type="number"
              value={position.realHeight}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <SettingsSlider
            title={t('editor.opacity')}
            value={params.widgetOpacity}
            onChange={handleChange('widgetOpacity')}
          />
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.text')}>
        <SettingsBox.Field>
          <SettingsFontSelector value={params.fontFamily} onChange={handleChange('fontFamily')} />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsSelect
              options={FONT_STYLES}
              value={params.fontParams}
              onChange={handleChange('fontParams')}
            />
            <SettingsInput
              icon={<FontSizeIcon />}
              max={FONT_SIZE_LIMITS.max}
              min={FONT_SIZE_LIMITS.min}
              type="number"
              value={params.fontSize}
              onChange={handleChange('fontSize')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
              value={params.color}
              onChange={handleChange('color')}
            />
            <SettingsInput
              icon={<DropIcon />}
              max={100}
              type="number"
              value={fontOpacity}
              onChange={handleChangeOpacity('color')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsTextAlign value={params.align} onChange={handleChange('align')} />
            <SettingsInput
              className={b('textInput').toString()}
              icon={<TextLineHeightIcon />}
              max={FONT_LINE_HEIGHT_LIMITS.max}
              min={FONT_LINE_HEIGHT_LIMITS.min}
              postfix="%"
              type="number"
              value={params.lineHeight}
              onChange={handleChange('lineHeight')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.SwitchableGroup
        isChecked={params.withFill}
        title={t('editor.fillBackground')}
        onToggle={handleChangeFill}
      >
        <ControlGroup sub>
          <ColorPicker
            availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
            value={params.backgroundColor}
            onChange={handleChange('backgroundColor')}
          />
          <SettingsInput
            icon={<DropIcon />}
            max={100}
            type="number"
            value={backgroundOpacity}
            onChange={handleChangeOpacity('backgroundColor')}
          />
        </ControlGroup>
      </SettingsBox.SwitchableGroup>
    </>
  );
};
