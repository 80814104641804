import React from 'react';
import PropTypes from 'prop-types';

const IconElementAlignTop = ({ className }) => (
  <svg
    className={className}
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1210_12885)">
      <rect height="16" transform="matrix(0 1 1 0 0 0)" width="1" />
      <rect height="16" transform="matrix(0 1 1 0 0 0)" width="1" />
      <rect height="5" rx="1.5" transform="matrix(0 1 1 0 2 4)" width="12" />
      <rect height="5" rx="1.5" transform="matrix(0 1 1 0 2 4)" width="12" />
      <rect height="5" rx="1.5" transform="matrix(0 1 1 0 9 4)" width="7" />
      <rect height="5" rx="1.5" transform="matrix(0 1 1 0 9 4)" width="7" />
    </g>
    <defs>
      <clipPath id="clip0_1210_12885">
        <rect fill="white" height="16" transform="matrix(0 1 1 0 0 0)" width="16" />
      </clipPath>
    </defs>
  </svg>
);

IconElementAlignTop.propTypes = {
  className: PropTypes.string
};

IconElementAlignTop.defaultProps = {
  className: ''
};

export default IconElementAlignTop;
