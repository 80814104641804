import React, { useMemo } from 'react';
import { SettingsBox, ColorPicker, DropIcon, ThicknessIcon } from '@modules';
import { useAppDispatch } from '@store';
import { fetchUpdateTemplateStoryWidgetBackground, GroupsType } from '@features';
import {
  ControlGroup,
  MAX_ANGLE_VALUE,
  MIN_ANGLE_VALUE,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsInput,
  SettingsSlider
} from '@features/stories/editorSettings/components';
import { MessageTypes, informSlice } from '@features/inform';
import { fetchUpdateStoryWidgetBackground } from '@features/stories/storiesSlice';
import { getChangedOpacityColor, getOpacityFromColor } from '@modules/EditorModule/utils';
import { BackgroundBlob } from '@features/stories/types';
import { onUploadProgress } from '@features/inform/utils';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import {
  BackgroundColorType,
  BackgroundType,
  MediaType,
  RectangleWidgetParamsType,
  WidgetPositionType,
  WidgetsTypes
} from '@storysdk/react';
import { useParams } from 'react-router-dom';
import { Icon } from '@components';

type Props = {
  id: string;
  position: WidgetPositionType;
  params: RectangleWidgetParamsType;
  onChange(params: RectangleWidgetParamsType): void;
  onChangePosition(params: WidgetPositionType, stopSave?: boolean): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
};

export const RectangleWidgetSettings: React.FC<Props> = (props) => {
  const { id, params, position, onChange, onChangeAlign, onChangePosition } = props;

  const { type } = useParams<{
    type: GroupsType;
  }>();

  const handleChange = (field: string) => (value: any) => {
    const newParams = {
      ...params,
      [field]: value
    };

    onChange(newParams);
  };

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // TODO: Unify this function
  const handleChangeBackground = (background: BackgroundBlob | BackgroundType) => {
    if (background.type === MediaType.VIDEO || background.type === MediaType.IMAGE) {
      const newModalId = nanoid(6);

      dispatch(
        informSlice.actions.addMessage({
          id: newModalId,
          type: MessageTypes.PROGRESS,
          // @ts-ignore
          text: background.value.name
        })
      );

      const backgroundParams = {
        widgetId: id,
        widgetType: WidgetsTypes.RECTANGLE,
        background: background as BackgroundBlob,
        onUploadProgress: onUploadProgress(dispatch),
        modalProgressId: newModalId
      };

      if (type === GroupsType.TEMPLATE) {
        dispatch(fetchUpdateTemplateStoryWidgetBackground(backgroundParams));
      } else {
        dispatch(fetchUpdateStoryWidgetBackground(backgroundParams));
      }
    } else {
      onChange({
        ...params,
        fillColor: background as BackgroundType
      });
    }
  };

  const handleChangePosition = (field: string) => (value: any) => {
    onChangePosition(
      {
        ...position,
        realWidth: field === 'width' ? value : position.realWidth,
        realHeight: field === 'height' ? value : position.realHeight,
        [field]: value
      },
      field !== 'width' && field !== 'height'
    );
  };

  const handleChangeBorder = () => {
    onChange({
      ...params,
      hasBorder: !params.hasBorder
    });
  };

  const handleChangeOpacity = (fieldName: 'fillColor' | 'strokeColor') => (value: number) => {
    if (params[fieldName].type === 'color') {
      handleChange(fieldName)({
        type: 'color',
        value: getChangedOpacityColor(params[fieldName].value as string, value)
      });
    }
  };

  const fillOpacity = useMemo(() => {
    if (params.fillColor.type === 'color') {
      return getOpacityFromColor(params.fillColor.value);
    }

    return 100;
  }, [params.fillColor]);

  const strokeOpacity = useMemo(() => {
    if (params.strokeColor.type === 'color') {
      return getOpacityFromColor(params.strokeColor.value);
    }

    return 100;
  }, [params.strokeColor]);

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup
            isLocked={position.isHeightLocked}
            onLockChange={handleChangePosition('isHeightLocked')}
          >
            <SettingsInput
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              postfix="px"
              prefix="H"
              type="number"
              value={position.height}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <SettingsSlider
            title={t('editor.opacity')}
            value={params.widgetOpacity}
            onChange={handleChange('widgetOpacity')}
          />
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
          <SettingsInput
            icon={<Icon name="border-radius" />}
            postfix="px"
            type="number"
            value={params.fillBorderRadius}
            onChange={handleChange('fillBorderRadius')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.fill')}>
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[
                MediaType.IMAGE,
                MediaType.VIDEO,
                BackgroundColorType.COLOR,
                BackgroundColorType.GRADIENT
              ]}
              value={params.fillColor}
              onChange={handleChangeBackground}
            />
            <SettingsInput
              icon={<DropIcon />}
              type="number"
              value={fillOpacity}
              onChange={handleChangeOpacity('fillColor')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.SwitchableGroup
        isChecked={params.hasBorder}
        title={t('editor.border')}
        onToggle={handleChangeBorder}
      >
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[BackgroundColorType.COLOR]}
              value={params.strokeColor}
              onChange={handleChange('strokeColor')}
            />
            <SettingsInput
              icon={<DropIcon />}
              type="number"
              value={strokeOpacity}
              onChange={handleChangeOpacity('strokeColor')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <SettingsInput
            icon={<ThicknessIcon />}
            postfix="px"
            type="number"
            value={params.strokeThickness}
            onChange={handleChange('strokeThickness')}
          />
        </SettingsBox.Field>
      </SettingsBox.SwitchableGroup>
    </>
  );
};
