import React from 'react';
import PropTypes from 'prop-types';

const IconStory = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1693_14196)">
      <rect height="10" rx="0.75" width="10" x="2.5" y="5.5" />
      <path d="M6 3L6.20711 2.79289C6.39464 2.60536 6.649 2.5 6.91421 2.5H14.25C14.9404 2.5 15.5 3.05964 15.5 3.75V11.0858C15.5 11.351 15.3946 11.6054 15.2071 11.7929L15 12" />
    </g>
    <defs>
      <clipPath id="clip0_1693_14196">
        <rect fill="white" height="14" transform="translate(2 2)" width="14" />
      </clipPath>
    </defs>
  </svg>
);

IconStory.propTypes = {
  className: PropTypes.string
};

IconStory.defaultProps = {
  className: ''
};

export default IconStory;
