import React, { useCallback } from 'react';
import cn from 'classnames';
import { Button as ButtonReactstrap } from 'reactstrap';
import { Icon } from '@components';
import './Button.scss';

type PropTypes = {
  active?: any;
  avatar?: string;
  block?: any;
  children?: any;
  className?: string;
  href?: string;
  color?: string;
  disabled?: boolean;
  iconClassName?: string;
  iconName?: string;
  iconPosition?: string;
  id?: string;
  iconVariant?: string;
  onlyIcon?: boolean;
  size?: string;
  tag?: any;
  text?: string;
  uppercase?: boolean;
  onClick?: (params: any) => void;
  onKeyDown?: () => void;
};

export const Button = ({
  children = null,
  text = '',
  id = '',
  iconName = '',
  avatar = '',
  iconClassName = '',
  iconPosition = 'left',
  iconVariant = 'default',
  size = 'md',
  onlyIcon = false,
  className = '',
  uppercase = false,
  color = 'white',
  onClick = () => {},
  ...props
}: PropTypes) => {
  const hasIcon = !!iconName;
  const hasAvatar = !!avatar;
  const hasText = !!text;
  const validOnlyIcon = hasIcon && (onlyIcon || !hasText);
  const validWithIcon = hasIcon && hasText && !onlyIcon;

  const buttonClasses = cn(
    'text-truncate d-inline-flex align-items-center justify-content-center position-relative flex-nowrap py-0',
    {
      'shadow-none': ['black', 'blue', 'dark', 'secondary'].includes(color),
      'btn-with-icon': validWithIcon,
      'btn-only-icon flex-shrink-0': validOnlyIcon,
      'btn-lg h-4_25': size === 'lg',
      'btn-md h-2_5': size === 'md',
      'btn-sm h-2_125': size === 'sm',
      'btn-xs h-2_125': size === 'xs',
      'btn-icon-left': hasIcon && iconPosition === 'left',
      'btn-icon-right': hasIcon && iconPosition === 'right'
    },
    className,
    'Button'
  );

  const iconWrapperClasses = 'btn-icon-wrapper d-flex align-items-center justify-content-center';

  const iconClasses = cn('btn-icon', iconClassName);

  const avatarClasses = 'w-1_5 h-1_5 object-cover rounded-circle mr-0_5';

  const textClasses = cn('text-truncate btn-text', {
    'text-lg': size === 'lg',
    'text-md': size === 'md',
    'text-sm': size === 'sm',
    'text-xs': !uppercase && size === 'xs',
    'text-button-uppercase': uppercase,
    'sr-only': validOnlyIcon
  });

  const handleClick = useCallback((event) => onClick({ event, params: { id } }), [id, onClick]);

  return (
    <ButtonReactstrap
      className={buttonClasses}
      color={color}
      id={id}
      onClick={handleClick}
      {...props}
    >
      {hasIcon && (
        <span className={iconWrapperClasses}>
          <Icon className={iconClasses} name={iconName} variant={iconVariant} />
        </span>
      )}
      {hasAvatar && <img alt="" className={avatarClasses} src={avatar} />}
      {hasText && <span className={textClasses}>{text}</span>}
      {children}
    </ButtonReactstrap>
  );
};
