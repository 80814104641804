import React from 'react';
import PropTypes from 'prop-types';

const IconGrid = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.222 1H2a1 1 0 00-1 1v4.222a1 1 0 001 1h4.222a1 1 0 001-1V2a1 1 0 00-1-1zM16 1h-4.222a1 1 0 00-1 1v4.222a1 1 0 001 1H16a1 1 0 001-1V2a1 1 0 00-1-1zM16 10.778h-4.222a1 1 0 00-1 1V16a1 1 0 001 1H16a1 1 0 001-1v-4.222a1 1 0 00-1-1zM6.222 10.778H2a1 1 0 00-1 1V16a1 1 0 001 1h4.222a1 1 0 001-1v-4.222a1 1 0 00-1-1z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconGrid.propTypes = {
  className: PropTypes.string
};

IconGrid.defaultProps = {
  className: ''
};

export default IconGrid;
