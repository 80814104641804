import React from 'react';
import block from 'bem-cn';
import { NewPasswordForm } from '@forms';
import { useTranslation } from 'react-i18next';
import { FreshModal, FreshModalTitle } from '@components/_fresh';

import './ChangePasswordModal.scss';

interface Props {
  isOpen: boolean;
  onClose(): void;
  handleSubmitNewPasswordForm(data: any): void;
}

export const ChangePasswordModal: React.FunctionComponent<Props> = (props) => {
  const { isOpen, onClose, handleSubmitNewPasswordForm } = props;

  const b = block('DeleteGroupModal');
  const { t } = useTranslation();

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle centered>{t('auth.chekEmailText')}</FreshModalTitle>

        <NewPasswordForm onSubmit={handleSubmitNewPasswordForm} />
      </div>
    </FreshModal>
  );
};
