import React, { useCallback, useState, useEffect } from 'react';
import { DropdownToggle, Dropdown, DropdownMenu } from 'reactstrap';
import './FreshDrop.scss';

const CLASS_NAME = 'FreshDrop';

type PropsTypes = {
  control: any;
  children: any;
  isRight?: boolean;
  isPreventClose?: boolean;
  targetElementForClose?: string;
  handleOpen?: (isOpen: boolean) => void;
};

export const FreshDrop: React.FC<PropsTypes> = ({
  control,
  children,
  isRight,
  isPreventClose,
  targetElementForClose,
  handleOpen
}: PropsTypes) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = useCallback(() => setDropdownOpen(!dropdownOpen), [dropdownOpen]);

  useEffect(() => {
    if (handleOpen) {
      handleOpen(dropdownOpen);
    }
  }, [dropdownOpen, handleOpen]);

  return (
    <Dropdown className={CLASS_NAME} isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle className={`${CLASS_NAME}__toggle`} color="transperent">
        {control}
      </DropdownToggle>
      <DropdownMenu
        className={`${CLASS_NAME}__dropdown`}
        right={isRight}
        onClick={
          !isPreventClose
            ? (e) => {
              e.stopPropagation();

              if (targetElementForClose) {
                const targetElement = e.target as HTMLElement;
                if (targetElement.closest(targetElementForClose)) {
                  setDropdownOpen(false);
                }
              } else {
                setDropdownOpen(false);
              }
            }
            : undefined
        }
      >
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};
