import React from 'react';
import PropTypes from 'prop-types';

const IconTemplates = ({ className }) => (
  <svg
    className={className}
    fill="#FFFFFF"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.2857 4H5.71429C5.25956 4 4.82361 4.18061 4.50204 4.50204C4.18061 4.8236 4 5.25956 4 5.71429V10.56C4 10.8632 4.12041 11.1539 4.33469 11.3682C4.54911 11.5824 4.8398 11.7029 5.14286 11.7029H18.8571C19.1602 11.7029 19.4509 11.5824 19.6653 11.3682C19.8796 11.1539 20 10.8632 20 10.56V5.71429C20 5.25956 19.8194 4.82361 19.498 4.50204C19.1764 4.18061 18.7404 4 18.2857 4V4ZM18.8571 10.56H5.14286V5.71429C5.14286 5.56276 5.20306 5.41735 5.3102 5.3102C5.41735 5.20306 5.56276 5.14286 5.71429 5.14286H18.2857C18.4372 5.14286 18.5827 5.20306 18.6898 5.3102C18.7969 5.41735 18.8571 5.56276 18.8571 5.71429V10.56Z" />
    <path d="M18.8571 12.2739H13.4171C13.1141 12.2739 12.8234 12.3943 12.6091 12.6088C12.3947 12.823 12.2743 13.1137 12.2743 13.4168V18.8568C12.2743 19.1598 12.3947 19.4505 12.6091 19.6649C12.8234 19.8792 13.1141 19.9996 13.4171 19.9996H18.2857C18.7404 19.9996 19.1764 19.819 19.498 19.4976C19.8194 19.176 20 18.7401 20 18.2854V13.4168C20 13.1137 19.8796 12.823 19.6653 12.6088C19.4509 12.3943 19.1602 12.2739 18.8571 12.2739ZM18.8571 18.2854C18.8571 18.4369 18.7969 18.5823 18.6898 18.6894C18.5827 18.7966 18.4373 18.8568 18.2857 18.8568H13.4171V13.4168H18.8571V18.2854Z" />
    <path d="M11.7029 13.4168C11.7029 13.1137 11.5824 12.823 11.3682 12.6088C11.1539 12.3943 10.8632 12.2739 10.56 12.2739H5.14286C4.8398 12.2739 4.54909 12.3943 4.33469 12.6088C4.12041 12.823 4 13.1137 4 13.4168V18.2854C4 18.7401 4.18061 19.176 4.50204 19.4976C4.8236 19.819 5.25956 19.9996 5.71429 19.9996H10.56C10.8632 19.9996 11.1539 19.8792 11.3682 19.6649C11.5824 19.4505 11.7029 19.1598 11.7029 18.8568V13.4168ZM10.56 18.8568H5.71429C5.56276 18.8568 5.41735 18.7966 5.3102 18.6894C5.20306 18.5823 5.14286 18.4369 5.14286 18.2854V13.4168H10.56V18.8568Z" />
  </svg>
);

IconTemplates.propTypes = {
  className: PropTypes.string
};

IconTemplates.defaultProps = {
  className: ''
};

export default IconTemplates;
