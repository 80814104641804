import React from 'react';
import PropTypes from 'prop-types';

const IconFlagEn = ({ className }) => (
  <svg className={className} viewBox="0 85.333 512 341.333" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 85.333h512V426.67H0z" fill="#FFF" />
    <path d="M288 85.33h-64v138.666H0v64h224v138.666h64V287.996h224v-64H288z" fill="#D80027" />
    <g fill="#0052B4">
      <path d="M393.785 315.358 512 381.034v-65.676zM311.652 315.358 512 426.662v-31.474l-143.693-79.83zM458.634 426.662l-146.982-81.664v81.664z" />
    </g>
    <path d="M311.652 315.358 512 426.662v-31.474l-143.693-79.83z" fill="#FFF" />
    <path d="M311.652 315.358 512 426.662v-31.474l-143.693-79.83z" fill="#D80027" />
    <g fill="#0052B4">
      <path d="M90.341 315.356 0 365.546v-50.19zM200.348 329.51v97.151H25.491z" />
    </g>
    <path d="M143.693 315.358 0 395.188v31.474l200.348-111.304z" fill="#D80027" />
    <g fill="#0052B4">
      <path d="M118.215 196.634 0 130.958v65.676zM200.348 196.634 0 85.33v31.474l143.693 79.83zM53.366 85.33l146.982 81.664V85.33z" />
    </g>
    <path d="M200.348 196.634 0 85.33v31.474l143.693 79.83z" fill="#FFF" />
    <path d="M200.348 196.634 0 85.33v31.474l143.693 79.83z" fill="#D80027" />
    <g fill="#0052B4">
      <path d="M421.659 196.636 512 146.446v50.19zM311.652 182.482V85.331h174.857z" />
    </g>
    <path d="M368.307 196.634 512 116.804V85.33L311.652 196.634z" fill="#D80027" />
  </svg>
);

IconFlagEn.propTypes = {
  className: PropTypes.string
};

IconFlagEn.defaultProps = {
  className: ''
};

export default IconFlagEn;
