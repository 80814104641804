import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { StoriesTemplateBase, StoriesTemplateCard, StoryLayers } from '@features';
import './StoriesTemplatesContainer.scss';

const b = block('StoriesTemplatesContainer');

interface StoriesTemplatesContainerProps {
  choosenTemplateId?: string;
  chosenStoryIds?: string[];
  templates: StoriesTemplateBase[];
  isLoading?: boolean;
  onSelectTemplate?: (templateId: string) => void;
  onChooseTemplate?: (templateId: string, stories: StoryLayers) => void;
}

export const StoriesTemplatesContainer: React.FC<StoriesTemplatesContainerProps> = ({
  choosenTemplateId,
  templates,
  chosenStoryIds,
  isLoading,
  onSelectTemplate,
  onChooseTemplate
}) => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <h1 className={b('title')}>{t('dashboard.templates.newTitle')}</h1>
      <div className={b('container')}>
        {isLoading ? (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <StoriesTemplateCard isLoading key={`template-catd-load-${index}`} />
            ))}
          </>
        ) : (
          <>
            {templates.length > 0 ? (
              templates.map((template) => (
                <StoriesTemplateCard
                  chosenStoryIds={chosenStoryIds}
                  isChoosen={choosenTemplateId === template.id && !!chosenStoryIds?.length}
                  isPreviewAvailable
                  key={`template-card-${template.id}`}
                  template={template}
                  onChooseTemplate={onChooseTemplate}
                  onSelectTemplate={onSelectTemplate}
                />
              ))
            ) : (
              <div className={b('empty')}>{t('dashboard.stories.emptyText')}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
