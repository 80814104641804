import React from 'react';
import PropTypes from 'prop-types';

const IconChevronLeft = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={14}
    viewBox="0 0 14 14"
    width={14}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 12L5 7l5-5" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} />
  </svg>
);

IconChevronLeft.propTypes = {
  className: PropTypes.string
};

IconChevronLeft.defaultProps = {
  className: ''
};

export default IconChevronLeft;
