export enum MessageTypes {
  SUCCESS = 'success',
  SHARE = 'share',
  ERROR = 'error',
  INFO = 'info',
  WARN = 'warn',
  PROGRESS = 'progress',
  NEED_ACTION = 'need-action',
  DONT_HAVE_ACCESS = 'dont-have-access'
}

export interface Message {
  id?: string;
  type: MessageTypes;
  text?: string;
  progress?: any;
  shareLink?: string;
  action?: {
    text: string;
    icon?: string;
    link: string;
    isExternal?: boolean;
  };
}

export interface State {
  messages: Message[];
}
