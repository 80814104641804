import React from 'react';
import { useTranslation } from 'react-i18next';
import block from 'bem-cn';
import { useAppDispatch } from '@store';
import { productsSlice } from '@features/products/productsSlice';
import { CountdownTimer } from '@components/CountdownTimer';
import { FreshButton } from '@components/_fresh';
import { IconUpgradeArrow } from '@components/Icon';
import './TrialTimer.scss';

interface TrialTimerProps {
  trialLeftTime: number;
  userLocale: string;
}

const b = block('TrialTimer');

export const TrialTimer: React.FC<TrialTimerProps> = ({ trialLeftTime, userLocale }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className={b()}>
      <CountdownTimer
        expiredText={t('plans.trialExpired')}
        locale={userLocale}
        text={t('plans.free')}
        timeLeft={trialLeftTime}
      />

      <FreshButton
        leftIcon={<IconUpgradeArrow />}
        size="sm"
        text={t('plans.upgrade')}
        onClick={() => {
          dispatch(productsSlice.actions.setPlansModalType('update'));
        }}
      />
    </div>
  );
};
