import React, { useCallback, useMemo } from 'react';
import {
  ColorPicker,
  DropIcon,
  getChangedOpacityColor,
  getOpacityFromColor,
  SettingsBox
} from '@modules';
import {
  SettingsTextarea,
  ControlGroup,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsInputText,
  FONT_STYLES,
  SettingsFontSelector,
  SettingsSelect,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features';
import { useTranslation } from 'react-i18next';
import {
  BackgroundColorType,
  QuizRateWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { Icon, SwitchGroup } from '@components';

interface QuizRateWidgetSettingsProps {
  params: QuizRateWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangePosition(params: WidgetPositionType): void;
  onChange(params: QuizRateWidgetParamsType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
}

export const QuizRateWidgetSettings: React.FC<QuizRateWidgetSettingsProps> = (props) => {
  const { params, position, positionLimits, onChange, onChangeAlign, onChangePosition } = props;

  const { t } = useTranslation();

  const handleChangeText = (value: string) => {
    onChange({
      ...params,
      title: value
    });
  };

  const handleChangeTitleHide = () => {
    onChange({
      ...params,
      isTitleHidden: !params.isTitleHidden
    });
  };

  const handleChangePosition = (field: string) => (value: any) => {
    if (positionLimits.keepRatio && positionLimits.ratioIndex) {
      if (field === 'width') {
        onChangePosition({
          ...position,
          width: value,
          realWidth: value,
          height: Math.round(value / positionLimits.ratioIndex),
          realHeight: Math.round(value / positionLimits.ratioIndex)
        });
      } else if (field === 'height') {
        onChangePosition({
          ...position,
          width: Math.round(value * positionLimits.ratioIndex),
          height: value,
          realHeight: value,
          realWidth: Math.round(value * positionLimits.ratioIndex)
        });
      }
    } else {
      onChangePosition({
        ...position,
        [field]: value
      });
    }
  };

  const handleChangeLink = (field: string) => (value: any) => {
    onChange({
      ...params,
      storeLinks: {
        ...params.storeLinks,
        [field]: value
      }
    });
  };

  const handleChange = useCallback(
    (fieldName, value) => {
      onChange({ ...params, [fieldName]: value });
    },
    [params, onChange]
  );

  const handleChangeOpacity = (fieldName: 'fontColor') => (value: number) => {
    handleChange(fieldName, {
      type: 'color',
      value: getChangedOpacityColor(params[fieldName].value as string, value)
    });
  };

  const fontOpacity = useMemo(() => {
    if (params.fontColor.type === 'color') {
      return getOpacityFromColor(params.fontColor.value);
    }

    return 100;
  }, [params.fontColor]);

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked={position.isHeightLocked}>
            <SettingsInput
              max={positionLimits.maxWidth}
              min={positionLimits.minWidth}
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              postfix="px"
              prefix="H"
              type="number"
              value={position.realHeight}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.title')}>
        <SettingsTextarea isMarginBottom value={params.title} onChange={handleChangeText} />

        <SwitchGroup
          isChecked={params.isTitleHidden}
          label={t('editor.hideTitle')}
          onToggle={handleChangeTitleHide}
        />
      </SettingsBox.Group>

      <SettingsBox.Group isGap title={t('editor.storeLinks.title')}>
        <SettingsInputText
          icon="logo-apple"
          name="ios"
          placeholder={t('editor.storeLinks.ios')}
          value={params.storeLinks.ios}
          onChange={(fieldName, value) => handleChangeLink(fieldName)(value)}
        />
        <SettingsInputText
          icon="logo-android"
          name="android"
          placeholder={t('editor.storeLinks.android')}
          value={params.storeLinks.android}
          onChange={(fieldName, value) => handleChangeLink(fieldName)(value)}
        />
        <SettingsInputText
          icon="logo-chrome"
          name="web"
          placeholder={t('editor.storeLinks.web')}
          value={params.storeLinks.web}
          onChange={(fieldName, value) => handleChangeLink(fieldName)(value)}
        />
        <SettingsInputText
          icon="logo-react"
          name="reactNative"
          placeholder={t('editor.storeLinks.reactNative')}
          value={params.storeLinks.reactNative}
          onChange={(fieldName, value) => handleChangeLink(fieldName)(value)}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.text')}>
        <SettingsBox.Field>
          <SettingsFontSelector
            value={params.fontFamily}
            onChange={(value) => handleChange('fontFamily', value)}
          />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsSelect
              options={FONT_STYLES}
              value={params.fontParams}
              onChange={(value) => handleChange('fontParams', value)}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
              value={params.fontColor}
              onChange={(value) => handleChange('fontColor', value)}
            />
            <SettingsInput
              icon={<DropIcon />}
              max={100}
              type="number"
              value={fontOpacity}
              onChange={handleChangeOpacity('fontColor')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>
    </>
  );
};
