import React, { useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import SimpleBar from 'simplebar-react';
import './SettingsFontSelector.scss';
import { fetchInstallFont, fontsSlice, GroupsType } from '@features';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { FONT_FAMILIES } from '@features/stories/editorSettings/constants';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { Icon } from '@components';
import { useVisible } from '@hooks';

const b = block('SettingsFontSelector');

interface SettingsFontSelectorProps {
  value: string;
  onChange(value: string): void;
}

export interface GoogleFont {
  kind: string;
  family: string;
  variants: string[];
  subsets: string[];
  version: string;
  lastModified: string;
  files: {
    [key: string]: string;
  };
  category: string;
}

export interface SystemFont {
  family: string;
}

const CheckIcon = () => (
  <svg
    className={b('icon')}
    fill="none"
    height="11"
    viewBox="0 0 11 11"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25757 5.90909L4.1212 10L9.0303 1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export const SettingsFontSelector: React.FC<SettingsFontSelectorProps> = (props) => {
  const { onChange, value } = props;
  const dispatch = useAppDispatch();
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const installedFonts = useSelector((store: RootState) => store.fonts).fonts;
  const googleFonts = useSelector((store: RootState) => store.fonts).googleFonts;
  const [searchQuery, setSearchQuery] = React.useState('');
  const [_, setSearchInputFocus] = React.useState(false);
  const allFonts = [...installedFonts, ...FONT_FAMILIES];
  const selectedTitle = allFonts.concat(googleFonts).find((option) => option.family === value)
    ?.family;

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  useEffect(() => {
    dispatch(currentStoriesSlice.actions.setIsPickerOpen(isVisible));
  }, [isVisible, dispatch]);

  const handleSearchFont = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleSelect = (newValue: string) => () => {
    onChange(newValue);
    dispatch(fontsSlice.actions.setRecentFont(newValue));
  };

  const handleInstallFont = (font: GoogleFont) => () => {
    if (!installedFonts.map(({ family }) => family).includes(font.family)) {
      dispatch(fetchInstallFont(font));
    }
    onChange(font.family);
    dispatch(fontsSlice.actions.setRecentFont(font.family));
  };

  const handleFocus = () => {
    setSearchInputFocus(true);
  };

  const handleBlur = () => {
    setSearchInputFocus(false);
  };

  const { t } = useTranslation();

  const menuRef = useRef<HTMLDivElement>(null);
  const menuBounding = menuRef.current ? menuRef.current.getBoundingClientRect() : undefined;

  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    const HEADER_SIZE_PX = 80;

    if (menuRef.current && menuBounding && menuBounding.y && !offsetTop) {
      setOffsetTop(menuRef.current.getBoundingClientRect().y - HEADER_SIZE_PX);
    }
  }, [menuBounding]);

  return (
    <div className={b()} ref={ref}>
      <button className={b('control')} onClick={handleToggle}>
        <span className={b('title')}>{selectedTitle}</span>
        <Icon className={b('chevron').toString()} name="chevron-down-fresh" variant="icons" />
      </button>
      <div className={b('menu', { open: isVisible })} ref={menuRef}>
        <input
          className={b('input')}
          placeholder={t('form.search')}
          type="text"
          value={searchQuery}
          onBlur={handleBlur}
          onChange={handleSearchFont}
          onFocus={handleFocus}
        />
        {searchQuery.trim().length === 0 && (
          <div className={b('list')}>
            <SimpleBar style={{ maxHeight: 130 }}>
              <div className={b('scrollList')}>
                {allFonts
                  .map((font) => (
                    <button
                      className={b('option', { current: font.family === value })}
                      key={font.family}
                      style={{ fontFamily: font.family }}
                      onClick={handleSelect(font.family ?? '')}
                    >
                      {font.family}
                      {font.family === value && <CheckIcon />}
                    </button>
                  ))
                  .reverse()}
              </div>
            </SimpleBar>
          </div>
        )}
        <div className={b('list')}>
          <SimpleBar style={{ maxHeight: 400 }}>
            <div className={b('scrollList')}>
              {googleFonts
                .filter(
                  (googleFont) =>
                    searchQuery.trim().length === 0 ||
                    googleFont.family.toLowerCase().startsWith(searchQuery.toLowerCase())
                )
                .map((googleFont) => (
                  <button
                    className={b('option')}
                    key={googleFont.family}
                    onClick={handleInstallFont(googleFont)}
                  >
                    {googleFont.family}
                    {googleFont.family === value ? (
                      <CheckIcon />
                    ) : (
                      <svg
                        className={b('plus')}
                        fill="none"
                        height="10"
                        viewBox="0 0 11 10"
                        width="11"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.83832 4H9.86862C10.4265 4 10.8787 4.44772 10.8787 5C10.8787 5.55228 10.4265 6 9.86862 6H6.83832V9C6.83832 9.55229 6.38608 10 5.82821 10C5.27035 10 4.81811 9.55229 4.81811 9V6H1.78781C1.22995 6 0.77771 5.55228 0.77771 5C0.77771 4.44772 1.22995 4 1.78781 4H4.81811V1C4.81811 0.447715 5.27035 0 5.82821 0C6.38608 0 6.83832 0.447715 6.83832 1V4Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </button>
                ))}
            </div>
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};
