import React, { useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { MessageTypes, informSlice } from '@features';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { FreshModal } from '@components/_fresh';
import { AuthPageConfirmMessage } from '../AuthPageConfirmMessage';
import './ConfirmEmailModal.scss';

interface Props {
  isOpen: boolean;
  onClose(): void;
}

export const ConfirmEmailModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props;

  const userState = useSelector((store: RootState) => store.user);
  const [isSending, setSending] = useState(false);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const resendConfirmEmail = (email: string) => {
    fetch(`${process.env.REACT_APP_API_URL}/auth/resend-confirm-email`, {
      method: 'post',
      body: JSON.stringify({ email })
    })
      .then((r) => r.json())
      .then((response) => {
        if (response.error) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.ERROR,
              text: t('errors.somethingWentWrong')
            })
          );
        }

        setSending(false);
      });
  };

  const b = block('ConfirmEmailModal');

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <AuthPageConfirmMessage
          email={userState.user.email as string}
          isSending={isSending}
          onButtonClick={() => resendConfirmEmail(userState.user.email as string)}
        />
      </div>
    </FreshModal>
  );
};
