export const convertEmoji = (input?: string) => {
  if (!input) {
    return '';
  }

  const charcode = parseInt(input, 16);

  if (!isNaN(charcode)) {
    return String.fromCodePoint(charcode);
  }

  return input;
};
