import React from 'react';
import PropTypes from 'prop-types';

const IconDisk = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16Z"
        stroke="#05051D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M9.00002 11.1C10.1598 11.1 11.1 10.1598 11.1 9C11.1 7.8402 10.1598 6.9 9.00002 6.9C7.84023 6.9 6.90002 7.8402 6.90002 9C6.90002 10.1598 7.84023 11.1 9.00002 11.1Z"
        stroke="#05051D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </g>
  </svg>
);

IconDisk.propTypes = {
  className: PropTypes.string
};

IconDisk.defaultProps = {
  className: ''
};

export default IconDisk;
