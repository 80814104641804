import { Story, StoryLayers } from '@features';

export const getStoriesByLayers = (stories: Story[]): StoryLayers => {
  const storiesByLayers: StoryLayers = {};

  stories
    .sort(
      (layerA, layerB) =>
        (layerA.layerData?.positionInGroup || 0) - (layerB.layerData?.positionInGroup || 0)
    )
    .forEach((item: Story) => {
      if (item.layerData) {
        if (!storiesByLayers[item.layerData.layersGroupId]) {
          storiesByLayers[item.layerData.layersGroupId] = {
            id: item.layerData.layersGroupId,
            activeLayerId: item.id,
            defaultLayerId: item.id,
            layers: { [item.id]: item },
            position: item.position
          };
        } else if (storiesByLayers[item.layerData.layersGroupId]?.layers) {
          if (item.layerData.isDefaultLayer) {
            storiesByLayers[item.layerData.layersGroupId].activeLayerId = item.id;
            storiesByLayers[item.layerData.layersGroupId].defaultLayerId = item.id;
          }
          storiesByLayers[item.layerData.layersGroupId].layers[item.id] = item;
        }
      }
    });

  return storiesByLayers;
};
