import React from 'react';
import PropTypes from 'prop-types';

const IconText2 = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    stroke="#FD19CC"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M20.0001 20.994H18.4441C17.8921 20.994 17.4441 20.546 17.4441 19.994V18.438C17.4441 17.886 17.8921 17.438 18.4441 17.438H20.0001C20.5521 17.438 21.0001 17.886 21.0001 18.438V19.994C21.0001 20.546 20.5521 20.994 20.0001 20.994Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M17.4401 19.2202H6.56006"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M5.556 20.994H4C3.448 20.994 3 20.546 3 19.994V18.438C3 17.886 3.448 17.438 4 17.438H5.556C6.108 17.438 6.556 17.886 6.556 18.438V19.994C6.556 20.546 6.108 20.994 5.556 20.994Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M20.0001 6.55014H18.4441C17.8921 6.55014 17.4441 6.10214 17.4441 5.55014V3.99414C17.4441 3.44214 17.8921 2.99414 18.4441 2.99414H20.0001C20.5521 2.99414 21.0001 3.44214 21.0001 3.99414V5.55014C21.0001 6.10214 20.5521 6.55014 20.0001 6.55014Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M17.4401 4.77002H6.56006"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M5.556 6.55014H4C3.448 6.55014 3 6.10214 3 5.55014V3.99414C3 3.44214 3.448 2.99414 4 2.99414H5.556C6.108 2.99414 6.556 3.44214 6.556 3.99414V5.55014C6.556 6.10214 6.108 6.55014 5.556 6.55014Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M4.78003 6.55029V17.4403"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M19.22 6.55029V17.4403"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M15.1824 14.72L12 8L8.81665 14.719M9.45313 13.375H14.544"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconText2.propTypes = {
  className: PropTypes.string
};

IconText2.defaultProps = {
  className: ''
};

export default IconText2;
