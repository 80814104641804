import React from 'react';
import PropTypes from 'prop-types';

const IconEditFresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 10.8333V15.8333C15 16.7542 14.2542 17.5 13.3333 17.5H4.16667C3.24583 17.5 2.5 16.7542 2.5 15.8333V6.66667C2.5 5.74583 3.24583 5 4.16667 5H7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M6.66699 12.5L9.29199 12.1759C9.47616 12.1534 9.64783 12.0692 9.77949 11.9384L16.1387 5.5792C16.8428 4.87504 16.8428 3.73337 16.1387 3.02837V3.02837C15.4345 2.3242 14.2928 2.3242 13.5878 3.02837L7.28783 9.32837C7.16033 9.45587 7.07783 9.62087 7.05199 9.80004L6.66699 12.5Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconEditFresh.propTypes = {
  className: PropTypes.string
};

IconEditFresh.defaultProps = {
  className: ''
};

export default IconEditFresh;
