import { WidgetObjectType, WidgetsTypes } from '@storysdk/react';
import { TFunction } from 'i18next';
import { AllInteractiveWidgets } from '@features/stories/consts';
import { convertEmoji } from '@utils';
import { OutputAnswerItemType } from '../types';

export const getWidgetTitle = (widget: WidgetObjectType) => {
  switch (widget.content.type) {
    case WidgetsTypes.QUESTION:
      return widget.content.params.question;
    case WidgetsTypes.TALK_ABOUT:
    case WidgetsTypes.SLIDER:
    case WidgetsTypes.CHOOSE_ANSWER:
      return widget.content.params.text;
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS:
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE:
    case WidgetsTypes.QUIZ_ONE_ANSWER:
    case WidgetsTypes.QUIZ_OPEN_ANSWER:
    case WidgetsTypes.QUIZ_RATE:
      return widget.content.params.title;
    default:
      return '';
  }
};

export const isShowPercent = (type: WidgetsTypes) => {
  switch (type) {
    case WidgetsTypes.QUESTION:
    case WidgetsTypes.TALK_ABOUT:
    case WidgetsTypes.EMOJI_REACTION:
    case WidgetsTypes.SLIDER:
    case WidgetsTypes.CHOOSE_ANSWER:
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS:
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE:
    case WidgetsTypes.QUIZ_ONE_ANSWER:
    case WidgetsTypes.QUIZ_RATE:
      return true;
    default:
      return false;
  }
};

export const getAnswer = (
  widgetType: WidgetsTypes,
  answer: OutputAnswerItemType['answer'],
  t: TFunction
) => {
  if (widgetType === WidgetsTypes.CLICK_ME) {
    return {
      emoji: null,
      title: t('dashboard.analytics.names.clicks')
    };
  }

  if (widgetType === WidgetsTypes.SWIPE_UP) {
    return {
      emoji: null,
      title: t('dashboard.analytics.names.swipes')
    };
  }

  return {
    emoji: answer.emoji ? convertEmoji(answer.emoji) : '',
    title: answer.title
  };
};

export const checkIsInteractiveWidgetsExists = (widgets: WidgetObjectType[]) =>
  widgets.some((widget) => AllInteractiveWidgets.find((type) => type === widget.content.type));
