import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteGroupModal } from '@features/groups/components/DeleteGroupModal/DeleteGroupModal';
import {
  AppCollaborationFeatures,
  GroupsType,
  fetchDeleteOnboarding,
  fetchUpdateOnboarding,
  useGetAccessByCollaboratorRole
} from '@features';
import { DateTime } from 'luxon';
import { RootState, useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import { useGroup } from '@features/groups/hooks';
import QRCode from 'react-qr-code';
import {
  FreshButton,
  FreshInput,
  FreshSwitchControl,
  FreshOutput,
  FreshLinkOutput
} from '@components/_fresh';
import { IconSaveFresh } from '@components/Icon/icons';
import { useShareLink } from '@hooks';

interface FormProps {
  name: string | null;
  startDate: number | null;
  endDate: number | null;
  isProgressHidden: boolean;
  isProhibitToClose: boolean;
  addToStories: boolean;
  isAutoplayVideos: boolean;
}

const b = block('GroupSettingsTab');

export const OnboardingSettingsTab: React.FC = () => {
  const initialFormData = {
    name: null,
    startDate: null,
    endDate: null,
    isProgressHidden: false,
    isProhibitToClose: false,
    addToStories: false,
    isAutoplayVideos: false
  };

  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormProps>(initialFormData);
  const appState = useSelector((state: RootState) => state);
  const { groupId } = useParams<{ groupId: string }>();
  const storiesGroup = useGroup(groupId, GroupsType.ONBOARDING);
  const app = appState.appManager;
  const { t } = useTranslation();

  const shareLink = useShareLink({
    groupId: storiesGroup?.id ?? '',
    type: GroupsType.ONBOARDING
  });

  const isDeleteAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.DELETE_GROUP
  });

  useEffect(() => {
    if (storiesGroup) {
      setFormData({
        name: storiesGroup.title,
        startDate: +storiesGroup.startDate || DateTime.now().toMillis(),
        endDate: +storiesGroup.endDate || null,
        isProgressHidden: storiesGroup.settings?.isProgressHidden ?? false,
        isProhibitToClose: storiesGroup.settings?.isProhibitToClose ?? false,
        addToStories: storiesGroup.settings?.addToStories ?? false,
        isAutoplayVideos: storiesGroup.settings?.autoplayVideos ?? false
      });
    }
  }, [storiesGroup]);

  if (!storiesGroup) {
    return null;
  }

  const handleChangeName = ({ value }: { value: string }) => {
    setFormData((prevState) => ({ ...prevState, name: value }));
  };

  const handleIsProgressHidden = () => {
    setFormData((prevState) => ({ ...prevState, isProgressHidden: !prevState.isProgressHidden }));
  };

  const handleIsAvailableToClose = () => {
    setFormData((prevState) => ({
      ...prevState,
      isProhibitToClose: !prevState.isProhibitToClose
    }));
  };

  const handleAddToStories = () => {
    setFormData((prevState) => ({
      ...prevState,
      addToStories: !prevState.addToStories
    }));
  };

  const handleAutoplaVideosToggle = () => {
    setFormData((prevState) => ({ ...prevState, isAutoplayVideos: !prevState.isAutoplayVideos }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (app.current && storiesGroup) {
      dispatch(
        fetchUpdateOnboarding({
          appId: app.current.id,
          groupId: storiesGroup.id,
          active: true,
          title: formData.name,
          startTime: formData.startDate,
          endTime: formData.endDate,
          settings: {
            ...storiesGroup.settings,
            isProhibitToClose: formData.isProhibitToClose,
            isProgressHidden: formData.isProgressHidden,
            addToStories: formData.addToStories,
            autoplayVideos: formData.isAutoplayVideos
          }
        })
      );
    }
  };

  const handleDeleteGroup = () => {
    if (app.current && storiesGroup) {
      dispatch(
        fetchDeleteOnboarding({
          appId: app.current.id,
          groupId: storiesGroup.id
        })
      ).then((res) => {
        setDeleteOpen(false);

        if (app.current) {
          history.push(`/dashboard/${app.current.id}/onboarding`);
        }
      });
    }
  };

  return (
    <>
      <form className={b()} onSubmit={handleSubmit}>
        <div className={b('formHeader')}>
          <h1 className={b('formHeaderTitle')}>{formData.name}</h1>

          <FreshButton
            disabled={!formData.name}
            leftIcon={<IconSaveFresh />}
            size="md"
            text={t('form.saveSettings')}
            type="submit"
          />
        </div>

        <div className={b('formColumn')}>
          <div className={b('filedSet', { noBorder: true })}>
            <div className={b('fieldGroup')}>
              <p className={b('fieldLabel')}>{t('dashboard.onboardings.groupName')}</p>

              <FreshInput
                fieldName="name"
                invalid={!formData.name}
                placeholder={t(`dashboard.onboardings.groupName`)}
                type="text"
                value={formData.name ?? undefined}
                onChange={handleChangeName}
              />
            </div>
          </div>

          <div className={b('filedSet')}>
            <div className={b('fieldGroup')}>
              <p className={b('fieldLabel')}>{t('dashboard.onboardings.identificator')}</p>

              <FreshOutput className={`${b('descGroup')}`} isCopy value={groupId} />

              <p className={b('desc')}>{t('dashboard.onboardings.identificatorDesc')}</p>
            </div>
          </div>

          <div className={b('filedSet', { flex: 'column' })}>
            <p className={b('fieldsetLabel')}>{t('dashboard.groups.shareLink')}</p>

            <FreshLinkOutput className={b('field').toString()} value={shareLink} />

            <p className={b('mainDesc')}>{t('dashboard.groups.qrDesc')}</p>

            <QRCode size={172} value={shareLink} />
          </div>

          <div className={b('filedSet', { flex: 'between' })}>
            <p className={b('fieldLabel', { noMargin: true })}>
              {t('dashboard.onboardings.addToStories')}
            </p>

            <FreshSwitchControl
              className={b('switch')}
              isChecked={formData.addToStories}
              onToggle={handleAddToStories}
            />
          </div>

          <div className={b('filedSet', { flex: 'between' })}>
            <p className={b('fieldLabel', { noMargin: true })}>
              {t('dashboard.onboardings.hideProgressBar')}
            </p>

            <FreshSwitchControl
              className={b('switch')}
              isChecked={formData.isProgressHidden}
              onToggle={handleIsProgressHidden}
            />
          </div>

          <div className={b('filedSet', { flex: 'between' })}>
            <p className={b('fieldLabel', { noMargin: true })}>
              {t('dashboard.onboardings.prohibitClose')}
            </p>

            <FreshSwitchControl
              className={b('switch')}
              isChecked={formData.isProhibitToClose}
              onToggle={handleIsAvailableToClose}
            />
          </div>

          <div className={b('filedSet', { flex: 'between' })}>
            <p className={b('fieldLabel', { noMargin: true })}>
              {t('dashboard.groups.autoplayVideos')}
            </p>

            <FreshSwitchControl
              className={b('switch')}
              isChecked={formData.isAutoplayVideos}
              onToggle={handleAutoplaVideosToggle}
            />
          </div>
          {isDeleteAvailable && (
            <a
              className={b('link')}
              role="button"
              tabIndex={0}
              onClick={() => setDeleteOpen(true)}
              onKeyDown={() => setDeleteOpen(true)}
            >
              {t('dashboard.onboardings.deleteGroup')}
            </a>
          )}
        </div>
      </form>

      <DeleteGroupModal
        handleDeleteGroup={handleDeleteGroup}
        image={`${process.env.REACT_APP_SITE_URL}/images/onboarding-active.webp`}
        isOnboarding
        isOpen={isDeleteOpen}
        title={formData.name as string}
        onClose={() => setDeleteOpen(false)}
      />
    </>
  );
};
