import React, { FC } from 'react';
import block from 'bem-cn';
import { GroupViewItem } from '..';

import './GroupViewContainer.scss';

const b = block('GroupViewContainer');

type GroupViewContainerProps = {
  isActive?: boolean;
  type: 'circle' | 'square' | 'bigSquare' | 'rectangle' | string;
  handleSelect: () => void;
};

export const GroupViewContainer: FC<GroupViewContainerProps> = ({
  type,
  isActive,
  handleSelect
}) => (
  <div
    className={b({ active: isActive })}
    role="button"
    tabIndex={0}
    onClick={handleSelect}
    onKeyDown={handleSelect}
  >
    <GroupViewItem image="/images/group1.jpg" isActive title="New York" type={type} />
    <GroupViewItem image="/images/group2.jpg" title="Paris" type={type} />
    <GroupViewItem image="/images/group3.jpg" title="Alpine" type={type} />
  </div>
);
