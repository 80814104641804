import React from 'react';
import PropTypes from 'prop-types';

const IconUnlock = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="13"
    viewBox="0 0 9 13"
    width="9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 7.44772 0.447715 7 1 7H8C8.55229 7 9 7.44772 9 8V12C9 12.5523 8.55229 13 8 13H1C0.447715 13 0 12.5523 0 12V8Z"
      fill="#AFAFAF"
    />
    <path
      clipRule="evenodd"
      d="M4.57862 1C3.15449 1 2 2.15449 2 3.57862V7H1V3.57862C1 1.6022 2.6022 0 4.57862 0C4.89937 0 5.2189 0.0393361 5.53006 0.117127L5.57761 0.129013L5.45634 0.614084L5.57761 0.129013C6.70772 0.411541 7.57389 1.31994 7.80234 2.46221L7.99029 3.40194L7.00971 3.59806L6.82176 2.65833C6.6691 1.895 6.09027 1.28796 5.33507 1.09916L5.28753 1.08727C5.05568 1.02931 4.8176 1 4.57862 1Z"
      fill="#AFAFAF"
      fillRule="evenodd"
    />
  </svg>
);

IconUnlock.propTypes = {
  className: PropTypes.string
};

IconUnlock.defaultProps = {
  className: ''
};

export default IconUnlock;
