import { StoriesGroup, GroupsType } from '@features/groups';

export const getGroupImage = (
  currentGroup: StoriesGroup,
  groupsType: GroupsType,
  image: string,
  groupId: string,
  hoveringGroup?: string
) => {
  if (groupsType === GroupsType.ONBOARDING) {
    if (currentGroup.id === groupId || hoveringGroup === currentGroup.id) {
      return `${process.env.REACT_APP_SITE_URL}/images/onboarding-active.webp`;
    }
    return `${process.env.REACT_APP_SITE_URL}/images/onboarding-default.webp`;
  }

  return image;
};
