import React from 'react';

type PropsType = {
  className?: string;
};

export const TextLineHeightIcon = ({ className = '' }: PropsType) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 15 16"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5301_13452)">
      <g clipPath="url(#clip1_5301_13452)">
        <rect
          fill="#FFFFFF"
          height="13"
          transform="rotate(-90 1 16)"
          width="0.999999"
          x="1"
          y="16"
        />
        <rect
          fill="#FFFFFF"
          height="13"
          transform="rotate(-90 1 1.00002)"
          width="0.999998"
          x="1"
          y="1.00002"
        />
      </g>
      <path
        d="M5.5 10.5L7.5 12.5L9.5 10.5M5.5 5.49999L7.5 3.49999L9.5 5.49999M7.5 12L7.5 3.99999"
        stroke="#FFFFFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5301_13452">
        <rect fill="white" height="16" width="15" />
      </clipPath>
      <clipPath id="clip1_5301_13452">
        <rect fill="white" height="16" transform="translate(14 16) rotate(180)" width="13" />
      </clipPath>
    </defs>
  </svg>
);
