import { Story } from '@features';
import { RootState } from '@store';
import { useSelector } from 'react-redux';

export const useGetStory = (layerId?: string, storyId?: string): Story | null => {
  const stories = useSelector((store: RootState) => store.stories.stories);

  if (!storyId) return null;

  const selectedLayer = layerId ? stories[layerId].layers[storyId] : null;

  return selectedLayer;
};
