import {
  appsManagerSlice,
  collaboratorsSlice,
  fontsSlice,
  groupsAnalyticsFeature,
  groupsSlice,
  informSlice,
  storiesAnalyticsGroupSlice,
  storiesSlice,
  userSlice,
  onboardingsSlice,
  templateStoriesSlice,
  subscriptionsSlice,
  templatesSlice
} from '@features';
import { Auth } from '@utils';

export const logout = (dispatch: any) => {
  dispatch(userSlice.actions.reset());
  dispatch(storiesSlice.actions.reset());
  dispatch(groupsSlice.actions.reset());
  dispatch(templatesSlice.actions.reset());
  dispatch(subscriptionsSlice.actions.reset());
  dispatch(onboardingsSlice.actions.reset());
  dispatch(templateStoriesSlice.actions.reset());
  dispatch(appsManagerSlice.actions.reset());
  dispatch(collaboratorsSlice.actions.reset());
  dispatch(groupsAnalyticsFeature.actions.reset());
  dispatch(informSlice.actions.reset());
  dispatch(fontsSlice.actions.reset());
  dispatch(storiesAnalyticsGroupSlice.actions.reset());

  Auth.clear();
};
