import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { appsManagerSlice, fetchUpdateApp } from '@features/appsManagerSlice';
import { useTranslation } from 'react-i18next';
import { FreshButton } from '@components/_fresh';
import { IconSaveFresh } from '@components';
import { RootState, useAppDispatch } from '../../../../../../store';
import { LocaleItemGroup, LocaleType } from './_components';

import './LocalizationTab.scss';

const b = block('LocalizationTab');
const s = block('GroupSettingsTab');

export const LocalizationTab = () => {
  const appState = useSelector((state: RootState) => state);
  const app = appState.appManager;
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!app.current) return;

    dispatch(
      fetchUpdateApp({
        id: app.current.id,
        appLocalization: app.current.appLocalization
      })
    );
  };
  const handleAddLocale = (locale: LocaleType) => {
    if (!app.current) return;

    dispatch(appsManagerSlice.actions.addAppLocalization(locale));
  };
  const handleMakeDefaultLocale = (localeShortName: string) => {
    dispatch(appsManagerSlice.actions.setDefaultLanguage(localeShortName));
  };
  const handleDeleteLocale = (localeShortName: string) => {
    dispatch(appsManagerSlice.actions.removeAppLocalization(localeShortName));
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className={s('formHeader')}>
        <div className={s('formHeaderContent')}>
          <h1 className={s('formHeaderTitle')}>{t('dashboard.apps.localizations.title')}</h1>
        </div>

        <FreshButton
          leftIcon={<IconSaveFresh />}
          size="md"
          text={t('form.saveSettings')}
          type="submit"
        />
      </div>

      <div className={b('languages')}>
        <p className={b('languagesTitle')}>{t('dashboard.apps.localizations.desc')}</p>

        {app.current && app.locales && (
          <LocaleItemGroup
            appLocales={app.current.appLocalization?.languages || []}
            defaultLang={app.current.appLocalization?.default}
            isDeleteAvailable={app.current.appLocalization?.languages.length > 1}
            locales={app.locales}
            onAddLocale={handleAddLocale}
            onDeleteLocale={handleDeleteLocale}
            onMakeDefault={handleMakeDefaultLocale}
          />
        )}
      </div>
    </form>
  );
};
