import React from 'react';
import PropTypes from 'prop-types';

const IconSaveFresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="white"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M13.4699 2.5H4.17245C3.24745 2.5 2.49911 3.25333 2.50578 4.17917L2.59245 15.8458C2.59911 16.7617 3.34328 17.5 4.25911 17.5H15.8266C16.7474 17.5 17.4933 16.7542 17.4933 15.8333V6.52333C17.4933 6.08167 17.3174 5.6575 17.0049 5.345L14.6483 2.98833C14.3358 2.67583 13.9124 2.5 13.4699 2.5Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.3274 2.5V5.7575C13.3274 6.2175 12.9541 6.59083 12.4941 6.59083H7.4941C7.0341 6.59083 6.66077 6.2175 6.66077 5.7575V2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M5.83325 17.5V11.0717C5.83325 10.48 6.31325 10 6.90492 10H13.0958C13.6866 10 14.1666 10.48 14.1666 11.0717V17.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconSaveFresh.propTypes = {
  className: PropTypes.string
};

IconSaveFresh.defaultProps = {
  className: ''
};

export default IconSaveFresh;
