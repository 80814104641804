import React, { useCallback, useMemo } from 'react';
import { API } from '@services';
import {
  SettingsUploadImage,
  SettingsTextarea,
  ControlGroup,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsSelect,
  SettingsFontSelector,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features/stories/editorSettings/components';
import { FONT_STYLES, GroupsType } from '@features';
import { useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import { getChangedOpacityColor, getOpacityFromColor } from '@modules/EditorModule/utils';
import {
  BackgroundColorType,
  TalkAboutWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { useParams } from 'react-router-dom';
import { Drop } from '@custom';
import { Icon, SwitchGroup } from '@components';
import {
  ColorPicker,
  DropIcon,
  SettingsBox,
  WidgetThemes,
  WidgetThemesColor
} from '../../_components';
import './TalkAboutWidgetSettings.scss';

interface Props {
  params: TalkAboutWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangePosition(params: WidgetPositionType): void;
  onChange(params: TalkAboutWidgetParamsType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
}

export const TalkAboutWidgetSettings: React.FunctionComponent<Props> = (props) => {
  const { params, positionLimits, position, onChangeAlign, onChangePosition, onChange } = props;

  const { t } = useTranslation();

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const handleChangeColor = useCallback(
    (newColor) => {
      onChange({ ...params, color: newColor });
    },
    [params, onChange]
  );

  const handleChangeText = (value: string) => {
    onChange({ ...params, text: value });
  };

  const handleChange = (field: string) => (value: any) => {
    onChange({
      ...params,
      [field]: value
    });
  };

  const handleFileChange = (files: FileList) => {
    API.storage.create({ file: files[0] }).then(({ data }) => {
      onChange({ ...params, image: data.data.file_url });
    });
  };

  const handleChangePosition = (field: string) => (value: any) => {
    if (positionLimits.keepRatio && positionLimits.ratioIndex) {
      if (field === 'width') {
        onChangePosition({
          ...position,
          width: value,
          height: Math.round(value / positionLimits.ratioIndex),
          realHeight: Math.round(value / positionLimits.ratioIndex)
        });
      } else if (field === 'height') {
        onChangePosition({
          ...position,
          width: Math.round(value * positionLimits.ratioIndex),
          realWidth: Math.round(value * positionLimits.ratioIndex),
          height: value
        });
      }
    } else {
      onChangePosition({
        ...position,
        [field]: value
      });
    }
  };

  const dispatch = useAppDispatch();

  const handleDropOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(currentStoriesSlice.actions.setIsPickerOpen(isOpen));
    },
    [dispatch]
  );

  const handleChangeTitleHide = () => {
    onChange({
      ...params,
      isTitleHidden: !params.isTitleHidden
    });
  };

  const fontOpacity = useMemo(() => {
    if (params.fontColor.type === 'color') {
      return getOpacityFromColor(params.fontColor.value);
    }

    return 100;
  }, [params.fontColor]);

  const handleChangeOpacity = (fieldName: 'fontColor') => (value: number) => {
    handleChange(fieldName)({
      type: 'color',
      value: getChangedOpacityColor(params[fieldName].value as string, value)
    });
  };

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked={position.isHeightLocked}>
            <SettingsInput
              max={positionLimits.maxWidth}
              min={positionLimits.minWidth}
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              postfix="px"
              prefix="H"
              type="number"
              value={position.realHeight}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.title')}>
        <SettingsTextarea isMarginBottom value={params.text} onChange={handleChangeText} />

        <SwitchGroup
          isChecked={params.isTitleHidden}
          label={t('editor.hideTitle')}
          onToggle={handleChangeTitleHide}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.text')}>
        <SettingsBox.Field>
          <SettingsFontSelector value={params.fontFamily} onChange={handleChange('fontFamily')} />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsSelect
              options={FONT_STYLES}
              value={params.fontParams}
              onChange={handleChange('fontParams')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
              value={params.fontColor}
              onChange={handleChange('fontColor')}
            />
            <SettingsInput
              icon={<DropIcon />}
              max={100}
              type="number"
              value={fontOpacity}
              onChange={handleChangeOpacity('fontColor')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.image')}>
        <SettingsUploadImage image={params.image} onChange={handleFileChange} />
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.themes')}>
        <Drop
          control={<WidgetThemesColor color={params.color} hasDarkBackground />}
          handleOpen={handleDropOpen}
        >
          <WidgetThemes selectedColor={params.color} onChange={handleChangeColor} />
        </Drop>
      </SettingsBox.Group>
    </>
  );
};
