import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import block from 'bem-cn';
import { FreshInput } from '@components/_fresh';
import { getWeekDays, getMonthName, getAllDaysInMonth, getDaysOfNextMonth } from './_utils';
import { FreshCalendarHeader } from './_components/FreshCalendarHeader';
import { FreshCalendarDayView } from './_components/FreshCalendarDayView';
import './FreshCalendar.scss';

const b = block('FreshCalendar');

type PropsType = {
  lang?: any;
  disabled?: boolean;
  id: string;
  theme?: 'dark' | 'light';
  onChange(params: { value: number | null }): void;
  value: any;
  withTime?: boolean;
  type: 'single' | 'range';
  canBeEmpty?: boolean;
};

export const FreshCalendar = ({
  disabled = false,
  id,
  lang = 'en',
  onChange,
  value,
  withTime = false,
  type,
  theme = 'light',
  canBeEmpty
}: PropsType) => {
  const nowDateTime = useMemo(() => DateTime.now().startOf('day').toSeconds(), []);

  const { t } = useTranslation();

  const now = useMemo(() => {
    if (type === 'single') {
      return value || nowDateTime;
    }

    return value.from || nowDateTime;
  }, [value, nowDateTime, type]);

  const [getDate, setDate] = useState(now);

  const [time, setTime] = useState({
    hours: '00',
    minutes: '00'
  });

  useEffect(() => {
    if (type === 'single') {
      setTime({
        hours: value ? DateTime.fromSeconds(value).toFormat('HH') : '00',
        minutes: value ? DateTime.fromSeconds(value).toFormat('mm') : '00'
      });
    }
  }, [type]);

  const month: string = useMemo(
    () =>
      `${getMonthName(lang, DateTime.fromSeconds(getDate).month - 1)} ${DateTime.fromSeconds(getDate).year
      }`,
    [lang, getDate]
  );

  const weekDays = useMemo(() => getWeekDays(lang), [lang]);

  const allDaysInMonth = useMemo(() => getAllDaysInMonth(getDate), [getDate]);

  const prevFirstDayOnWeek = new Array(allDaysInMonth[0].weekday - 1)
    .fill(1)
    .map((item, index) => item + index);

  const prevLastDayPrevMonth = allDaysInMonth[0].minus({ months: 1 }).endOf('month');

  const firstWeelOfNextMonth = useMemo(
    () => getDaysOfNextMonth(allDaysInMonth[0].plus({ months: 1 }).startOf('day')),
    [allDaysInMonth]
  );

  const handlePrevMonth = useCallback(() => {
    const prevMonth = DateTime.fromSeconds(getDate).minus({ months: 1 }).toSeconds();
    setDate(prevMonth);
  }, [getDate]);

  const handleNextMonth = useCallback(() => {
    const nextMonth = DateTime.fromSeconds(getDate).plus({ months: 1 }).toSeconds();
    setDate(nextMonth);
  }, [getDate]);

  const handleChangeTime = (params: any) => {
    let isValid = false;

    switch (params.fieldName) {
      case 'hours':
        if ((params.value && params.value.match(/^([0-1]?[0-9]|2[0-3])$/)) || !params.value) {
          isValid = true;
        }
        break;
      case 'minutes':
        if ((params.value && params.value.match(/^([0-5]?[0-9])$/)) || !params.value) {
          isValid = true;
        }
        break;
    }

    if (isValid) {
      setTime((prevState) => ({ ...prevState, [params.fieldName]: params.value }));
    }
  };

  useEffect(() => {
    if (value && type === 'single') {
      let newDate = DateTime.fromSeconds(value);
      newDate = newDate.set({
        hour: +time.hours,
        minute: +time.minutes
      });
      onChange({ value: newDate.toSeconds() });
    }
  }, [time]);

  return (
    <div
      className={b({
        theme,
        withTime
      })}
      id={id}
    >
      <div
        className={b('month', {
          withTime
        })}
      >
        <FreshCalendarHeader
          month={month}
          theme={theme}
          onNextMonth={handleNextMonth}
          onPrevMonth={handlePrevMonth}
        />
        <div
          className={b('content', {
            withTime: withTime && !!value
          })}
        >
          <div className={b('week')}>
            {weekDays.map((dayName, key) => (
              <span
                className={b('week-name', {
                  end: key >= 5
                })}
                key={`w${dayName}`}
              >
                {dayName}
              </span>
            ))}
          </div>
          <div className={b('days')}>
            {prevFirstDayOnWeek.map((el, index) => (
              <button className={b('day-empty')} key={el} type="button">
                {prevLastDayPrevMonth.day - (prevFirstDayOnWeek.length - index - 1)}
              </button>
            ))}
            {allDaysInMonth.map((day) => (
              <FreshCalendarDayView
                day={day.toSeconds()}
                disabled={disabled}
                key={day.toISODate()}
                theme={theme}
                type={type}
                value={value}
                onClick={onChange}
              />
            ))}
            {firstWeelOfNextMonth.map((el, index) => (
              <button className={b('day-empty')} key={el} type="button">
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>

      {withTime && value && (
        <div className={b('timeContainer')}>
          <FreshInput
            className={b('timeInput')}
            fieldName="hours"
            theme={theme}
            type="text"
            value={time.hours}
            onChange={handleChangeTime}
          />
          <span className={b('timeContainerDots', { theme })}>:</span>
          <FreshInput
            className={b('timeInput')}
            fieldName="minutes"
            theme={theme}
            type="text"
            value={time.minutes}
            onChange={handleChangeTime}
          />
        </div>
      )}

      {canBeEmpty && (
        <div className={b('bottomBtnContainer')}>
          <button
            className={b('withoutBtn', { theme })}
            onClick={(e) => {
              e.preventDefault();
              onChange({ value: null });
            }}
          >
            {t('form.withoutDate')}
          </button>
        </div>
      )}
    </div>
  );
};
