import React from 'react';

type PropsType = {
  className?: string;
};

export const ThicknessIcon = ({ className = '' }: PropsType) => (
  <svg
    className={className}
    fill="none"
    height="11"
    viewBox="0 0 12 11"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8484 2.35714H0V1.49702H11.8484V2.35714ZM11.8484 0H0V0.344548H11.8484V0ZM0.151144 3.49917V5.3119H12V3.49917H0.151144ZM0 10.235H11.8484V6.17749H0V10.235Z"
      fill="white"
    />
  </svg>
);
