import React from 'react';
import PropTypes from 'prop-types';

const IconBilling = ({ className }) => (
  <svg
    className={className}
    fill="#A49BA4"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.29 6.32971C15.9536 6.17973 15.5969 6.12298 15.226 6.16149C14.9301 6.19189 14.6362 6.29526 14.3545 6.46348L12.9175 7.32485L11.8798 4.78735C11.6548 4.23607 11.213 3.81451 10.6658 3.62602C10.2726 3.49023 9.85306 3.47807 9.45176 3.58954C8.85792 3.75371 8.35732 4.20973 8.11411 4.80762L7.08452 7.32485L5.64755 6.46348C5.36583 6.29323 5.07195 6.19189 4.77604 6.16149C4.40717 6.12096 4.04844 6.17973 3.712 6.32971C2.97629 6.65804 2.5 7.40592 2.5 8.23283V8.26323C2.5 8.49428 2.53851 8.72331 2.61553 8.9422L3.95724 14.305C4.27746 15.5899 5.42866 16.4858 6.75213 16.4858H13.2479C14.5713 16.4858 15.7205 15.5879 16.0428 14.305L17.3845 8.9422C17.4615 8.72331 17.5 8.49631 17.5 8.26526V8.23486C17.5 7.40794 17.0237 6.66007 16.288 6.33174L16.29 6.32971ZM15.8806 8.26323C15.8806 8.31593 15.8705 8.3666 15.8523 8.41524C15.8401 8.44564 15.832 8.47604 15.8239 8.50644L14.472 13.9098C14.3322 14.4712 13.8295 14.8644 13.2499 14.8644H6.75416C6.1745 14.8644 5.6739 14.4732 5.53202 13.9098L4.18018 8.50644C4.17207 8.47604 4.16396 8.44564 4.1518 8.41524C4.13356 8.3666 4.12343 8.3139 4.12343 8.26121V8.23081C4.12343 8.04232 4.22274 7.87612 4.37475 7.80721C4.43352 7.78087 4.4923 7.76871 4.55513 7.76871C4.57134 7.76871 4.58958 7.76871 4.6058 7.77073C4.66255 7.77681 4.73754 7.80519 4.81253 7.8518L6.7724 9.02732C6.94264 9.13068 7.13721 9.18338 7.33583 9.18338C7.78375 9.18338 8.18302 8.91585 8.35326 8.50036L9.6139 5.41767C9.66863 5.28188 9.77199 5.17851 9.88346 5.14811C9.96859 5.12379 10.0537 5.12785 10.1368 5.15622C10.2402 5.1927 10.3273 5.27985 10.376 5.3974L11.6447 8.50036C11.815 8.91585 12.2142 9.18338 12.6621 9.18338C12.8587 9.18338 13.0553 9.12866 13.2256 9.02732L15.1854 7.8518C15.2625 7.80519 15.3334 7.77681 15.3922 7.77276C15.4753 7.76465 15.5503 7.77681 15.6232 7.80924C15.7773 7.87815 15.8745 8.04434 15.8745 8.23283V8.26323H15.8806Z" />
    <path d="M12.1429 12H7.85714C7.38357 12 7 12.3836 7 12.8571C7 13.3307 7.38357 13.7143 7.85714 13.7143H12.1429C12.6164 13.7143 13 13.3307 13 12.8571C13 12.3836 12.6164 12 12.1429 12Z" />
  </svg>
);

IconBilling.propTypes = {
  className: PropTypes.string
};

IconBilling.defaultProps = {
  className: ''
};

export default IconBilling;
