import React from 'react';
import PropTypes from 'prop-types';

const IconCloseCircle = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#F8F7F8" height="11" rx="5.5" width="11" x="0.5" y="0.5" />
    <rect height="11" rx="5.5" stroke="#E9E6E9" width="11" x="0.5" y="0.5" />
    <path d="M4 4L8 8M8 4L4 8" stroke="#A49BA4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconCloseCircle.propTypes = {
  className: PropTypes.string
};

IconCloseCircle.defaultProps = {
  className: ''
};

export default IconCloseCircle;
