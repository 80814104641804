import React, { memo } from 'react';
import cn from 'classnames';
import './ReadOnlyWidgetControl.scss';

const CLASS_NAME = 'ReadOnlyWidgetControl';

type PropsType = {
  text: string;
  isVertical?: boolean;
  justifyStart?: boolean;
  icon?: React.ReactNode;
};

export const ReadOnlyWidgetControl = memo(
  ({ text = '', icon, isVertical = false, justifyStart }: PropsType) => (
    <div
      className={cn(CLASS_NAME, {
        [`${CLASS_NAME}--isVertical`]: isVertical,
        [`${CLASS_NAME}--justifyStart`]: justifyStart
      })}
    >
      <div
        className={cn(`${CLASS_NAME}__icon-wrapper`, {
          [`${CLASS_NAME}__icon-wrapper--isVertical`]: isVertical
        })}
      >
        {icon}
      </div>
      {text}
    </div>
  )
);

ReadOnlyWidgetControl.displayName = 'ReadOnlyWidgetControl';
