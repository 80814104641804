import React, { useEffect, useState } from 'react';
import { getReducedValue } from '@features/stories/utils';
import { BondsType, CARD_WIDTH_INIT } from '@features';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import iphoneMockupBottom from '@images/iphone-footer.png';
import dynamicIslandMockup from '@images/dynamic-island-mockup.svg';
import { IconIphoneBattery, IconIphoneCellular, IconIphoneWifi } from '@components';
import './StoryEditorSafeZones.scss';

interface StoryEditorSafeZonesProps {
  cardTop: number;
  cardHeight: number;
  backgroundTop?: number;
  isOnboarding?: boolean;
  topBarHeight: number;
  bottomBarHeight: number;
  backgroundSize: {
    width: number;
    height: number;
  };
  showBounds: BondsType;
  isFilled?: boolean;
  isShowToolbar?: boolean;
  showTopbar?: boolean;
  isSafeZones?: boolean;
  borderRadius?: number;
  isCentered?: boolean;
}

const INIT_ELEMENT_STYLES = {
  backgroundTop: 20,
  bakgroundBorderRadius: 20,
  topFontSize: 14,
  tooltipFontSize: 12,
  tooltipPostition: 19,
  topBarHeight: 73,
  paddingSideMockTop: 37,
  paddingSideMockBottom: 14,
  paddingBottomMockTop: 20,
  paddingTopMockBottom: 15,
  paddingBottomMockBottom: 35,
  mockIconCellularWidth: 17,
  mockIconWifiWidth: 15,
  mockIconBatteryWidth: 25,
  mockIconHeight: 17,
  safeZoneBorderRadius: 35,
  safeZoneSideMargin: 5,
  dynamicIslandWidth: 110
};

const b = block('StoryEditorSafeZones');

export const StoryEditorSafeZones: React.FC<StoryEditorSafeZonesProps> = ({
  cardTop,
  cardHeight,
  isFilled,
  topBarHeight,
  bottomBarHeight,
  backgroundSize,
  isOnboarding,
  showBounds,
  showTopbar,
  isShowToolbar,
  isSafeZones,
  borderRadius,
  isCentered,
  backgroundTop
}) => {
  const tooltipFontSize = getReducedValue(
    INIT_ELEMENT_STYLES.tooltipFontSize * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const tooltipPostition = getReducedValue(
    INIT_ELEMENT_STYLES.tooltipPostition * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const topFontSize = getReducedValue(
    INIT_ELEMENT_STYLES.topFontSize * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const paddingSideMockTop = getReducedValue(
    INIT_ELEMENT_STYLES.paddingSideMockTop * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const paddingSideMockBottom = getReducedValue(
    INIT_ELEMENT_STYLES.paddingSideMockBottom * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const paddingBottomMockBottom = getReducedValue(
    INIT_ELEMENT_STYLES.paddingBottomMockBottom * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const mockIconCellularWidth = getReducedValue(
    INIT_ELEMENT_STYLES.mockIconCellularWidth * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const mockIconWifiWidth = getReducedValue(
    INIT_ELEMENT_STYLES.mockIconWifiWidth * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const mockIconBatteryWidth = getReducedValue(
    INIT_ELEMENT_STYLES.mockIconBatteryWidth * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const mockIconHeight = getReducedValue(
    INIT_ELEMENT_STYLES.mockIconHeight * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const paddingTopMockBottom = getReducedValue(
    INIT_ELEMENT_STYLES.paddingTopMockBottom * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const borderRadiusSafeZone = getReducedValue(
    INIT_ELEMENT_STYLES.safeZoneBorderRadius * backgroundSize.width,
    CARD_WIDTH_INIT
  );
  const dynamycIslandWidth = getReducedValue(
    INIT_ELEMENT_STYLES.dynamicIslandWidth * backgroundSize.width,
    CARD_WIDTH_INIT
  );

  const { t } = useTranslation();

  const [time, setTime] = useState(DateTime.now().toFormat('HH:mm'));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(DateTime.now().toFormat('HH:mm'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        className={b('background', { centered: isCentered })}
        style={{
          width: backgroundSize.width,
          height: isFilled ? backgroundSize.height : cardHeight,
          top: isFilled ? backgroundTop : cardTop,
          borderRadius
        }}
      />

      {showTopbar && (
        <div
          className={b('backgroundMockTop', {
            yellow: showBounds.top && isSafeZones && !isOnboarding,
            transparent: (isFilled && !showBounds.top) || isOnboarding
          })}
          style={{
            top: isFilled ? backgroundTop : cardTop,
            height: topBarHeight,
            paddingLeft: paddingSideMockTop,
            paddingRight: paddingSideMockTop,
            borderTopLeftRadius: borderRadiusSafeZone,
            borderTopRightRadius: borderRadiusSafeZone
          }}
        >
          {showBounds.top && (
            <div
              className={b('tooltip')}
              style={{
                top: 0,
                fontSize: tooltipFontSize
              }}
            >
              {t('editor.safeZoneTooltipText')}
            </div>
          )}

          {!showBounds.top && isShowToolbar && (
            <>
              <span
                className={b('backgroundMockTopTime')}
                style={{
                  fontSize: topFontSize
                }}
              >
                {time}
              </span>

              <img
                alt=""
                className={b('dynamicIslandImage')}
                src={dynamicIslandMockup}
                width={dynamycIslandWidth}
              />

              <div
                className={b('backgroundMockTopRightContainer')}
                style={{
                  height: mockIconHeight
                }}
              >
                <div
                  className={b('backgroundMockTopIconWrapper')}
                  style={{
                    width: mockIconCellularWidth
                  }}
                >
                  <IconIphoneCellular className={b('backgroundMockTopIcon').toString()} />
                </div>
                <div
                  className={b('backgroundMockTopIconWrapper')}
                  style={{
                    width: mockIconWifiWidth
                  }}
                >
                  <IconIphoneWifi className={b('backgroundMockTopIcon').toString()} />
                </div>
                <div
                  className={b('backgroundMockTopIconWrapper')}
                  style={{
                    width: mockIconBatteryWidth
                  }}
                >
                  <IconIphoneBattery className={b('backgroundMockTopIcon').toString()} />
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {isSafeZones && (
        <div
          className={b('backgroundMockBottom', {
            yellow: showBounds.bottom,
            transparent: isFilled && !showBounds.bottom
          })}
          style={{
            bottom: tooltipPostition,
            height: bottomBarHeight,
            paddingTop: paddingTopMockBottom,
            paddingLeft: paddingSideMockBottom,
            paddingRight: paddingSideMockBottom,
            paddingBottom: paddingBottomMockBottom,
            borderBottomLeftRadius: borderRadiusSafeZone,
            borderBottomRightRadius: borderRadiusSafeZone
          }}
        >
          {showBounds.bottom ? (
            <div
              className={b('tooltip')}
              style={{
                bottom: 0,
                fontSize: tooltipFontSize
              }}
            >
              {t('editor.safeZoneTooltipText')}
            </div>
          ) : (
            <img
              alt=""
              className={b('backgroundMockBottomImage', { hide: isFilled })}
              src={iphoneMockupBottom}
            />
          )}
        </div>
      )}
    </>
  );
};
