import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { informSlice, MessageTypes } from '@features/inform';
import { RootState } from '@store';
import { appsManagerSlice } from '@features/appsManagerSlice';
import i18n from '../../i18n/i18n';
import { CollabaratorRole, CollabaratorStatus } from './types';

export interface Collabarator {
  id: string;
  appId: string;
  email: string;
  userId: string;
  creatorId: string;
  role: CollabaratorRole;
  status: CollabaratorStatus;
  createdAt: string;
  updatedAt: string;
}

interface State {
  list: Collabarator[];
  status: string;
}

const initialState: State = {
  list: [],
  status: 'idle'
};

export const collaboratorsSlice = createSlice({
  name: 'collaborators',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<Collabarator[]>) => {
      state.list = action.payload;
      state.status = 'loaded';
    },
    add: (state, action: PayloadAction<Collabarator>) => {
      state.list.push(action.payload);
    },
    remove: (state, action: PayloadAction<string>) => {
      const findIndex = state.list.findIndex(({ id }) => id === action.payload);
      state.list.splice(findIndex, 1);
    },
    update: (state, action: PayloadAction<Collabarator>) => {
      state.list = state.list.map((group) =>
        group.id === action.payload.id ? action.payload : group
      );
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    }
  }
});

export const fetchCollaborators = createAsyncThunk(
  'collaborators/fetchCollaborators',
  async (params: { appId: string }, { dispatch }) => {
    dispatch(collaboratorsSlice.actions.setStatus('pending'));
    const { data } = await API.collabolators.getList(params);

    if (data.data && !data.error) {
      dispatch(
        collaboratorsSlice.actions.init(
          data.data.map((rawCollab: any) => ({
            id: rawCollab.id,
            appId: rawCollab.app_id,
            userId: rawCollab.collaborator_id,
            email: rawCollab.collaborator_email,
            creatorId: rawCollab.creator_id,
            role: rawCollab.role,
            status: rawCollab.status,
            createdAt: rawCollab.created_at,
            updatedAt: rawCollab.updated_at
          }))
        )
      );
    }
  }
);

export const fetchCreateCollaborator = createAsyncThunk(
  'collaborators/fetchCreateCollaborator',
  async (
    params: { appId: string; email: string; role: CollabaratorRole },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const collaboratorsCount = state.appManager.current?.collaboratorsCount ?? 0;

    const isExist = state.collaborators.list.find(
      (collaborator: Collabarator) => collaborator.email === params.email
    );

    if (isExist) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.collaborators.alreadyExist')
        })
      );
      return;
    }

    const { data } = await API.collabolators.create(params);

    if (data.data && !data.error) {
      dispatch(
        collaboratorsSlice.actions.add({
          id: data.data.id,
          appId: params.appId,
          userId: data.data.collaborator_id,
          email: data.data.collaborator_email,
          creatorId: '',
          role: data.data.role,
          status: data.data.status,
          createdAt: '',
          updatedAt: ''
        })
      );

      dispatch(appsManagerSlice.actions.updateCollaboratorsCount(collaboratorsCount + 1));

      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.collaborators.added')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.collaborators.addError')
        })
      );
    }
  }
);

export const fetchUpdateCollaborator = createAsyncThunk(
  'collaborators/fetchUpdateCollaborator',
  async (params: { appId: string; collabolatorId: string; role: string }, { dispatch }) => {
    const { data } = await API.collabolators.update(params);

    if (data.data && !data.error) {
      dispatch(
        collaboratorsSlice.actions.update({
          id: params.collabolatorId,
          appId: params.appId,
          userId: data.data.collaborator_id,
          email: data.data.collaborator_email,
          creatorId: '',
          role: data.data.role,
          status: data.data.status,
          createdAt: '',
          updatedAt: ''
        })
      );

      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.collaborators.updated')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.collaborators.updateError')
        })
      );
    }
  }
);

export const fetchRemoveCollaborator = createAsyncThunk(
  'collaborators/fetchRemoveCollaborator',
  async (params: { appId: string; collaboratorId: string }, { dispatch, getState }) => {
    const { data } = await API.collabolators.remove(params);
    const state = getState() as RootState;

    const collaboratorsCount = state.appManager.current?.collaboratorsCount ?? 0;

    if (data.data && !data.error) {
      dispatch(collaboratorsSlice.actions.remove(params.collaboratorId));

      if (collaboratorsCount > 0) {
        dispatch(appsManagerSlice.actions.updateCollaboratorsCount(collaboratorsCount - 1));
      }

      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.collaborators.removed')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.collaborators.removeError')
        })
      );
    }
  }
);

export const fetchResendInviteToCollaborator = createAsyncThunk(
  'collaborators/fetchResendInviteToCollaborator',
  async (params: { appId: string; collaboratorId: string }, { dispatch }) => {
    const { data } = await API.collabolators.resend(params);

    if (data.data && !data.error) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.collaborators.resend')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.collaborators.resendError')
        })
      );
    }
  }
);
