import React from 'react';

export const StoryRenderingFallback = () => (
  <div
    role="alert"
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    Story render error
  </div>
);
