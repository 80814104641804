import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { fontsSlice } from '@features/fonts';
import { productsSlice } from '@features/products/productsSlice';
import { subscriptionsSlice } from '@features/subscriptions/subscriptionsSlice';
import { collaboratorsSlice } from './features/collaborators';
import { storiesAnalyticsGroupSlice } from './features/storiesAnalyticsFeature';
import { groupsAnalyticsFeature } from './features/groupsAnalyticsFeature';
import { informSlice } from './features/inform';
import { groupsSlice, onboardingsSlice } from './features/groups';
import { appsManagerSlice } from './features/appsManagerSlice';
import { storiesSlice } from './features/stories';
import { userSlice } from './features/user';
import { templateStoriesSlice, templatesSlice } from './features/storyTemplates';

const store = configureStore({
  reducer: {
    form: reduxFormReducer,
    user: userSlice.reducer,
    inform: informSlice.reducer,
    appManager: appsManagerSlice.reducer,
    groups: groupsSlice.reducer,
    onboardings: onboardingsSlice.reducer,
    templates: templatesSlice.reducer,
    templateStories: templateStoriesSlice.reducer,
    storiesAnalyticsGroup: storiesAnalyticsGroupSlice.reducer,
    groupsAnalytic: groupsAnalyticsFeature.reducer,
    products: productsSlice.reducer,
    subscriptions: subscriptionsSlice.reducer,
    collaborators: collaboratorsSlice.reducer,
    stories: storiesSlice.reducer,
    fonts: fontsSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
