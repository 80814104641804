import React from 'react';
import PropTypes from 'prop-types';

const IconSchedule = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83157 14.168L9.92078 14.1989"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M9.99997 10.8333H14.1684"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M17.5031 7.49838H2.49686"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.66525 2.07959V4.58063"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.3347 2.07959V4.58063"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M15.0021 17.5026H4.9979C3.61649 17.5026 2.49686 16.383 2.49686 15.0016V5.83112C2.49686 4.44971 3.61649 3.33008 4.9979 3.33008H15.0021C16.3835 3.33008 17.5031 4.44971 17.5031 5.83112V15.0016C17.5031 16.383 16.3835 17.5026 15.0021 17.5026Z"
      fillRule="evenodd"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconSchedule.propTypes = {
  className: PropTypes.string
};

IconSchedule.defaultProps = {
  className: ''
};

export default IconSchedule;
