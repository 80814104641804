import {
  BackgroundType,
  ChooseAnswerState,
  ClickMeState,
  EllipseState,
  EmojiReactionState,
  GiphyState,
  MediaType,
  QuestionState,
  QuizMultipleAnswerState,
  QuizMultipleAnswerWithImageState,
  QuizOneAnswerState,
  QuizOpenAnswerState,
  QuizRateState,
  RectangleState,
  SliderState,
  SwipeUpState,
  TalkAboutState,
  TextState,
  TimerState,
  WidgetObjectType,
  WidgetPositionType
} from '@storysdk/react';

export * from './StoriesState';

export enum SaveStatus {
  NOT_SAVED = 'NOT_SAVED',
  SAVING = 'SAVING',
  SAVED = 'SAVED'
}

export enum StoryStatus {
  DRAFT = 'draft',
  ACTIVE = 'active'
}

export enum ElementHorizontalAlign {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER'
}

export enum ElementVerticalAlign {
  TOP = 'LEFT',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM'
}
export interface ElementSizeType {
  width: number | string;
  height: number | string;
}

export enum SettingsAnswersType {
  EMOJI = 'emoji',
  ID = 'id',
  IMAGE = 'image'
}

export enum ScoreType {
  NUMBERS = 'numbers',
  LETTERS = 'letters'
}
export type BackgroundBlob = { type: MediaType; value: Blob; metadata?: any };
export interface GroupPositionType {
  x: number;
  y: number;
  width: number;
  height: number;
  rotate: number;
  lastAlign?: ElementHorizontalAlign | ElementVerticalAlign | null;
}

export type WidgetContentType =
  | RectangleState
  | EllipseState
  | TextState
  | SwipeUpState
  | SliderState
  | QuestionState
  | ClickMeState
  | TalkAboutState
  | EmojiReactionState
  | TimerState
  | ChooseAnswerState
  | GiphyState
  | QuizOneAnswerState
  | QuizMultipleAnswerState
  | QuizMultipleAnswerWithImageState
  | QuizRateState
  | QuizOpenAnswerState;

export interface StoryStatistic {
  actions: number;
  back: number;
  close: number;
  interactions: any;
  duration?: number;
  next: number;
  views: number;
  timeOfCompletion: number;
  start: number;
  finish: number;
  completionRate: number;
}

export interface LayerData {
  layersGroupId: string;
  positionInGroup: number;
  isActiveLayerInEditor?: boolean;
  isDefaultLayer: boolean;
  isWidgetsSizeSyncActive?: boolean;
  score: {
    letter: string;
    points: number;
  };
  shortDataId?: string;
}

interface StoryDataContent {
  background: BackgroundType;
  widgets: WidgetObjectType[];
}

interface StoryDataInfo extends StoryDataContent {
  start_time?: string | null;
  end_time?: string | null;
  status: StoryStatus;
  is_hidden: boolean;
  is_sync_design_active: boolean;
  history: {
    story_data: StoryDataContent | null;
    published_at: string | null;
  };
}

export interface StoryData {
  [key: string]: StoryDataInfo;
}

export interface Story extends StoryDataContent {
  id: string;
  createdAt: string;
  storyData: StoryData;
  layerData: LayerData;
  type: 'new' | 'exist';
  position: number;
  statistic?: StoryStatistic;
  isHasUnpublishedChanges?: boolean;
  isSyncDesignActive?: boolean;
}

export interface StoryLayer {
  id: string;
  activeLayerId: string;
  defaultLayerId: string;
  position: number;
  layers: {
    [key: string]: Story;
  };
}

export interface StoryLayers {
  [key: string]: StoryLayer;
}

export type FontParamsType = {
  style: string;
  weight: number;
};

export type EmojiItemType = {
  name: string;
  unicode: string;
};

export type WidgetElemetsSizes = {
  widget?: {
    width: number;
    height: number;
  };
  title?: {
    width: number;
    height: number;
    fontSize: number;
  };
  answers?: {
    [key: string]: {
      width: number;
      height: number;
      fontSize: number;
    };
  };
};

export interface LayerWidgetObjectType extends WidgetObjectType {
  storyId: string;
  layersGroupId: string;
}

export type ScheduleTimeType = {
  startTime: string | null;
  endTime: string | null;
};

export interface WidgetObjectTypeExtended extends WidgetObjectType {
  position?: WidgetPositionType;
}
