import { COPY_WIDGET_DEVIATION } from '@features/consts';
import { nanoid } from 'nanoid';
import { WidgetObjectType } from '@storysdk/react';

export const makeNewWidgetFromExist = ({
  widget,
  copyDeviation = COPY_WIDGET_DEVIATION,
  storyWidth,
  storyHeight
}: {
  widget: WidgetObjectType;
  copyDeviation?: number;
  storyWidth: number;
  storyHeight: number;
}) => ({
  ...widget,
  position: {
    ...widget.positionByResolutions[`${storyWidth}x${storyHeight}`],
    origin: {
      width: widget.positionByResolutions[`${storyWidth}x${storyHeight}`].origin?.width ?? 0,
      height: widget.positionByResolutions[`${storyWidth}x${storyHeight}`].origin?.height ?? 0,
      x: widget.positionByResolutions[`${storyWidth}x${storyHeight}`].x + copyDeviation,
      y: widget.positionByResolutions[`${storyWidth}x${storyHeight}`].y + copyDeviation
    },
    x: widget.positionByResolutions[`${storyWidth}x${storyHeight}`].x + copyDeviation,
    y: widget.positionByResolutions[`${storyWidth}x${storyHeight}`].y + copyDeviation
  },
  id: `W${nanoid()}`
});
