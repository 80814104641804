import { REDUCE_INDEX, SCALE_INDEX } from '@features';

export const getScalableValue = (value: number, index?: number): number =>
  Math.round(value * (index || SCALE_INDEX));

export const getReducedValue = (value: number, index?: number): number =>
  Math.round(value / (index || SCALE_INDEX));

export const getIncreasedValuePosition = (position: any, index?: number) => {
  const newPosition = { ...position };

  for (const key in newPosition) {
    if (Object.prototype.hasOwnProperty.call(newPosition, key)) {
      const element = newPosition[key];
      if (typeof element === 'number' && key !== 'rotate') {
        newPosition[key] = getScalableValue(element, index ?? REDUCE_INDEX);
      } else if (typeof element === 'string') {
        newPosition[key] = element;
      } else if (typeof element === 'object') {
        newPosition[key] = getIncreasedValuePosition(element);
      }
    }
  }

  return newPosition;
};

export const getReducedValuePosition = (position: any, index?: number) => {
  const newPosition = { ...position };

  for (const key in newPosition) {
    if (Object.prototype.hasOwnProperty.call(newPosition, key)) {
      const element = newPosition[key];
      if (typeof element === 'number' && key !== 'rotate') {
        newPosition[key] = getReducedValue(element, index ?? REDUCE_INDEX);
      } else if (typeof element === 'string') {
        newPosition[key] = element;
      } else if (typeof element === 'object') {
        newPosition[key] = getReducedValuePosition(element);
      }
    }
  }

  return newPosition;
};
