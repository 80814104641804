export const groupStatisticDataAdapter = (data: any) => ({
  click: data.click,
  completionRate: data.complition_rate,
  duration: data.duration,
  finish: data.finish,
  impression: data.impression,
  open: data.open,
  start: data.start,
  timeOfCompletion: data.time_of_complition
});
