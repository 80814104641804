import React from 'react';
import PropTypes from 'prop-types';

const IconRefresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.25 3v4.5h-4.5M.75 15v-4.5h4.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
    <path
      d="M2.632 6.75A6.75 6.75 0 0113.77 4.23l3.48 3.27m-16.5 3l3.48 3.27a6.75 6.75 0 0011.137-2.52"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconRefresh.propTypes = {
  className: PropTypes.string
};

IconRefresh.defaultProps = {
  className: ''
};

export default IconRefresh;
