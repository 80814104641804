import { WidgetObjectType, WidgetsTypes } from '@storysdk/react';
import { calculateElementSize, calculateElementSizeByHeight } from '@modules';
import { WidgetElementsSizes } from '@features/stories/utils';
import { WidgetSizeType } from '@hooks';

export const calulateWidgetSizeValues = (
  obj: any,
  widget: WidgetObjectType,
  widgetSize: WidgetSizeType
) => {
  for (const k in obj) {
    if (obj[k] && typeof obj[k] === 'object' && obj[k] !== null) {
      calulateWidgetSizeValues(obj[k], widget, widgetSize);
    } else if (obj[k] && typeof obj[k] === 'number') {
      obj[k] =
        widget.content.type === WidgetsTypes.EMOJI_REACTION
          ? calculateElementSizeByHeight(+widgetSize.height, widget.positionLimits, obj[k])
          : calculateElementSize(+widgetSize.width, widget.positionLimits, obj[k]);
    }
  }
};

export const getInitWidgetSizes = (type: WidgetsTypes) => {
  if (type in WidgetElementsSizes) {
    // @ts-ignore
    return WidgetElementsSizes[type];
  }

  return undefined;
};
