import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@store';
import {
  StoriesTemplateBase,
  Templates,
  fetchCreateTemplate,
  fetchUpdateTemplate
} from '@features';
import { FormGroup, FormLabel, IconSelectArrowFresh } from '@components';
import {
  FreshModal,
  FreshModalTitle,
  FreshButton,
  FreshInput,
  FreshTextarea,
  FreshDrop
} from '@components/_fresh';
import './CreateTemplateModal.scss';

const b = block('CreateTemplateModal');

interface Props {
  isOpen: boolean;
  template?: StoriesTemplateBase;
  onClose(): void;
}

interface State {
  name: string;
  description: string;
  category: Templates.Category;
}

export const CreateTemplateModal: React.FC<Props> = (props) => {
  const { template, isOpen, onClose } = props;
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const initialState: State = {
    name: template?.title || '',
    description: template?.description || '',
    category: template?.category || Templates.Category.STORIES
  };

  useEffect(() => {
    if (template) {
      setGroupForm({
        name: template.title,
        description: template.description,
        category: template.category
      });
    }
  }, [template]);

  const [isInvalid, setInvalid] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [groupForm, setGroupForm] = useState(initialState);

  const templateCategoriesMap = {
    [Templates.Category.STORIES]: t('dashboard.sideMenu.stories'),
    [Templates.Category.ONBOARDING]: t('dashboard.sideMenu.onboarding')
  };

  useEffect(() => {
    if (groupForm.name?.trim().length !== 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [groupForm]);

  const handleChangeName = ({ value }: { value: string }) => {
    if (value) {
      setInvalid(false);
    } else {
      setInvalid(true);
    }
    setGroupForm({ ...groupForm, name: value });
  };

  const handleChangeDesc = (value: string) => {
    setGroupForm({ ...groupForm, description: value });
  };

  const handleCreateTemplate = () => {
    if (isInvalid) {
      return;
    }

    if (template) {
      dispatch(
        fetchUpdateTemplate({
          templateId: template.id,
          description: groupForm.description,
          title: groupForm.name,
          category: groupForm.category,
          image: null
        })
      );
    } else {
      dispatch(
        fetchCreateTemplate({
          description: groupForm.description,
          name: groupForm.name,
          category: groupForm.category,
          image: null
        })
      );
    }

    setGroupForm(initialState);
    onClose();
  };

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle>{t('dashboard.modals.addNewTemplate.title')}</FreshModalTitle>
        <FormGroup>
          <FormLabel invalid={isInvalid} isBlack>
            {t('dashboard.modals.addNewTemplate.templateName')}
          </FormLabel>
          <FreshInput
            fieldName="name"
            invalid={isInvalid}
            placeholder={t('dashboard.modals.addNewTemplate.templateNamePlaceholder')}
            type="text"
            value={groupForm.name}
            onChange={handleChangeName}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel invalid={isInvalid} isBlack>
            {t('dashboard.modals.addNewTemplate.templateType')}
          </FormLabel>
          <FreshDrop
            control={
              <FreshInput
                isDisabled
                rightIcon={IconSelectArrowFresh}
                type="text"
                value={templateCategoriesMap[groupForm.category]}
              />
            }
          >
            <div className={b('drop')}>
              {Object.keys(templateCategoriesMap).map((key) => (
                <div
                  className={b('dropItem')}
                  key={key}
                  onClick={() =>
                    setGroupForm({ ...groupForm, category: key as Templates.Category })
                  }
                >
                  <span>{templateCategoriesMap[key as Templates.Category]}</span>
                </div>
              ))}
            </div>
          </FreshDrop>
        </FormGroup>
        <FormGroup>
          <FormLabel isBlack>{t('dashboard.modals.addNewTemplate.templateDescription')}</FormLabel>
          <FreshTextarea
            placeholder={t('dashboard.modals.addNewTemplate.templateDescriptionPlaceholder')}
            value={groupForm.description}
            onChange={handleChangeDesc}
          />
        </FormGroup>
        <FreshButton
          className={b('btn')}
          disabled={buttonDisabled}
          text={
            template
              ? t('dashboard.modals.addNewTemplate.updateBtn')
              : t('dashboard.modals.addNewTemplate.addBtn')
          }
          onClick={handleCreateTemplate}
        />
      </div>
    </FreshModal>
  );
};
