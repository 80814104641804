import React, { useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { AppBase, AppLimits, LimitsByPlan, fetchSetFreeSubscription } from '@features';
import { AppPlan } from '@features/user/types';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { API } from '@services';
import SimpleBar from 'simplebar-react';
import Skeleton from 'react-loading-skeleton';
import { FreshButton, FreshModal } from '@components/_fresh';
import { DowngradeAppsItem } from './_components';
import './DowngradeModal.scss';

interface DowngradeModalProps {
  isOpen: boolean;
  plan?: {
    plan: AppPlan;
    priceId: string;
  };
  onClose?(): void;
}

export const DowngradeModal: React.FC<DowngradeModalProps> = ({ isOpen, plan, onClose }) => {
  const b = block('DowngradeModal');

  const { t } = useTranslation();
  const planStoriesCountLimit = useMemo(
    () =>
      LimitsByPlan[plan?.plan || (AppPlan.FREE as keyof typeof LimitsByPlan)][
      AppLimits.STORIES_COUNT
      ],
    [plan]
  );

  const [apps, setApps] = useState<AppBase[]>([]);

  const [selectedApp, setSelectedApp] = useState<string>(apps[0]?.id);
  const [isDowngrading, setIsDowngrading] = useState<boolean>(false);

  const currentSubscription = useSelector(
    (store: RootState) => store.subscriptions.currentSubscription
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedApp(apps[0]?.id);
  }, [apps]);

  const fetchData = async () => {
    const { data } = await API.apps.getAppsWithStoriesCount();

    if (data.data && !data.error) {
      const apps: AppBase[] = data.data.map((app: any) => ({
        id: app.app_id,
        title: app.app_title,
        storiesCount: app.stories_count.all_stories_count
      }));

      setApps(apps);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleDowngradeClick = async () => {
    if (
      selectedApp &&
      plan &&
      plan.priceId !== '' &&
      plan.plan &&
      currentSubscription?.subscriptionItemId
    ) {
      setIsDowngrading(true);

      if (plan.plan === AppPlan.FREE) {
        await dispatch(
          fetchSetFreeSubscription({
            appId: selectedApp
          })
        );
      } else if (plan.plan === AppPlan.INDIE) {
        await dispatch(
          fetchSetFreeSubscription({
            appId: selectedApp,
            nextUpgrade: true
          })
        );

        const { data } = await API.subscription.checkoutSession({
          appId: selectedApp,
          priceId: plan.priceId
        });

        if (data?.data.url && !data.error) {
          window.open(data.data.url, '_self');
        }
      }

      setIsDowngrading(false);

      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <FreshModal isOpen={isOpen} size="sm" onClose={onClose}>
      <div className={b()}>
        <div className={b('header')}>
          <p className={b('title')}>{t('dashboard.modals.downgrade.title')}</p>
          <div className={b('descContainer')}>
            <p className={b('desc', { bold: true })}>{t('dashboard.modals.downgrade.desc1')}</p>
            <p className={b('desc')}>{t('dashboard.modals.downgrade.desc2')}</p>
            <p className={b('desc', { bold: true })}>{t('dashboard.modals.downgrade.desc3')}</p>
          </div>
          <div className={b('apps')}>
            {apps.length > 0 ? (
              <SimpleBar style={{ maxHeight: 320 }}>
                {apps.map((app) => (
                  <DowngradeAppsItem
                    app={app}
                    isSelected={selectedApp === app.id}
                    key={app.id}
                    storiesLimit={planStoriesCountLimit}
                    onSelect={setSelectedApp}
                  />
                ))}
              </SimpleBar>
            ) : (
              <>
                {Array.from({ length: 3 }).map((_) => (
                  <Skeleton height={64} width="100%" />
                ))}
              </>
            )}
          </div>

          <FreshButton
            className={b('button')}
            disabled={isDowngrading || !selectedApp}
            text={
              isDowngrading
                ? t('dashboard.modals.downgrade.downgrading')
                : t('dashboard.modals.downgrade.downgradeNow')
            }
            onClick={handleDowngradeClick}
          />
        </div>
      </div>
    </FreshModal>
  );
};
