import React from 'react';
import block from 'bem-cn';
import { IconAlignLeft, IconAlignCenter, IconAlignRight } from '@components/Icon';

import './SettingsTextAlign.scss';

const b = block('SettingsTextAlign');

type ValueType = 'left' | 'center' | 'right';

type PropsType = {
  value: ValueType;
  onChange(value: ValueType): void;
};

export const SettingsTextAlign: React.FC<PropsType> = ({ value, onChange }) => {
  const handleAlignClick = (newValue: ValueType) => {
    onChange(newValue);
  };

  return (
    <div className={b()}>
      <button
        className={b('item', { active: value === 'left' })}
        onClick={() => handleAlignClick('left')}
      >
        <IconAlignLeft />
      </button>
      <button
        className={b('item', { active: value === 'center' })}
        onClick={() => handleAlignClick('center')}
      >
        <IconAlignCenter />
      </button>
      <button
        className={b('item', { active: value === 'right' })}
        onClick={() => handleAlignClick('right')}
      >
        <IconAlignRight />
      </button>
    </div>
  );
};
