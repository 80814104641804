import React, { useEffect, useCallback } from 'react';
import block from 'bem-cn';
import './MessagesContainer.scss';
import { useSelector } from 'react-redux';
import { informSlice } from '@features';
import { nanoid } from 'nanoid';
import { InformMessage } from '@features/inform/components/InformMessage/InformMessage';
import { RootState, useAppDispatch } from '../../../../store';

const b = block('MessagesContainer');

const MAX_NOTIFICATION_COUNT = 3;
const SHOW_TIME_SEC = 3;

export const MessagesContainer: React.FC = () => {
  const storeMessages = useSelector((store: RootState) => store.inform.messages);
  const dispatch = useAppDispatch();

  const closeMessage = useCallback(
    (id: string) => {
      dispatch(informSlice.actions.removeMessage(id));
    },
    [dispatch]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (storeMessages.length) {
      if (storeMessages.length > MAX_NOTIFICATION_COUNT) {
        dispatch(
          informSlice.actions.removeAllButOne(storeMessages[storeMessages.length - 1].id as string)
        );
      } else {
        timeout = setTimeout(() => {
          closeMessage(storeMessages[storeMessages.length - 1].id as string);
        }, SHOW_TIME_SEC * 1000);
      }
    }

    return () => clearTimeout(timeout);
  }, [storeMessages, closeMessage, dispatch]);

  if (!storeMessages.length) {
    return null;
  }

  return (
    <section className={b()}>
      {storeMessages.map((item) => (
        <InformMessage
          action={item.action}
          closeMessage={closeMessage}
          id={item.id || nanoid(6)}
          key={item.id}
          progress={item.progress}
          shareLink={item.shareLink}
          text={item.text}
          type={item.type}
        />
      ))}
    </section>
  );
};
