import React from 'react';
import { FormGroup, FormLabel } from '@components';
import { Input } from '../components/Input/Input';

export const renderField = ({
  input,
  label,
  type,
  placeholder,
  autocomplete,
  meta: { touched, error, warning }
}: any) => (
  <FormGroup>
    <FormLabel active={touched} invalid={touched && error}>
      {label}
    </FormLabel>
    <Input
      autocomplete={autocomplete}
      input={{ ...input, type, placeholder }}
      placeholder={placeholder}
    />
    {touched &&
      ((error && (
        <div
          style={{ fontSize: '12px', lineHeight: '18px', color: '#FF00D0', marginTop: '0.2rem' }}
        >
          {error}
        </div>
      )) ||
        (warning && <div>{warning}</div>))}
    {type === 'password' && (
      <div style={{ fontSize: '12px', lineHeight: '18px', color: '#6c757d' }}>
        Password must contain at least 8 characters
      </div>
    )}
  </FormGroup>
);

export const renderLightField = ({
  input,
  label,
  type,
  placeholder,
  autocomplete,
  meta: { touched, error, warning }
}: any) => (
  <FormGroup>
    <FormLabel active={touched} color="black" invalid={touched && error}>
      {label}
    </FormLabel>
    <Input
      autocomplete={autocomplete}
      input={{ ...input, type }}
      invalid={touched && !!error}
      placeholder={placeholder}
      theme="light"
    />
    {touched &&
      ((error && (
        <span
          style={{
            fontSize: '12px',
            lineHeight: '18px',
            color: '#EE2E2E',
            display: 'block',
            marginTop: '12px'
          }}
        >
          {error}
        </span>
      )) ||
        (warning && <span>{warning}</span>))}
  </FormGroup>
);
