import React from 'react';
import block from 'bem-cn';
import './ControlGroup.scss';
import { Icon } from '@components';

const b = block('ControlGroup');

interface Props {
  isLocked?: boolean;
  sub?: boolean;
  paddingRight?: number;
  onLockChange?(isLocked: boolean): void;
}

export const ControlGroup: React.FC<Props> = (props) => {
  const { children, onLockChange, sub, isLocked, paddingRight } = props;

  const handleLockChange = () => {
    onLockChange && onLockChange(!isLocked);
  };

  return (
    <div
      className={b()}
      style={{
        paddingRight
      }}
    >
      <div className={b('items', { sub })}>{children}</div>
      {!isLocked && onLockChange ? (
        <button className={b('lockBtn')} disabled={!onLockChange} onClick={handleLockChange}>
          <Icon className={b('lockIcon').toString()} name="unlock" />
        </button>
      ) : (
        <>
          {isLocked && (
            <button className={b('lockBtn')} disabled={!onLockChange} onClick={handleLockChange}>
              <Icon className={b('lockIcon').toString()} name="lock" />
            </button>
          )}
        </>
      )}
    </div>
  );
};
