import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { GroupsType, informSlice, MessageTypes, StoriesGroup } from '@features';
import { RootState } from '@store';
import { asyncForEach } from '@utils';
import i18n from '../../i18n/i18n';

interface State {
  groups: StoriesGroup[];
  current: StoriesGroup['id'] | null;
  status: string;
  appId: string | null;
  editGroup?: StoriesGroup;
}

const initialState: State = {
  groups: [],
  current: null,
  status: 'idle',
  appId: null
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<{ groups: StoriesGroup[]; appId: string }>) => {
      state.groups = action.payload.groups;
      const newCurrent = action.payload.groups.find((group) => group.id === state.current);

      if (newCurrent) {
        state.current = newCurrent.id;
      } else {
        state.current = action.payload.groups.length ? action.payload.groups[0].id : null;
      }

      state.appId = action.payload.appId;
      state.status = 'loaded';
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setEditGroup: (state, action: PayloadAction<StoriesGroup | undefined>) => {
      state.editGroup = action.payload;
    },
    create: (state, action: PayloadAction<StoriesGroup>) => {
      state.groups.push(action.payload);

      if (state.groups.length === 1) {
        state.current = action.payload.id;
      }
    },
    select: (state, action: PayloadAction<string>) => {
      state.current = action.payload;
    },
    remove: (state, action: PayloadAction<string>) => {
      const findIndex = state.groups.findIndex(({ id }) => id === action.payload);
      state.groups.splice(findIndex, 1);
    },
    update: (state, action: PayloadAction<StoriesGroup>) => {
      state.groups = state.groups.map((group) =>
        group.id === action.payload.id ? action.payload : group
      );
    }
  }
});

export const fetchCreateGroup = createAsyncThunk(
  'groups/fetchCreateGroup',
  async (
    params: {
      name: string;
      image: Blob | null;
      appId: string;
      startTime: string;
      position?: number;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const languages = state.appManager.locales;
    const currentLocale = state.appManager.currentLocale;

    if (!currentLocale) {
      return null;
    }

    let imageUrl = `${process.env.REACT_APP_SITE_URL}/images/group-default.jpg`;
    let fileId;

    if (params.image) {
      const storageData = await API.storage.create({ file: params.image });

      if (storageData && storageData.data && !storageData.data.error) {
        imageUrl = storageData.data.data.file_url;
        fileId = storageData.data.data.id;
      }
    }

    const { data } = await API.groups.create({
      appId: params.appId,
      image: imageUrl,
      type: GroupsType.GROUP,
      fileId,
      name: params.name,
      startTime: params.startTime,
      languages: languages?.map((item: any) => item.shortName),
      settings: {
        position: params.position ?? undefined,
        autoplayVideos: true
      }
    });

    if (data.data && !data.error) {
      const shareData = {
        appToken: state.appManager.current?.sdkToken,
        groupId: data.data.id
      };

      const shortsData = await API.shorts.create({
        data: shareData
      });

      let settings = { ...data.data.settings };

      if (shortsData.data.data && !shortsData.data.error) {
        settings = { ...data.data.settings, shortDataId: shortsData.data.data.id };
      }

      const groupData = {
        id: data.data.id,
        title: data.data.title[currentLocale],
        image: data.data.image_url[currentLocale],
        fileId: data.data.file_id,
        startDate: data.data.start_time,
        endDate: data.data.end_time,
        type: data.data.type,
        settings,
        active: false,
        groupData: {
          title: data.data.title,
          image: data.data.image
        }
      };

      dispatch(groupsSlice.actions.create(groupData));

      const reqParams = { ...groupData } as any;

      reqParams.title = groupData.groupData.title;
      reqParams.image = groupData.groupData.image;
      reqParams.groupId = groupData.id;
      reqParams.startTime = groupData.startDate;
      reqParams.endTime = groupData.endDate;
      reqParams.appId = params.appId;

      await API.groups.update(reqParams);

      return data.data.id;
    }

    return null;
  }
);

export const fetchDeleteGroup = createAsyncThunk(
  'groups/fetchDeleteGroup',
  async (params: { groupId: string; appId: string }, { dispatch }) => {
    const { data } = await API.groups.remove(params);

    if (data.data && !data.error) {
      dispatch(groupsSlice.actions.remove(params.groupId));
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.groups.deleted')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.groups.deleteError')
        })
      );
    }
  }
);

export const fetchChangeGroupPublishStatus = createAsyncThunk(
  'groups/fetchChangeGroupPublishStatus',
  async (params: { groupId: string; appId: string; active: boolean }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    if (!currentLocale) {
      return null;
    }

    const currentGroup = state.groups.groups.find(
      (item: StoriesGroup) => item.id === params.groupId
    );

    if (currentGroup) {
      const reqParams = { ...currentGroup, active: params.active } as any;

      reqParams.type = GroupsType.GROUP;
      reqParams.title = currentGroup.groupData?.title;
      reqParams.image = currentGroup.groupData?.image;
      reqParams.groupId = currentGroup.id;
      reqParams.startTime = currentGroup.startDate;
      reqParams.endTime = currentGroup.endDate;
      reqParams.appId = params.appId;

      const { data } = await API.groups.update(reqParams);

      if (data.data && !data.error) {
        dispatch(
          groupsSlice.actions.update({
            id: data.data.id,
            title: data.data.title[currentLocale],
            image: data.data.image_url[currentLocale],
            startDate: data.data.start_time,
            endDate: data.data.end_time,
            active: data.data.active,
            type: data.data.type,
            settings: data.data.settings,
            groupData: {
              title: data.data.title,
              image: data.data.image_url
            }
          })
        );
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: i18n.t('notification.groups.updateError')
          })
        );
      }
    }

    return null;
  }
);

export const fetchUpdateGroup = createAsyncThunk(
  'groups/fetchUpdateGroup',
  async (
    params: {
      appId?: string;
      groupId: string;
      title: string;
      active?: boolean;
      image?: Blob | null;
      position?: number;
      isAutoplayVideos?: boolean;
      noNotification?: boolean;
      startTime?: number | string | null;
      endTime?: number | string | null;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;
    const groups = state.groups.groups;
    const currentGroup = groups.find((item: StoriesGroup) => item.id === params.groupId);

    if (!currentLocale || !params.appId) {
      return;
    }

    let storageData;

    if (params.image) {
      storageData = await API.storage.create({ file: params.image });
    }

    if (
      ((storageData && storageData.data && !storageData.data.error) || !params.image) &&
      currentGroup
    ) {
      const reqParams = { ...currentGroup, ...params };
      let image = null;

      reqParams.title = { ...currentGroup.groupData?.title, [currentLocale]: params.title };

      if (params.isAutoplayVideos !== undefined) {
        reqParams.settings = { ...currentGroup.settings, autoplayVideos: params.isAutoplayVideos };
      }

      if (params.position) {
        const duplicatedPositionGroups = groups.filter(
          (group) => group.settings?.position === params.position && group.id !== params.groupId
        );

        if (duplicatedPositionGroups.length) {
          await asyncForEach(duplicatedPositionGroups, async (group: StoriesGroup) => {
            const newSettings = {
              ...group.settings,
              position: currentGroup.settings?.position,
              autoplayVideos: params.isAutoplayVideos ?? group.settings?.autoplayVideos
            };
            const groupTitle = group.groupData?.title;
            const groupImage = group.groupData?.image;

            const { data } = await API.groups.update({
              appId: params.appId,
              groupId: group.id,
              ...group,
              title: groupTitle,
              image: groupImage
            });

            if (data.data && !data.error) {
              dispatch(
                groupsSlice.actions.update({
                  id: group.id,
                  title: group.title,
                  image: group.image,
                  startDate: group.startDate,
                  endDate: group.endDate,
                  active: group.active,
                  type: group.type,
                  settings: newSettings,
                  groupData: group.groupData
                })
              );
            }
          });
        }

        reqParams.settings = { ...currentGroup.settings, position: params.position };
      }

      if (storageData && storageData.data) {
        image = storageData.data.data.file_url;
        reqParams.image = { ...currentGroup.groupData?.image, [currentLocale]: image };
      } else {
        reqParams.image = currentGroup.groupData?.image;
      }

      reqParams.type = GroupsType.GROUP;

      const { data } = await API.groups.update(reqParams);

      if (data.data && !data.error) {
        dispatch(
          groupsSlice.actions.update({
            id: data.data.id,
            title: data.data.title[currentLocale],
            image: data.data.image_url[currentLocale],
            startDate: data.data.start_time,
            endDate: data.data.end_time,
            active: data.data.active,
            type: data.data.type,
            settings: data.data.settings,
            groupData: {
              title: data.data.title,
              image: data.data.image_url
            }
          })
        );
        if (!params.noNotification) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.SUCCESS,
              text: i18n.t('notification.groups.updated')
            })
          );
        }
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: i18n.t('notification.groups.updateError')
          })
        );
      }
    }
  }
);

export const fetchGroups = createAsyncThunk(
  'groups/fetchGroups',
  async (params: { appId: string }, { dispatch, getState }) => {
    dispatch(groupsSlice.actions.setStatus('pending'));

    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    const { data } = await API.groups.getList(params);

    if (data.data && !data.error && currentLocale) {
      const groups = data.data
        .filter((item: any) => item.type === GroupsType.GROUP)
        .map((group: any) => ({
          id: group.id,
          title: group.title[currentLocale],
          image: group.image_url[currentLocale],
          active: group.active,
          startDate: group.start_time,
          endDate: group.end_time,
          type: GroupsType.GROUP,
          settings: group.settings,
          groupData: {
            title: group.title,
            image: group.image_url
          }
        }));

      dispatch(
        groupsSlice.actions.init({
          appId: params.appId,
          groups
        })
      );
    }
  }
);
