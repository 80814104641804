import React from 'react';
import PropTypes from 'prop-types';

const IconArchive = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M15.3448 5.345L12.9882 2.98833C12.6757 2.67583 12.2515 2.5 11.8098 2.5H5.83317C4.91234 2.5 4.1665 3.24583 4.1665 4.16667V15.8333C4.1665 16.7542 4.91234 17.5 5.83317 17.5H14.1665C15.0873 17.5 15.8332 16.7542 15.8332 15.8333V6.52333C15.8332 6.08167 15.6573 5.6575 15.3448 5.345V5.345Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M15.8332 6.66667H12.4998C12.0398 6.66667 11.6665 6.29333 11.6665 5.83333V2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.6665 9.16667H11.6665"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.6665 11.6667H11.6665"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.6665 14.1667H10.2748"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconArchive.propTypes = {
  className: PropTypes.string
};

IconArchive.defaultProps = {
  className: ''
};

export default IconArchive;
