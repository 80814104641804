import React from 'react';
import PropTypes from 'prop-types';

const IconDashboardOnboarding = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.2 1H4.8C4.32268 1 3.86482 1.18964 3.52714 1.52714C3.18964 1.86484 3 2.32269 3 2.79999V20.7999C3 21.2773 3.18964 21.7351 3.52714 22.0728C3.86484 22.4103 4.32269 22.5999 4.8 22.5999H19.2C19.6773 22.5999 20.1351 22.4103 20.4728 22.0728C20.8103 21.7351 21 21.2773 21 20.7999V2.79999C21 2.32268 20.8103 1.86482 20.4728 1.52714C20.1351 1.18964 19.6773 1 19.2 1ZM19.6242 20.5652C19.6242 20.7243 19.6575 20.9757 19.545 21.0882C19.4325 21.2007 19.3591 21.2242 19.2 21.2242H4.8C4.5034 21.2242 4.37579 21.0711 4.37579 20.7999V2.79999C4.37579 2.46872 4.46872 2.40476 4.8 2.40476L19.2 2.37571C19.3591 2.37571 19.4451 2.35718 19.5576 2.46968C19.6242 2.53631 19.6242 2.64299 19.6242 2.8021V20.5652Z" />
    <path d="M12.7977 18.5649C12.7977 19.0066 12.4392 19.3648 11.9975 19.3648C11.5558 19.3648 11.1974 19.0066 11.1974 18.5649C11.1974 18.1229 11.5558 17.7648 11.9975 17.7648C12.4392 17.7648 12.7977 18.1229 12.7977 18.5649Z" />
    <path d="M9.36221 18.5649C9.36221 19.0066 9.00406 19.3648 8.56234 19.3648C8.12036 19.3648 7.76221 19.0066 7.76221 18.5649C7.76221 18.1229 8.12036 17.7648 8.56234 17.7648C9.00407 17.7648 9.36221 18.1229 9.36221 18.5649Z" />
    <path d="M16.2377 18.5649C16.2377 19.0066 15.8795 19.3648 15.4376 19.3648C14.9958 19.3648 14.6377 19.0066 14.6377 18.5649C14.6377 18.1229 14.9958 17.7648 15.4376 17.7648C15.8795 17.7648 16.2377 18.1229 16.2377 18.5649Z" />
    <path d="M0.749871 2.80005C0.335758 2.80005 0 3.06864 0 3.40012V20.1997C0 20.5312 0.335764 20.7998 0.749871 20.7998C1.16424 20.7998 1.5 20.5312 1.5 20.1997V3.40012C1.5 3.24101 1.42089 3.08833 1.28026 2.97583C1.13963 2.86333 0.94877 2.80005 0.749871 2.80005Z" />
    <path d="M23.2501 2.80005C22.8358 2.80005 22.5 3.06864 22.5 3.40012V20.1997C22.5 20.5312 22.8358 20.7998 23.2501 20.7998C23.6642 20.7998 24 20.5312 24 20.1997V3.40012C24 3.24101 23.9211 3.08833 23.7805 2.97583C23.6396 2.86333 23.449 2.80005 23.2501 2.80005H23.2501Z" />
  </svg>
);

IconDashboardOnboarding.propTypes = {
  className: PropTypes.string
};

IconDashboardOnboarding.defaultProps = {
  className: ''
};

export default IconDashboardOnboarding;
