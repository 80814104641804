import React, { memo } from 'react';
import cn from 'classnames';
import './WidgetThemes.scss';
import { WidgetThemesColor } from './WidgetThemesColor';

const CLASS_NAME = 'WidgetThemes';

type PropsType = {
  selectedColor?: string;
  onChange: (color: string) => void;
};

const WIDGET_THEMES_COLORS = [
  'purple',
  'blue',
  'darkBlue',
  'white',
  'green',
  'orange',
  'orangeRed',
  'yellow',
  'black'
];

export const WidgetThemes = memo(({ selectedColor, onChange }: PropsType) => (
  <div className={cn(CLASS_NAME)}>
    <div className={cn(`${CLASS_NAME}__colors`)}>
      {WIDGET_THEMES_COLORS.map((colorName) => (
        <WidgetThemesColor
          color={colorName}
          key={colorName}
          selectedColor={selectedColor}
          onChange={onChange}
        />
      ))}
    </div>
  </div>
));
