import React from 'react';
import PropTypes from 'prop-types';

const IconBack = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 12L5 7L10 2"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);

IconBack.propTypes = {
  className: PropTypes.string
};

IconBack.defaultProps = {
  className: ''
};

export default IconBack;
