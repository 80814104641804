import { useEffect, useMemo } from 'react';

export const useSetBodyOverflow = (isShowingModal: boolean) => {
  const initOverlow = useMemo(() => document.body.style.overflow, []);

  useEffect(() => {
    if (isShowingModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = initOverlow ?? 'auto';
    }
  }, [isShowingModal]);
};
