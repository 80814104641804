import React from 'react';
import PropTypes from 'prop-types';

const IconToken = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16A7 7 0 109 2a7 7 0 000 14z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
    <path
      d="M9 11.1a2.1 2.1 0 100-4.2 2.1 2.1 0 000 4.2z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconToken.propTypes = {
  className: PropTypes.string
};

IconToken.defaultProps = {
  className: ''
};

export default IconToken;
