import React from 'react';
import PropTypes from 'prop-types';

const IconGoogle = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M5.319 14.503l-.835 3.12-3.054.064A11.946 11.946 0 010 12c0-1.99.484-3.867 1.342-5.519l2.719.499 1.19 2.702A7.133 7.133 0 004.868 12c0 .88.16 1.725.452 2.503z"
        fill="#FBBB00"
      />
      <path
        d="M23.79 9.758a12.028 12.028 0 01-.053 4.747 11.998 11.998 0 01-4.225 6.853l-3.424-.175-.485-3.025a7.152 7.152 0 003.077-3.652h-6.416V9.758H23.79z"
        fill="#518EF8"
      />
      <path
        d="M19.512 21.358A11.95 11.95 0 0112 24c-4.57 0-8.543-2.554-10.57-6.313l3.889-3.183a7.135 7.135 0 0010.284 3.654l3.909 3.2z"
        fill="#28B446"
      />
      <path
        d="M19.66 2.763l-3.888 3.182a7.137 7.137 0 00-10.52 3.736l-3.91-3.2A11.998 11.998 0 0112 0a11.95 11.95 0 017.66 2.763z"
        fill="#F14336"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path d="M0 0h24v24H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

IconGoogle.propTypes = {
  className: PropTypes.string
};

IconGoogle.defaultProps = {
  className: ''
};

export default IconGoogle;
