import React from 'react';
import block from 'bem-cn';
import { CustomInput } from 'reactstrap';
import { nanoid } from 'nanoid';
import './FreshSwitchControl.scss';

interface Props {
  className?: string;
  onToggle(): void;
  isChecked: boolean;
  isDisabled?: boolean;
}

export const FreshSwitchControl: React.FunctionComponent<Props> = ({
  className,
  onToggle,
  isChecked,
  isDisabled
}) => {
  const b = block('FreshSwitchControl');

  return (
    <CustomInput
      bsSize="lg"
      checked={isChecked}
      className={`${b('switch')} ${className}`}
      disabled={isDisabled}
      id={`accordion_${nanoid()}`}
      type="switch"
      onChange={onToggle}
    />
  );
};
