export enum CollabaratorRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member'
}

export enum CollabaratorStatus {
  NEW = 'new',
  CONFIRM = 'confirm',
  DECLINE = 'decline',
  REMOVE = 'remove'
}
