import React, { memo, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { SCALE_INDEX } from '@features/consts';
import { BondsType, GroupPositionType } from '@features';
import Moveable, {
  OnChangeTargets,
  OnClickGroup,
  OnDrag,
  OnDragEnd,
  OnDragGroup,
  OnDragGroupEnd,
  OnResize,
  OnResizeEnd,
  OnResizeStart,
  OnRotate,
  OnRotateEnd,
  OnRotateStart,
  OnSnap
} from 'react-moveable';
import { WidgetPositionLimitsType, WidgetPositionType } from '@storysdk/react';
import { StoryEditorAction, useStoryEditorDispatch } from '@modules';
import './StoryEditorMoveable.scss';

const b = block('StoryEditorMoveable');
interface StoryEditorMoveableProps {
  widgetIds: string[];
  groupPosition: GroupPositionType | null;
  isAltPressed?: boolean;
  isShiftPressed?: boolean;
  scaleIndex?: number;
  keepRatio?: boolean;
  topBarHeight?: number;
  bottomBarHeight?: number;
  container: HTMLDivElement | null;
  containerWidth?: number;
  containerHeight?: number;
  moveableRef?: React.MutableRefObject<Moveable | null>;
  positionLimits?: WidgetPositionLimitsType;
  onBoundsChange?(payload: BondsType): void;
  onSnap?(e: OnSnap): void;
  onChangeWidgetPosition(
    widgetId: string,
    payload: Partial<WidgetPositionType>,
    isStopDesignSyncToggle?: boolean
  ): void;
  onChangeGroup(payload: GroupPositionType): void;
  onMove(isMoving: boolean): void;
}

const RENDER_DIRECTIONS_ALL = ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'];
const CENTER_AREA_GAP = 20;

export const StoryEditorMoveable: React.FC<StoryEditorMoveableProps> = memo((props) => {
  const {
    widgetIds,
    groupPosition,
    container,
    keepRatio,
    moveableRef,
    positionLimits,
    containerWidth,
    containerHeight,
    onChangeWidgetPosition,
    onBoundsChange,
    onSnap,
    onChangeGroup,
    onMove
  } = props;

  const [isResizing, setIsResizing] = useState(false);
  const [renderDirections, setRenderDirections] = useState<string[]>(RENDER_DIRECTIONS_ALL);

  const topBarHeight = useMemo(() => props.topBarHeight || 0, [props.topBarHeight]);
  const bottomBarHeight = useMemo(() => props.bottomBarHeight || 0, [props.bottomBarHeight]);

  const storyEditorDispatch = useStoryEditorDispatch();

  const handleDrag = (e: OnDrag, isGroup?: boolean) => {
    if (!isGroup) {
      const rect = e.moveable.getRect();

      const isOnTop = rect.top <= topBarHeight;
      const isOnBottom = containerHeight
        ? rect.top + rect.height >= containerHeight - bottomBarHeight
        : false;

      if (onBoundsChange) {
        onBoundsChange({
          top: isOnTop,
          bottom: isOnBottom
        });
      }
    }

    e.target.style.left = `${e.left}px`;
    e.target.style.top = `${e.top}px`;
  };

  const handleDragGroup = (e: OnDragGroup) => {
    const events = e.events;
    const rect = e.moveable.getRect();

    const isOnTop = rect.top <= topBarHeight;
    const isOnBottom = containerHeight
      ? rect.top + rect.height >= containerHeight - bottomBarHeight
      : false;

    if (onBoundsChange) {
      onBoundsChange({
        top: isOnTop,
        bottom: isOnBottom
      });
    }
    events.forEach((event) => {
      handleDrag(event, true);
    });
  };

  const handleDragStart = () => {
    onMove(true);
  };

  const handleDragGroupStart = () => {
    onMove(true);
  };

  const handleDragEnd = (e: OnDragEnd) => {
    if (onBoundsChange) {
      onBoundsChange({
        top: false,
        bottom: false
      });
    }

    if (e.lastEvent) {
      const rect = e.moveable.getRect();

      onChangeWidgetPosition(e.target.id, {
        x: Math.ceil(e.lastEvent.left),
        y: Math.ceil(e.lastEvent.top),
        origin: {
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
          x: Math.floor(rect.left),
          y: Math.floor(rect.top)
        }
      });
    }

    onMove(false);
  };

  const handleDragGroupEnd = (e: OnDragGroupEnd) => {
    onChangeGroup({
      x: Math.ceil(e.moveable.state.left),
      y: Math.ceil(e.moveable.state.top),
      width: Math.ceil(e.moveable.state.width),
      height: Math.ceil(e.moveable.state.height),
      rotate: 0,
      lastAlign: groupPosition?.lastAlign
    });

    e.events.forEach((widgetEvent: OnDragEnd) => {
      handleDragEnd(widgetEvent);
    });

    onMove(false);
  };

  const handleResize = (e: OnResize) => {
    let width = e.width;
    let height = e.height;

    if (
      positionLimits?.isResizableX &&
      positionLimits.minWidth &&
      width < positionLimits.minWidth
    ) {
      width = positionLimits.minWidth;
    }

    if (
      positionLimits?.isResizableY &&
      positionLimits.minHeight &&
      height < positionLimits.minHeight
    ) {
      height = positionLimits.minHeight;
    }

    if (
      positionLimits?.isResizableX &&
      positionLimits.maxWidth &&
      width > positionLimits.maxWidth
    ) {
      width = positionLimits.maxWidth;
    }

    if (
      positionLimits?.isResizableY &&
      positionLimits.maxHeight &&
      height > positionLimits.maxHeight
    ) {
      height = positionLimits.maxHeight;
    }

    if (positionLimits?.keepRatio && positionLimits.ratioIndex) {
      height = Math.round(width / positionLimits.ratioIndex);
    }

    const newPosition = {
      x: Math.ceil(e.drag.left),
      y: Math.ceil(e.drag.top),
      width: 0,
      height: 0
    };

    if (positionLimits?.isResizableX) {
      newPosition.width = width;
    }

    if (positionLimits?.isResizableY) {
      newPosition.height = height;
    }

    if (newPosition.width && positionLimits?.isResizableX && !positionLimits?.isAutoWidth) {
      e.target.style.width = `${newPosition.width}px`;
    }

    if (newPosition.height && positionLimits?.isResizableY && !positionLimits?.isAutoHeight) {
      e.target.style.height = `${newPosition.height}px`;
    }

    e.target.style.left = `${newPosition.x}px`;
    e.target.style.top = `${newPosition.y}px`;
  };

  const handleResizeStart = (e: OnResizeStart) => {
    setIsResizing(true);
    onMove(true);
  };

  const handleResizeEnd = (e: OnResizeEnd) => {
    setIsResizing(false);
    onMove(false);

    const rect = e.moveable.getRect();
    const target = e.target as any;

    onChangeWidgetPosition(e.target.id, {
      x: Math.ceil(target.offsetLeft),
      y: Math.ceil(target.offsetTop),
      width: target.offsetWidth,
      height: target.offsetHeight,
      origin: {
        width: Math.floor(rect.width),
        height: Math.floor(rect.height),
        x: Math.floor(rect.left),
        y: Math.floor(rect.top)
      }
    });
  };

  const handleRotateStart = (e: OnRotateStart) => {
    onMove(true);
  };

  const handleRotateEnd = (e: OnRotateEnd) => {
    if (e.lastEvent) {
      const rect = e.moveable.getRect();

      onChangeWidgetPosition(e.target.id, {
        rotate: e.lastEvent.rotate,
        origin: {
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
          x: Math.floor(rect.left),
          y: Math.floor(rect.top)
        }
      });
    }

    onMove(false);
  };

  const handleClickGroup = (e: OnClickGroup) => {
    if (e.moveableTarget) {
      storyEditorDispatch?.({
        type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
        payload: [e.moveableTarget.id]
      });
    } else {
      storyEditorDispatch?.({
        type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
        payload: []
      });
    }
  };

  const handleRotate = (e: OnRotate) => {
    e.target.style.transform = e.transform;
  };

  const [targets, setTargets] = useState<any>([]);

  const isGroupMode = targets.length > 1;

  const handleChangeTargets = (e: OnChangeTargets) => {
    if (e.targets.length === 1 && widgetIds[0] === e.targets[0].id) {
      const rect = e.moveable.getRect();

      if (positionLimits?.isAutoWidth) {
        setRenderDirections((prevState) =>
          prevState.filter((item) => item !== 'w' && item !== 'e')
        );
      }

      if (positionLimits?.isAutoHeight) {
        setRenderDirections((prevState) =>
          prevState.filter((item) => item !== 's' && item !== 'n')
        );
      }

      if (rect.width > 0 && rect.height > 0) {
        onChangeWidgetPosition(
          e.targets[0].id,
          {
            realHeight: Math.round(rect.offsetHeight),
            realWidth: Math.round(rect.offsetWidth),
            origin: {
              width: Math.floor(rect.width),
              height: Math.floor(rect.height),
              x: Math.floor(rect.left),
              y: Math.floor(rect.top)
            }
          },
          true
        );
      }
    }
  };

  useEffect(() => {
    setTargets(document.querySelectorAll(widgetIds.map((item) => `#${item}`).join(', ')));
  }, [widgetIds]);

  const handleSnap = (e: OnSnap) => {
    onSnap && onSnap(e);
  };

  return (
    <Moveable
      className={b()}
      draggable
      horizontalGuidelines={containerHeight ? [containerHeight / 2] : []}
      keepRatio={keepRatio || positionLimits?.keepRatio}
      origin={false}
      padding={undefined}
      ref={moveableRef}
      renderDirections={!isGroupMode ? renderDirections : false}
      resizable={!isGroupMode}
      rotatable={!isGroupMode && positionLimits?.isRotatable}
      rotationPosition="top"
      snapContainer={container}
      snapDigit={0}
      snapDirections={{
        middle: true,
        center: true
      }}
      snapGap
      snapThreshold={CENTER_AREA_GAP}
      snappable={!isResizing}
      target={targets}
      useMutationObserver
      useResizeObserver
      verticalGuidelines={containerWidth ? [containerWidth / 2] : []}
      zoom={SCALE_INDEX}
      onChangeTargets={handleChangeTargets}
      onClickGroup={handleClickGroup}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      onDragGroup={handleDragGroup}
      onDragGroupEnd={handleDragGroupEnd}
      onDragGroupStart={handleDragGroupStart}
      onDragStart={handleDragStart}
      onResize={handleResize}
      onResizeEnd={handleResizeEnd}
      onResizeStart={handleResizeStart}
      onRotate={handleRotate}
      onRotateEnd={handleRotateEnd}
      onRotateStart={handleRotateStart}
      onSnap={handleSnap}
    />
  );
});
