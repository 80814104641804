import { StoriesGroupSettings, Story, StoriesGroupAnalytic } from '@features';
import { WidgetsTypes } from '@storysdk/react';

export interface AnalitycsStoriesGroupBase {
  id: string;
  title: {
    [key: string]: string;
  };
  image: string;
}

export interface AnalitycsStoriesGroup extends AnalitycsStoriesGroupBase {
  stories: Story[];
  startDate: string;
  endDate: string;
  active: boolean;
  settings?: StoriesGroupSettings;
  statistic?: StoriesGroupAnalytic;
}

export interface RangeType {
  from: number | null;
  to: number | null;
}

export interface AnalitycsStoriesGroupState {
  data: AnalitycsStoriesGroup | null;
  currentStory: Story | null;
  stories: { [key: string]: Story };
  status: string;
  storyStatus: string;
  range: RangeType;
  storiesLoadingStatuses: { [key: string]: boolean };
  settings?: StoriesGroupSettings;
}

export type OutputAnswerItemType = {
  answer: {
    title: string;
    emoji?: string;
  };
  count?: number;
  percent?: number;
};

export const STAT_WIDGETS_TYPES = [
  WidgetsTypes.SWIPE_UP,
  WidgetsTypes.SLIDER,
  WidgetsTypes.QUESTION,
  WidgetsTypes.CLICK_ME,
  WidgetsTypes.TALK_ABOUT,
  WidgetsTypes.EMOJI_REACTION,
  WidgetsTypes.CHOOSE_ANSWER,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWERS,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE,
  WidgetsTypes.QUIZ_ONE_ANSWER,
  WidgetsTypes.QUIZ_OPEN_ANSWER
];

export const WIDGETS_WITH_BACKGROUND = [
  WidgetsTypes.QUESTION,
  WidgetsTypes.CLICK_ME,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWERS,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE,
  WidgetsTypes.QUIZ_ONE_ANSWER,
  WidgetsTypes.QUIZ_OPEN_ANSWER
];
