import { WidgetObjectType } from '@storysdk/react';
import { InteractiveWidgetsInPlan } from '../consts';

export const getInteractiveWidgetIds = (widgets?: WidgetObjectType[]) => {
  if (!widgets) return [];

  return widgets
    .filter((widget) => InteractiveWidgetsInPlan.find((type) => type === widget.content.type))
    .map((widget) => widget.id);
};
