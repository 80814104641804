import React from 'react';
import PropTypes from 'prop-types';

const IconExtLink = ({ className }) => (
  <svg
    className={className}
    fill="#FD19CC"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M15.6663 15.6666V10.3304C15.6663 9.96387 15.9622 9.66668 16.333 9.66668C16.7012 9.66668 16.9996 9.96321 16.9996 10.3304V16.3363C16.9996 16.7028 16.7038 17 16.333 17H16.3328H16.3326H1.66706H1.66684H1.66661C1.48269 17 1.31605 16.9254 1.19542 16.8049C1.07455 16.6853 1 16.5186 1 16.3334V16.3332V16.3329V1.66743V1.6672V1.66698C1 1.29878 1.29654 1.00036 1.6637 1.00036H7.66962C8.03613 1.00036 8.33332 1.29621 8.33332 1.66698C8.33332 2.03517 8.03679 2.33371 7.66962 2.33371H2.33341V15.6667L15.6663 15.6666ZM15.6663 3.27619L7.09154 11.8509C6.83061 12.1119 6.40975 12.1142 6.14759 11.852C5.88721 11.5917 5.8881 11.1688 6.1487 10.9081L14.7234 2.33335H12.331C11.9639 2.33335 11.6662 2.03739 11.6662 1.66662C11.6662 1.29842 11.9644 1 12.331 1H16.3349C16.5182 1 16.6841 1.07366 16.8043 1.19363C16.9253 1.31584 16.9996 1.4818 16.9996 1.66471V5.66866C16.9996 6.03574 16.7038 6.33339 16.333 6.33339C15.9648 6.33339 15.6663 6.03517 15.6663 5.66866L15.6663 3.27619Z"
      fillRule="evenodd"
    />
  </svg>
);

IconExtLink.propTypes = {
  className: PropTypes.string
};

IconExtLink.defaultProps = {
  className: ''
};

export default IconExtLink;
