import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import './SettingsTextarea.scss';

const b = block('SettingsTextarea');

interface Props {
  isMarginBottom?: boolean;
  className?: string;
  value: string;
  onChange(value: string): void;
}

export const SettingsTextarea: React.FC<Props> = ({
  className,
  isMarginBottom,
  value,
  onChange
}) => {
  const [state, setState] = useState(value);
  useEffect(() => {
    onChange(state);
  }, [state]);

  useEffect(() => {
    if (state !== value) {
      setState(value);
    }
  }, [value]);

  return (
    <textarea
      className={`${b({ marginBottom: isMarginBottom })} ${className ?? ''}`}
      value={state}
      onChange={(e) => setState(e.target.value)}
    />
  );
};
