import React from 'react';
import PropTypes from 'prop-types';

const IconAngle = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="10"
    viewBox="0 0 10 10"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49997 10C9.77611 10 9.99997 9.77614 9.99997 9.5C9.99997 9.22386 9.77611 9 9.49997 9V10ZM0.499969 9.5L0.146416 9.14645C0.00341709 9.28945 -0.0393608 9.5045 0.0380297 9.69134C0.11542 9.87818 0.297738 10 0.499969 10V9.5ZM9.85352 0.853553C10.0488 0.658291 10.0488 0.341709 9.85352 0.146447C9.65826 -0.0488155 9.34168 -0.0488155 9.14642 0.146447L9.85352 0.853553ZM9.49997 9H0.499969V10H9.49997V9ZM0.853523 9.85355L9.85352 0.853553L9.14642 0.146447L0.146416 9.14645L0.853523 9.85355Z"
      fill="#AFAFAF"
    />
  </svg>
);

IconAngle.propTypes = {
  className: PropTypes.string
};

IconAngle.defaultProps = {
  className: ''
};

export default IconAngle;
