import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { SizePresetKeys } from '@features/consts';
import { IconIphoneLarge, IconIphoneSmall } from '@components';
import './StorySizeToogle.scss';

const b = block('StorySizeToogle');

interface StorySizeToogleProps {
  size: SizePresetKeys;
  isDisabled?: boolean;
  onChange(size: SizePresetKeys): void;
}

export const StorySizeToogle: React.FC<StorySizeToogleProps> = ({ size, isDisabled, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <button
        className={b('btn', { active: size === SizePresetKeys.IphoneLarge, disabled: isDisabled })}
        disabled={isDisabled}
        onClick={() => onChange(SizePresetKeys.IphoneLarge)}
      >
        <IconIphoneLarge
          className={b('icon', { white: size === SizePresetKeys.IphoneLarge }).toString()}
        />
        {t('editor.large')}
      </button>
      <button
        className={b('btn', { active: size === SizePresetKeys.IphoneSmall, disabled: isDisabled })}
        disabled={isDisabled}
        onClick={() => onChange(SizePresetKeys.IphoneSmall)}
      >
        <IconIphoneSmall
          className={b('icon', { white: size === SizePresetKeys.IphoneSmall }).toString()}
        />
        {t('editor.small')}
      </button>
      <div className={b('background', { right: size === SizePresetKeys.IphoneSmall })} />
    </div>
  );
};
