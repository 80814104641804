import React from 'react';
import PropTypes from 'prop-types';

const IconStoryPreviewNoMockup = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="100"
    viewBox="0 0 50 100"
    width="50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#FD19CC" height="100" rx="2" width="50" />
    <rect fill="white" height="1" rx="0.5" width="14" x="2" y="2" />
    <rect fill="white" height="1" opacity="0.5" rx="0.5" width="14" x="17.9734" y="2" />
    <rect fill="white" height="1" opacity="0.5" rx="0.5" width="14" x="33.9465" y="2" />
  </svg>
);

IconStoryPreviewNoMockup.propTypes = {
  className: PropTypes.string
};

IconStoryPreviewNoMockup.defaultProps = {
  className: ''
};

export default IconStoryPreviewNoMockup;
