import React from 'react';
import PropTypes from 'prop-types';

const IconEdit = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.4442 3.77068L11.5583 4.65661L9.34342 2.43904L10.2294 1.5531C10.4098 1.37263 10.7243 1.37263 10.9047 1.5531L12.4442 3.09529C12.6301 3.28122 12.6301 3.58474 12.4442 3.77068ZM5.04224 11.1808L2.8274 8.96325L8.55318 3.22927L10.768 5.44685L5.04224 11.1808ZM2.31607 10.0324L3.9731 11.6922L1.66529 12.3429L2.31607 10.0324ZM13.2344 2.30505L11.695 0.762864C11.3942 0.462083 10.3087 -0.106667 9.44185 0.762864L1.64068 8.5695C1.57232 8.63786 1.5231 8.7199 1.49849 8.81286L0.322713 12.9992C0.268026 13.1933 0.325447 13.4011 0.464901 13.5461C0.607088 13.691 0.891463 13.7156 1.01178 13.6883L5.19537 12.5097C5.28834 12.4851 5.37037 12.4359 5.43873 12.3676L13.2344 4.56091C13.8551 3.94021 13.8551 2.92849 13.2344 2.30505Z" />
  </svg>
);

IconEdit.propTypes = {
  className: PropTypes.string
};

IconEdit.defaultProps = {
  className: ''
};

export default IconEdit;
