import React from 'react';
import block from 'bem-cn';
import './ButtonsGroup.scss';

const b = block('ButtonsGroup');

export type ButtonsGroupItemType = {
  id: string;
  title: string;
};

interface Props {
  items: Array<ButtonsGroupItemType>;
  current: ButtonsGroupItemType['id'];
  onChange(id: ButtonsGroupItemType['id']): void;
}

export const ButtonsGroup: React.FunctionComponent<Props> = (props) => {
  const { items, current, onChange } = props;

  const handleClick = (id: ButtonsGroupItemType['id']) => () => {
    onChange(id);
  };

  return (
    <div className={b()}>
      {items.map((btn) => (
        <button
          className={b('btn', { current: current === btn.id })}
          key={btn.id}
          onClick={handleClick(btn.id)}
        >
          {btn.title}
        </button>
      ))}
    </div>
  );
};
