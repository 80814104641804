import React, { FC } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { IconStoryPreviewNoMockup, IconStoryPreviewWithMockup } from '@components';

import './MockupViewContainer.scss';

const b = block('MockupViewContainer');

type MockupViewContainerProps = {
  isActive?: boolean;
  isShowMockup: boolean;
  handleSelect: () => void;
};

export const MockupViewContainer: FC<MockupViewContainerProps> = ({
  isShowMockup,
  isActive,
  handleSelect
}) => {
  const { t } = useTranslation();

  return (
    <div className={b('wrapper')}>
      <div
        className={b({ active: isActive })}
        role="button"
        tabIndex={0}
        onClick={handleSelect}
        onKeyDown={handleSelect}
      >
        {isShowMockup ? <IconStoryPreviewWithMockup /> : <IconStoryPreviewNoMockup />}
      </div>

      <p className={b('title', { active: isActive })}>
        {isShowMockup
          ? t('dashboard.apps.styles.withPhone')
          : t('dashboard.apps.styles.withoutPhone')}
      </p>
    </div>
  );
};
