import React from 'react';
import PropTypes from 'prop-types';

const IconCalendar = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 3H3.75a1.5 1.5 0 00-1.5 1.5V15a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V4.5a1.5 1.5 0 00-1.5-1.5zM12 1.5v3M6 1.5v3M2.25 7.5h13.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconCalendar.propTypes = {
  className: PropTypes.string
};

IconCalendar.defaultProps = {
  className: ''
};

export default IconCalendar;
