import React, { useState } from 'react';
import block from 'bem-cn';
import { Icon } from '@components';
import './SettingsCollapse.scss';

const b = block('SettingsCollapse');

interface SettingsCollapseProps {
  title: string;
  children: React.ReactNode;
  isOpenByDefault?: boolean;
  subtitle?: string;
}

export const SettingsCollapse: React.FC<SettingsCollapseProps> = ({
  title,
  children,
  subtitle,
  isOpenByDefault
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  return (
    <div className={b()}>
      <div className={b('titleContainer', { open: isOpen })}>
        <div className={b('subtitleContainer')}>
          <p className={b('title')}>{title}</p>
          {subtitle && <div className={b('subtitle')}>{subtitle}</div>}
        </div>

        <button className={b('arrow')} onClick={() => setIsOpen(!isOpen)}>
          <Icon
            className={b('chevron', { open: isOpen }).toString()}
            name="chevron-down-fresh"
            variant="icons"
          />
        </button>
      </div>
      {isOpen && <div className={b('content')}>{children}</div>}
    </div>
  );
};
