import React from 'react';
import { StartStep } from '@modules/DashboardModule/types';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import './StartStepsHeader.scss';
import {
  IconGroups,
  IconInfoSuccess,
  IconIphoneSmallBlack,
  IconSeparatorStart,
  IconStartStories
} from '@components';

interface StartStepsHeaderProps {
  currentStep: StartStep;
  completedSteps?: StartStep[];
}

const b = block('StartStepsHeader');

export const StartStepsHeader: React.FC<StartStepsHeaderProps> = ({
  currentStep,
  completedSteps
}) => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('step', { active: currentStep === StartStep.APP })}>
        {completedSteps?.includes(StartStep.APP) ? (
          <IconInfoSuccess
            className={b('stepIcon', {
              completed: true
            }).toString()}
          />
        ) : (
          <IconIphoneSmallBlack
            className={b('stepIcon', {
              filled: true,
              active: currentStep === StartStep.APP
            }).toString()}
          />
        )}

        <div
          className={b('stepTitle', {
            active: currentStep === StartStep.APP,
            pink: completedSteps?.includes(StartStep.APP)
          }).toString()}
        >
          {t('dashboard.start.addNewApp')}
        </div>
        <IconSeparatorStart
          className={b('separatorIcon', {
            filled: currentStep === StartStep.APP,
            pink: completedSteps?.includes(StartStep.APP)
          }).toString()}
        />
      </div>
      <div className={b('step', { active: currentStep === StartStep.GROUP })}>
        {completedSteps?.includes(StartStep.GROUP) ? (
          <IconInfoSuccess
            className={b('stepIcon', {
              completed: true
            }).toString()}
          />
        ) : (
          <IconGroups
            className={b('stepIcon').toString()}
            color={currentStep === StartStep.GROUP ? '#18182E' : undefined}
          />
        )}

        <div
          className={b('stepTitle', {
            active: currentStep === StartStep.GROUP,
            pink: completedSteps?.includes(StartStep.GROUP)
          }).toString()}
        >
          {t('dashboard.start.addGroup')}
        </div>
        <IconSeparatorStart
          className={b('separatorIcon', {
            filled: currentStep === StartStep.GROUP,
            pink: completedSteps?.includes(StartStep.GROUP)
          }).toString()}
        />
      </div>
      <div className={b('step', { active: currentStep === StartStep.STORIES })}>
        {completedSteps?.includes(StartStep.STORIES) ? (
          <IconInfoSuccess
            className={b('stepIcon', {
              completed: true
            })}
          />
        ) : (
          <IconStartStories
            className={b('stepIcon', {
              stroked: true,
              active: currentStep === StartStep.STORIES
            }).toString()}
          />
        )}

        <div
          className={b('stepTitle', {
            active: currentStep === StartStep.STORIES,
            pink: completedSteps?.includes(StartStep.STORIES)
          })}
        >
          {t('dashboard.start.addStories')}
        </div>
      </div>
    </div>
  );
};
