import React, { useState, useRef } from 'react';
import block from 'bem-cn';
import { Cropper } from 'react-cropper';
import { Modal, ModalTitle } from '@components';
import { Button } from '@custom';
import 'cropperjs/dist/cropper.css';

import './CropImageModal.scss';

export interface CropProps {
  dataUrl: string | null;
  blob: Blob | null;
}

interface Props {
  isOpen: boolean;
  onClose(): void;
  onCrop(params: CropProps): void;
  image: string | ArrayBuffer | null;
  aspectRatio: number;
  height: number;
  width: number;
}

const b = block('CropImageModal');

export const CropImageModal: React.FunctionComponent<Props> = (props) => {
  const { isOpen, onClose, onCrop, image, aspectRatio } = props;
  const [buttonDisabled] = useState(false);

  const cropperRef = useRef<HTMLImageElement>(null);

  const handleCropImage = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    const croppedImage = cropper.getCroppedCanvas({
      width: props.width,
      height: props.height
    });

    const dataUrl = croppedImage.toDataURL();

    croppedImage.toBlob((blob: Blob) => {
      onCrop({ dataUrl, blob });
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <ModalTitle centered>Crop image</ModalTitle>
        <Cropper
          aspectRatio={aspectRatio}
          background={false}
          className={b('cropContainer')}
          guides={false}
          minCropBoxHeight={50}
          minCropBoxWidth={50}
          ref={cropperRef}
          src={image as string}
          style={{ height: 300, width: '100%' }}
          viewMode={1}
        />
        <Button block disabled={buttonDisabled} text="Crop image" onClick={handleCropImage} />
      </div>
    </Modal>
  );
};
