import { SizePresetKeys } from '@features/consts';
import { ScoreType } from '@features/stories';

export interface StoriesGroupAnalytic {
  click: number;
  completionRate: number;
  duration: number;
  finish: number;
  impression: number;
  open: number;
  start: number;
  timeOfCompletion: number;
}

export interface StoriesGroupBase {
  id: string;
  title: string;
  image: string;
  fileId?: string;
  startDate?: string;
  endDate?: string;
  statistic?: StoriesGroupAnalytic;
  groupData?: {
    [key: string]: any;
  };
  type: GroupsType;
}

export interface StoriesGroupSettings {
  isProgressHidden?: boolean;
  isProhibitToClose?: boolean;
  addToStories?: boolean;
  autoplayVideos?: boolean;
  scoreType?: ScoreType;
  scoreResultLayersGroupId?: string;
  lastStoryPosition?: number;
  shortDataId?: string;
  sizePreset?: SizePresetKeys;
  position?: number;
}

export interface StoriesGroup extends StoriesGroupBase {
  startDate: string;
  endDate: string;
  active: boolean;
  settings?: StoriesGroupSettings;
}

export interface StoriesGroupState {
  data: StoriesGroup | null;
  status: string;
}

export interface CropProps {
  dataUrl: string | null;
  blob: Blob | null;
}

export enum GroupsType {
  GROUP = 'group',
  ONBOARDING = 'onboarding',
  TEMPLATE = 'template'
}

export type SizePreset = {
  title: SizePresetKeys;
  width: number;
  height: number;
  isDefault?: boolean;
};

export type SizePresets = {
  [key: string]: SizePreset;
};
