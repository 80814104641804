import { WidgetPositionLimitsType, WidgetsTypes } from '@storysdk/react';
import { SettingsAnswersType } from './types';

export const getWidgetPositionLimits = (type: WidgetsTypes): WidgetPositionLimitsType => {
  switch (type) {
    case WidgetsTypes.CHOOSE_ANSWER: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 100,
        isAutoHeight: true,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true
      };
    }
    case WidgetsTypes.CLICK_ME: {
      return {
        isResizableX: true,
        isResizableY: true,
        isRotatable: true
      };
    }
    case WidgetsTypes.LINK: {
      return {
        isResizableX: true,
        isResizableY: true,
        isRotatable: true
      };
    }
    case WidgetsTypes.ELLIPSE: {
      return {
        isResizableX: true,
        isResizableY: true,
        isRotatable: true
      };
    }
    case WidgetsTypes.EMOJI_REACTION: {
      return {
        minHeight: 70,
        minWidth: 70,
        maxHeight: 130,
        isAutoWidth: true,
        isResizableX: false,
        isResizableY: true,
        isRotatable: true
      };
    }
    case WidgetsTypes.QUESTION: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 90,
        maxHeight: 169,
        ratioIndex: 2.18,
        keepRatio: true,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true,
        isAutoHeight: true
      };
    }
    case WidgetsTypes.RECTANGLE: {
      return {
        isResizableX: true,
        isResizableY: true,
        isRotatable: true
      };
    }
    case WidgetsTypes.SLIDER: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 100,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true,
        isAutoHeight: true
      };
    }
    case WidgetsTypes.IMAGE: {
      return {
        minWidth: 10,
        minHeight: 10,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true,
        isAutoHeight: true
      };
    }
    case WidgetsTypes.VIDEO: {
      return {
        minWidth: 10,
        minHeight: 10,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true,
        isAutoHeight: true
      };
    }
    case WidgetsTypes.SWIPE_UP: {
      return {
        minWidth: 100,
        maxWidth: 360,
        isResizableX: true,
        isResizableY: true,
        isAutoHeight: true,
        isRotatable: true
      };
    }
    case WidgetsTypes.TALK_ABOUT: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 127,
        maxHeight: 240,
        ratioIndex: 1.54,
        isAutoHeight: true,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true
      };
    }
    case WidgetsTypes.TEXT: {
      return {
        isResizableX: true,
        isResizableY: false,
        isAutoHeight: true,
        isRotatable: true,
        keepRatio: false
      };
    }
    case WidgetsTypes.TIMER: {
      return {
        minWidth: 196,
        minHeight: 100,
        maxHeight: 189,
        maxWidth: 360,
        keepRatio: true,
        ratioIndex: 1.96,
        isAutoHeight: true,
        isResizableX: true,
        isResizableY: true,
        isRotatable: true
      };
    }
    case WidgetsTypes.GIPHY: {
      return {
        minWidth: 20,
        minHeight: 20,
        isResizableX: true,
        isResizableY: true,
        isRotatable: true
      };
    }
    case WidgetsTypes.QUIZ_ONE_ANSWER: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 100,
        isAutoHeight: true,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true
      };
    }
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 100,
        isAutoHeight: true,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true
      };
    }
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 100,
        isAutoHeight: true,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true
      };
    }
    case WidgetsTypes.QUIZ_RATE: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 100,
        isAutoHeight: true,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true
      };
    }
    case WidgetsTypes.QUIZ_OPEN_ANSWER: {
      return {
        minWidth: 196,
        maxWidth: 360,
        minHeight: 100,
        isAutoHeight: true,
        isResizableX: true,
        isResizableY: false,
        isRotatable: true
      };
    }
    default: {
      return {
        isResizableX: false,
        isResizableY: false,
        isRotatable: false
      };
    }
  }
};

export const WidgetInitSize = {
  [WidgetsTypes.CHOOSE_ANSWER]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.CLICK_ME]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.LINK]: {
    width: 100,
    height: 'auto'
  },
  [WidgetsTypes.ELLIPSE]: {
    width: 90,
    height: 27
  },
  [WidgetsTypes.EMOJI_REACTION]: {
    width: 'auto',
    maxWidth: '100%',
    height: 70
  },
  [WidgetsTypes.QUESTION]: {
    width: 196,
    height: 90
  },
  [WidgetsTypes.RECTANGLE]: {
    width: 90,
    height: 27
  },
  [WidgetsTypes.SLIDER]: {
    width: 196,
    height: 100
  },
  [WidgetsTypes.SWIPE_UP]: {
    width: 100,
    height: 'auto'
  },
  [WidgetsTypes.TALK_ABOUT]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.TEXT]: {
    width: 90,
    height: 'auto'
  },
  [WidgetsTypes.TIMER]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.GIPHY]: {
    width: 100,
    height: 100
  },
  [WidgetsTypes.QUIZ_ONE_ANSWER]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWERS]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.QUIZ_RATE]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.QUIZ_OPEN_ANSWER]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.IMAGE]: {
    width: 196,
    height: 'auto'
  },
  [WidgetsTypes.VIDEO]: {
    width: 196,
    height: 'auto'
  }
};

export const WidgetsToImage = [
  WidgetsTypes.CLICK_ME,
  WidgetsTypes.SWIPE_UP,
  WidgetsTypes.ELLIPSE,
  WidgetsTypes.RECTANGLE,
  WidgetsTypes.TEXT,
  WidgetsTypes.TALK_ABOUT,
  WidgetsTypes.SLIDER,
  WidgetsTypes.QUESTION,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWERS,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE,
  WidgetsTypes.QUIZ_ONE_ANSWER,
  WidgetsTypes.QUIZ_OPEN_ANSWER,
  WidgetsTypes.QUIZ_RATE
];

export const InteractiveWidgets = [
  WidgetsTypes.CHOOSE_ANSWER,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWERS,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE,
  WidgetsTypes.QUIZ_ONE_ANSWER
];

export const AllInteractiveWidgets = [
  WidgetsTypes.CLICK_ME,
  WidgetsTypes.SWIPE_UP,
  WidgetsTypes.CHOOSE_ANSWER,
  WidgetsTypes.TALK_ABOUT,
  WidgetsTypes.EMOJI_REACTION,
  WidgetsTypes.SLIDER,
  WidgetsTypes.QUESTION,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWERS,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE,
  WidgetsTypes.QUIZ_ONE_ANSWER,
  WidgetsTypes.QUIZ_OPEN_ANSWER
];

export const InteractiveWidgetsInPlan = [
  WidgetsTypes.CHOOSE_ANSWER,
  WidgetsTypes.TALK_ABOUT,
  WidgetsTypes.EMOJI_REACTION,
  WidgetsTypes.SLIDER,
  WidgetsTypes.QUESTION,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWERS,
  WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE,
  WidgetsTypes.QUIZ_ONE_ANSWER,
  WidgetsTypes.QUIZ_OPEN_ANSWER
];

export const AnswerTypesMap = {
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWERS]: SettingsAnswersType.EMOJI,
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE]: SettingsAnswersType.IMAGE,
  [WidgetsTypes.QUIZ_ONE_ANSWER]: SettingsAnswersType.EMOJI,
  [WidgetsTypes.CHOOSE_ANSWER]: SettingsAnswersType.ID
};
