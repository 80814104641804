import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  SignInPage,
  SignUpPage,
  NewPasswordPage,
  ResetPasswordPage,
  ConfirmEmailPage,
  ConfirmEmailRedirectPage,
  ReturnToFigmaPage,
  JoinTheTeamRedirectPage,
  GoogleAuthRedirectPage,
  MicrosoftAuthRedirectPage
} from './pages';

export const AuthModule: React.FunctionComponent = () => (
  <Switch>
    <Route component={SignInPage} exact path="/" />
    <Route component={SignUpPage} path="/signup" />
    <Route component={ReturnToFigmaPage} exact path="/returnToFigma" />
    <Route component={ResetPasswordPage} path="/auth/reset-password" />
    <Route component={NewPasswordPage} path="/auth/new-password" />
    <Route component={GoogleAuthRedirectPage} path="/auth/google_callback" />
    <Route component={MicrosoftAuthRedirectPage} path="/auth/microsoft_callback" />
    <Route component={ConfirmEmailRedirectPage} exact path="/confirm/:token" />
    <Route component={JoinTheTeamRedirectPage} exact path="/team/:appId/join/:id" />
    <Route component={ConfirmEmailPage} path="/confirm" />
  </Switch>
);
