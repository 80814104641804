import { DateTime } from 'luxon';

export const getAllDaysInMonth = (getDateInSeconds: number) => {
  const dateTime = DateTime.fromSeconds(getDateInSeconds);
  const getMonth = dateTime.month;

  let newDateTime = DateTime.fromSeconds(getDateInSeconds).startOf('month');

  const allDaysInMonth = [];

  while (newDateTime.month === getMonth) {
    const newDate = DateTime.fromSeconds(newDateTime.toSeconds());
    allDaysInMonth.push(newDate);

    newDateTime = newDateTime.plus({ days: 1 });
  }
  return allDaysInMonth;
};
