import React from 'react';
import block from 'bem-cn';
import { FormGroup, FormLabel } from '@components';
import './FreshActionOutput.scss';

const b = block('FreshActionOutput');

interface Props {
  value: string;
  descriptionEl?: React.ReactNode;
  label?: string;
  theme?: 'light' | 'dark' | 'transparent';
  className?: string;
  actionText?: string;
  action?: () => void;
}

export const FreshActionOutput: React.FC<Props> = ({
  value,
  label,
  theme = 'light',
  descriptionEl,
  className,
  actionText,
  action
}) => (
  <FormGroup className={className}>
    {label && (
      <FormLabel color={theme === 'light' || theme === 'transparent' ? 'black' : 'white'} isBlack>
        {label}
      </FormLabel>
    )}

    <div className={b({ transparent: theme === 'transparent' })}>
      <div className={b('valueContainer')}>
        <p className={b('value')}>{value}</p>
        {descriptionEl && <div className={b('description')}>{descriptionEl}</div>}
      </div>
      {actionText && action && (
        <button className={b('action')} onClick={action}>
          {actionText}
        </button>
      )}
    </div>
  </FormGroup>
);
