import { StoryPreview, Story, STORY_WIDTH } from '@features';
import React from 'react';
import block from 'bem-cn';
import { Icon } from '@components';

import './SettingsStoriesPreview.scss';

const b = block('SettingsStoriesPreview');

interface SettingsStoriesPreviewProps {
  stories: Story[];
  currentStoryId?: string;
  onClick(storyId: string): void;
  onCreateStory(): void;
}

const CARD_WIDTH = 43;

export const SettingsStoriesPreview: React.FC<SettingsStoriesPreviewProps> = ({
  stories,
  currentStoryId,
  onClick,
  onCreateStory
}) => {
  const scaleIndex = CARD_WIDTH / STORY_WIDTH;

  return (
    <div className={b('stories')}>
      <div className={b('storiesContainer')}>
        {stories.map((story) => (
          <div
            className={b('card', {
              current: currentStoryId === story.id
            })}
            key={`preview-${story.id}`}
            onClick={() => onClick(story.id)}
          >
            <StoryPreview className={b('story')} scaleIndex={scaleIndex} story={story} />
          </div>
        ))}

        {stories.length === 0 && (
          <div className={b('card', { add: true })} onClick={onCreateStory}>
            <div className={b('circle')}>
              <Icon className={b('plus-icon').toString()} name="add" variant="editor" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
