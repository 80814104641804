import React from 'react';
import { ColorPicker, SettingsBox } from '@modules';
import {
  ControlGroup,
  MAX_ANGLE_VALUE,
  MIN_ANGLE_VALUE,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsInput,
  SettingsSlider
} from '@features/stories/editorSettings/components';
import { useTranslation } from 'react-i18next';
import {
  ImageWidgetParamsType,
  MediaType,
  WidgetPositionType,
  WidgetsTypes
} from '@storysdk/react';
import { useParams } from 'react-router-dom';
import {
  BackgroundBlob,
  GroupsType,
  MessageTypes,
  fetchUpdateStoryWidgetBackground,
  fetchUpdateTemplateStoryWidgetBackground,
  informSlice,
  onUploadProgress
} from '@features';
import { nanoid } from 'nanoid';
import { useAppDispatch } from '@store';
import { Icon } from '@components';

type ImageWidgetProps = {
  id: string;
  position: WidgetPositionType;
  params: ImageWidgetParamsType;
  onChange(params: ImageWidgetParamsType): void;
  onChangePosition(params: WidgetPositionType, stopSave?: boolean): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
};

export const ImageWidgetSettings: React.FC<ImageWidgetProps> = (props) => {
  const { type } = useParams<{
    type: GroupsType;
  }>();

  const { id, params, position, onChange, onChangeAlign, onChangePosition } = props;

  const dispatch = useAppDispatch();

  const handleChange = (field: string) => (value: any) => {
    onChange({
      ...params,
      [field]: value
    });
  };
  const { t } = useTranslation();

  const handleChangePosition = (field: string) => (value: any) => {
    onChangePosition(
      {
        ...position,
        [field]: value
      },
      field !== 'width' && field !== 'height'
    );
  };

  const handleChangeImage = (imageParams: { type: MediaType; value: Blob }) => {
    const newModalId = nanoid(6);

    dispatch(
      informSlice.actions.addMessage({
        id: newModalId,
        type: MessageTypes.PROGRESS,
        // @ts-ignore
        text: imageParams.value.name
      })
    );

    const widgetParams = {
      widgetId: id,
      widgetType: WidgetsTypes.IMAGE,
      background: { type: MediaType.IMAGE, value: imageParams.value } as BackgroundBlob,
      onUploadProgress: onUploadProgress(dispatch),
      modalProgressId: newModalId,
      mediaType: MediaType.IMAGE
    };

    if (type === GroupsType.TEMPLATE) {
      dispatch(fetchUpdateTemplateStoryWidgetBackground(widgetParams));
    } else {
      dispatch(fetchUpdateStoryWidgetBackground(widgetParams));
    }
  };

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked={position.isHeightLocked}>
            <SettingsInput
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              postfix="px"
              prefix="H"
              type="number"
              value={position.realHeight}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <SettingsSlider
            title={t('editor.opacity')}
            value={params.widgetOpacity}
            onChange={handleChange('widgetOpacity')}
          />
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
          <SettingsInput
            icon={<Icon name="border-radius" />}
            postfix="px"
            type="number"
            value={params.borderRadius}
            onChange={handleChange('borderRadius')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.image')}>
        <SettingsBox.Field>
          <ColorPicker
            availableTypes={[MediaType.IMAGE]}
            value={{
              type: MediaType.IMAGE,
              value: params.imageUrl,
              fileId: params.fileId
            }}
            onChange={handleChangeImage}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>
    </>
  );
};
