import React from 'react';
import PropTypes from 'prop-types';

const IconStartOnboarding = ({ className }) => (
  <svg
    className={className}
    fill="#A49BA4"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.8679 1H5.46796C4.99065 1 4.53279 1.18964 4.19511 1.52714C3.85761 1.86484 3.66797 2.32269 3.66797 2.79999V20.7999C3.66797 21.2773 3.85761 21.7351 4.19511 22.0728C4.53281 22.4103 4.99066 22.5999 5.46796 22.5999H19.8679C20.3452 22.5999 20.8031 22.4103 21.1408 22.0728C21.4783 21.7351 21.6679 21.2773 21.6679 20.7999V2.79999C21.6679 2.32268 21.4783 1.86482 21.1408 1.52714C20.8031 1.18964 20.3452 1 19.8679 1ZM20.2922 20.5652C20.2922 20.7243 20.3255 20.9757 20.213 21.0882C20.1005 21.2007 20.027 21.2242 19.8679 21.2242H5.46796C5.17137 21.2242 5.04376 21.0711 5.04376 20.7999V2.79999C5.04376 2.46872 5.13669 2.40476 5.46796 2.40476L19.8679 2.37571C20.027 2.37571 20.1131 2.35718 20.2256 2.46968C20.2922 2.53631 20.2922 2.64299 20.2922 2.8021V20.5652Z" />
    <path d="M13.4655 18.5648C13.4655 19.0065 13.1071 19.3646 12.6654 19.3646C12.2237 19.3646 11.8652 19.0065 11.8652 18.5648C11.8652 18.1228 12.2237 17.7646 12.6654 17.7646C13.1071 17.7646 13.4655 18.1228 13.4655 18.5648Z" />
    <path d="M10.0302 18.5648C10.0302 19.0065 9.67203 19.3646 9.23031 19.3646C8.78832 19.3646 8.43018 19.0065 8.43018 18.5648C8.43018 18.1228 8.78832 17.7646 9.23031 17.7646C9.67203 17.7646 10.0302 18.1228 10.0302 18.5648Z" />
    <path d="M16.9057 18.5648C16.9057 19.0065 16.5475 19.3646 16.1055 19.3646C15.6638 19.3646 15.3057 19.0065 15.3057 18.5648C15.3057 18.1228 15.6638 17.7646 16.1055 17.7646C16.5475 17.7646 16.9057 18.1228 16.9057 18.5648Z" />
    <path d="M1.41784 2.80005C1.00373 2.80005 0.667969 3.06864 0.667969 3.40012V20.1997C0.667969 20.5312 1.00373 20.7998 1.41784 20.7998C1.8322 20.7998 2.16797 20.5312 2.16797 20.1997V3.40012C2.16797 3.24101 2.08886 3.08833 1.94823 2.97583C1.80759 2.86333 1.61674 2.80005 1.41784 2.80005Z" />
    <path d="M23.9181 2.80005C23.5037 2.80005 23.168 3.06864 23.168 3.40012V20.1997C23.168 20.5312 23.5037 20.7998 23.9181 20.7998C24.3322 20.7998 24.668 20.5312 24.668 20.1997V3.40012C24.668 3.24101 24.5891 3.08833 24.4485 2.97583C24.3076 2.86333 24.117 2.80005 23.9181 2.80005H23.9181Z" />
  </svg>
);

IconStartOnboarding.propTypes = {
  className: PropTypes.string
};

IconStartOnboarding.defaultProps = {
  className: ''
};

export default IconStartOnboarding;
