import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchUpdateApp } from '@features';
import { AppGroupStyle } from '@features/appsManagerSlice/types/AppState';
import { IconSaveFresh, FilterControlGroup } from '@components';
import { FreshButton, FreshSwitchControl } from '@components/_fresh';
import { RootState, useAppDispatch } from '../../../../../../store';
import { GroupViewContainer, MockupViewContainer } from './_components';

import './StyleTab.scss';

type groupViewType = AppGroupStyle | string;

interface FormProps {
  ios: groupViewType;
  android: groupViewType;
  react: groupViewType;
  web: groupViewType;
}

const b = block('StyleTab');
const s = block('GroupSettingsTab');

const groupViewData = [
  AppGroupStyle.CIRCLE,
  AppGroupStyle.SQUARE,
  AppGroupStyle.BIG_SQUARE,
  AppGroupStyle.RECTANGLE
];

type PlatformType = keyof FormProps;

export const StyleTab: React.FC = () => {
  const initialFormData = {
    ios: AppGroupStyle.CIRCLE,
    android: AppGroupStyle.CIRCLE,
    react: AppGroupStyle.CIRCLE,
    web: AppGroupStyle.CIRCLE
  };

  const appState = useSelector((state: RootState) => state);
  const app = appState.appManager;

  const [isApplyForAll, setIsApplyForAll] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormProps>(initialFormData);
  const [isShowMockup, setIsShowMockup] = useState<boolean>(false);

  const [subTab, setSubTab] = useState<PlatformType>('web');

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (app.current) {
      const newGroupView = {
        ...formData
      };

      if (isApplyForAll) {
        const currentValue = formData[subTab];

        Object.keys(newGroupView).forEach((key) => {
          newGroupView[key as PlatformType] = currentValue;
        });
      }

      dispatch(
        fetchUpdateApp({
          id: app.current.id,
          settings: {
            ...app.current.settings,
            groupView: newGroupView,
            isShowMockup
          }
        })
      );
    }
  };

  const handleChange = ({ fieldName, value }: any) => {
    setFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  useEffect(() => {
    if (app.current) {
      setFormData((prevState) => ({ ...prevState, ...app.current?.settings?.groupView }));
      setIsShowMockup(app.current.settings?.isShowMockup ?? false);
    }
  }, [app]);

  return (
    <>
      <div className={s('formHeader')}>
        <h1 className={s('formHeaderTitle')}>{t('dashboard.apps.styles.title')}</h1>

        <FreshButton
          leftIcon={<IconSaveFresh />}
          size="md"
          text={t('form.saveSettings')}
          type="button"
          onClick={handleSubmit}
        />
      </div>

      <FilterControlGroup
        className={b('subtabs')}
        current={subTab}
        items={
          [
            {
              id: 'web',
              title: 'Web SDK'
            },
            {
              id: 'ios',
              title: 'iOS SDK'
            },
            {
              id: 'android',
              title: 'Android SDK'
            }
          ] as {
            id: PlatformType;
            title: string;
          }[]
        }
        onChange={(id: PlatformType) => setSubTab(id)}
      />

      <div className={s('fieldGroup')}>
        <div className={b('groupViewContainer')}>
          {groupViewData.map((item: string) => (
            <GroupViewContainer
              handleSelect={() => handleChange({ fieldName: subTab, value: item })}
              // @ts-ignore
              isActive={formData[subTab] === item}
              type={item}
            />
          ))}
        </div>
        <div className={s('formColumn')}>
          <div className={s('filedSet')}>
            <div className={s('fieldGroup')}>
              <div className={b('fieldFlex')}>
                <p className={s('fieldLabel', { noMargin: true })}>
                  {t('dashboard.groups.applyForAllSdk')}
                </p>

                <FreshSwitchControl
                  className={b('switch')}
                  isChecked={isApplyForAll}
                  onToggle={() => setIsApplyForAll(!isApplyForAll)}
                />
              </div>
              <p className={b('note')}>{t('dashboard.apps.styles.desc')}</p>
            </div>
          </div>
        </div>
      </div>

      <p className={s('subTitle')}>{t('dashboard.apps.styles.previewSettings')}</p>
      <div className={s('filedSet', { noBorder: true })} style={{ marginBottom: 0 }}>
        <div className={s('fieldGroup')}>
          <div className={b('groupViewContainer', { mockup: true })}>
            <MockupViewContainer
              handleSelect={() => setIsShowMockup(false)}
              isActive={!isShowMockup}
              isShowMockup={false}
            />
            <MockupViewContainer
              handleSelect={() => setIsShowMockup(true)}
              isActive={isShowMockup}
              isShowMockup
            />
          </div>
          <p className={b('note')}>{t('dashboard.apps.styles.mockupDesc')}</p>
        </div>
      </div>
    </>
  );
};
