import React from 'react';
import block from 'bem-cn';
import { CustomInput } from 'reactstrap';
import { nanoid } from 'nanoid';
import './SwitchControl.scss';

interface Props {
  className?: string;
  onToggle(): void;
  isChecked: boolean;
  isDisabled?: boolean;
}

export const SwitchControl: React.FunctionComponent<Props> = ({
  className,
  isChecked,
  isDisabled,
  onToggle
}) => {
  const b = block('SwitchControl');

  return (
    <CustomInput
      bsSize="lg"
      checked={isChecked}
      className={`${b('switch', { disabled: isDisabled })} ${className}`}
      disabled={isDisabled}
      id={`accordion_${nanoid()}`}
      type="switch"
      onChange={onToggle}
    />
  );
};
