import React from 'react';
import block from 'bem-cn';
import { AnalyticsHeader, AnalyticsStoryGroupsTab } from '@modules/DashboardModule/components';
import './AppAnalyticsRoute.scss';

const b = block('AppAnalyticsRoute');

export const AppAnalyticsRoute: React.FC = () => (
  <div className={b()}>
    <AnalyticsHeader />
    <AnalyticsStoryGroupsTab />
  </div>
);
