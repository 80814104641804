import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import './StoryHiddenMessage.scss';
import { Icon } from '@components';

const b = block('StoryHiddenMessage');

interface StoryHiddenMessageProps {
  borderRadius?: number;
}

export const StoryHiddenMessage: React.FC<StoryHiddenMessageProps> = ({ borderRadius }) => {
  const { t } = useTranslation();

  return (
    <div
      className={b()}
      style={{
        borderRadius
      }}
    >
      <div className={b('circle')}>
        <Icon className={b('icon').toString()} name="hide" />
      </div>
      <p className={b('text')}>{t('editor.hiddenStory')}</p>
    </div>
  );
};
