import React from 'react';
import { Field, reduxForm } from 'redux-form';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { validators, renderLightField } from '@utils';
import { FreshButton } from '@components/_fresh';
import './ResetPasswordForm.scss';

const b = block('ResetPasswordForm');

export const ResetPasswordForm = reduxForm({
  form: 'resetPassword'
})((props) => {
  const { handleSubmit, submitting, invalid, pristine } = props;

  const { t } = useTranslation();

  return (
    <form className={b()} onSubmit={handleSubmit}>
      <Field
        component={renderLightField}
        label={t('auth.email')}
        name="email"
        placeholder={t('auth.emailPlaceholder')}
        type="email"
        validate={[validators.required, validators.email]}
      />
      <FreshButton
        className={b('btn').toString()}
        disabled={invalid || submitting || pristine}
        text={t('auth.resetPassword')}
        type="submit"
      />
    </form>
  );
});
