import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormLabel } from '@components/Form';
import { FreshDrop, FreshInput } from '@components/_fresh';
import { IconSelectArrowFresh } from '@components';
import './SetupPlatformSelect.scss';

const b = block('SetupPlatformSelect');

export interface SelectPlatformOption {
  title: string;
  value: string;
}

interface SetupPlatformSelectProps {
  title?: string;
  options: SelectPlatformOption[];
  onChange: (value: string) => void;
}

export const SetupPlatformSelect: React.FC<SetupPlatformSelectProps> = ({
  title,
  options,
  onChange
}) => {
  const { t } = useTranslation();

  const [currentValue, setCurrentValue] = useState<SelectPlatformOption>(options[0]);

  useEffect(() => {
    setCurrentValue(options[0]);
  }, [options]);

  useEffect(() => {
    onChange(currentValue.value);
  }, [currentValue]);

  return (
    <div className={b()}>
      <FormGroup>
        <FormLabel isBlack>{title ?? t('dashboard.setup.choosePlatform')}</FormLabel>
        <FreshDrop
          control={
            <FreshInput
              isDisabled
              rightIcon={IconSelectArrowFresh}
              type="text"
              value={currentValue.title}
            />
          }
        >
          <div className={b('drop')}>
            {options.map((item, index) => (
              <div
                className={b('dropItem')}
                key={`${item.value}-${index}`}
                onClick={() => {
                  setCurrentValue(item);
                }}
              >
                <span>{item.title}</span>
              </div>
            ))}
          </div>
        </FreshDrop>
      </FormGroup>
    </div>
  );
};
