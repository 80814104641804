import React from 'react';
import block from 'bem-cn';
import Skeleton from 'react-loading-skeleton';
import './FreshTable.scss';

export type FreshTableDataRow = {
  [key: string]: string | React.ReactNode;
};

export type FreshTableHeaderCol = {
  title: string;
  key: string;
};

interface FreshTableProps {
  headerCols: FreshTableHeaderCol[];
  dataRows: FreshTableDataRow[];
  emptyText: string;
  alignStart?: boolean;
  isLoading?: boolean;
}

const b = block('FreshTable');

const renderDataRow = (
  row: FreshTableDataRow,
  headerCols: FreshTableHeaderCol[],
  alignStart?: boolean
) => (
  <div className={b('tableRow')}>
    {headerCols.map((col: FreshTableHeaderCol, index) => (
      <div
        className={b('tableCol', { first: index === 0, start: alignStart })}
        key={`fresh-table-col-${col.key}-${index}`}
      >
        {typeof row[col.key] === 'string' ? (
          <p className={b('tableText')}>{row[col.key]}</p>
        ) : (
          row[col.key]
        )}
      </div>
    ))}
  </div>
);

export const FreshTable: React.FC<FreshTableProps> = ({
  headerCols,
  dataRows,
  isLoading,
  emptyText,
  alignStart
}) => (
  <div
    className={b()}
    style={{
      gridTemplateColumns: `repeat(${headerCols.length}, 1fr)`
    }}
  >
    <div className={b('tableHeader')}>
      {headerCols.map((col, index) => (
        <div className={b('tableCol', { first: index === 0, head: true, start: alignStart })}>
          <p className={b('tableText', { bold: true })}>{col.title}</p>
        </div>
      ))}
    </div>

    {isLoading ? (
      <>
        {Array.from(Array(3)).map((_) => (
          <div className={b('tableRow')}>
            {headerCols.map((col: FreshTableHeaderCol, index) => (
              <div
                className={b('tableCol', { first: index === 0, skelet: true })}
                key={`fresh-table-col-skeleton-${col.key}-${index}`}
              >
                <Skeleton
                  height={48}
                  key={`fresh-table-skeleton-${index}`}
                  style={{
                    borderRadius: 12
                  }}
                  width="100%"
                />
              </div>
            ))}
          </div>
        ))}
      </>
    ) : (
      <>
        {dataRows.length > 0 ? (
          <div className={b('tableContent')}>
            {dataRows.map((row) => renderDataRow(row, headerCols, alignStart))}
          </div>
        ) : (
          <div
            className={b('emptyRow')}
            style={{
              gridColumnStart: 1,
              gridColumnEnd: headerCols.length + 1
            }}
          >
            <p className={b('emptyText')}>{emptyText}</p>
          </div>
        )}
      </>
    )}
  </div>
);
