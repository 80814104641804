import React from 'react';
import PropTypes from 'prop-types';

const IconBorderRadius = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="9"
    viewBox="0 0 9 9"
    width="9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.5 9V6.5C0.5 3.18629 3.18629 0.5 6.5 0.5H9" stroke="#AFAFAF" />
  </svg>
);

IconBorderRadius.propTypes = {
  className: PropTypes.string
};

IconBorderRadius.defaultProps = {
  className: ''
};

export default IconBorderRadius;
