import React from 'react';
import PropTypes from 'prop-types';

const IconDashboardStories = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    stroke="#A49BA4"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.16022 6.16005C5.62122 5.62005 6.14622 5.13905 6.72222 4.72205"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M17.0002 19.4821C15.5702 20.4401 13.8512 21.0001 12.0002 21.0001C11.0882 21.0001 10.2092 20.8621 9.38025 20.6101"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.34919 19C5.06819 17.965 4.08419 16.583 3.52319 15"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M3.52322 9C3.19022 9.94 3.00022 10.946 3.00022 12"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M19.4632 17C20.4272 15.569 21.0002 13.855 21.0002 12C21.0002 7.029 16.9712 3 12.0002 3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path d="M12 9V15" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <path d="M15 12H9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </svg>
);

IconDashboardStories.propTypes = {
  className: PropTypes.string
};

IconDashboardStories.defaultProps = {
  className: ''
};

export default IconDashboardStories;
