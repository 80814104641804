import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { SettingsBox } from '@modules';
import { useTranslation } from 'react-i18next';
import block from 'bem-cn';
import { AnswerTypesMap, InteractiveWidgets } from '@features/stories/consts';
import {
  SettingsCollapse,
  SettingsLayerSelect,
  SettingsScoreAnswers,
  SettingsSelect,
  SettingsStoriesPreview
} from '@features/stories/editorSettings';
import {
  fetchCreateStory,
  fetchCreateTemplateStory,
  fetchUpdateGroup,
  fetchUpdateOnboarding,
  fetchUpdateTemplate,
  GroupsType,
  LayerWidgetObjectType,
  ScoreType
} from '@features';
import { useParams } from 'react-router-dom';
import { WidgetsTypes } from '@storysdk/react';
import {
  useCurrentStoriesSlice,
  useCurrentStoriesType,
  useGetCurrentGroup
} from '@features/stories/hooks';
import { getInitStoryParams } from '@utils';
import { StoryLogicEmptyMessage } from './_components/StoryLogicEmptyMessage';
import './StoryLogicTools.scss';

const b = block('StoryLogicTools');

const WidgetAnswersCount = {
  [WidgetsTypes.CHOOSE_ANSWER]: 'one',
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWERS]: 'any',
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE]: 'any',
  [WidgetsTypes.QUIZ_ONE_ANSWER]: 'one'
};

export const StoryLogicTools: React.FC = () => {
  const dispatch = useAppDispatch();
  const { appId, groupId, templateId, type } = useParams<{
    groupId?: string;
    appId?: string;
    templateId?: string;
    type: GroupsType;
  }>();

  const storiesType = useCurrentStoriesType(type);
  const currentStoriesSlice = useCurrentStoriesSlice(type);
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);

  const stories = useSelector((store: RootState) => store[storiesType].stories);
  const storiesArr = Object.values(stories).sort((storyA, storyB) =>
    storyA.position < storyB.position ? -1 : 1
  );

  const currentGroup = useGetCurrentGroup(type, templateId ?? groupId, !!templateId);

  const [selectedLayerId, setSelectedLayerId] = useState<string | null>(null);
  const [selectedLayersGroupId, setSelectedLayersGroupId] = useState<string | null>(
    currentGroup?.settings?.scoreResultLayersGroupId ?? null
  );
  const [scoreType, setScoreType] = useState<ScoreType>(
    currentGroup?.settings?.scoreType ?? ScoreType.NUMBERS
  );

  const activeStories = storiesArr.map((story) => {
    if (selectedLayerId && story.layers[selectedLayerId]) {
      return story.layers[selectedLayerId];
    }
    return story.layers[story.activeLayerId];
  });

  const handleCreateStory = () => {
    if (type === GroupsType.TEMPLATE && templateId) {
      dispatch(
        fetchCreateTemplateStory({
          templateId,
          story: getInitStoryParams({})
        })
      );
    } else if (appId && groupId) {
      dispatch(
        fetchCreateStory({
          appId,
          groupId,
          type,
          story: getInitStoryParams({})
        })
      );
    }
  };

  const interactiveWidgets = storiesArr.reduce((acc, story) => {
    const layerWidgets = Object.values(story.layers)
      .map((layer) =>
        layer.widgets.map((widget) => ({ ...widget, storyId: layer.id, layersGroupId: story.id }))
      )
      .flat();

    const widgets = layerWidgets.filter((widget) =>
      InteractiveWidgets.includes(widget.content.type)
    );

    if (widgets.length > 0) {
      return { ...acc, [story.id]: widgets };
    }

    return acc;
  }, {});

  const { t } = useTranslation();

  const storyWidgets = Object.values(interactiveWidgets) as LayerWidgetObjectType[][];

  const calculatePoints = (widgets: LayerWidgetObjectType[]): number =>
    widgets.reduce(
      (acc, widget) =>
        acc +
        // @ts-ignore
        +(widget.content.params.answers ?? []).reduce(
          // @ts-ignore
          (pointsAcc, answer) => {
            // @ts-ignore
            if (WidgetAnswersCount[widget.content.type] === 'any') {
              return pointsAcc + +answer.score.points;
            }
            if (pointsAcc < +answer.score.points) {
              return +answer.score.points;
            }

            return pointsAcc;
          },
          0
        ),
      0
    );

  useEffect(() => {
    if (
      currentGroup &&
      (currentGroup.settings?.scoreType !== scoreType ||
        currentGroup.settings?.scoreResultLayersGroupId !== selectedLayersGroupId)
    ) {
      if (type === GroupsType.TEMPLATE) {
        const templateParams = {
          templateId,
          active: currentGroup.active,
          title: currentGroup.title,
          description: currentGroup.description,
          type: currentGroup.type,
          category: currentGroup.category,
          noNotification: true,
          settings: {
            ...currentGroup.settings,
            scoreType,
            scoreResultLayersGroupId: selectedLayersGroupId
          }
        };

        dispatch(fetchUpdateTemplate(templateParams));
        return;
      }

      const params = {
        appId: appId ?? '',
        groupId: currentGroup.id,
        active: currentGroup.active,
        title: currentGroup.title,
        description: currentGroup.description,
        startTime: currentGroup.startDate,
        endTime: currentGroup.endDate,
        noNotification: true,
        settings: {
          ...currentGroup.settings,
          scoreType,
          scoreResultLayersGroupId: selectedLayersGroupId
        }
      };

      if (type === GroupsType.ONBOARDING) {
        dispatch(fetchUpdateOnboarding(params));
      } else if (type === GroupsType.GROUP && params.appId) {
        dispatch(fetchUpdateGroup(params));
      }
    }
  }, [type, scoreType, selectedLayersGroupId, currentGroup]);

  const handleChooseLayersGroupId = (id: string) => {
    const layersGroupId =
      activeStories.find((story) => story.id === id)?.layerData.layersGroupId ?? null;

    if (selectedLayersGroupId === layersGroupId) {
      setSelectedLayersGroupId(null);
    } else {
      setSelectedLayersGroupId(layersGroupId);
    }

    setSelectedLayerId(null);
  };

  const layers =
    selectedLayersGroupId && stories[selectedLayersGroupId]
      ? stories[selectedLayersGroupId].layers
      : {};

  const layersToSelect = Object.values(layers).map((layer, index) => ({
    title: `${t('editor.layer')} #${index + 1}`,
    value: layer.id,
    score: layer.layerData.score
  }));

  const handleChangeLayerScore = (layerId: string, value: string) => {
    if (!selectedLayersGroupId) {
      return;
    }

    const storyChanged = {
      ...stories[selectedLayersGroupId].layers[layerId],
      layerData: {
        ...stories[selectedLayersGroupId].layers[layerId].layerData,
        score: {
          letter:
            scoreType === ScoreType.LETTERS
              ? value
              : stories[selectedLayersGroupId].layers[layerId].layerData.score.letter,
          points:
            scoreType === ScoreType.NUMBERS
              ? +value
              : stories[selectedLayersGroupId].layers[layerId].layerData.score.points
        }
      }
    };
    dispatch(currentStoriesSlice.actions.updateStory(storyChanged));
  };
  const handleChangeLayer = (layerId: string) => {
    setSelectedLayerId(layerId);
  };

  const selectedStory = activeStories.find(
    (story) => story.layerData.layersGroupId === selectedLayersGroupId
  );

  return (
    <SettingsBox withToggle>
      {storyWidgets.length > 0 ? (
        <>
          <SettingsBox.Group title={t('editor.logic.chooseTypeScore')}>
            <SettingsSelect
              options={[
                { title: t('editor.logic.numbersType'), value: ScoreType.NUMBERS },
                { title: t('editor.logic.lettersType'), value: ScoreType.LETTERS }
              ]}
              value={scoreType}
              onChange={(value) => setScoreType(value)}
            />
          </SettingsBox.Group>

          {storyWidgets.map((widgets, index) => (
            <SettingsCollapse
              isOpenByDefault
              key={`collapseLogic-${index}`}
              subtitle={
                scoreType === ScoreType.NUMBERS
                  ? `${t('editor.logic.maxScore')} ${calculatePoints(widgets)}`
                  : undefined
              }
              title={`${t('editor.story')} ${index + 1}`}
            >
              {widgets.map((widget) => (
                <SettingsScoreAnswers
                  // @ts-ignore
                  answers={widget.content.params.answers ?? []}
                  groupType={type}
                  key={`score-${widget.id}`}
                  // @ts-ignore
                  leftChildType={AnswerTypesMap[widget.content.type]}
                  // @ts-ignore
                  storyId={widget.storyId}
                  type={scoreType}
                  widget={widget}
                />
              ))}
            </SettingsCollapse>
          ))}

          <SettingsBox.Title>{t('editor.logic.showResult')}</SettingsBox.Title>
          <SettingsBox.Group title={t('editor.logic.chooseStory')}>
            <SettingsStoriesPreview
              currentStoryId={selectedStory?.id}
              stories={activeStories}
              onClick={handleChooseLayersGroupId}
              onCreateStory={handleCreateStory}
            />
          </SettingsBox.Group>

          {selectedLayersGroupId && (
            <SettingsBox.Group>
              <SettingsLayerSelect
                currentLayerId={selectedLayerId || undefined}
                layers={layersToSelect}
                type={scoreType}
                onChangeLayer={handleChangeLayer}
                onChangeScore={handleChangeLayerScore}
              />
            </SettingsBox.Group>
          )}
        </>
      ) : (
        <StoryLogicEmptyMessage />
      )}
    </SettingsBox>
  );
};
