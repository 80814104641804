import React from 'react';
import {
  EllipseIcon,
  ImageIcon,
  ReadOnlyWidgetControl,
  RectangleIcon,
  StoryEditorAction,
  TextIcon,
  useStoryEditorDispatch
} from '@modules';
import { RootState } from '@store';
import { GroupsType, createObjectWithPosition } from '@features';
import { useTranslation } from 'react-i18next';
import {
  ButtonIcon,
  ImageLoaderWrapper,
  SwipeUpCircleIcon,
  VideoIcon
} from '@modules/EditorModule/widgets';
import { useSelector } from 'react-redux';
import StoryWidgetFactory from '@features/stories/utils/StoryWidgetFactory';
import { ClickMeState, TextState, TextWidgetParamsType, WidgetsTypes } from '@storysdk/react';
import { useCurrentStoriesType } from '@features/stories/hooks';
import { useParams } from 'react-router-dom';
import { useCreateMediaWidget } from '@hooks';
import { LinkIcon } from '@components/MaterialIcon/icons';
import { WidgetItem, WidgetList } from './WidgetList/WidgetList';
import { WIDGET_PARAMS } from './consts';

export const EditorSimpleTab = () => {
  const { type } = useParams<{
    type: GroupsType;
  }>();

  const { t } = useTranslation();

  const currentStoriesType = useCurrentStoriesType(type);

  const storyEditorDispatch = useStoryEditorDispatch();

  const appSettings = useSelector((store: RootState) => store.appManager.current?.settings);
  const recentFont = useSelector((store: RootState) => store.fonts.recentFont);
  const stories = useSelector((store: RootState) => store[currentStoriesType].stories);
  const selectedLayersGroupId = useSelector(
    (store: RootState) => store[currentStoriesType].editor.selectedLayersGroupId
  );
  const selectedStoryId = selectedLayersGroupId
    ? stories[selectedLayersGroupId].activeLayerId
    : null;
  const storiesArr = Object.values(stories).sort((storyA, storyB) =>
    storyA.position < storyB.position ? -1 : 1
  );

  const getNextStoryId = () => {
    if (!selectedStoryId) return undefined;

    const selectedStoryPosition = storiesArr.map((story) => story.id).indexOf(selectedStoryId);

    if (selectedStoryPosition !== storiesArr.length - 1) {
      return storiesArr[selectedStoryPosition + 1].activeLayerId;
    }

    return undefined;
  };

  const nextStoryId = getNextStoryId();

  const getContent = (type: WidgetsTypes) => {
    let content = appSettings?.defaultWidgetsContent?.[type];

    if (content && type === WidgetsTypes.CLICK_ME) {
      content = {
        ...content,
        params: {
          ...content.params,
          actionType: 'story',
          storyId: nextStoryId ?? ''
        }
      } as ClickMeState;
    } else if (content && type === WidgetsTypes.TEXT) {
      content = {
        ...content,
        params: {
          ...content.params,
          lineHeight: (content.params as TextWidgetParamsType)?.lineHeight || 100,
          text: (content.params as TextWidgetParamsType)?.text || t('editor.widgets.yourText')
        }
      } as TextState;
    }

    return content;
  };

  const createObject = (type: WidgetsTypes) => (e: React.MouseEvent<HTMLButtonElement>) => {
    storyEditorDispatch?.({
      type: StoryEditorAction.ADD_STORY_WIDGET,
      payload: StoryWidgetFactory.make(type, getContent(type))
    });
    e.currentTarget.blur();
  };

  const createText = (e: React.MouseEvent<HTMLButtonElement>) => {
    const textParams = StoryWidgetFactory.makeWithPosition({
      type: WidgetsTypes.TEXT,
      position: {
        x: WIDGET_PARAMS[WidgetsTypes.TEXT].x,
        y: WIDGET_PARAMS[WidgetsTypes.TEXT].y,
        width: WIDGET_PARAMS[WidgetsTypes.TEXT].width,
        height: WIDGET_PARAMS[WidgetsTypes.TEXT].height,
        realWidth: WIDGET_PARAMS[WidgetsTypes.TEXT].width,
        realHeight: WIDGET_PARAMS[WidgetsTypes.TEXT].height,
        isHeightLocked: true,
        rotate: 0,
        origin: {
          x: WIDGET_PARAMS[WidgetsTypes.TEXT].x,
          y: WIDGET_PARAMS[WidgetsTypes.TEXT].y,
          width: WIDGET_PARAMS[WidgetsTypes.TEXT].width,
          height: WIDGET_PARAMS[WidgetsTypes.TEXT].height
        }
      },
      font: recentFont,
      content: getContent(WidgetsTypes.TEXT)
    });

    storyEditorDispatch?.({
      type: StoryEditorAction.ADD_STORY_WIDGET,
      payload: textParams
    });

    storyEditorDispatch?.({
      type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
      payload: [textParams.id]
    });

    e.currentTarget.blur();
  };

  const handleCreateMediaWidget = useCreateMediaWidget();

  return (
    <WidgetList>
      <WidgetItem onClick={createText}>
        <ReadOnlyWidgetControl icon={<TextIcon />} justifyStart text={t('editor.widgets.text')} />
      </WidgetItem>
      <WidgetItem onClick={createObject(WidgetsTypes.RECTANGLE)}>
        <ReadOnlyWidgetControl
          icon={<RectangleIcon />}
          justifyStart
          text={t('editor.widgets.rectangle')}
        />
      </WidgetItem>
      <WidgetItem onClick={createObject(WidgetsTypes.ELLIPSE)}>
        <ReadOnlyWidgetControl
          icon={<EllipseIcon />}
          justifyStart
          text={t('editor.widgets.ellipse')}
        />
      </WidgetItem>
      <WidgetItem>
        <ImageLoaderWrapper onLoad={handleCreateMediaWidget}>
          <ReadOnlyWidgetControl
            icon={<ImageIcon />}
            justifyStart
            text={t('editor.widgets.image')}
          />
        </ImageLoaderWrapper>
      </WidgetItem>
      <WidgetItem>
        <ImageLoaderWrapper onlyVideo onLoad={handleCreateMediaWidget}>
          <ReadOnlyWidgetControl
            icon={<VideoIcon />}
            justifyStart
            text={t('editor.widgets.video')}
          />
        </ImageLoaderWrapper>
      </WidgetItem>
      <WidgetItem
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.CLICK_ME,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].x,
            y: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].y,
            width: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].width,
            height: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].height,
            isHeightLocked: false
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].width,
            height: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].height
          },
          recentFont,
          content: getContent(WidgetsTypes.CLICK_ME),
          nextStoryId
        })}
      >
        <ReadOnlyWidgetControl
          icon={<ButtonIcon />}
          justifyStart
          text={t('editor.widgets.button')}
        />
      </WidgetItem>
      <WidgetItem
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.LINK,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.LINK].x,
            y: WIDGET_PARAMS[WidgetsTypes.LINK].y,
            width: WIDGET_PARAMS[WidgetsTypes.LINK].width,
            height: WIDGET_PARAMS[WidgetsTypes.LINK].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.LINK].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.LINK].height,
            isHeightLocked: false
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.LINK].width,
            height: WIDGET_PARAMS[WidgetsTypes.LINK].height
          },
          recentFont,
          content: getContent(WidgetsTypes.LINK)
        })}
      >
        <ReadOnlyWidgetControl
          icon={<LinkIcon color="#AFAFAF" />}
          justifyStart
          text={t('editor.widgets.link')}
        />
      </WidgetItem>
      <WidgetItem
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.SWIPE_UP,
          position: {
            width: WIDGET_PARAMS[WidgetsTypes.SWIPE_UP].width,
            height: WIDGET_PARAMS[WidgetsTypes.SWIPE_UP].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.SWIPE_UP].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.SWIPE_UP].height,
            isHeightLocked: false
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.SWIPE_UP].width,
            height: WIDGET_PARAMS[WidgetsTypes.SWIPE_UP].height
          },
          recentFont
        })}
      >
        <ReadOnlyWidgetControl
          icon={<SwipeUpCircleIcon />}
          justifyStart
          text={t('editor.widgets.swipeUp')}
        />
      </WidgetItem>
    </WidgetList>
  );
};
