import React from 'react';
import PropTypes from 'prop-types';

const IconIos = ({ className }) => (
  <svg
    className={className}
    fill="#888888"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.039 16.7279C23.0279 14.6253 23.9501 13.0383 25.8168 11.8696C24.7723 10.3285 23.1945 9.48053 21.1112 9.31439C19.139 9.15397 16.9834 10.5003 16.1946 10.5003C15.3612 10.5003 13.4501 9.37168 11.9501 9.37168C8.85011 9.42324 5.55566 11.9212 5.55566 17.0029C5.55566 18.504 5.82233 20.0547 6.35566 21.655C7.06677 23.7576 9.63344 28.9139 12.3112 28.8279C13.7112 28.7936 14.7001 27.8024 16.5223 27.8024C18.289 27.8024 19.2057 28.8279 20.7668 28.8279C23.4668 28.7878 25.789 24.1014 26.4668 21.993C22.8445 20.2342 23.039 16.8368 23.039 16.7279ZM19.8946 7.32064C21.4112 5.46439 21.2723 3.77428 21.2279 3.16699C19.889 3.2472 18.339 4.10658 17.4557 5.16647C16.4834 6.30085 15.9112 7.70449 16.0334 9.28574C17.4834 9.40033 18.8057 8.63262 19.8946 7.32064Z" />
  </svg>
);

IconIos.propTypes = {
  className: PropTypes.string
};

IconIos.defaultProps = {
  className: ''
};

export default IconIos;
