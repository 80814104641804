import React from 'react';
import PropTypes from 'prop-types';

const IconCheckCircleSmall = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    stroke="white"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M7 13V13C3.686 13 1 10.314 1 7V7C1 3.686 3.686 1 7 1V1C10.314 1 13 3.686 13 7V7C13 10.314 10.314 13 7 13Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.66634 5.66667L6.33301 9L4.33301 7.00001"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCheckCircleSmall.propTypes = {
  className: PropTypes.string
};

IconCheckCircleSmall.defaultProps = {
  className: ''
};

export default IconCheckCircleSmall;
