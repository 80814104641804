import React from 'react';
import block from 'bem-cn';
import './SettingsOpenSelect.scss';

const b = block('SettingsOpenSelect');

interface SettingsSelectProps {
  value: any;
  options: Array<{ value: any; title: string }>;
  className?: string;
  onChange(value: any): void;
}

export const SettingsOpenSelect: React.FC<SettingsSelectProps> = (props) => {
  const { options, value, className, onChange } = props;

  return (
    <div className={`${b()} ${className ?? ''}`}>
      <>
        {options.map((option) => (
          <button
            className={b('option', { current: option.value === value })}
            onClick={() => onChange(option.value)}
          >
            {option.title}
          </button>
        ))}
      </>
    </div>
  );
};
