import React from 'react';
import { Subscription } from '@features';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import cn from 'classnames';
import '../AppOrderCard/AppOrderCard.scss';
import './SubscriptionCancelledCard.scss';

const b = block('SubscriptionCancelledCard');
const bAppOrderCard = block('AppOrderCard');

interface SubscriptionCancelledCardProps {
  subscription: Subscription;
}

export const SubscriptionCancelledCard: React.FC<SubscriptionCancelledCardProps> = ({
  subscription
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(b().toString(), bAppOrderCard().toString())}>
      <p className={b('title')}>
        {t('dashboard.billing.subscription')} - {subscription.plan?.name}
      </p>
      <p className={b('desc')}>
        {t('dashboard.billing.expiresIs')}{' '}
        <span className={b('time')}>
          {DateTime.fromSeconds(subscription.canceledAt).toLocaleString({
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </span>
      </p>
    </div>
  );
};
