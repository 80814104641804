import React from 'react';
import PropTypes from 'prop-types';

const IconChartFresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.74805 11.6677L8.83225 9.4443L10.9164 11.6677L14.2512 8.33301"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.41"
    />
    <rect
      height="15.0062"
      rx="5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      width="15.0062"
      x="2.99658"
      y="2.49707"
    />
  </svg>
);

IconChartFresh.propTypes = {
  className: PropTypes.string
};

IconChartFresh.defaultProps = {
  className: ''
};

export default IconChartFresh;
