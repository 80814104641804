import React from 'react';
import PropTypes from 'prop-types';

const IconAlignCenter = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 18 14"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3333 5H3.66663"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
    />
    <path
      d="M17 1H1"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
    />
    <path
      d="M17 9H1"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
    />
    <path
      d="M14.3333 13H3.66663"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
    />
  </svg>
);

IconAlignCenter.propTypes = {
  className: PropTypes.string
};

IconAlignCenter.defaultProps = {
  className: ''
};

export default IconAlignCenter;
