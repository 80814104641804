import React from 'react';

type PropsType = {
  className?: string;
};

export const DropIcon = ({ className = '' }: PropsType) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 13 14"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6.97434 0.341886C6.90628 0.137715 6.71522 0 6.5 0C6.28478 0 6.09372 0.137715 6.02566 0.341886C5.93112 0.62549 5.64298 1.05936 5.18594 1.66875C5.04956 1.85059 4.90037 2.04537 4.74325 2.25052L4.74321 2.25056L4.74321 2.25057C4.4012 2.69711 4.02156 3.19277 3.65449 3.71099C2.58846 5.21597 1.5 7.07948 1.5 9C1.5 10.4249 1.96284 11.6824 2.8434 12.5861C3.72597 13.4919 4.98691 14 6.5 14C8.01309 14 9.27403 13.4919 10.1566 12.5861C11.0372 11.6824 11.5 10.4249 11.5 9C11.5 7.07948 10.4115 5.21597 9.34551 3.71099C8.97844 3.19277 8.5988 2.69711 8.2568 2.25057L8.25679 2.25056C8.09965 2.0454 7.95045 1.8506 7.81406 1.66875C7.35702 1.05936 7.06888 0.62549 6.97434 0.341886ZM9.5 8.5C9.5 8.22386 9.27614 8 9 8C8.72386 8 8.5 8.22386 8.5 8.5C8.5 9.60457 7.60457 10.5 6.5 10.5C6.22386 10.5 6 10.7239 6 11C6 11.2761 6.22386 11.5 6.5 11.5C8.15685 11.5 9.5 10.1569 9.5 8.5Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);
