import React from 'react';
import { getScalableValue } from '@features';
import block from 'bem-cn';
import { WidgetPositionType } from '@storysdk/react';
import './WidgetOutline.scss';

const b = block('WidgetOutline');

interface WidgetOutlineProps {
  position: WidgetPositionType;
}

export const WidgetOutline: React.FC<WidgetOutlineProps> = ({ children, position }) => {
  const lineWidth = getScalableValue(1);

  const getTop = () => {
    if (position.origin?.y) {
      return position.origin.y - position.y;
    }

    return 0;
  };

  const getLeft = () => {
    if (position.origin?.x) {
      return position.origin.x - position.x;
    }

    return 0;
  };
  return (
    <div
      className={b()}
      style={{
        borderWidth: lineWidth
      }}
    >
      <div
        className={b('outline')}
        style={{
          width: position.origin?.width,
          height: position.origin?.height,
          top: getTop(),
          left: getLeft(),
          borderWidth: lineWidth,
          transform: `rotate(${-position.rotate}deg)`
        }}
      >
        {children}
      </div>
    </div>
  );
};
