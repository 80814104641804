import React from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs, TabType } from '@components';
import { AccountSettingsTab } from './components/AccountSettingsTab/AccountSettingsTab';
import './AccountSettingsRoute.scss';

const b = block('AccountSettingsRoute');

export const AccountSettingsRoute: React.FC = () => {
  const { tab } = useParams<{ tab: 'settings' | 'plans' }>();

  const { t } = useTranslation();

  const tabsItems: TabType[] = [
    {
      id: 'settings',
      title: t('user.accountSettings'),
      component: AccountSettingsTab
    }
    // {
    //   id: 'plans',
    //   title: t('user.plans'),
    //   component: PlansTab
    // },
    // {
    //   id: 'billing',
    //   title: t('user.billing'),
    //   link: `${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${userEmail}`
    // }
  ];

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <Tabs initialTabId={tab} tabs={tabsItems} />
      </div>
    </div>
  );
};
