import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import humanizeDuration from 'humanize-duration';
import { IconSuccessCircleFreshSmall, IconWarningCircleFresh } from '@components/Icon';
import './CountdownTimer.scss';

interface CountdownTimerProps {
  timeLeft: number; // seconds
  text: string;
  expiredText: string;
  locale?: string;
}
const b = block('CountdownTimer');

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
  timeLeft,
  text,
  expiredText,
  locale
}) => {
  const [time, setTime] = useState(timeLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time === 0) {
        clearInterval(interval);
      } else {
        setTime((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  const isExpired = time <= 0;

  const displayedDuration = humanizeDuration(time * 1000, {
    largest: 1,
    round: true,
    language: locale ?? 'en',
    units: ['d', 'h', 'm', 's']
  });

  return (
    <div className={b()}>
      {isExpired ? (
        <IconWarningCircleFresh className={b('icon').toString()} />
      ) : (
        <IconSuccessCircleFreshSmall className={b('icon').toString()} />
      )}
      <div className={b('text')}>{isExpired ? expiredText : `${displayedDuration} ${text}`}</div>
    </div>
  );
};
