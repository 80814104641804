import React from 'react';
import { Field, reduxForm } from 'redux-form';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { validators, renderLightField } from '@utils';
import { FreshButton } from '@components/_fresh';

import './NewPasswordForm.scss';

const b = block('NewPasswordForm');

export const NewPasswordForm = reduxForm({
  form: 'newPassword'
})((props) => {
  const { handleSubmit, submitting, invalid, pristine } = props;

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <Field
        autoComplete="off"
        component={renderLightField}
        label={t('auth.codeFromEmail')}
        name="code"
        placeholder={t('auth.enterCodeFromEmail')}
        type="text"
        validate={[validators.required]}
      />
      <Field
        autoComplete="off"
        component={renderLightField}
        label={t('auth.newPassword')}
        name="password"
        placeholder={t('auth.newPasswordPlaceholder')}
        type="password"
        validate={[
          validators.required,
          validators.password
          // validators.minLength(8),
          // validators.maxLength(256)
        ]}
      />
      <FreshButton
        className={b('btn').toString()}
        disabled={invalid || submitting || pristine}
        text={t('auth.updatePassword')}
        type="submit"
      />
    </form>
  );
});
