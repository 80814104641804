import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshModal, FreshModalTitle, FreshButton } from '@components/_fresh';

import './RemoveTeamMemberModal.scss';

interface RemoveTeamMemberModalProps {
  title: string;
  isOpen: boolean;
  onClose(): void;
  handleRemoveTeamMember(): void;
}

export const RemoveTeamMemberModal: React.FC<RemoveTeamMemberModalProps> = (props) => {
  const { isOpen, title, onClose, handleRemoveTeamMember } = props;

  const b = block('RemoveTeamMemberModal');
  const { t } = useTranslation();

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle>
          {t('dashboard.modals.deleteTeamMember.deleteText')} {title}?
        </FreshModalTitle>

        <section className={b('btnContaier')}>
          <FreshButton
            color="grey"
            text={t('dashboard.modals.deleteTeamMember.cancel')}
            onClick={onClose}
          />
          <FreshButton
            color="black"
            text={t('dashboard.modals.deleteTeamMember.delete')}
            onClick={handleRemoveTeamMember}
          />
        </section>
      </div>
    </FreshModal>
  );
};
