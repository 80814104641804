import React from 'react';
import PropTypes from 'prop-types';

const IconElementAlignCenterHorizontal = ({ className }) => (
  <svg
    className={className}
    height="16"
    viewBox="0 0 15 16"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1210_12883)">
      <rect height="16" width="1" x="7" />
      <rect height="16" width="1" x="7" />
      <rect height="5" rx="1.5" width="15" y="2" />
      <rect height="5" rx="1.5" width="15" y="2" />
      <rect height="5" rx="1.5" width="7" x="4" y="9" />
      <rect height="5" rx="1.5" width="7" x="4" y="9" />
    </g>
    <defs>
      <clipPath id="clip0_1210_12883">
        <rect fill="white" height="16" width="15" />
      </clipPath>
    </defs>
  </svg>
);

IconElementAlignCenterHorizontal.propTypes = {
  className: PropTypes.string
};

IconElementAlignCenterHorizontal.defaultProps = {
  className: ''
};

export default IconElementAlignCenterHorizontal;
