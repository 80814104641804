import React from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import './Accordion.scss';
import { CustomInput } from 'reactstrap';
import { Icon } from '@components';

const b = block('Accordion');

interface Props {
  title: string;
  icon?: string;
  toggleSwitch?: boolean;
  onChange?(isShowed: boolean): void;
}

export const Accordion: React.FunctionComponent<Props> = (props) => {
  const { children, icon, title, toggleSwitch, onChange } = props;
  const [isShowed, toggle] = React.useState<boolean>(false);

  const handleToggle = React.useCallback(() => {
    toggle(!isShowed);
    if (onChange) {
      onChange(!isShowed);
    }
  }, [isShowed, onChange]);

  return (
    <div className={b()}>
      <div
        className={b('header', { noClickable: toggleSwitch })}
        onClick={toggleSwitch ? () => { } : handleToggle}
      >
        <div className={b('titleColumn')}>
          {icon && (
            <Icon className={cn(b('icon').toString(), `${b(`icon_${icon}`)}`)} name={icon} />
          )}
          <div className={b('title')}>{title}</div>
        </div>
        {toggleSwitch && (
          <CustomInput
            bsSize="lg"
            className={`${b('switch')}`}
            id={`accordion_${title.replace(/\s/g, '')}`}
            type="switch"
            onClick={handleToggle}
          />
        )}
      </div>
      <div className={b('body', { showed: isShowed })}>{children}</div>
    </div>
  );
};
