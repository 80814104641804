import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SharingRoute } from './pages';

export const SharingModule: React.FC = (props) => (
  <Switch>
    <Route exact path="/share/:shortId">
      <SharingRoute />
    </Route>
  </Switch>
);
