import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshButton } from '@components/_fresh';
import './AppIntegrationsCard.scss';

const b = block('AppIntegrationsCard');

interface AppIntegrationsCardProps {
  type: string;
  icon: React.ReactElement;
  title: string;
  isActive: boolean;
  onActivate: (type: string) => void;
  onConfigure: (type: string) => void;
}

export const AppIntegrationsCard: React.FC<AppIntegrationsCardProps> = ({
  type,
  icon,
  title,
  isActive,
  onActivate,
  onConfigure
}) => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <div className={b('top')}>
        <div className={b('icon')}>{icon}</div>
        <p className={b('title')}>{title}</p>
      </div>
      <div className={b('bottom')}>
        <div className={b('label')}>
          <span className={b('indicator', { active: isActive })} />
          {isActive ? t('dashboard.integrations.active') : t('dashboard.integrations.inactive')}
        </div>
        <FreshButton
          className={b('button')}
          color={isActive ? 'transparent-grey' : 'pink'}
          size="sm"
          text={
            isActive ? t('dashboard.integrations.configure') : t('dashboard.integrations.activate')
          }
          onClick={() => (isActive ? onConfigure(type) : onActivate(type))}
        />
      </div>
    </div>
  );
};
