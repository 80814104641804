export const validators = {
  required: (value: string | number) =>
    value || typeof value === 'number' ? undefined : 'Required',
  maxLength: (max: number) => (value: string) =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined,
  minLength: (min: number) => (value: string) =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined,
  number: (value: number) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined),
  email: (value: string) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined,
  password: (value: string) =>
    value && !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/i.test(value)
      ? 'Password must contain at least 8 characters and numbers and capital letters'
      : undefined
};
