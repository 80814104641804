import React, { useEffect, useState, useCallback } from 'react';
import block from 'bem-cn';
import { Emoji, EmojiData } from 'emoji-mart';
import { SettingsBox, EmojiWidget, WidgetThemes, WidgetThemesColor } from '@modules';
import { useAppDispatch } from '@store';
import {
  ControlGroup,
  MAX_ANGLE_VALUE,
  MIN_ANGLE_VALUE,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsInput
} from '@features/stories/editorSettings/components';
import { GroupsType } from '@features';
import { useTranslation } from 'react-i18next';
import {
  EmojiReactionWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { Drop } from '@custom';
import { Icon } from '@components';
import { FreshDrop } from '@components/_fresh';
import './EmojiReactionWidgetSettings.scss';

const b = block('EmojiReactionWidgetSettings');

interface Props {
  params: EmojiReactionWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChange(params: EmojiReactionWidgetParamsType): void;
  onChangePosition(params: WidgetPositionType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
}

export const EmojiReactionWidgetSettings: React.FunctionComponent<Props> = (props) => {
  const { params, position, positionLimits, onChangeAlign, onChangePosition, onChange } = props;

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const { t } = useTranslation();

  const handleChangeColor = (color: string) => {
    onChange({ ...params, color });
  };

  const handleRemoveEmoji = (i: number) => () => {
    if (params.emoji.length > 1) {
      onChange({ ...params, emoji: params.emoji.filter((emoji, index) => index !== i) });
    }
  };

  const handleAddEmoji = (emoji: EmojiData) => {
    // @ts-ignore
    onChange({ ...params, emoji: [...params.emoji, { name: emoji.id, unicode: emoji.unified }] });
  };

  const dispatch = useAppDispatch();

  const [isEmojiChangeOpen, setEmojiChangeOpen] = useState<boolean>(false);
  const [isEmojiOpen, setEmojiOpen] = useState<boolean>(false);

  const handleDropOpen = useCallback((isOpen: boolean) => {
    setEmojiOpen(isOpen);
  }, []);

  const handleDropChangeOpen = useCallback((isOpen: boolean) => {
    setEmojiChangeOpen(isOpen);
  }, []);

  const handleDropThemesOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(currentStoriesSlice.actions.setIsPickerOpen(isOpen));
    },
    [dispatch]
  );
  const handleChangePosition = (field: string) => (value: any) => {
    onChangePosition({
      ...position,
      [field]: value,
      realHeight: field === 'height' ? value : position.realHeight
    });
  };
  useEffect(() => {
    dispatch(currentStoriesSlice.actions.setIsPickerOpen(isEmojiOpen || isEmojiChangeOpen));
  }, [isEmojiOpen, isEmojiChangeOpen, dispatch]);

  const handleChangeEmoji = (i: number) => (newEmoji: EmojiData) => {
    onChange({
      ...params,
      // @ts-ignore
      emoji: params.emoji.map((emoji, index) =>
        // @ts-ignore
        index === i ? { name: newEmoji.id, unicode: newEmoji.unified } : emoji
      )
    });
  };

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked>
            <SettingsInput
              disabled
              postfix="px"
              prefix="W"
              type="number"
              value={position.realWidth}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              max={positionLimits.maxHeight}
              min={positionLimits.minHeight}
              postfix="px"
              prefix="H"
              type="number"
              value={position.height}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.emoji')}>
        <div className={b('list')}>
          {params.emoji.map((emoji, index) => (
            <div className={b('item')} key={`emoji-react-${index}`}>
              <div className={b('itemEmoji')}>
                <span className={b('itemEmojiIconWrapper')}>
                  <FreshDrop
                    control={<Emoji emoji={emoji.name} set="apple" size={18} />}
                    handleOpen={handleDropChangeOpen}
                    isPreventClose
                  >
                    <EmojiWidget onChange={handleChangeEmoji(index)} />
                  </FreshDrop>
                </span>
                <p className={b('itemEmojiTitle')}>{emoji.name}</p>
              </div>

              {params.emoji.length > 1 && (
                <button className={b('remove-btn')} onClick={handleRemoveEmoji(index)}>
                  <Icon className={b('remove-icon').toString()} color="#AFAFAF" name="delete" />
                </button>
              )}
            </div>
          ))}
          <div className={b('item', { add: true })}>
            <FreshDrop
              control={
                <div className={b('btnContainer')}>
                  <button className={b('btn')}>
                    <Icon className={b('icon').toString()} name="add" variant="editor" />
                  </button>
                  <span className={b('addText')}>{t('editor.add')}</span>
                </div>
              }
              handleOpen={handleDropOpen}
              isPreventClose
            >
              <EmojiWidget onChange={handleAddEmoji} />
            </FreshDrop>
          </div>
        </div>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.themes')}>
        <div className={b('color')}>
          <Drop
            control={<WidgetThemesColor color={params.color} hasDarkBackground />}
            handleOpen={handleDropThemesOpen}
          >
            <WidgetThemes selectedColor={params.color} onChange={handleChangeColor} />
          </Drop>
        </div>
      </SettingsBox.Group>
    </>
  );
};
