import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CollabaratorRole, fetchCreateCollaborator, fetchUpdateCollaborator } from '@features';
import { useAppDispatch } from '@store';
import { FormGroup, FormLabel, IconSelectArrowFresh } from '@components';
import {
  FreshModal,
  FreshModalTitle,
  FreshButton,
  FreshInput,
  FreshDrop
} from '@components/_fresh';
import './InviteTeamMemberModal.scss';
import { validators } from '@utils';

const b = block('InviteTeamMemberModal');

interface State {
  email: string;
  role: CollabaratorRole;
}

interface InviteTeamMemberModalProps {
  isOpen: boolean;
  data?: {
    id: string;
    email: string;
    role: CollabaratorRole;
  };
  onClose(): void;
}

export const InviteTeamMemberModal: React.FC<InviteTeamMemberModalProps> = (props) => {
  const initialState: State = {
    email: '',
    role: CollabaratorRole.MEMBER
  };

  const { isOpen, data, onClose } = props;
  const dispatch = useAppDispatch();
  const { appId } = useParams<{ appId: string }>();

  const { t } = useTranslation();

  const [isInvalid, setInvalid] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [collaboratorForm, setCollaboratorForm] = useState<State>(
    data
      ? {
        email: data.email,
        role: data.role
      }
      : initialState
  );

  useEffect(() => {
    if (data) {
      setCollaboratorForm({
        email: data.email,
        role: data.role
      });
    }
  }, [data]);

  useEffect(() => {
    if (!validators.email(collaboratorForm.email)) {
      setInvalid(false);
    } else {
      setInvalid(true);
    }

    if (collaboratorForm.email.trim().length !== 0 && !validators.email(collaboratorForm.email)) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [collaboratorForm]);

  const handleChangeEmail = ({ value }: { value: string }) => {
    setCollaboratorForm({ ...collaboratorForm, email: value });
  };

  const collaboratorsRoleMap = {
    [CollabaratorRole.OWNER]: t('dashboard.team.appOwner'),
    [CollabaratorRole.ADMIN]: t('dashboard.team.appAdmin'),
    [CollabaratorRole.MEMBER]: t('dashboard.team.appMember')
  };

  const accessibleCollaboratorsRoleMap = {
    [CollabaratorRole.ADMIN]: t('dashboard.team.appAdmin'),
    [CollabaratorRole.MEMBER]: t('dashboard.team.appMember')
  };

  const handleChangeRole = (role: CollabaratorRole) => {
    setCollaboratorForm({ ...collaboratorForm, role });
  };

  const handleAddUser = () => {
    dispatch(
      fetchCreateCollaborator({
        appId,
        email: collaboratorForm.email,
        role: collaboratorForm.role
      })
    );

    setCollaboratorForm(initialState);
    onClose();
  };

  const handleEditUser = () => {
    if (!data) {
      return;
    }

    dispatch(
      fetchUpdateCollaborator({
        appId,
        collabolatorId: data.id,
        role: collaboratorForm.role
      })
    );

    setCollaboratorForm(initialState);
    onClose();
  };

  return (
    <>
      <FreshModal
        isOpen={isOpen}
        onClose={() => {
          setCollaboratorForm(initialState);
          onClose();
        }}
      >
        <div className={b()}>
          <FreshModalTitle>{t('dashboard.modals.inviteTeamMember.inviteUser')}</FreshModalTitle>
          <FormGroup>
            <FormLabel invalid={isInvalid} isBlack={!data}>
              {t('dashboard.modals.inviteTeamMember.enterEmail')}
            </FormLabel>
            <FreshInput
              className={b('input', {
                disabled: !!data?.email
              })}
              fieldName="email"
              invalid={isInvalid}
              isDisabled={!!data?.email}
              placeholder={t('dashboard.modals.inviteTeamMember.enterEmail')}
              type="text"
              value={collaboratorForm.email}
              onChange={handleChangeEmail}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel isBlack>{t('dashboard.modals.inviteTeamMember.chooseRole')}</FormLabel>
            <FreshDrop
              control={
                <FreshInput
                  isDisabled
                  rightIcon={IconSelectArrowFresh}
                  type="text"
                  value={collaboratorsRoleMap[collaboratorForm.role]}
                />
              }
            >
              <div className={b('drop')}>
                {Object.keys(accessibleCollaboratorsRoleMap).map((key) => (
                  <div
                    className={b('dropItem')}
                    key={key}
                    onClick={() => handleChangeRole(key as CollabaratorRole)}
                  >
                    <span>{collaboratorsRoleMap[key as CollabaratorRole]}</span>
                  </div>
                ))}
              </div>
            </FreshDrop>
          </FormGroup>
          <FormGroup>
            <div className={b('desc')}>{t('dashboard.modals.inviteTeamMember.membersText')}</div>
          </FormGroup>
          <FormGroup className={b('lastDesc').toString()}>
            <div className={b('desc')}>{t('dashboard.modals.inviteTeamMember.adminsText')}</div>
          </FormGroup>

          <FreshButton
            className={b('btn')}
            disabled={buttonDisabled}
            text={
              data
                ? t('dashboard.modals.inviteTeamMember.editRole')
                : t('dashboard.modals.inviteTeamMember.inviteUser')
            }
            onClick={data ? handleEditUser : handleAddUser}
          />
        </div>
      </FreshModal>
    </>
  );
};
