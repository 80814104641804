import React from 'react';
import cn from 'classnames';
import block from 'bem-cn';
import { FormGroup as FormGroupComponent } from 'reactstrap';
import './FormGroup.scss';

type PropsType = {
  className?: string;
  children?: Array<React.ReactNode> | React.ReactNode;
  [key: string]: any;
};

const b = block('FormGroup');

export const FormGroup = ({ className = '', children, ...props }: PropsType) => {
  const groupClasses = cn(b().toString(), className);

  return (
    <FormGroupComponent {...props} className={groupClasses}>
      {children}
    </FormGroupComponent>
  );
};
