import { GroupsType, StoriesGroup } from '@features';
import { RootState } from '@store';
import { useSelector } from 'react-redux';

export const useGroup = (groupId: string, type: GroupsType): StoriesGroup | undefined => {
  const groupList = useSelector((store: RootState) => store.groups);
  const onboardingList = useSelector((store: RootState) => store.onboardings);

  const list = type === GroupsType.GROUP ? groupList.groups : onboardingList.onboardings;
  const storiesGroups = list.find((item: StoriesGroup) => item.id === groupId);

  return storiesGroups;
};
