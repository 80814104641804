import React, { useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@store';
import { fetchCancelSubscription } from '@features';
import { FreshButton, FreshModal, FreshModalTitle } from '@components/_fresh';
import { Drop } from '@custom';
import { Icon } from '@components';
import './SubscriptionStatusModal.scss';

interface Props {
  isOpen: boolean;
  subscriptionId: string;
  onClose(): void;
}

export const SubscriptionStatusModal: React.FC<Props> = (props) => {
  const { isOpen, subscriptionId, onClose } = props;

  const b = block('SubscriptionStatusModal');
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useAppDispatch();

  const statuses = {
    active: t('dashboard.billing.active'),
    cancel: t('dashboard.billing.cancel')
  };

  const [currentStatus, setCurrentStatus] = useState<'active' | 'cancel'>('active');

  const control = (
    <div className={b('selector')}>
      <p className={b('title')}>{statuses[currentStatus]}</p>

      <Icon className={b('chevron').toString()} name="chevron-down-fresh" variant="icons" />
    </div>
  );

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle centered>
          {t('dashboard.billing.changeSubsriptionStatusModalTitle')}
        </FreshModalTitle>

        <Drop className={b('dropdown').toString()} control={control}>
          <div className={b('menu')}>
            <div
              className={b('menuContentItem')}
              onClick={() => {
                setCurrentStatus('active');
              }}
            >
              <p className={b('menuContentItemTitle')}>{t('dashboard.billing.active')}</p>
            </div>
            <div
              className={b('menuContentItem')}
              onClick={() => {
                setCurrentStatus('cancel');
              }}
            >
              <p className={b('menuContentItemTitle')}>{t('dashboard.billing.cancel')}</p>
            </div>
          </div>
        </Drop>

        {currentStatus === 'cancel' && (
          <div className={b('cancelInfo')}>
            <p className={b('cancelInfoText')}>{t('dashboard.billing.cancelText')}</p>
          </div>
        )}

        <FreshButton
          className={b('btn').toString()}
          disabled={isUpdating}
          text={isUpdating ? t('dashboard.billing.updating') : t('dashboard.billing.updateStatus')}
          onClick={() => {
            if (currentStatus === 'cancel') {
              setIsUpdating(true);
              dispatch(
                fetchCancelSubscription({
                  subscriptionId
                })
              ).then(() => {
                setIsUpdating(false);
                onClose();
              });
            }
          }}
        />
      </div>
    </FreshModal>
  );
};
