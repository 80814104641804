import React from 'react';
import PropTypes from 'prop-types';

const IconChart = ({ className }) => (
  <svg
    className={className}
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.375.75a.625.625 0 00-.625.625v13.934c0 1.06.88 1.941 1.941 1.941h13.934a.625.625 0 100-1.25H3a1 1 0 01-1-1V1.375A.625.625 0 001.375.75z" />
    <path d="M16.28 8.43a.48.48 0 00-.69-.121l-3.332 2.506a1 1 0 01-1.3-.084l-1.547-1.51a1 1 0 00-1.405.008L3.53 13.704a.502.502 0 00.708.712L8.01 10.69a1 1 0 011.396-.01l1.436 1.382a1 1 0 001.299.076l4.027-3.063a.48.48 0 00.112-.645zM8.552 5.732l-3.8 3.665a.54.54 0 11-.746-.781L8.68 4.19a1 1 0 011.46.092l.88 1.071a1 1 0 001.383.157l1.934-1.494a.543.543 0 11.66.864l-2.744 2.067a1 1 0 01-1.35-.135l-.908-1.024a1 1 0 00-1.443-.056z" />
  </svg>
);

IconChart.propTypes = {
  className: PropTypes.string
};

IconChart.defaultProps = {
  className: ''
};

export default IconChart;
