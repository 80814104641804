import React, { memo, useMemo } from 'react';
import { ColorPicker, SettingsBox, DropIcon, FontSizeIcon } from '@modules';
import {
  ControlGroup,
  SettingsInputText,
  SettingsSelect,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features/stories/editorSettings/components';
import { FONT_STYLES, FONT_SIZE_LIMITS } from '@features/stories/editorSettings/constants';
import { getChangedOpacityColor, getOpacityFromColor } from '@modules/EditorModule/utils';
import { SettingsFontSelector } from '@features/stories/editorSettings/components/SettingsFontSelector/SettingsFontSelector';
import { useTranslation } from 'react-i18next';
import {
  BackgroundColorType,
  SwipeUpWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { Icon, IconResize } from '@components';
import { MaterialIconsPicker } from '../../_components/MaterialIconsPicker';

type PropsType = {
  params: SwipeUpWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangePosition(params: WidgetPositionType, stopSave?: boolean): void;
  onChange(params: SwipeUpWidgetParamsType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
};

export const SwipeUpWidgetSettings = memo(
  ({ params, position, positionLimits, onChangeAlign, onChangePosition, onChange }: PropsType) => {
    const { t } = useTranslation();

    const handleChange = (field: string) => (value: any) => {
      const newParams = {
        ...params,
        [field]: value
      };

      onChange(newParams);
    };

    const handleChangePosition = (field: string) => (value: any) => {
      onChangePosition(
        {
          ...position,
          realWidth: field === 'width' ? value : position.realWidth,
          [field]: value
        },
        field !== 'width' && field !== 'height'
      );
    };

    const handleChangeOpacity = (fieldName: 'color') => (value: number) => {
      if (params[fieldName].type === 'color') {
        handleChange(fieldName)({
          type: 'color',
          value: getChangedOpacityColor(params[fieldName].value as string, value)
        });
      }
    };

    const colorOpacity = useMemo(() => {
      if (params.color.type === 'color') {
        return getOpacityFromColor(params.color.value);
      }

      return 100;
    }, [params.color]);

    return (
      <>
        <SettingsBox.Group>
          <SettingsBox.Field>
            <SettingsAlign onChange={onChangeAlign} />
          </SettingsBox.Field>
        </SettingsBox.Group>
        <SettingsBox.Group title={t('editor.position')}>
          <SettingsBox.Field>
            <ControlGroup>
              <SettingsInput
                postfix="px"
                prefix="X"
                type="number"
                value={Math.round(position.x)}
                onChange={handleChangePosition('x')}
              />
              <SettingsInput
                postfix="px"
                prefix="Y"
                type="number"
                value={Math.round(position.y)}
                onChange={handleChangePosition('y')}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup isLocked={position.isHeightLocked}>
              <SettingsInput
                max={positionLimits.maxWidth}
                min={positionLimits.minWidth}
                postfix="px"
                prefix="W"
                type="number"
                value={position.width}
                onChange={handleChangePosition('width')}
              />
              <SettingsInput
                disabled={position.isHeightLocked}
                postfix="px"
                prefix="H"
                type="number"
                value={position.realHeight}
                onChange={handleChangePosition('height')}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field separate>
            <SettingsInput
              icon={<Icon name="angle" />}
              max={MAX_ANGLE_VALUE}
              min={MIN_ANGLE_VALUE}
              postfix="°"
              type="number"
              value={position.rotate ? Math.round(position.rotate) : 0}
              onChange={handleChangePosition('rotate')}
            />
          </SettingsBox.Field>
        </SettingsBox.Group>
        <SettingsBox.Group title={t('editor.text')}>
          <SettingsBox.Field>
            <SettingsFontSelector value={params.fontFamily} onChange={handleChange('fontFamily')} />
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup sub>
              <SettingsSelect
                options={FONT_STYLES}
                value={params.fontParams}
                onChange={handleChange('fontParams')}
              />
              <SettingsInput
                icon={<FontSizeIcon />}
                max={FONT_SIZE_LIMITS.max}
                min={FONT_SIZE_LIMITS.min}
                type="number"
                value={params.fontSize}
                onChange={handleChange('fontSize')}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup sub>
              <ColorPicker
                availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
                value={params.color}
                onChange={handleChange('color')}
              />
              <SettingsInput
                icon={<DropIcon />}
                max={100}
                type="number"
                value={colorOpacity}
                onChange={handleChangeOpacity('color')}
              />
            </ControlGroup>
          </SettingsBox.Field>
        </SettingsBox.Group>

        <SettingsBox.Group title={t('editor.icon')}>
          <ControlGroup>
            <MaterialIconsPicker
              value={params.icon}
              viewType="group"
              onChange={handleChange('icon')}
            />
            <SettingsInput
              grow
              icon={<IconResize />}
              max={200}
              min={10}
              postfix="px"
              type="number"
              value={params.iconSize}
              onChange={handleChange('iconSize')}
            />
          </ControlGroup>
        </SettingsBox.Group>

        <SettingsBox.Group title={t('editor.link')}>
          <SettingsInputText
            icon="url"
            name="url"
            placeholder={t('editor.swipeUpPlaceholder')}
            value={params.url}
            onChange={(fieldName, value) => handleChange(fieldName)(value)}
          />
        </SettingsBox.Group>
      </>
    );
  }
);

SwipeUpWidgetSettings.displayName = 'SwipeUpWidgetDropdown';
