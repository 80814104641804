import React from 'react';

type PropsType = {
  className?: string;
};

export const TextIcon = ({ className = '' }: PropsType) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#AFAFAF"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 12.4998L12.9167 6.6665"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M15.8334 12.4998L12.9167 6.6665"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M10.5833 11.3332H15.2499"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M9.16675 3.3335H8.33342C7.41258 3.3335 6.66675 4.07933 6.66675 5.00016C6.66675 4.07933 5.92091 3.3335 5.00008 3.3335H4.16675"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M9.16675 16.6667H8.33342C7.41258 16.6667 6.66675 15.9208 6.66675 15C6.66675 15.9208 5.92091 16.6667 5.00008 16.6667H4.16675"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path d="M6.66667 15V5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </svg>
);
