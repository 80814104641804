import React from 'react';
import PropTypes from 'prop-types';

const IconResize = ({ className }) => (
  <svg
    className={className}
    fill="white"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3601_1102)">
      <path
        clipRule="evenodd"
        d="M0 14H5.13327V13.0667H1.59329L13.0667 1.59329V5.13327H14V0H8.86673V0.933265H12.4067L0.933266 12.4067V8.86673H0V14Z"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_3601_1102">
        <rect height="14" transform="matrix(-1 0 0 1 14 0)" width="14" />
      </clipPath>
    </defs>
  </svg>
);

IconResize.propTypes = {
  className: PropTypes.string
};

IconResize.defaultProps = {
  className: ''
};

export default IconResize;
