export * from './fontFamilies';
export * from './fontStyles';

export const FONT_SIZE_LIMITS = {
  min: 1,
  max: 120
};

export const FONT_LINE_HEIGHT_LIMITS = {
  min: 1,
  max: 1000
};
