import { RootState, useAppDispatch } from '@store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchTemplates } from '@features/storyTemplates';
import { GroupsType, Templates, fetchOnboardings } from '@features';
import { fetchGroups } from '../groupsSlice';

interface UseFetchGroupsProps {
  appId?: string;
  type?: GroupsType;
  templateCategory?: Templates.Category;
}

export const useFetchGroups = ({ appId, type, templateCategory }: UseFetchGroupsProps) => {
  const dispatch = useAppDispatch();

  const appsStatus = useSelector((store: RootState) => store.appManager.status);
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);

  useEffect(() => {
    if (!type || !currentLocale) return;

    if (appsStatus === 'loaded' && appId) {
      if (type === GroupsType.GROUP) {
        dispatch(fetchGroups({ appId }));
      } else if (type === GroupsType.ONBOARDING) {
        dispatch(fetchOnboardings({ appId }));
      }
    } else if (type === GroupsType.TEMPLATE && templateCategory) {
      dispatch(
        fetchTemplates({
          category: templateCategory
        })
      );
    }
  }, [appsStatus, appId, dispatch, type, templateCategory, currentLocale]);
};
