import { useGetUserSubscription } from '@hooks';
import { AccesibleFeaturesByPlan, AppFeatures } from '../consts';
import { AppPlan } from '../types';

export const useGetAccessByFeature = ({ feature }: { feature: AppFeatures }) => {
  const subscription = useGetUserSubscription();

  let plan = subscription.plan;

  if (plan === 'Startup' || plan === 'Business') {
    plan = AppPlan.STARTUP;
  } else if (plan === 'Indie') {
    plan = AppPlan.INDIE;
  }

  if (AccesibleFeaturesByPlan[plan as AppPlan][feature]) {
    return true;
  }

  return false;
};
