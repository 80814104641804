export const FONT_FAMILIES = [
  {
    value: '-apple-system, BlinkMacSystemFont, sans-serif',
    family: 'San Francisco'
  },
  {
    value: 'sans-serif',
    family: 'SF Compact Rounded'
  }
];
