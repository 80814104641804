import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { AppPlan, UserRole, UserSubscriptionStatus } from '@features/user/types';
import { useGetCurrentApp } from '@features/stories/hooks';
import { useEffect, useState } from 'react';

interface UserSubscription {
  userId: string;
  subscriptionId: string;
  plan: AppPlan | string;
  trialLeft: number;
  subscriptionLeft: number;
  isTrialing: boolean;
  isSubscribed: boolean;
  isUnpaid: boolean;
  isLoaded: boolean;
  isAppOwner: boolean;
  subscriptionStatus?: UserSubscriptionStatus;
  storiesCount: number;
  publishedStoriesCount: number;
  collaboratorsCount: number;
}

export const useGetUserSubscription = () => {
  const user = useSelector((store: RootState) => store.user.user);
  const currentApp = useGetCurrentApp();

  const [userSubcription, setUserSubcription] = useState<UserSubscription>({
    userId: '',
    subscriptionId: '',
    plan: AppPlan.FREE,
    trialLeft: 0,
    subscriptionLeft: 0,
    storiesCount: 0,
    publishedStoriesCount: 0,
    collaboratorsCount: 0,
    isTrialing: false,
    isSubscribed: false,
    isUnpaid: false,
    isLoaded: false,
    isAppOwner: false,
    subscriptionStatus: UserSubscriptionStatus.INCOMPLETE
  });

  useEffect(() => {
    if (user.id && user.role === UserRole.ADMIN) {
      setUserSubcription({
        userId: user.id,
        subscriptionId: currentApp?.subscriptionId || '',
        plan: AppPlan.STARTUP,
        trialLeft: 0,
        subscriptionLeft: 0,
        collaboratorsCount: currentApp?.collaboratorsCount || 0,
        storiesCount: currentApp?.storiesCount || 0,
        publishedStoriesCount: currentApp?.publishedStoriesCount || 0,
        isTrialing: false,
        isSubscribed: true,
        isUnpaid: false,
        isLoaded: true,
        isAppOwner: currentApp?.ownerId === user.id,
        subscriptionStatus: UserSubscriptionStatus.ACTIVE
      });
    } else if (user.id && currentApp?.plan === AppPlan.FREE) {
      setUserSubcription({
        userId: user.id,
        subscriptionId: currentApp?.subscriptionId || '',
        plan: AppPlan.FREE,
        trialLeft: 0,
        subscriptionLeft: 0,
        collaboratorsCount: currentApp?.collaboratorsCount || 0,
        storiesCount: currentApp?.storiesCount || 0,
        publishedStoriesCount: currentApp?.publishedStoriesCount || 0,
        isTrialing: false,
        isSubscribed: true,
        isUnpaid: false,
        isLoaded: true,
        isAppOwner: currentApp?.ownerId === user.id,
        subscriptionStatus: UserSubscriptionStatus.ACTIVE
      });
    } else if (user.id && currentApp?.plan) {
      const trialLeft = currentApp?.trialEnd ? (currentApp.trialEnd * 1000 - Date.now()) / 1000 : 0;
      const subscriptionLeft = currentApp?.subscriptionEnd
        ? (currentApp.subscriptionEnd * 1000 - Date.now()) / 1000
        : 0;

      let userPlan = currentApp.plan;

      const isTrialExpired = trialLeft <= 0;
      const isSubscriptionExpired = subscriptionLeft <= 0;

      const isUnpaid =
        currentApp.subscriptionStatus === UserSubscriptionStatus.INCOMPLETE ||
        currentApp.subscriptionStatus === UserSubscriptionStatus.UNPAID ||
        currentApp.subscriptionStatus === UserSubscriptionStatus.INCOMPLETE_EXPIRED ||
        currentApp.subscriptionStatus === UserSubscriptionStatus.PAST_DUE;
      if (
        ((currentApp.subscriptionStatus === UserSubscriptionStatus.TRIALING && isTrialExpired) ||
          (currentApp.subscriptionStatus === UserSubscriptionStatus.ACTIVE &&
            isSubscriptionExpired) ||
          isUnpaid) &&
        user.role === UserRole.MEMBER
      ) {
        userPlan = AppPlan.FREE;
      }

      setUserSubcription({
        userId: user.id,
        plan: userPlan,
        subscriptionId: currentApp?.subscriptionId || '',
        storiesCount: currentApp?.storiesCount || 0,
        publishedStoriesCount: currentApp?.publishedStoriesCount || 0,
        collaboratorsCount: currentApp?.collaboratorsCount || 0,
        isTrialing:
          (currentApp.subscriptionStatus === UserSubscriptionStatus.TRIALING &&
            currentApp.isStartTrial) ||
          false,
        trialLeft,
        subscriptionLeft,
        isUnpaid,
        isSubscribed:
          currentApp.subscriptionStatus === UserSubscriptionStatus.TRIALING ||
          currentApp.subscriptionStatus === UserSubscriptionStatus.ACTIVE,
        isLoaded: true,
        isAppOwner: currentApp.ownerId === user.id,
        subscriptionStatus: currentApp.subscriptionStatus
      });
    }
  }, [user, currentApp]);

  return userSubcription;
};
