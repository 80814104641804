import React, { memo, useMemo, useCallback } from 'react';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import { BackgroundType } from '@storysdk/react';
import { MATERIAL_ICONS } from './_constants';
import { MaterialIconChangeType } from './_types';
import './MaterialIcon.scss';

const CLASS_NAME = 'MaterialIcon';

type PropsType = {
  name: string;
  size?: number | string;
  className?: string;
  color?: string;
  background?: BackgroundType;
  onClick?: MaterialIconChangeType;
};

export const MaterialIcon = memo(
  ({
    name = 'ArrowCircleUpOutlineIcon',
    className,
    color = '#ffffff',
    background,
    size = 'auto',
    onClick
  }: PropsType) => {
    const Icon = useMemo(() => MATERIAL_ICONS[name], [name]);

    const handleSelect = useCallback(() => {
      if (onClick) {
        onClick({ name });
      }
    }, [name, onClick]);

    let gradient;
    const gradientId = useMemo(() => nanoid(), []);

    if (background?.type === 'gradient') {
      gradient = (
        <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={background.value[0]} />
          <stop offset="100%" stopColor={background.value[1]} />
        </linearGradient>
      );
    }

    if (Icon) {
      return (
        <span
          className={cn(`${CLASS_NAME}`, className)}
          role="button"
          style={{ width: size, height: size }}
          tabIndex={0}
          onClick={handleSelect}
          onKeyDown={handleSelect}
        >
          <Icon color={color} gradient={gradient} gradientId={gradientId} />
        </span>
      );
    }

    return null;
  }
);
