import React from 'react';
import PropTypes from 'prop-types';

const IconACircle = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={20}
    viewBox="0 0 20 20"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={10} cy={10} r={9.5} stroke="#18182E" />
    <path
      d="M7.8 14h-.923l2.67-7.273h.91L13.126 14h-.924l-.731-2.06h-2.94L7.8 14zm1.009-2.84h2.386L10.03 7.877h-.057L8.81 11.159z"
      fill="#18182E"
    />
  </svg>
);

IconACircle.propTypes = {
  className: PropTypes.string
};

IconACircle.defaultProps = {
  className: ''
};

export default IconACircle;
