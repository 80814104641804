import React from 'react';
import PropTypes from 'prop-types';

const IconIphoneSmallBlack = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8489 14.1135H4.98084C4.83278 14.1135 4.71338 13.9941 4.71338 13.8461V2.14929C4.71338 2.00124 4.83278 1.88184 4.98084 1.88184H11.8441C11.9922 1.88184 12.1116 2.00124 12.1116 2.14929V13.8461C12.1116 13.9941 11.9922 14.1135 11.8489 14.1135Z"
      opacity="0.2"
    />
    <path
      clipRule="evenodd"
      d="M5.31505 16H11.5145C12.1019 16 12.5795 15.5224 12.5795 14.9302V1.06505C12.5795 0.477614 12.1019 0 11.5145 0H5.31505C4.72761 0 4.25 0.477614 4.25 1.06505V14.935C4.25 15.5224 4.72761 16 5.31505 16ZM4.981 14.1135H11.8491C11.9923 14.1135 12.1118 13.9941 12.1118 13.8461V2.14929C12.1118 2.00124 11.9924 1.88184 11.8443 1.88184H4.981C4.83295 1.88184 4.71354 2.00124 4.71354 2.14929V13.8461C4.71354 13.9941 4.83295 14.1135 4.981 14.1135ZM8.4127 15.5712C8.05364 15.5712 7.7627 15.2802 7.7627 14.9212C7.7627 14.5621 8.05363 14.2712 8.4127 14.2712C8.77176 14.2712 9.0627 14.5621 9.0627 14.9212C9.0627 15.2802 8.77176 15.5712 8.4127 15.5712ZM7.47813 0.953333H9.34904C9.50601 0.953333 9.57976 1.05326 9.57942 1.17667C9.57942 1.30007 9.50601 1.4 9.34904 1.4H7.47813C7.32116 1.4 7.24609 1.30007 7.24609 1.17667C7.24609 1.05326 7.32116 0.953333 7.47813 0.953333Z"
      fillRule="evenodd"
    />
  </svg>
);

IconIphoneSmallBlack.propTypes = {
  className: PropTypes.string
};

IconIphoneSmallBlack.defaultProps = {
  className: ''
};

export default IconIphoneSmallBlack;
