import React from 'react';
import PropTypes from 'prop-types';

const IconMore = ({ className }) => (
  <svg
    className={className}
    height={24}
    viewBox="0 0 24 24"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 14a2 2 0 100-4 2 2 0 000 4zM12 14a2 2 0 100-4 2 2 0 000 4zM19 14a2 2 0 100-4 2 2 0 000 4z" />
  </svg>
);

IconMore.propTypes = {
  className: PropTypes.string
};

IconMore.defaultProps = {
  className: ''
};

export default IconMore;
