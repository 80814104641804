import { StoryLayers } from '@features/stories';
import { GroupType } from '@storysdk/react';

export * from './TemplateStoriesState';

export namespace Templates {
  export enum Category {
    ONBOARDING = 'onboarding',
    STORIES = 'stories'
  }
}

export interface StoriesTemplateBase {
  id: string;
  title: string;
  image: string;
  fileId?: string;
  description: string;
  stories?: StoryLayers;
  category: Templates.Category;
  type: GroupType;
  active: boolean;
  settings?: any;
  templateData?: any;
}
