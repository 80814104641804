import React from 'react';
import { EditGroupContainer, StoriesGroup } from '@features';
import { FreshModal } from '@components/_fresh';

interface EditGroupModalProps {
  isOpen: boolean;
  currentAppId?: string;
  onClose(): void;
  group?: StoriesGroup;
}

export const EditGroupModal: React.FC<EditGroupModalProps> = (props) => {
  const { isOpen, currentAppId, group, onClose } = props;

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <EditGroupContainer currentAppId={currentAppId} group={group} onClose={onClose} />
    </FreshModal>
  );
};
