import React, { useCallback } from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import './FreshDropInput.scss';
import { Icon } from '@components/Icon';

type PropsType = {
  className?: string;
  value: any;
};

const b = block('FreshDropInput');

export const FreshDropInput = ({ className, value }: PropsType) => (
  <div className={cn(b().toString(), className)}>
    {value}
    <Icon className={b('icon').toString()} name="chevron-down-fresh" variant="icons" />
  </div>
);
