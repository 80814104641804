import React from 'react';
import block from 'bem-cn';
import { STORY_WIDTH, Story, StoryPreview } from '@features';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { IconMoreSquare } from '@components';
import './StoryAnalyticsGeneral.scss';

const b = block('StoryAnalyticsGeneral');

interface StoryAnalyticsGeneralProps {
  story: Story;
  isLoading?: boolean;
  onMoreClick?(): void;
}

const CARD_WIDTH = 68;

export const StoryAnalyticsGeneral: React.FC<StoryAnalyticsGeneralProps> = ({
  story,
  isLoading,
  onMoreClick
}) => {
  const { t } = useTranslation();

  const scaleIndex = CARD_WIDTH / STORY_WIDTH;

  if (isLoading) {
    return (
      <div className={b()}>
        <div className={b('previewContainer', { isLoading: true })}>
          <Skeleton height="100%" />
        </div>
        <div className={b('analytics')}>
          <div className={b('analyticsItem')}>
            <Skeleton height={20} width={160} />
          </div>
          <div className={b('analyticsItem')}>
            <Skeleton height={20} width={160} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={b()}>
      <div className={b('previewContainer')}>
        <StoryPreview className={b('story')} scaleIndex={scaleIndex} story={story} />
        <div className={b('previewMoreBtn')} onClick={onMoreClick}>
          <IconMoreSquare className={b('previewMoreBtnIcon').toString()} />
          <p className={b('previewMoreBtnText')}>{t('dashboard.analytics.more')}</p>
        </div>
      </div>
      <div className={b('analytics')}>
        <div className={b('analyticsItem')}>
          <p className={b('analyticsItemTitle')}>{t('dashboard.analytics.views')}</p>
          <p className={b('analyticsItemCount')}>{story.statistic?.views || 0}</p>
        </div>
        <div className={b('analyticsItem')}>
          <p className={b('analyticsItemTitle')}>{t('dashboard.analytics.interactions')}</p>
          <p className={b('analyticsItemCount')}>{story.statistic?.actions || 0}</p>
        </div>
      </div>
    </div>
  );
};
