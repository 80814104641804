import React, { useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { FileDrop } from 'react-file-drop';
import { useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import {
  informSlice,
  KB_IN_MB,
  MAX_FILE_SIZE_KB,
  MAX_FILE_SIZE_MB,
  MAX_VIDEO_DURATION_SEC,
  MAX_VIDEO_SIZE_KB,
  MAX_VIDEO_SIZE_MB,
  MessageTypes
} from '@features';
import { BackgroundFillType, MediaType } from '@storysdk/react';
import { Icon, IconUploadArrow } from '@components';
import './MediaLoader.scss';

const b = block('MediaLoader');

interface Props {
  availableTypes: BackgroundFillType[];
  value: string | null;
  onLoad(type: MediaType, file: Blob | string, metadata?: any): void;
}

const MediaLoader: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();

  const { onLoad, value, availableTypes } = props;
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [image, setImage] = React.useState<string | null>(value);

  const { t } = useTranslation();

  useEffect(() => {
    setImage(value);
  }, [value]);

  const inputChangeHandler = (files?: FileList | null) => {
    if (fileInputRef.current) {
      const currentFiles = files ?? fileInputRef.current.files;

      if (currentFiles && currentFiles.length) {
        if (
          currentFiles[0] &&
          currentFiles[0].size / KB_IN_MB >
          (currentFiles[0].type.includes('video') ? MAX_VIDEO_SIZE_KB : MAX_FILE_SIZE_KB)
        ) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.WARN,
              text: `${t('errors.fileSize')} ${currentFiles[0].type.includes('video') ? MAX_VIDEO_SIZE_MB : MAX_FILE_SIZE_MB
                }MB.`
            })
          );
        } else if (
          currentFiles[0].type.includes('image') &&
          availableTypes?.includes(MediaType.IMAGE)
        ) {
          onLoad(MediaType.IMAGE, currentFiles[0]);
        } else if (
          currentFiles[0].type.includes('video') &&
          availableTypes?.includes(MediaType.VIDEO)
        ) {
          if (setImage) {
            setImage(null);
          }
          const file = currentFiles[0];
          const fileURL = URL.createObjectURL(file);
          const video = document.createElement('video');
          video.src = fileURL;
          video.onloadedmetadata = () => {
            const duration = video.duration;

            if (duration > MAX_VIDEO_DURATION_SEC) {
              dispatch(
                informSlice.actions.addMessage({
                  type: MessageTypes.WARN,
                  text: `${t('errors.videoDuration')} ${MAX_VIDEO_DURATION_SEC} sec.`
                })
              );
            } else {
              onLoad(MediaType.VIDEO, file, { duration });
            }

            video.remove();
          };
        }

        fileInputRef.current.value = '';
      }
    }
  };

  const onTargetClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const acceptedTypes = useMemo(() => {
    let types = '';

    if (availableTypes?.includes(MediaType.IMAGE)) {
      types += 'image/jpeg,image/png,image/gif,image/webp';

      if (availableTypes?.includes(MediaType.VIDEO)) {
        types += ',';
      }
    }

    if (availableTypes?.includes(MediaType.VIDEO)) {
      types += 'video/mp4,video/x-m4v,video/webm,video/ogg,video/ogv,video/avi';
    }

    return types;
  }, [availableTypes]);

  return (
    <div className={b()}>
      {image ? (
        <div className={b('img')} style={{ backgroundImage: `url("${image}")` }}>
          <button className={b('reset')} onClick={onTargetClick}>
            <Icon className={b('iconEdit').toString()} name="upload" variant="editor" />
          </button>
        </div>
      ) : (
        <FileDrop
          className={b('fileDrop').toString()}
          onDrop={inputChangeHandler}
          onTargetClick={onTargetClick}
        >
          <div className={b('fileDropTarget')}>
            <IconUploadArrow className={b('icon').toString()} />
            <div className={b('title')}>
              {t('editor.dragAndDrop')}{' '}
              <span className={b('fileInputControl')}>{t('editor.chooseFile')}</span>
            </div>
          </div>
        </FileDrop>
      )}
      <input
        accept={acceptedTypes}
        className={b('fileInput')}
        hidden
        ref={fileInputRef}
        type="file"
        onChange={() => inputChangeHandler()}
      />
    </div>
  );
};

export default MediaLoader;
