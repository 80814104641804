import React from 'react';
import PropTypes from 'prop-types';

const IconWarning = ({ className }) => (
  <svg
    className={className}
    fill="#A49BA4"
    height="12"
    viewBox="0 0 14 12"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.318182 12L7.05966 0.545454L13.8011 12H0.318182ZM7.05966 10.6825C7.3215 10.6825 7.54522 10.5897 7.73082 10.4041C7.91974 10.2152 8.0142 9.98982 8.0142 9.72798C8.0142 9.46615 7.91974 9.24242 7.73082 9.05682C7.54522 8.87121 7.3215 8.77841 7.05966 8.77841C6.79782 8.77841 6.57244 8.87121 6.38352 9.05682C6.19792 9.24242 6.10511 9.46615 6.10511 9.72798C6.10511 9.98982 6.19792 10.2152 6.38352 10.4041C6.57244 10.5897 6.79782 10.6825 7.05966 10.6825ZM6.36364 7.71449H7.75568L7.83523 4.36364H6.28409L6.36364 7.71449Z" />
  </svg>
);

IconWarning.propTypes = {
  className: PropTypes.string
};

IconWarning.defaultProps = {
  className: ''
};

export default IconWarning;
