import React from 'react';
import PropTypes from 'prop-types';

const IconCheckCircleSmallFilled = ({ className }) => (
  <svg
    className={className}
    fill="white"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M1 7C1 10.314 3.686 13 7 13C10.314 13 13 10.314 13 7C13 3.686 10.314 1 7 1C3.686 1 1 3.686 1 7ZM10.0199 6.02023C10.2152 5.82496 10.2152 5.50838 10.0199 5.31312C9.82463 5.11786 9.50805 5.11786 9.31279 5.31312L6.33301 8.2929L4.68656 6.64645C4.4913 6.45119 4.17472 6.45119 3.97945 6.64645C3.78419 6.84171 3.78419 7.1583 3.97945 7.35356L5.97945 9.35356C6.17472 9.54882 6.4913 9.54882 6.68656 9.35356L10.0199 6.02023Z"
      fillRule="evenodd"
    />
  </svg>
);

IconCheckCircleSmallFilled.propTypes = {
  className: PropTypes.string
};

IconCheckCircleSmallFilled.defaultProps = {
  className: ''
};

export default IconCheckCircleSmallFilled;
