import React from 'react';
import { GroupsType, StoriesGroup } from '@features/groups/types';
import block from 'bem-cn';
import { getGroupImage } from '@features/stories';
import { useAppDispatch } from '@store';
import { AppGroupStyle, groupsSlice } from '@features';
import { GroupItem as GroupSdkItem } from '@storysdk/react';
import { IconPencil } from '@components';
import { useIsMobile } from '@hooks';
import './GroupListItem.scss';

interface GroupListItemProps {
  group: StoriesGroup;
  groupView?: AppGroupStyle;
  groupId?: string;
  hoveringGroup: string | null;
  isActive?: boolean;
  onSelect?: (groupId: string) => void;
  setHoveringGroup?: (groupId: string | null) => void;
}

const b = block('GroupListItem');

export const GroupListItem: React.FC<GroupListItemProps> = ({
  group,
  groupId,
  hoveringGroup,
  groupView = 'circle',
  isActive,
  onSelect,
  setHoveringGroup
}) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const getSize = () => {
    if (!isMobile) {
      return {
        title: 12
      };
    }

    if (group.type === GroupsType.ONBOARDING) {
      return {
        width: 48,
        height: 48,
        title: 10
      };
    }

    if (groupView === AppGroupStyle.BIG_SQUARE) {
      return {
        width: 66,
        height: 66,
        title: 10
      };
    }

    if (groupView === AppGroupStyle.RECTANGLE) {
      return {
        width: 66,
        height: 83,
        title: 8
      };
    }

    return {
      width: 48,
      height: 48,
      title: 10
    };
  };

  return (
    <div
      className={b()}
      onMouseEnter={() => setHoveringGroup && setHoveringGroup(group.id)}
      onMouseLeave={() => setHoveringGroup && setHoveringGroup(null)}
    >
      <GroupSdkItem
        groupClassName={b('groupItem').toString()}
        groupImageHeight={getSize()?.height ?? undefined}
        groupImageWidth={getSize()?.width ?? undefined}
        groupTitleSize={getSize()?.title ?? undefined}
        imageUrl={getGroupImage(
          group,
          group.type,
          group.image,
          groupId ?? '',
          hoveringGroup ?? undefined
        )}
        index={0}
        isChosen={isActive}
        title={group.title}
        type={group.type as any}
        view={
          group.type === GroupsType.ONBOARDING ? AppGroupStyle.SQUARE : (groupView as AppGroupStyle)
        }
        onClick={() => onSelect && onSelect(group.id)}
      />
      <div
        className={b('editIcon', { visible: hoveringGroup === group.id, view: groupView })}
        onClick={() => {
          dispatch(groupsSlice.actions.setEditGroup(group));
        }}
        onMouseEnter={() => setHoveringGroup && setHoveringGroup(group.id)}
        onMouseLeave={() => setHoveringGroup && setHoveringGroup(null)}
      >
        <IconPencil />
      </div>
    </div>
  );
};
