import { Story } from '@features';

export const getLastStoryPosition = (stories: Story[]): number => {
  let lastPosition = 0;

  const storiesSorted = stories.sort((a, b) => a.position - b.position);

  storiesSorted.forEach((layersGroup) => {
    if (layersGroup.position > lastPosition) {
      lastPosition = layersGroup.position;
    }
  });

  return lastPosition;
};
