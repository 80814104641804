import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { StoryLayers } from '@features/stories';
import { usePrevious } from '@hooks';
import { fetchGetTemplateStories, templateStoriesSlice } from '../templateStoriesSlice';

export const useFetchTemplateStories = ({
  templateId,
  stories,
  stopFetching
}: {
  templateId: string;
  stories?: StoryLayers;
  stopFetching?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);
  const storiesStatus = useSelector((store: RootState) => store.templateStories.status);
  const templatesStatus = useSelector((store: RootState) => store.templates.status);
  const prevLocale = usePrevious(currentLocale);

  useEffect(() => {
    if (stopFetching || !currentLocale) return;

    if (stories) {
      dispatch(templateStoriesSlice.actions.initStories(stories));
    } else if (
      (templatesStatus === 'loaded' && storiesStatus !== 'pending') ||
      (currentLocale && prevLocale && currentLocale !== prevLocale)
    ) {
      dispatch(fetchGetTemplateStories({ templateId, currentLocale }));
    }
  }, [templateId, dispatch, currentLocale, prevLocale, stopFetching, templatesStatus]);
};
