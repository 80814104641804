import React, { SyntheticEvent, useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import './FreshCalendarDayView.scss';
import block from 'bem-cn';

const b = block('FreshCalendarDayView');

type PropsType = {
  day: number;
  disabled: boolean;
  onClick: any;
  theme?: 'dark' | 'light';
  value: any;
  type: 'single' | 'range';
};

export const FreshCalendarDayView = ({
  day,
  disabled,
  onClick,
  theme = 'light',
  value,
  type
}: PropsType) => {
  const isNow = useMemo(
    () => DateTime.fromSeconds(day).toISODate() === DateTime.now().toISODate(),
    [day]
  );

  const handleSelect = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      onClick({ event, value: day });
    },
    [day, onClick]
  );

  return (
    <button
      className={b({
        theme,
        now: isNow,
        first:
          (type === 'single' &&
            !!value &&
            DateTime.fromSeconds(value).toISODate() === DateTime.fromSeconds(day).toISODate()) ||
          (type === 'range' && `${value.from}` === `${day}`),
        last: type === 'range' && DateTime.fromSeconds(value.to).startOf('day').toSeconds() === day,
        select: type === 'range' && value.from <= day && value.to >= day
      })}
      disabled={disabled}
      type="button"
      onClick={handleSelect}
    >
      {DateTime.fromSeconds(day).day}
    </button>
  );
};
