import React from 'react';
import PropTypes from 'prop-types';

const IconView = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M9 3C5 3 2 7 1 9c1 2 4 6 8 6s7-4 8-6c-1-2-4-6-8-6z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        clipRule="evenodd"
        d="M9 12a3 3 0 100-6 3 3 0 000 6zm1-3a1 1 0 100-2 1 1 0 000 2z"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

IconView.propTypes = {
  className: PropTypes.string
};

IconView.defaultProps = {
  className: ''
};

export default IconView;
