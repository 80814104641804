import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshModal, FreshModalTitle, FreshButton } from '@components/_fresh';

import './DeleteAppModal.scss';

interface Props {
  isOpen: boolean;
  onClose(): void;
  title: string;
  handleDeleteApp(): void;
}

export const DeleteAppModal: React.FunctionComponent<Props> = (props) => {
  const { isOpen, onClose, handleDeleteApp, title } = props;

  const b = block('DeleteAppModal');
  const { t } = useTranslation();

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle>
          {t('dashboard.modals.deleteApp.title')} {title}?
        </FreshModalTitle>

        <section className={b('btnContaier')}>
          <FreshButton
            color="grey"
            text={t('dashboard.modals.deleteApp.cancel')}
            onClick={onClose}
          />
          <FreshButton
            color="black"
            text={t('dashboard.modals.deleteApp.delete')}
            onClick={handleDeleteApp}
          />
        </section>
      </div>
    </FreshModal>
  );
};
