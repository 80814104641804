import React from 'react';
import PropTypes from 'prop-types';

const IconYoutube = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.905 4.815a2.084 2.084 0 00-1.455-1.5C14.16 3 9 3 9 3s-5.16 0-6.45.345a2.085 2.085 0 00-1.455 1.5A21.75 21.75 0 00.75 8.813a21.75 21.75 0 00.345 3.997 2.085 2.085 0 001.455 1.44c1.29.345 6.45.345 6.45.345s5.16 0 6.45-.345a2.084 2.084 0 001.455-1.5c.234-1.3.35-2.617.345-3.938a21.736 21.736 0 00-.345-3.997v0z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
    <path
      d="M7.313 11.265l4.312-2.453L7.312 6.36v4.905z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconYoutube.propTypes = {
  className: PropTypes.string
};

IconYoutube.defaultProps = {
  className: ''
};

export default IconYoutube;
