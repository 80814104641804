import React from 'react';
import PropTypes from 'prop-types';

const IconBlank = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect height="16.6" rx="1.3" stroke="white" strokeWidth="1.4" width="16.6" x="0.7" y="0.7" />
  </svg>
);

IconBlank.propTypes = {
  className: PropTypes.string
};

IconBlank.defaultProps = {
  className: ''
};

export default IconBlank;
