import React, { memo } from 'react';
import block from 'bem-cn';
import './HeaderButton.scss';
import { Icon } from '@components';

const b = block('HeaderButton');

interface Props {
  color: 'primary' | 'accent' | 'transparent' | 'transparent-black' | 'transparent-white';
  onClick?(): void;
  icon?: string;
  iconVariant?: string;
  disabled?: boolean;
  className?: string;
}

export const HeaderButton: React.FC<Props> = memo((props) => {
  const { children, color, onClick, icon, iconVariant, disabled, className } = props;

  return (
    <button
      className={`${b({ color }).toString()} ${className ?? ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <Icon className={b('icon').toString()} name={icon} variant={iconVariant} />}
      {children}
    </button>
  );
});
