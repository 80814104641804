import React from 'react';
import PropTypes from 'prop-types';

const IconCheckArrow = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="7"
    stroke="white"
    viewBox="0 0 10 7"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.05835L4 6.07458L1 3.06484"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconCheckArrow.propTypes = {
  className: PropTypes.string
};

IconCheckArrow.defaultProps = {
  className: ''
};

export default IconCheckArrow;
