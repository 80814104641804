import React from 'react';
import PropTypes from 'prop-types';

const IconSeparatorStart = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    viewBox="0 0 15 40"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 39.5L13.3251 22.3328C14.3261 20.9387 14.3261 19.0613 13.3251 17.6672L1 0.5" />
  </svg>
);

IconSeparatorStart.propTypes = {
  className: PropTypes.string
};

IconSeparatorStart.defaultProps = {
  className: ''
};

export default IconSeparatorStart;
