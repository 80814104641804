import React from 'react';
import block from 'bem-cn';
import './Table.scss';

const b = block('Table');

interface Props {}

export const Table: React.FunctionComponent<Props> = (props) => {
  const { children } = props;

  return <table className={b()}>{children}</table>;
};

export const TableRow: React.FunctionComponent<Props> = (props) => {
  const { children } = props;

  return <tr className={b('row')}>{children}</tr>;
};

export const TableHead: React.FunctionComponent<Props> = (props) => {
  const { children } = props;

  return <thead className={b('head')}>{children}</thead>;
};

export const TableHeadCell: React.FunctionComponent<Props> = (props) => {
  const { children } = props;

  return <td className={b('headCell')}>{children}</td>;
};

export const TableBody: React.FunctionComponent<Props> = (props) => {
  const { children } = props;

  return <tbody className={b('tableBody')}>{children}</tbody>;
};

export const TableBodyCell: React.FunctionComponent<Props> = (props) => {
  const { children } = props;

  return <td className={b('bodyCell')}>{children}</td>;
};
