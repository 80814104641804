import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { ColorExample } from '@modules/EditorModule/widgets/_components/ColorPicker/_components/ColorExample/ColorExample';
import { ColorExampleGradient } from '@modules/EditorModule/widgets/_components/ColorPicker/_components/ColorExampleGradient/ColorExampleGradient';
import {
  colorExamples,
  gradientExamples
} from '@modules/EditorModule/widgets/_components/ColorPicker/_components/Picker/Picker';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './BackgroundColorTemplates.scss';
import { RootState, useAppDispatch } from '@store';
import { useCurrentStoriesSlice, useCurrentStoriesType } from '@features/stories/hooks';
import { GroupsType } from '@features';
import { useSelector } from 'react-redux';
import { BackgroundColorType } from '@storysdk/react';

const b = block('BackgroundColorTemplates');

interface BackgroundColorTemplatesProps {
  type: BackgroundColorType;
  groupType: GroupsType;
  onChange: (value: any) => void;
}

export const BackgroundColorTemplates: React.FC<BackgroundColorTemplatesProps> = ({
  type,
  groupType,
  onChange
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const currentStoriesSlice = useCurrentStoriesSlice(groupType);
  const currentStoriesType = useCurrentStoriesType(groupType);

  const pickerOpen = useSelector(
    (store: RootState) => store[currentStoriesType].editor.pickerStoryOpen
  );

  const handlePickerOpen = (type: BackgroundColorType) => {
    if (!pickerOpen) {
      dispatch(currentStoriesSlice.actions.setPickerStoryOpen(type));
    }
  };

  return (
    <div className={b()}>
      <button className={b('btn')} onClick={() => handlePickerOpen(type)}>
        <img className={b('icon')} src="/images/color-picker-icon.png" />
        <span className={b('text')}>{t('editor.openColorPicker')}</span>
      </button>
      <div className={b('colorsWrapper')}>
        <SimpleBar
          classNames={{
            scrollContent: b('colorsContainer')
          }}
          style={{ minHeight: 56, maxHeight: 56 }}
        >
          {type === 'color' ? (
            <>
              {colorExamples.map((color, index) => (
                <ColorExample
                  className={b('colorExample').toString()}
                  color={color.value}
                  index={index}
                  key={`color-exp-${index}`}
                  onClick={(value) => onChange(value)}
                />
              ))}
            </>
          ) : (
            <>
              {gradientExamples.map((colors, index) => (
                <ColorExampleGradient
                  className={b('colorExample').toString()}
                  colors={colors.value}
                  index={index}
                  key={`colors-exp-${index}`}
                  onClick={(value) => onChange(value)}
                />
              ))}
            </>
          )}
        </SimpleBar>
      </div>
    </div>
  );
};
