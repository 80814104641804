import React from 'react';
import block from 'bem-cn';
import './SettingsOpenLayerSelect.scss';
import { Icon, IconCheckCircleSmall, IconCheckCircleSmallFilled } from '@components';

const b = block('SettingsOpenLayerSelect');

interface SettingsOpenLayerSelectProps {
  value: string;
  defaultId: string;
  options: Array<{ value: string; title: string }>;
  className?: string;
  onChange(value: string): void;
  onChangeDefaultId(id: string): void;
  onRemoveLayer(id: string): void;
}

export const SettingsOpenLayerSelect: React.FC<SettingsOpenLayerSelectProps> = (props) => {
  const {
    options,
    value,
    className,
    defaultId,
    onChange,
    onChangeDefaultId,
    onRemoveLayer
  } = props;

  return (
    <div className={`${b()} ${className ?? ''}`}>
      <>
        {options.map((option) => (
          <div
            className={b('option', { current: option.value === value })}
            key={`layerToSelect-${option.value}`}
          >
            <span className={b('optionText')} onClick={() => onChange(option.value)}>
              {option.title}
            </span>

            <div className={b('btnsContainer')}>
              <button className={b('btn')} onClick={() => onChangeDefaultId(option.value)}>
                {option.value === defaultId ? (
                  <IconCheckCircleSmallFilled className={b('icon').toString()} />
                ) : (
                  <IconCheckCircleSmall className={b('icon').toString()} />
                )}
              </button>
              <button className={b('btn')} onClick={() => onRemoveLayer(option.value)}>
                <Icon className={b('icon').toString()} name="delete-fresh" />
              </button>
            </div>
          </div>
        ))}
      </>
    </div>
  );
};
