import { nanoid } from 'nanoid';
import { getInitStoryParams } from '@utils';
import { Story } from '../types';
import { storyStatisticDataAdapter } from './storyStatisticDataAdapter';

const getDefaultPosition = (widget: any) => {
  if (widget.editorPosition) {
    return widget.editorPosition;
  }

  return widget.position;
};

export const convertStoryFromRaw = (
  raw: any,
  currentLocale: string,
  type: Story['type']
): Story => {
  let widgets =
    raw.story_data[currentLocale]?.history?.story_data?.widgets ??
    raw.story_data[currentLocale]?.widgets;
  let background =
    raw.story_data[currentLocale].history?.story_data?.background ??
    raw.story_data[currentLocale].background;

  const storyInitParams = getInitStoryParams({});

  if (!widgets) {
    widgets = [];
  }

  if (!background) {
    background = storyInitParams.background;
  }

  const adaptedWidgets = [...widgets].map((widget) => ({
    ...widget,
    id: widget.id ?? `W${nanoid()}`,
    position: getDefaultPosition(widget)
  }));

  return {
    id: raw.id,
    createdAt: raw.created_at,
    statistic: storyStatisticDataAdapter(raw.statistic),
    widgets: adaptedWidgets,
    background,
    type,
    position: raw.position,
    storyData: raw.story_data,
    layerData: raw.layer_data,
    isSyncDesignActive: raw.is_sync_design_active,
    isHasUnpublishedChanges: !!raw.story_data[currentLocale]?.history?.story_data
  };
};
