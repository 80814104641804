import React from 'react';
import block from 'bem-cn';
import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { STORY_WIDTH, Story, StoryPreview } from '@features';
import { FreshTable, FreshTableDataRow } from '@components/_fresh';
import { shortEnglishHumanizer } from '@utils';
import { GroupGeneralAnalytics } from '../GroupGeneralAnalytics';
import './AnalyticsLogicsTab.scss';
import { GroupResultsAnalytics } from '../GroupResultsAnalytics';

const b = block('AnalyticsLogicsTab');

const CARD_WIDTH = 18;

const getStoryRow = (item: Story): FreshTableDataRow => {
  const watchDuration = item.statistic?.duration
    ? shortEnglishHumanizer(item.statistic.duration * 1000)
    : '0s';

  const scaleIndex = CARD_WIDTH / STORY_WIDTH;

  return {
    story: (
      <div className={b('storyPreview')}>
        <StoryPreview scaleIndex={scaleIndex} story={item} />
      </div>
    ),
    views: item.statistic?.views || '0',
    action: item.statistic?.actions || '0',
    cr: item.statistic?.completionRate ? `${item.statistic.completionRate}%` : '0%',
    time: watchDuration
  };
};

export const AnalyticsLogicsTab = () => {
  const groupData = useSelector((store: RootState) => store.storiesAnalyticsGroup.data);
  const status = useSelector((store: RootState) => store.groupsAnalytic.status);
  const appStatus = useSelector((store: RootState) => store.appManager.status);

  const { t } = useTranslation();

  const storiesTableHeaderCols = [
    {
      title: t('dashboard.analytics.stories.story'),
      key: 'story'
    },
    {
      title: t('dashboard.analytics.stories.views'),
      key: 'views'
    },
    {
      title: t('dashboard.analytics.stories.action'),
      key: 'action'
    },
    {
      title: t('dashboard.analytics.stories.cr'),
      key: 'cr'
    },
    {
      title: t('dashboard.analytics.stories.time'),
      key: 'time'
    }
  ];

  const resultLayerGroupId = groupData?.settings?.scoreResultLayersGroupId;

  const storiesRows = groupData?.stories
    ?.filter((item) => item.layerData.layersGroupId !== resultLayerGroupId)
    .map((item) => getStoryRow(item));

  const resultLayers = groupData?.stories?.filter(
    (item) => item.layerData.layersGroupId === resultLayerGroupId
  );

  return (
    <div className={b()}>
      <GroupGeneralAnalytics
        groupStatistic={groupData?.statistic}
        isLoading={status === 'pending' || appStatus === 'pending'}
      />

      <FreshTable
        dataRows={storiesRows ?? []}
        emptyText={t('dashboard.analytics.groups.noData')}
        headerCols={storiesTableHeaderCols}
        isLoading={status === 'pending'}
      />

      {resultLayers && resultLayers.length > 0 && (
        <GroupResultsAnalytics
          isLoading={status === 'pending' || appStatus === 'pending'}
          layers={resultLayers ?? []}
        />
      )}
    </div>
  );
};
