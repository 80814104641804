import React from 'react';
import block from 'bem-cn';
import { getScalableValue } from '@features';
import './WidgetLine.scss';

const b = block('WidgetLine');

interface WidgetLineProps {
  value: number;
  width?: number;
  height?: number;
  top?: number | string;
  left?: number | string;
  bottom?: number;
  right?: number;
  rotate?: number;
  transform?: string;
}

export const WidgetLine: React.FC<WidgetLineProps> = (props) => {
  const lineWidth = getScalableValue(1);

  const {
    value,
    width = lineWidth,
    height = lineWidth,
    top = 'auto',
    left = 'auto',
    right = 'auto',
    bottom = 'auto',
    rotate = 0,
    transform
  } = props;

  return (
    <div
      className={b()}
      style={{
        width,
        height,
        top,
        left,
        right,
        bottom,
        transform: `rotate(${rotate}deg); ${transform}`
      }}
    >
      {value > 0 && (
        <span
          className={b('value')}
          style={{
            fontSize: getScalableValue(14),
            padding: `${getScalableValue(2)}px ${getScalableValue(4)}px`,
            borderRadius: `${getScalableValue(2)}px`
          }}
        >
          {value}
        </span>
      )}
    </div>
  );
};
