import React from 'react';
import PropTypes from 'prop-types';

const IconTool = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.025 4.725a.75.75 0 000 1.05l1.2 1.2a.75.75 0 001.05 0l2.827-2.827a4.5 4.5 0 01-5.954 5.954l-5.183 5.183a1.59 1.59 0 11-2.25-2.25l5.183-5.182a4.5 4.5 0 015.954-5.955l-2.82 2.82-.007.007z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconTool.propTypes = {
  className: PropTypes.string
};

IconTool.defaultProps = {
  className: ''
};

export default IconTool;
