import React from 'react';
import PropTypes from 'prop-types';

const IconText2 = ({ className }) => (
  <svg
    className={className}
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M3.457 10L6 3.5 8.543 10H3.457zm-.47 1.2L1.5 15l-1.428-.471 5-12.5h1.856l5 12.5L10.5 15l-1.487-3.8H2.987zM18 2.2h-2.4V16H18v1.2h-6V16h2.4V2.2H12V1h6v1.2z"
      fillRule="evenodd"
    />
  </svg>
);

IconText2.propTypes = {
  className: PropTypes.string
};

IconText2.defaultProps = {
  className: ''
};

export default IconText2;
