import React from 'react';
import block from 'bem-cn';
import { ScoreType } from '@features';
import { useTranslation } from 'react-i18next';
import './SettingsLayerSelect.scss';

const b = block('SettingsLayerSelect');

interface SettingsLayerSelectPropsType {
  layers: Array<{
    value: any;
    title: string;
    score: {
      letter: string;
      points: number;
    };
  }>;
  type: ScoreType;
  currentLayerId?: string;
  onChangeLayer(id: string): void;
  onChangeScore(id: string, value: string): void;
}

export const SettingsLayerSelect: React.FC<SettingsLayerSelectPropsType> = ({
  layers,
  type,
  currentLayerId,
  onChangeLayer,
  onChangeScore
}) => {
  const { t } = useTranslation();

  const handleChangeScore = (id: string) => (event: any) => {
    const value = event.target.value;

    if (
      (type === ScoreType.LETTERS &&
        (value.length > 1 || (value.length !== 0 && !value.match('^[a-zA-Z]+$')))) ||
      (type === ScoreType.NUMBERS &&
        (value.length > 2 || (value.length !== 0 && !value.match('^[0-9]+$'))))
    ) {
      return;
    }

    onChangeScore(id, value);
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <span className={b('headerText', { uppercase: true })}>
          {t('editor.logic.chooseLayers')}
        </span>
        <span className={b('scoreText')}>
          {type === ScoreType.NUMBERS ? t('editor.logic.minScore') : t('editor.logic.totalScore')}
        </span>
      </div>
      <div className={b('layers')}>
        {layers.map((layer) => (
          <div
            className={b('layerItem', { current: currentLayerId === layer.value })}
            key={`layer-${layer.value}`}
          >
            <span className={b('layerTitle')} onClick={() => onChangeLayer(layer.value)}>
              {layer.title}
            </span>
            <div className={b('scoreInputContainer')}>
              {type === ScoreType.NUMBERS && <span className={b('scoreInputText')}>≥</span>}
              <input
                className={b('scoreInput')}
                type="text"
                value={type === ScoreType.LETTERS ? layer.score.letter : layer.score.points}
                onChange={handleChangeScore(layer.value)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
