import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '@layouts';
import { FooterLinks, ReturnToFigmaMessage } from '../_components';

export const ReturnToFigmaPage: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout
      footer={
        <>
          <FooterLinks
            links={[
              {
                title: t('auth.signIn'),
                href: '/'
              },
              {
                title: t('auth.help'),
                href: 'https://docs.storysdk.com/',
                isExternal: true
              }
            ]}
          />
        </>
      }
      template="form"
      title={t('auth.returnToFigmaTitle')}
    >
      <ReturnToFigmaMessage />
    </AuthLayout>
  );
};
