import React from 'react';
import cn from 'classnames';
import {
  IconGoogle,
  IconView,
  IconToken,
  IconUrl,
  IconCopy,
  IconCopy2,
  IconQuestion,
  IconStorySdk,
  IconUnlock,
  IconPlus,
  IconPlusBold,
  IconMore,
  IconDelete,
  IconRefresh,
  IconApple,
  IconAndroid,
  IconSend,
  IconImg,
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
  IconChart,
  IconSettings,
  IconCaret,
  IconTool,
  IconSave,
  IconFlagRu,
  IconText1,
  IconText2,
  IconLightning,
  IconSticker,
  IconRectangle,
  IconEllipse,
  IconChevronCircleUp,
  IconBlank,
  IconUnsplash,
  IconYoutube,
  IconGrid,
  IconCheckCircle,
  IconACircle,
  IconBCircle,
  IconAlignLeft,
  IconAlignCenter,
  IconAlignRight,
  IconChevronDown,
  IconBack,
  IconSuccessCircle,
  IconSelectArrow,
  IconDisk,
  IconEdit,
  IconUpload,
  IconLock,
  IconUploadEditor,
  IconAngle,
  IconBorderRadius,
  IconSuccessCircleFresh,
  IconProfile,
  IconLogout,
  IconAddPlusLarge,
  IconEditFresh,
  IconPublish,
  IconArchive,
  IconDeleteFresh,
  IconSaveFresh,
  IconImgFresh,
  IconCalendarFresh,
  IconAndroinFresh,
  IconIos,
  IconClose,
  IconBackFresh,
  IconPasswordHidden,
  IconSelectArrowFresh,
  IconRateStar,
  IconText3,
  IconPlay,
  IconFlagEn,
  IconFlagEs,
  IconPasswordShown,
  IconSchedule,
  IconIphoneSmallBlack,
  IconTemplates,
  IconShare,
  IconLogoApple,
  IconLogoAndroid,
  IconLogoChrome,
  IconLogoReact,
  IconUpgradeArrow,
  IconWarningCircleFresh
} from './icons';
import IconCircleFire from './icons/icon-circle-fire';

const icons: any = {
  google: IconGoogle,
  edit: IconEdit,
  upload: IconUpload,
  angle: IconAngle,
  view: IconView,
  token: IconToken,
  url: IconUrl,
  copy: IconCopy,
  'copy-2': IconCopy2,
  question: IconQuestion,
  'story-sdk': IconStorySdk,
  unlock: IconUnlock,
  lock: IconLock,
  plus: IconPlus,
  'plus-bold': IconPlusBold,
  more: IconMore,
  delete: IconDelete,
  refresh: IconRefresh,
  apple: IconApple,
  android: IconAndroid,
  send: IconSend,
  img: IconImg,
  image: IconImg,
  calendar: IconCalendar,
  'calendar-fresh': IconCalendarFresh,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  chart: IconChart,
  settings: IconSettings,
  caret: IconCaret,
  tool: IconTool,
  save: IconSave,
  check: IconCheckCircle,
  'circle-success': IconSuccessCircle,
  'circle-success-fresh': IconSuccessCircleFresh,
  'select-arrow': IconSelectArrow,
  'icon-disk': IconDisk,
  'border-radius': IconBorderRadius,
  profile: IconProfile,
  logout: IconLogout,
  publish: IconPublish,
  archive: IconArchive,
  'delete-fresh': IconDeleteFresh,
  'save-fresh': IconSaveFresh,
  'image-fresh': IconImgFresh,
  close: IconClose,
  ios: IconIos,
  'android-fresh': IconAndroinFresh,
  'edit-fresh': IconEditFresh,
  'chevron-down-fresh': IconSelectArrowFresh,
  play: IconPlay,
  hide: IconPasswordHidden,
  show: IconPasswordShown,
  'rate-start': IconRateStar,
  schedule: IconSchedule,
  'iphone-small-black': IconIphoneSmallBlack,
  share: IconShare,
  'logo-apple': IconLogoApple,
  'logo-android': IconLogoAndroid,
  'logo-chrome': IconLogoChrome,
  'logo-react': IconLogoReact,
  'upgrade-arrow': IconUpgradeArrow,
  'warning-circle': IconWarningCircleFresh,
  'fire-circle': IconCircleFire
};

const flagIcons: any = {
  ru: IconFlagRu,
  en: IconFlagEn,
  es: IconFlagEs
};

const editorIcons: any = {
  'text-1': IconText1,
  'text-2': IconText2,
  'text-3': IconText3,
  templates: IconTemplates,
  lightning: IconLightning,
  sticker: IconSticker,
  rectangle: IconRectangle,
  ellipse: IconEllipse,
  'swipe-up': IconChevronCircleUp,
  'chevron-circle-up': IconChevronCircleUp,
  blank: IconBlank,
  a: IconACircle,
  b: IconBCircle,
  image: IconImg,
  unsplash: IconUnsplash,
  video: IconYoutube,
  template: IconGrid,
  check: IconCheckCircle,
  add: IconPlusBold,
  'add-large': IconAddPlusLarge,
  back: IconBack,
  'back-fresh': IconBackFresh,
  'chevron-down': IconChevronDown,
  'align-left': IconAlignLeft,
  'align-center': IconAlignCenter,
  'align-right': IconAlignRight,
  upload: IconUploadEditor,
  edit: IconEditFresh
};

const variants: any = {
  DEFAULT: 'default',
  FLAG: 'flag',
  EDITOR: 'editor'
};

const fillIcons: any = [
  'url',
  'copy',
  'chart',
  'question',
  'plus',
  'plus-bold',
  'more',
  'apple',
  'android',
  'caret',
  'text-1',
  'text-2',
  'sticker',
  'rectangle',
  'unsplash',
  'add'
];
const strokeIcons: any = [
  'align-center',
  'align-left',
  'align-right',
  'blank',
  'calendar',
  'chevron-circle-up',
  'chevron-left',
  'chevron-right',
  'copy-2',
  'delete',
  'ellipse',
  'image',
  'img',
  'lightning',
  'refresh',
  'save',
  'send',
  'settings',
  'template',
  'token',
  'tool',
  'lock',
  'unlock',
  'video',
  'view'
];

interface Props {
  name: string;
  className?: string;
  variant?: string;
  color?: string;
}

export const Icon: React.FC<Props> = (props) => {
  const { name, variant = variants.DEFAULT, className } = props;

  const nameClassName = !!name && `icon-${name}`;
  const strokeClassName = strokeIcons.includes(name) && 'stroke-current';
  const fillClassName = fillIcons.includes(name) && 'fill-current';

  let IconSVG;
  switch (variant) {
    case variants.EDITOR:
      IconSVG = editorIcons[name];
      break;
    case variants.FLAG:
      IconSVG = flagIcons[name];
      break;
    default:
      IconSVG = icons[name];
  }

  const iconClasses = cn(
    'icon flex-shrink-0',
    className,
    nameClassName,
    fillClassName,
    strokeClassName
  );

  let color = props.color;

  if (color === 'white') {
    color = '#ffffff';
  } else if (color === 'black') {
    color = '#05051D';
  }

  return <>{IconSVG && <IconSVG className={iconClasses} color={color} />}</>;
};
