import React from 'react';
import block from 'bem-cn';
import hexOpacity from 'hex-opacity';
import parse from 'parse-color';

import './ColorExampleGradient.scss';

const b = block('ColorExampleGradient');

interface Props {
  colors: Array<string>;
  index: number;
  className?: string;
  onClick: (param: Array<string>) => void;
}

export const ColorExampleGradient: React.FunctionComponent<Props> = (props) => {
  const { colors, index, className, onClick } = props;

  const hexColors = colors.map((color: string) => {
    const parsed = parse(color);
    return hexOpacity.create(parsed.hex, parsed.rgba[parsed.rgba.length - 1]);
  });

  return (
    <div
      aria-label={hexColors[0]}
      className={`${b()} ${className ?? ''}`}
      role="button"
      style={{
        background: `linear-gradient(${hexColors[0]}, ${hexColors[1]})`
      }}
      tabIndex={index}
      onClick={() => onClick(hexColors)}
      onKeyDown={() => onClick(hexColors)}
    />
  );
};
