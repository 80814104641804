import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { GroupsType } from '@features/groups';
import { useCurrentStoriesType } from './useCurrentStoriesType';

export const useGetStoriesParams = (type: GroupsType) => {
  const storiesType = useCurrentStoriesType(type);

  const status = useSelector((store: RootState) => store[storiesType].status);
  const changedObjects = useSelector(
    (store: RootState) => store[storiesType].editor.changedObjects
  );
  const stories = useSelector((store: RootState) => store[storiesType].stories);
  const selectedLayersGroupId =
    useSelector((store: RootState) => store[storiesType].editor.selectedLayersGroupId) ?? '';
  const editorMode = useSelector((store: RootState) => store[storiesType].editor.editorMode);
  const scheduleLayersGroupId = useSelector(
    (store: RootState) => store[storiesType].editor.scheduleLayersGroupId
  );

  return {
    status,
    changedObjects,
    stories,
    selectedLayersGroupId,
    editorMode,
    scheduleLayersGroupId
  };
};
