import React from 'react';
import block from 'bem-cn';
import './FreshCheckbox.scss';

const b = block('FreshCheckbox');

interface Props {
  className?: string;
  label?: string | React.ReactNode;
  name: string;
  type?: 'square' | 'round';
  invalid?: boolean;
  value: boolean;
  onChange?(): void;
}

export const FreshCheckbox: React.FunctionComponent<Props> = (props) => {
  const { label, name, type = 'square', value, className, onChange, invalid } = props;

  return (
    <label
      className={`${b({
        disabled: !onChange
      }).toString()} ${className || ''}`}
    >
      <input
        checked={value}
        className={b('checkbox', {
          disabled: !onChange
        })}
        disabled={!onChange}
        name={name}
        type="checkbox"
        onChange={onChange}
      />
      <div className={b('customCheckbox', { checked: value, invalid, type, disabled: !onChange })}>
        {value && (
          <>
            {type === 'round' ? (
              <svg
                fill="none"
                height="7"
                viewBox="0 0 10 7"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 1.05835L4 6.07458L1 3.06484"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                fill="none"
                height="8"
                viewBox="0 0 12 8"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1L4.125 7L1 4.27273"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            )}
          </>
        )}
      </div>
      {label && <div className={b('label')}>{label}</div>}
    </label>
  );
};
