import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { AppBase } from '@features';
import { IconSuccessCircleFreshSmall } from '@components';
import './DowngradeAppsItem.scss';

interface DowngradeAppsItemProps {
  app: AppBase;
  isSelected: boolean;
  storiesLimit?: number;
  onSelect(appId: string): void;
}

export const DowngradeAppsItem: React.FC<DowngradeAppsItemProps> = ({
  app,
  isSelected,
  storiesLimit,
  onSelect
}) => {
  const b = block('DowngradeAppsItem');

  const { t } = useTranslation();

  return (
    <div
      className={b({ selected: isSelected })}
      onClick={() => {
        onSelect(app.id);
      }}
    >
      <div className={b('appSelect')}>
        <div className={b('select')}>
          {isSelected ? (
            <IconSuccessCircleFreshSmall className={b('fillIcon')} />
          ) : (
            <div className={b('emptyCircleContainer')}>
              <div className={b('emptyCircle')} />
            </div>
          )}
        </div>
        <p className={b('appTitle')}>{app.title}</p>
      </div>
      <div className={b('info')}>
        <p className={b('storiesCount')}>
          {isSelected ? (
            <>
              {app.storiesCount || 0}/{storiesLimit || '∞'}{' '}
              {t('dashboard.modals.downgrade.stories')}
            </>
          ) : (
            <>{t('dashboard.modals.downgrade.toDraft')}</>
          )}
        </p>
        {!isSelected && (
          <p className={b('storiesCountDesc')}>
            {app.storiesCount} {t('dashboard.modals.downgrade.storiesToDraft')}
          </p>
        )}
      </div>
    </div>
  );
};
