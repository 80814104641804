import React, { useCallback } from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import './FreshInput.scss';

type PropsType = {
  theme?: 'dark' | 'light';
  className?: string;
  value: any;
  fieldName?: string;
  invalid?: boolean;
  type: string;
  iconClassName?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
  placeholder?: string;
  onChange?: (params: any) => void;
};

const b = block('FreshInput');

export const FreshInput = ({
  className,
  value,
  type,
  theme = 'light',
  placeholder,
  invalid,
  fieldName,
  isDisabled,
  iconClassName,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  onClick,
  onChange
}: PropsType) => {
  const handleChange = useCallback(
    (event: any) => {
      if (onChange) {
        onChange({
          fieldName,
          value: type === 'number' ? parseInt(event.target.value, 10) : event.target.value
        });
      }
    },
    [onChange, fieldName, type]
  );

  return (
    <div className={cn(b().toString(), className?.toString())}>
      {LeftIcon && <LeftIcon className={cn(b('icon').toString(), iconClassName)} />}
      <input
        className={b('input', {
          icon: (!!LeftIcon && 'left') || (!!RightIcon && 'right'),
          theme,
          pointer: !!onClick,
          invalid
        })}
        disabled={isDisabled}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={handleChange}
        onClick={onClick}
      />
      {RightIcon && (
        <RightIcon className={cn(b('icon', { right: true }).toString(), iconClassName)} />
      )}
    </div>
  );
};
