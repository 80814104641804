import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { SupportedLanguages } from 'react-code-blocks/dist/types';
import { CodeSample, FilterControlGroup } from '@components';
import { SelectPlatformOption, SetupPlatformSelect } from './_components';
import './SetupTab.scss';

const b = block('SetupTab');
const s = block('GroupSettingsTab');

interface SolutionConfig {
  code: string;
  desc: string;
  language: SupportedLanguages;
}

export const SetupTab: React.FC = () => {
  const { t } = useTranslation();
  const [platform, setPlatform] = useState<string>('web');
  const [solution, setSolution] = useState<string>('custom_solution');
  const [currentConfig, setCurrentConfig] = useState<SolutionConfig | null>(null);

  const webSolutions: SelectPlatformOption[] = [
    {
      title: t('dashboard.setup.customSolution'),
      value: 'custom_solution'
    },
    {
      title: 'React',
      value: 'react'
    }
  ];

  const iosSolutions: SelectPlatformOption[] = [
    {
      title: t('dashboard.stories.title'),
      value: 'stories'
    },
    {
      title: t('dashboard.onboardings.onboardings'),
      value: 'onboardings'
    }
  ];

  const [options, setOptions] = useState<SelectPlatformOption[]>(webSolutions);

  useEffect(() => {
    setOptions(platform === 'ios' ? iosSolutions : webSolutions);
  }, [platform]);

  const solutionsConfig: {
    [key: string]: {
      [key: string]: SolutionConfig;
    };
  } = {
    web: {
      custom_solution: {
        code: `<script src="https://cdn.jsdelivr.net/npm/@storysdk/core@latest/dist/bundle.umd.js"></script>
<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/@storysdk/core@latest/dist/bundle.css">`,
        desc: t('dashboard.setup.solutions.web.customSolution.desc'),
        language: 'html'
      },
      react: {
        code: `import { Story } from "@storysdk/core"; 
import "@storysdk/core/dist/bundle.css";

const ref = useRef(null);

useEffect(() => {
  const story = new Story("SDK_TOKEN");

  const element = ref.current;
  story.renderGroups(element);
}, []);`,
        desc: t('dashboard.setup.solutions.web.react.desc'),
        language: 'jsx'
      }
    },
    ios: {
      stories: {
        code: `import StorySDK

let storySdk: StorySDK = {
    var config = SRConfiguration(sdkId: "YOUR_SDK_ID")
    config.language = "en"

    return StorySDK(configuration: config)
}()

let widget = SRStoryWidget()
addSubview(widget)`,
        desc: t('dashboard.setup.solutions.ios.stories.desc'),
        language: 'swift'
      },
      onboardings: {
        code: `import StorySDK

let storySdk: StorySDK = {
    var config = SRConfiguration(sdkId: "YOUR_SDK_ID")
    config.language = "en"

    return StorySDK(configuration: config)
}()

let widget = SRStoryWidget()
addSubview(widget)`,
        desc: t('dashboard.setup.solutions.ios.onboardings.desc'),
        language: 'swift'
      }
    }
  };

  useEffect(() => {
    setSolution(platform === 'ios' ? 'stories' : 'custom_solution');
  }, [platform]);

  useEffect(() => {
    setCurrentConfig(solutionsConfig[platform][solution]);
  }, [platform, solution]);

  return (
    <div className={b()}>
      <div className={s('formHeader')}>
        <h1 className={s('formHeaderTitle')}>{t('dashboard.apps.nav.setup')}</h1>
      </div>

      <FilterControlGroup
        className={b('subtabs')}
        color="pink"
        current={platform}
        items={[
          {
            id: 'web',
            title: 'Web SDK'
          },
          {
            id: 'ios',
            title: 'iOS SDK'
          },
          {
            id: 'android',
            title: 'Android SDK',
            disabled: true
          }
        ]}
        onChange={setPlatform}
      />

      {platform === 'web' && <SetupPlatformSelect options={options} onChange={setSolution} />}

      {currentConfig && <CodeSample code={currentConfig.code} language={currentConfig.language} />}

      <p className={b('desc')}>
        {currentConfig?.desc} {t('dashboard.setup.open')}{' '}
        <a
          className={b('descLink')}
          href="https://docs.storysdk.com/"
          rel="noreferrer"
          target="_blank"
        >
          {t('dashboard.setup.documentation')}
        </a>
      </p>

      <div className={b('note')}>☀ {t('dashboard.setup.note')}</div>
    </div>
  );
};
