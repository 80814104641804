import React from 'react';
import PropTypes from 'prop-types';

const IconLock = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="14"
    stroke="#AFAFAF"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M9.91663 12.2502H4.08329C3.43871 12.2502 2.91663 11.7281 2.91663 11.0835V7.00016C2.91663 6.35558 3.43871 5.8335 4.08329 5.8335H9.91663C10.5612 5.8335 11.0833 6.35558 11.0833 7.00016V11.0835C11.0833 11.7281 10.5612 12.2502 9.91663 12.2502Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M4.66663 5.83333V4.08333V4.08333C4.66663 2.79475 5.71138 1.75 6.99996 1.75V1.75C8.28854 1.75 9.33329 2.79475 9.33329 4.08333V4.08333V5.83333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconLock.propTypes = {
  className: PropTypes.string
};

IconLock.defaultProps = {
  className: ''
};

export default IconLock;
