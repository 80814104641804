import { WidgetsTypes } from '@storysdk/react';

export const WidgetElementsSizes = Object.freeze({
  [WidgetsTypes.CHOOSE_ANSWER]: {
    widget: {
      borderRadius: 10
    },
    header: {
      fontSize: 12,
      paddingTop: 13,
      paddingBottom: 13
    },
    answers: {
      padding: 12
    },
    answer: {
      padding: 8,
      marginBottom: 6
    },
    answerId: {
      width: 18,
      height: 18,
      marginRight: 8,
      fontSize: 10
    },
    answerTitle: {
      fontSize: 10
    }
  },
  [WidgetsTypes.EMOJI_REACTION]: {
    widget: {
      borderRadius: 50,
      paddingTop: 14,
      paddingBottom: 14,
      paddingRight: 11,
      paddingLeft: 11
    },
    emoji: {
      width: 34
    },
    item: {
      marginRight: 11,
      marginLeft: 11
    }
  },
  [WidgetsTypes.QUESTION]: {
    text: {
      fontSize: 14,
      marginBottom: 10
    },
    button: {
      height: 50,
      fontSize: 24,
      borderRadius: 10
    }
  },
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWERS]: {
    title: {
      fontSize: 14,
      marginBottom: 16
    },
    answers: {
      gap: 5
    },
    answer: {
      padding: 5,
      gap: 5,
      borderRadius: 20
    },
    emoji: {
      width: 11
    },
    answerTitle: {
      fontSize: 8
    },
    sendBtn: {
      fontSize: 8,
      borderRadius: 20,
      padding: 5,
      marginTop: 5,
      lineHeight: 11
    }
  },
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE]: {
    title: {
      fontSize: 14,
      marginBottom: 16
    },
    answers: {
      gap: 5
    },
    answer: {
      padding: 4,
      gap: 5,
      borderRadius: 5
    },
    emoji: {
      width: 11
    },
    answerTitle: {
      fontSize: 11
    },
    sendBtn: {
      fontSize: 11,
      borderRadius: 5,
      padding: 10,
      marginTop: 5
    }
  },
  [WidgetsTypes.QUIZ_ONE_ANSWER]: {
    title: {
      fontSize: 14,
      marginBottom: 16
    },
    answers: {
      gap: 10
    },
    answer: {
      padding: 10,
      gap: 10,
      borderRadius: 20
    },
    emoji: {
      width: 17
    },
    answerTitle: {
      fontSize: 11
    }
  },
  [WidgetsTypes.QUIZ_OPEN_ANSWER]: {
    title: {
      fontSize: 14,
      marginBottom: 16
    },
    input: {
      fontSize: 11
    },
    inputWrapper: {
      paddingVertical: 9,
      paddingHorizontal: 16,
      borderRadius: 20,
      paddingRight: 35
    },
    sendButton: {
      width: 25,
      height: 25,
      right: 4
    }
  },
  [WidgetsTypes.QUIZ_RATE]: {
    title: {
      fontSize: 14,
      marginBottom: 16
    },
    stars: {
      gap: 10
    }
  },
  [WidgetsTypes.SLIDER]: {
    widget: {
      borderRadius: 10,
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30
    },
    emoji: {
      width: 30,
      height: 30
    },
    text: {
      fontSize: 16,
      marginBottom: 15
    },
    slider: {
      height: 11,
      borderRadius: 6
    }
  },
  [WidgetsTypes.TALK_ABOUT]: {
    widget: {
      borderRadius: 10
    },
    content: {
      paddingRight: 12,
      paddingLeft: 12,
      paddingBottom: 12,
      paddingTop: 30
    },
    text: {
      fontSize: 14,
      marginBottom: 15
    },
    input: {
      fontSize: 10,
      padding: 11,
      borderRadius: 8
    },
    empty: {
      height: 18
    },
    imageWrapper: {
      width: 36,
      height: 36
    },
    send: {
      height: 50
    },
    sendText: {
      fontSize: 14
    }
  }
});
