import React from 'react';
import PropTypes from 'prop-types';

const IconCopy3 = ({ className, color = '#FF00D0' }) => (
  <svg
    className={className}
    fill={color}
    height="14"
    viewBox="0 0 12 14"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M2.5 0C1.11929 0 0 1.11929 0 2.5V10.5H1V2.5C1 1.67157 1.67157 1 2.5 1H9.5V0H2.5ZM4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H10C11.1046 14 12 13.1046 12 12V4C12 2.89543 11.1046 2 10 2H4Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

IconCopy3.propTypes = {
  className: PropTypes.string
};

IconCopy3.defaultProps = {
  className: ''
};

export default IconCopy3;
