import { BackgroundType } from '../ColorPicker';

export const colorPickerStyles = {
  default: {
    picker: {
      background: 'none',
      width: '214px',
      padding: 0,
      border: 'none',
      boxShadow: 'none'
    },
    saturation: {
      borderRadius: '3px',
      marginBottom: '12px'
    },
    activeColor: {
      display: 'none'
    },
    color: {
      display: 'none'
    },
    hue: {
      height: '12px',
      marginBottom: '10px',
      borderRadius: '50px',
      border: '1px solid rgba(0, 0, 0, 0.1)'
    },
    alpha: {
      height: '12px',
      borderRadius: '50px',
      border: '1px solid rgba(0, 0, 0, 0.1)'
    },
    sliders: {
      padding: 0,
      marginBottom: '10px'
    },
    controls: {
      background: 'none'
    }
  }
};

export const COLOR_DEFAULT = {
  type: 'color',
  value: ''
} as BackgroundType;
