import React, { useState } from 'react';
import block from 'bem-cn';
import { IconPasswordHidden, IconPasswordShown } from '@components';
import './Input.scss';

const b = block('Input');

interface InputProps {
  input: Partial<React.InputHTMLAttributes<HTMLInputElement>>;
  icon?: React.ReactNode;
  controls?: React.ReactNode;
  invalid?: boolean;
  theme?: 'dark' | 'light';
  placeholder?: string;
  autocomplete?: 'on' | 'off';
  fieldName?: string;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    input,
    icon,
    controls,
    theme,
    placeholder,
    invalid,
    autocomplete = 'on',
    fieldName
  } = props;
  const { type, ...rest } = input;

  const [stateType, setType] = useState(type);
  const handleToggleType = () => setType(stateType === 'password' ? 'text' : 'password');

  return (
    <div className={b({ theme })}>
      {icon && <div className={b('leftIcon')}>{icon}</div>}
      <input
        autoComplete={autocomplete}
        className={b('input', { theme, invalid })}
        name={fieldName}
        type={stateType}
        {...rest}
        placeholder={placeholder}
      />
      <div className={b('rightControls')}>
        {controls}
        {type === 'password' && (
          <button className={b('control')} type="button" onClick={handleToggleType}>
            {stateType === 'password' ? (
              <IconPasswordHidden className={b('controlIcon')} />
            ) : (
              <IconPasswordShown className={b('controlIcon')} />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

Input.defaultProps = {
  theme: 'dark'
};

export { Input };
