import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { EditorTemplateItems } from '../EditorTemplateItems';
import { EditorTemplateCard } from '../EditorTemplateCard';
import 'simplebar-react/dist/simplebar.min.css';
import './EditorTemplatesTab.scss';

const b = block('EditorTemplatesTab');

export const EditorTemplatesTab: React.FC = () => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const templates = useSelector((store: RootState) => store.templates);
  const selectedTemplate = templates.templates.find(
    (template) => template.id === selectedTemplateId
  );

  const isLoading = templates.status === 'pending';

  const { t } = useTranslation();

  const handleBack = () => {
    setSelectedTemplateId(null);
  };

  return (
    <>
      {selectedTemplate ? (
        <EditorTemplateItems template={selectedTemplate} onBack={handleBack} />
      ) : (
        <SimpleBar style={{ maxHeight: '100%' }}>
          <div className={b()}>
            {!isLoading && templates.templates?.length === 0 ? (
              <div className={b('empty')}>{t('editor.noTemplates')}</div>
            ) : (
              <>
                {isLoading ? (
                  <>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <EditorTemplateCard isLoading key={`loading-template-${index}`} />
                    ))}
                  </>
                ) : (
                  <>
                    {templates.templates.map((template) => (
                      <EditorTemplateCard
                        key={`editor-template-${template.id}`}
                        template={template}
                        onClick={(id: string) => setSelectedTemplateId(id)}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </SimpleBar>
      )}
    </>
  );
};
