import { StoryVideoBackground } from '@features/stories/components';
import { Story } from '@features/stories/types';
import { renderBackgroundStyles } from '@modules/EditorModule/utils';
import React, { useEffect, useState } from 'react';
import block from 'bem-cn';

import './StoryEditorBackground.scss';

interface StoryEditorBackgroundProps {
  selectedStory: Story;
  cardTop: number;
  cardHeight: number;
  backgroundTop?: number;
  isLarge?: boolean;
  backgroundSize: {
    width: number;
    height: number;
  };
  isFilled?: boolean;
  borderRadius?: number;
  isCentered?: boolean;
}

const b = block('StoryEditorBackground');

export const StoryEditorBackground: React.FC<StoryEditorBackgroundProps> = ({
  selectedStory,
  cardTop,
  cardHeight,
  isFilled,
  backgroundSize,
  borderRadius,
  isLarge,
  isCentered,
  backgroundTop
}) => {
  const [isVideoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    if (selectedStory?.background?.type === 'video') {
      setVideoLoading(true);
    }
  }, [selectedStory?.background?.type]);

  return (
    <div
      className={b('background', { centered: isCentered })}
      style={{
        background: renderBackgroundStyles(selectedStory?.background),
        width: backgroundSize.width,
        height: isFilled ? backgroundSize.height : cardHeight,
        top: isFilled ? backgroundTop : cardTop,
        borderRadius
      }}
    >
      {selectedStory?.background?.type === 'video' && (
        <StoryVideoBackground
          autoplay
          borderRadius={borderRadius}
          isFilled={isFilled || isLarge}
          isLoading={isVideoLoading}
          src={selectedStory?.background?.value}
          onLoadEnd={() => {
            setVideoLoading(false);
          }}
        />
      )}
    </div>
  );
};
