import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CropProps,
  fetchUpdateGroup,
  GroupsType,
  informSlice,
  KB_IN_MB,
  MAX_GROUP_AVATAR_SIZE_KB,
  MessageTypes,
  StoriesGroup
} from '@features';
import { MAX_GROUP_AVATAR_SIZE_MB } from '@features/consts';
import { useGetGroupLastPosition } from '@features/groups/hooks';
import { FormGroup, FormLabel, IconSelectArrowFresh } from '@components';
import {
  FreshModalTitle,
  FreshButton,
  FreshInput,
  FreshDropArea,
  FreshDrop
} from '@components/_fresh';
import { useAppDispatch } from '../../../../store';
import './EditGroupContainer.scss';

const b = block('EditGroupContainer');

interface CreateGroupContainerProps {
  currentAppId?: string;
  className?: string;
  onClose?(groupId?: string): void;
  group?: StoriesGroup;
}

interface State {
  name: string;
  image: string | null;
  position: number;
}

const initialState: State = {
  name: '',
  image: null,
  position: 1
};

export const EditGroupContainer: React.FC<CreateGroupContainerProps> = (props) => {
  const { currentAppId, className, onClose, group } = props;
  const imageFiles = React.useRef<File[] | null>(null);
  const dispatch = useAppDispatch();
  const { appId } = useParams<{ appId: string }>();

  const { t } = useTranslation();

  const [isInvalid, setInvalid] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [groupForm, setGroupForm] = useState(initialState);

  const lastGroupPosition = useGetGroupLastPosition();

  const initialCroppedState = {
    dataUrl: group?.image || '',
    blob: null
  };

  const [croppedImage, setCroppedImage] = useState<CropProps>(initialCroppedState);

  useEffect(() => {
    setCroppedImage({
      dataUrl: group?.image || '',
      blob: null
    });

    setGroupForm({
      name: group?.title || '',
      image: group?.image || '',
      position: group?.settings?.position || 1
    });
  }, [group]);

  useEffect(() => {
    if (groupForm.name.trim().length !== 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [groupForm]);

  const handleChangeName = ({ value }: { value: string }) => {
    if (value) {
      setInvalid(false);
    } else {
      setInvalid(true);
    }
    setGroupForm({ ...groupForm, name: value });
  };

  const handleChangePosition = (position: number) => {
    setGroupForm({ ...groupForm, position });
  };

  const handleSaveGroup = () => {
    dispatch(
      fetchUpdateGroup({
        appId: currentAppId || appId,
        groupId: group?.id || '',
        title: groupForm.name,
        image: croppedImage.blob,
        position: groupForm.position
      })
    )
      .unwrap()
      .then(() => {
        setGroupForm(initialState);
        setButtonDisabled(false);
        onClose && onClose(group?.id || '');
      });
  };

  const handleFileChange = (files: File[]) => {
    imageFiles.current = files;

    if (files[0] && files[0].size / KB_IN_MB > MAX_GROUP_AVATAR_SIZE_KB) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.WARN,
          text: `${t('errors.fileSize')} ${MAX_GROUP_AVATAR_SIZE_MB}MB.`
        })
      );
    } else {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = () => {
        setCroppedImage({ dataUrl: reader.result as string, blob: file });
      };
    }
  };

  return (
    <div className={`${b().toString()} ${className || ''}`}>
      <FreshModalTitle>{t('dashboard.modals.editGroup.title')}</FreshModalTitle>
      <FreshDropArea
        className={`${b('dropArea')}`}
        currentImage={croppedImage.dataUrl}
        disabled={group?.type !== GroupsType.GROUP}
        handleFileChange={group?.type === GroupsType.GROUP ? handleFileChange : undefined}
        isNoHover={group?.type !== GroupsType.GROUP}
      />
      <FormGroup>
        <FormLabel invalid={isInvalid} isBlack>
          {t('dashboard.modals.editGroup.groupName')}
        </FormLabel>
        <FreshInput
          fieldName="name"
          invalid={isInvalid}
          placeholder={t('dashboard.modals.editGroup.groupNamePlaceholder')}
          type="text"
          value={groupForm.name}
          onChange={handleChangeName}
        />
      </FormGroup>
      {group?.type === GroupsType.GROUP && (
        <FormGroup>
          <FormLabel isBlack>{t('dashboard.modals.editGroup.order')}</FormLabel>
          <FreshDrop
            control={
              <FreshInput
                isDisabled
                rightIcon={lastGroupPosition > 1 ? IconSelectArrowFresh : undefined}
                type="text"
                value={`#${groupForm.position}`}
              />
            }
          >
            {lastGroupPosition > 1 && (
              <div className={b('drop')}>
                {Array.from({ length: lastGroupPosition }).map((_, index) => (
                  <div
                    className={b('dropItem')}
                    onClick={() => {
                      handleChangePosition(index + 1);
                    }}
                  >
                    <span>#{index + 1}</span>
                  </div>
                ))}
              </div>
            )}
          </FreshDrop>
        </FormGroup>
      )}
      <FreshButton
        className={b('btn')}
        disabled={buttonDisabled}
        text={t('dashboard.modals.editGroup.saveBtn')}
        onClick={handleSaveGroup}
      />
    </div>
  );
};
