import React from 'react';
import block from 'bem-cn';
import { LocaleItem, LocaleType } from '../LocaleItem/LocaleItem';
import { LocaleAdd } from '../LocaleAdd/LocaleAdd';

import './LocaleItemGroup.scss';

const b = block('LocaleItemGroup');

interface LocaleItemGroupProps {
  defaultLang: string;
  isDeleteAvailable: boolean;
  locales: LocaleType[];
  appLocales: LocaleType[];
  onAddLocale: (locale: LocaleType) => void;
  onDeleteLocale: (localeShortName: string) => void;
  onMakeDefault: (localeShortName: string) => void;
}

export const LocaleItemGroup: React.FC<LocaleItemGroupProps> = ({
  locales,
  appLocales,
  defaultLang,
  isDeleteAvailable,
  onAddLocale,
  onDeleteLocale,
  onMakeDefault
}) => (
  <div className={b()}>
    {appLocales.map((item) => (
      <LocaleItem
        isDefault={item.shortName === defaultLang}
        isDeleteAvailable={isDeleteAvailable}
        localeItem={item}
        onDeleteLocale={onDeleteLocale}
        onMakeDefault={onMakeDefault}
      />
    ))}

    <LocaleAdd
      appLocales={appLocales.map((locale) => locale.shortName)}
      locales={locales}
      onLocaleClick={onAddLocale}
    />
  </div>
);
