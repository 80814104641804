import React from 'react';
import PropTypes from 'prop-types';

const IconUploadEditor = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.79995 11C1.79995 10.6686 1.53132 10.4 1.19995 10.4C0.86858 10.4 0.599951 10.6686 0.599951 11H1.79995ZM17.8 11C17.8 10.6686 17.5313 10.4 17.2 10.4C16.8686 10.4 16.6 10.6686 16.6 11H17.8ZM5.77569 3.57574C5.54137 3.81005 5.54137 4.18995 5.77569 4.42426C6.01 4.65858 6.3899 4.65858 6.62422 4.42426L5.77569 3.57574ZM9.19995 1L9.62422 0.575736C9.3899 0.341421 9.01 0.341421 8.77569 0.575736L9.19995 1ZM11.7757 4.42426C12.01 4.65858 12.3899 4.65858 12.6242 4.42426C12.8585 4.18995 12.8585 3.81005 12.6242 3.57574L11.7757 4.42426ZM8.59995 11C8.59995 11.3314 8.86858 11.6 9.19995 11.6C9.53132 11.6 9.79995 11.3314 9.79995 11H8.59995ZM9.79995 2C9.79995 1.66863 9.53132 1.4 9.19995 1.4C8.86858 1.4 8.59995 1.66863 8.59995 2H9.79995ZM16.6 11V15H17.8V11H16.6ZM15.2 16.4H3.19995V17.6H15.2V16.4ZM1.79995 15V11H0.599951V15H1.79995ZM6.62422 4.42426L9.62422 1.42426L8.77569 0.575736L5.77569 3.57574L6.62422 4.42426ZM8.77569 1.42426L11.7757 4.42426L12.6242 3.57574L9.62422 0.575736L8.77569 1.42426ZM9.79995 11V2H8.59995V11H9.79995ZM3.19995 16.4C2.42675 16.4 1.79995 15.7732 1.79995 15H0.599951C0.599951 16.4359 1.76401 17.6 3.19995 17.6V16.4ZM16.6 15C16.6 15.7732 15.9732 16.4 15.2 16.4V17.6C16.6359 17.6 17.8 16.4359 17.8 15H16.6Z"
      fill="white"
    />
  </svg>
);

IconUploadEditor.propTypes = {
  className: PropTypes.string
};

IconUploadEditor.defaultProps = {
  className: ''
};

export default IconUploadEditor;
