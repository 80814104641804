import React, { useEffect, useState } from 'react';
import { ColorResult } from 'react-color';
import { SettingsInput } from '@features';
import block from 'bem-cn';
import { roundToPercent } from '@modules/EditorModule/utils';
import { ColorTranslator } from 'colortranslator';
import { EditableInput } from 'react-color/lib/components/common';
import { Icon } from '@components';
import '../../Picker.scss';

type ColorState = {
  hex?: ColorResult['hex'];
  hsl?: ColorResult['hsl'];
  rgb?: ColorResult['rgb'];
};

interface PickerInputProps {
  mode: string;
  colorRGB?: ColorResult['rgb'];
  handleChange(value: any): void;
  handleToggleMode(): void;
}
const b = block('Picker');

const inputStyles = {
  background: 'transparent',
  border: 'none',
  color: '#fff',
  fontSize: '12px',
  height: '100%',
  width: '100%',
  lineHeight: '15px',
  backgroundColor: '#05051D',
  borderRadius: '4px'
};

export const PickerInput: React.FC<PickerInputProps> = ({
  mode,
  colorRGB,
  handleToggleMode,
  handleChange
}) => {
  const [colorsState, setColorsState] = useState<ColorState>({});

  const setNewColorState = (color: ColorResult['rgb']) => {
    const colorTranslator = new ColorTranslator({
      R: color.r,
      G: color.g,
      B: color.b,
      A: color.a
    });

    setColorsState({
      hex: colorTranslator.HEX,
      hsl: {
        a: colorTranslator.HSLObject.A,
        h: Math.round(colorTranslator.HSLObject.H),
        l: Math.round(colorTranslator.HSLObject.L),
        s: Math.round(colorTranslator.HSLObject.S)
      },
      rgb: {
        a: colorTranslator.RGBAObject.A,
        b: colorTranslator.RGBAObject.B,
        g: colorTranslator.RGBAObject.G,
        r: colorTranslator.RGBAObject.R
      }
    });
  };

  useEffect(() => {
    if (colorRGB) {
      setNewColorState(colorRGB);
    }
  }, [colorRGB]);

  const handleChangeOpacity = (value: number | string) => {
    handleChange({
      h: colorsState.hsl?.h ?? 0,
      s: colorsState.hsl?.s ?? 0,
      l: colorsState.hsl?.l ?? 0,
      a: value ? +value / 100 : 0
    });
  };

  return (
    <div className={b('input')}>
      <button className={b('mode')} onClick={handleToggleMode}>
        <span className={b('modeVal')}>{mode.toUpperCase()}</span>
        <Icon className={b('modeValIcon').toString()} name="chevron-down" variant="editor" />
      </button>
      {mode === 'hex' && (
        <>
          <EditableInput
            style={{
              input: inputStyles,
              wrap: {
                width: '100%'
              }
            }}
            value={colorsState.hex?.replace('#', '')}
            onChange={handleChange}
          />

          <SettingsInput
            className={b('inputItemPercent')}
            max={100}
            min={0}
            postfix="%"
            type="number"
            value={roundToPercent(colorsState.rgb?.a ?? 1)}
            onChange={handleChangeOpacity}
          />
        </>
      )}
      {mode === 'rgb' && (
        <>
          <div className={b('inputItemContainer')}>
            <input
              className={b('inputItem')}
              type="number"
              value={colorsState.rgb?.r}
              onChange={(e) => {
                handleChange({
                  r: e.target.value,
                  g: colorsState.rgb?.g,
                  b: colorsState.rgb?.b,
                  a: colorsState.rgb?.a
                });
              }}
            />
            <input
              className={b('inputItem')}
              type="number"
              value={colorsState.rgb?.g}
              onChange={(e) => {
                handleChange({
                  r: colorsState.rgb?.r,
                  g: e.target.value,
                  b: colorsState.rgb?.b,
                  a: colorsState.rgb?.a
                });
              }}
            />
            <input
              className={b('inputItem')}
              type="number"
              value={colorsState.rgb?.b}
              onChange={(e) => {
                handleChange({
                  r: colorsState.rgb?.r,
                  g: colorsState.rgb?.g,
                  b: e.target.value,
                  a: colorsState.rgb?.a
                });
              }}
            />
          </div>

          <SettingsInput
            className={b('inputItemPercent')}
            max={100}
            min={0}
            postfix="%"
            type="number"
            value={roundToPercent(colorsState.rgb?.a ?? 1)}
            onChange={handleChangeOpacity}
          />
        </>
      )}
      {mode === 'hsl' && (
        <>
          <div className={b('inputItemContainer')}>
            <input
              className={b('inputItem')}
              type="number"
              value={colorsState.hsl?.h}
              onChange={(e) => {
                handleChange({
                  h: e.target.value,
                  s: colorsState.hsl?.s,
                  l: colorsState.hsl?.l,
                  a: colorsState.hsl?.a
                });
              }}
            />
            <input
              className={b('inputItem')}
              type="number"
              value={colorsState.hsl?.s}
              onChange={(e) => {
                handleChange({
                  h: colorsState.hsl?.h,
                  s: e.target.value,
                  l: colorsState.hsl?.l,
                  a: colorsState.hsl?.a
                });
              }}
            />
            <input
              className={b('inputItem')}
              type="number"
              value={colorsState.hsl?.l}
              onChange={(e) => {
                handleChange({
                  h: colorsState.hsl?.h,
                  s: colorsState.hsl?.s,
                  l: e.target.value,
                  a: colorsState.hsl?.a
                });
              }}
            />
          </div>

          <SettingsInput
            className={b('inputItemPercent')}
            max={100}
            min={0}
            postfix="%"
            type="number"
            value={roundToPercent(colorsState.rgb?.a ?? 1)}
            onChange={handleChangeOpacity}
          />
        </>
      )}
    </div>
  );
};
