import { LayerData, Story } from '@features';
import { BackgroundType } from '@modules';
import { BackgroundColorType } from '@storysdk/react';
import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';

interface GetInitStoryParams {
  position?: number;
  layerData?: LayerData;
  isGreyBackground?: boolean;
  isLocalesDesignSyncActive?: boolean;
}

export const getInitStoryParams = (params: GetInitStoryParams): Story => {
  const { position = 1, layerData, isGreyBackground } = params;

  return {
    id: nanoid(),
    type: 'new',
    widgets: [],
    createdAt: DateTime.now().toISO() ?? '',
    position,
    background: {
      type: isGreyBackground ? BackgroundColorType.COLOR : BackgroundColorType.TRANSPARENT,
      value: isGreyBackground ? '#ebebebff' : '',
      isFilled: true
    } as BackgroundType,
    layerData: layerData ?? {
      layersGroupId: nanoid(),
      positionInGroup: 1,
      score: {
        letter: 'A',
        points: 0
      },
      isDefaultLayer: true,
      isWidgetsSizeSyncActive: true
    },
    storyData: {}
  };
};
