import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import {
  ColorPicker,
  DropIcon,
  getChangedOpacityColor,
  getOpacityFromColor,
  SettingsBox
} from '@modules';
import {
  SettingsTextarea,
  ControlGroup,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsAnswers,
  EmojiItemType,
  SettingsAnswersType,
  KB_IN_MB,
  informSlice,
  MAX_ANSWER_IMAGE_SIZE_KB,
  MAX_ANSWER_IMAGE_SIZE_MB,
  SettingsFontSelector,
  SettingsSelect,
  FONT_STYLES,
  GroupsType,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features';
import { useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import { EmojiData } from 'emoji-mart';
import { API } from '@services';
import { nanoid } from 'nanoid';
import { MessageTypes, onUploadProgress } from '@features/inform';
import {
  BackgroundColorType,
  QuizMultipleAnswerWithImageWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { useParams } from 'react-router-dom';
import { Icon, SwitchGroup } from '@components';

interface QuizMultipleAnswerWithImageWidgetSettingsProps {
  params: QuizMultipleAnswerWithImageWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangePosition(params: WidgetPositionType): void;
  onChange(params: QuizMultipleAnswerWithImageWidgetParamsType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
}

export const QuizMultipleAnswerWithImageWidgetSettings: React.FC<QuizMultipleAnswerWithImageWidgetSettingsProps> = (
  props
) => {
  const { params, position, positionLimits, onChange, onChangeAlign, onChangePosition } = props;

  const imageFiles = useRef<File[] | null>(null);
  const imageInputRef = useRef<HTMLInputElement | null>(null);

  const handleChangeAnswer = (id: string) => (event: any) => {
    onChange({
      ...params,
      answers: params.answers.map((answer) =>
        answer.id === id ? { ...answer, title: event.target.value } : answer
      )
    });
  };

  const [isEmojiChangeOpen, setEmojiChangeOpen] = useState<boolean>(false);

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const { t } = useTranslation();

  const uploadImage = (files: File[], callback: (imageUrl: string, fileId: string) => void) => {
    if (files[0] && files[0].size / KB_IN_MB > MAX_ANSWER_IMAGE_SIZE_KB) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.WARN,
          text: `${t('errors.fileSize')} ${MAX_ANSWER_IMAGE_SIZE_MB}MB.`
        })
      );
    } else {
      imageFiles.current = files;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = async () => {
        const newModalId = nanoid(6);

        dispatch(
          informSlice.actions.addMessage({
            id: newModalId,
            type: MessageTypes.PROGRESS,
            text: file.name
          })
        );

        const storageData = await API.storage.create({
          file,
          onUploadProgress: onUploadProgress(dispatch),
          modalProgressId: newModalId
        });

        if (storageData && storageData.data && !storageData.data.error) {
          const imageUrl = storageData.data.data.file_url;
          const fileId = storageData.data.data.id;

          callback(imageUrl, fileId);
        }
      };
    }
  };

  const handleChangeText = (value: string) => {
    onChange({
      ...params,
      title: value
    });
  };

  const handleAddAnswer = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const handleImageInputChange = () => {
    if (imageInputRef.current) {
      const files = imageInputRef.current.files;

      if (files && files.length) {
        uploadImage(files as any, (imageUrl, fileId) => {
          onChange({
            ...params,
            answers: [
              ...params.answers,
              {
                id: fileId,
                title: `Answer ${params.answers.length + 1}`,
                image: {
                  url: imageUrl,
                  fileId
                },
                score: {
                  letter: 'A',
                  points: 0
                }
              }
            ]
          });
        });

        imageInputRef.current.value = '';
      }
    }
  };

  const handleChangeTitleHide = () => {
    onChange({
      ...params,
      isTitleHidden: !params.isTitleHidden
    });
  };

  const handleRemoveAnswer = (id: string) => () => {
    if (params.answers.length > 1) {
      const currentAnswer = params.answers.find((answer) => answer.id === id);

      if (currentAnswer && currentAnswer.image && currentAnswer.image.fileId) {
        API.storage.remove({ fileId: currentAnswer.image.fileId });
      }

      onChange({
        ...params,
        answers: params.answers.filter((answer) => answer.id !== id)
      });
    }
  };

  const handleChangePosition = (field: string) => (value: any) => {
    if (positionLimits.keepRatio && positionLimits.ratioIndex) {
      if (field === 'width') {
        onChangePosition({
          ...position,
          width: value,
          realWidth: value,
          height: Math.round(value / positionLimits.ratioIndex),
          realHeight: Math.round(value / positionLimits.ratioIndex)
        });
      } else if (field === 'height') {
        onChangePosition({
          ...position,
          width: Math.round(value * positionLimits.ratioIndex),
          height: value,
          realHeight: value,
          realWidth: Math.round(value * positionLimits.ratioIndex)
        });
      }
    } else {
      onChangePosition({
        ...position,
        [field]: value
      });
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(currentStoriesSlice.actions.setIsPickerOpen(isEmojiChangeOpen));
  }, [isEmojiChangeOpen, dispatch]);

  const handleChangeEmoji = (i: number) => (newEmoji: EmojiData) => {
    onChange({
      ...params,
      answers: params.answers.map((answer, index) => {
        if (index === i) {
          return {
            ...answer,
            // @ts-ignore
            emoji: { name: newEmoji.id, unicode: newEmoji.unified } as EmojiItemType
          };
        }
        return answer;
      })
    });
  };

  const handleDropChangeOpen = useCallback((isOpen: boolean) => {
    setEmojiChangeOpen(isOpen);
  }, []);

  const handleImageChange = (answerId: string, files: File[]) => {
    uploadImage(files, (imageUrl, fileId) => {
      onChange({
        ...params,
        answers: params.answers.map((answer) =>
          answer.id === answerId
            ? {
              ...answer,
              image: {
                url: imageUrl,
                fileId
              }
            }
            : answer
        )
      });
    });
  };

  // TEXT STYLES

  const handleChangeTextStyle = useCallback(
    (fieldType: 'titleFont' | 'answersFont', fieldName, value) => {
      onChange({ ...params, [fieldType]: { ...params[fieldType], [fieldName]: value } });
    },
    [params, onChange]
  );

  const handleChangeTextOpacity = (fieldType: 'titleFont' | 'answersFont') => (value: number) => {
    handleChangeTextStyle(fieldType, 'fontColor', {
      type: 'color',
      value: getChangedOpacityColor(params[fieldType].fontColor.value as string, value)
    });
  };

  const titleFontOpacity = useMemo(() => {
    if (params.titleFont.fontColor.type === 'color') {
      return getOpacityFromColor(params.titleFont.fontColor.value);
    }

    return 100;
  }, [params.titleFont.fontColor]);

  const answerFontOpacity = useMemo(() => {
    if (params.answersFont.fontColor.type === 'color') {
      return getOpacityFromColor(params.answersFont.fontColor.value);
    }

    return 100;
  }, [params.answersFont.fontColor]);

  // END TEXT STYLES

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked={position.isHeightLocked}>
            <SettingsInput
              max={positionLimits.maxWidth}
              min={positionLimits.minWidth}
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              postfix="px"
              prefix="H"
              type="number"
              value={position.realHeight}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.title')}>
        <SettingsTextarea isMarginBottom value={params.title} onChange={handleChangeText} />

        <SwitchGroup
          isChecked={params.isTitleHidden}
          label={t('editor.hideTitle')}
          onToggle={handleChangeTitleHide}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.answers')}>
        <SettingsAnswers
          answers={params.answers}
          handleAddAnswer={handleAddAnswer}
          handleChangeAnswer={handleChangeAnswer}
          handleChangeEmoji={handleChangeEmoji}
          handleDropChangeOpen={handleDropChangeOpen}
          handleImageChange={handleImageChange}
          handleRemoveAnswer={handleRemoveAnswer}
          isInputDisabled={false}
          leftChildType={SettingsAnswersType.IMAGE}
        />

        <input
          accept="image/jpeg,image/png,image/gif,image/webp"
          hidden
          ref={imageInputRef}
          type="file"
          onChange={handleImageInputChange}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.titleStyle')}>
        <SettingsBox.Field>
          <SettingsFontSelector
            value={params.titleFont.fontFamily}
            onChange={(value) => handleChangeTextStyle('titleFont', 'fontFamily', value)}
          />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsSelect
              options={FONT_STYLES}
              value={params.titleFont.fontParams}
              onChange={(value) => handleChangeTextStyle('titleFont', 'fontParams', value)}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
              value={params.titleFont.fontColor}
              onChange={(value) => handleChangeTextStyle('titleFont', 'fontColor', value)}
            />
            <SettingsInput
              icon={<DropIcon />}
              max={100}
              type="number"
              value={titleFontOpacity}
              onChange={handleChangeTextOpacity('titleFont')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.answerStyle')}>
        <SettingsBox.Field>
          <SettingsFontSelector
            value={params.answersFont.fontFamily}
            onChange={(value) => handleChangeTextStyle('answersFont', 'fontFamily', value)}
          />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsSelect
              options={FONT_STYLES}
              value={params.answersFont.fontParams}
              onChange={(value) => handleChangeTextStyle('answersFont', 'fontParams', value)}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <ColorPicker
              availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
              value={params.answersFont.fontColor}
              onChange={(value) => handleChangeTextStyle('answersFont', 'fontColor', value)}
            />
            <SettingsInput
              icon={<DropIcon />}
              max={100}
              type="number"
              value={answerFontOpacity}
              onChange={handleChangeTextOpacity('answersFont')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>
    </>
  );
};
