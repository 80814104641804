import React from 'react';
import PropTypes from 'prop-types';

const IconPlusBold = ({ className }) => (
  <svg
    className={className}
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect height={2.571} rx={1.286} width={18} y={7.714} />
    <rect height={2.571} rx={1.286} transform="rotate(-90 7.714 18)" width={18} x={7.714} y={18} />
  </svg>
);

IconPlusBold.propTypes = {
  className: PropTypes.string
};

IconPlusBold.defaultProps = {
  className: ''
};

export default IconPlusBold;
