import React from 'react';
import block from 'bem-cn';
import { getScalableValue } from '@features';
import './WidgetLineDotted.scss';

const b = block('WidgetLineDotted');

interface WidgetLineDottedProps {
  width?: number;
  height?: number;
  top?: number | string;
  left?: number | string;
  bottom?: number;
  right?: number;
  rotate?: number;
  isVertical?: boolean;
}

export const WidgetLineDotted: React.FC<WidgetLineDottedProps> = (props) => {
  const lineWidth = getScalableValue(1);

  const {
    width = lineWidth,
    height = lineWidth,
    top = 'auto',
    left = 'auto',
    right = 'auto',
    bottom = 'auto',
    rotate = 0,
    isVertical
  } = props;

  const background = `repeating-linear-gradient(to ${isVertical ? 'bottom' : 'right'
    },#EB5757 0,#EB5757 ${getScalableValue(10)}px,transparent ${getScalableValue(
      10
    )}px,transparent ${getScalableValue(15)}px)`;

  return (
    <div
      className={b()}
      style={{
        width,
        height,
        top,
        left,
        right,
        bottom,
        background,
        transform: `rotate(${rotate}deg)`
      }}
    />
  );
};
