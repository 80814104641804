import React, { FC } from 'react';
import block from 'bem-cn';

import './GroupViewItem.scss';

type GroupViewItemProps = {
  isActive?: boolean;
  type: 'circle' | 'square' | 'bigSquare' | 'rectangle' | string;
  image: string;
  title: string;
};

const b = block('GroupViewItem');

export const GroupViewItem: FC<GroupViewItemProps> = ({ type, image, title, isActive }) => (
  <div className={b({ type, active: isActive })}>
    <div className={b('imgContainer', { type })}>
      <img alt="" className={b('img', { type })} src={image} />
    </div>

    <p className={b('title', { type, active: isActive })}>{title}</p>
  </div>
);
