import React, { useState, forwardRef, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { Input as InputComponent } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import { Icon } from '@components';
import './old-input.scss';

export const colors = {
  BLACK: 'black',
  BLUE: 'blue',
  WHITE: 'white'
};

type PropsType = {
  fieldName?: string;
  className?: string;
  color?: string;
  disabled?: boolean;
  iconAction?: any;
  iconActive?: boolean;
  iconClassName?: string;
  iconName?: string;
  iconPosition?: string;
  iconText?: string;
  inputClassNameInner?: string;
  inputClassName?: string;
  invalid?: boolean;
  onChange?: (params: any) => void;
  onClick?: () => void;
  placeholder?: string;
  required?: boolean;
  size?: string;
  text?: string;
  type?: InputType;
  value?: any;
  inputProps?: object;
  [key: string]: any;
};

export const OldInput = forwardRef(
  (
    {
      fieldName = '',
      className = '',
      color = colors.BLACK,
      disabled = false,
      iconAction = null,
      iconActive = false,
      iconClassName = '',
      iconName = '',
      iconPosition = 'left',
      iconText = '',
      inputClassName = '',
      inputClassNameInner = '',
      invalid = false,
      size = 'md',
      type = 'text',
      onChange = () => {},
      inputProps,
      ...props
    }: PropsType,
    ref: any
  ) => {
    const [focus, setFocus] = useState(false);
    const hasIcon = !!iconName;
    const hasIconText = !!iconText;
    const hasIconClassName = !!iconClassName;
    const valid = !invalid;
    const isTextarea = type === 'textarea';

    const containerClasses = cn('input-container position-relative', className, {
      'has-icon': hasIcon,
      'is-textarea': isTextarea,
      'input-icon-left': hasIcon && iconPosition === 'left',
      'input-icon-right': hasIcon && iconPosition === 'right',
      'input-md': size === 'md',
      'opacity-50': disabled
    });

    const inputClasses = cn('input-input placeholder-opacity-50', inputClassName, {
      'text-sm': size === 'md',
      'bg-black': color === colors.BLACK,
      'bg-blue': color === colors.BLUE,
      'bg-white': color === colors.WHITE,
      'text-black': color === colors.WHITE,
      'placeholder-white text-white': [colors.BLACK].includes(color),
      'placeholder-black text-gray-975': [colors.BLUE, colors.WHITE].includes(color),
      'border-black': valid && color === colors.BLACK,
      'border-blue': valid && color === colors.BLUE,
      'border-white': valid && color === colors.WHITE,
      'border-danger-pink': invalid
      // 'border-primary': focus
    });

    const iconWrapperClasses =
      'input-icon-wrapper d-flex align-items-center justify-content-center';

    const iconClasses = cn('input-icon', iconClassName, {
      'text-white text-opacity-50':
        !hasIconClassName && !iconActive && [colors.BLACK].includes(color),
      'text-black text-opacity-50':
        !hasIconClassName && !iconActive && [colors.BLUE, colors.WHITE].includes(color),
      'text-primary text-opacity-50': !hasIconClassName && iconActive
    });

    const iconTextClasses = cn(
      'input-icon-text text-sm font-weight-bold ml-0_375 mr-0_75',
      iconClassName,
      {
        'text-white text-opacity-50':
          !hasIconClassName && !iconActive && [colors.BLACK].includes(color),
        'text-black text-opacity-50':
          !hasIconClassName && !iconActive && [colors.BLUE, colors.WHITE].includes(color),
        'text-primary text-opacity-50': !hasIconClassName && iconActive
      }
    );

    const handleChange = useCallback(
      (event) => {
        onChange({ fieldName, value: event.target.value, event });
      },
      [fieldName, onChange]
    );

    const handleBlur = useCallback(() => setFocus(false), []);
    const handleFocus = useCallback(() => setFocus(true), []);

    return (
      <div className={containerClasses}>
        <InputComponent
          autoFocus={focus}
          className={`${inputClasses} ${inputClassNameInner}`}
          disabled={disabled}
          ref={ref}
          type={type}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          {...props}
          {...inputProps}
        />
        {hasIcon && iconAction && (
          <span
            className={iconWrapperClasses}
            role="button"
            tabIndex={0}
            onClick={iconAction}
            onKeyDown={iconAction}
          >
            <Icon className={iconClasses} name={iconName} />
            {hasIconText && <span className={iconTextClasses}>{iconText}</span>}
          </span>
        )}
        {hasIcon && !iconAction && (
          <span className={iconWrapperClasses}>
            <Icon className={iconClasses} name={iconName} />
            {hasIconText && <span className={iconTextClasses}>{iconText}</span>}
          </span>
        )}
      </div>
    );
  }
);
