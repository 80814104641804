import { WidgetsTypes } from '@storysdk/react';

export const WIDGET_PARAMS = {
  [WidgetsTypes.TEXT]: {
    width: 300,
    height: 9,
    x: 30,
    y: 100
  },
  [WidgetsTypes.SWIPE_UP]: {
    width: 100,
    height: 37
  },
  [WidgetsTypes.SLIDER]: {
    width: 196,
    height: 95,
    x: 70,
    y: 70
  },
  [WidgetsTypes.QUESTION]: {
    width: 196,
    height: 90,
    x: 70,
    y: 70
  },
  [WidgetsTypes.CLICK_ME]: {
    width: 300,
    height: 54,
    x: 30,
    y: 100
  },
  [WidgetsTypes.LINK]: {
    width: 100,
    height: 38,
    x: 130,
    y: 100
  },
  [WidgetsTypes.TALK_ABOUT]: {
    width: 196,
    height: 42,
    x: 70,
    y: 70
  },
  [WidgetsTypes.EMOJI_REACTION]: {
    width: 190,
    height: 70
  },
  [WidgetsTypes.CHOOSE_ANSWER]: {
    width: 196,
    height: 47,
    x: 70,
    y: 70
  },
  [WidgetsTypes.QUIZ_ONE_ANSWER]: {
    width: 300,
    height: 183,
    x: 30,
    y: 100
  },
  [WidgetsTypes.IMAGE]: {
    width: 300,
    height: 300,
    x: 30,
    y: 100
  },
  [WidgetsTypes.VIDEO]: {
    width: 300,
    height: 300,
    x: 30,
    y: 100
  },
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWERS]: {
    width: 300,
    height: 27,
    x: 30,
    y: 100
  },
  [WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE]: {
    width: 300,
    height: 27,
    x: 30,
    y: 100
  },
  [WidgetsTypes.QUIZ_OPEN_ANSWER]: {
    width: 300,
    height: 27,
    x: 30,
    y: 100
  },
  [WidgetsTypes.QUIZ_RATE]: {
    width: 300,
    height: 27,
    x: 30,
    y: 100
  }
};

export const DEFAULT_WIDGET_ZINDEX = 3;
