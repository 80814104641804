import React, { useState, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AppCollaborationFeatures,
  CropProps,
  GroupListItem,
  GroupsType,
  StoriesGroup,
  useGetActionByAccess,
  useGetActionByCollaboratorRole
} from '@features';
import { AppFeatures } from '@features/user/consts';
import { Icon } from '@components';
import { CreateGroupModal } from '../CreateGroupModal/CreateGroupModal';
import { RootState, useAppDispatch } from '../../../../store';
import { CreateOnboardingModal } from '../CreateOnboardingModal';
import { GroupsLineLoadItem } from './_components/GroupsLineLoadItem';
import './GroupsLine.scss';

const b = block('GroupsLine');

interface GroupsLineProps {
  groups: StoriesGroup[];
  loadStatus: string;
  currentAppId?: string;
  redirectLink: string;
  type?: GroupsType;
  onLoad(appId: string): void;
  onSelect(id: string): void;
}

export const GroupsLine: React.FC<GroupsLineProps> = ({
  groups,
  currentAppId,
  redirectLink,
  loadStatus,
  type = GroupsType.GROUP,
  onLoad,
  onSelect
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const appState = useSelector((state: RootState) => state);
  const currentLocale = appState.appManager.currentLocale;

  const onActionClick = useGetActionByAccess();
  const onActionClickCollaborator = useGetActionByCollaboratorRole();

  const { t } = useTranslation();
  const { appId, groupId } = useParams<{ appId: string; groupId?: string }>();
  const [isOpen, setOpen] = useState(false);

  const [hoveringGroup, setHoveringGroup] = useState<string | null>(null);

  const sortedGroups = useMemo(
    () => [...groups].sort((a, b) => (a.settings?.position ?? 0) - (b.settings?.position ?? 0)),
    [groups]
  );

  useEffect(() => {
    if (
      appState.appManager.current?.id &&
      currentLocale &&
      appState.appManager.status === 'loaded'
    ) {
      onLoad(appState.appManager.current.id);
    }
  }, [dispatch, currentLocale, appState.appManager.current?.id, appState.appManager.status]);

  useEffect(() => {
    if (groups.length > 0 && appId === currentAppId && !groupId) {
      history.push(redirectLink);
    }
  }, [redirectLink]);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = (groupId?: string) => {
    if (groupId) {
      history.push(
        `/dashboard/${appId}/${type === GroupsType.GROUP ? 'stories' : 'onboarding'}/${groupId}`
      );
    }

    setOpen(false);
  };

  const initialCroppedState = {
    dataUrl: null,
    blob: null
  };

  const [croppedImage, setCroppedImage] = useState<CropProps>(initialCroppedState);

  if (loadStatus === 'pending') {
    return (
      <div className={b({ showed: !!appId })}>
        <div className={b('groupsList')}>
          {Array.from(Array(5)).map((_, index) => (
            <GroupsLineLoadItem
              isSquared={type === GroupsType.ONBOARDING}
              key={`groupLoad-${index}`}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={b({ showed: !!appId })}>
        <div className={b('groupsList')}>
          <button
            className={b('groupItem', { grey: true })}
            onClick={() => {
              onActionClickCollaborator(
                type === GroupsType.GROUP
                  ? AppCollaborationFeatures.CREATE_STORIES
                  : AppCollaborationFeatures.CREATE_ONBOARDING,
                () => {
                  onActionClick(
                    type === GroupsType.GROUP
                      ? AppFeatures.CREATE_STORIES_GROUP
                      : AppFeatures.CREATE_ONBOARDING_GROUP,
                    () => {
                      handleOpenModal();
                    }
                  );
                }
              );
            }}
          >
            <span className={b('addGroup', { squared: type === GroupsType.ONBOARDING })}>
              <Icon className={b('addGroupIcon').toString()} name="add-large" variant="editor" />
            </span>
            <div className={b('groupTitle')}>
              {type === GroupsType.ONBOARDING
                ? t('dashboard.header.addOnboarding')
                : t('dashboard.header.addGroup')}
            </div>
          </button>
          {loadStatus === 'loaded' &&
            sortedGroups.map((group) => (
              <GroupListItem
                group={group}
                groupId={groupId}
                groupView={appState.appManager.current?.settings?.groupView?.web ?? undefined}
                hoveringGroup={hoveringGroup}
                isActive={groupId === group.id}
                key={`group-${group.id}`}
                setHoveringGroup={setHoveringGroup}
                onSelect={onSelect}
              />
            ))}
        </div>
      </div>
      {type === GroupsType.GROUP && (
        <CreateGroupModal
          clearCurrentImage={() => setCroppedImage(initialCroppedState)}
          currentAppId={currentAppId}
          currentImage={croppedImage}
          isOpen={isOpen}
          onClose={handleCloseModal}
          onImageLoaded={setCroppedImage}
        />
      )}
      {type === GroupsType.ONBOARDING && (
        <CreateOnboardingModal
          clearCurrentImage={() => setCroppedImage(initialCroppedState)}
          currentImage={croppedImage}
          isOpen={isOpen}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};
