import React from 'react';
import PropTypes from 'prop-types';

const IconLightning = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    stroke="#AFAFAF"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.967 3L4.75 14H12L11.033 21L19.25 10H12L12.967 3Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconLightning.propTypes = {
  className: PropTypes.string
};

IconLightning.defaultProps = {
  className: ''
};

export default IconLightning;
