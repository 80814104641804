import React from 'react';
import PropTypes from 'prop-types';

const IconCalendarFresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83167 14.1685L9.92087 14.1994"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M10.0001 10.8338H14.1685"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M17.5032 7.49911H2.49695"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.66538 2.08008V4.58112"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.3348 2.08008V4.58112"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M15.0022 17.5031H4.99799C3.61658 17.5031 2.49695 16.3835 2.49695 15.0021V5.83161C2.49695 4.4502 3.61658 3.33057 4.99799 3.33057H15.0022C16.3836 3.33057 17.5032 4.4502 17.5032 5.83161V15.0021C17.5032 16.3835 16.3836 17.5031 15.0022 17.5031Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconCalendarFresh.propTypes = {
  className: PropTypes.string
};

IconCalendarFresh.defaultProps = {
  className: ''
};

export default IconCalendarFresh;
