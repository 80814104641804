import { useSelector } from 'react-redux';
import { RootState } from '@store';

export const useGetCurrentApp = () => {
  const app = useSelector((store: RootState) => store.appManager);

  if (app.current) {
    return app.current;
  }

  return null;
};
