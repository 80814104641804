export const storyStatisticDataAdapter = (data: any) =>
  data
    ? {
        actions: data.actions,
        back: data.back,
        close: data.close,
        interactions: data.interactions,
        click: data.click,
        completionRate: data.complition_rate,
        duration: data.duration,
        finish: data.finish,
        impression: data.impression,
        open: data.open,
        start: data.start,
        timeOfCompletion: data.time_of_complition,
        next: data.next,
        views: data.views
      }
    : undefined;
