import { StoriesGroup } from '@features';

export const getLastGroupsPosition = (groups: StoriesGroup[]): number => {
  let lastPosition = 0;

  const storyGroups = [...groups].sort(
    (a, b) => (a.settings?.position ?? 0) - (b.settings?.position ?? 0)
  );

  storyGroups.forEach((group) => {
    if ((group.settings?.position ?? 0) > lastPosition) {
      lastPosition = group.settings?.position ?? 0;
    }
  });

  return lastPosition;
};
