import React from 'react';
import { useHistory } from 'react-router-dom';
import block from 'bem-cn';
import { Icon } from '@components';
import './HeaderBackButton.scss';

const b = block('HeaderBackButton');

interface HeaderBackButtonProps {
  link?: string;
}

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = ({ link }) => {
  const history = useHistory();

  return (
    <button
      className={b()}
      onClick={() => {
        link ? history.push(link) : history.goBack();
      }}
    >
      <Icon className={b('icon').toString()} name="back-fresh" variant="editor" />
    </button>
  );
};
