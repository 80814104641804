import { WidgetObjectType } from '@storysdk/react';
import { ElementHorizontalAlign, GroupPositionType, WidgetObjectTypeExtended } from '../types';
import { getAlignHorizontalPosition } from './getAlignHorizontalPosition';

export const getAlignHorizontalWidgets = ({
  align,
  widgets,
  container,
  storyWidth,
  storyHeight
}: {
  align: ElementHorizontalAlign;
  widgets: WidgetObjectType[];
  container: GroupPositionType;
  storyWidth: number;
  storyHeight: number;
}): WidgetObjectTypeExtended[] =>
  widgets.map((widget) => ({
    ...widget,
    position: getAlignHorizontalPosition(
      align,
      widget.positionByResolutions[`${storyWidth}x${storyHeight}`],
      container
    )
  }));
