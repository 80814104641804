import React, { memo, useMemo } from 'react';
import cn from 'classnames';
import { MaterialIcon } from '@components';
import { MATERIAL_ICONS } from '@components/MaterialIcon/_constants';
import { MaterialIconChangeType, MaterialIconValueType } from '@components/MaterialIcon/_types';
import './MaterialIcons.scss';

const CLASS_NAME = 'MaterialIcons';

type PropsType = {
  value: MaterialIconValueType;
  onChange: MaterialIconChangeType;
};

export const MaterialIcons = memo(({ value, onChange }: PropsType) => {
  const icons = useMemo(() => Object.keys(MATERIAL_ICONS), []);

  return (
    <div className={cn(CLASS_NAME)}>
      {icons.map((key) => (
        <div className={cn(`${CLASS_NAME}__icon`)}>
          <MaterialIcon key={key} name={key} size={24} onClick={onChange} />
        </div>
      ))}
    </div>
  );
});
