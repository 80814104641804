import React from 'react';
import block from 'bem-cn';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './WidgetList.scss';

const b = block('WidgetList');

interface WidgetItemProps {
  isFilled?: boolean;
  isDisabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const WidgetItem: React.FC<WidgetItemProps> = (props) => {
  const { children, isFilled, isDisabled, onClick } = props;

  return (
    <button
      className={b('item', { filled: isFilled, disabled: isDisabled })}
      onClick={isDisabled ? undefined : onClick}
    >
      {children}
    </button>
  );
};

export const WidgetList: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const { children } = props;

  return (
    <SimpleBar style={{ maxHeight: '100%' }}>
      <div className={b()}>{children}</div>
    </SimpleBar>
  );
};
