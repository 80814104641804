import { RootState } from '@store';
import { useSelector } from 'react-redux';

export const useIsDefaultLanguageChoosen = () => {
  const app = useSelector((store: RootState) => store.appManager);

  if (app.current?.appLocalization && app.currentLocale) {
    return app.currentLocale === app.current.appLocalization.default;
  }

  return false;
};
