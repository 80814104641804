import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import './StoryLogicEmptyMessage.scss';

const b = block('StoryLogicEmptyMessage');

export const StoryLogicEmptyMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <p className={b('title')}>{t('editor.logic.emptyTitle')}</p>
      <p className={b('text')}>{t('editor.logic.emptyText')}</p>
      <img alt="" className={b('img')} src="/images/interactive-widgets.png" />
    </div>
  );
};
