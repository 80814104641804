import React, { useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IconGlobe, IconLink } from '@components/Icon';
import { FreshButton } from '../FreshButton';
import './FreshLinkOutput.scss';

type FreshInputPropsType = {
  className?: string;
  value: string;
  theme?: 'light' | 'dark';
};

const b = block('FreshLinkOutput');

export const FreshLinkOutput: React.FC<FreshInputPropsType> = ({
  className,
  theme = 'light',
  value
}) => {
  const { t } = useTranslation();

  const [isCopied, setIsCopied] = useState(false);

  const copyValue = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className={`${b().toString()} ${className ?? ''}`}>
      <div className={b('output', { dark: theme === 'dark' })}>
        <IconGlobe className={b('icon', { white: theme === 'dark' }).toString()} />
        <div className={b('outputValue')}>{value}</div>

        <CopyToClipboard text={value} onCopy={copyValue}>
          <FreshButton
            className={b('btn')}
            leftIcon={<IconLink />}
            size="sm"
            text={isCopied ? t('dashboard.groups.copied') : t('dashboard.groups.copyLink')}
          />
        </CopyToClipboard>
      </div>
    </div>
  );
};
