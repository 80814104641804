import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchInitUser } from '@features';
import { RootState, useAppDispatch } from '@store';
import { Auth } from '@utils';

export const useAuth = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useSelector((store: RootState) => store.user.user);

  useEffect(() => {
    if (!Auth.isLoggedIn) {
      history.push('/');
    } else if (!user.id) {
      dispatch(fetchInitUser());
    }
  }, [history, user, dispatch]);
};
