import React, { useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { GroupPositionType } from '@features/stories/types';
import { STORY_HEIGHT, STORY_WIDTH } from '@features/consts';
import { getReducedValue, getScalableValue, makeNewWidgetFromExist } from '@features/stories/utils';
import { StoryEditorAction, useStoryEditorDispatch } from '@modules';
import './WidgetContextMenu.scss';
import { useTranslation } from 'react-i18next';
import { WidgetObjectType, WidgetPositionType } from '@storysdk/react';

const b = block('WidgetContextMenu');

interface WidgetContextMenuProps {
  scaleIndex: number;
  widgetGroupPosition?: GroupPositionType;
  selectedWidgets: WidgetObjectType[];
  isFirstLayer: boolean;
  isLastLayer: boolean;
  presetWidth: number;
  presetHeight: number;
  onClose?(): void;
  onRender?(element: HTMLDivElement): void;
  onLayerDown(widgetIds: string[]): void;
  onLayerUp(widgetIds: string[]): void;
}

type PositionType = {
  top: number | string;
  right: number | string;
  left: number | string;
  bottom: number | string;
};

const MENU_MARGIN = 5;

const convertWidgetPosition = (position: WidgetPositionType) => ({
  x: position.x,
  y: position.y,
  width: position.realWidth,
  height: position.realHeight
});

export const WidgetContextMenu: React.FC<WidgetContextMenuProps> = ({
  scaleIndex,
  widgetGroupPosition,
  selectedWidgets,
  isFirstLayer,
  isLastLayer,
  presetWidth,
  presetHeight,
  onLayerDown,
  onLayerUp,
  onClose,
  onRender
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<PositionType | null>(null);

  const { t } = useTranslation();

  const storyEditorDispatch = useStoryEditorDispatch();
  const storyWidth = presetWidth ?? STORY_WIDTH;
  const storyHeight = presetHeight ?? STORY_HEIGHT;

  const layerPosition =
    selectedWidgets.length === 1
      ? convertWidgetPosition(
        selectedWidgets[0].positionByResolutions[`${storyWidth}x${storyHeight}`]
      )
      : widgetGroupPosition;

  const handleLayerUp = (e: any) => {
    e.preventDefault();

    onLayerUp(selectedWidgets.map((widget) => widget.id));
  };

  const handleLayerDown = (e: any) => {
    e.preventDefault();

    onLayerDown(selectedWidgets.map((widget) => widget.id));
  };

  const handleDublicate = (e: any) => {
    e.preventDefault();
    const newWidgets = selectedWidgets.map((widget) =>
      makeNewWidgetFromExist({
        widget,
        storyWidth,
        storyHeight
      })
    );
    const newWidgetsIds = newWidgets.map((widget) => widget.id);

    if (storyEditorDispatch) {
      storyEditorDispatch({ type: StoryEditorAction.ADD_STORY_WIDGETS, payload: newWidgets });
      storyEditorDispatch({
        type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
        payload: newWidgetsIds
      });
    }

    if (onClose) {
      onClose();
    }
  };

  const handleDelete = (e: any) => {
    e.preventDefault();

    storyEditorDispatch?.({ type: StoryEditorAction.REMOVE_SELECTED_STORY_WIDGETS });

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    const newPosition = {
      right: 0,
      left: 0,
      top: 0,
      bottom: 0
    } as PositionType;

    const menuWidth = ref.current?.clientWidth ?? 0;
    const menuHeight = ref.current?.clientHeight ?? 0;

    if (!layerPosition) {
      return;
    }

    if (layerPosition.x < 0) {
      newPosition.left = MENU_MARGIN;
      newPosition.right = 'auto';
    } else if (layerPosition.x + getReducedValue(menuWidth, scaleIndex) > storyWidth) {
      newPosition.right = MENU_MARGIN;
      newPosition.left = 'auto';
    } else {
      newPosition.left = getScalableValue(layerPosition.x, scaleIndex) + MENU_MARGIN;
      newPosition.right = 'auto';
    }

    if (getScalableValue(layerPosition.y, scaleIndex) < 0) {
      newPosition.top = MENU_MARGIN;
      newPosition.bottom = 'auto';
    } else if (
      layerPosition.y + layerPosition.height + getReducedValue(menuHeight, scaleIndex) >
      storyHeight
    ) {
      newPosition.top = 'auto';
      newPosition.bottom = MENU_MARGIN;
    } else {
      newPosition.top =
        getScalableValue(layerPosition.y, scaleIndex) +
        getScalableValue(layerPosition.height, scaleIndex) +
        MENU_MARGIN;
      newPosition.bottom = 'auto';
    }

    setPosition(newPosition);
  }, [selectedWidgets, storyHeight, scaleIndex]);

  useEffect(() => {
    if (ref.current && onRender) {
      onRender(ref.current);
    }
  }, [ref.current]);

  return (
    <div
      className={b({ show: !!position })}
      ref={ref}
      style={position || undefined}
      onContextMenu={(e: React.MouseEvent) => {
        e.preventDefault();
      }}
    >
      <button className={b('item')} disabled={isLastLayer} onClick={handleLayerUp}>
        {t('editor.contextMenu.layerUp')}
      </button>
      <button className={b('item')} disabled={isFirstLayer} onClick={handleLayerDown}>
        {t('editor.contextMenu.layerDown')}
      </button>
      <button className={b('item')} onClick={handleDublicate}>
        {t('editor.contextMenu.duplicate')}
      </button>
      <button className={b('item')} onClick={handleDelete}>
        {t('editor.contextMenu.delete')}
      </button>
    </div>
  );
};
