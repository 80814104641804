import React, { useCallback } from 'react';
import block from 'bem-cn';
import { SettingsBox, WidgetThemes, WidgetThemesColor } from '@modules';
import {
  SettingsTextarea,
  ControlGroup,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsAnswers,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features/stories/editorSettings/components';
import { ALPHABET, GroupsType, SettingsAnswersType } from '@features';
import { useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import {
  ChooseAnswerWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { Icon, SwitchGroup } from '@components';
import { Drop } from '@custom';
import './ChooseAnswerWidgetSettings.scss';

const b = block('ChooseAnswerWidgetSettings');

interface ChooseAnswerWidgetSettingsProps {
  params: ChooseAnswerWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangePosition(params: WidgetPositionType): void;
  onChange(params: ChooseAnswerWidgetParamsType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
}

export const ChooseAnswerWidgetSettings: React.FC<ChooseAnswerWidgetSettingsProps> = (props) => {
  const { params, position, positionLimits, onChange, onChangeAlign, onChangePosition } = props;

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const handleChangeAnswer = (id: string) => (event: any) => {
    onChange({
      ...params,
      answers: params.answers.map((answer) =>
        answer.id === id ? { ...answer, title: event.target.value } : answer
      )
    });
  };

  const { t } = useTranslation();

  const handleChangeMark = () => {
    onChange({ ...params, markCorrectAnswer: !params.markCorrectAnswer });
  };

  const handleChangeText = (value: string) => {
    onChange({
      ...params,
      text: value
    });
  };

  const handleChangeColor = (color: string) => {
    onChange({ ...params, color });
  };

  const handleCheckAnswer = (id: string) => () => {
    onChange({ ...params, correct: id });
  };

  const handleAddAnswer = () => {
    onChange({
      ...params,
      answers: [
        ...params.answers,
        {
          id: ALPHABET[params.answers.length],
          title: `Answer ${params.answers.length + 1}`,
          score: {
            letter: 'A',
            points: 0
          }
        }
      ]
    });
  };

  const handleChangeTitleHide = () => {
    onChange({
      ...params,
      isTitleHidden: !params.isTitleHidden
    });
  };

  const handleRemoveAnswer = (id: string) => () => {
    if (params.answers.length > 1) {
      onChange({
        ...params,
        answers: params.answers
          .filter((answer) => answer.id !== id)
          .map((answer, i) => ({ ...answer, id: ALPHABET[i] }))
      });
    }
  };

  const handleChangePosition = (field: string) => (value: any) => {
    if (positionLimits.keepRatio && positionLimits.ratioIndex) {
      if (field === 'width') {
        onChangePosition({
          ...position,
          width: value,
          realWidth: value,
          height: Math.round(value / positionLimits.ratioIndex),
          realHeight: Math.round(value / positionLimits.ratioIndex)
        });
      } else if (field === 'height') {
        onChangePosition({
          ...position,
          width: Math.round(value * positionLimits.ratioIndex),
          height: value,
          realHeight: value,
          realWidth: Math.round(value * positionLimits.ratioIndex)
        });
      }
    } else {
      onChangePosition({
        ...position,
        [field]: value
      });
    }
  };

  const dispatch = useAppDispatch();

  const handleDropOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(currentStoriesSlice.actions.setIsPickerOpen(isOpen));
    },
    [dispatch]
  );

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked={position.isHeightLocked}>
            <SettingsInput
              max={positionLimits.maxWidth}
              min={positionLimits.minWidth}
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              postfix="px"
              prefix="H"
              type="number"
              value={position.realHeight}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.title')}>
        <SettingsTextarea isMarginBottom value={params.text} onChange={handleChangeText} />

        <SwitchGroup
          isChecked={params.isTitleHidden}
          label={t('editor.hideTitle')}
          onToggle={handleChangeTitleHide}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.answers')}>
        <div className={b('answersList')}>
          <SettingsAnswers
            answers={params.answers}
            correctAnswerId={params.correct}
            handleAddAnswer={handleAddAnswer}
            handleChangeAnswer={handleChangeAnswer}
            handleCheckCorrectAnswer={handleCheckAnswer}
            handleRemoveAnswer={handleRemoveAnswer}
            isInputDisabled={false}
            leftChildType={SettingsAnswersType.ID}
            markCorrectAnswer={params.markCorrectAnswer}
          />

          <SwitchGroup
            className={b('markCorrectAnswer')}
            isChecked={params.markCorrectAnswer}
            label={t('editor.markCorrectAnswer')}
            onToggle={handleChangeMark}
          />
        </div>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.themes')}>
        <Drop
          control={<WidgetThemesColor color={params.color} hasDarkBackground />}
          handleOpen={handleDropOpen}
        >
          <WidgetThemes selectedColor={params.color} onChange={handleChangeColor} />
        </Drop>
      </SettingsBox.Group>
    </>
  );
};
