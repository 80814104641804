import React from 'react';
import PropTypes from 'prop-types';

const IconFlagRu = ({ className }) => (
  <svg className={className} viewBox="0 85.333 512 341.333" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 85.33v341.332h512V85.33z" fill="#FFF" />
    <path d="M0 85.333h512V426.67H0z" fill="#0052B4" />
    <path d="M0 85.333h512v113.775H0z" fill="#FFF" />
    <path d="M0 312.884h512v113.775H0z" fill="#D80027" />
  </svg>
);

IconFlagRu.propTypes = {
  className: PropTypes.string
};

IconFlagRu.defaultProps = {
  className: ''
};

export default IconFlagRu;
