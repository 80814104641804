import React from 'react';
import PropTypes from 'prop-types';

const IconCheckCircle = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={9} cy={9} fill="#44D937" r={9} />
    <path
      d="M13 6l-5.5 6L5 9.273"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
    />
  </svg>
);

IconCheckCircle.propTypes = {
  className: PropTypes.string
};

IconCheckCircle.defaultProps = {
  className: ''
};

export default IconCheckCircle;
