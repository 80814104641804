import React, { memo, useCallback, useEffect, useState } from 'react';
import block from 'bem-cn';
import { Story } from '@features';
import './StoryEditorSelect.scss';

const b = block('StoryEditorSelect');

interface StoryEditorSelectProps {
  container: HTMLDivElement | null;
  selectable: string;
  selectedStory?: Story;
  scale?: number;
  onChange(ids: string[]): void;
}

const checkIsInsideContainer = (
  x: number,
  y: number,
  container: {
    left: number;
    top: number;
    bottom: number;
    right: number;
  }
) => {
  const { left, right, top, bottom } = container;
  return x <= right && x >= left && y >= top && y <= bottom;
};

const getXY = (
  x: number,
  y: number,
  container: {
    left: number;
    top: number;
    bottom: number;
    right: number;
  }
) => {
  const { left, right, top, bottom } = container;

  let newX = x;
  let newY = y;

  if (x < left) {
    newX = left;
  }

  if (x > right) {
    newX = right;
  }

  if (y > bottom) {
    newY = bottom;
  }

  if (y < top) {
    newY = top;
  }

  return {
    x: newX,
    y: newY
  };
};

export const StoryEditorSelect: React.FC<StoryEditorSelectProps> = memo(
  ({ container, selectable, scale, selectedStory, onChange }) => {
    const [selected, setSelected] = useState<string[]>([]);

    const [isHidden, setIsHidden] = useState(true);
    const [isMousePressed, setIsMousePressed] = useState(false);
    const [isFirst, setIsFirst] = useState(true);

    const initElPosition = {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0
    };

    const initAreaPosition = {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
      bottom: 0,
      right: 0
    };

    const initContainerPosition = {
      left: 0,
      top: 0,
      bottom: 0,
      right: 0
    };

    const [elPosition, setElPosition] = useState(initElPosition);
    const [areaPosition, setAreaPosition] = useState(initAreaPosition);
    const [containerPosition, setContainerPosition] = useState(initContainerPosition);

    useEffect(() => {
      const { x1, y1, x2, y2 } = elPosition;

      const x3 = Math.min(x1, x2);
      const x4 = Math.max(x1, x2);
      const y3 = Math.min(y1, y2);
      const y4 = Math.max(y1, y2);

      setAreaPosition({
        left: x3,
        top: y3,
        width: x4 - x3,
        height: y4 - y3,
        bottom: y4,
        right: x4
      });
    }, [elPosition]);

    const handleMouseDown = useCallback(
      (e: any) => {
        const clientX = e.clientX;
        const clientY = e.clientY;

        const isInsideContainer = checkIsInsideContainer(clientX, clientY, containerPosition);

        if (isInsideContainer) {
          setSelected([]);

          setElPosition((prevState) => ({
            ...prevState,
            x1: clientX,
            y1: clientY,
            x2: clientX,
            y2: clientY
          }));

          setIsMousePressed(true);
        }
      },
      [containerPosition]
    );

    const handleMouseMove = useCallback(
      (e: any) => {
        if (isMousePressed) {
          setIsHidden(false);

          const clientX = e.clientX;
          const clientY = e.clientY;

          const newPos = getXY(clientX, clientY, containerPosition);

          setElPosition((prevState) => ({
            ...prevState,
            x2: newPos.x,
            y2: newPos.y
          }));
        }
      },
      [containerPosition, isMousePressed]
    );

    const handleMouseUp = useCallback(() => {
      setIsHidden(true);
      setIsMousePressed(false);

      const selectableItems = container?.querySelectorAll(selectable);

      selectableItems?.forEach((item) => {
        const selectablePosition = item.getBoundingClientRect();

        const isFullCover =
          areaPosition.right > selectablePosition.right &&
          areaPosition.left < selectablePosition.left &&
          areaPosition.top < selectablePosition.top &&
          areaPosition.bottom > selectablePosition.bottom;

        const isVerticalInside =
          areaPosition.top < selectablePosition.top &&
          areaPosition.bottom > selectablePosition.bottom &&
          areaPosition.left > selectablePosition.left &&
          areaPosition.right < selectablePosition.right;

        const isHorisontalInside =
          areaPosition.left < selectablePosition.left &&
          areaPosition.right > selectablePosition.right &&
          areaPosition.top > selectablePosition.top &&
          areaPosition.bottom < selectablePosition.bottom;

        const isLeftInside =
          areaPosition.left < selectablePosition.left &&
          areaPosition.right > selectablePosition.left &&
          areaPosition.right < selectablePosition.right &&
          areaPosition.top < selectablePosition.top &&
          areaPosition.bottom > selectablePosition.bottom;

        const isRightInside =
          areaPosition.left > selectablePosition.left &&
          areaPosition.left < selectablePosition.right &&
          areaPosition.right > selectablePosition.right &&
          areaPosition.top < selectablePosition.top &&
          areaPosition.bottom > selectablePosition.bottom;

        const isTopInside =
          areaPosition.left < selectablePosition.left &&
          areaPosition.right > selectablePosition.right &&
          areaPosition.top < selectablePosition.top &&
          areaPosition.bottom > selectablePosition.top &&
          areaPosition.bottom < selectablePosition.bottom;

        const isBottomInside =
          areaPosition.left < selectablePosition.left &&
          areaPosition.right > selectablePosition.right &&
          areaPosition.top > selectablePosition.top &&
          areaPosition.top < selectablePosition.bottom &&
          areaPosition.bottom > selectablePosition.bottom;

        const isX1Y1Inside =
          areaPosition.top > selectablePosition.top &&
          areaPosition.top < selectablePosition.bottom &&
          areaPosition.left > selectablePosition.left &&
          areaPosition.left < selectablePosition.right;

        const isX2Y1Inside =
          areaPosition.top > selectablePosition.top &&
          areaPosition.top < selectablePosition.bottom &&
          areaPosition.right > selectablePosition.left &&
          areaPosition.right < selectablePosition.right;

        const isX1Y2Inside =
          areaPosition.bottom > selectablePosition.top &&
          areaPosition.bottom < selectablePosition.bottom &&
          areaPosition.left > selectablePosition.left &&
          areaPosition.left < selectablePosition.right;

        const isX2Y2Inside =
          areaPosition.bottom > selectablePosition.top &&
          areaPosition.bottom < selectablePosition.bottom &&
          areaPosition.right > selectablePosition.left &&
          areaPosition.right < selectablePosition.right;

        if (
          (isFullCover ||
            isVerticalInside ||
            isHorisontalInside ||
            isLeftInside ||
            isRightInside ||
            isTopInside ||
            isBottomInside ||
            isX1Y1Inside ||
            isX2Y1Inside ||
            isX1Y2Inside ||
            isX2Y2Inside) &&
          !(isX1Y1Inside && isX2Y1Inside && isX1Y2Inside && isX2Y2Inside)
        ) {
          if (!selected.some((currentId) => currentId === item.id)) {
            setSelected((prevState) => [item.id, ...prevState]);
          }
        } else if (areaPosition.width && areaPosition.height) {
          setSelected((prevState) => prevState.filter((currentId) => currentId !== item.id));
        }

        setElPosition(initElPosition);
      });
    }, [
      areaPosition.bottom,
      areaPosition.left,
      areaPosition.right,
      areaPosition.top,
      container,
      selectable
    ]);

    useEffect(() => {
      if (!isFirst) {
        onChange(selected);
      } else {
        setIsFirst(false);
      }
    }, [selected]);

    useEffect(() => {
      if (!container) {
        return;
      }

      const resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length || !container) {
            return;
          }

          const { left, top, bottom, right } = container.getBoundingClientRect();

          setContainerPosition({
            left,
            top,
            bottom,
            right
          });
        });
      });

      resizeObserver.observe(container);
      // eslint-disable-next-line consistent-return
      return () => resizeObserver.disconnect();
    }, []);

    useEffect(() => {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }, [handleMouseDown, handleMouseMove, handleMouseUp]);

    const { left, top, width, height } = areaPosition;

    return (
      <div
        className={b({ hidden: isHidden })}
        style={{
          left,
          top,
          width,
          height
        }}
        onContextMenu={(e: React.MouseEvent) => {
          e.preventDefault();
        }}
      />
    );
  }
);
