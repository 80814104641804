import React from 'react';

type PropsType = {
  className?: string;
};

export const ButtonIcon = ({ className = '' }: PropsType) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M13.3333 5.22222H6.66667C4.21207 5.22222 2.22222 7.21207 2.22222 9.66667C2.22222 12.1213 4.21207 14.1111 6.66667 14.1111H13.3333C15.7879 14.1111 17.7778 12.1213 17.7778 9.66667C17.7778 7.21207 15.7879 5.22222 13.3333 5.22222ZM6.66667 3C2.98477 3 0 5.98477 0 9.66667C0 13.3486 2.98477 16.3333 6.66667 16.3333H13.3333C17.0152 16.3333 20 13.3486 20 9.66667C20 5.98477 17.0152 3 13.3333 3H6.66667Z"
      fill="#A49BA4"
      fillRule="evenodd"
    />
    <path d="M5.55566 8.55566H14.4446V10.7779H5.55566V8.55566Z" fill="#A49BA4" opacity="0.5" />
  </svg>
);
