import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { useGetCurrentApp } from '@features/stories/hooks';
import { AccesibleFeaturesByCollaboratorRole, AppCollaborationFeatures } from '../consts';

export const useGetAccessByCollaboratorRole = ({
  feature
}: {
  feature: AppCollaborationFeatures;
}) => {
  const user = useSelector((store: RootState) => store.user.user);
  const app = useGetCurrentApp();

  if (!app) {
    return false;
  }

  if (app.ownerId === user.id) {
    return true;
  }

  if (AccesibleFeaturesByCollaboratorRole[app.collaboratorRole][feature]) return true;

  return false;
};
