import React, { memo, useCallback, useMemo } from 'react';
import { Emoji } from 'emoji-mart';
import {
  SettingsBox,
  WidgetThemes,
  WidgetThemesColor,
  EmojiWidget,
  ColorPicker,
  DropIcon,
  getChangedOpacityColor,
  getOpacityFromColor
} from '@modules';
import { useAppDispatch } from '@store';
import {
  SettingsTextarea,
  ControlGroup,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsFontSelector,
  SettingsSelect,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features/stories/editorSettings/components';
import { FONT_STYLES, GroupsType } from '@features';
import { useTranslation } from 'react-i18next';
import {
  BackgroundColorType,
  SliderWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { useParams } from 'react-router-dom';
import { Button, Icon } from '@components';
import { Drop } from '@custom';

type PropsType = {
  params: SliderWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
  onChangePosition(params: WidgetPositionType): void;
  onChange(params: any): void;
};

export const SliderWidgetSettings = memo(
  ({
    params,
    params: { text, color, emoji },
    position,
    positionLimits,
    onChangePosition,
    onChange,
    onChangeAlign
  }: PropsType) => {
    const { t } = useTranslation();

    const { type } = useParams<{
      type: GroupsType;
    }>();

    const currentStoriesSlice = useCurrentStoriesSlice(type);

    const handleChangeText = useCallback(
      (value) => {
        onChange({ ...params, text: value });
      },
      [params, onChange]
    );

    const handleChangeColor = useCallback(
      (newColor) => {
        onChange({ ...params, color: newColor });
      },
      [params, onChange]
    );

    const handleChangeEmoji = useCallback(
      (newEmoji) => {
        onChange({ ...params, emoji: { name: newEmoji.id, unicode: newEmoji.unified } });
      },
      [params, onChange]
    );

    const handleChangePosition = (field: string) => (value: any) => {
      if (positionLimits.keepRatio && positionLimits.ratioIndex) {
        if (field === 'width') {
          onChangePosition({
            ...position,
            width: value,
            realWidth: value,
            height: Math.round(value / positionLimits.ratioIndex),
            realHeight: Math.round(value / positionLimits.ratioIndex)
          });
        } else if (field === 'height') {
          onChangePosition({
            ...position,
            width: Math.round(value * positionLimits.ratioIndex),
            realWidth: Math.round(value * positionLimits.ratioIndex),
            height: value,
            realHeight: value
          });
        }
      } else {
        onChangePosition({
          ...position,
          [field]: value
        });
      }
    };

    const dispatch = useAppDispatch();

    const handleDropOpen = useCallback(
      (isOpen: boolean) => {
        dispatch(currentStoriesSlice.actions.setIsPickerOpen(isOpen));
      },
      [dispatch]
    );

    const handleChange = useCallback(
      (fieldName, value) => {
        onChange({ ...params, [fieldName]: value });
      },
      [params, onChange]
    );

    const handleChangeOpacity = (fieldName: 'fontColor') => (value: number) => {
      handleChange(fieldName, {
        type: 'color',
        value: getChangedOpacityColor(params[fieldName].value as string, value)
      });
    };

    const fontOpacity = useMemo(() => {
      if (params.fontColor.type === 'color') {
        return getOpacityFromColor(params.fontColor.value);
      }

      return 100;
    }, [params.fontColor]);

    return (
      <>
        <SettingsBox.Group>
          <SettingsBox.Field>
            <SettingsAlign onChange={onChangeAlign} />
          </SettingsBox.Field>
        </SettingsBox.Group>
        <SettingsBox.Group title={t('editor.position')}>
          <SettingsBox.Field>
            <ControlGroup>
              <SettingsInput
                postfix="px"
                prefix="X"
                type="number"
                value={Math.round(position.x)}
                onChange={handleChangePosition('x')}
              />
              <SettingsInput
                postfix="px"
                prefix="Y"
                type="number"
                value={Math.round(position.y)}
                onChange={handleChangePosition('y')}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup isLocked={position.isHeightLocked}>
              <SettingsInput
                max={positionLimits.maxWidth}
                min={positionLimits.minWidth}
                postfix="px"
                prefix="W"
                type="number"
                value={position.width}
                onChange={handleChangePosition('width')}
              />
              <SettingsInput
                disabled={position.isHeightLocked}
                postfix="px"
                prefix="H"
                type="number"
                value={position.realHeight}
                onChange={handleChangePosition('height')}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field separate>
            <SettingsInput
              icon={<Icon name="angle" />}
              max={MAX_ANGLE_VALUE}
              min={MIN_ANGLE_VALUE}
              postfix="°"
              type="number"
              value={position.rotate ? Math.round(position.rotate) : 0}
              onChange={handleChangePosition('rotate')}
            />
          </SettingsBox.Field>
        </SettingsBox.Group>
        <SettingsBox.Group title={t('editor.title')}>
          <SettingsTextarea value={text || ''} onChange={handleChangeText} />
        </SettingsBox.Group>

        <SettingsBox.Group title={t('editor.emoji')}>
          <Drop
            control={
              <Button className="box-button1" color="black" iconName="xxx" onlyIcon>
                <Emoji emoji={emoji.name} set="apple" size={24} />
              </Button>
            }
            handleOpen={handleDropOpen}
          >
            <EmojiWidget selectedEmoji={emoji.name} onChange={handleChangeEmoji} />
          </Drop>
        </SettingsBox.Group>

        <SettingsBox.Group title={t('editor.themes')}>
          <Drop
            control={<WidgetThemesColor color={color} hasDarkBackground />}
            handleOpen={handleDropOpen}
          >
            <WidgetThemes selectedColor={color} onChange={handleChangeColor} />
          </Drop>
        </SettingsBox.Group>

        <SettingsBox.Group title={t('editor.text')}>
          <SettingsBox.Field>
            <SettingsFontSelector
              value={params.fontFamily}
              onChange={(value) => handleChange('fontFamily', value)}
            />
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup sub>
              <SettingsSelect
                options={FONT_STYLES}
                value={params.fontParams}
                onChange={(value) => handleChange('fontParams', value)}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup sub>
              <ColorPicker
                availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
                value={params.fontColor}
                onChange={(value) => handleChange('fontColor', value)}
              />
              <SettingsInput
                icon={<DropIcon />}
                max={100}
                type="number"
                value={fontOpacity}
                onChange={handleChangeOpacity('fontColor')}
              />
            </ControlGroup>
          </SettingsBox.Field>
        </SettingsBox.Group>
      </>
    );
  }
);
