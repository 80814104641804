import React, { useState, useRef } from 'react';
import cn from 'classnames';
import block from 'bem-cn';
import { Icon } from '@components';

import './FreshDropArea.scss';

type PropTypes = {
  className?: string;
  currentImage: string | ArrayBuffer | null;
  defaultImage?: string;
  disabled?: boolean;
  isNoHover?: boolean;
  isNoDefultImage?: boolean;
  handleFileChange?: (params: any) => void;
};

export const FreshDropArea: React.FunctionComponent<PropTypes> = ({
  className,
  currentImage,
  isNoHover,
  disabled,
  defaultImage = '/images/group-default.jpg',
  isNoDefultImage,
  handleFileChange
}) => {
  const b = block('FreshDropArea');
  const [_, setHighligted] = useState<boolean>(false);
  const imageInputRef = useRef<HTMLInputElement | null>(null);

  const dropHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setHighligted(false);

    const dt = e.dataTransfer;
    const files = dt.files;

    handleFileChange?.(files);
  };

  const inputChangeHandler = () => {
    if (imageInputRef.current) {
      const files = imageInputRef.current.files;

      if (files && files.length) {
        handleFileChange?.(files);

        imageInputRef.current.value = '';
      }
    }
  };

  const onClick = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  const getImage = () => {
    if (currentImage) {
      return currentImage as string;
    }

    if (!isNoDefultImage) {
      return defaultImage;
    }

    return undefined;
  };

  return (
    <div
      className={cn(
        className?.toString(),
        `${b({
          noHover: isNoHover,
          noDefultImage: isNoDefultImage && !currentImage,
          disabled
        })}`
      )}
      role="button"
      tabIndex={0}
      onClick={!disabled ? onClick : undefined}
      onDragEnter={!disabled ? () => setHighligted(true) : undefined}
      onDragLeave={!disabled ? () => setHighligted(false) : undefined}
      onDragOver={!disabled ? () => setHighligted(true) : undefined}
      onDrop={!disabled ? dropHandler : undefined}
      onKeyDown={!disabled ? onClick : undefined}
    >
      <input
        accept="image/jpeg,image/png,image/gif,image/webp"
        hidden
        ref={imageInputRef}
        type="file"
        onChange={inputChangeHandler}
      />

      <Icon className={`${b('uploadIcon')}`} name="upload" variant="editor" />

      <img className={b('image')} src={getImage()} />
    </div>
  );
};
