import React from 'react';

type PropsType = {
  className?: string;
};

export const FontSizeIcon = ({ className = '' }: PropsType) => (
  <svg
    className={className}
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0 3.00003H4V12H5V3.00003H9V2.00003H0V3.00003Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9 8.00003H11V12L12 12.038V10.019V8.00003H14V7.00003H9V8.00003Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);
