import React from 'react';
import block from 'bem-cn';
import { STORY_WIDTH, Story, StoryPreview } from '@features';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import './ResultLayerAnalytics.scss';

const b = block('ResultLayerAnalytics');

interface ResultLayerAnalyticsProps {
  story?: Story;
  proportion: number;
  className?: string;
  isLoading?: boolean;
}

const CARD_WIDTH = 68;

export const ResultLayerAnalytics: React.FC<ResultLayerAnalyticsProps> = ({
  story,
  className,
  proportion,
  isLoading
}) => {
  const { t } = useTranslation();

  const scaleIndex = CARD_WIDTH / STORY_WIDTH;

  if (isLoading || !story) {
    return (
      <div className={b()}>
        <div className={b('previewContainer', { isLoading: true })}>
          <Skeleton height="100%" />
        </div>
        <div className={b('analytics')}>
          <div className={b('analyticsItem')}>
            <Skeleton height={20} width={160} />
          </div>
          <div className={b('analyticsItem')}>
            <Skeleton height={20} width={160} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${b().toString()} ${className || ''}`}>
      <div className={b('previewContainer')}>
        <StoryPreview className={b('story')} scaleIndex={scaleIndex} story={story} />
      </div>
      <div className={b('analytics')}>
        <div className={b('analyticsItem')}>
          <p className={b('analyticsItemTitle')}>{t('dashboard.analytics.proportion')}</p>
          <p className={b('analyticsItemCount')}>{proportion}%</p>
        </div>
        <div className={b('analyticsItem')}>
          <p className={b('analyticsItemTitle')}>{t('dashboard.analytics.views')}</p>
          <p className={b('analyticsItemCount')}>{story.statistic?.views || 0}</p>
        </div>
      </div>
    </div>
  );
};
