import React from 'react';
import block from 'bem-cn';
import { Emoji } from 'emoji-mart';
import { GroupsType, LayerWidgetObjectType, ScoreType, SettingsAnswersType } from '@features';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '@store';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { useSelector } from 'react-redux';
import { Icon } from '@components';
import './SettingsScoreAnswers.scss';

const b = block('SettingsScoreAnswers');

interface SettingsAnswersPropsType {
  answers: any[];
  widget: LayerWidgetObjectType;
  type: ScoreType;
  leftChildType: SettingsAnswersType;
  groupType: GroupsType;
  correctAnswerId?: string;
  markCorrectAnswer?: boolean;
}

export const SettingsScoreAnswers: React.FC<SettingsAnswersPropsType> = ({
  answers,
  type,
  widget,
  groupType,
  leftChildType,
  correctAnswerId,
  markCorrectAnswer
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentStorySlice = useCurrentStoriesSlice(groupType);
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);

  const handleChangeScore = (id: string) => (event: any) => {
    const value = event.target.value;

    if (
      (type === ScoreType.LETTERS &&
        (value.length > 1 || (value.length !== 0 && !value.match('^[a-zA-Z]+$')))) ||
      (type === ScoreType.NUMBERS &&
        (value.length > 2 || (value.length !== 0 && !value.match('^[0-9]+$'))))
    ) {
      return;
    }

    dispatch(
      currentStorySlice.actions.updateStoryWidget({
        widgetId: widget.id,
        storyId: widget.storyId,
        layersGroupId: widget.layersGroupId,
        syncToLocales: true,
        currentLocale,
        widget: {
          ...widget,
          content: {
            ...widget.content,
            params: {
              ...widget.content.params,
              // @ts-ignore
              answers: widget.content.params.answers?.map((answer: any) => {
                if (answer.id === id) {
                  return {
                    ...answer,
                    score: {
                      letter: type === ScoreType.LETTERS ? value : answer.score.letter,
                      points: type === ScoreType.NUMBERS ? value : answer.score.points
                    }
                  };
                }
                return answer;
              })
            }
          }
        },
        needToSaveImage: false
      })
    );
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <span className={b('headerText', { uppercase: true })}>{t('editor.logic.answers')}</span>
        <span className={b('scoreText')}>{t('editor.logic.score')}</span>
      </div>
      {answers.map((answer) => (
        <div className={b('answerItem')} key={`answerScore-${answer.id}`}>
          <div className={b('leftAnswerChild')}>
            {leftChildType === SettingsAnswersType.EMOJI && answer.emoji && (
              <Emoji emoji={answer.emoji.name} set="apple" size={18} />
            )}

            {leftChildType === SettingsAnswersType.IMAGE && (
              <>
                {answer.image?.url ? (
                  <img alt="" className={b('image')} src={answer.image?.url} />
                ) : (
                  <div className={b('image', { empty: true })} />
                )}
              </>
            )}

            {leftChildType === SettingsAnswersType.ID && (
              <div
                className={b('answerIdControl', {
                  checked: correctAnswerId === answer.id && markCorrectAnswer
                })}
              >
                {correctAnswerId === answer.id && markCorrectAnswer ? (
                  <Icon name="check" variant="editor" />
                ) : (
                  answer.id
                )}
              </div>
            )}
          </div>
          <div className={b('answerContainer')}>
            <span className={b('answerInput')}>{answer.title}</span>
            <input
              className={b('scoreInput')}
              type="text"
              value={type === ScoreType.LETTERS ? answer.score.letter : answer.score.points}
              onChange={handleChangeScore(answer.id)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
