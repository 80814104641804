import React from 'react';
import PropTypes from 'prop-types';

const IconQuestion = ({ className }) => (
  <svg
    className={className}
    height={12}
    viewBox="0 0 12 12"
    width={12}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M6 12A6 6 0 106 0a6 6 0 000 12zm.556-6.169A1 1 0 016 6h-.5v1.5h1v-.564A2 2 0 104 5h1a1 1 0 111.556.831zm.094 2.794a.625.625 0 11-1.25 0 .625.625 0 011.25 0z"
      fillRule="evenodd"
    />
  </svg>
);

IconQuestion.propTypes = {
  className: PropTypes.string
};

IconQuestion.defaultProps = {
  className: ''
};

export default IconQuestion;
