import { WidgetsTypes } from '@storysdk/react';
import { getPercent } from './getPercent';

const noDataAnswer = {
  answer: { title: '—' },
  count: 0
};

const getEmptyAnswers = (type: WidgetsTypes, params?: any) => {
  switch (type) {
    case WidgetsTypes.SLIDER:
      return [
        {
          answer: { title: 'Left' },
          count: 0
        },
        {
          answer: { title: 'Right' },
          count: 0
        }
      ];
    case WidgetsTypes.QUESTION:
      return [
        {
          answer: { title: 'Confirm' },
          count: 0
        },
        {
          answer: { title: 'Decline' },
          count: 0
        }
      ];

    case WidgetsTypes.CLICK_ME:
    case WidgetsTypes.SWIPE_UP:
    case WidgetsTypes.CHOOSE_ANSWER:
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS:
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE:
    case WidgetsTypes.QUIZ_ONE_ANSWER:
      return (
        params?.answers?.map((answer: any) => ({
          answer: { title: answer.title, emoji: answer.emoji?.unicode },
          count: 0,
          percent: 0
        })) ?? [noDataAnswer]
      );

    case WidgetsTypes.EMOJI_REACTION:
      return (
        params?.emoji?.map((answer: any) => ({
          answer: {
            title: answer.name,
            emoji: answer.unicode
          },
          count: 0,
          percent: 0
        })) ?? [noDataAnswer]
      );

    default:
      return [noDataAnswer];
  }
};

export const getAnswerVariantsData = (type: WidgetsTypes, data: any, params?: any) => {
  const input = [];
  let totalCount = 0;

  for (const key in data) {
    if (data[key] || data[key] === 0) {
      input.push({
        answer: { title: key },
        count: data[key]
      });
    }
  }

  if (!input.length) {
    return getEmptyAnswers(type, params);
  }

  input.sort((a: any, b: any) => b.count - a.count);

  if (type === WidgetsTypes.SLIDER) {
    let left = 0;
    let right = 0;

    totalCount = input.length;

    input.forEach((item) => {
      if (+item.answer.title <= 50) {
        left += 1;
      } else {
        right += 1;
      }
    });

    return [
      { answer: { title: 'Left' }, count: left, percent: getPercent(+left, input.length) },
      { answer: { title: 'Right' }, count: right, percent: getPercent(+right, input.length) }
    ];
  }

  if (
    type === WidgetsTypes.SWIPE_UP ||
    type === WidgetsTypes.CLICK_ME ||
    type === WidgetsTypes.CHOOSE_ANSWER ||
    type === WidgetsTypes.QUESTION ||
    type === WidgetsTypes.QUIZ_MULTIPLE_ANSWERS ||
    type === WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE ||
    type === WidgetsTypes.QUIZ_ONE_ANSWER
  ) {
    input.forEach((item) => {
      totalCount += item.count;
    });

    return input.map((item: any) => {
      const currentAnswer = params?.answers?.find((answer: any) => answer.id === item.answer.title);
      const answerTitle = currentAnswer?.title || currentAnswer?.text;

      return {
        ...item,
        answer: { title: answerTitle ?? item.answer.title, emoji: currentAnswer?.emoji?.unicode },
        percent: getPercent(+item.count, +totalCount)
      };
    });
  }

  if (type === WidgetsTypes.EMOJI_REACTION) {
    input.forEach((item) => {
      totalCount += item.count;
    });

    return input.map((item: any) => ({
      ...item,
      answer: {
        title: params.emoji.find((emoji: any) => emoji.unicode === item.answer.title).name,
        emoji: item.answer.title
      },
      percent: getPercent(+item.count, +totalCount)
    }));
  }

  return input;
};
