import React from 'react';
import block from 'bem-cn';
import { StoriesGroupAnalytic } from '@features';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { shortEnglishHumanizer } from '@utils';
import './GroupGeneralAnalytics.scss';

const b = block('GroupGeneralAnalytics');

interface GroupGeneralAnalyticsProps {
  groupStatistic?: StoriesGroupAnalytic;
  isLoading?: boolean;
}

export const GroupGeneralAnalytics: React.FC<GroupGeneralAnalyticsProps> = ({
  groupStatistic,
  isLoading
}) => {
  const { t } = useTranslation();

  const completeTime = groupStatistic?.timeOfCompletion
    ? shortEnglishHumanizer(groupStatistic.timeOfCompletion * 1000)
    : '0s';

  return (
    <div className={b()}>
      <div className={b('container')}>
        <div className={b('item')}>
          {isLoading ? (
            <Skeleton
              height={50}
              style={{
                borderRadius: 12
              }}
              width={120}
            />
          ) : (
            <>
              <p className={b('itemTitle')}>{t('dashboard.analytics.groups.views')}</p>
              <p className={b('itemValue')}>{groupStatistic?.open ?? 0}</p>
            </>
          )}
        </div>
        <div className={b('item')}>
          {isLoading ? (
            <Skeleton
              height={50}
              style={{
                borderRadius: 12
              }}
              width={120}
            />
          ) : (
            <>
              <p className={b('itemTitle')}>{t('dashboard.analytics.groups.starts')}</p>
              <p className={b('itemValue')}>{groupStatistic?.start ?? 0}</p>
            </>
          )}
        </div>
        <div className={b('item')}>
          {isLoading ? (
            <Skeleton
              height={50}
              style={{
                borderRadius: 12
              }}
              width={120}
            />
          ) : (
            <>
              <p className={b('itemTitle')}>{t('dashboard.analytics.groups.finish')}</p>
              <p className={b('itemValue')}>{groupStatistic?.finish ?? 0}</p>
            </>
          )}
        </div>
      </div>
      <div className={b('container')}>
        <div className={b('item')}>
          {isLoading ? (
            <Skeleton
              height={50}
              style={{
                borderRadius: 12
              }}
              width={120}
            />
          ) : (
            <>
              <p className={b('itemTitle')}>{t('dashboard.analytics.groups.completionRate')}</p>
              <p className={b('itemValue')}>{groupStatistic?.completionRate ?? 0}%</p>
            </>
          )}
        </div>
        <div className={b('item')}>
          {isLoading ? (
            <Skeleton
              height={50}
              style={{
                borderRadius: 12
              }}
              width={120}
            />
          ) : (
            <>
              <p className={b('itemTitle')}>{t('dashboard.analytics.groups.timeForComplete')}</p>
              <p className={b('itemValue')}>{completeTime}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
