import React from 'react';
import PropTypes from 'prop-types';

const IconAndroid = ({ className }) => (
  <svg
    className={className}
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 7.336a1 1 0 00-1 1V13a1 1 0 102 0V8.336a1 1 0 00-1-1zM14.33 7.336a1 1 0 00-1 1V13a1 1 0 102 0V8.336a1 1 0 00-1-1zM12.33 7.336H5a.333.333 0 00-.334.333v5.332c0 .792.557 1.475 1.333 1.633V17a1 1 0 102 0v-2.333h1.333V17a1 1 0 102 0v-2.366a1.666 1.666 0 001.332-1.633V7.67a.333.333 0 00-.333-.333zM11.088 3.385l.812-.812a.333.333 0 10-.47-.471l-.947.946a4.561 4.561 0 00-3.635 0l-.946-.947a.333.333 0 00-.471.471l.811.813a3.517 3.517 0 00-1.576 2.951c0 .184.15.333.333.333h7.332c.184 0 .333-.149.333-.333a3.517 3.517 0 00-1.576-2.951z" />
    <path
      d="M6.999 5.336a.333.333 0 100-.666.333.333 0 000 .666zM10.331 5.336a.333.333 0 100-.666.333.333 0 000 .666z"
      fill="#FAFAFA"
    />
  </svg>
);

IconAndroid.propTypes = {
  className: PropTypes.string
};

IconAndroid.defaultProps = {
  className: ''
};

export default IconAndroid;
