import React, { useRef } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { GroupsType } from '@features/groups';
import { FreshModal, FreshModalTitle, FreshButton, FreshLinkOutput } from '@components/_fresh';
import { useShareLink } from '@hooks';
import { downloadSvg } from '@utils';
import './StoryShareModal.scss';
import { Icon } from '@components';

interface StoryShareModalProps {
  theme?: 'light' | 'dark';
  isOpen: boolean;
  groupId: string;
  storyId: string;
  layerId: string;
  type: GroupsType;
  isAfterPublish?: boolean;
  onClose(): void;
}

const b = block('StoryShareModal');

export const StoryShareModal: React.FC<StoryShareModalProps> = (props) => {
  const {
    isOpen,
    theme = 'light',
    type,
    layerId,
    isAfterPublish,
    groupId,
    storyId,
    onClose
  } = props;

  const { t } = useTranslation();

  const link = useShareLink({
    groupId,
    storyId,
    layerId,
    type
  });

  const qrCodeRef = useRef(null);

  return (
    <FreshModal isOpen={isOpen} size="md" theme={theme} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle centered>
          {isAfterPublish
            ? t('dashboard.modals.share.publishedTitle')
            : t('dashboard.modals.share.title')}
        </FreshModalTitle>

        <FreshLinkOutput className={b('link').toString()} theme={theme} value={link} />

        <p className={b('desc', { white: theme === 'dark' })}>{t('dashboard.modals.share.desc')}</p>

        <div className={b('qrContainer')}>
          <div className={b('qrWrapper')}>
            <QRCode ref={qrCodeRef} size={172} value={link} />
          </div>
        </div>

        <section className={b('btnContaier')}>
          <FreshButton
            leftIcon={<Icon className={b('btnIcon').toString()} name="play" />}
            text={t('dashboard.modals.share.preview')}
            onClick={() => {
              window.open(link, '_blank');
            }}
          />
        </section>
      </div>
    </FreshModal>
  );
};
