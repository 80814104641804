import React from 'react';
import block from 'bem-cn';
import './FilterControlGroup.scss';

const b = block('FilterControlGroup');

export type FilterControlGroupItemType = {
  id: string;
  title?: string;
  count?: number;
  countText?: string;
  color?: string;
  icon?: React.ReactElement;
  disabled?: boolean;
};

interface Props {
  className?: string;
  color?: string;
  items: Array<FilterControlGroupItemType>;
  current: FilterControlGroupItemType['id'];
  onChange(id: FilterControlGroupItemType['id']): void;
}

export const FilterControlGroup: React.FC<Props> = (props) => {
  const { items, current, color, className, onChange } = props;

  const handleClick = (id: FilterControlGroupItemType['id']) => () => {
    onChange(id);
  };

  return (
    <div className={`${b()} ${className}`}>
      {items.map((btn) => (
        <button
          className={b('btn', {
            current: current === btn.id,
            color: btn.color,
            commonColor: color,
            disabled: btn.disabled
          })}
          key={btn.id}
          onClick={!btn.disabled ? handleClick(btn.id) : undefined}
        >
          {btn.icon && <span className={b('icon', { color: btn.color })}>{btn.icon}</span>}
          {btn.title && btn.title}
          {(btn.count || btn.count === 0) &&
            ` (${+btn.count}${btn.countText ? ' ' : ''}${btn.countText ?? ''})`}
        </button>
      ))}
    </div>
  );
};
