import React from 'react';
import PropTypes from 'prop-types';

const IconElementAlignCenterVertical = ({ className }) => (
  <svg
    className={className}
    height="15"
    viewBox="0 0 16 15"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1210_12886)">
      <path d="M0 8L0 7L16 7V8L0 8Z" />
      <path d="M3.50001 15C2.67158 15 2 14.3284 2 13.5L2.00001 1.5C2.00001 0.671572 2.67158 -9.53674e-07 3.50001 -9.53674e-07H5.5C6.32843 -9.53674e-07 7 0.671572 7 1.5L7 13.5C7 14.3284 6.32843 15 5.50001 15L3.50001 15Z" />
      <path d="M10.5 12C9.67157 12 9 11.3284 9 10.5L9 4.50001C9 3.67158 9.67158 3.00001 10.5 3.00001H12.5C13.3284 3.00001 14 3.67158 14 4.50001V10.5C14 11.3284 13.3284 12 12.5 12L10.5 12Z" />
    </g>
    <defs>
      <clipPath id="clip0_1210_12886">
        <rect fill="white" height="16" transform="translate(0 15) rotate(-90)" width="15" />
      </clipPath>
    </defs>
  </svg>
);

IconElementAlignCenterVertical.propTypes = {
  className: PropTypes.string
};

IconElementAlignCenterVertical.defaultProps = {
  className: ''
};

export default IconElementAlignCenterVertical;
