import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { Emoji, EmojiData } from 'emoji-mart';
import { EmojiWidget } from '@modules';
import { SettingsAnswersType } from '@features';
import { Icon } from '@components';
import { Drop } from '@custom';
import { FreshDropArea } from '@components/_fresh';
import './SettingsAnswers.scss';

const b = block('SettingsAnswers');
interface SettingsAnswersPropsType {
  answers: any[];
  isInputDisabled: boolean;
  leftChildType: SettingsAnswersType;
  correctAnswerId?: string;
  markCorrectAnswer?: boolean;
  handleDropChangeOpen?: (isOpen: boolean) => void;
  handleCheckCorrectAnswer?: (id: string) => () => void;
  handleChangeAnswer?: (id: string) => (event: any) => void;
  handleChangeEmoji?: (index: number) => (emoji: EmojiData) => void;
  handleRemoveAnswer: (id: any) => () => void;
  handleAddAnswer?: () => void;
  handleImageChange?: (answerId: string, files: File[]) => void;
}

export const SettingsAnswers: React.FC<SettingsAnswersPropsType> = ({
  answers,
  isInputDisabled,
  leftChildType,
  correctAnswerId,
  markCorrectAnswer,
  handleCheckCorrectAnswer,
  handleChangeAnswer,
  handleRemoveAnswer,
  handleAddAnswer,
  handleChangeEmoji,
  handleDropChangeOpen,
  handleImageChange
}) => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      {answers.map((answer, index) => (
        <div className={b('answerItem')} key={`answer-${answer.id}`}>
          <div className={b('leftAnswerChild')}>
            {leftChildType === SettingsAnswersType.EMOJI && (
              <>
                {answer.emoji && handleChangeEmoji && (
                  <Drop
                    control={<Emoji emoji={answer.emoji.name} set="apple" size={18} />}
                    handleOpen={handleDropChangeOpen}
                    isPreventClose
                  >
                    <EmojiWidget onChange={handleChangeEmoji(index)} />
                  </Drop>
                )}
              </>
            )}

            {leftChildType === SettingsAnswersType.IMAGE && handleImageChange && (
              <FreshDropArea
                className={`${b('dropArea')}`}
                currentImage={answer.image?.url}
                handleFileChange={(files) => handleImageChange(answer.id, files)}
                isNoDefultImage
                isNoHover
              />
            )}

            {leftChildType === SettingsAnswersType.ID && handleCheckCorrectAnswer && (
              <button
                className={b('answerIdControl', {
                  checked: correctAnswerId === answer.id && markCorrectAnswer
                })}
                onClick={markCorrectAnswer ? handleCheckCorrectAnswer(answer.id) : undefined}
              >
                {correctAnswerId === answer.id && markCorrectAnswer ? (
                  <Icon name="check" variant="editor" />
                ) : (
                  answer.id
                )}
              </button>
            )}
          </div>
          <input
            className={b('answerInput')}
            disabled={isInputDisabled}
            type="text"
            value={answer.title}
            onChange={handleChangeAnswer && handleChangeAnswer(answer.id)}
          />
          {answers.length > 1 && (
            <button className={b('removeBtn')} onClick={handleRemoveAnswer(answer.id)}>
              <Icon className={b('removeIcon').toString()} color="#AFAFAF" name="delete" />
            </button>
          )}
        </div>
      ))}
      <button
        className={b('answerItem', { center: true, marginBottomExpand: true })}
        onClick={handleAddAnswer}
      >
        <div className={b('btn')}>
          <Icon className={b('icon').toString()} name="add" variant="editor" />
        </div>
        <span className={b('addText')}>{t('editor.add')}</span>
      </button>
    </div>
  );
};
