import React from 'react';
import PropTypes from 'prop-types';

const IconChevronDown = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="4"
    viewBox="0 0 8 4"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 1L7 0L4 1L1 0L0 1L4 4L8 1Z" />
  </svg>
);

IconChevronDown.propTypes = {
  className: PropTypes.string
};

IconChevronDown.defaultProps = {
  className: ''
};

export default IconChevronDown;
