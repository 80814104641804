import React, { useEffect } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useGetActionByAccess } from '@features';
import { AppFeatures } from '@features/user/consts';
import { useWindowHeight } from '@react-hook/window-size';
import { IconAddPlus, SearchSelect } from '@components';
import { useVisible } from '@hooks';
import { LocaleType } from '../LocaleItem/LocaleItem';
import './LocaleAdd.scss';

const b = block('LocaleAdd');

interface Props {
  appLocales: string[];
  locales: LocaleType[];
  onLocaleClick: (locale: LocaleType) => void;
}

export const LocaleAdd: React.FC<Props> = ({ locales, appLocales, onLocaleClick }) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const [isTop, setIsTop] = React.useState(false);

  const { t } = useTranslation();

  const addLocale = (shortName: string) => {
    const locale = locales.find((locale) => locale.shortName === shortName);

    if (!locale) {
      return;
    }

    onLocaleClick(locale);
    setIsVisible(false);
  };

  const onAddButtonClick = useGetActionByAccess();

  const height = useWindowHeight();

  useEffect(() => {
    if (ref.current && height < ref.current.getBoundingClientRect().bottom) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }
  }, [height, ref, isVisible]);

  return (
    <div className={b()}>
      <button
        className={b('btn', { active: isVisible })}
        onClick={(e) => {
          e.preventDefault();

          onAddButtonClick(AppFeatures.ALL_LOCALIZATIONS, () => {
            setIsVisible(!isVisible);
          });
        }}
      >
        <span className={b('addIconWrapper')}>
          <IconAddPlus className={b('addIcon').toString()} />
        </span>
        {t('dashboard.apps.localizations.add')}
      </button>

      {isVisible && (
        <div
          className={b('select', {
            top: appLocales.length > 2 && isTop
          })}
          ref={ref}
        >
          <SearchSelect
            className={b('selectModal').toString()}
            options={locales
              .filter((locale) => !appLocales.includes(locale.shortName))
              .map((locale) => ({
                value: locale.shortName,
                label: locale.name,
                icon: locale.icon
              }))}
            onChange={addLocale}
          />
        </div>
      )}
    </div>
  );
};
