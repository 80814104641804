import React from 'react';
import PropTypes from 'prop-types';

const IconAndroinFresh = ({ className }) => (
  <svg
    className={className}
    fill="#3DDC84"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.25867 11.9495H22.8091V23.1979C22.8091 23.1979 22.6063 24.2 21.8152 24.2C21.0241 24.2 20.3039 24.2 20.3039 24.2V27.8097C20.3039 27.8097 20.2431 29.3333 18.7927 29.3333C17.3423 29.3333 17.2814 27.8097 17.2814 27.8097V24.3023H14.7965V27.912C14.7965 27.912 14.8168 29.2924 13.2447 29.272C13.2447 29.272 11.7943 29.2515 11.7943 27.8097C11.7943 26.3679 11.7943 24.2409 11.7943 24.2409H10.3236C10.3236 24.2409 9.24853 24.3023 9.24853 23.157C9.25867 22.0015 9.25867 11.9495 9.25867 11.9495Z" />
    <path d="M23.7118 13.422C23.7118 13.422 23.7118 11.9393 25.1825 11.9393C26.6532 11.9393 26.6633 13.2073 26.6633 13.4322C26.6633 13.647 26.6633 19.8847 26.6633 19.8847C26.6633 19.8847 26.8053 21.2754 25.2839 21.2754C23.7625 21.2754 23.7118 20.2631 23.7118 19.8234C23.7118 19.3939 23.7118 13.422 23.7118 13.422Z" />
    <path d="M5.3335 13.422C5.3335 13.422 5.3335 11.9393 6.80417 11.9393C8.27484 11.9393 8.28498 13.2073 8.28498 13.4322C8.28498 13.647 8.28498 19.8847 8.28498 19.8847C8.28498 19.8847 8.42698 21.2754 6.90559 21.2754C5.38421 21.2754 5.3335 20.2631 5.3335 19.8234C5.3335 19.3939 5.3335 13.422 5.3335 13.422Z" />
    <path
      clipRule="evenodd"
      d="M20.4966 4.4029L19.3201 6.1515C21.2168 7.07182 22.5556 8.79998 22.8091 10.8963H9.24854C9.51224 8.78975 10.8612 7.07182 12.7579 6.1515L11.5205 4.31086C11.5205 4.31086 11.419 4.22906 11.632 4.06544C11.632 4.06544 11.7538 3.96319 11.8349 4.01432C11.9059 4.05522 12.8491 5.42547 13.2143 5.95721C14.0967 5.59931 15.0907 5.40502 16.1252 5.41525C17.1293 5.42547 18.0827 5.62999 18.9347 5.97767L20.1518 4.15748C20.1518 4.15748 20.2837 3.92228 20.4459 4.05522C20.4459 4.05522 20.6995 4.11657 20.4966 4.4029ZM13.1534 9.07606C13.5693 9.07606 13.904 8.72839 13.904 8.30913C13.904 7.87965 13.5693 7.54219 13.1534 7.55242C12.7274 7.55242 12.3927 7.88987 12.3927 8.30913C12.3927 8.73861 12.7376 9.07606 13.1534 9.07606ZM18.9651 9.07606C19.3809 9.07606 19.7156 8.72839 19.7156 8.30913C19.7156 7.87965 19.3708 7.54219 18.9651 7.55242C18.5493 7.55242 18.2145 7.88987 18.2145 8.30913C18.2145 8.73861 18.5493 9.07606 18.9651 9.07606Z"
      fillRule="evenodd"
    />
  </svg>
);

IconAndroinFresh.propTypes = {
  className: PropTypes.string
};

IconAndroinFresh.defaultProps = {
  className: ''
};

export default IconAndroinFresh;
