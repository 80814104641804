import React from 'react';
import PropTypes from 'prop-types';

const IconLogoAndroid = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.78673 6.0517H13.2558V13.0819C13.2558 13.0819 13.129 13.7082 12.6345 13.7082C12.1401 13.7082 11.69 13.7082 11.69 13.7082V15.9643C11.69 15.9643 11.652 16.9166 10.7455 16.9166C9.839 16.9166 9.80096 15.9643 9.80096 15.9643V13.7722H8.24788V16.0282C8.24788 16.0282 8.26056 16.891 7.278 16.8782C7.278 16.8782 6.37151 16.8655 6.37151 15.9643C6.37151 15.0632 6.37151 13.7338 6.37151 13.7338H5.45234C5.45234 13.7338 4.78039 13.7722 4.78039 13.0564C4.78673 12.3342 4.78673 6.0517 4.78673 6.0517Z"
      fill="#3DDC84"
    />
    <path
      d="M13.8199 6.97201C13.8199 6.97201 13.8199 6.0453 14.7391 6.0453C15.6583 6.0453 15.6646 6.8378 15.6646 6.97841C15.6646 7.11262 15.6646 11.0112 15.6646 11.0112C15.6646 11.0112 15.7534 11.8804 14.8025 11.8804C13.8516 11.8804 13.8199 11.2477 13.8199 10.9728C13.8199 10.7044 13.8199 6.97201 13.8199 6.97201Z"
      fill="#3DDC84"
    />
    <path
      d="M2.3335 6.97201C2.3335 6.97201 2.3335 6.0453 3.25267 6.0453C4.17183 6.0453 4.17817 6.8378 4.17817 6.97841C4.17817 7.11262 4.17817 11.0112 4.17817 11.0112C4.17817 11.0112 4.26692 11.8804 3.31606 11.8804C2.36519 11.8804 2.3335 11.2477 2.3335 10.9728C2.3335 10.7044 2.3335 6.97201 2.3335 6.97201Z"
      fill="#3DDC84"
    />
    <path
      clipRule="evenodd"
      d="M11.8105 1.33506L11.0751 2.42794C12.2605 3.00314 13.0973 4.08324 13.2558 5.39342H4.7804C4.94521 4.07685 5.78831 3.00314 6.97373 2.42794L6.20036 1.27754C6.20036 1.27754 6.13697 1.22641 6.27009 1.12416C6.27009 1.12416 6.34616 1.06024 6.39687 1.0922C6.44124 1.11776 7.03078 1.97417 7.25899 2.30651C7.81049 2.08282 8.43172 1.96139 9.07831 1.96778C9.70588 1.97417 10.3018 2.10199 10.8342 2.31929L11.5949 1.18167C11.5949 1.18167 11.6773 1.03468 11.7788 1.11776C11.7788 1.11776 11.9372 1.15611 11.8105 1.33506ZM7.22095 4.25579C7.48085 4.25579 7.69004 4.03849 7.69004 3.77646C7.69004 3.50803 7.48085 3.29712 7.22095 3.30351C6.9547 3.30351 6.74551 3.51442 6.74551 3.77646C6.74551 4.04489 6.96104 4.25579 7.22095 4.25579ZM10.8532 4.25579C11.1132 4.25579 11.3223 4.03849 11.3223 3.77646C11.3223 3.50803 11.1068 3.29712 10.8532 3.30351C10.5933 3.30351 10.3842 3.51442 10.3842 3.77646C10.3842 4.04489 10.5933 4.25579 10.8532 4.25579Z"
      fill="#3DDC84"
      fillRule="evenodd"
    />
  </svg>
);

IconLogoAndroid.propTypes = {
  className: PropTypes.string
};

IconLogoAndroid.defaultProps = {
  className: ''
};

export default IconLogoAndroid;
