import React from 'react';
import { CreateAppContainer } from '@features';
import { useTranslation } from 'react-i18next';
import block from 'bem-cn';
import './AddAppPage.scss';

const b = block('AddAppPage');

export const AddAppPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={b()}>
      <CreateAppContainer className={b('container')} title={t('dashboard.apps.addFirstApp')} />
    </div>
  );
};
