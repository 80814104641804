import { MutableRefObject, useEffect, useState } from 'react';

export const useElementSize = (target: MutableRefObject<HTMLElement | null>) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    let resizeObserver: ResizeObserver;
    if (target.current) {
      resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          const entry = entries[0];
          setSize({ width: entry.contentRect.width, height: entry.contentRect.height });
        });
      });
      resizeObserver.observe(target.current);
    }
    return () => {
      if (resizeObserver && target.current) {
        resizeObserver.unobserve(target.current);
      }

      resizeObserver?.disconnect();
    };
  }, [target.current]);

  return size;
};
