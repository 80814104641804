import React, { useCallback, useState, useEffect } from 'react';
import { DropdownToggle, Dropdown, DropdownMenu } from 'reactstrap';
import './Drop.scss';

const CLASS_NAME = 'Drop';

type PropsTypes = {
  className?: string;
  control: any;
  children: any;
  isRight?: boolean;
  isPreventClose?: boolean;
  handleOpen?: (isOpen: boolean) => void;
};

export const Drop = ({
  className,
  isPreventClose,
  control,
  children,
  isRight,
  handleOpen
}: PropsTypes) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = useCallback(() => setDropdownOpen(!dropdownOpen), [dropdownOpen]);

  useEffect(() => {
    if (handleOpen) {
      handleOpen(dropdownOpen);
    }
  }, [dropdownOpen, handleOpen]);

  return (
    <Dropdown
      className={`${CLASS_NAME} ${className ?? ''}`}
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle className={`${CLASS_NAME}__toggle`} color="transperent">
        {control}
      </DropdownToggle>
      <DropdownMenu
        className={`${CLASS_NAME}__dropdown ${isRight ? `${CLASS_NAME}__dropdown_right` : null}`}
        flip
        right={isRight}
        onClick={
          !isPreventClose
            ? (e) => {
              e.stopPropagation();
              setDropdownOpen(false);
            }
            : undefined
        }
      >
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};
