import React from 'react';
import { Tooltip as ReactTooltip } from 'reactstrap';
import block from 'bem-cn';
import './Tooltip.scss';

const b = block('Tooltip');

interface TooltipProps {
  id: string;
  text: string;
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ id, text, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={b()}>
      {children}
      <ReactTooltip isOpen={isOpen} target={id} toggle={toggle}>
        {text}
      </ReactTooltip>
    </div>
  );
};
