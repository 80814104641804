import React from 'react';
import './NoWidgetsAccessMessage.scss';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FreshButton } from '@components/_fresh';
import { Icon } from '@components';

const b = block('NoWidgetsAccessMessage');

interface NoWidgetsAccessMessageProps {
  isAppOwner?: boolean;
}

export const NoWidgetsAccessMessage: React.FC<NoWidgetsAccessMessageProps> = ({ isAppOwner }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={b()}>
      <div className={b('text')}>
        {isAppOwner
          ? t('notification.subscription.dontHaveAccess')
          : t('notification.subscription.dontHaveAccessCollaborator')}
      </div>
      {isAppOwner && (
        <FreshButton
          leftIcon={<Icon name="upgrade-arrow" />}
          size="md"
          text={t('plans.upgrade')}
          onClick={() => {
            history.push('/account/plans');
          }}
        />
      )}
    </div>
  );
};
