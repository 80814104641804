import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components';

import './ReturnToFigmaMessage.scss';

const b = block('ReturnToFigmaMessage');

export const ReturnToFigmaMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={b()}>
      <Icon className={b('icon').toString()} name="circle-success-fresh" />
      <p className={b('title').toString()}>{t('auth.returnToFigma')}</p>
    </section>
  );
};
