import { fetchGetApp } from '@features';
import { RootState, useAppDispatch } from '@store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useSelectApp = (appId?: string) => {
  const appsState = useSelector((store: RootState) => store.appManager);
  const dispatch = useAppDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (
      appId &&
      appsState.locales?.length &&
      (!appsState.current || appsState.current.id !== appId || isFirstLoad)
    ) {
      dispatch(fetchGetApp({ appId }));
      setIsFirstLoad(false);
    } else if (!appId && appsState.locales?.length && appsState.appsList.length) {
      dispatch(fetchGetApp({ appId: appsState.appsList[0].id }));
    }
  }, [dispatch, appId, appsState.appsList]);
};
