import React from 'react';

type PropsType = {
  className?: string;
};

export const RectangleIcon = ({ className = '' }: PropsType) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#AFAFAF"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect height="15" rx="1" strokeWidth="1.5" width="15" x="2.5" y="2.5" />
  </svg>
);
