import hexToRgba from 'hex-to-rgba';
import hexOpacity from 'hex-opacity';
import rgb2hex from 'rgb2hex';
import { getScalableValue } from '@features';
import React from 'react';
import parseColor from 'parse-color';
import { GoogleFont } from '@features/stories/editorSettings/components/SettingsFontSelector/SettingsFontSelector';
import { BackgroundType, BorderType, WidgetPositionLimitsType } from '@storysdk/react';
import _ from 'lodash';
import { getFontsLink } from '@utils';

export * from './getHtmlWidgetLayout';
export * from './getWidgetImageParts';
export * from './getHtmlTextLayout';
export * from './getAdaptedTextParams';

function renderColor(color: string, opacity?: number) {
  if (color.includes('#') && opacity) {
    color = hexOpacity.create(color, opacity / 100);
  }

  return color;
}

function renderGradient(colors: string[], opacity?: number) {
  const first = renderColor(colors[0], opacity);
  const second = renderColor(colors[1], opacity);

  return `linear-gradient(180deg, ${first} 0%, ${second} 100%)`;
}

export const renderBackgroundStyles = (background?: BackgroundType, opacity?: number): string => {
  let color = background?.value ?? '#FFFFFF';

  switch (background?.type) {
    case 'color':
      if (color.includes('#') && opacity !== undefined) {
        color = hexOpacity.create(background.value, opacity / 100);
      } else if (opacity !== undefined) {
        const parsed = parseColor(background.value);
        return `rgba(${parsed.rgb[0]}, ${parsed.rgb[1]}, ${parsed.rgb[2]}, ${opacity / 100})`;
      }
      return color as string;
    case 'gradient':
      return `linear-gradient(180deg, ${background.value[0]} 0%, ${background.value[1]} 100%)`;
    case 'image':
      return `url('${background.value}') center / cover no-repeat`;
    case 'transparent':
      return "url('/images/transparent-background.jpg') center / cover no-repeat";
    default:
      return 'transparent';
  }
};

export const renderColorFromRgba = (color: string) => {
  if (color.includes('rgba')) {
    return rgb2hex(color).hex;
  }

  return color.slice(0, 7);
};

export const roundToPercent = (value?: number) => (value ? Math.round(value * 100) : 0);

export const getChangedOpacityColor = (color: string, opacity: number) => {
  if (_.isNaN(opacity)) {
    return color;
  }

  const parsedColor = parseColor(color);

  const newColor = `rgba(${parsedColor.rgba[0]}, ${parsedColor.rgba[1]}, ${parsedColor.rgba[2]}, ${opacity / 100 > 1 ? 1 : opacity / 100
    })`;

  const hex = rgb2hex(newColor);

  return hexOpacity.create(hex.hex, hex.alpha);
};

export const getOpacityFromColor = (color: string) => {
  const parsed = parseColor(hexToRgba(color));

  return Math.round(parsed.rgba[parsed.rgba.length - 1] * 100);
};

export const getRealElementSize = (selector: string) => {
  const element = document.querySelector(selector);

  if (element) {
    return { realWidth: element.clientWidth, realHeight: element.clientHeight };
  }

  return { realWidth: 0, realHeight: 0 };
};

interface Stroke {
  strokeThickness: number;
  strokeColor: BorderType;
  strokeOpacity: number;
  fillBorderRadius?: number;
}

export function renderBorderStyles({
  strokeThickness,
  strokeColor,
  strokeOpacity,
  fillBorderRadius
}: Stroke): any {
  switch (strokeColor.type) {
    case 'color':
      return {
        border: `${strokeThickness}px solid ${renderColor(strokeColor.value, strokeOpacity)}`
      };
    case 'gradient':
      return {
        border: `${strokeThickness}px solid`,
        borderImageSlice: `1`,
        borderImageSource: renderGradient(strokeColor.value, strokeOpacity),
        borderRadius: `${fillBorderRadius}px`
      };
    default:
      return {
        background: 'transparent'
      };
  }
}

export function renderTextBackgroundStyles({
  color,
  opacity,
  returnType
}: {
  color: BorderType;
  opacity?: number;
  returnType?: string;
}): React.CSSProperties | string {
  switch (color.type) {
    case 'color':
      if (returnType === 'string') {
        return `color: ${color.value}`;
      }

      return {
        color: color.value
      };
    case 'gradient':
      if (returnType === 'string') {
        return `background: ${renderGradient(color.value, opacity)}`;
      }

      return {
        background: renderGradient(color.value, opacity)
      };
    default:
      if (returnType === 'string') {
        return `background: transparent`;
      }

      return {
        background: 'transparent'
      };
  }
}

export const calculateElementSize = (
  width: number,
  positionLimits: WidgetPositionLimitsType,
  elementSize: number
) =>
  positionLimits.minWidth
    ? getScalableValue(Math.round((elementSize * width) / positionLimits.minWidth))
    : getScalableValue(elementSize);

export const calculateElementSizeByHeight = (
  height: number,
  positionLimits: WidgetPositionLimitsType,
  elementSize: number
) =>
  positionLimits.minHeight
    ? getScalableValue(Math.round((elementSize * height) / positionLimits.minHeight))
    : getScalableValue(elementSize);

export const getFontLink = (fonts: GoogleFont[]) => {
  if (fonts.length) {
    const updateLink = getFontsLink(fonts);

    return `<link type="text/css" rel="stylesheet" id="googleFonts" href="${updateLink}">`;
  }

  return null;
};

export const utf2Html = (str: any) =>
  [...str].map((char) => (char.codePointAt() > 127 ? `&#${char.codePointAt()};` : char)).join('');
