import React from 'react';
import PropTypes from 'prop-types';

const IconSuccessCircleFresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M32 64C14.3253 64 0 49.6747 0 32C0 14.3253 14.3253 0 32 0C49.6747 0 64 14.3253 64 32C64 49.6747 49.6747 64 32 64Z"
      fill="#00CC66"
      fillRule="evenodd"
    />
    <path
      d="M46.2218 24.8889L28.444 42.6667L17.7773 32"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);

IconSuccessCircleFresh.propTypes = {
  className: PropTypes.string
};

IconSuccessCircleFresh.defaultProps = {
  className: ''
};

export default IconSuccessCircleFresh;
