import React from 'react';
import block from 'bem-cn';
import './SettingsAlign.scss';
import { ElementHorizontalAlign, ElementVerticalAlign } from '@features/stories/types';
import {
  IconElementAlignBottom,
  IconElementAlignCenterHorizontal,
  IconElementAlignCenterVertical,
  IconElementAlignLeft,
  IconElementAlignRight,
  IconElementAlignTop
} from '@components';

const b = block('SettingsAlign');

export type SettingAlignOnChangeType = {
  horizontal: ElementHorizontalAlign | null;
  vertical: ElementVerticalAlign | null;
};

interface SettingsAlignProps {
  onChange(params: SettingAlignOnChangeType): void;
}

export const SettingsAlign: React.FC<SettingsAlignProps> = ({ onChange }) => {
  const handleChangeHorizontal = (align: ElementHorizontalAlign) => {
    onChange({
      vertical: null,
      horizontal: align
    });
  };

  const handleChangeVertical = (align: ElementVerticalAlign) => {
    onChange({
      vertical: align,
      horizontal: null
    });
  };

  return (
    <div className={b()}>
      <button
        className={b('button')}
        onClick={() => handleChangeHorizontal(ElementHorizontalAlign.LEFT)}
      >
        <IconElementAlignLeft className={b('icon').toString()} />
      </button>
      <button
        className={b('button')}
        onClick={() => handleChangeHorizontal(ElementHorizontalAlign.CENTER)}
      >
        <IconElementAlignCenterHorizontal className={b('icon').toString()} />
      </button>
      <button
        className={b('button')}
        onClick={() => handleChangeHorizontal(ElementHorizontalAlign.RIGHT)}
      >
        <IconElementAlignRight className={b('icon').toString()} />
      </button>

      <button
        className={b('button')}
        onClick={() => handleChangeVertical(ElementVerticalAlign.TOP)}
      >
        <IconElementAlignTop className={b('icon').toString()} />
      </button>
      <button
        className={b('button')}
        onClick={() => handleChangeVertical(ElementVerticalAlign.MIDDLE)}
      >
        <IconElementAlignCenterVertical className={b('icon').toString()} />
      </button>
      <button
        className={b('button')}
        onClick={() => handleChangeVertical(ElementVerticalAlign.BOTTOM)}
      >
        <IconElementAlignBottom className={b('icon').toString()} />
      </button>
    </div>
  );
};
