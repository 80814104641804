import React, { ReactElement, useRef } from 'react';
import block from 'bem-cn';
import { useAppDispatch } from '@store';
import {
  informSlice,
  KB_IN_MB,
  MAX_FILE_SIZE_KB,
  MAX_FILE_SIZE_MB,
  MAX_VIDEO_DURATION_SEC,
  MAX_VIDEO_SIZE_KB,
  MAX_VIDEO_SIZE_MB,
  MessageTypes
} from '@features';
import cn from 'classnames';
import './ImageLoaderWrapper.scss';
import { useTranslation } from 'react-i18next';

const b = block('ImageLoaderWrapper');

interface Props {
  children: ReactElement;
  className?: string;
  onlyVideo?: boolean;
  withVideo?: boolean;
  setImage?(url: string | null): void;
  onLoad(type: 'image' | 'video', file: Blob | string, metadata?: any): void;
}

export const ImageLoaderWrapper: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { children, onLoad, withVideo, onlyVideo, className, setImage } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const inputChangeHandler = () => {
    if (fileInputRef.current) {
      const files = fileInputRef.current.files;

      if (files && files.length) {
        if (
          files[0] &&
          files[0].size / KB_IN_MB >
          (files[0].type.includes('video') ? MAX_VIDEO_SIZE_KB : MAX_FILE_SIZE_KB)
        ) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.WARN,
              text: `${t('errors.fileSize')} ${files[0].type.includes('video') ? MAX_VIDEO_SIZE_MB : MAX_FILE_SIZE_MB
                }MB.`
            })
          );
        } else if (files[0].type.includes('image')) {
          onLoad('image', files[0]);
        } else if (files[0].type.includes('video') && (withVideo || onlyVideo)) {
          if (setImage) {
            setImage(null);
          }
          const file = files[0];
          const fileURL = URL.createObjectURL(file);
          const video = document.createElement('video');
          video.src = fileURL;
          video.onloadedmetadata = (e: Event) => {
            const duration = video.duration;

            if (duration > MAX_VIDEO_DURATION_SEC) {
              dispatch(
                informSlice.actions.addMessage({
                  type: MessageTypes.WARN,
                  text: `${t('errors.videoDuration')} ${MAX_VIDEO_DURATION_SEC} sec.`
                })
              );
            } else {
              onLoad('video', file, { duration });
            }

            video.remove();
          };
        }

        fileInputRef.current.value = '';
      }
    }
  };

  const onTargetClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getAccepted = () => {
    if (onlyVideo) {
      return 'video/mp4,video/x-m4v,video/webm,video/ogg,video/ogv,video/avi';
    }

    if (withVideo) {
      return 'image/jpeg,image/png,image/gif,image/webp,video/mp4,video/x-m4v,video/webm,video/ogg,video/ogv,video/avi';
    }

    return 'image/jpeg,image/png,image/gif,image/webp';
  };

  return (
    <>
      <div className={cn(b().toString(), className)} onClick={onTargetClick}>
        {children}
      </div>

      <input
        accept={getAccepted()}
        className={b('fileInput')}
        hidden
        ref={fileInputRef}
        type="file"
        onChange={inputChangeHandler}
      />
    </>
  );
};
