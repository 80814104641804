import { TextWidgetParamsType, WidgetsTypes } from '@storysdk/react';

export const getAdaptedTextParams = (
  widgetType: WidgetsTypes,
  params: any,
  contentType: 'title' | 'answer',
  fontSize?: number,
  text?: string
): TextWidgetParamsType | undefined => {
  const adaptedParams = {
    fontSize: fontSize || 0,
    align: contentType === 'title' ? 'center' : 'left',
    widgetOpacity: 100,
    backgroundOpacity: 1,
    withFill: false
  } as TextWidgetParamsType;

  switch (widgetType) {
    case WidgetsTypes.TALK_ABOUT:
      if (contentType === 'title' && !params.isTitleHidden) {
        adaptedParams.text = text || params.text;
        adaptedParams.color = params.color;
        adaptedParams.fontFamily = params.fontFamily;
        adaptedParams.fontParams = params.fontParams;
      } else {
        return undefined;
      }
      break;
    case WidgetsTypes.SLIDER:
      if (contentType === 'title' && params.text) {
        adaptedParams.text = text || params.text;
        adaptedParams.color = params.color;
        adaptedParams.fontFamily = params.fontFamily;
        adaptedParams.fontParams = params.fontParams;
      } else {
        return undefined;
      }
      break;
    case WidgetsTypes.QUESTION:
      if (contentType === 'title' && !params.isTitleHidden) {
        adaptedParams.text = text || params.question;
        adaptedParams.color = params.fontColor;
        adaptedParams.fontFamily = params.fontFamily;
        adaptedParams.fontParams = params.fontParams;
      } else {
        return undefined;
      }
      break;
    case WidgetsTypes.QUIZ_RATE:
      if (contentType === 'title' && !params.isTitleHidden) {
        adaptedParams.text = text || params.title;
        adaptedParams.color = params.fontColor;
        adaptedParams.fontFamily = params.fontFamily;
        adaptedParams.fontParams = params.fontParams;
      } else {
        return undefined;
      }
      break;
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS:
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE:
    case WidgetsTypes.QUIZ_ONE_ANSWER:
      if (contentType === 'title' && !params.isTitleHidden) {
        adaptedParams.text = text || params.title;
        adaptedParams.color = params.titleFont.fontColor;
        adaptedParams.fontFamily = params.titleFont.fontFamily;
        adaptedParams.fontParams = params.titleFont.fontParams;
      } else if (contentType === 'answer' && text) {
        adaptedParams.text = text;
        adaptedParams.color = params.answersFont.fontColor;
        adaptedParams.fontFamily = params.answersFont.fontFamily;
        adaptedParams.fontParams = params.answersFont.fontParams;
      } else {
        return undefined;
      }
      break;
    case WidgetsTypes.QUIZ_OPEN_ANSWER:
      if (contentType === 'title' && !params.isTitleHidden) {
        adaptedParams.text = text || params.title;
        adaptedParams.color = params.fontColor;
        adaptedParams.fontFamily = params.fontFamily;
        adaptedParams.fontParams = params.fontParams;
      } else {
        return undefined;
      }
      break;
  }

  return adaptedParams;
};
