import React from 'react';
import cn from 'classnames';
import block from 'bem-cn';
import './SelectButton.scss';
import { IconSelectArrowFresh } from '@components';

type PropsType = {
  text?: string;
  placeholder?: string;
  className?: string;
  withArrow: boolean;
  icon?: any;
  opened?: boolean;
};

const b = block('SelectButton');

export const SelectButton = ({
  text = '',
  placeholder = '',
  className = '',
  withArrow,
  opened,
  icon: Icon
}: PropsType) => (
  <button className={cn(b().toString(), className)} type="button">
    {Icon && <Icon />}
    <span className={b('text')}>{text || placeholder}</span>
    {withArrow && <IconSelectArrowFresh className={b('arrow', { opened }).toString()} />}
  </button>
);
