import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import block from 'bem-cn';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { productsSlice } from '@features/products/productsSlice';
import { AppPlan, UserRole } from '@features/user/types';
import {
  Logo,
  IconSelectArrowFresh,
  IconProfile,
  IconLogout,
  TrialTimer,
  HeaderAction,
  HeaderSubscription,
  Icon
} from '@components';
import { Auth, logout } from '@utils';
import { Drop } from '@custom';
import { useGetUserSubscription } from '@hooks';
import './Header.scss';
import { FreshButton } from '@components/_fresh';

type HeaderProps = {
  leftNav?: React.ReactElement;
  centerNav?: React.ReactElement;
  className?: string;
  withLogo?: boolean;
  noTrialTimer?: boolean;
  noSubscriptionStatus?: boolean;
};

const b = block('Header');

export const Header: React.FC<HeaderProps> = (props) => {
  const {
    leftNav,
    centerNav,
    className,
    withLogo = true,
    noTrialTimer,
    noSubscriptionStatus
  } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleLogout = () => {
    logout(dispatch);
    history.push('/');
  };

  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.user.user);
  const userSubcription = useGetUserSubscription();

  const getHeaderAction = () => {
    if (userSubcription.isTrialing && !noTrialTimer) {
      return <TrialTimer trialLeftTime={userSubcription.trialLeft} userLocale={user.locale} />;
    }

    if (userSubcription.isUnpaid && !noSubscriptionStatus) {
      return (
        <HeaderAction
          actionLink={`${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${user.email}`}
          actionText={t('user.managePayments')}
          isExternal
          text={t('user.billingIssue')}
        />
      );
    }

    if (userSubcription.subscriptionStatus === 'canceled' && !noSubscriptionStatus) {
      return (
        <HeaderAction
          actionText={t('plans.upgrade')}
          btnIcon="upgrade-arrow"
          text={t('user.subscriptionIsCanceled')}
          onClick={() => {
            dispatch(productsSlice.actions.setPlansModalType('update'));
          }}
        />
      );
    }

    if (
      (userSubcription.subscriptionStatus === 'active' ||
        userSubcription.subscriptionStatus === 'trialing' ||
        userSubcription.isSubscribed) &&
      !noSubscriptionStatus
    ) {
      return (
        <div className={b('subscriptionContainer')}>
          <HeaderSubscription />

          {userSubcription.plan !== AppPlan.STARTUP && (
            <FreshButton
              leftIcon={<Icon name="upgrade-arrow" variant="icons" />}
              size="sm"
              text={t('plans.upgrade')}
              onClick={() => {
                dispatch(productsSlice.actions.setPlansModalType('update'));
              }}
            />
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <header className={b()}>
      <div className={cn(`${b('container', { noLogo: !withLogo })}`, className)}>
        <div className={b('col')}>
          {withLogo && (
            <Link className={b('brand')} to="/">
              <Logo color="black" />
            </Link>
          )}
          <div className={b('nav')}>{leftNav}</div>
        </div>
        <div className={b('col', { centered: true })}>{centerNav}</div>
        <div className={b('col', { right: true })}>
          {getHeaderAction()}
          <div className={b('separator')} />
          <Drop
            control={
              <div className={b('authControl')}>
                <IconProfile className={b('authControlIcon').toString()} />

                <div className={b('authControlContainer')}>
                  <span className={b('authControlText')}>
                    {Auth.user ? Auth.user.fullName : 'unknown'}
                  </span>

                  {user.role === UserRole.ADMIN && (
                    <span className={b('authControlText', { small: true })}>
                      {t('nav.administrator')}
                    </span>
                  )}
                </div>

                <IconSelectArrowFresh className={b('authControlArrow').toString()} />
              </div>
            }
            isRight
          >
            <div className={cn('SelectDropdown', `${b('authControlDropdown')}`)}>
              <Link className={b('authControlDropdownItem')} to="/account/settings">
                <IconProfile className={b('authControlDropdownItemIcon').toString()} />
                {t('nav.account')}
              </Link>
              <div
                className={b('authControlDropdownItem')}
                role="button"
                tabIndex={-1}
                onClick={handleLogout}
                onKeyDown={handleLogout}
              >
                <IconLogout className={b('authControlDropdownItemIcon').toString()} />
                {t('nav.logout')}
              </div>
            </div>
          </Drop>
        </div>
      </div>
    </header>
  );
};
