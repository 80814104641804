import React from 'react';
import block from 'bem-cn';
import './Typography.scss';
import classNames from 'classnames';

const b = block('Typography');

interface Props {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  styling: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'bold';
  align?: 'left' | 'center' | 'right';
  color?: 'dark' | 'light';
  className?: string;
}

export const Typography: React.FunctionComponent<Props> = (props) => {
  const { tag, styling, align, color, className, children } = props;

  return React.createElement(
    tag,
    { className: `${b({ styling, align, color })} ${className}` },
    children
  );
};
