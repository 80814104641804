import React from 'react';

type PropsType = {
  className?: string;
};

export const SwipeUpCircleIcon = ({ className = '' }: PropsType) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1901_13866)">
      <circle cx="10" cy="10" r="9.5" stroke="#A49BA4" />
      <path
        d="M13.3334 11.1112L10.0001 7.77783L6.66675 11.1112"
        stroke="#A49BA4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1901_13866">
        <rect fill="white" height="20" width="20" />
      </clipPath>
    </defs>
  </svg>
);
