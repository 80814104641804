import React, { memo, useCallback, useMemo } from 'react';
import block from 'bem-cn';
import {
  SettingsTextarea,
  SettingsInputText,
  ControlGroup,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsFontSelector,
  SettingsSelect,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features/stories/editorSettings/components';
import { FONT_STYLES } from '@features';
import { useTranslation } from 'react-i18next';
import {
  BackgroundColorType,
  QuestionWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { getChangedOpacityColor, getOpacityFromColor } from '@modules/EditorModule/utils';
import { Icon, SwitchGroup } from '@components';
import { ColorPicker, DropIcon, SettingsBox } from '../../_components';
import './QuestionWidgetSettings.scss';

const b = block('QuestionWidgetSettings');

type QuestionWidgetSettingsPropsType = {
  id: string;
  position: WidgetPositionType;
  params: QuestionWidgetParamsType;
  positionLimits: WidgetPositionLimitsType;
  onChange(params: QuestionWidgetParamsType): void;
  onChangePosition(params: WidgetPositionType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
};

export const QuestionWidgetSettings = memo(
  ({
    id,
    params,
    params: { question, confirm, decline },
    position,
    positionLimits,
    onChange,
    onChangePosition,
    onChangeAlign
  }: QuestionWidgetSettingsPropsType) => {
    const handleChange = useCallback(
      (fieldName, value) => {
        onChange({ ...params, [fieldName]: value });
      },
      [params, onChange]
    );

    const handleChangeText = (value: string) => {
      onChange({ ...params, question: value });
    };

    const handleChangePosition = (field: string) => (value: any) => {
      if (positionLimits.keepRatio && positionLimits.ratioIndex) {
        if (field === 'width') {
          onChangePosition({
            ...position,
            width: value,
            realWidth: value,
            height: Math.round(value / positionLimits.ratioIndex),
            realHeight: Math.round(value / positionLimits.ratioIndex)
          });
        } else if (field === 'height') {
          onChangePosition({
            ...position,
            width: Math.round(value * positionLimits.ratioIndex),
            height: value,
            realHeight: value,
            realWidth: Math.round(value * positionLimits.ratioIndex)
          });
        }
      } else {
        onChangePosition({
          ...position,
          [field]: value
        });
      }
    };

    const { t } = useTranslation();

    const handleChangeTitleHide = () => {
      onChange({
        ...params,
        isTitleHidden: !params.isTitleHidden
      });
    };

    const handleChangeOpacity = (fieldName: 'fontColor') => (value: number) => {
      handleChange(fieldName, {
        type: 'color',
        value: getChangedOpacityColor(params[fieldName].value as string, value)
      });
    };

    const fontOpacity = useMemo(() => {
      if (params.fontColor.type === 'color') {
        return getOpacityFromColor(params.fontColor.value);
      }

      return 100;
    }, [params.fontColor]);

    return (
      <>
        <SettingsBox.Group>
          <SettingsBox.Field>
            <SettingsAlign onChange={onChangeAlign} />
          </SettingsBox.Field>
        </SettingsBox.Group>
        <SettingsBox.Group title={t('editor.position')}>
          <SettingsBox.Field>
            <ControlGroup>
              <SettingsInput
                postfix="px"
                prefix="X"
                type="number"
                value={Math.round(position.x)}
                onChange={handleChangePosition('x')}
              />
              <SettingsInput
                postfix="px"
                prefix="Y"
                type="number"
                value={Math.round(position.y)}
                onChange={handleChangePosition('y')}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup isLocked={position.isHeightLocked}>
              <SettingsInput
                max={positionLimits.maxWidth}
                min={positionLimits.minWidth}
                postfix="px"
                prefix="W"
                type="number"
                value={position.width}
                onChange={handleChangePosition('width')}
              />
              <SettingsInput
                disabled={position.isHeightLocked}
                postfix="px"
                prefix="H"
                type="number"
                value={position.realHeight}
                onChange={handleChangePosition('height')}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field separate>
            <SettingsInput
              icon={<Icon name="angle" />}
              max={MAX_ANGLE_VALUE}
              min={MIN_ANGLE_VALUE}
              postfix="°"
              type="number"
              value={position.rotate ? Math.round(position.rotate) : 0}
              onChange={handleChangePosition('rotate')}
            />
          </SettingsBox.Field>
        </SettingsBox.Group>

        <SettingsBox.Group title={t('editor.question')}>
          <SettingsTextarea isMarginBottom value={question} onChange={handleChangeText} />

          <SwitchGroup
            isChecked={params.isTitleHidden}
            label={t('editor.hideTitle')}
            onToggle={handleChangeTitleHide}
          />
        </SettingsBox.Group>

        <SettingsBox.Group title={t('editor.answers')}>
          <SettingsInputText
            className={b('input')}
            name="confirm"
            value={confirm}
            onChange={handleChange}
          />
          <SettingsInputText
            className={b('input')}
            name="decline"
            value={decline}
            onChange={handleChange}
          />
        </SettingsBox.Group>

        <SettingsBox.Group title={t('editor.text')}>
          <SettingsBox.Field>
            <SettingsFontSelector
              value={params.fontFamily}
              onChange={(value) => handleChange('fontFamily', value)}
            />
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup sub>
              <SettingsSelect
                options={FONT_STYLES}
                value={params.fontParams}
                onChange={(value) => handleChange('fontParams', value)}
              />
            </ControlGroup>
          </SettingsBox.Field>
          <SettingsBox.Field>
            <ControlGroup sub>
              <ColorPicker
                availableTypes={[BackgroundColorType.COLOR, BackgroundColorType.GRADIENT]}
                value={params.fontColor}
                onChange={(value) => handleChange('fontColor', value)}
              />
              <SettingsInput
                icon={<DropIcon />}
                max={100}
                type="number"
                value={fontOpacity}
                onChange={handleChangeOpacity('fontColor')}
              />
            </ControlGroup>
          </SettingsBox.Field>
        </SettingsBox.Group>
      </>
    );
  }
);

QuestionWidgetSettings.displayName = 'QuestionWidgetSettings';
