import React from 'react';
import PropTypes from 'prop-types';

const IconIphoneLarge = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 13 24"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3918 0H2.35523C1.33483 0 0.5 0.834826 0.5 1.85523V22.1448C0.5 23.1652 1.33483 24 2.35523 24H10.3918C11.4204 24 12.247 23.164 12.247 22.1448V1.85523C12.247 0.835621 11.4204 0 10.3918 0Z"
      opacity="0.3"
    />
    <path d="M10.3918 0H2.35523C1.33483 0 0.5 0.834826 0.5 1.85523V22.1448C0.5 23.1652 1.33483 24 2.35523 24H10.3918C11.4204 24 12.247 23.164 12.247 22.1448V1.85523C12.247 0.835621 11.4204 0 10.3918 0V0ZM11.6958 22.1446C11.6958 22.8611 11.1173 23.449 10.3915 23.449H2.35494C1.63764 23.449 1.0506 22.8622 1.0506 22.1446L1.05116 1.85509C1.05116 1.13779 1.63794 0.550741 2.35551 0.550741H10.3921C11.1175 0.550741 11.6964 1.13865 11.6964 1.85509L11.6958 22.1446Z" />
    <path d="M7.72386 1.0498H5.02379C4.69228 1.0498 4.42383 1.2959 4.42383 1.5998C4.42383 1.90371 4.69228 2.1498 5.02379 2.1498H7.72386C8.05538 2.1498 8.32383 1.90371 8.32383 1.5998C8.32439 1.2959 8.05538 1.0498 7.72386 1.0498V1.0498Z" />
    <path d="M8.58 21.9458H4.17C3.8 21.9458 3.62305 22.0957 3.62305 22.2808C3.62305 22.4659 3.8 22.6158 4.17 22.6158H8.58C8.95 22.6158 9.12305 22.4659 9.12305 22.2808C9.12383 22.0957 8.95 21.9458 8.58 21.9458Z" />
  </svg>
);

IconIphoneLarge.propTypes = {
  className: PropTypes.string
};

IconIphoneLarge.defaultProps = {
  className: ''
};

export default IconIphoneLarge;
