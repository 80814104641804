export const FONT_STYLES = [
  {
    value: { style: 'normal', weight: 300 },
    title: 'Light Regular'
  },
  {
    value: { style: 'normal', weight: 400 },
    title: 'Normal Regular'
  },
  {
    value: { style: 'normal', weight: 500 },
    title: 'Medium Regular'
  },
  {
    value: { style: 'normal', weight: 700 },
    title: 'Bold Regular'
  },
  {
    value: { style: 'italic', weight: 300 },
    title: 'Light Italic'
  },
  {
    value: { style: 'italic', weight: 400 },
    title: 'Normal Italic'
  },
  {
    value: { style: 'italic', weight: 500 },
    title: 'Medium Italic'
  },
  {
    value: { style: 'italic', weight: 700 },
    title: 'Bold Italic'
  }
];
