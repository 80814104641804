import { nanoid } from 'nanoid';
import { getWidgetPositionLimits } from '@features/stories/consts';
import { DEFAULT_WIDGET_PARAMS, SIZE_PRESETS } from '@features/consts';
import { WIDGET_PARAMS } from '@modules/EditorModule/components/EditorLayout/components/EditorSidebar/_components/consts';
import {
  BackgroundColorType,
  ChooseAnswerWidgetParamsType,
  ClickMeWidgetParamsType,
  EllipseWidgetParamsType,
  EmojiReactionWidgetParamsType,
  GiphyWidgetParamsType,
  ImageWidgetParamsType,
  LinkWidgetParamsType,
  QuestionWidgetParamsType,
  QuizMultipleAnswerWidgetParamsType,
  QuizMultipleAnswerWithImageWidgetParamsType,
  QuizOneAnswerWidgetParamsType,
  QuizOpenAnswerWidgetParamsType,
  QuizRateWidgetParamsType,
  RectangleWidgetParamsType,
  SliderWidgetParamsType,
  SwipeUpWidgetParamsType,
  TalkAboutWidgetParamsType,
  TextWidgetParamsType,
  TimerWidgetParamsType,
  VideoWidgetParamsType,
  WidgetObjectType,
  WidgetPositionType,
  WidgetsTypes
} from '@storysdk/react';
import { getInitWidgetSizes } from '@utils';
import i18n from '../../../i18n/i18n';
import { getScalableValue } from './calculateWidgetsValues';

class StoryWidgetFactory {
  protected static [WidgetsTypes.CHOOSE_ANSWER] = (): ChooseAnswerWidgetParamsType => ({
    text: i18n.t('editor.widgets.howAreYou'),
    color: 'black',
    markCorrectAnswer: false,
    answers: [
      {
        id: 'A',
        title: i18n.t('editor.widgets.answers.answer1'),
        score: {
          letter: 'A',
          points: 0
        }
      },
      {
        id: 'B',
        title: i18n.t('editor.widgets.answers.answer2'),
        score: {
          letter: 'A',
          points: 0
        }
      }
    ],
    correct: 'A',
    isTitleHidden: false
  });

  protected static [WidgetsTypes.CLICK_ME] = (
    font?: string,
    nextStoryId?: string
  ): ClickMeWidgetParamsType => ({
    text: i18n.t('editor.widgets.clickMe'),
    fontFamily: font ?? 'Roboto',
    fontSize: 17,
    iconSize: 21,
    color: { type: BackgroundColorType.COLOR, value: '#ffffff' },
    fontParams: { style: 'normal', weight: 400 },
    opacity: 100,
    borderOpacity: 100,
    hasIcon: false,
    borderRadius: 40,
    backgroundColor: { type: BackgroundColorType.COLOR, value: '#FD19CC' },
    borderWidth: 2,
    borderColor: { type: BackgroundColorType.COLOR, value: '#ffffff' },
    hasBorder: false,
    url: '',
    storyId: nextStoryId ?? '',
    actionType: 'link',
    icon: {
      name: 'ShareLineIcon'
    }
  });

  protected static [WidgetsTypes.LINK] = (): LinkWidgetParamsType => ({
    text: i18n.t('editor.widgets.link'),
    fontFamily: 'Roboto',
    fontSize: 17,
    color: { type: BackgroundColorType.COLOR, value: '#ffffff' },
    fontParams: { style: 'normal', weight: 400 },
    opacity: 100,
    backgroundColor: { type: BackgroundColorType.GRADIENT, value: ['#AE13AB', '#890EEA'] },
    url: ''
  });

  protected static [WidgetsTypes.ELLIPSE] = (): EllipseWidgetParamsType => ({
    fillColor: { type: BackgroundColorType.COLOR, value: '#5E50B5' },
    fillOpacity: 100,
    widgetOpacity: 100,
    strokeThickness: 3,
    strokeColor: { type: BackgroundColorType.COLOR, value: '#FFFFFF' },
    strokeOpacity: 100,
    hasBorder: false
  });

  protected static [WidgetsTypes.IMAGE] = (): ImageWidgetParamsType => ({
    borderRadius: 0,
    widgetOpacity: 100,
    imageUrl: ''
  });

  protected static [WidgetsTypes.VIDEO] = (): VideoWidgetParamsType => ({
    widgetOpacity: 100,
    videoUrl: '',
    borderRadius: 0
  });

  protected static [WidgetsTypes.EMOJI_REACTION] = (): EmojiReactionWidgetParamsType => ({
    color: 'white',
    emoji: [
      { name: 'smile', unicode: '1f604' },
      { name: 'thumbsup', unicode: '1f44d' },
      { name: 'fire', unicode: '1f525' }
    ]
  });

  protected static [WidgetsTypes.QUESTION] = (): QuestionWidgetParamsType => ({
    question: i18n.t('editor.widgets.howAreYou'),
    confirm: i18n.t('form.yes'),
    decline: i18n.t('form.no'),
    color: '#000000',
    isTitleHidden: false,
    fontFamily: 'Roboto',
    fontParams: { style: 'normal', weight: 500 },
    fontColor: { type: BackgroundColorType.COLOR, value: '#FFFFFF' }
  });

  protected static [WidgetsTypes.RECTANGLE] = (): RectangleWidgetParamsType => ({
    fillColor: { type: BackgroundColorType.COLOR, value: '#5E50B5' },
    fillBorderRadius: 5,
    widgetOpacity: 100,
    fillOpacity: 100,
    strokeThickness: 3,
    strokeColor: { type: BackgroundColorType.COLOR, value: '#FFFFFF' },
    strokeOpacity: 100,
    hasBorder: false
  });

  protected static [WidgetsTypes.SLIDER] = (): SliderWidgetParamsType => ({
    value: 50,
    emoji: { name: 'smile', unicode: '1f604' },
    text: i18n.t('editor.widgets.howAreYou'),
    color: 'white',
    fontFamily: 'Roboto',
    fontParams: { style: 'normal', weight: 400 },
    fontColor: { type: BackgroundColorType.COLOR, value: '#000000' }
  });

  protected static [WidgetsTypes.SWIPE_UP] = (font?: string): SwipeUpWidgetParamsType => ({
    text: i18n.t('editor.widgets.swipeUp'),
    fontFamily: font ?? 'Roboto',
    fontSize: 21,
    iconSize: 21,
    color: { type: BackgroundColorType.COLOR, value: '#000000' },
    opacity: 100,
    fontParams: { style: 'normal', weight: 400 },
    url: '',
    icon: {
      name: 'IconChevronCircleUp'
    }
  });

  protected static [WidgetsTypes.TALK_ABOUT] = (font?: string): TalkAboutWidgetParamsType => ({
    text: i18n.t('editor.widgets.talkAbout'),
    image: null,
    color: 'white',
    isTitleHidden: false,
    fontFamily: font ?? 'Roboto',
    fontParams: { style: 'normal', weight: 500 },
    fontColor: { type: BackgroundColorType.COLOR, value: '#000000' }
  });

  protected static [WidgetsTypes.TEXT] = (font?: string): TextWidgetParamsType => ({
    text: i18n.t('editor.widgets.yourText'),
    fontFamily: font ?? 'Roboto',
    fontSize: 16,
    lineHeight: 100,
    fontParams: { style: 'normal', weight: 400 },
    align: 'left',
    color: { type: BackgroundColorType.COLOR, value: '#000000' },
    withFill: false,
    opacity: 100,
    widgetOpacity: 100,
    backgroundColor: { type: BackgroundColorType.COLOR, value: '#ffffff' },
    backgroundOpacity: 100
  });

  protected static [WidgetsTypes.TIMER] = (): TimerWidgetParamsType => ({
    time: new Date(new Date().getTime() + 1000 * 3600 * 12 * 3).getTime(),
    text: i18n.t('editor.widgets.timer'),
    color: 'white'
  });

  protected static [WidgetsTypes.GIPHY] = (): GiphyWidgetParamsType => ({
    gif: 'null',
    widgetOpacity: 100,
    borderRadius: 0
  });

  protected static [WidgetsTypes.QUIZ_ONE_ANSWER] = (): QuizOneAnswerWidgetParamsType => ({
    title: i18n.t('editor.widgets.quizOneAnswer'),
    answers: [
      {
        id: 'A',
        emoji: { name: 'smile', unicode: '1f604' },
        title: i18n.t('editor.widgets.answers.answer1'),
        score: {
          letter: 'A',
          points: 0
        }
      },
      {
        id: 'B',
        emoji: { name: 'smile', unicode: '1f604' },
        title: i18n.t('editor.widgets.answers.answer2'),
        score: {
          letter: 'A',
          points: 0
        }
      }
    ],
    isTitleHidden: false,
    titleFont: {
      fontFamily: 'Roboto',
      fontParams: { style: 'normal', weight: 500 },
      fontColor: { type: BackgroundColorType.COLOR, value: '#FFFFFF' }
    },
    answersFont: {
      fontFamily: 'Roboto',
      fontParams: { style: 'normal', weight: 500 },
      fontColor: { type: BackgroundColorType.COLOR, value: '#000000' }
    }
  });

  protected static [WidgetsTypes.QUIZ_MULTIPLE_ANSWERS] = (): QuizMultipleAnswerWidgetParamsType => ({
    title: i18n.t('editor.widgets.quizMultipleAnswer'),
    answers: [
      {
        id: nanoid(),
        emoji: { name: 'smile', unicode: '1f604' },
        title: i18n.t('editor.widgets.answers.answer1'),
        score: {
          letter: 'A',
          points: 0
        }
      },
      {
        id: nanoid(),
        emoji: { name: 'smile', unicode: '1f604' },
        title: i18n.t('editor.widgets.answers.answer2'),
        score: {
          letter: 'A',
          points: 0
        }
      }
    ],
    isTitleHidden: false,
    titleFont: {
      fontFamily: 'Roboto',
      fontParams: { style: 'normal', weight: 500 },
      fontColor: { type: BackgroundColorType.COLOR, value: '#FFFFFF' }
    },
    answersFont: {
      fontFamily: 'Roboto',
      fontParams: { style: 'normal', weight: 500 },
      fontColor: { type: BackgroundColorType.COLOR, value: '#000000' }
    }
  });

  protected static [WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE] = (): QuizMultipleAnswerWithImageWidgetParamsType => ({
    title: i18n.t('editor.widgets.quizMultipleAnswerWithImage'),
    answers: [
      {
        id: nanoid(),
        title: i18n.t('editor.widgets.answers.answer1'),
        score: {
          letter: 'A',
          points: 0
        }
      },
      {
        id: nanoid(),
        title: i18n.t('editor.widgets.answers.answer2'),
        score: {
          letter: 'A',
          points: 0
        }
      }
    ],
    isTitleHidden: false,
    titleFont: {
      fontFamily: 'Roboto',
      fontParams: { style: 'normal', weight: 500 },
      fontColor: { type: BackgroundColorType.COLOR, value: '#FFFFFF' }
    },
    answersFont: {
      fontFamily: 'Roboto',
      fontParams: { style: 'normal', weight: 500 },
      fontColor: { type: BackgroundColorType.COLOR, value: '#000000' }
    }
  });

  protected static [WidgetsTypes.QUIZ_RATE] = (): QuizRateWidgetParamsType => ({
    title: i18n.t('editor.widgets.quizRate'),
    storeLinks: {
      ios: '',
      android: '',
      web: '',
      reactNative: ''
    },
    isTitleHidden: false,
    fontFamily: 'Roboto',
    fontParams: { style: 'normal', weight: 500 },
    fontColor: { type: BackgroundColorType.COLOR, value: '#FFFFFF' }
  });

  protected static [WidgetsTypes.QUIZ_OPEN_ANSWER] = (): QuizOpenAnswerWidgetParamsType => ({
    title: i18n.t('editor.widgets.quizOpenAnswer'),
    isTitleHidden: false,
    fontFamily: 'Roboto',
    fontParams: { style: 'normal', weight: 500 },
    fontColor: { type: BackgroundColorType.COLOR, value: '#FFFFFF' }
  });

  public static getDefaultParams(
    type: WidgetsTypes,
    font?: string,
    nextStoryId?: string
  ): WidgetObjectType['content'] {
    switch (type) {
      case WidgetsTypes.TIMER:
        return { type: WidgetsTypes.TIMER, params: StoryWidgetFactory[WidgetsTypes.TIMER]() };
      case WidgetsTypes.TEXT:
        return { type: WidgetsTypes.TEXT, params: StoryWidgetFactory[WidgetsTypes.TEXT](font) };
      case WidgetsTypes.TALK_ABOUT:
        return {
          type: WidgetsTypes.TALK_ABOUT,
          params: StoryWidgetFactory[WidgetsTypes.TALK_ABOUT]()
        };
      case WidgetsTypes.SWIPE_UP:
        return {
          type: WidgetsTypes.SWIPE_UP,
          params: StoryWidgetFactory[WidgetsTypes.SWIPE_UP](font)
        };
      case WidgetsTypes.SLIDER:
        return { type: WidgetsTypes.SLIDER, params: StoryWidgetFactory[WidgetsTypes.SLIDER]() };
      case WidgetsTypes.RECTANGLE:
        return {
          type: WidgetsTypes.RECTANGLE,
          params: StoryWidgetFactory[WidgetsTypes.RECTANGLE]()
        };
      case WidgetsTypes.QUESTION:
        return { type: WidgetsTypes.QUESTION, params: StoryWidgetFactory[WidgetsTypes.QUESTION]() };
      case WidgetsTypes.GIPHY:
        return { type: WidgetsTypes.GIPHY, params: StoryWidgetFactory[WidgetsTypes.GIPHY]() };
      case WidgetsTypes.EMOJI_REACTION:
        return {
          type: WidgetsTypes.EMOJI_REACTION,
          params: StoryWidgetFactory[WidgetsTypes.EMOJI_REACTION]()
        };
      case WidgetsTypes.ELLIPSE:
        return { type: WidgetsTypes.ELLIPSE, params: StoryWidgetFactory[WidgetsTypes.ELLIPSE]() };
      case WidgetsTypes.CLICK_ME:
        return {
          type: WidgetsTypes.CLICK_ME,
          params: StoryWidgetFactory[WidgetsTypes.CLICK_ME](font, nextStoryId)
        };
      case WidgetsTypes.LINK:
        return {
          type: WidgetsTypes.LINK,
          params: StoryWidgetFactory[WidgetsTypes.LINK]()
        };
      case WidgetsTypes.CHOOSE_ANSWER:
        return {
          type: WidgetsTypes.CHOOSE_ANSWER,
          params: StoryWidgetFactory[WidgetsTypes.CHOOSE_ANSWER]()
        };
      case WidgetsTypes.QUIZ_ONE_ANSWER:
        return {
          type: WidgetsTypes.QUIZ_ONE_ANSWER,
          params: StoryWidgetFactory[WidgetsTypes.QUIZ_ONE_ANSWER]()
        };
      case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS:
        return {
          type: WidgetsTypes.QUIZ_MULTIPLE_ANSWERS,
          params: StoryWidgetFactory[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS]()
        };
      case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE:
        return {
          type: WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE,
          params: StoryWidgetFactory[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE]()
        };
      case WidgetsTypes.QUIZ_OPEN_ANSWER:
        return {
          type: WidgetsTypes.QUIZ_OPEN_ANSWER,
          params: StoryWidgetFactory[WidgetsTypes.QUIZ_OPEN_ANSWER]()
        };
      case WidgetsTypes.QUIZ_RATE:
        return {
          type: WidgetsTypes.QUIZ_RATE,
          params: StoryWidgetFactory[WidgetsTypes.QUIZ_RATE]()
        };
      case WidgetsTypes.IMAGE:
        return {
          type: WidgetsTypes.IMAGE,
          params: StoryWidgetFactory[WidgetsTypes.IMAGE]()
        };
      case WidgetsTypes.VIDEO:
        return {
          type: WidgetsTypes.VIDEO,
          params: StoryWidgetFactory[WidgetsTypes.VIDEO]()
        };
      default:
        throw new Error('Unknown widget');
    }
  }

  static getDefaultSize(type: WidgetsTypes): { width: number; height: number } {
    switch (type) {
      case WidgetsTypes.TIMER:
        return {
          width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
          height: getScalableValue(DEFAULT_WIDGET_PARAMS.height)
        };
      case WidgetsTypes.TEXT:
        return {
          width: WIDGET_PARAMS[WidgetsTypes.TEXT].width,
          height: getScalableValue(WIDGET_PARAMS[WidgetsTypes.TEXT].height)
        };
      case WidgetsTypes.TALK_ABOUT:
        return {
          width: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].width,
          height: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].height
        };
      case WidgetsTypes.SWIPE_UP:
        return {
          width: getScalableValue(WIDGET_PARAMS[WidgetsTypes.SWIPE_UP].width),
          height: getScalableValue(WIDGET_PARAMS[WidgetsTypes.SWIPE_UP].height)
        };
      case WidgetsTypes.SLIDER:
        return {
          width: WIDGET_PARAMS[WidgetsTypes.SLIDER].width,
          height: WIDGET_PARAMS[WidgetsTypes.SLIDER].height
        };
      case WidgetsTypes.IMAGE:
        return {
          width: WIDGET_PARAMS[WidgetsTypes.IMAGE].width,
          height: WIDGET_PARAMS[WidgetsTypes.IMAGE].height
        };
      case WidgetsTypes.VIDEO:
        return {
          width: WIDGET_PARAMS[WidgetsTypes.VIDEO].width,
          height: WIDGET_PARAMS[WidgetsTypes.VIDEO].height
        };
      case WidgetsTypes.RECTANGLE:
        return {
          width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
          height: getScalableValue(DEFAULT_WIDGET_PARAMS.height)
        };
      case WidgetsTypes.QUESTION:
        return {
          width: WIDGET_PARAMS[WidgetsTypes.QUESTION].width,
          height: WIDGET_PARAMS[WidgetsTypes.QUESTION].height
        };
      case WidgetsTypes.GIPHY:
        return {
          width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
          height: getScalableValue(DEFAULT_WIDGET_PARAMS.height)
        };
      case WidgetsTypes.EMOJI_REACTION:
        return {
          width: getScalableValue(WIDGET_PARAMS[WidgetsTypes.EMOJI_REACTION].width),
          height: getScalableValue(WIDGET_PARAMS[WidgetsTypes.EMOJI_REACTION].height)
        };
      case WidgetsTypes.ELLIPSE:
        return {
          width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
          height: getScalableValue(DEFAULT_WIDGET_PARAMS.height)
        };
      case WidgetsTypes.CLICK_ME:
        return {
          width: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].width,
          height: WIDGET_PARAMS[WidgetsTypes.CLICK_ME].height
        };
      case WidgetsTypes.CHOOSE_ANSWER:
        return {
          width: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].width,
          height: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].height
        };
      default:
        return {
          width: getScalableValue(DEFAULT_WIDGET_PARAMS.width),
          height: getScalableValue(DEFAULT_WIDGET_PARAMS.height)
        };
    }
  }

  public static make(type: WidgetsTypes, content?: any): WidgetObjectType {
    const widgetSize = StoryWidgetFactory.getDefaultSize(type);
    const initElementsSize = getInitWidgetSizes(type);

    return StoryWidgetFactory.makeWithPosition({
      type,
      position: {
        x: getScalableValue(DEFAULT_WIDGET_PARAMS.x),
        y: getScalableValue(DEFAULT_WIDGET_PARAMS.y),
        width: widgetSize.width,
        height: widgetSize.height,
        realWidth: widgetSize.width,
        realHeight: widgetSize.height,
        origin: {
          width: widgetSize.width,
          height: widgetSize.height,
          x: getScalableValue(DEFAULT_WIDGET_PARAMS.x),
          y: getScalableValue(DEFAULT_WIDGET_PARAMS.y)
        },
        rotate: 0,
        elementsSize: initElementsSize
      },
      content
    });
  }

  public static makeWithPosition({
    type,
    position,
    font,
    content,
    nextStoryId
  }: {
    type: WidgetsTypes;
    font?: string;
    position: WidgetPositionType;
    content?: any;
    nextStoryId?: string;
  }): WidgetObjectType {
    const positionByResolutions: WidgetObjectType['positionByResolutions'] = {};

    for (const key in SIZE_PRESETS) {
      if (Object.prototype.hasOwnProperty.call(SIZE_PRESETS, key)) {
        const { width, height } = SIZE_PRESETS[key];
        positionByResolutions[`${width}x${height}`] = position;
      }
    }

    return {
      id: `W${nanoid()}`,
      positionByResolutions,
      positionLimits: getWidgetPositionLimits(type),
      content: content ?? StoryWidgetFactory.getDefaultParams(type, font, nextStoryId)
    };
  }
}

export default StoryWidgetFactory;
