import React from 'react';
import PropTypes from 'prop-types';

const IconRectangle = ({ className }) => (
  <svg
    className={className}
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.473 3.164A.527.527 0 0018 2.637V.527A.527.527 0 0017.473 0h-2.11a.527.527 0 00-.527.527v.528H3.164V.527A.522.522 0 002.637 0H.527A.522.522 0 000 .527v2.11c0 .295.232.527.527.527h.528v11.672H.527a.527.527 0 00-.527.527v2.11c0 .291.236.527.527.527h2.11a.527.527 0 00.527-.527v-.528h11.672v.528c0 .295.232.527.527.527h2.11a.522.522 0 00.527-.527v-2.11a.522.522 0 00-.527-.527h-.528V3.164h.528zM15.89 14.836h-.528a.522.522 0 00-.527.527v.528H3.164v-.528a.527.527 0 00-.527-.527h-.528V3.164h.528a.522.522 0 00.527-.527v-.528h11.672v.528c0 .291.236.527.527.527h.528v11.672z" />
  </svg>
);

IconRectangle.propTypes = {
  className: PropTypes.string
};

IconRectangle.defaultProps = {
  className: ''
};

export default IconRectangle;
