import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import block from 'bem-cn';
import { RootState, useAppDispatch } from '@store';
import { LocaleSelector, RangeType, storiesAnalyticsGroupSlice } from '@features';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FreshDatepicker } from '@components/_fresh';
import { HeaderBackButton } from '@components';
import { useIsMobile } from '@hooks';
import './AnalyticsHeader.scss';

const b = block('AnalyticsHeader');

interface AnalyticsHeaderProps {
  appId?: string;
  groupId?: string;
  isLoading?: boolean;
  title?: string;
  withBack?: boolean;
  activeTab?: 'stories' | 'logics';
}

export const AnalyticsHeader: React.FC<AnalyticsHeaderProps> = ({
  appId,
  groupId,
  title,
  withBack,
  isLoading,
  activeTab
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const [isFromChoosen, setIsFromChoosen] = useState<boolean>(false);
  const currentRange = useSelector((store: RootState) => store.storiesAnalyticsGroup.range);

  const [range, setRange] = useState<RangeType>({
    from: currentRange.from ?? DateTime.now().startOf('month').toSeconds(),
    to: currentRange.to ?? DateTime.now().endOf('month').toSeconds()
  });

  useEffect(() => {
    dispatch(storiesAnalyticsGroupSlice.actions.setRange(range));
  }, [dispatch, range]);

  const handleChangeDate = (date: any) => {
    const newDate = date.value;

    const newDateDateTime = DateTime.fromSeconds(newDate);

    if (isFromChoosen) {
      if (range.from && newDate >= range.from) {
        setRange((prevState) => ({
          ...prevState,
          to: newDateDateTime.endOf('day').toSeconds()
        }));
        setIsFromChoosen(false);
      } else {
        setRange((prevState) => ({ ...prevState, from: newDate }));
      }
    } else {
      setRange({ from: newDate, to: newDateDateTime.endOf('day').toSeconds() });
      setIsFromChoosen(true);
    }
  };

  return (
    <div className={b('header')}>
      {isLoading ? (
        <Skeleton
          height={40}
          style={{
            borderRadius: 12
          }}
          width={138}
        />
      ) : (
        <div className={b('headerContainer')}>
          {withBack && appId && <HeaderBackButton link={`/dashboard/${appId}/analytics`} />}
          <h1 className={b('headerTitle')}>{title || t('dashboard.analytics.title')}</h1>
          <div className={b('headerRightContainer')}>
            <LocaleSelector isRight={!isMobile} theme="light" />
            <FreshDatepicker
              dropdownPosition="left"
              id="TimerWidgetSettings"
              label=""
              theme="light"
              type="range"
              value={range}
              onChange={handleChangeDate}
            />
          </div>

          {!!activeTab && appId && groupId && (
            <div className={b('nav')}>
              <Link
                className={b('navItem', { active: activeTab === 'stories' })}
                to={`/dashboard/${appId}/analytics/${groupId}/stories`}
              >
                {t('dashboard.analytics.storiesTitle')}
              </Link>
              <Link
                className={b('navItem', { active: activeTab === 'logics' })}
                to={`/dashboard/${appId}/analytics/${groupId}/logics`}
              >
                {t('dashboard.analytics.logics')}
                <span className={b('shield')}>{t('dashboard.analytics.quiz')}</span>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
