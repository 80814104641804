import React from 'react';
import PropTypes from 'prop-types';

const IconCaret = ({ className }) => (
  <svg
    className={className}
    height={4}
    viewBox="0 0 8 4"
    width={8}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 1L7 0 4 1 1 0 0 1l4 3 4-3z" />
  </svg>
);

IconCaret.propTypes = {
  className: PropTypes.string
};

IconCaret.defaultProps = {
  className: ''
};

export default IconCaret;
