import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { ResultLayerAnalytics, Story } from '@features';
import './GroupResultsAnalytics.scss';
import { getPercent } from '@utils';

const b = block('GroupResultsAnalytics');

interface GroupResultsAnalyticsProps {
  layers: Story[];
  isLoading?: boolean;
}

export const GroupResultsAnalytics: React.FC<GroupResultsAnalyticsProps> = ({
  layers,
  isLoading
}) => {
  const { t } = useTranslation();

  const getProportion = (views: number) => {
    const allViews = layers.reduce((acc, layer) => acc + (layer.statistic?.views || 0), 0);

    return getPercent(views, allViews) || 0;
  };

  return (
    <div className={b()}>
      <p className={b('title')}>{t('dashboard.analytics.resultLayers')}</p>
      <div className={b('container')}>
        {isLoading ? (
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <ResultLayerAnalytics className={b('layer').toString()} isLoading proportion={0} />
            ))}
          </>
        ) : (
          <>
            {layers.map((layer) => (
              <ResultLayerAnalytics
                className={b('layer').toString()}
                proportion={getProportion(layer.statistic?.views || 0)}
                story={layer}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
