import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '@store';
import { MessageTypes, informSlice } from '@features/inform';
import { useTranslation } from 'react-i18next';
import { App } from '@features/appsManagerSlice';
import { useGetUserSubscription } from '@hooks';
import { AccesibleFeaturesByPlan, AppFeatures } from '../consts';
import { useGetUserPlanByApp } from './useGetUserPlanByApp';

export const useRedirectByAccess = ({
  feature,
  redirectLink,
  app
}: {
  feature: AppFeatures;
  redirectLink?: string;
  app?: App;
}) => {
  const userSubcription = useGetUserSubscription();
  const plan = useGetUserPlanByApp(app);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const isAppOwner = app && app.ownerId === userSubcription.userId;

  if (!AccesibleFeaturesByPlan[plan][feature]) {
    if (redirectLink) {
      history.push(redirectLink);
    } else {
      history.goBack();
    }

    if (userSubcription.isTrialing && userSubcription.trialLeft <= 0) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.NEED_ACTION,
          text: t('notification.subscription.needAction'),
          action:
            !app || isAppOwner
              ? {
                text: t('plans.upgrade'),
                link: '/account/plans',
                icon: 'upgrade-arrow'
              }
              : undefined
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.DONT_HAVE_ACCESS,
          text:
            !app || isAppOwner
              ? t('notification.subscription.dontHaveAccess')
              : t('notification.subscription.dontHaveAccessCollaborator'),
          action:
            !app || isAppOwner
              ? {
                text: t('plans.upgrade'),
                link: '/account/plans',
                icon: 'upgrade-arrow'
              }
              : undefined
        })
      );
    }
  }
};
