import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshModal, FreshModalTitle, FreshButton } from '@components/_fresh';

import './DeleteGroupModal.scss';

interface DeleteGroupModalProps {
  isOpen: boolean;
  onClose(): void;
  image: string;
  title: string;
  isOnboarding?: boolean;
  handleDeleteGroup(): void;
}

export const DeleteGroupModal: React.FC<DeleteGroupModalProps> = (props) => {
  const { isOpen, onClose, handleDeleteGroup, image, title, isOnboarding } = props;

  const b = block('DeleteGroupModal');
  const { t } = useTranslation();

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle centered>
          {t('dashboard.modals.deleteGroup.title')} {title}?
        </FreshModalTitle>

        <section className={b('content')}>
          <img className={b('img', { squared: isOnboarding })} src={image} />
        </section>

        <section className={b('btnContaier')}>
          <FreshButton
            color="grey"
            text={t('dashboard.modals.deleteGroup.cancel')}
            onClick={onClose}
          />
          <FreshButton
            color="black"
            text={
              isOnboarding
                ? t('dashboard.modals.deleteOnboarding.delete')
                : t('dashboard.modals.deleteGroup.delete')
            }
            onClick={handleDeleteGroup}
          />
        </section>
      </div>
    </FreshModal>
  );
};
