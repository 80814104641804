import { useEffect, useState } from 'react';
import { WidgetObjectType, WidgetPositionType } from '@storysdk/react';
import { calulateWidgetSizeValues, getInitWidgetSizes } from '@utils';

export type WidgetSizeType = {
  width: number;
  height: number;
};

export const useWidgetElementsSize = (
  widget: WidgetObjectType,
  widgetSize: WidgetSizeType,
  currentElementSize?: WidgetPositionType['elementsSize']
) => {
  const [sizes, setSizes] = useState<any>(currentElementSize);
  const [initSizes, setInitSizes] = useState<any>();

  useEffect(() => {
    if (initSizes) return;

    setInitSizes(getInitWidgetSizes(widget.content.type));
  }, []);

  useEffect(() => {
    if (initSizes) {
      const currentSizes = JSON.parse(JSON.stringify(initSizes));
      calulateWidgetSizeValues(currentSizes, widget, widgetSize);

      setSizes(currentSizes);
    }
  }, [initSizes, widgetSize, widget.positionLimits]);

  return sizes;
};
