import { useEffect } from 'react';
import { appsManagerSlice, fetchGetApp, fetchGetApps, fetchGetLocales } from '@features';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { useHistory } from 'react-router-dom';

export const useFetchApps = (redirectToDashboard?: boolean) => {
  const appsState = useSelector((store: RootState) => store.appManager);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (appsState.status === 'idle') {
      dispatch(fetchGetLocales()).then(() => {
        dispatch(fetchGetApps()).then((app) => {
          if (app.payload && redirectToDashboard) {
            let appId = app.payload;

            if (localStorage.getItem('joinAppId')) {
              appId = localStorage.getItem('joinAppId') as string;
              localStorage.removeItem('joinAppId');
            }

            history.push(`/dashboard/${appId}/stories`);
          }
        });
      });
    } else if (appsState.status === 'loaded' && appsState.current?.id && redirectToDashboard) {
      history.push(`/dashboard/${appsState.current.id}/stories`);
    } else if (appsState.status === 'loaded' && !appsState.current && !appsState.appsList.length) {
      dispatch(appsManagerSlice.actions.setIsFirstUsing(true));
    }
  }, [appsState.status, history, redirectToDashboard]);
};
