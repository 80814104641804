import React from 'react';
import block from 'bem-cn';
import './SettingsButton.scss';

const b = block('SettingsButton');

interface Props {
  icon: React.ReactNode;
  onClick(): void;
}

export const SettingsButton: React.FC<Props> = ({ children, onClick, icon }) => (
  <button className={b()} onClick={onClick}>
    <span className={b('icon')}>{icon}</span>
    <span className={b('title')}>{children}</span>
  </button>
);
