import React from 'react';
import PropTypes from 'prop-types';

const IconSend = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height={18}
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 1.5L8.25 9.75M16.5 1.5l-5.25 15-3-6.75-6.75-3 15-5.25z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

IconSend.propTypes = {
  className: PropTypes.string
};

IconSend.defaultProps = {
  className: ''
};

export default IconSend;
