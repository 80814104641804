import React from 'react';
import PropTypes from 'prop-types';

const IconLink = ({ className }) => (
  <svg
    className={className}
    fill="white"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.80007 4.60007C3.00179 4.60007 1.59993 6.00193 1.59993 7.80007C1.59993 9.59821 3.00179 11.0001 4.80007 11.0001H6.4C6.84185 11.0001 7.2001 11.3583 7.2001 11.8002C7.2001 12.242 6.84185 12.6001 6.4 12.6001H4.80007C2.1181 12.6001 0 10.4819 0 7.80007C0 5.11824 2.1181 3 4.80007 3H6.4C6.84185 3 7.2001 3.35812 7.2001 3.79997C7.2001 4.24181 6.84185 4.60007 6.4 4.60007H4.80007ZM8.7999 3.79997C8.7999 3.35812 9.15815 3 9.6 3H11.1999C13.8819 3 16 5.11824 16 7.80007C16 10.4819 13.8819 12.6001 11.1999 12.6001H9.6C9.15815 12.6001 8.7999 12.242 8.7999 11.8002C8.7999 11.3583 9.15815 11.0001 9.6 11.0001H11.1999C12.9982 11.0001 14.4001 9.59821 14.4001 7.80007C14.4001 6.00193 12.9982 4.60007 11.1999 4.60007H9.6C9.15815 4.60007 8.7999 4.24181 8.7999 3.79997ZM3.99983 7.80014C3.99983 7.35829 4.35808 7.00017 4.79993 7.00017H11.1998C11.6416 7.00017 11.9999 7.35829 11.9999 7.80014C11.9999 8.24198 11.6416 8.6001 11.1998 8.6001H4.79993C4.35808 8.6001 3.99983 8.24198 3.99983 7.80014Z" />
  </svg>
);

IconLink.propTypes = {
  className: PropTypes.string
};

IconLink.defaultProps = {
  className: ''
};

export default IconLink;
