import React from 'react';
import PropTypes from 'prop-types';

const IconLogoApple = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3924 9.4563C13.3855 8.14217 13.9619 7.15031 15.1285 6.41984C14.4757 5.45662 13.4896 4.92668 12.1876 4.82284C10.9549 4.72257 9.60769 5.56405 9.11464 5.56405C8.5938 5.56405 7.39936 4.85864 6.46186 4.85864C4.52436 4.89087 2.46533 6.45207 2.46533 9.62817C2.46533 10.5663 2.632 11.5355 2.96533 12.5357C3.40978 13.8499 5.01394 17.0725 6.68755 17.0188C7.56255 16.9973 8.18061 16.3778 9.3195 16.3778C10.4237 16.3778 10.9966 17.0188 11.9723 17.0188C13.6598 16.9937 15.1112 14.0647 15.5348 12.747C13.2709 11.6477 13.3924 9.52433 13.3924 9.4563ZM11.4271 3.57674C12.3751 2.41659 12.2882 1.36027 12.2605 0.980713C11.4237 1.03084 10.4549 1.56795 9.90283 2.23039C9.29519 2.93937 8.93755 3.81665 9.01394 4.80493C9.92019 4.87655 10.7466 4.39673 11.4271 3.57674Z"
      fill="#F8F7F8"
    />
  </svg>
);

IconLogoApple.propTypes = {
  className: PropTypes.string
};

IconLogoApple.defaultProps = {
  className: ''
};

export default IconLogoApple;
