import { GroupsType } from '@features';
import { useGroup } from '@features/groups/hooks';
import { useGetStory } from '@features/stories/hooks';
import { useMemo } from 'react';

export const useShareLink = ({
  groupId,
  storyId,
  layerId,
  type
}: {
  groupId: string;
  storyId?: string;
  layerId?: string;
  type: GroupsType;
}) => {
  const storiesGroup = useGroup(groupId, type);
  const story = useGetStory(layerId, storyId);

  const link = useMemo(() => {
    if (story?.layerData.shortDataId) {
      return `${process.env.REACT_APP_SITE_URL}/share/${story?.layerData.shortDataId}`;
    }

    if (storiesGroup?.settings?.shortDataId) {
      return `${process.env.REACT_APP_SITE_URL}/share/${storiesGroup?.settings?.shortDataId}`;
    }

    return `${process.env.REACT_APP_SITE_URL}`;
  }, [story?.layerData.shortDataId, storiesGroup?.settings?.shortDataId]);

  return link;
};
