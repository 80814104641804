import axios from 'axios';
import { DateTime } from 'luxon';
import { parseJwt } from './parseJWT';

const AUTH_STORAGE_KEY = 'auth';

interface AuthData {
  user: {
    id: string;
    fullName: string;
    email: string;
  };
  accessToken: string;
  refreshToken: string;
  expirationTime: string;
}

export class Auth {
  static get isLoggedIn(): boolean {
    return localStorage.getItem(AUTH_STORAGE_KEY) !== null;
  }

  static get storage(): AuthData | null {
    if (localStorage.getItem(AUTH_STORAGE_KEY)) {
      const data = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) as string);

      return {
        user: {
          id: data.user.id,
          fullName: data.user.full_name,
          email: data.user.email
        },
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expirationTime: data.expiration_time
      };
    }

    return null;
  }

  static updateStorage(data: object) {
    const existData = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) as string);

    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify({ ...existData, ...data }) as string);
  }

  static updateUser(data: object) {
    const existData = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) as string);

    localStorage.setItem(
      AUTH_STORAGE_KEY,
      JSON.stringify({ ...existData, user: { ...existData.user, ...data } }) as string
    );
  }

  static setStorage(data: object) {
    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(data) as string);
  }

  static clear() {
    localStorage.removeItem('auth');
  }

  static get user() {
    const storage = Auth.storage;
    if (storage) {
      return storage.user;
    }

    return null;
  }

  static get accessToken() {
    const storage = Auth.storage;

    if (storage) {
      return storage.accessToken;
    }

    return null;
  }

  static refreshAccessToken() {
    const jwt = parseJwt(Auth.storage?.accessToken);

    if (Auth.storage && DateTime.now().toSeconds() - 1000 > jwt.exp) {
      axios({
        method: 'post',
        url: '/auth/refresh',
        data: {
          refresh_token: Auth.storage.refreshToken
        }
      }).then((response: any) => {
        if (!response.data.error) {
          Auth.updateStorage(response.data.data);

          axios.defaults.headers.common = {
            Authorization: `Bearer ${response.data.data.access_token}`
          };
        }
      });
    }
  }
}
