import React from 'react';
import PropTypes from 'prop-types';

const IconUpgradeArrow = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 18C5.63973 18 2 14.3602 2 10C2 5.63973 5.63976 2 10 2C14.3603 2 18 5.63976 18 10C18 14.3603 14.3602 18 10 18ZM10 3.09402C6.19203 3.09402 3.09402 6.19203 3.09402 10C3.09402 13.808 6.19203 16.906 10 16.906C13.808 16.906 16.906 13.808 16.906 10C16.906 6.19203 13.808 3.09402 10 3.09402Z"
      fill="white"
    />
    <path
      d="M11.2164 12.2636H8.77868C8.55881 12.2636 8.23167 12.1077 8.23167 11.6783L8.23163 8.85023H7.28743C6.84613 8.85023 6.58203 8.31693 6.85669 7.96603L9.61905 4.43765C9.8411 4.15406 10.2862 4.0914 10.5213 4.41023L13.1531 7.97854C13.4139 8.33215 13.1423 8.85023 12.7129 8.85023H11.7637L11.7635 11.6784C11.7633 12.1078 11.4362 12.2636 11.2164 12.2636ZM9.32565 11.1696H10.6695C10.6695 10.2123 10.6696 8.32846 10.6696 8.30323C10.6696 8.00114 10.9145 7.75622 11.2166 7.75622H11.6297L10.068 5.63878C9.62237 6.20794 8.92869 7.09406 8.41033 7.75622H8.77861C9.08069 7.75622 9.32562 8.00114 9.32562 8.30323C9.32562 8.30323 9.32565 10.2054 9.32565 11.1696Z"
      fill="white"
    />
    <path d="M11.65 12.9438H8.35034V14.0379H11.65V12.9438Z" fill="white" />
    <path d="M11.219 14.7178H8.78101V15.8118H11.219V14.7178Z" fill="white" />
  </svg>
);

IconUpgradeArrow.propTypes = {
  className: PropTypes.string
};

IconUpgradeArrow.defaultProps = {
  className: ''
};

export default IconUpgradeArrow;
