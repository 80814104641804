import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AccountSettingsRoute } from './pages/AccountSettingsRoute/AccountSettingsRoute';
import { AccountModuleLayout } from './_components/AccountModuleLayout/AccountModuleLayout';

export * from './pages/AccountSettingsRoute/components';

export const AccountModule: React.FC = () => (
  <Switch>
    <Route exact path="/account/:tab">
      <AccountModuleLayout>
        <AccountSettingsRoute />
      </AccountModuleLayout>
    </Route>
  </Switch>
);
