import React, { useCallback } from 'react';
import block from 'bem-cn';
import {
  SettingsTextarea,
  ControlGroup,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features/stories/editorSettings/components';
import { SettingsBox, WidgetThemes, WidgetThemesColor } from '@modules';
import { GroupsType } from '@features';
import './TimerWidgetSettings.scss';
import { useAppDispatch } from '@store';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  TimerWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { useParams } from 'react-router-dom';
import { Drop } from '@custom';
import { Icon, Datepicker } from '@components';

const b = block('TimerWidgetSettings');

interface TimerWidgetSettingsProps {
  params: TimerWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangePosition(params: WidgetPositionType): void;
  onChange(params: TimerWidgetParamsType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
}

export const TimerWidgetSettings: React.FC<TimerWidgetSettingsProps> = (props) => {
  const { params, position, positionLimits, onChange, onChangeAlign, onChangePosition } = props;

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const handleChangeColor = (color: string) => {
    onChange({ ...params, color });
  };

  const handleChangeText = (value: string) => {
    onChange({ ...params, text: value });
  };

  const handleChangeDate = ({ value }: any) => {
    onChange({ ...params, time: DateTime.fromJSDate(value).toUTC().toMillis() });
  };

  const handleChangePosition = (field: string) => (value: any) => {
    if (positionLimits.keepRatio && positionLimits.ratioIndex) {
      if (field === 'width') {
        onChangePosition({
          ...position,
          width: value,
          realWidth: value,
          height: Math.round(value / positionLimits.ratioIndex),
          realHeight: Math.round(value / positionLimits.ratioIndex)
        });
      } else if (field === 'height') {
        onChangePosition({
          ...position,
          width: Math.round(value * positionLimits.ratioIndex),
          realWidth: Math.round(value * positionLimits.ratioIndex),
          height: value,
          realHeight: value
        });
      }
    } else {
      onChangePosition({
        ...position,
        [field]: value
      });
    }
  };

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleDropOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(currentStoriesSlice.actions.setIsPickerOpen(isOpen));
    },
    [dispatch]
  );

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup isLocked>
            <SettingsInput
              max={positionLimits.maxWidth}
              min={positionLimits.minWidth}
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled
              postfix="px"
              prefix="H"
              type="number"
              value={position.realHeight}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.title')}>
        <SettingsTextarea value={params.text} onChange={handleChangeText} />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.finishDate')}>
        <Datepicker
          className={b('timer')}
          dropdownPosition="left"
          handleOpen={handleDropOpen}
          id="TimerWidgetSettings"
          label=""
          theme="dark"
          value={new Date(params.time)}
          withTime
          onChange={handleChangeDate}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.themes')}>
        <Drop
          control={<WidgetThemesColor color={params.color} hasDarkBackground />}
          handleOpen={handleDropOpen}
        >
          <WidgetThemes selectedColor={params.color} onChange={handleChangeColor} />
        </Drop>
      </SettingsBox.Group>
    </>
  );
};
