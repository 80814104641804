import React, { useEffect } from 'react';
import block from 'bem-cn';
import {
  AnalyticsHeader,
  AnalyticsInteractionsTab,
  AnalyticsLogicsTab
} from '@modules/DashboardModule/components';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { useHistory, useParams } from 'react-router-dom';
import { fetchAnalyticsStoriesGroup, useRedirectByAccess } from '@features';
import './AppAnalyticsStoriesRoute.scss';
import { AppFeatures } from '@features/user/consts';
import { useGetCurrentApp } from '@features/stories/hooks';
import { usePrevious } from '@hooks';

const b = block('AppAnalyticsStoriesRoute');

export const AppAnalyticsStoriesRoute: React.FC = () => {
  const { appId, groupId, tab } = useParams<{
    appId: string;
    groupId?: string;
    tab: 'stories' | 'logics';
  }>();

  const history = useHistory();

  const dispatch = useAppDispatch();
  const groupData = useSelector((store: RootState) => store.storiesAnalyticsGroup.data);
  const status = useSelector((store: RootState) => store.storiesAnalyticsGroup.status);
  const appStatus = useSelector((store: RootState) => store.appManager.status);
  const range = useSelector((store: RootState) => store.storiesAnalyticsGroup.range);
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);

  const currentApp = useGetCurrentApp();

  useRedirectByAccess({
    feature: AppFeatures.EXTENDED_ANALITICS,
    redirectLink: `/dashboard/${appId}/analytics`,
    app: currentApp ?? undefined
  });

  useEffect(() => {
    if (
      appId &&
      groupId &&
      groupId !== 'all' &&
      range.from &&
      range.to &&
      appStatus === 'loaded' &&
      status !== 'pending'
    ) {
      dispatch(fetchAnalyticsStoriesGroup({ appId, groupId, range }));
    }
  }, [appId, groupId, range, currentLocale]);

  const prevAppId = usePrevious(appId);

  useEffect(() => {
    if (appId && prevAppId && appId !== prevAppId) {
      history.push(`/dashboard/${appId}/analytics`);
    }
  }, [appId]);

  return (
    <div className={b()}>
      <AnalyticsHeader
        activeTab={groupData?.settings?.scoreResultLayersGroupId ? tab : undefined}
        appId={appId}
        groupId={groupId}
        isLoading={appStatus === 'pending'}
        title={currentLocale ? groupData?.title[currentLocale] : ''}
        withBack
      />
      {tab === 'stories' && <AnalyticsInteractionsTab />}
      {tab === 'logics' && <AnalyticsLogicsTab />}
    </div>
  );
};
