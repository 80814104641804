import React, { useCallback } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import SimpleBar from 'simplebar-react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useFetchApps,
  useSelectApp,
  SettingsFactory,
  GroupsType,
  Templates,
  useFetchTemplates
} from '@features';
import { useFetchGroups } from '@features/groups/hooks';
import { useCurrentStoriesType } from '@features/stories/hooks';
import { PlansModal } from '@modules';
import { productsSlice } from '@features/products/productsSlice';
import { useTranslation } from 'react-i18next';
import { useAuth, useIsAdmin, useIsMobile } from '@hooks';
import { FreshButton } from '@components/_fresh';
import { StoryEditorProvider } from './StoryEditorContext';
import { EditorHeader, EditorSidebar } from './components';
import 'simplebar-react/dist/simplebar.min.css';
import './EditorLayout.scss';

const b = block('EditorLayout');

export const EditorLayout: React.FC = ({ children }) => {
  const { appId, type, category } = useParams<{
    appId?: string;
    type: GroupsType;
    category?: Templates.Category;
  }>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isAdmin = useIsAdmin();
  const isMobile = useIsMobile();
  const history = useHistory();

  const currentStoriesType = useCurrentStoriesType(type ?? GroupsType.GROUP);
  const plansModalType = useSelector((store: RootState) => store.products.plansModalType);

  const isPickerOpen = useSelector(
    (store: RootState) => store[currentStoriesType].editor.isPickerOpen
  );

  const storyId = useSelector(
    (store: RootState) => store[currentStoriesType].editor.selectedLayersGroupId
  );

  const getTemplatesCategory = useCallback(() => {
    if (!type) {
      return undefined;
    }

    if (type === GroupsType.TEMPLATE && category) {
      return category;
    }

    if (type === GroupsType.ONBOARDING) {
      return Templates.Category.ONBOARDING;
    }

    return Templates.Category.STORIES;
  }, [category, type]);

  useFetchTemplates({
    category: getTemplatesCategory(),
    isWithStories: true,
    isOnlyActive: !isAdmin
  });

  useAuth();
  useFetchApps();
  useSelectApp(appId);
  useFetchGroups({
    appId,
    type,
    templateCategory: category
  });

  if (isMobile) {
    return (
      <div className={b('mobile')}>
        <p className={b('mobileText')}>{t('editor.mobileText')}</p>
        <FreshButton
          className={b('mobileButton').toString()}
          text={t('editor.goBackToDashboard')}
          onClick={() => history.push(`/dashboard`)}
        />
      </div>
    );
  }

  return (
    <StoryEditorProvider>
      <div className={b()}>
        <div className={b('header')}>
          <EditorHeader />
        </div>

        <main className={b('main', { noStories: !storyId })}>
          <div className={b('sidebar', { noStories: !storyId })}>
            <EditorSidebar />
          </div>

          <div className={b('content')}>{children}</div>

          {storyId && (
            <SimpleBar
              className={b('settingsContainer', {
                pickerOpen: isPickerOpen,
                noStories: !storyId
              })}
              scrollableNodeProps={{ className: b('settingsContentWrapper') }}
            >
              <div className={b('settings')}>
                <SettingsFactory />
              </div>
            </SimpleBar>
          )}
        </main>
        <PlansModal
          isOpen={!!plansModalType}
          onClose={() => {
            dispatch(productsSlice.actions.setPlansModalType(undefined));
          }}
        />
      </div>
    </StoryEditorProvider>
  );
};
