import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import {
  CreateTemplateModal,
  DeleteTemplateModal,
  StoriesTemplateBase,
  StoriesTemplateCard,
  StoryLayers,
  Templates,
  fetchChangeTemplatePublishStatus,
  fetchDeleteTemplate,
  AddTemplatesModal,
  fetchGroups,
  fetchOnboardings,
  templateStoriesSlice,
  CreateGroupModal,
  CreateOnboardingModal,
  CropProps
} from '@features';
import { Link } from 'react-router-dom';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { FreshButton } from '@components/_fresh';
import { IconCart, IconDashboardOnboarding, IconDashboardStories } from '@components';
import { useIsAdmin } from '@hooks';
import './StoriesTemplatesCatalogContainer.scss';

const b = block('StoriesTemplatesCatalogContainer');
interface StoriesTemplatesCatalogContainerProps {
  category: Templates.Category;
  choosenTemplateId?: string;
  onSelectTemplate?: (templateId: string) => void;
  onChooseTemplate?: (templateId: string, stories: StoryLayers) => void;
}

export const StoriesTemplatesCatalogContainer: React.FC<StoriesTemplatesCatalogContainerProps> = ({
  choosenTemplateId,
  category,
  onSelectTemplate,
  onChooseTemplate
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isAdmin = useIsAdmin();

  const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isAddTemplateOpen, setIsAddTemplateOpen] = useState<boolean>(false);
  const [createGroupModal, setCreateGroupModal] = useState<Templates.Category | null>(null);
  const [template, setTemplate] = useState<StoriesTemplateBase | null>(null);

  const templates = useSelector((state: RootState) => state.templates);
  const templatesStatus = useSelector((state: RootState) => state.templates.status);
  const selectedStoryLayers = useSelector(
    (store: RootState) => store.templateStories.selectedStoryLayers
  );
  const selectedCategory = useSelector(
    (store: RootState) => store.templateStories.selectedStoriesCategory
  );
  const currentApp = useSelector((state: RootState) => state.appManager.current);
  const groups = useSelector((state: RootState) => state.groups.groups);
  const onboardings = useSelector((state: RootState) => state.onboardings.onboardings);
  const currentAppId = currentApp?.id;

  useEffect(() => {
    if (
      (category === Templates.Category.ONBOARDING &&
        selectedStoryLayers.length &&
        selectedCategory === Templates.Category.STORIES) ||
      (category === Templates.Category.STORIES &&
        selectedStoryLayers.length &&
        selectedCategory === Templates.Category.ONBOARDING)
    ) {
      dispatch(templateStoriesSlice.actions.setSelectedStories({ layers: [], category: null }));
    }
  }, [category, dispatch, selectedCategory, selectedStoryLayers]);

  useEffect(() => {
    if (currentApp) {
      dispatch(fetchGroups({ appId: currentApp.id }));
      dispatch(fetchOnboardings({ appId: currentApp.id }));
    }
  }, [currentApp, dispatch]);

  const handlePublishTemplate = (id: string) => {
    dispatch(fetchChangeTemplatePublishStatus({ templateId: id, active: true }));
  };

  const handleUnpublishTemplate = (id: string) => {
    dispatch(fetchChangeTemplatePublishStatus({ templateId: id, active: false }));
  };

  const handleRemoveTemplate = (id: string) => {
    dispatch(fetchDeleteTemplate({ templateId: id }));
    setIsDeleteModalOpen(false);
  };

  const openRemoveModal = (templateId: string) => {
    const currentTemplate = templates.templates.find(
      (itemTemplate) => itemTemplate.id === templateId
    );

    if (currentTemplate) {
      setTemplate(currentTemplate);
      setIsDeleteModalOpen(true);
    }
  };

  const openEditModal = (templateId: string) => {
    const currentTemplate = templates.templates.find(
      (itemTemplate) => itemTemplate.id === templateId
    );

    if (currentTemplate) {
      setTemplate(currentTemplate);
      setIsCreateTemplateModalOpen(true);
    }
  };

  const initialCroppedState = {
    dataUrl: null,
    blob: null
  };

  const [croppedImage, setCroppedImage] = useState<CropProps>(initialCroppedState);

  return (
    <>
      <div className={b()}>
        <div className={b('header')}>
          <h1 className={b('title')}>{t('dashboard.templates.title')}</h1>

          <div className={b('nav')}>
            <Link
              className={b('navItem', {
                active: category === Templates.Category.STORIES,
                disabled: templatesStatus === 'pending'
              })}
              to={`/dashboard/${currentAppId}/templates/stories`}
            >
              <IconDashboardStories className={b('navItemIcon').toString()} />
              {t('dashboard.sideMenu.stories')}
            </Link>
            <Link
              className={b('navItem', {
                active: category === Templates.Category.ONBOARDING,
                disabled: templatesStatus === 'pending'
              })}
              to={`/dashboard/${currentAppId}/templates/onboarding`}
            >
              <IconDashboardOnboarding
                className={b('navItemIcon', {
                  filled: true
                }).toString()}
              />
              {t('dashboard.sideMenu.onboarding')}
            </Link>
          </div>

          <div className={b('btnsContainer')}>
            {isAdmin && (
              <FreshButton
                className={b('btn').toString()}
                color="transparent-grey"
                size="md"
                text={t('dashboard.templates.createTemplate')}
                onClick={() => setIsCreateTemplateModalOpen(true)}
              />
            )}

            <FreshButton
              className={b('btn').toString()}
              disabled={!selectedStoryLayers.length}
              leftIcon={<IconCart className={b('btnIcon').toString()} />}
              size="md"
              text={`${t('dashboard.templates.continue')} (${selectedStoryLayers.length})`}
              onClick={() => setIsAddTemplateOpen(true)}
            />
          </div>
        </div>

        <div className={b('container')}>
          {templates.status === 'pending' || templates.status === 'idle' ? (
            <>
              {Array.from({ length: 6 }).map((_, index) => (
                <StoriesTemplateCard isLoading />
              ))}
            </>
          ) : (
            <>
              {templates.templates.length > 0 ? (
                templates.templates
                  .filter((item: any) => item.category === category)
                  .map((item) => (
                    <StoriesTemplateCard
                      isAdmin={isAdmin}
                      isChoosen={choosenTemplateId === item.id}
                      key={`story-template-card-${item.id}`}
                      template={item}
                      onChooseTemplate={onChooseTemplate}
                      onEditClick={openEditModal}
                      onPublish={handlePublishTemplate}
                      onRemove={openRemoveModal}
                      onSelectTemplate={onSelectTemplate}
                      onUnpublish={handleUnpublishTemplate}
                    />
                  ))
              ) : (
                <div className={b('empty')}>{t('dashboard.stories.emptyText')}</div>
              )}
            </>
          )}
        </div>
      </div>

      <DeleteTemplateModal
        handleDeleteTemplate={() => (template ? handleRemoveTemplate(template.id) : undefined)}
        isOpen={isDeleteModalOpen}
        title={template?.title ?? ''}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setTemplate(null);
        }}
      />

      <CreateTemplateModal
        isOpen={isCreateTemplateModalOpen}
        template={template ?? undefined}
        onClose={() => {
          setIsCreateTemplateModalOpen(false);
          setTemplate(null);
        }}
      />

      <AddTemplatesModal
        appId={currentAppId ?? ''}
        category={category}
        groups={category === Templates.Category.STORIES ? groups : onboardings}
        isOpen={isAddTemplateOpen}
        isProhibitedOutsideClick={createGroupModal !== null}
        stories={selectedStoryLayers}
        onClose={() => setIsAddTemplateOpen(false)}
        onOpenAddModal={(addCategory: Templates.Category) => setCreateGroupModal(addCategory)}
      />

      {category === Templates.Category.STORIES && (
        <CreateGroupModal
          clearCurrentImage={() => setCroppedImage(initialCroppedState)}
          currentAppId={currentAppId ?? undefined}
          currentImage={croppedImage}
          isOpen={createGroupModal === Templates.Category.STORIES}
          onClose={() => setCreateGroupModal(null)}
          onImageLoaded={setCroppedImage}
        />
      )}
      {category === Templates.Category.ONBOARDING && (
        <CreateOnboardingModal
          clearCurrentImage={() => setCroppedImage(initialCroppedState)}
          currentAppId={currentAppId ?? undefined}
          currentImage={croppedImage}
          isOpen={createGroupModal === Templates.Category.ONBOARDING}
          onClose={() => setCreateGroupModal(null)}
        />
      )}
    </>
  );
};
