import React, { useMemo } from 'react';
import block from 'bem-cn';
import { STORY_WIDTH, StoriesTemplateBase, StoryPreview, useGetTemplateStories } from '@features';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getActiveStoriesFlattenedArr, getInitStoryParams } from '@utils';
import './EditorTemplateCard.scss';

const b = block('EditorTemplateCard');

interface EditorTemplateCardProps {
  template?: StoriesTemplateBase;
  isLoading?: boolean;
  onClick?: (templateId: string) => void;
}

const CARD_WIDTH = 58;
const PREVIEW_STORIES_COUNT = 3;

export const EditorTemplateCard: React.FC<EditorTemplateCardProps> = ({
  template,
  isLoading,
  onClick
}) => {
  const scaleIndex = CARD_WIDTH / STORY_WIDTH;

  const templateStories = useGetTemplateStories(template?.id);

  const activeStories = useMemo(() => getActiveStoriesFlattenedArr(templateStories ?? {}), [
    templateStories
  ]);

  const storiesFromTemplate = useMemo(
    () =>
      activeStories
        .slice(0, PREVIEW_STORIES_COUNT)
        .sort((storyA, storyB) => storyA.position - storyB.position),
    [activeStories]
  );

  const storiesToRender = useMemo(
    () =>
      storiesFromTemplate?.concat(
        Array.from({ length: PREVIEW_STORIES_COUNT - storiesFromTemplate.length }).map(() =>
          getInitStoryParams({
            isGreyBackground: true
          })
        )
      ),
    [storiesFromTemplate]
  );

  if (isLoading || !template) {
    return (
      <div className={b()}>
        <SkeletonTheme baseColor="#18182E" highlightColor="#0e0e2a">
          <div className={b('preview')}>
            {Array.from({ length: 3 }).map((_, index) => (
              <div className={b('previewItem')} key={`story-load-card-${index}`}>
                <Skeleton height="100%" />
              </div>
            ))}
          </div>

          <Skeleton className={b('title').toString()} height={20} />
          <Skeleton className={b('desc').toString()} height={20} />
        </SkeletonTheme>
      </div>
    );
  }

  return (
    <div className={b()} onClick={() => onClick && onClick(template.id)}>
      <div className={b('preview')}>
        {storiesToRender?.map((story) => (
          <div className={b('previewItem')} key={`story-preveiw-card-${story.id}`}>
            <StoryPreview scaleIndex={scaleIndex} story={story} />
          </div>
        ))}
      </div>

      <p className={b('title')}>{template.title}</p>
      <p className={b('desc')}>{template.description}</p>
    </div>
  );
};
