import React from 'react';
import PropTypes from 'prop-types';

const IconDashboardAnalytics = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    stroke="#A49BA4"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 12V20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <path
      d="M14.7 14.6699V19.9999"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path d="M4 17V20" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <path
      d="M9.29999 13.5999V19.9999"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M15.9394 8.50841C16.6228 9.19183 16.6228 10.2999 15.9394 10.9833C15.256 11.6667 14.1479 11.6667 13.4645 10.9833C12.7811 10.2999 12.7811 9.19182 13.4645 8.50841C14.1479 7.825 15.256 7.825 15.9394 8.50841"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M21.3424 4.00548C22.0258 4.6889 22.0258 5.79694 21.3424 6.48035C20.659 7.16377 19.551 7.16377 18.8675 6.48035C18.1841 5.79694 18.1841 4.68889 18.8675 4.00548C19.551 3.32207 20.659 3.32207 21.3424 4.00548"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M5.13245 9.40246C5.81587 10.0859 5.81587 11.1939 5.13245 11.8773C4.44903 12.5607 3.34099 12.5607 2.65758 11.8773C1.97417 11.1939 1.97417 10.0859 2.65758 9.40246C3.341 8.71904 4.44904 8.71904 5.13245 9.40246"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M10.5364 4.9005C11.2198 5.58392 11.2198 6.69196 10.5364 7.37537C9.85297 8.05879 8.74492 8.05879 8.06151 7.37537C7.3781 6.69195 7.3781 5.58391 8.06151 4.9005C8.74493 4.21709 9.85297 4.21709 10.5364 4.9005"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M18.77 6.35986L16.04 8.62986"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.24 8.77986L10.75 7.10986"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M5.23999 9.53001L7.95999 7.26001"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconDashboardAnalytics.propTypes = {
  className: PropTypes.string
};

IconDashboardAnalytics.defaultProps = {
  className: ''
};

export default IconDashboardAnalytics;
