import React from 'react';
import { Subscription } from '@features';
import block from 'bem-cn';
import './AppSubscriptionCard.scss';
import { DateTime } from 'luxon';

interface AppSubscriptionCardProps {
  subscription: Subscription;
}

const b = block('AppSubscriptionCard');

export const AppSubscriptionCard: React.FC<AppSubscriptionCardProps> = ({ subscription }) => (
  <div className={b()}>
    <div className={b('col')}>
      <p className={b('title')}>{subscription.appTitle}</p>
      <p className={b('price')}>
        {DateTime.fromSeconds(subscription.currentPeriodStart).toLocaleString({
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })}{' '}
        -{' '}
        {DateTime.fromSeconds(subscription.currentPeriodEnd).toLocaleString({
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })}
      </p>
    </div>
  </div>
);
