import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { GroupsType } from '@features/groups';

export const useGetCurrentGroup = (type: GroupsType, id?: string, isTemplate?: boolean) => {
  const groups = useSelector((store: RootState) => store.groups.groups);
  const onboardings = useSelector((store: RootState) => store.onboardings.onboardings);
  const templates = useSelector((store: RootState) => store.templates.templates);

  const groupList = type === GroupsType.GROUP ? groups : onboardings;
  const list: any = isTemplate ? templates : groupList;

  const currentGroup = list.find((group: any) => group.id === id);

  return currentGroup;
};
