import { WidgetPositionType } from '@storysdk/react';
import { ElementHorizontalAlign, GroupPositionType } from '../types';

export const getAlignHorizontalPosition = (
  align: ElementHorizontalAlign,
  position: WidgetPositionType,
  container: GroupPositionType
): WidgetPositionType => {
  let newPosition = position;

  switch (align) {
    case ElementHorizontalAlign.LEFT:
      newPosition = {
        ...newPosition,
        x: container.x
      };
      break;
    case ElementHorizontalAlign.CENTER:
      newPosition = {
        ...newPosition,
        x: container.x + Math.ceil(container.width / 2) - Math.ceil(newPosition.realWidth / 2)
      };
      break;
    case ElementHorizontalAlign.RIGHT:
      newPosition = {
        ...newPosition,
        x: container.x + container.width - Math.ceil(newPosition.realWidth)
      };
      break;
  }

  return newPosition;
};
