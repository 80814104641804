import React from 'react';
import block from 'bem-cn';
import cn from 'classnames';
import { Label } from 'reactstrap';
import { Icon } from '@components';
import './FormLabel.scss';

const b = block('FormLabel');

export const colors = {
  BLACK: 'black',
  WHITE: 'white'
};

type PropsType = {
  active?: boolean;
  children?: Array<React.ReactNode> | React.ReactNode;
  className?: string;
  color?: string;
  helpText?: string;
  iconClassName?: string;
  iconName?: string;
  invalid?: boolean;
  required?: boolean;
  [key: string]: any;
};

export const FormLabel = ({
  active = false,
  className = '',
  children,
  color = colors.WHITE,
  helpText = '',
  iconClassName = '',
  iconName = '',
  invalid = false,
  required = false,
  isBlack = false,
  ...props
}: PropsType) => {
  const hasIcon = !!iconName;
  const hasIconClassName = !!iconClassName;

  const labelClasses = cn(
    b().toString(),
    // 'form-label mb-0_5 d-flex align-items-center flex-nowrap',
    // {
    //   'text-white': valid && color === colors.WHITE,
    //   'text-black': valid && color === colors.BLACK,
    // { 'text-opacity-50': !active && !isBlack },
    //   'text-pink': invalid
    // },
    className
  );

  const iconClasses = cn(
    'form-label-icon w-1_125 h-1_125 mr-0_5',
    {
      'text-white text-opacity-50': !hasIconClassName && [colors.WHITE].includes(color),
      'text-black text-opacity-50': !hasIconClassName && [colors.BLACK].includes(color)
    },
    iconClassName
  );

  const iconQuestionClasses = cn('w-0_75 h-0_75 ml-0_375', {
    'text-white text-opacity-50': [colors.WHITE].includes(color),
    'text-black text-opacity-50': [colors.BLACK].includes(color)
  });

  return (
    <Label className={labelClasses} {...props}>
      {hasIcon && <Icon className={iconClasses} name={iconName} />}
      {children}
      {required && <sup>*</sup>}
      {helpText && (
        <span title={helpText}>
          <Icon className={iconQuestionClasses} name="question" />
        </span>
      )}
    </Label>
  );
};
