import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { EditorStories } from '@features';
import { EditorLayout } from './components';

export const EditorModule: React.FC = () => (
  <Switch>
    <Route exact path="/templateEditor/:type/:category/:templateId">
      <EditorLayout>
        <EditorStories />
      </EditorLayout>
    </Route>
    <Route exact path="/editor/:appId/:type/:groupId">
      <EditorLayout>
        <EditorStories />
      </EditorLayout>
    </Route>
  </Switch>
);
