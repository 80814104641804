import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { AppBase, Subscription } from '@features';
import { DateTime } from 'luxon';
import { AppSubscriptionCard } from './components';
import './OrderSummaryCard.scss';

const b = block('OrderSummaryCard');

interface OrderSummaryCardProps {
  currentAppId?: string;
  nextPayment?: number;
  apps: AppBase[];
  subscriptions: Subscription[];
}

export const OrderSummaryCard: React.FC<OrderSummaryCardProps> = ({
  currentAppId,
  subscriptions,
  nextPayment,
  apps
}) => {
  const { t } = useTranslation();

  const totalCost = useMemo(
    () =>
      subscriptions.reduce((acc, subscription) => {
        const amount = subscription.plan?.amount ? +subscription.plan.amount : 0;
        const totalAmount = amount / 100;

        return acc + totalAmount;
      }, 0),
    [subscriptions]
  );

  const notCurrentSubscriptions = useMemo(
    () =>
      subscriptions.filter(
        (subscription) =>
          subscription.appId !== currentAppId && apps.find((app) => app.id === subscription.appId)
      ),
    [subscriptions, currentAppId]
  );

  return (
    <div className={b()}>
      <div className={b('header', { borderBottom: notCurrentSubscriptions.length > 0 })}>
        <div className={b('headerLeft')}>
          <p className={b('title')}>{t('dashboard.billing.total')}</p>
          {nextPayment && (
            <p className={b('nextPayment')}>
              {t('dashboard.billing.nextPaymentIs')}{' '}
              {DateTime.fromSeconds(nextPayment).toLocaleString({
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              })}
            </p>
          )}
        </div>
        <div className={b('headerRight')}>
          <p className={b('cost')}>{totalCost}$</p>
        </div>
      </div>
      {notCurrentSubscriptions.length > 0 && (
        <div className={b('pricing')}>
          <p className={b('title')}>{t('dashboard.billing.pricing')}</p>
          <div className={b('pricingApps')}>
            {notCurrentSubscriptions.map((subscription) => (
              <AppSubscriptionCard key={subscription.id} subscription={subscription} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
