import React from 'react';
import PropTypes from 'prop-types';

const IconSwitch = ({ className }) => (
  <svg
    className={className}
    fill="white"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.3896 6.61989C17.3639 5.57843 15.9616 4.99434 14.4997 5.00004C14.1425 5.00004 13.8124 5.19055 13.6337 5.49992C13.4551 5.80929 13.4551 6.19064 13.6337 6.50002C13.8124 6.8094 14.1425 6.99989 14.4997 6.99989C15.4272 7.00257 16.3158 7.37221 16.9717 8.02794C17.6274 8.68385 17.9971 9.57244 17.9997 10.4999C17.9997 11.4282 17.6309 12.3183 16.9745 12.9747C16.3181 13.6311 15.428 13.9999 14.4997 13.9999H12.4098L13.6999 12.7098H13.6997C13.9202 12.4524 13.9953 12.1008 13.8991 11.7759C13.803 11.4508 13.5488 11.1966 13.2237 11.1005C12.8988 11.0043 12.5472 11.0794 12.2898 11.2999L9.28981 14.2999C9.10349 14.4872 8.99902 14.7406 8.99902 15.0048C8.99902 15.269 9.10349 15.5224 9.28981 15.7098L12.2898 18.7097C12.5472 18.9302 12.8988 19.0052 13.2237 18.9091C13.5488 18.813 13.803 18.5589 13.8991 18.2338C13.9953 17.9088 13.9202 17.5571 13.6997 17.2998L12.4098 15.9997H14.4997C15.9575 15.9972 17.355 15.4168 18.3858 14.3859C19.4167 13.3551 19.9971 11.9576 19.9996 10.4998C20.0064 9.04272 19.426 7.64418 18.3896 6.61983L18.3896 6.61989Z" />
    <path d="M10.9197 6.38056C10.995 6.19742 11.0144 5.99619 10.9753 5.80217C10.9361 5.60815 10.8402 5.43002 10.6997 5.29055L7.69976 2.2906C7.44613 2.03865 7.07735 1.94139 6.73249 2.03497C6.38746 2.12872 6.11878 2.39942 6.02754 2.7451C5.93614 3.09063 6.03625 3.45875 6.28987 3.71053L7.58979 5.00059H5.49985C4.04205 5.0031 2.64454 5.58353 1.6138 6.61439C0.582891 7.64513 0.00248567 9.04264 0 10.5004C0.00251109 11.9583 0.582934 13.3558 1.6138 14.3865C2.64454 15.4174 4.04205 15.9978 5.49985 16.0003C5.8571 16.0003 6.18723 15.8098 6.36585 15.5004C6.54448 15.1911 6.54448 14.8097 6.36585 14.5003C6.18723 14.1909 5.85711 14.0004 5.49985 14.0004C4.5724 13.9978 3.68381 13.6281 3.02789 12.9724C2.37215 12.3165 2.00251 11.4279 1.99985 10.5004C1.99985 9.57217 2.36865 8.68205 3.02507 8.02566C3.68148 7.36927 4.57158 7.00044 5.49985 7.00044H7.58979L6.28987 8.29051C6.07827 8.47165 5.95187 8.73296 5.94099 9.01135C5.93028 9.28975 6.03625 9.56013 6.23329 9.75714C6.43016 9.95418 6.70051 10.06 6.9789 10.0493C7.2573 10.0386 7.51864 9.912 7.69975 9.70039L10.6997 6.70044C10.7933 6.6092 10.8681 6.50055 10.9197 6.38053L10.9197 6.38056Z" />
  </svg>
);

IconSwitch.propTypes = {
  className: PropTypes.string
};

IconSwitch.defaultProps = {
  className: ''
};

export default IconSwitch;
