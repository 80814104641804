import React, { useState } from 'react';
import block from 'bem-cn';
import { CopyBlock } from 'react-code-blocks';
import { SupportedLanguages, Theme } from 'react-code-blocks/dist/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components/Icon';
import './CodeSample.scss';

interface CodeSampleProps {
  code: string;
  language: SupportedLanguages;
}

export const CodeSample: React.FC<CodeSampleProps> = ({ code, language }) => {
  const b = block('CodeSample');

  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  const theme: Theme = {
    backgroundColor: '#F8F7F8',
    lineNumberColor: '#05051D'
  };

  const copyValue = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className={b()}>
      <div className={b('container')}>
        <CopyBlock
          customStyle={{
            fontSize: '85%'
          }}
          language={language}
          lineNumberContainerStyle={{
            color: '#05051D'
          }}
          showLineNumbers
          text={code}
          theme={theme}
          wrapLongLines
        />
      </div>

      <CopyToClipboard text={code} onCopy={copyValue}>
        <div className={b('copyButtonContainer')}>
          {isCopied && <span className={b('copyMessage')}>{t('dashboard.groups.copied')}</span>}

          <button className={b('copyButton')}>
            <Icon name="copy" />
          </button>
        </div>
      </CopyToClipboard>
    </div>
  );
};
