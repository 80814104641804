import { useEffect, useState } from 'react';
import { useElementSize } from './useElementSize';

export const useCardSize = ({
  storyWidth,
  storyHeight,
  containerRef,
  gap,
  containerPaddingTop
}: {
  storyWidth?: number;
  storyHeight?: number;
  containerRef: React.MutableRefObject<HTMLElement | null>;
  gap?: number;
  containerPaddingTop?: number;
}) => {
  const containerSize = useElementSize(containerRef);

  const [cardSize, setCardSize] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    if (!containerSize?.width || !containerSize?.height || !storyWidth || !storyHeight) return;

    const currentGap = gap ?? 0;
    const currentContainerGap = containerPaddingTop ?? 0;

    setCardSize({
      width: Math.ceil(
        (storyWidth * (containerSize.height - currentContainerGap - currentGap)) / storyHeight
      ),
      height: Math.ceil(containerSize.height - currentContainerGap - currentGap)
    });
  }, [
    storyWidth,
    storyHeight,
    containerSize?.width,
    containerSize?.height,
    gap,
    containerPaddingTop
  ]);

  return cardSize;
};
