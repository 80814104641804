import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getLastGroupsPosition } from '@utils';

export const useGetGroupLastPosition = (): number => {
  const groups = useSelector((state: RootState) => state.groups.groups);
  const [lastPosition, setLastPosition] = useState<number>(0);

  useEffect(() => {
    const currentLastPosition = getLastGroupsPosition(groups);

    setLastPosition(currentLastPosition);
  }, [groups]);

  return lastPosition;
};
