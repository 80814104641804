import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import block from 'bem-cn';
import { FreshButton, FreshInput, FreshModal, FreshModalTitle } from '@components/_fresh';
import './AppIntegrationModal.scss';

interface AppIntegrationModalProps {
  title: string;
  isOpen: boolean;
  trackId?: string;
  onSubmit?(trackId: string): void;
  onClose(): void;
}

const b = block('AppIntegrationModal');

export const AppIntegrationModal: React.FC<AppIntegrationModalProps> = ({
  title,
  isOpen,
  trackId,
  onSubmit,
  onClose
}) => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState<string>(trackId ?? '');

  useEffect(() => {
    setValue(trackId ?? '');
  }, [trackId]);

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle>{title}</FreshModalTitle>
        <FreshInput
          className={b('input')}
          fieldName="trackId"
          placeholder={t('dashboard.integrations.trackIdPlaceholder')}
          type="text"
          value={value}
          onChange={({ value }) => setValue(value)}
        />
        <div className={b('description')}>
          ☀ {t('dashboard.integrations.GADescription')}{' '}
          <a
            href="https://docs.storysdk.com/tutorial/integrations/google-analytics"
            rel="noreferrer"
            target="_blank"
          >
            {t('dashboard.integrations.seeDocs')}
          </a>
        </div>
        <FreshButton
          disabled={!trackId && !value}
          text={t('dashboard.integrations.save')}
          onClick={() => {
            onSubmit?.(value);
            onClose();
          }}
        />
      </div>
    </FreshModal>
  );
};
