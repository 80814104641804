import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormGroup } from 'reactstrap';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import block from 'bem-cn';
import classNames from 'classnames';
import { RangeType } from '@features';
import { IconCalendarFresh } from '@components';
import { FreshInput, FreshCalendar } from '@components/_fresh';
import { useVisible } from '@hooks';
import './FreshDatepicker.scss';

const b = block('FreshDatepicker');
const cn = classNames;

type PropsType = {
  id: string;
  label: string;
  lang?: string;
  value: number | RangeType | null;
  withTime?: boolean;
  onChange: (params: { value: number | null }) => void;
  theme: 'dark' | 'light';
  className?: string;
  dropdownPosition?: 'left' | 'right';
  type?: 'single' | 'range';
  handleOpen?: (isOpen: boolean) => void;
  canBeEmpty?: boolean;
};

export const FreshDatepicker = ({
  id,
  label,
  value,
  onChange,
  lang = 'en',
  withTime,
  theme = 'light',
  className = '',
  dropdownPosition = 'right',
  type = 'single',
  canBeEmpty,
  handleOpen
}: PropsType) => {
  const datepickerRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { ref, isVisible, setIsVisible } = useVisible(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (handleOpen) {
      handleOpen(isVisible);
    }
  }, [isVisible, handleOpen]);

  const inputValue = useMemo(() => {
    let formatedValue;

    if (value) {
      if (type === 'single' && value) {
        formatedValue = DateTime.fromSeconds(value as number).toLocaleString({
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });
      } else if (type === 'range') {
        const rangeVal = value as RangeType;

        if (!rangeVal.from || !rangeVal.to) {
          formatedValue = t('form.withoutDate');
        } else {
          formatedValue = `${DateTime.fromSeconds(rangeVal.from).toFormat(
            'dd MMM'
          )} - ${DateTime.fromSeconds(rangeVal.to).toFormat('dd MMM y')}`;
        }
      }
    } else {
      formatedValue = t('form.withoutDate');
    }

    return formatedValue;
  }, [value, type, t]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  const handleOutsideClick = useCallback(
    ({ target }: any) => {
      if (datepickerRef.current && !datepickerRef.current.contains(target)) {
        setIsVisible(false);
      }
    },
    [setIsVisible]
  );

  const handleOpenClick = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible, setIsVisible]);

  return (
    <div className={cn(b().toString(), className)} ref={datepickerRef}>
      <FormGroup style={{ marginBottom: '0' }}>
        {label && (
          <p
            className={b('label', {
              theme
            })}
          >
            {label}
          </p>
        )}

        <div className={b('input', { type })} ref={inputRef}>
          <FreshInput
            iconClassName={b('icon', {
              icon: {
                pink: inputValue !== t('form.withoutDate')
              }
            })}
            rightIcon={IconCalendarFresh}
            theme={theme}
            type="text"
            value={inputValue}
            onClick={handleOpenClick}
          />
        </div>
      </FormGroup>
      {isVisible && (
        <div
          className={b('dropdown', {
            left: dropdownPosition === 'left'
          })}
          ref={ref}
        >
          <FreshCalendar
            canBeEmpty={canBeEmpty}
            id={id}
            theme={theme}
            type={type}
            value={value}
            withTime={withTime}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};
