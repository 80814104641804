import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshModal, FreshModalTitle, FreshButton } from '@components/_fresh';

import './DeleteTemplateModal.scss';

interface DeleteTemplateModalProps {
  isOpen: boolean;
  onClose(): void;
  title: string;
  handleDeleteTemplate(): void;
}

export const DeleteTemplateModal: React.FC<DeleteTemplateModalProps> = (props) => {
  const { isOpen, onClose, handleDeleteTemplate, title } = props;

  const b = block('DeleteTemplateModal');
  const { t } = useTranslation();

  return (
    <FreshModal isOpen={isOpen} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle centered>
          {t('dashboard.modals.deleteTemplate.title')} {title}?
        </FreshModalTitle>

        <section className={b('btnContaier')}>
          <FreshButton
            color="grey"
            text={t('dashboard.modals.deleteTemplate.cancel')}
            onClick={onClose}
          />
          <FreshButton
            color="black"
            text={t('dashboard.modals.deleteTemplate.delete')}
            onClick={handleDeleteTemplate}
          />
        </section>
      </div>
    </FreshModal>
  );
};
