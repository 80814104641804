import React from 'react';
import PropTypes from 'prop-types';

const IconCart = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.2569 1.09917C10.6106 0.895023 11.0627 1.01616 11.2669 1.36982L13.6328 5.46778C13.6546 5.50557 13.6728 5.54452 13.6871 5.58413H14.7814C16.152 5.58413 17.1946 6.81479 16.9693 8.16675L15.8848 14.673C15.7066 15.7424 14.7813 16.5264 13.697 16.5264H4.303C3.21873 16.5264 2.2934 15.7424 2.11515 14.673L1.03071 8.16675C0.805444 6.81479 1.84797 5.58413 3.21856 5.58413H4.31272C4.32724 5.54452 4.3454 5.50557 4.36718 5.46778L6.73311 1.36982C6.93725 1.01615 7.38944 0.895031 7.74311 1.09917C8.09677 1.30331 8.2179 1.7555 8.01376 2.10917L6.00745 5.58412H11.9924L9.9861 2.10917C9.78195 1.7555 9.90309 1.30331 10.2568 1.09917H10.2569ZM13.697 15.048C14.0585 15.048 14.3668 14.7867 14.4263 14.4303L15.5108 7.92398C15.5859 7.47344 15.2383 7.06316 14.7815 7.06316H3.21843C2.76164 7.06316 2.41406 7.47344 2.48913 7.92398L3.57357 14.4303C3.63298 14.7868 3.94143 15.048 4.30287 15.048H13.697Z"
      fillRule="evenodd"
    />
  </svg>
);

IconCart.propTypes = {
  className: PropTypes.string
};

IconCart.defaultProps = {
  className: ''
};

export default IconCart;
