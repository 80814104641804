import React, { useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshButton, FreshModal } from '@components/_fresh';
import { IconSwitch } from '@components';
import { PlansCards } from '../PlansCards';
import './PlansModal.scss';

interface PlansModalProps {
  isOpen: boolean;
  onClose?(): void;
}

export const PlansModal: React.FC<PlansModalProps> = ({ isOpen, onClose }) => {
  const b = block('PlansModal');

  const { t } = useTranslation();

  const [showMode, setShowMode] = useState<'monthly' | 'yearly'>('yearly');

  return (
    <FreshModal isOpen={isOpen} size="lg" onClose={onClose}>
      <div className={b()}>
        <div className={b('header')}>
          <p className={b('title')}>{t('dashboard.modals.plans.title')}</p>
          <p className={b('desc')}>{t('dashboard.modals.plans.desc')}</p>
          <div className={b('switch')}>
            <FreshButton
              color={showMode === 'yearly' ? 'transparent-grey' : 'pink'}
              leftIcon={
                <IconSwitch
                  className={b('switchIcon', { white: showMode === 'monthly' }).toString()}
                />
              }
              size="sm"
              text={
                showMode === 'monthly'
                  ? `${t('plans.switchToAnnual')} (-20%)`
                  : t('plans.switchToMonthly')
              }
              type="submit"
              onClick={() => setShowMode(showMode === 'monthly' ? 'yearly' : 'monthly')}
            />
          </div>
        </div>
        <PlansCards showMode={showMode} />
      </div>
    </FreshModal>
  );
};
