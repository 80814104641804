import React from 'react';
import PropTypes from 'prop-types';

const IconUploadArrow = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="33"
    viewBox="0 0 32 33"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M16 2.66174C23.7318 2.66174 30 8.92992 30 16.6617C30 24.3936 23.7318 30.6617 16 30.6617C8.26818 30.6617 2 24.3936 2 16.6617C2 8.92958 8.26783 2.66174 16 2.66174ZM15.7038 8.89111C15.1843 9.25335 11.099 13.4338 10.3052 14.2278C9.64546 14.8876 10.6479 15.8901 11.3077 15.2303L15.2916 11.2464V23.788C15.2916 24.7212 16.7091 24.7212 16.7091 23.788V11.2467L20.693 15.2306C21.3528 15.8904 22.3552 14.888 21.6955 14.2282L16.501 9.03412C16.2895 8.82433 15.9748 8.76699 15.7038 8.89111ZM24.8972 7.76454C19.9836 2.8509 12.0168 2.8509 7.1028 7.76454C2.18916 12.6782 2.18916 20.6453 7.1028 25.5589C12.0164 30.4726 19.9832 30.4726 24.8972 25.5589C29.8108 20.645 29.8108 12.6785 24.8972 7.76454Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
);

IconUploadArrow.propTypes = {
  className: PropTypes.string
};

IconUploadArrow.defaultProps = {
  className: ''
};

export default IconUploadArrow;
