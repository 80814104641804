import React, { useEffect, useCallback, Suspense, useMemo } from 'react';
import { RootState, useAppDispatch } from '@store';
import { userSlice } from '@features';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const LocaleWrapper: React.FC = (props) => {
  const { children } = props;

  const dispatch = useAppDispatch();

  const setLocale = useCallback(
    (locale: string) => {
      dispatch(userSlice.actions.setUserLocale(locale));
    },
    [dispatch]
  );

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setLocale(lng);
    });
  }, [setLocale]);

  const app = useSelector((store: RootState) => store.appManager);

  const currentLocale = useMemo(() => {
    if (app.locales) {
      return app.locales.find((item: any) => item.shortName === app.currentLocale);
    }

    return null;
  }, [app]);

  useEffect(() => {
    axios.defaults.headers.common['Accept-Language'] = currentLocale?.shortName || 'en';
  }, [currentLocale]);

  return <Suspense fallback={null}>{children}</Suspense>;
};
