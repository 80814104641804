import React from 'react';
import PropTypes from 'prop-types';

const IconClose = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6665 6.66699L13.3332 13.3337"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M13.3332 6.66699L6.6665 13.3337"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconClose.propTypes = {
  className: PropTypes.string
};

IconClose.defaultProps = {
  className: ''
};

export default IconClose;
