import React from 'react';
import PropTypes from 'prop-types';

const IconBackFresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 5L7.5 10L12.5 15"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconBackFresh.propTypes = {
  className: PropTypes.string
};

IconBackFresh.defaultProps = {
  className: ''
};

export default IconBackFresh;
