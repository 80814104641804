import React from 'react';
import PropTypes from 'prop-types';

const IconPlus = ({ className }) => (
  <svg
    className={className}
    height={10}
    viewBox="0 0 10 10"
    width={10}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#fff" height={1.429} rx={0.714} width={10} y={4.286} />
    <rect height={1.429} rx={0.714} transform="rotate(-90 4.286 10)" width={10} x={4.286} y={10} />
  </svg>
);

IconPlus.propTypes = {
  className: PropTypes.string
};

IconPlus.defaultProps = {
  className: ''
};

export default IconPlus;
