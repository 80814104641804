import React from 'react';
import PropTypes from 'prop-types';

const IconResend = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7894 8.49243C21.6646 8.36839 21.4958 8.29876 21.3199 8.29876C21.1439 8.29876 20.9751 8.36839 20.8504 8.49243L19.1853 10.1575C18.8186 8.07399 17.7018 6.19684 16.0458 4.88046C14.3898 3.56407 12.3091 2.89954 10.1966 3.01232C8.08409 3.12511 6.08599 4.00741 4.57953 5.49265C3.07306 6.97788 2.1625 8.96326 2.01975 11.0739C1.87699 13.1846 2.51194 15.2745 3.80469 16.9491C5.09745 18.6236 6.95856 19.767 9.03662 20.1632C11.1147 20.5594 13.2659 20.181 15.0841 19.0996C16.9023 18.0182 18.2617 16.3085 18.9056 14.2934C18.9464 14.1295 18.9233 13.9562 18.8411 13.8086C18.7589 13.661 18.6237 13.5502 18.4629 13.4986C18.302 13.4469 18.1276 13.4583 17.9748 13.5305C17.8221 13.6026 17.7024 13.7301 17.6402 13.8871C17.1031 15.5671 15.9779 16.997 14.4714 17.9141C12.9648 18.8312 11.178 19.174 9.43896 18.8796C7.69996 18.5851 6.12555 17.6732 5.00485 16.3113C3.88416 14.9494 3.29241 13.2288 3.33828 11.4657C3.38415 9.70253 4.06456 8.01511 5.25456 6.71329C6.44455 5.41147 8.06425 4.58266 9.81621 4.37905C11.5682 4.17544 13.3348 4.6107 14.7916 5.60491C16.2485 6.59912 17.2977 8.08552 17.7467 9.79116L16.468 8.51241C16.3406 8.4033 16.1767 8.34629 16.0091 8.35276C15.8414 8.35924 15.6824 8.42872 15.5638 8.54734C15.4452 8.66595 15.3757 8.82496 15.3693 8.99258C15.3628 9.16019 15.4198 9.32408 15.5289 9.45149L18.193 12.1155C18.2549 12.178 18.3285 12.2275 18.4097 12.2613C18.4909 12.2951 18.5779 12.3126 18.6658 12.3126C18.7537 12.3126 18.8408 12.2951 18.922 12.2613C19.0031 12.2275 19.0768 12.178 19.1387 12.1155L21.8028 9.45149C21.8664 9.38847 21.9166 9.31328 21.9505 9.2304C21.9844 9.14752 22.0012 9.05866 21.9999 8.96913C21.9987 8.8796 21.9794 8.79125 21.9432 8.70934C21.9071 8.62743 21.8548 8.55366 21.7894 8.49243Z"
      fill="#FD19CC"
    />
  </svg>
);

IconResend.propTypes = {
  className: PropTypes.string
};

IconResend.defaultProps = {
  className: ''
};

export default IconResend;
