import React from 'react';
import cn from 'classnames';
import { renderToString } from 'react-dom/server';
import { nanoid } from 'nanoid';
import { BackgroundType } from '@storysdk/react';
import { MATERIAL_ICONS } from './_constants';

type PropsType = {
  name: string;
  size?: number | string;
  className?: string;
  color?: string;
  background?: BackgroundType;
};

const CLASS_NAME = 'MaterialIcon';

export const MaterialIconToString = ({
  name = 'ArrowCircleUpOutlineIcon',
  className,
  color = '#ffffff',
  background,
  size = 'auto'
}: PropsType) => {
  const Icon = MATERIAL_ICONS[name];

  let gradient;
  const gradientId = nanoid();

  if (background?.type === 'gradient') {
    gradient = (
      <linearGradient id={gradientId} x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%" stopColor={background.value[0]} />
        <stop offset="100%" stopColor={background.value[1]} />
      </linearGradient>
    );
  }
  return renderToString(
    <span
      className={cn(`${CLASS_NAME}`, className)}
      role="button"
      style={{ width: size, height: size }}
      tabIndex={0}
    >
      <Icon color={color} gradient={gradient} gradientId={gradientId} />
    </span>
  );
};
