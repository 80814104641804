import React from 'react';
import block from 'bem-cn';
import './GroupAvatar.scss';
import { IconPencil } from '@components/Icon';

const b = block('GroupAvatar');

interface Props {
  image: string;
  size: 'lg' | 'sm';
  isSquared?: boolean;
  isHovered?: boolean;
  current?: boolean;
  onEditClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const GroupAvatar: React.FC<Props> = ({
  image,
  size,
  current,
  isSquared,
  isHovered,
  onMouseEnter,
  onMouseLeave,
  onEditClick
}) => (
  <div className={b('container')}>
    <img
      alt=""
      className={b({ size, current, squared: isSquared })}
      src={image}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />

    <div
      className={b('editIcon', { visible: isHovered })}
      onClick={onEditClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <IconPencil />
    </div>
  </div>
);
