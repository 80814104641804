import React, { memo } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { EditorMode, GroupsType } from '@features';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesSlice, useCurrentStoriesType } from '@features/stories/hooks';
import './EditorModeToggle.scss';

const b = block('EditorModeToggle');

export const EditorModeToggle: React.FC = memo(() => {
  const { type } = useParams<{
    type: GroupsType;
  }>();

  const storiesType = useCurrentStoriesType(type);
  const editorMode = useSelector((store: RootState) => store[storiesType].editor.editorMode);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const handleChangeMode = (mode: EditorMode) => {
    dispatch(currentStoriesSlice.actions.setEditorMode(mode));
  };

  return (
    <div className={b()}>
      <button
        className={b('btn', { active: editorMode === EditorMode.DESIGN })}
        onClick={() => handleChangeMode(EditorMode.DESIGN)}
      >
        {t('editor.design')}
      </button>
      <button
        className={b('btn', { active: editorMode === EditorMode.LOGIC })}
        onClick={() => handleChangeMode(EditorMode.LOGIC)}
      >
        {t('editor.logics')}
      </button>
    </div>
  );
});
