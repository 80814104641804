import React from 'react';
import PropTypes from 'prop-types';

const IconProfile = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    stroke="#A49BA4"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M4 14.4867C5.3075 13.2159 7.07667 12.4342 9.01917 12.4342C10.9425 12.4342 12.6925 13.2 14 14.4475C12.6925 15.7184 10.9233 16.5 8.98083 16.5C7.0575 16.5 5.3075 15.7342 4 14.4867Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M2.3125 12.3542C1.80417 11.3425 1.5 10.21 1.5 9C1.5 4.855 4.855 1.5 9 1.5C13.145 1.5 16.5 4.855 16.5 9C16.5 10.2083 16.1975 11.3417 15.6858 12.3525"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M10.7678 5.56561C11.7441 6.54191 11.7441 8.12483 10.7678 9.10114C9.79146 10.0774 8.20854 10.0774 7.23223 9.10114C6.25592 8.12483 6.25592 6.54191 7.23223 5.56561C8.20854 4.5893 9.79146 4.5893 10.7678 5.56561"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconProfile.propTypes = {
  className: PropTypes.string
};

IconProfile.defaultProps = {
  className: ''
};

export default IconProfile;
