import React from 'react';
import PropTypes from 'prop-types';

const IconPreviewSettings = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9114 5.32197V3.53686C10.9114 3.03378 10.5036 2.62598 10.0005 2.62598C9.49745 2.62598 9.08964 3.03378 9.08964 3.53686V5.32192C8.34358 5.33058 7.73926 5.93978 7.73926 6.68784V7.14329C7.73926 7.89135 8.34363 8.5006 9.08964 8.5092V16.4632C9.08964 16.9663 9.49745 17.3741 10.0005 17.3741C10.5036 17.3741 10.9114 16.9663 10.9114 16.4632V8.50925C11.6575 8.50064 12.2618 7.8914 12.2618 7.14333V6.68789C12.2618 5.93982 11.6575 5.33058 10.9114 5.32197Z"
      fill="white"
    />
    <path
      d="M16.6497 9.66384V3.53686C16.6497 3.03378 16.2419 2.62598 15.7388 2.62598C15.2358 2.62598 14.8279 3.03378 14.8279 3.53686V9.6638C14.0819 9.6724 13.4775 10.2817 13.4775 11.0297V11.4851C13.4775 12.2332 14.0819 12.8425 14.8279 12.8511V16.4632C14.8279 16.9663 15.2358 17.3741 15.7388 17.3741C16.2419 17.3741 16.6497 16.9663 16.6497 16.4632V12.8511C17.3957 12.8426 18.0001 12.2333 18.0001 11.4852V11.0298C18.0001 10.2817 17.3957 9.67245 16.6497 9.66384Z"
      fill="white"
    />
    <path
      d="M5.17216 7.87245V3.53686C5.17216 3.03378 4.76431 2.62598 4.26128 2.62598C3.75815 2.62598 3.35039 3.03378 3.35039 3.53686V7.8724C2.60433 7.88106 2 8.49026 2 9.23832V9.69377C2 10.4418 2.60437 11.0511 3.35039 11.0597V16.4633C3.35039 16.9664 3.75815 17.3742 4.26128 17.3742C4.76431 17.3742 5.17216 16.9664 5.17216 16.4633V11.0598C5.91822 11.0512 6.52255 10.4419 6.52255 9.69381V9.23837C6.52255 8.4903 5.91822 7.88106 5.17216 7.87245Z"
      fill="white"
    />
  </svg>
);

IconPreviewSettings.propTypes = {
  className: PropTypes.string
};

IconPreviewSettings.defaultProps = {
  className: ''
};

export default IconPreviewSettings;
