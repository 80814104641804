import React from 'react';
import PropTypes from 'prop-types';

const IconPencil = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="16"
    stroke="#A49BA4"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M3 13L5.62506 12.6758C5.80923 12.6533 5.9809 12.5692 6.11257 12.4383L12.4719 6.07902C13.176 5.37484 13.176 4.23315 12.4719 3.52814V3.52814C11.7677 2.82395 10.626 2.82395 9.92098 3.52814L3.62085 9.82827C3.49334 9.95577 3.41084 10.1208 3.38501 10.2999L3 13Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconPencil.propTypes = {
  className: PropTypes.string
};

IconPencil.defaultProps = {
  className: ''
};

export default IconPencil;
