import React from 'react';
import PropTypes from 'prop-types';

const IconPasswordHidden = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="20"
    stroke="#A49BA4"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 15.8333C9.29833 15.8333 8.59583 15.685 7.91333 15.4208"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M17.4017 10.39C15.825 13.3058 12.9125 15.8333 10 15.8333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M15.8992 7.43417C16.475 8.10834 16.9867 8.84334 17.4017 9.61084C17.5325 9.85334 17.5325 10.1475 17.4017 10.39"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M4.16663 15.8333L15.8333 4.16666"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M8.14412 11.8558C7.11912 10.8308 7.11912 9.16832 8.14412 8.14332C9.16912 7.11832 10.8316 7.11832 11.8566 8.14332"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M14.2034 5.79666C12.9142 4.79916 11.4567 4.16666 10 4.16666C7.08754 4.16666 4.17504 6.69416 2.59837 9.61082C2.46754 9.85332 2.46754 10.1475 2.59837 10.39C3.3867 11.8475 4.50837 13.2075 5.7967 14.2042"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconPasswordHidden.propTypes = {
  className: PropTypes.string
};

IconPasswordHidden.defaultProps = {
  className: ''
};

export default IconPasswordHidden;
