import React from 'react';
import { CropProps } from '@features';
import { FreshModal } from '@components/_fresh';
import { CreateOnboardingContainer } from '../CreateOnboardingContainer';
import './CreateOnboardingModal.scss';

interface Props {
  isOpen: boolean;
  currentAppId?: string;
  currentImage: CropProps;
  onClose(): void;
  clearCurrentImage(): void;
}

export const CreateOnboardingModal: React.FC<Props> = (props) => {
  const { isOpen, currentImage, currentAppId, onClose, clearCurrentImage } = props;

  return (
    <FreshModal isOpen={isOpen} onClose={() => onClose()}>
      <CreateOnboardingContainer
        clearCurrentImage={clearCurrentImage}
        currentAppId={currentAppId}
        currentImage={currentImage}
        onClose={onClose}
      />
    </FreshModal>
  );
};
