import React, { memo, useCallback } from 'react';
import cn from 'classnames';
import './WidgetThemesColor.scss';

const CLASS_NAME = 'WidgetThemesColor';

type PropsType = {
  color: string;
  selectedColor?: string;
  hasDarkBackground?: boolean;
  onChange?: (color: string) => void;
};

export const WidgetThemesColor = memo(
  ({ color, selectedColor, hasDarkBackground = false, onChange }: PropsType) => {
    const handleChange = useCallback(
      (e) => {
        if (onChange) {
          e.stopPropagation();
          onChange(color);
        }
      },
      [color, onChange]
    );

    return (
      <button
        className={cn(`${CLASS_NAME}__wrapper`, {
          [`${CLASS_NAME}__wrapper--isSelected`]: Boolean(selectedColor && selectedColor === color),
          [`${CLASS_NAME}__wrapper--hasDarkBackground`]: hasDarkBackground
        })}
        type="button"
        onClick={handleChange}
      >
        <div className={cn(`${CLASS_NAME}`, `${CLASS_NAME}--${color}`)} />
      </button>
    );
  }
);
