import React from 'react';
import { SettingsBox } from '@modules';
import {
  ControlGroup,
  MAX_ANGLE_VALUE,
  MIN_ANGLE_VALUE,
  SettingAlignOnChangeType,
  SettingsAlign,
  SettingsInput,
  SettingsSlider
} from '@features/stories/editorSettings/components';
import { useTranslation } from 'react-i18next';
import {
  GiphyWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { Icon } from '@components';

type GiphyWidgetSettingsProps = {
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  params: GiphyWidgetParamsType;
  onChange(params: GiphyWidgetParamsType): void;
  onChangePosition(params: WidgetPositionType): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
};

export const GiphyWidgetSettings: React.FC<GiphyWidgetSettingsProps> = (props) => {
  const { params, position, positionLimits, onChange, onChangeAlign, onChangePosition } = props;

  const { t } = useTranslation();

  const handleChange = (field: string) => (value: any) => {
    const newParams = {
      ...params,
      [field]: value
    };

    onChange(newParams);
  };

  const handleChangePosition = (field: string) => (value: any) => {
    onChangePosition({
      ...position,
      [field]: value,
      realWidth: field === 'width' ? value : position.realWidth,
      realHeight: field === 'height' ? value : position.realHeight
    });
  };

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup
            isLocked={position.isHeightLocked}
            onLockChange={handleChangePosition('isHeightLocked')}
          >
            <SettingsInput
              min={positionLimits.minWidth}
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              min={positionLimits.minHeight}
              postfix="px"
              prefix="H"
              type="number"
              value={position.height}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <SettingsSlider
            title={t('editor.opacity')}
            value={params.widgetOpacity}
            onChange={handleChange('widgetOpacity')}
          />
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
          <SettingsInput
            icon={<Icon name="border-radius" />}
            postfix="px"
            type="number"
            value={params.borderRadius}
            onChange={handleChange('borderRadius')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>
    </>
  );
};
