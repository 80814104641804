import React from 'react';
import PropTypes from 'prop-types';

const IconUnsplash = ({ className }) => (
  <svg
    className={className}
    height={16}
    viewBox="0 0 16 16"
    width={16}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 7.085h-5.053v4.448H5.034V7.085H0V16h16V7.085zM5.034 0h5.913v4.467H5.034V0z" />
  </svg>
);

IconUnsplash.propTypes = {
  className: PropTypes.string
};

IconUnsplash.defaultProps = {
  className: ''
};

export default IconUnsplash;
