import React, { useMemo } from 'react';
import block from 'bem-cn';
import hexOpacity from 'hex-opacity';
import parse from 'parse-color';

import './ColorExample.scss';

const b = block('ColorExample');

interface Props {
  color: string;
  index: number;
  className?: string;
  onClick: (param: string) => void;
}

export const ColorExample: React.FunctionComponent<Props> = (props) => {
  const { color, index, className, onClick } = props;

  const parsed = useMemo(() => parse(color), [color]);
  const hex = useMemo(() => hexOpacity.create(parsed.hex, parsed.rgba[parsed.rgba.length - 1]), [
    parsed
  ]);
  const alpha = useMemo(() => (parsed ? parsed.rgba[parsed.rgba.length - 1] : 1), [parsed]);

  return (
    <div
      aria-label={color}
      className={`${b()} ${className ?? ''}`}
      role="button"
      tabIndex={index}
      onClick={() => onClick(hex)}
      onKeyDown={() => onClick(hex)}
    >
      <div
        className={b('mainColor', { opacity: alpha < 1 })}
        style={{
          background:
            alpha < 1
              ? `linear-gradient(135deg, rgb(${parsed.rgb[0]}, ${parsed.rgb[1]}, ${parsed.rgb[2]}) 0%, rgb(${parsed.rgb[0]}, ${parsed.rgb[1]}, ${parsed.rgb[2]}) 50%, ${color} 50%, ${color} 100%)`
              : color
        }}
      />
      {alpha < 1 ? <div className={b('background')} /> : null}
    </div>
  );
};
