import React, { useCallback } from 'react';
import {
  SettingsBox,
  FontSizeIcon,
  ColorThemesItem,
  ColorThemes,
  ColorThemesItemColor
} from '@modules';
import {
  ControlGroup,
  SettingsInputText,
  SettingsSelect,
  SettingsInput,
  SettingAlignOnChangeType,
  SettingsAlign,
  MIN_ANGLE_VALUE,
  MAX_ANGLE_VALUE
} from '@features/stories/editorSettings/components';
import { FONT_STYLES, FONT_SIZE_LIMITS } from '@features/stories/editorSettings/constants';
import { SettingsFontSelector } from '@features/stories/editorSettings/components/SettingsFontSelector/SettingsFontSelector';
import { useTranslation } from 'react-i18next';
import {
  BackgroundColorType,
  GradientDirection,
  LinkWidgetParamsType,
  WidgetPositionLimitsType,
  WidgetPositionType
} from '@storysdk/react';
import { useCurrentStoriesSlice } from '@features/stories/hooks';
import { useParams } from 'react-router-dom';
import { GroupsType } from '@features';
import { useAppDispatch } from '@store';
import { Icon } from '@components';
import { Drop } from '@custom';
import { useDebounce } from '@hooks';
import { getUrlFromString } from '@utils';

const COLOR_THEMES: ColorThemesItemColor[] = [
  {
    background: {
      type: BackgroundColorType.GRADIENT,
      value: ['#AE13AB', '#890EEA'],
      direction: GradientDirection.LEFT_TO_RIGHT
    },
    text: '#FFFFFF'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#FD19CC'
    },
    text: '#FFFFFF'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#FFA93D'
    },
    text: '#FFFFFF'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#366EFE'
    },
    text: '#FFFFFF'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#05051D'
    },
    text: '#FFFFFF'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#FF4C25'
    },
    text: '#FFFFFF'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#F3CC00'
    },
    text: '#FFFFFF'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#00B2FF'
    },
    text: '#FFFFFF'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#FFFFFF'
    },
    text: '#000000'
  },
  {
    background: {
      type: BackgroundColorType.COLOR,
      value: '#ffffff80'
    },
    text: '#FFFFFF'
  }
];

export interface LinkWidgetSettingsProps {
  keepRatio: boolean;
  params: LinkWidgetParamsType;
  position: WidgetPositionType;
  positionLimits: WidgetPositionLimitsType;
  onChangePosition(params: WidgetPositionType, stopSaveImage?: boolean): void;
  onChange(params: LinkWidgetParamsType, stopSaveImage?: boolean, syncToLocales?: boolean): void;
  onChangeAlign(alignParams: SettingAlignOnChangeType): void;
}

export const LinkWidgetSettings = React.memo((props: LinkWidgetSettingsProps) => {
  const { params, position, positionLimits, onChange, onChangePosition, onChangeAlign } = props;

  const { type } = useParams<{
    type: GroupsType;
  }>();
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const { t } = useTranslation();

  const getTextWidth = useCallback(
    (text: string) => Math.round((text.length * params.fontSize) / 1.25),
    [params.fontSize]
  );

  const handleChange = (field: string) => (value: any) => {
    const newParams = {
      ...params,
      [field]: value
    };

    if (field === 'text') {
      const textWidth = getTextWidth(value);

      if (textWidth && position.realWidth < textWidth) {
        handleChangePosition('width')(textWidth);
      }
    }

    onChange(newParams);
  };

  const hanldeChangeLink = (value: string) => {
    const url = getUrlFromString(value);

    if (url) {
      const protocol = url[1];
      const hostName = url[4];

      onChange({
        ...params,
        url: protocol ? value : `https://${value}`,
        text: hostName
      });

      const textWidth = getTextWidth(hostName);

      if (textWidth && position.realWidth < textWidth) {
        handleChangePosition('width')(textWidth);
      }
    } else {
      onChange({
        ...params,
        url: value
      });
    }
  };

  const handleChangeTheme = (color: ColorThemesItemColor) => {
    onChange({
      ...params,
      backgroundColor: color.background,
      color: { type: BackgroundColorType.COLOR, value: color.text }
    });
  };

  const handleChangePosition = (field: string) => (value: any) => {
    if (positionLimits.keepRatio && positionLimits.ratioIndex) {
      if (field === 'width') {
        onChangePosition({
          ...position,
          width: value,
          height: Math.round(value / positionLimits.ratioIndex),
          realWidth: value,
          realHeight: Math.round(value / positionLimits.ratioIndex)
        });
      } else if (field === 'height') {
        onChangePosition({
          ...position,
          width: Math.round(value * positionLimits.ratioIndex),
          height: value,
          realHeight: value,
          realWidth: Math.round(value * positionLimits.ratioIndex)
        });
      }
    } else {
      onChangePosition({
        ...position,
        [field]: value
      });
    }
  };

  const dispatch = useAppDispatch();

  const handleDropOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(currentStoriesSlice.actions.setIsPickerOpen(isOpen));
    },
    [dispatch]
  );

  return (
    <>
      <SettingsBox.Group>
        <SettingsBox.Field>
          <SettingsAlign onChange={onChangeAlign} />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.position')}>
        <SettingsBox.Field>
          <ControlGroup>
            <SettingsInput
              postfix="px"
              prefix="X"
              type="number"
              value={Math.round(position.x)}
              onChange={handleChangePosition('x')}
            />
            <SettingsInput
              postfix="px"
              prefix="Y"
              type="number"
              value={Math.round(position.y)}
              onChange={handleChangePosition('y')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup
            isLocked={position.isHeightLocked}
            onLockChange={handleChangePosition('isHeightLocked')}
          >
            <SettingsInput
              max={positionLimits.maxWidth}
              min={positionLimits.minWidth}
              postfix="px"
              prefix="W"
              type="number"
              value={position.width}
              onChange={handleChangePosition('width')}
            />
            <SettingsInput
              disabled={position.isHeightLocked}
              min={positionLimits.minHeight}
              postfix="px"
              prefix="H"
              type="number"
              value={position.height}
              onChange={handleChangePosition('height')}
            />
          </ControlGroup>
        </SettingsBox.Field>
        <SettingsBox.Field separate>
          <SettingsInput
            icon={<Icon name="angle" />}
            max={MAX_ANGLE_VALUE}
            min={MIN_ANGLE_VALUE}
            postfix="°"
            type="number"
            value={position.rotate ? Math.round(position.rotate) : 0}
            onChange={handleChangePosition('rotate')}
          />
        </SettingsBox.Field>
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.link')}>
        <SettingsInputText
          icon="url"
          name="url"
          placeholder={t('editor.link')}
          value={params.url ?? ''}
          onChange={(_, value) => hanldeChangeLink(value)}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.title')}>
        <SettingsInputText
          name="text"
          value={params.text}
          onChange={(fieldName, value) => handleChange(fieldName)(value)}
        />
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.themes')}>
        <Drop
          control={
            <ColorThemesItem
              color={{
                background: params.backgroundColor,
                text: params.color.value
              }}
              isDark
              selected={{ background: params.backgroundColor, text: params.color.value }}
            />
          }
          handleOpen={handleDropOpen}
        >
          <ColorThemes
            options={COLOR_THEMES}
            selected={{ background: params.backgroundColor, text: params.color.value }}
            onChange={handleChangeTheme}
          />
        </Drop>
      </SettingsBox.Group>

      <SettingsBox.Group title={t('editor.text')}>
        <SettingsBox.Field>
          <SettingsFontSelector value={params.fontFamily} onChange={handleChange('fontFamily')} />
        </SettingsBox.Field>
        <SettingsBox.Field>
          <ControlGroup sub>
            <SettingsSelect
              options={FONT_STYLES}
              value={params.fontParams}
              onChange={handleChange('fontParams')}
            />
            <SettingsInput
              icon={<FontSizeIcon />}
              max={FONT_SIZE_LIMITS.max}
              min={FONT_SIZE_LIMITS.min}
              type="number"
              value={params.fontSize}
              onChange={handleChange('fontSize')}
            />
          </ControlGroup>
        </SettingsBox.Field>
      </SettingsBox.Group>
    </>
  );
});
