import { StoryStatus } from '@features/stories';
import { RootState, useAppDispatch } from '@store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchChangeOnboardingPublishStatus } from '@features';
import { getStoriesFlattenedArr } from '@utils';
import { fetchChangeGroupPublishStatus } from '../groupsSlice';
import { GroupsType } from '../types';
import { useGroup } from './useGroup';

export const useGroupPublish = (
  appId?: string,
  groupId?: string,
  type: GroupsType = GroupsType.GROUP,
  isStoriesLoaded?: boolean
) => {
  const group = useGroup(groupId ?? '', type);

  const stories = useSelector((store: RootState) => store.stories.stories);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!appId || !groupId || !group || !isStoriesLoaded) return;

    let isGroupShouldBePublished = false;
    const storiesArray = getStoriesFlattenedArr(stories);
    storiesArray.forEach((storyItem) => {
      const storyDataArray = Object.values(storyItem.storyData);
      if (
        storyDataArray.some((storyDataItem: any) => storyDataItem.status === StoryStatus.ACTIVE)
      ) {
        isGroupShouldBePublished = true;
      }
    });

    if (isGroupShouldBePublished === group?.active) return;

    if (type === GroupsType.ONBOARDING) {
      dispatch(
        fetchChangeOnboardingPublishStatus({ groupId, appId, active: isGroupShouldBePublished })
      );
    } else if (type === GroupsType.GROUP) {
      dispatch(fetchChangeGroupPublishStatus({ groupId, appId, active: isGroupShouldBePublished }));
    }
  }, [stories]);
};
