import { WidgetPositionType } from '@storysdk/react';
import { ElementVerticalAlign, GroupPositionType } from '../types';

export const getAlignVerticalPosition = (
  align: ElementVerticalAlign,
  position: WidgetPositionType,
  container: GroupPositionType
): WidgetPositionType => {
  let newPosition = position;

  switch (align) {
    case ElementVerticalAlign.TOP:
      newPosition = {
        ...newPosition,
        y: container.y
      };
      break;
    case ElementVerticalAlign.MIDDLE:
      newPosition = {
        ...newPosition,
        y: container.y + Math.ceil(container.height / 2) - Math.ceil(newPosition.realHeight / 2)
      };
      break;
    case ElementVerticalAlign.BOTTOM:
      newPosition = {
        ...newPosition,
        y: container.y + container.height - Math.ceil(newPosition.realHeight)
      };
      break;
  }

  return newPosition;
};
