import React from 'react';
import { FormGroup, FormLabel } from '@components';
import { Input } from '../Input/Input';

interface Props {
  input: Partial<React.InputHTMLAttributes<HTMLInputElement>>;
  label: string;
  type: string;
  placeholder?: string;
  onChange: any;
  theme?: 'light' | 'dark';
  className?: string;
  iconName?: string;
  iconClassName?: string;
  invalid?: boolean;
}

export const InputGroup: React.FunctionComponent<Props> = (props) => {
  const {
    input,
    label,
    type,
    theme = 'light',
    placeholder,
    onChange,
    className,
    iconName,
    iconClassName,
    invalid
  } = props;

  return (
    <FormGroup className={className}>
      <FormLabel
        color={theme === 'light' ? 'black' : 'white'}
        iconClassName={iconClassName}
        iconName={iconName}
        invalid={invalid}
        isBlack
      >
        {label}
      </FormLabel>
      <Input
        input={{ ...input, type, onChange }}
        invalid={invalid}
        placeholder={placeholder}
        theme={theme}
      />
      {/* {touched &&
        ((error && (
          <span style={{ fontSize: '12px', lineHeight: '15px', color: '#FF00D0' }}>{error}</span>
        )) ||
          (warning && <span>{warning}</span>))} */}
    </FormGroup>
  );
};
