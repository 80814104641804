import React, { useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ScheduleTimeType } from '@features';
import { FreshModal, FreshModalTitle, FreshButton, FreshDatepicker } from '@components/_fresh';
import './StoryScheduleModal.scss';

interface StoryScheduleModalProps {
  theme?: 'light' | 'dark';
  isOpen: boolean;
  layersGroupId: string;
  startTime?: string;
  endTime?: string | null;
  onClose(): void;
  handleSaveSchedule(storyId: string, time: ScheduleTimeType): void;
}

const b = block('StoryScheduleModal');

export const StoryScheduleModal: React.FC<StoryScheduleModalProps> = (props) => {
  const {
    isOpen,
    theme = 'light',
    startTime,
    endTime,
    layersGroupId,
    onClose,
    handleSaveSchedule
  } = props;

  const [time, setTime] = useState({
    startTime: startTime ?? DateTime.now().toISO(),
    endTime: endTime ?? null
  });

  const { t } = useTranslation();

  const handleChangeDate = (date: number | null, type: string) => {
    setTime({ ...time, [type]: date ? DateTime.fromSeconds(date).toISO() : null });
  };

  const handleSave = () => {
    handleSaveSchedule(layersGroupId, time);
  };

  return (
    <FreshModal isOpen={isOpen} size="md" theme={theme} onClose={onClose}>
      <div className={b()}>
        <FreshModalTitle centered>{t('dashboard.modals.schedule.title')}</FreshModalTitle>

        <div className={b('timeContainer')}>
          <FreshDatepicker
            className={b('datepicker')}
            id="StartDatePicker"
            label={t('dashboard.groups.startDate')}
            theme={theme}
            value={time.startTime ? DateTime.fromISO(time.startTime).toSeconds() : null}
            withTime
            onChange={(date) => handleChangeDate(date.value, 'startTime')}
          />

          <FreshDatepicker
            canBeEmpty
            className={b('datepicker')}
            id="EndDatePicker"
            label={t('dashboard.groups.endDate')}
            theme={theme}
            value={time.endTime ? DateTime.fromISO(time.endTime).toSeconds() : null}
            withTime
            onChange={(date) => handleChangeDate(date.value, 'endTime')}
          />
        </div>

        <p className={b('desc')}>{t('dashboard.groups.dateDesc')}</p>

        <section className={b('btnContaier')}>
          <FreshButton
            color="pink"
            text={t('dashboard.modals.schedule.saveBtn')}
            onClick={handleSave}
          />
        </section>
      </div>
    </FreshModal>
  );
};
