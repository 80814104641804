import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { fetchUpdateApp } from '@features';
import { IconGoogle } from '@components';
import { AppIntegrationModal, AppIntegrationsCard } from './components';
import './AppIntegrationsTab.scss';

const b = block('AppIntegrationsTab');

export const AppIntegrationsTab: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const appState = useSelector((state: RootState) => state);
  const app = appState.appManager;

  const dispatch = useAppDispatch();

  const onSaveGoogleAnalytics = (trackId: string) => {
    if (app.current) {
      dispatch(
        fetchUpdateApp({
          id: app.current.id,
          settings: {
            ...app.current.settings,
            integrations: {
              ...app.current.settings?.integrations,
              googleAnalytics: {
                trackingId: trackId
              }
            }
          }
        })
      );
    }
  };

  return (
    <>
      <div className={b()}>
        <div className={b('col')}>
          <div className={b('header')}>
            <p className={b('title')}>{t('dashboard.integrations.title')}</p>
          </div>
          <div className={b('cards')}>
            <AppIntegrationsCard
              icon={<IconGoogle className={b('icon')} />}
              isActive={!!app.current?.settings?.integrations?.googleAnalytics?.trackingId}
              title="Google Analytics"
              type="google"
              onActivate={() => {
                setIsModalOpen(true);
              }}
              onConfigure={() => {
                setIsModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>
      <AppIntegrationModal
        isOpen={isModalOpen}
        title="Google Analytics"
        trackId={app.current?.settings?.integrations?.googleAnalytics?.trackingId}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onSubmit={onSaveGoogleAnalytics}
      />
    </>
  );
};
