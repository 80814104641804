import axios from 'axios';
import { Auth } from '@utils';

export const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Reject promise if usual error
      if ((error && error.response && error.response.status !== 401) || !error || !error.response) {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axios.interceptors.response.eject(interceptor);

      if (Auth.storage) {
        return axios({
          method: 'post',
          url: '/auth/refresh',
          data: {
            refresh_token: Auth.storage.refreshToken
          }
        })
          .then((response: any) => {
            if (!response.data.error) {
              const accessData = {
                access_token: response.data.data.access_token,
                expiration_time: response.data.data.expiration_time,
                refresh_token: response.data.data.refresh_token
              };

              if (Auth.storage) {
                const newAuthData = {
                  user: {
                    id: Auth.storage.user.id,
                    full_name: Auth.storage.user.fullName,
                    email: Auth.storage.user.email
                  },
                  ...accessData
                };

                Auth.setStorage(newAuthData);
              }

              error.response.config.headers.Authorization = `Bearer ${response.data.data.access_token}`;
            }

            return axios(error.response.config);
          })
          .catch((errorRefresh) => {
            localStorage.removeItem('auth');
            window.location.href = '/';
            return Promise.reject(errorRefresh);
          })
          .finally(createAxiosResponseInterceptor);
      }

      return null;
    }
  );
};
