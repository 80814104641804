import React, { memo } from 'react';
import block from 'bem-cn';
import { IconSliderArrow } from '@components';
import './FreshCalendarHeader.scss';

const b = block('FreshCalendarHeader');

type PropsType = {
  month: string;
  theme?: 'light' | 'dark';
  onPrevMonth: () => void;
  onNextMonth: () => void;
};

export const FreshCalendarHeader = memo(({ month, theme, onPrevMonth, onNextMonth }: PropsType) => (
  <div className={b({ theme })}>
    <button className={b('icon')} type="button" onClick={onPrevMonth}>
      <IconSliderArrow className={b('arrow')} />
    </button>

    <span className={b('month-name', { theme })}>{month}</span>

    <button className={b('icon')} type="button" onClick={onNextMonth}>
      <IconSliderArrow className={b('arrow', { next: true })} />
    </button>
  </div>
));
