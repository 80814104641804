import React, { useCallback, useState } from 'react';
import { Story, StoryPreview, StoryRenderingFallback } from '@features/stories';
import block from 'bem-cn';
import { STORY_WIDTH } from '@features/consts';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { CustomGroupControl, GroupType } from '@storysdk/react';
import { StoriesTemplateBase, Templates } from '@features/storyTemplates/types';
import { IconPlay } from '@components';
import { FreshCheckbox } from '@components/_fresh';
import './StoryChoosePreview.scss';

interface StoryChoosePreviewProps {
  template?: StoriesTemplateBase;
  story: Story;
  stories?: Story[];
  cardWidth?: number;
  cardHeight?: number;
  handleChoose?: (story: Story) => void;
  isChoosen?: boolean;
  isFilled?: boolean;
  isWithActions?: boolean;
}

const b = block('StoryChoosePreview');

const CARD_WIDTH_DEFAULT = 174;
const CARD_HEIGHT_DEFAULT = 309;

export const StoryChoosePreview: React.FC<StoryChoosePreviewProps> = ({
  story,
  template,
  stories,
  cardWidth = CARD_WIDTH_DEFAULT,
  cardHeight = CARD_HEIGHT_DEFAULT,
  handleChoose,
  isChoosen,
  isFilled,
  isWithActions
}) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

  const onChoose = useCallback(() => {
    if (handleChoose) {
      handleChoose(story);
    }
  }, [story, handleChoose]);

  const { t } = useTranslation();

  return (
    <>
      <div
        className={b({ filled: isChoosen && isFilled })}
        style={{
          width: cardWidth,
          height: cardHeight
        }}
      >
        <FreshCheckbox
          className={b('checkbox').toString()}
          name={`choose-checkbox-${story.id}`}
          type="round"
          value={!!isChoosen}
          onChange={onChoose}
        />
        <ErrorBoundary FallbackComponent={StoryRenderingFallback}>
          <StoryPreview
            isOnboarding={template?.category === Templates.Category.ONBOARDING}
            scaleIndex={cardWidth / STORY_WIDTH}
            story={story}
            templateVariant
          />
        </ErrorBoundary>

        {isWithActions && (
          <div className={b('overlay')}>
            <div className={b('mainButtons')}>
              <button className={b('btn')} onClick={onChoose}>
                {isChoosen ? t('dashboard.templates.unchoose') : t('dashboard.templates.choose')}
              </button>
              <button
                className={b('btn')}
                onClick={() => {
                  setIsPreviewOpen(true);
                }}
              >
                <IconPlay className={b('btnIcon').toString()} />
                {t('editor.header.preview')}
              </button>
            </div>
          </div>
        )}
      </div>

      {isWithActions && stories && template && (
        <CustomGroupControl
          group={{
            id: template.id,
            imageUrl: `${process.env.REACT_APP_SITE_URL}/images/group-default.jpg`,
            title: template.title,
            type:
              template?.category === Templates.Category.STORIES
                ? GroupType.GROUP
                : GroupType.ONBOARDING,
            settings: {},
            stories: stories
              .sort((storyA, storyB) => storyA.position - storyB.position)
              .map((item) => ({
                ...item,
                storyData: item.widgets,
                positionIndex: item.position
              }))
          }}
          handleCloseModal={() => setIsPreviewOpen(false)}
          handleNextGroup={() => undefined}
          handlePrevGroup={() => undefined}
          isCacheDisabled
          isFirstGroup
          isForceCloseAvailable
          isLastGroup
          isShowing={isPreviewOpen}
          startStoryId={story.id}
        />
      )}
    </>
  );
};
