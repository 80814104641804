import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const JoinTheTeamRedirectPage: React.FC = () => {
  const history = useHistory();
  const { appId, id } = useParams<{ appId: string; id: string }>();

  useEffect(() => {
    if (appId && id) {
      fetch(`${process.env.REACT_APP_API_URL}/apps/${appId}/collaborations/${id}/confirm`, {
        method: 'get'
      })
        .then((response) => {
          if (response.status === 200) {
            if (localStorage.getItem('auth')) {
              history.push(`/dashboard/${appId}/stories`);
            } else {
              history.push(`/?joinApp=${appId}`);
            }
          } else {
            history.push('/');
          }
        })
        .catch(() => {
          history.push('/');
        });
    } else {
      history.push('/');
    }
  }, [history, appId, id]);

  return null;
};
