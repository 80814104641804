import React, { useEffect, useState } from 'react';
import { STORY_HEIGHT, STORY_WIDTH } from '@features';
import { WidgetPositionType } from '@storysdk/react';
import { WidgetLine } from './WidgetLine';
import { WidgetOutline } from './WidgetOutline';
import { WidgetLineDotted } from './WidgetLineDotted';

interface WidgetLinesProps {
  presetWidth?: number;
  presetHeight?: number;
  selectedPosition: WidgetPositionType;
  hoveredPosition?: WidgetPositionType;
}

const getX = (position?: WidgetPositionType) => {
  if (position?.origin?.x) {
    return position.origin?.x;
  }

  if (position?.x) {
    return position.x;
  }

  return 0;
};

const getY = (position?: WidgetPositionType) => {
  if (position?.origin?.y) {
    return position.origin?.y;
  }

  if (position?.y) {
    return position.y;
  }

  return 0;
};

const getWidth = (position?: WidgetPositionType) => {
  if (position?.origin?.width) {
    return position.origin?.width;
  }

  if (position?.realWidth) {
    return position.realWidth;
  }

  return 0;
};

const getHeight = (position?: WidgetPositionType) => {
  if (position?.origin?.height) {
    return position.origin?.height;
  }

  if (position?.realHeight) {
    return position.realHeight;
  }

  return 0;
};

export const WidgetLines: React.FC<WidgetLinesProps> = ({
  selectedPosition,
  presetWidth,
  presetHeight,
  hoveredPosition
}) => {
  const storyWidth = presetWidth ?? STORY_WIDTH;
  const storyHeight = presetHeight ?? STORY_HEIGHT;

  const defaultState = {
    top: {
      top: 0,
      height: 0
    },
    right: {
      left: 0,
      width: 0
    },
    bottom: {
      top: 0,
      height: 0
    },
    left: {
      left: 0,
      width: 0
    }
  };

  const [state, setState] = useState(defaultState);

  const defaultDottedState = {
    horizontal: {
      top: 0,
      left: 0,
      width: 0
    },
    vertical: {
      top: 0,
      left: 0,
      height: 0
    }
  };

  const [dottedState, setDottedState] = useState(defaultDottedState);

  const relativePositionState = {
    isAboveOutside: false,
    isBelowOutside: false,
    isLeftOutside: false,
    isRightOutside: false,
    isIntersectedTop: false,
    isIntersectedBottom: false,
    isIntersectedLeft: false,
    isIntersectedRight: false
  };
  const [relativePosition, setRelativePosition] = useState(relativePositionState);

  const [showDotted, setShowDotted] = useState(false);

  useEffect(() => {
    const commonPositions = {
      top: {
        top: -getY(selectedPosition),
        height: getY(selectedPosition)
      },
      right: {
        left: getWidth(selectedPosition),
        width: storyWidth - (getX(selectedPosition) + getWidth(selectedPosition))
      },
      bottom: {
        top: getHeight(selectedPosition),
        height: storyHeight - getY(selectedPosition) - getHeight(selectedPosition)
      },
      left: {
        left: -getX(selectedPosition),
        width: getX(selectedPosition)
      }
    };

    const isAboveOutside =
      hoveredPosition &&
      getY(hoveredPosition) + getHeight(hoveredPosition) <= getY(selectedPosition);

    const isBelowOutside =
      hoveredPosition &&
      getY(hoveredPosition) >= getY(selectedPosition) + getHeight(selectedPosition);

    const isLeftOutside =
      hoveredPosition &&
      getX(hoveredPosition) + getWidth(hoveredPosition) <= getX(selectedPosition);

    const isRightOutside =
      hoveredPosition &&
      getX(selectedPosition) + getWidth(selectedPosition) <= getX(hoveredPosition);

    const isInsideHorizontal =
      hoveredPosition &&
      getX(selectedPosition) + getWidth(selectedPosition) / 2 > getX(hoveredPosition) &&
      getX(selectedPosition) + getWidth(selectedPosition) / 2 <
      getX(hoveredPosition) + getWidth(hoveredPosition);

    const isInsideVertical =
      hoveredPosition &&
      getY(selectedPosition) + getHeight(selectedPosition) / 2 > getY(hoveredPosition) &&
      getY(selectedPosition) + getHeight(selectedPosition) / 2 <
      getY(hoveredPosition) + getHeight(hoveredPosition);

    const isAboveInside =
      hoveredPosition &&
      isInsideHorizontal &&
      getY(selectedPosition) > getY(hoveredPosition) &&
      getY(selectedPosition) < getY(hoveredPosition) + getHeight(hoveredPosition);

    const isBelowInside =
      hoveredPosition &&
      isInsideHorizontal &&
      getY(selectedPosition) + getHeight(selectedPosition) > getY(hoveredPosition) &&
      getY(selectedPosition) + getHeight(selectedPosition) <
      getY(hoveredPosition) + getHeight(hoveredPosition);

    const isLeftInside =
      hoveredPosition &&
      isInsideVertical &&
      getX(selectedPosition) > getX(hoveredPosition) &&
      getX(selectedPosition) < getX(hoveredPosition) + getWidth(hoveredPosition);

    const isRightInside =
      hoveredPosition &&
      isInsideVertical &&
      getX(selectedPosition) + getWidth(selectedPosition) > getX(hoveredPosition) &&
      getX(selectedPosition) + getWidth(selectedPosition) <
      getX(hoveredPosition) + getWidth(hoveredPosition);

    const isIntersectedTop =
      hoveredPosition &&
      getY(hoveredPosition) + getHeight(hoveredPosition) >= getY(selectedPosition) &&
      getY(hoveredPosition) + getHeight(hoveredPosition) <=
      getY(selectedPosition) + getHeight(selectedPosition) / 2;

    const isIntersectedBottom =
      hoveredPosition &&
      getY(hoveredPosition) >= getY(selectedPosition) + getHeight(selectedPosition) / 2 &&
      getY(hoveredPosition) <= getY(selectedPosition) + getHeight(selectedPosition);

    const isIntersectedLeft =
      hoveredPosition &&
      getX(hoveredPosition) + getWidth(hoveredPosition) >= getX(selectedPosition) &&
      getX(hoveredPosition) + getWidth(hoveredPosition) <=
      getX(selectedPosition) + getWidth(selectedPosition) / 2;

    const isIntersectedRight =
      hoveredPosition &&
      getX(hoveredPosition) >= getX(selectedPosition) + getWidth(selectedPosition) / 2 &&
      getX(hoveredPosition) <= getX(selectedPosition) + getWidth(selectedPosition);

    setRelativePosition({
      isAboveOutside: !!isAboveOutside,
      isBelowOutside: !!isBelowOutside,
      isLeftOutside: !!isLeftOutside,
      isRightOutside: !!isRightOutside,
      isIntersectedTop: !!isIntersectedTop,
      isIntersectedLeft: !!isIntersectedLeft,
      isIntersectedBottom: !!isIntersectedBottom,
      isIntersectedRight: !!isIntersectedRight
    });

    let newPositions = { ...commonPositions };

    if (isAboveOutside) {
      const topHeight =
        getY(selectedPosition) - (getY(hoveredPosition) + getHeight(hoveredPosition));

      newPositions = {
        ...newPositions,
        top: { top: -topHeight, height: topHeight }
      };
    }

    if (isBelowOutside) {
      const bottomHeight =
        getY(hoveredPosition) - (getY(selectedPosition) + getHeight(selectedPosition));

      newPositions = {
        ...newPositions,
        bottom: {
          top: getHeight(selectedPosition),
          height: bottomHeight
        }
      };
    }

    if (isRightOutside) {
      const rightWidth =
        getX(hoveredPosition) - (getX(selectedPosition) + getWidth(selectedPosition));

      newPositions = {
        ...newPositions,
        right: {
          left: getWidth(selectedPosition),
          width: rightWidth
        }
      };
    }

    if (isLeftOutside) {
      const leftWidth =
        getX(selectedPosition) - (getX(hoveredPosition) + getWidth(hoveredPosition));

      newPositions = {
        ...newPositions,
        left: {
          left: -leftWidth,
          width: leftWidth
        }
      };
    }

    if (isAboveInside) {
      const topHeight = getY(selectedPosition) - getY(hoveredPosition);

      newPositions = {
        ...newPositions,
        top: { top: -topHeight, height: topHeight }
      };
    }

    if (isBelowInside) {
      const bottomHeight =
        getY(hoveredPosition) +
        getHeight(hoveredPosition) -
        (getY(selectedPosition) + getHeight(selectedPosition));

      newPositions = {
        ...newPositions,
        bottom: {
          top: getHeight(selectedPosition),
          height: bottomHeight
        }
      };
    }

    if (isRightInside) {
      const rightWidth =
        getX(hoveredPosition) +
        getWidth(hoveredPosition) -
        (getX(selectedPosition) + getWidth(selectedPosition));

      newPositions = {
        ...newPositions,
        right: {
          left: getWidth(selectedPosition),
          width: rightWidth
        }
      };
    }

    if (isLeftInside) {
      const leftWidth = getX(selectedPosition) - getX(hoveredPosition);

      newPositions = {
        ...newPositions,
        left: {
          left: -leftWidth,
          width: leftWidth
        }
      };
    }

    setState(newPositions);
  }, [selectedPosition, hoveredPosition]);

  useEffect(() => {
    const isTwoDirectionSelected =
      [
        relativePosition.isAboveOutside,
        relativePosition.isBelowOutside,
        relativePosition.isLeftOutside,
        relativePosition.isRightOutside
      ].filter((item) => item).length === 2;

    const isIntersected = [
      relativePosition.isIntersectedLeft,
      relativePosition.isIntersectedBottom,
      relativePosition.isIntersectedRight,
      relativePosition.isIntersectedTop
    ].filter((item) => item).length;

    if (isTwoDirectionSelected || isIntersected) {
      setShowDotted(true);
    } else {
      setShowDotted(false);
    }

    const halfSelectedWidth = getWidth(selectedPosition) / 2;
    const halfSelectedHeight = getHeight(selectedPosition) / 2;

    if (relativePosition.isAboveOutside && relativePosition.isLeftOutside) {
      setDottedState({
        horizontal: {
          top: -state.top.height,
          left: -state.left.width,
          width: state.left.width + halfSelectedWidth
        },
        vertical: {
          top: -state.top.height,
          left: -state.left.width,
          height: state.top.height + halfSelectedHeight
        }
      });
    } else if (relativePosition.isAboveOutside && relativePosition.isRightOutside) {
      setDottedState({
        horizontal: {
          top: -state.top.height,
          left: halfSelectedWidth,
          width: state.right.width + halfSelectedWidth
        },
        vertical: {
          top: -state.top.height,
          left: state.right.width + getWidth(selectedPosition),
          height: state.top.height + halfSelectedHeight
        }
      });
    } else if (relativePosition.isBelowOutside && relativePosition.isRightOutside) {
      setDottedState({
        horizontal: {
          top: state.bottom.height + getHeight(selectedPosition),
          left: halfSelectedWidth,
          width: state.right.width + halfSelectedWidth
        },
        vertical: {
          top: halfSelectedHeight,
          left: state.right.width + getWidth(selectedPosition),
          height: state.bottom.height + halfSelectedHeight
        }
      });
    } else if (relativePosition.isBelowOutside && relativePosition.isLeftOutside) {
      setDottedState({
        horizontal: {
          top: state.bottom.height + getHeight(selectedPosition),
          left: -state.left.width,
          width: state.left.width + halfSelectedWidth
        },
        vertical: {
          top: halfSelectedHeight,
          left: -state.left.width,
          height: state.bottom.height + halfSelectedHeight
        }
      });
    } else if (
      relativePosition.isAboveOutside &&
      relativePosition.isIntersectedLeft &&
      hoveredPosition
    ) {
      setDottedState({
        ...defaultDottedState,
        horizontal: {
          top: -state.top.height,
          left: getX(hoveredPosition) + getWidth(hoveredPosition) - getX(selectedPosition),
          width:
            getX(selectedPosition) +
            halfSelectedWidth -
            (getX(hoveredPosition) + getWidth(hoveredPosition))
        }
      });
    } else if (
      relativePosition.isAboveOutside &&
      relativePosition.isIntersectedRight &&
      hoveredPosition
    ) {
      setDottedState({
        ...defaultDottedState,
        horizontal: {
          top: -state.top.height,
          left: halfSelectedWidth,
          width: getX(hoveredPosition) - (getX(selectedPosition) + halfSelectedWidth)
        }
      });
    } else if (
      relativePosition.isRightOutside &&
      relativePosition.isIntersectedTop &&
      hoveredPosition
    ) {
      setDottedState({
        ...defaultDottedState,
        vertical: {
          top: getY(hoveredPosition) + getHeight(hoveredPosition) - getY(selectedPosition),
          left: getWidth(selectedPosition) + state.right.width,
          height:
            getY(selectedPosition) +
            halfSelectedHeight -
            (getY(hoveredPosition) + getHeight(hoveredPosition))
        }
      });
    } else if (
      relativePosition.isRightOutside &&
      relativePosition.isIntersectedBottom &&
      hoveredPosition
    ) {
      setDottedState({
        ...defaultDottedState,
        vertical: {
          top: halfSelectedHeight,
          left: getWidth(selectedPosition) + state.right.width,
          height: getY(hoveredPosition) - (getY(selectedPosition) + halfSelectedHeight)
        }
      });
    } else if (
      relativePosition.isBelowOutside &&
      relativePosition.isIntersectedRight &&
      hoveredPosition
    ) {
      setDottedState({
        ...defaultDottedState,
        horizontal: {
          top: getHeight(selectedPosition) + state.bottom.height,
          left: halfSelectedWidth,
          width: getX(hoveredPosition) - (getX(selectedPosition) + halfSelectedWidth)
        }
      });
    } else if (
      relativePosition.isBelowOutside &&
      relativePosition.isIntersectedLeft &&
      hoveredPosition
    ) {
      setDottedState({
        ...defaultDottedState,
        horizontal: {
          top: getHeight(selectedPosition) + state.bottom.height,
          left: getX(hoveredPosition) + getWidth(hoveredPosition) - getX(selectedPosition),
          width:
            getX(selectedPosition) +
            halfSelectedWidth -
            (getX(hoveredPosition) + getWidth(hoveredPosition))
        }
      });
    } else if (
      relativePosition.isLeftOutside &&
      relativePosition.isIntersectedBottom &&
      hoveredPosition
    ) {
      setDottedState({
        ...defaultDottedState,
        vertical: {
          top: halfSelectedHeight,
          left: -state.left.width,
          height: getY(hoveredPosition) - (getY(selectedPosition) + halfSelectedHeight)
        }
      });
    } else if (
      relativePosition.isLeftOutside &&
      relativePosition.isIntersectedTop &&
      hoveredPosition
    ) {
      setDottedState({
        ...defaultDottedState,
        vertical: {
          top: getY(hoveredPosition) + getHeight(hoveredPosition) - getY(selectedPosition),
          left: -state.left.width,
          height:
            getY(selectedPosition) +
            halfSelectedHeight -
            (getY(hoveredPosition) + getHeight(hoveredPosition))
        }
      });
    } else {
      setDottedState(defaultDottedState);
    }
  }, [state, hoveredPosition, selectedPosition, relativePosition]);

  return (
    <WidgetOutline position={selectedPosition}>
      {/* top */}
      <WidgetLine
        height={state.top.height}
        left="50%"
        top={state.top.top}
        transform="translateX(-50%)"
        value={state.top.height}
      />
      {/* right */}
      <WidgetLine
        left={state.right.left}
        top="50%"
        transform="translateY(-50%)"
        value={state.right.width}
        width={state.right.width}
      />
      {/* bottom */}
      <WidgetLine
        height={state.bottom.height}
        left="50%"
        top={state.bottom.top}
        transform="translateX(-50%)"
        value={state.bottom.height}
      />
      {/* left */}
      <WidgetLine
        left={state.left.left}
        top="50%"
        transform="translateY(-50%)"
        value={state.left.width}
        width={state.left.width}
      />

      {showDotted && (
        <>
          <WidgetLineDotted
            left={dottedState.horizontal.left}
            top={dottedState.horizontal.top}
            width={dottedState.horizontal.width}
          />
          <WidgetLineDotted
            height={dottedState.vertical.height}
            isVertical
            left={dottedState.vertical.left}
            top={dottedState.vertical.top}
          />
        </>
      )}
    </WidgetOutline>
  );
};
