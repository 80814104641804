import React, { useEffect, useRef } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { API } from '@services';
import { Story } from '@storysdk/core';
import './SharingRoute.scss';

const b = block('SharingRoute');

export const SharingRoute: React.FC = () => {
  const { shortId } = useParams<{
    shortId: string;
  }>();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const showStory = async () => {
      const { data } = await API.shorts.get({
        shortId
      });

      if (data.data && !data.error) {
        const { appToken, groupId, storyId } = data.data.data;

        const story = new Story(appToken, {
          devMode:
            process.env.REACT_APP_SITE_URL !== 'https://app.storysdk.com' ? 'staging' : undefined,
          autoplay: true,
          forbidClose: true,
          groupId,
          startStoryId: storyId
        });

        const element = containerRef.current;
        story.renderGroups(element);
      }
    };

    showStory();
  }, [shortId]);

  return <div className={b()} ref={containerRef} />;
};
