import React from 'react';
import PropTypes from 'prop-types';

const IconPublish = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="22"
    stroke="#A49BA4"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.69479 16.3055V16.3055C2.76438 13.3751 2.76438 8.6249 5.69479 5.69449V5.69449C8.62521 2.76408 13.3754 2.76408 16.3058 5.69449V5.69449C19.2362 8.6249 19.2362 13.3751 16.3058 16.3055V16.3055"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M11.0001 7.46299V14.537"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M8.34732 10.1157L11.0001 7.46297L13.6528 10.1157"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M7.77161 17.7656C9.8101 18.7383 12.1905 18.7383 14.229 17.7656"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconPublish.propTypes = {
  className: PropTypes.string
};

IconPublish.defaultProps = {
  className: ''
};

export default IconPublish;
