import React from 'react';
import block from 'bem-cn';
import { SwitchGroup } from '@components';
import { EditorModeToggle } from '../EditorModeToggle';
import './SettingsBox.scss';

const b = block('SettingsBox');

interface SettingsBoxGroupProps {
  title?: string | React.ReactChild;
  noPadding?: boolean;
  isGap?: boolean;
  onAction?(): void;
}

interface CollapsibleGroupProps extends SettingsBoxGroupProps {
  isCollapse?: boolean;
  onToggle(): void;
}

interface SwitchableGroupProps extends SettingsBoxGroupProps {
  isChecked: boolean;
  onToggle(): void;
}

export class SettingsBox extends React.Component<{ withToggle?: boolean }> {
  static Title: React.FC<SettingsBoxGroupProps> = (props) => (
    <div className={b('titleMain')}>{props.children}</div>
  );

  static Group: React.FC<SettingsBoxGroupProps> = (props) => (
    <div className={b('group', { noPadding: props.noPadding })}>
      {props.title && (
        <div className={b('titleContainer')}>
          <span className={b('title')}>{props.title}</span>
          {props.onAction && (
            <button className={b('actionBtn')} onClick={props.onAction}>
              <svg
                fill="none"
                height="7"
                viewBox="0 0 7 7"
                width="7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 3.5H7M3.5 7V0" stroke="white" />
              </svg>
            </button>
          )}
        </div>
      )}

      <div className={b('groupContent', { gap: props.isGap })}>{props.children}</div>
    </div>
  );

  static SwitchableGroup: React.FC<SwitchableGroupProps> = (props) => (
    <div className={b('group')}>
      <SwitchGroup
        className={b('switchContainer', { noMargin: !props.isChecked }).toString()}
        isChecked={props.isChecked}
        label={props.title ?? ''}
        onToggle={props.onToggle}
      />
      <div className={b('groupContent', { hidden: !props.isChecked })}>{props.children}</div>
    </div>
  );

  static CollapsibleGroup: React.FC<CollapsibleGroupProps> = (props) => (
    <div className={b('group')}>
      <div className={b('titleContainer')}>
        <span className={b('title')}>{props.title}</span>
      </div>

      <div className={b('groupContent', { collapsed: props.isCollapse })}>{props.children}</div>
    </div>
  );

  static Field: React.FC<{ separate?: boolean }> = (props) => (
    <div className={b('field', { separate: props.separate })}>{props.children}</div>
  );

  render() {
    return (
      <div className={b()}>
        {this.props.withToggle && <EditorModeToggle />}
        {this.props.children}
      </div>
    );
  }
}
