import React from 'react';
import PropTypes from 'prop-types';

const IconUrl = ({ className }) => (
  <svg
    className={className}
    height={18}
    stroke="#A49BA4"
    viewBox="0 0 18 18"
    width={18}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.166 6.541a.5.5 0 10-.707-.707l.707.707zM5.834 11.46a.5.5 0 10.707.707l-.707-.707zm2.516 3.053l-.296.296.707.707.296-.296-.707-.707zm.863-.863l-.863.863.707.707.863-.863-.707-.707zm5.593-5.593l-.296.296.707.707.296-.296-.707-.707zm-.296.296l-.864.864.707.707.864-.864-.707-.707zM9.649 3.49l.295-.296-.707-.707-.296.296.708.707zm-.865.864l.865-.864-.708-.707-.864.864.707.707zM3.192 9.946l.296-.296-.707-.707-.296.296.707.707zm.296-.296l.863-.863-.707-.707-.863.863.707.707zm7.971-3.816l-5.625 5.625.707.707 5.625-5.625-.707-.707zm2.187 3.382a1.45 1.45 0 01-1.208.413l-.126.992a2.45 2.45 0 002.04-.698l-.706-.707zM4.35 8.787a1.46 1.46 0 011.212-.416l.124-.992a2.46 2.46 0 00-2.043.701l.707.707zm-1.866 6.728a4.437 4.437 0 006.276 0l-.707-.707a3.437 3.437 0 01-4.862 0l-.707.707zm12.32-12.32a3.437 3.437 0 010 4.86l.708.708a4.437 4.437 0 000-6.276l-.707.707zm.708-.708a4.437 4.437 0 00-6.276 0l.707.707a3.437 3.437 0 014.862 0l.707-.707zM9.92 14.356a2.46 2.46 0 00.701-2.043l-.992.124a1.46 1.46 0 01-.416 1.212l.707.707zM8.077 3.647a2.45 2.45 0 00-.698 2.04l.992-.125a1.45 1.45 0 01.413-1.208l-.707-.707zm-4.885 11.16a3.437 3.437 0 010-4.86l-.707-.708a4.437 4.437 0 000 6.276l.707-.707z" />
  </svg>
);

IconUrl.propTypes = {
  className: PropTypes.string
};

IconUrl.defaultProps = {
  className: ''
};

export default IconUrl;
