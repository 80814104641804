import React from 'react';
import PropTypes from 'prop-types';

const IconAddPlus = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="8"
    stroke="white"
    viewBox="0 0 8 8"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 1V7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    <path d="M7 4H1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </svg>
);

IconAddPlus.propTypes = {
  className: PropTypes.string
};

IconAddPlus.defaultProps = {
  className: ''
};

export default IconAddPlus;
