import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { GroupsType, informSlice, MessageTypes, StoriesGroup } from '@features';
import { RootState } from '@store';
import i18n from '../../i18n/i18n';

interface State {
  onboardings: StoriesGroup[];
  current: StoriesGroup['id'] | null;
  status: string;
  appId: string | null;
}

const initialState: State = {
  onboardings: [],
  current: null,
  status: 'idle',
  appId: null
};

export const onboardingsSlice = createSlice({
  name: 'onboardings',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<{ groups: StoriesGroup[]; appId: string }>) => {
      state.onboardings = action.payload.groups;

      const newCurrent = action.payload.groups.find((group) => group.id === state.current);

      if (newCurrent) {
        state.current = newCurrent.id;
      } else {
        state.current = action.payload.groups.length ? action.payload.groups[0].id : null;
      }

      state.appId = action.payload.appId;
      state.status = 'loaded';
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    create: (state, action: PayloadAction<StoriesGroup>) => {
      state.onboardings.push(action.payload);

      if (state.onboardings.length === 1) {
        state.current = action.payload.id;
      }
    },
    select: (state, action: PayloadAction<string>) => {
      state.current = action.payload;
    },
    remove: (state, action: PayloadAction<string>) => {
      const findIndex = state.onboardings.findIndex(({ id }) => id === action.payload);
      state.onboardings.splice(findIndex, 1);
    },
    update: (state, action: PayloadAction<StoriesGroup>) => {
      state.onboardings = state.onboardings.map((group) =>
        group.id === action.payload.id ? action.payload : group
      );
    }
  }
});

export const fetchCreateOnboarding = createAsyncThunk(
  'onboardings/fetchCreateOnboarding',
  async (
    params: { name: string; image: Blob | null; appId: string; startTime: string },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const languages = state.appManager.locales;
    const currentLocale = state.appManager.currentLocale;

    let imageUrl = `${process.env.REACT_APP_SITE_URL}/images/onboarding-default.webp`;
    let fileId;

    if (params.image) {
      const storageData = await API.storage.create({ file: params.image });

      if (storageData && storageData.data && !storageData.data.error) {
        imageUrl = storageData.data.data.image_url;
        fileId = storageData.data.data.id;
      }
    }

    const { data } = await API.groups.create({
      appId: params.appId,
      image: imageUrl,
      type: GroupsType.ONBOARDING,
      fileId,
      name: params.name,
      startTime: params.startTime,
      languages: languages?.map((item: any) => item.shortName),
      settings: {
        isProgressHidden: true,
        isProhibitToClose: true,
        addToStories: false,
        autoplayVideos: true
      }
    });

    if (data.data && !data.error && currentLocale) {
      const shareData = {
        appToken: state.appManager.current?.sdkToken,
        groupId: data.data.id
      };

      const shortsData = await API.shorts.create({
        data: shareData
      });

      let settings = { ...data.data.settings };

      if (shortsData.data.data && !shortsData.data.error) {
        settings = { ...data.data.settings, shortDataId: shortsData.data.data.id };
      }

      const groupData = {
        id: data.data.id,
        title: data.data.title[currentLocale],
        image: data.data.image_url[currentLocale],
        fileId: data.data.file_id,
        startDate: data.data.start_time,
        endDate: data.data.end_time,
        type: data.data.type,
        settings,
        active: false,
        groupData: {
          title: data.data.title,
          image: data.data.image
        }
      };

      dispatch(onboardingsSlice.actions.create(groupData));
      const reqParams = { ...groupData } as any;

      reqParams.title = groupData.groupData.title;
      reqParams.image = groupData.groupData.image;
      reqParams.groupId = groupData.id;
      reqParams.startTime = groupData.startDate;
      reqParams.endTime = groupData.endDate;
      reqParams.appId = params.appId;

      await API.groups.update(reqParams);

      return data.data.id;
    }

    return null;
  }
);

export const fetchDeleteOnboarding = createAsyncThunk(
  'onboardings/fetchDeleteOnboarding',
  async (params: { groupId: string; appId: string }, { dispatch }) => {
    const { data } = await API.groups.remove(params);

    if (data.data && !data.error) {
      dispatch(onboardingsSlice.actions.remove(params.groupId));
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.SUCCESS,
          text: i18n.t('notification.groups.deleted')
        })
      );
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.ERROR,
          text: i18n.t('notification.groups.deleteError')
        })
      );
    }
  }
);

export const fetchChangeOnboardingPublishStatus = createAsyncThunk(
  'onboardings/fetchChangeOnboardingPublishStatus',
  async (params: { groupId: string; appId: string; active: boolean }, { dispatch, getState }) => {
    const state = getState() as RootState;

    const currentLocale = state.appManager.currentLocale;
    const currentGroup = state.onboardings.onboardings.find(
      (item: StoriesGroup) => item.id === params.groupId
    );

    if (currentGroup) {
      const reqParams = { ...currentGroup, active: params.active } as any;

      reqParams.type = GroupsType.ONBOARDING;
      reqParams.title = currentGroup.groupData?.title;
      reqParams.image = currentGroup.groupData?.image;
      reqParams.groupId = currentGroup.id;
      reqParams.startTime = currentGroup.startDate;
      reqParams.endTime = currentGroup.endDate;
      reqParams.appId = params.appId;

      const { data } = await API.groups.update(reqParams);

      if (data.data && !data.error && currentLocale) {
        dispatch(
          onboardingsSlice.actions.update({
            id: data.data.id,
            title: data.data.title[currentLocale],
            image: data.data.image_url[currentLocale],
            startDate: data.data.start_time,
            endDate: data.data.end_time,
            active: data.data.active,
            type: data.data.type,
            settings: data.data.settings,
            groupData: {
              title: data.data.title,
              image: data.data.image_url
            }
          })
        );
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: i18n.t('notification.groups.updateError')
          })
        );
      }
    }
  }
);

export const fetchUpdateOnboarding = createAsyncThunk(
  'onboardings/fetchUpdateOnboarding',
  async (params: any, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    if (!currentLocale) {
      return;
    }

    const currentGroup = state.onboardings.onboardings.find(
      (item: StoriesGroup) => item.id === params.groupId
    );

    let storageData;

    if (params.image) {
      storageData = await API.storage.create({ file: params.image });
    }

    if (
      ((storageData && storageData.data && !storageData.data.error) || !params.image) &&
      currentGroup
    ) {
      const reqParams = { ...params };
      let image = null;

      reqParams.title = { ...currentGroup.groupData?.title, [currentLocale]: params.title };

      if (storageData && storageData.data) {
        image = storageData.data.data.file_url;
        reqParams.image = { ...currentGroup.groupData?.image, [currentLocale]: image };
      } else {
        reqParams.image = currentGroup.groupData?.image;
      }

      reqParams.type = GroupsType.ONBOARDING;

      const { data } = await API.groups.update(reqParams);

      if (data.data && !data.error) {
        dispatch(
          onboardingsSlice.actions.update({
            id: data.data.id,
            title: data.data.title[currentLocale],
            image: data.data.image_url[currentLocale],
            startDate: data.data.start_time,
            endDate: data.data.end_time,
            active: data.data.active,
            type: data.data.type,
            settings: data.data.settings,
            groupData: {
              title: data.data.title,
              image: data.data.image_url
            }
          })
        );

        if (!params.noNotification) {
          dispatch(
            informSlice.actions.addMessage({
              type: MessageTypes.SUCCESS,
              text: i18n.t('notification.groups.updated')
            })
          );
        }
      } else {
        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.ERROR,
            text: i18n.t('notification.groups.updateError')
          })
        );
      }
    }
  }
);

export const fetchOnboardings = createAsyncThunk(
  'onboardings/fetchOnboardings',
  async (params: { appId: string }, { dispatch, getState }) => {
    dispatch(onboardingsSlice.actions.setStatus('pending'));

    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    const { data } = await API.groups.getList(params);

    if (data.data && !data.error && currentLocale) {
      dispatch(
        onboardingsSlice.actions.init({
          appId: params.appId,
          groups: data.data
            .filter((item: any) => item.type === GroupsType.ONBOARDING)
            .map((group: any) => ({
              id: group.id,
              title: group.title[currentLocale],
              image: group.image_url[currentLocale],
              active: group.active,
              startDate: group.start_time,
              endDate: group.end_time,
              settings: group.settings,
              type: GroupsType.ONBOARDING,
              groupData: {
                title: group.title,
                image: group.image_url
              }
            }))
        })
      );
    }
  }
);
