import React, { memo, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import {
  ChooseAnswerWidgetSettings,
  ClickMeWidgetSettings,
  EllipseWidgetSettings,
  EmojiReactionWidgetSettings,
  GiphyWidgetSettings,
  QuestionWidgetSettings,
  QuizMultipleAnswerWidgetSettings,
  QuizMultipleAnswerWithImageWidgetSettings,
  QuizOneAnswerWidgetSettings,
  QuizRateWidgetSettings,
  RectangleWidgetSettings,
  SettingsBox,
  SliderWidgetSettings,
  SwipeUpWidgetSettings,
  TalkAboutWidgetSettings,
  TextWidgetSettings,
  TimerWidgetSettings,
  QuizOpenAnswerWidgetSettings,
  useStoryEditorDispatch,
  StoryEditorAction,
  useStoryEditor,
  ImageWidgetSettings,
  VideoWidgetSettings,
  LinkWidgetSettings
} from '@modules';
import { RootState } from '@store';
import {
  EditorMode,
  GroupsType,
  STORY_WIDTH,
  STORY_HEIGHT,
  SettingAlignOnChangeType,
  StoryLogicTools,
  getAlignHorizontalPosition,
  getAlignVerticalPosition
} from '@features';
import { WidgetPositionLimitsType, WidgetPositionType, WidgetsTypes } from '@storysdk/react';
import { useParams } from 'react-router-dom';
import { useCurrentStoriesType, useGetCurrentGroup } from '@features/stories/hooks';
import { useCurrentSizePreset, useCurrentWidgetPosititon } from '@hooks';
import { StoryGroupTools } from '../StoryGroupTools';
import { StoryDesignTools } from '../StoryDesignTools';
import SkeletonSettings from './SkeletonSettings';
import './SettingsFactory.scss';

const b = block('SettingsFactory');

const renderWidget = (
  type: WidgetsTypes,
  params: any,
  position: WidgetPositionType,
  id: string,
  positionLimits: WidgetPositionLimitsType,
  keepRatio: boolean,
  handleChangeParams: any,
  handleChangePosition: any,
  handleChangeWidgetAlign: (alignParams: SettingAlignOnChangeType) => void
) => {
  switch (type) {
    case WidgetsTypes.RECTANGLE:
      return (
        <RectangleWidgetSettings
          id={id}
          params={params}
          position={position}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.ELLIPSE:
      return (
        <EllipseWidgetSettings
          id={id}
          params={params}
          position={position}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.TEXT:
      return (
        <TextWidgetSettings
          params={params}
          position={position}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.SWIPE_UP:
      return (
        <SwipeUpWidgetSettings
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.SLIDER:
      return (
        <SliderWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.QUESTION:
      return (
        <QuestionWidgetSettings
          id={id}
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.CLICK_ME:
      return (
        <ClickMeWidgetSettings
          keepRatio={keepRatio}
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.LINK:
      return (
        <LinkWidgetSettings
          keepRatio={keepRatio}
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.IMAGE:
      return (
        <ImageWidgetSettings
          id={id}
          key={id}
          params={params}
          position={position}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.VIDEO:
      return (
        <VideoWidgetSettings
          id={id}
          key={id}
          params={params}
          position={position}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.TALK_ABOUT:
      return (
        <TalkAboutWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.EMOJI_REACTION:
      return (
        <EmojiReactionWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.TIMER:
      return (
        <TimerWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.CHOOSE_ANSWER:
      return (
        <ChooseAnswerWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.GIPHY:
      return (
        <GiphyWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.QUIZ_ONE_ANSWER:
      return (
        <QuizOneAnswerWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWERS:
      return (
        <QuizMultipleAnswerWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE:
      return (
        <QuizMultipleAnswerWithImageWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.QUIZ_OPEN_ANSWER:
      return (
        <QuizOpenAnswerWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    case WidgetsTypes.QUIZ_RATE:
      return (
        <QuizRateWidgetSettings
          key={id}
          params={params}
          position={position}
          positionLimits={positionLimits}
          onChange={handleChangeParams}
          onChangeAlign={handleChangeWidgetAlign}
          onChangePosition={handleChangePosition}
        />
      );
    default:
      return null;
  }
};

export const SettingsFactory: React.FC = memo(() => {
  const { type, templateId, groupId } = useParams<{
    type: GroupsType;
    templateId?: string;
    groupId?: string;
  }>();

  const currentStoriesType = useCurrentStoriesType(type);
  const editorMode = useSelector((store: RootState) => store[currentStoriesType].editor.editorMode);
  const storyEditorState = useStoryEditor();
  const selectedStory = storyEditorState?.story;
  const selectedWidgetIds = storyEditorState?.selectedWidgetsIds ?? [];
  const storyWidgets = selectedStory?.widgets.filter((item) => item.id === selectedWidgetIds[0]);
  const selectedStoryWidget = storyWidgets?.length ? storyWidgets[0] : null;
  const keepRatio = storyEditorState?.keepRatio;
  const currentGroup = useGetCurrentGroup(type, templateId ?? groupId, !!templateId);
  const currentSizePreset = useCurrentSizePreset(currentGroup);

  const currentPosition = useCurrentWidgetPosititon(
    selectedStoryWidget?.positionByResolutions,
    currentSizePreset?.width,
    currentSizePreset?.height
  );

  const currentStoryPosition = useMemo(
    () => ({
      x: 0,
      y: 0,
      rotate: 0,
      width: currentSizePreset?.width ?? STORY_WIDTH,
      height: currentSizePreset?.height ?? STORY_HEIGHT,
      center: {
        x: (currentSizePreset?.width ?? STORY_WIDTH) / 2,
        y: (currentSizePreset?.height ?? STORY_HEIGHT) / 2
      }
    }),
    [currentSizePreset]
  );

  const storyEditorDispatch = useStoryEditorDispatch();

  const status = useSelector((store: RootState) => store[currentStoriesType].status);
  if (status === 'pending') {
    return <SkeletonSettings />;
  }

  if (selectedWidgetIds.length > 1) {
    return <StoryGroupTools currentSizePreset={currentSizePreset} />;
  }

  if (!selectedStoryWidget && editorMode === EditorMode.LOGIC) {
    return <StoryLogicTools />;
  }

  if (!selectedStoryWidget) {
    return <StoryDesignTools />;
  }

  const handleChangeParams = (params: any, stopSaveImage?: boolean, syncToLocales?: boolean) => {
    storyEditorDispatch?.({
      type: StoryEditorAction.UPDATE_STORY_WIDGET_CONTENT,
      payload: {
        id: selectedStoryWidget.id,
        content: params,
        stopSaveImage,
        syncToLocales
      }
    });
  };

  const handleChangePosition = (params: WidgetPositionType, stopSaveImage?: boolean) => {
    storyEditorDispatch?.({
      type: StoryEditorAction.SET_STORY_WIDGET_POSITION,
      payload: {
        id: selectedStoryWidget.id,
        position: params,
        storyWidth: currentSizePreset.width,
        storyHeight: currentSizePreset.height,
        stopSaveImage
      }
    });
  };

  const handleChangeWidgetAlign = (alignParams: SettingAlignOnChangeType) => {
    if (alignParams.horizontal) {
      handleChangePosition(
        getAlignHorizontalPosition(alignParams.horizontal, currentPosition, currentStoryPosition)
      );
    } else if (alignParams.vertical) {
      handleChangePosition(
        getAlignVerticalPosition(alignParams.vertical, currentPosition, currentStoryPosition)
      );
    }
  };

  return (
    <div className={b()}>
      <SettingsBox>
        {renderWidget(
          selectedStoryWidget.content.type,
          selectedStoryWidget.content.params,
          currentPosition,
          selectedStoryWidget.id,
          selectedStoryWidget.positionLimits,
          keepRatio ?? false,
          handleChangeParams,
          handleChangePosition,
          handleChangeWidgetAlign
        )}
      </SettingsBox>
    </div>
  );
});
