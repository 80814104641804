import { Story } from '@features';
import { BackgroundColorType } from '@storysdk/react';
import { nanoid } from 'nanoid';

export const StoryMock: Story = {
  id: nanoid(),
  widgets: [],
  type: 'exist',
  layerData: {
    layersGroupId: nanoid(),
    positionInGroup: 1,
    isDefaultLayer: true,
    score: {
      letter: 'A',
      points: 0
    }
  },
  background: {
    type: BackgroundColorType.COLOR,
    value: '#FFFFFF'
  },
  createdAt: '2021-08-10T12:00:00.000Z',
  position: 1,
  storyData: {}
};
