import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { groupStatisticDataAdapter, storiesAnalyticsGroupSlice, StoriesGroupBase } from '@features';
import { RangeType } from '@features/storiesAnalyticsFeature/types';
import { DateTime } from 'luxon';
import { RootState } from '@store';

interface GroupsState {
  appId: string | null;
  data: StoriesGroupBase[];
  status: string;
}

const initialState: GroupsState = {
  appId: null,
  data: [],
  status: 'idle'
};

export const groupsAnalyticsFeature = createSlice({
  name: 'groupsAnalytic',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<{ groups: StoriesGroupBase[]; appId: string }>) => {
      state.data = action.payload.groups;
      state.appId = action.payload.appId;
      state.status = 'loaded';
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    }
  }
});

export const fetchGroupsAnalytic = createAsyncThunk(
  'groupsAnalytic/fetchGroups',
  async (params: { appId: string; range: RangeType }, { dispatch, getState }) => {
    dispatch(groupsAnalyticsFeature.actions.setStatus('pending'));

    const state = getState() as RootState;
    const currentLocale = state.appManager.currentLocale;

    const dateFrom = params.range.from ? DateTime.fromSeconds(params.range.from).toJSON() : '';
    const dateTo = params.range.to ? DateTime.fromSeconds(params.range.to).toJSON() : '';

    const groupsResponse = await API.groups.getList({
      appId: params.appId,
      dateFrom: dateFrom ?? undefined,
      dateTo: dateTo ?? undefined,
      reqStatistic: true
    });

    if (groupsResponse.data.data && currentLocale) {
      const groups = groupsResponse.data.data;

      if (!groups.length) {
        dispatch(storiesAnalyticsGroupSlice.actions.reset());
      }

      dispatch(
        groupsAnalyticsFeature.actions.init({
          appId: params.appId,
          groups: groups.map((group: any) => ({
            id: group.id,
            title: group.title[currentLocale],
            image: group.image_url[currentLocale],
            statistic: groupStatisticDataAdapter(group.statistic)
          }))
        })
      );
    }
  }
);
