import React from 'react';
import PropTypes from 'prop-types';

const IconLogout = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="22"
    stroke="#A49BA4"
    viewBox="0 0 22 22"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99805 13.5011V16.0022C5.99805 17.3834 7.1178 18.5032 8.49909 18.5032H16.0022C17.3835 18.5032 18.5033 17.3834 18.5033 16.0022V5.99799C18.5033 4.6167 17.3835 3.49695 16.0022 3.49695H8.49909C7.1178 3.49695 5.99805 4.6167 5.99805 5.99799V8.49903"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M11 13.5011L13.501 11.0001L11 8.49902"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path d="M3.49707 11H13.5012" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </svg>
);

IconLogout.propTypes = {
  className: PropTypes.string
};

IconLogout.defaultProps = {
  className: ''
};

export default IconLogout;
