import React from 'react';
import PropTypes from 'prop-types';

const IconStartStories = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.69043 4.10662C3.99776 3.74662 4.34776 3.42595 4.73176 3.14795"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M11.5834 12.988C10.6301 13.6267 9.48408 14 8.25008 14C7.64208 14 7.05608 13.908 6.50342 13.74"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M4.48312 12.6667C3.62912 11.9767 2.97312 11.0553 2.59912 10"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M2.59898 6C2.37698 6.62667 2.25031 7.29733 2.25031 8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M13.2258 11.3333C13.8685 10.3793 14.2505 9.23667 14.2505 8C14.2505 4.686 11.5645 2 8.25049 2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path d="M8.25016 6V10" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
    <path d="M10.25 8.00004H6.25" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
  </svg>
);

IconStartStories.propTypes = {
  className: PropTypes.string
};

IconStartStories.defaultProps = {
  className: ''
};

export default IconStartStories;
