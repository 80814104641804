import React from 'react';
import PropTypes from 'prop-types';

const IconImg = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2222 1H2.77778C1.79594 1 1 1.79594 1 2.77778V15.2222C1 16.2041 1.79594 17 2.77778 17H15.2222C16.2041 17 17 16.2041 17 15.2222V2.77778C17 1.79594 16.2041 1 15.2222 1Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M5.88888 7.22222C6.62526 7.22222 7.22221 6.62527 7.22221 5.88889C7.22221 5.15251 6.62526 4.55556 5.88888 4.55556C5.1525 4.55556 4.55554 5.15251 4.55554 5.88889C4.55554 6.62527 5.1525 7.22222 5.88888 7.22222Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M17.2222 11.4444L12.7778 7L3 16.7778"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);

IconImg.propTypes = {
  className: PropTypes.string
};

IconImg.defaultProps = {
  className: ''
};

export default IconImg;
