import React from 'react';
import PropTypes from 'prop-types';

const IconShare = ({ className }) => (
  <svg
    className={className}
    fill="white"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="check-done-icon">
      <path
        clipRule="evenodd"
        d="M10.7626 1.33014L14.5039 5.1495C15.5559 6.20469 14.1289 7.60608 13.0763 6.55089L11.4993 4.90388C11.2077 4.61028 10.8466 4.72526 10.8466 5.13885L10.9571 10.8068C10.9571 11.42 10.6166 11.9106 10.0002 11.9106C9.38492 11.9106 9.04627 11.3886 9.04627 10.772L9.01902 5.21717C9.01902 4.80592 8.60589 4.58417 8.31412 4.87777L6.77068 6.49878C5.71835 7.55396 4.44399 6.2047 5.49604 5.1495L9.19879 1.33534C9.63164 0.889477 10.3302 0.888706 10.7626 1.33014ZM15.8836 16.3304L15.9252 14.1059C15.9252 13.4874 16.2633 12.9832 16.8815 12.9832C17.4997 12.9832 17.8361 13.5309 17.8361 14.1494V17.5094C17.8361 18.3342 17.1635 19 16.3434 19H3.65663C2.83313 19 2.16394 18.3297 2.16394 17.5094V14.1494C2.16394 13.5309 2.47974 13.0248 3.09793 13.0248C3.71609 13.0248 4.07504 13.5498 4.07504 14.1682V16.4343C4.07504 16.8917 4.24136 17.1619 4.63636 17.1619L15.3846 17.0788C15.8004 17.058 15.9044 16.7669 15.8836 16.3304Z"
        fillRule="evenodd"
        id="Vector"
      />
    </g>
  </svg>
);

IconShare.propTypes = {
  className: PropTypes.string
};

IconShare.defaultProps = {
  className: ''
};

export default IconShare;
