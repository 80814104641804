import { MessageTypes, informSlice } from '@features/inform';
import { RootState, useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetCurrentApp } from '@features/stories/hooks';
import { AccesibleFeaturesByCollaboratorRole, AppCollaborationFeatures } from '../consts';
import { CollabaratorRole } from '../types';

export const useGetActionByCollaboratorRole = () => {
  const { t } = useTranslation();
  const app = useGetCurrentApp();

  const dispatch = useAppDispatch();
  const user = useSelector((store: RootState) => store.user.user);

  const collaboratorRole =
    app && app.collaboratorRole && user.id !== app.ownerId
      ? app.collaboratorRole
      : CollabaratorRole.OWNER;

  return (feature: AppCollaborationFeatures, action: (params?: any) => void) => {
    if (AccesibleFeaturesByCollaboratorRole[collaboratorRole][feature]) {
      action();
    } else {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.DONT_HAVE_ACCESS,
          text: t('notification.subscription.dontHaveAccessCollaboratorRole')
        })
      );
    }
  };
};
