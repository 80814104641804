import React from 'react';
import PropTypes from 'prop-types';

const IconImgFresh = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="40"
    stroke="#D2CDD1"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M30 35H10C7.23833 35 5 32.7617 5 30V10C5 7.23833 7.23833 5 10 5H30C32.7617 5 35 7.23833 35 10V30C35 32.7617 32.7617 35 30 35Z"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M16.6456 12.521C17.7846 13.6601 17.7846 15.5068 16.6456 16.6458C15.5065 17.7848 13.6598 17.7848 12.5208 16.6458C11.3817 15.5068 11.3817 13.66 12.5208 12.521C13.6598 11.382 15.5065 11.382 16.6456 12.521"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="M34.9998 16.8484C34.2498 16.7417 33.4882 16.6667 32.7082 16.6667C23.8482 16.6667 16.6665 23.8484 16.6665 32.7084C16.6665 33.4884 16.7415 34.2501 16.8482 35.0001"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

IconImgFresh.propTypes = {
  className: PropTypes.string
};

IconImgFresh.defaultProps = {
  className: ''
};

export default IconImgFresh;
