import { useWindowSize } from '@react-hook/window-size';
import { useMemo } from 'react';

const MOBILE_BREAKPOINT = 992;

export const useIsMobile = () => {
  const [width] = useWindowSize();
  const isMobile = useMemo(() => width < MOBILE_BREAKPOINT, [width]);

  return isMobile;
};
