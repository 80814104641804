import React, { useCallback, useEffect, useRef } from 'react';
import block from 'bem-cn';
import { IconClose } from '@components';
import './FreshModal.scss';

const b = block('FreshModal');

interface FreshModalProps {
  isOpen: boolean;
  position?: 'left' | 'center' | 'right';
  size?: 'sm' | 'md' | 'lg';
  theme?: 'dark' | 'light';
  className?: string;
  isProhibitedOutsideClick?: boolean;
  onClose?(): void;
}

export const FreshModalTitle: React.FC<{ centered?: true }> = (props) => {
  const { centered, children } = props;

  return <div className={b('title', { centered })}>{children}</div>;
};

export const FreshModal: React.FC<FreshModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    size = 'sm',
    children,
    position = 'center',
    theme = 'light',
    className,
    isProhibitedOutsideClick
  } = props;

  const modalRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback(
    ({ target }: any) => {
      if (
        isOpen &&
        !isProhibitedOutsideClick &&
        modalRef.current &&
        !modalRef.current?.contains(target) &&
        target.id !== 'downloadLink' &&
        onClose
      ) {
        onClose();
      }
    },
    [onClose, isOpen, isProhibitedOutsideClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={b({ isOpen })}>
      <div className={b('wrapper', { isOpen, position, theme, size })}>
        <div
          className={`${b('dialog', { size, template: theme })} ${className || ''}`}
          ref={modalRef}
        >
          {!!onClose && (
            <button
              aria-label="Close"
              className={b('closeBtn')}
              tabIndex={0}
              onClick={onClose}
              onKeyDown={onClose}
            >
              <IconClose className={b('closeBtnIcon').toString()} />
            </button>
          )}

          {children}
        </div>
      </div>
    </div>
  );
};
