import React from 'react';

interface Props {
  min: number;
  max: number;
  steps: number;
  value: number;
  disabled?: boolean;
  onScrubEnd(): void;
  onChange(value: number): void;
}

interface State {
  isMouseDown: boolean;
  initialMousePos: any;
  initialValue: number;
  value: number;
  steps: number;
}

export class Scrubber extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      isMouseDown: false,
      initialMousePos: null,
      initialValue: 0,
      value: props.value,
      steps: props.steps
    };
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown(e: React.MouseEvent<HTMLSpanElement>) {
    const initialMousePos = {
      x: e.clientX,
      y: e.clientY
    };

    const { value } = this.state;

    this.setState({
      initialMousePos,
      initialValue: value,
      isMouseDown: true
    });

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseMove(e: MouseEvent) {
    if (this.state.isMouseDown && !this.props.disabled) {
      const { min, max } = this.props;
      const { initialValue, initialMousePos, steps } = this.state;

      let newValue = initialValue + (e.clientX - initialMousePos.x) * steps;

      newValue = this.constrain(newValue, min, max);

      this.setState({
        value: newValue
      });
      this.props.onChange(newValue);
    }
  }

  handleMouseUp() {
    if (this.state.isMouseDown && this.props.onScrubEnd) {
      this.props.onScrubEnd();
    }
    this.setState({
      isMouseDown: false
    });
  }

  handleInput(e: any) {
    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    } else {
      this.setState({ value: e.target.value });
    }
  }

  handleChange(e: any) {
    const { min, max } = this.props;
    const value = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);

    this.setState({
      initialMousePos: undefined,
      initialValue: 0,
      isMouseDown: false,
      steps: 0,
      value: this.constrain(value, min, max)
    });
  }

  constrain(value: number, min: number, max: number, decimals?: number): number {
    decimals = typeof decimals !== 'undefined' ? decimals : 0;
    if (min !== undefined && max !== undefined) {
      return this.round(Math.min(Math.max(parseFloat(String(value)), min), max), decimals);
    }
    return value;
  }

  // eslint-disable-next-line class-methods-use-this
  round(value: number, decimals: number): number {
    return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);
  }

  render() {
    return (
      <span
        style={{ cursor: this.props.disabled ? 'auto' : 'ew-resize' }}
        onMouseDown={this.handleMouseDown}
      >
        {this.props.children}
      </span>
    );
  }
}
