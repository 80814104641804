import React, { useCallback, useEffect, useRef } from 'react';
import block from 'bem-cn';
import './Modal.scss';

const b = block('Modal');

interface Props {
  isOpen: boolean;
  position?: 'left' | 'center' | 'right';
  onClose(): void;
  size?: 'sm' | 'md' | 'lg';
  template?: 'dark' | 'light';
  className?: string;
}

export const ModalTitle: React.FunctionComponent<{ centered?: true }> = (props) => {
  const { centered, children } = props;

  return <div className={b('title', { centered })}>{children}</div>;
};

export const Modal: React.FunctionComponent<Props> = (props) => {
  const {
    isOpen,
    onClose,
    size,
    children,
    position = 'center',
    template = 'dark',
    className
  } = props;

  const modalRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback(
    ({ target }: any) => {
      if (modalRef.current && !modalRef.current.contains(target)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <div className={b({ isOpen })}>
      <div className={b('wrapper', { isOpen, position })}>
        <div
          className={`${b('dialog', { size: size || 'sm', template })} ${className || ''}`}
          ref={modalRef}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
