import React, { useState } from 'react';
import block from 'bem-cn';
import {
  Product,
  ProductFeature,
  ProductPrice,
  fetchCancelSubscription,
  fetchSetFreeSubscription,
  fetchUpdateSubscriptionItem,
  useFetchProducts
} from '@features';
import { useSelector } from 'react-redux';
import { API } from '@services';
import { RootState, useAppDispatch } from '@store';
import { AppPlan, UserSubscriptionStatus } from '@features/user/types';
import { useGetCurrentApp } from '@features/stories/hooks';
import { productsSlice } from '@features/products/productsSlice';
import { useGetUserSubscription } from '@hooks';
import { PlansCard } from './_components/PlansCard';
import { PlanCancelModal } from './_components/PlanCancelModal';
import { PlanDowngradeModal } from './_components/PlanDowngradeModal';

import './PlansCards.scss';

const b = block('PlansCards');

interface PlansCardsProps {
  showMode: 'monthly' | 'yearly';
}

export const PlansCards: React.FC<PlansCardsProps> = ({ showMode }) => {
  const userSubcription = useGetUserSubscription();
  const dispatch = useAppDispatch();
  const currentApp = useGetCurrentApp();
  const plansModalType = useSelector((store: RootState) => store.products.plansModalType);
  const apps = useSelector((store: RootState) => store.appManager.appsList);

  useFetchProducts();

  const [planUngrading, setPlanUngrading] = useState<AppPlan | undefined>();
  const [cancelSubscriptionId, setCancelSubscriptionId] = useState<string>();
  const [downgradablePlan, setDowngradablePlan] = useState<{
    priceId: string;
    plan?: AppPlan;
  }>({
    priceId: '',
    plan: undefined
  });

  const productsStatus = useSelector((store: RootState) => store.products.status);
  const products = useSelector((store: RootState) => store.products.products);
  const currentSubscription = useSelector(
    (store: RootState) => store.subscriptions.currentSubscription
  );

  const getSubscriptionAbilities = (product: Product) => {
    if (userSubcription.isSubscribed) {
      return {
        isDowngradable:
          (product.name === AppPlan.FREE && userSubcription.plan !== AppPlan.FREE) ||
          (product.name === AppPlan.INDIE && userSubcription.plan !== AppPlan.INDIE),
        isUpgradable: userSubcription.plan !== AppPlan.STARTUP && product.name !== AppPlan.FREE
      };
    }

    return {
      isDowngradable: false,
      isUpgradable: false
    };
  };

  const productsAdapted = products
    .filter((product: Product) => {
      if (
        userSubcription.subscriptionStatus === UserSubscriptionStatus.CANCELED &&
        apps.length > 1
      ) {
        return product.name === AppPlan.STARTUP;
      }
      if (plansModalType === 'create') {
        return product.name !== AppPlan.FREE;
      }

      return true;
    })
    .map((product: Product) => ({
      ...product,
      features: product.features.map((feature: ProductFeature) => feature.name),
      prices: {
        monthly: {
          id: product.prices?.find((price: ProductPrice) => price.interval === 'month')?.id,
          amount: product.prices?.find((price: ProductPrice) => price.interval === 'month')
            ?.unitAmount,
          currency: product.prices?.find((price: ProductPrice) => price.interval === 'month')
            ?.currency
        },
        yearly: {
          id: product.prices?.find((price: ProductPrice) => price.interval === 'year')?.id,
          amount: product.prices?.find((price: ProductPrice) => price.interval === 'year')
            ?.unitAmount,
          currency: product.prices?.find((price: ProductPrice) => price.interval === 'year')
            ?.currency
        }
      },
      ...getSubscriptionAbilities(product)
    }))
    .sort((a: any, b: any) => a.prices[showMode].amount - b.prices[showMode].amount);

  const handleBuyClick = async (priceId: string, plan?: AppPlan) => {
    if (!currentApp) return;

    if (plan === AppPlan.FREE) {
      await dispatch(
        fetchSetFreeSubscription({
          appId: currentApp.id
        })
      );
    } else {
      const { data } = await API.subscription.checkoutSession({ appId: currentApp.id, priceId });

      if (data?.data.url && !data.error) {
        window.open(data.data.url, '_self');
      }
    }
  };

  const handleCancelClick = () => {
    if (!userSubcription.subscriptionId) return;

    setCancelSubscriptionId(userSubcription.subscriptionId);
  };

  const handleDowngradeClick = (priceId: string, plan?: AppPlan) => {
    const currentDowngradablePlan = plan ?? AppPlan.FREE;
    dispatch(productsSlice.actions.setPlansModalType(undefined));
    dispatch(productsSlice.actions.setDowngradablePlan({ priceId, plan: currentDowngradablePlan }));
  };

  const handleCancelPlan = async () => {
    if (!cancelSubscriptionId) {
      return;
    }

    await dispatch(
      fetchCancelSubscription({
        subscriptionId: cancelSubscriptionId
      })
    );
    setCancelSubscriptionId(undefined);
  };

  const handleDowngradePlan = async () => {
    if (
      downgradablePlan.priceId !== '' &&
      downgradablePlan.plan &&
      currentSubscription?.subscriptionItemId
    ) {
      const planData = {
        ...downgradablePlan
      };

      setDowngradablePlan({
        priceId: '',
        plan: undefined
      });

      setPlanUngrading(downgradablePlan.plan);

      if (downgradablePlan.plan === AppPlan.FREE && currentApp) {
        await dispatch(
          fetchSetFreeSubscription({
            appId: currentApp.id
          })
        );
      } else if (currentApp) {
        await dispatch(
          fetchUpdateSubscriptionItem({
            priceId: planData.priceId,
            subscriptionId: userSubcription.subscriptionId,
            subscriptionItemId: currentSubscription.subscriptionItemId,
            appId: currentApp.id
          })
        );
      }

      setPlanUngrading(undefined);
    }
  };

  const handleUpdateClick = async (priceId: string, plan?: AppPlan) => {
    setPlanUngrading(plan);

    if (currentSubscription?.subscriptionItemId && currentApp) {
      await dispatch(
        fetchUpdateSubscriptionItem({
          priceId,
          subscriptionId: userSubcription.subscriptionId,
          subscriptionItemId: currentSubscription.subscriptionItemId,
          appId: currentApp.id
        })
      );
    } else {
      handleBuyClick(priceId, plan);
    }

    setPlanUngrading(undefined);
  };

  return (
    <>
      <div className={b('cards')}>
        {productsStatus === 'loading' && (
          <>
            <PlansCard isLoading />
            <PlansCard isLoading />
            <PlansCard isLoading />
          </>
        )}
        {productsAdapted.map((product: any) => (
          <PlansCard
            key={`plan-${product.id}`}
            {...product}
            isActive={userSubcription.plan === product.name && userSubcription.isSubscribed}
            isTrialing={userSubcription.isTrialing}
            isUpgrading={planUngrading === product.name}
            showMode={showMode}
            onBuyClick={handleBuyClick}
            onCancelClick={handleCancelClick}
            onDowngradeClick={handleDowngradeClick}
            onUpgradeClick={handleUpdateClick}
          />
        ))}
      </div>

      <PlanCancelModal
        handleCancelPlan={handleCancelPlan}
        isOpen={!!cancelSubscriptionId}
        onClose={() => setCancelSubscriptionId(undefined)}
      />

      <PlanDowngradeModal
        handleDowngradePlan={handleDowngradePlan}
        isOpen={downgradablePlan.priceId !== ''}
        onClose={() =>
          setDowngradablePlan({
            priceId: '',
            plan: undefined
          })
        }
      />
    </>
  );
};
