import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { CropProps, fetchCreateOnboarding } from '@features';
import { FormGroup, FormLabel } from '@components';
import { FreshModalTitle, FreshButton, FreshInput } from '@components/_fresh';
import { useAppDispatch } from '../../../../store';
import './CreateOnboardingContainer.scss';

const b = block('CreateOnboardingContainer');

interface Props {
  currentAppId?: string;
  currentImage: CropProps;
  className?: string;
  defaultName?: string;
  onClose?(groupId?: string): void;
  clearCurrentImage(): void;
}

interface State {
  name: string;
  image: string | null;
}

const initialState: State = {
  name: '',
  image: null
};

export const CreateOnboardingContainer: React.FC<Props> = (props) => {
  const { currentImage, currentAppId, className, defaultName, onClose, clearCurrentImage } = props;
  const dispatch = useAppDispatch();
  const { appId } = useParams<{ appId: string }>();

  const { t } = useTranslation();

  const [isInvalid, setInvalid] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [groupForm, setGroupForm] = useState(initialState);

  useEffect(() => {
    if (defaultName) {
      setGroupForm({ ...groupForm, name: defaultName });
    }
  }, []);

  useEffect(() => {
    if (groupForm.name.trim().length !== 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [groupForm, currentImage]);

  const handleChangeName = ({ value }: { value: string }) => {
    if (value) {
      setInvalid(false);
    } else {
      setInvalid(true);
    }
    setGroupForm({ ...groupForm, name: value });
  };

  const handleCreateGroup = () => {
    if (currentImage) {
      dispatch(
        fetchCreateOnboarding({
          appId: currentAppId ?? appId,
          name: groupForm.name,
          image: null,
          startTime: DateTime.now().set({ hour: 0, minute: 0 }).toMillis().toString()
        })
      )
        .unwrap()
        .then((groupId) => {
          setGroupForm(initialState);
          setButtonDisabled(false);
          clearCurrentImage();
          onClose && onClose(groupId || '');
        });
    }
  };

  return (
    <div className={`${b()} ${className || ''}`}>
      <FreshModalTitle>{t('dashboard.modals.addNewOnboarding.title')}</FreshModalTitle>
      <div className={b('imageContainer')}>
        <img
          alt=""
          className={b('image')}
          src={`${process.env.REACT_APP_SITE_URL}/images/onboarding-active.webp`}
        />
      </div>
      <FormGroup>
        <FormLabel invalid={isInvalid} isBlack>
          {t('dashboard.modals.addNewOnboarding.onboardingName')}
        </FormLabel>
        <FreshInput
          fieldName="name"
          invalid={isInvalid}
          placeholder={t('dashboard.modals.addNewOnboarding.onboardingNamePlaceholder')}
          type="text"
          value={groupForm.name}
          onChange={handleChangeName}
        />
      </FormGroup>
      <FreshButton
        className={b('btn')}
        disabled={buttonDisabled}
        text={t('dashboard.modals.addNewOnboarding.addBtn')}
        onClick={handleCreateGroup}
      />
    </div>
  );
};
