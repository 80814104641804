import { SIZE_PRESETS, STORY_HEIGHT, STORY_WIDTH } from '@features';
import { WidgetObjectType, WidgetPositionType } from '@storysdk/react';
import { useEffect, useMemo, useState } from 'react';

export const useCurrentWidgetPosititon = (
  positionByResolutions?: WidgetObjectType['positionByResolutions'],
  presetWidth?: number,
  presetHeight?: number
) => {
  const defaultPreset = useMemo(
    () =>
      Object.values(SIZE_PRESETS).find((preset) => preset.isDefault) ?? {
        width: STORY_WIDTH,
        height: STORY_HEIGHT
      },
    []
  );

  const [currentPosition, setCurrentPosition] = useState(
    positionByResolutions
      ? (positionByResolutions[`${presetWidth}x${presetHeight}`] as WidgetPositionType)
      : ({} as WidgetPositionType)
  );

  useEffect(() => {
    if (!presetHeight || !presetWidth) return;

    const currentPreset = `${presetWidth}x${presetHeight}`;
    const defaultResolution = `${defaultPreset.width}x${defaultPreset.height}`;

    if (positionByResolutions?.[currentPreset]) {
      setCurrentPosition(positionByResolutions[currentPreset]);
    } else if (positionByResolutions?.[defaultResolution]) {
      setCurrentPosition(positionByResolutions?.[defaultResolution]);
    }
  }, [defaultPreset.height, defaultPreset.width, positionByResolutions, presetHeight, presetWidth]);

  return currentPosition;
};
