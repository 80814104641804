import React from 'react';
import PropTypes from 'prop-types';

const IconIntegrations = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    stroke="#A49BA4"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 5.83333C1.75 4.59069 2.75736 3.58333 4 3.58333H9C10.2426 3.58333 11.25 4.59069 11.25 5.83333V22.25H4C2.75736 22.25 1.75 21.2426 1.75 20V5.83333Z"
      strokeWidth="1.5"
    />
    <path
      d="M4 22.25C2.75736 22.25 1.75 21.2426 1.75 20L1.75 12.75L18.1667 12.75C19.4093 12.75 20.4167 13.7574 20.4167 15V20C20.4167 21.2426 19.4093 22.25 18.1667 22.25H4Z"
      strokeWidth="1.5"
    />
    <circle cx="18.4166" cy="5.58333" r="3.83333" strokeWidth="1.5" />
  </svg>
);

IconIntegrations.propTypes = {
  className: PropTypes.string
};

IconIntegrations.defaultProps = {
  className: ''
};

export default IconIntegrations;
