import React from 'react';
import { CreateGroupContainer, CropProps } from '@features';
import { FreshModal } from '@components/_fresh';

interface Props {
  isOpen: boolean;
  currentImage: CropProps;
  currentAppId?: string;
  onClose(groupId?: string): void;
  clearCurrentImage(): void;
  onImageLoaded(params: CropProps): void;
}

export const CreateGroupModal: React.FC<Props> = (props) => {
  const { isOpen, currentImage, currentAppId, onClose, onImageLoaded, clearCurrentImage } = props;

  return (
    <FreshModal isOpen={isOpen} onClose={() => onClose()}>
      <CreateGroupContainer
        clearCurrentImage={clearCurrentImage}
        currentAppId={currentAppId}
        currentImage={currentImage}
        onClose={onClose}
        onImageLoaded={onImageLoaded}
      />
    </FreshModal>
  );
};
