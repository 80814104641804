import React, { useState } from 'react';
import block from 'bem-cn';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormLabel, IconCopyFresh } from '@components';
import './FreshOutput.scss';

const b = block('FreshOutput');

interface Props {
  value: string;
  label?: string;
  isCopy?: boolean;
  theme?: 'light' | 'dark' | 'transparent';
  className?: string;
}

export const FreshOutput: React.FunctionComponent<Props> = ({
  value,
  label,
  isCopy,
  theme = 'light',
  className
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation();

  const copyValue = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <FormGroup className={className}>
      {label && (
        <FormLabel color={theme === 'light' || theme === 'transparent' ? 'black' : 'white'} isBlack>
          {label}
        </FormLabel>
      )}

      <section className={b('container')}>
        <article className={b('wrapper')}>
          <output className={b({ copy: isCopy, transparent: theme === 'transparent' })}>
            {' '}
            {value}
          </output>

          {isCopy && (
            <CopyToClipboard text={value} onCopy={copyValue}>
              <div
                className={b('copyBtn')}
                role="button"
                tabIndex={0}
                onClick={copyValue}
                onKeyDown={copyValue}
              >
                <IconCopyFresh />
              </div>
            </CopyToClipboard>
          )}
        </article>

        {isCopied && <p className={b('copyText')}>{t('dashboard.groups.copied')}</p>}
      </section>
    </FormGroup>
  );
};
