import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { State, Message } from './types';

const initialState: State = {
  messages: []
};

export const informSlice = createSlice({
  name: 'inform',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<State>) => {
      state = action.payload;
    },
    removeAllButOne: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((item) => item.id === action.payload);
    },
    updateMessage: (state, action: PayloadAction<any>) => {
      state.messages = state.messages.map((item) =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item
      );
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      const newMessage = { ...action.payload, id: action.payload.id || nanoid(6) };

      state.messages = state.messages.filter((item) => item.type !== newMessage.type);

      state.messages.push(newMessage);
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      const findIndex = state.messages.findIndex(({ id }) => id === action.payload);
      state.messages.splice(findIndex, 1);
    }
  }
});

export default informSlice;
