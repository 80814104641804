import React, { useCallback } from 'react';
import cn from 'classnames';
import { Button, Logo } from '@components';
import './AuthLayout.scss';

type PropsType = {
  withGoogle?: boolean;
  title?: string;
  googleTitle?: string;
  footer: any;
  children: any;
  template?: string;
};

const CLASS_NAME = 'AuthLayout';

export const AuthLayout = ({
  withGoogle = false,
  title = '',
  googleTitle = '',
  footer,
  children,
  template
}: PropsType) => {
  const hadnleGoodle = useCallback(() => { }, []);

  return (
    <div className={cn(`${CLASS_NAME}`)}>
      <header className={cn(`${CLASS_NAME}__header`)}>
        <a className={cn(`${CLASS_NAME}__header-link`)} href="/">
          <Logo color="black" />
        </a>
      </header>

      {template === 'form' ? (
        <>
          <section className={cn(`${CLASS_NAME}__content`)}>
            <h2 className={cn(`${CLASS_NAME}__title`)}>{title}</h2>

            {withGoogle && (
              <div className={cn(`${CLASS_NAME}__google`)}>
                <Button
                  block
                  color="white"
                  iconName="google"
                  text={googleTitle}
                  onClick={hadnleGoodle}
                />
              </div>
            )}

            <div className={cn(`${CLASS_NAME}__form-wrapper`)}>{children}</div>
          </section>
        </>
      ) : (
        <>{children}</>
      )}

      <footer className={`${CLASS_NAME}__footer`}>{footer}</footer>
    </div>
  );
};
