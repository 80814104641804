import React, { useEffect, useState } from 'react';
import { CreateAppContainer } from '@features';
import { FreshModal } from '@components/_fresh';

interface CreateAppModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const CreateAppModal: React.FC<CreateAppModalProps> = (props) => {
  const { isOpen, onClose } = props;
  const [isOpenModal, setOpenModal] = useState(isOpen);

  const handleOpenModal = (isModalOpen: boolean) => {
    setOpenModal(isModalOpen);

    if (!isModalOpen) {
      onClose();
    }
  };

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  return (
    <FreshModal isOpen={isOpenModal} size="sm" onClose={onClose}>
      <CreateAppContainer setOpenModal={handleOpenModal} />
    </FreshModal>
  );
};
