import { GroupPositionType, Story } from '@features';
import { WidgetObjectType } from '@storysdk/react';
import React, { createContext, useContext, useReducer } from 'react';
import { getNewWidgetPositions } from '@utils';

type StoryStateType = Story & {
  saveAll?: boolean;
  saveOnlyBackground?: boolean;
};

export type StoryActionType = {
  type: string;
  payload?: any;
};

export enum SelectedWidgetMode {
  VIEW = 'view',
  EDIT = 'edit'
}

type StoryEditorStateType = {
  story: StoryStateType | null;
  group: GroupPositionType | null;
  selectedWidgetsIds: string[];
  keepRatio?: boolean;
  selectedWidgetMode: SelectedWidgetMode;
};

const initState = {
  story: null,
  group: null,
  keepRatio: false,
  selectedWidgetMode: SelectedWidgetMode.VIEW,
  selectedWidgetsIds: []
};

const StoryEditorContext = createContext<StoryEditorStateType | null>(null);
const StoryEditorDispatchContext = createContext<React.Dispatch<StoryActionType> | null>(null);

export enum StoryEditorAction {
  SET_STORY = 'SET_STORY',
  UPDATE_STORY = 'UPDATE_STORY',
  SET_GROUP = 'SET_GROUP',
  SET_STORY_WIDGETS = 'SET_STORY_WIDGETS',
  SET_GROUP_ALIGN = 'SET_GROUP_ALIGN',
  UPDATE_STORY_WIDGET = 'UPDATE_STORY_WIDGET',
  UPDATE_STORY_WIDGET_CONTENT = 'UPDATE_STORY_WIDGET_CONTENT',
  SET_STORY_WIDGET_POSITION = 'SET_STORY_WIDGET_POSITION',
  UPDATE_STORY_WIDGET_POSITION = 'UPDATE_STORY_WIDGET_POSITION',
  SET_KEEP_RATIO = 'SET_KEEP_RATIO',
  SET_SELECTED_WIDGETS_IDS = 'SET_SELECTED_WIDGETS_IDS',
  ADD_STORY_WIDGET = 'ADD_STORY_WIDGET',
  ADD_STORY_WIDGETS = 'ADD_STORY_WIDGETS',
  REMOVE_SELECTED_STORY_WIDGETS = 'REMOVE_SELECTED_STORY_WIDGETS',
  SET_SELECTED_WIDGET_MODE = 'SET_SELECTED_WIDGET_MODE',
  UPDATE_STORY_BACKGROUND = 'UPDATE_STORY_BACKGROUND'
}

const storyEditorReducer = (state: StoryEditorStateType, action: StoryActionType) => {
  switch (action.type) {
    case StoryEditorAction.SET_STORY: {
      return { ...state, story: action.payload };
    }
    case StoryEditorAction.UPDATE_STORY: {
      return {
        ...state,
        story: { ...state.story, ...action.payload, isHasUnpublishedChanges: true, saveAll: true }
      };
    }
    case StoryEditorAction.UPDATE_STORY_BACKGROUND: {
      return {
        ...state,
        story: {
          ...state.story,
          isHasUnpublishedChanges: true,
          saveOnlyBackground: true,
          background: { ...state.story?.background, ...action.payload.background }
        }
      };
    }
    case StoryEditorAction.SET_GROUP: {
      return { ...state, group: action.payload };
    }
    case StoryEditorAction.SET_GROUP_ALIGN: {
      return {
        ...state,
        group: {
          ...state.group,
          lastAlign: action.payload
        }
      };
    }
    case StoryEditorAction.UPDATE_STORY_WIDGET: {
      return {
        ...state,
        story: {
          ...state.story,
          isHasUnpublishedChanges: action.payload.ignoreForPublish
            ? state.story?.isHasUnpublishedChanges
            : true,
          widgets: state.story?.widgets?.map((widget: WidgetObjectType) => {
            if (widget.id === action.payload.widget.id) {
              const newPositions = getNewWidgetPositions(
                action.payload.widget.position,
                widget.positionByResolutions,
                action.payload.storyWidth,
                action.payload.storyHeight,
                state.story?.layerData.isWidgetsSizeSyncActive
              );

              return {
                ...widget,
                ...action.payload.widget,
                position: undefined,
                positionByResolutions: newPositions
              };
            }

            return widget;
          })
        }
      };
    }
    case StoryEditorAction.ADD_STORY_WIDGET: {
      const newWidgets = state.story?.widgets
        ? [...state.story.widgets, action.payload]
        : [action.payload];

      return {
        ...state,
        story: {
          ...state.story,
          isHasUnpublishedChanges: true,
          widgets: newWidgets
        }
      };
    }
    case StoryEditorAction.ADD_STORY_WIDGETS: {
      const newWidgets = state.story?.widgets
        ? [...state.story.widgets, ...action.payload]
        : [...action.payload];

      return {
        ...state,
        story: {
          ...state.story,
          isHasUnpublishedChanges: true,
          widgets: newWidgets
        }
      };
    }
    case StoryEditorAction.REMOVE_SELECTED_STORY_WIDGETS: {
      return {
        ...state,
        story: {
          ...state.story,
          widgets: state.story?.widgets.filter(({ id }) => !state.selectedWidgetsIds.includes(id)),
          saveAll: true
        }
      };
    }
    case StoryEditorAction.SET_SELECTED_WIDGETS_IDS: {
      return { ...state, selectedWidgetsIds: action.payload };
    }
    case StoryEditorAction.SET_STORY_WIDGETS: {
      return {
        ...state,
        story: {
          ...state.story,
          isHasUnpublishedChanges: true,
          widgets: action.payload.widgets,
          saveAll: action.payload.saveAll ?? false
        }
      };
    }
    case StoryEditorAction.UPDATE_STORY_WIDGET_CONTENT: {
      return {
        ...state,
        story: {
          ...state.story,
          isHasUnpublishedChanges: action.payload.ignoreForPublish
            ? state.story?.isHasUnpublishedChanges
            : true,
          widgets: state.story?.widgets.map((widget: WidgetObjectType) => {
            if (widget.id === action.payload.id) {
              return {
                ...widget,
                content: {
                  ...widget.content,
                  params: {
                    ...widget.content.params,
                    ...action.payload.content
                  }
                },
                stopSaveImage: action.payload.stopSaveImage,
                syncToLocales: action.payload.syncToLocales
              };
            }

            return widget;
          })
        }
      };
    }
    case StoryEditorAction.SET_STORY_WIDGET_POSITION: {
      return {
        ...state,
        story: {
          ...state.story,
          isHasUnpublishedChanges: true,
          widgets: state.story?.widgets.map((widget: WidgetObjectType) => {
            if (widget.id === action.payload.id) {
              const newPositions = getNewWidgetPositions(
                action.payload.position,
                widget.positionByResolutions,
                action.payload.storyWidth,
                action.payload.storyHeight,
                state.story?.layerData.isWidgetsSizeSyncActive
              );

              return {
                ...widget,
                positionByResolutions: newPositions
              };
            }

            return widget;
          })
        }
      };
    }
    case StoryEditorAction.UPDATE_STORY_WIDGET_POSITION: {
      return {
        ...state,
        story: {
          ...state.story,
          isHasUnpublishedChanges: action.payload.ignoreForPublish
            ? state.story?.isHasUnpublishedChanges
            : true,
          widgets: state.story?.widgets.map((widget: WidgetObjectType) => {
            if (widget.id === action.payload.id) {
              const newPositions = getNewWidgetPositions(
                action.payload.position,
                widget.positionByResolutions,
                action.payload.storyWidth,
                action.payload.storyHeight,
                state.story?.layerData.isWidgetsSizeSyncActive
              );

              return {
                ...widget,
                positionByResolutions: newPositions
              };
            }

            return widget;
          })
        }
      };
    }
    case StoryEditorAction.SET_KEEP_RATIO: {
      return { ...state, keepRatio: action.payload };
    }
    case StoryEditorAction.SET_SELECTED_WIDGET_MODE: {
      return { ...state, selectedWidgetMode: action.payload };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
};

export const useStoryEditor = () => useContext(StoryEditorContext);
export const useStoryEditorDispatch = () => useContext(StoryEditorDispatchContext);

export const StoryEditorProvider = ({ children }: any) => {
  const [storyEditorState, dispatch] = useReducer(storyEditorReducer, initState);

  return (
    <StoryEditorContext.Provider value={storyEditorState}>
      <StoryEditorDispatchContext.Provider value={dispatch}>
        {children}
      </StoryEditorDispatchContext.Provider>
    </StoryEditorContext.Provider>
  );
};
